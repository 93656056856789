function extractInwardEntryData(inwardEntry) {
    // Extracting the main structured object
    const additionalInfo = {
      invoiceNumber: inwardEntry?.fields["04"]?.["01"]?.value || "",
      invoiceDate: inwardEntry?.fields["04"]?.["02"]?.value || "",
      supplierVendor: inwardEntry?.fields["07"]?.value || "",
      poNumber: inwardEntry.poNumber || "",
      referralPONo: inwardEntry.fields["01"]?.value || "",
      mrn: inwardEntry.fields["02"]?.value || "",
      vehicleNo: inwardEntry.fields["03"]?.value || "",
      gatePassNo: inwardEntry?.fields["05"]?.["01"]?.value || "",
      gatePassDate: inwardEntry?.fields["05"]?.["02"]?.value || "",
      dcNo: inwardEntry?.fields["06"]?.["01"]?.value || "",
      dcDate: inwardEntry?.fields["06"]?.["02"]?.value || "",
      inTime: inwardEntry.inTime || "",
      outTime: inwardEntry.outTime || "",
      id: inwardEntry.id
    };
  
    // Extracting the array of items from invoiceItems
    const invoiceItems = inwardEntry?.invoices[Object.keys(inwardEntry.invoices)[0]]?.invoiceItems || {};
    console.log('Object.values(invoiceItems) : ', Object.values(invoiceItems))
    const itemsArray = Object.values(invoiceItems).map((item) => ({
      itemID: item.itemID || new Date().getTime(),
      id: item.id || new Date().getTime(),
      historyId: item.historyId || new Date().getTime(),
      category: item.category || "",
      description: item.description || "",
      quantityAccepted: item.quantityAccepted || "",
      quantityRejected: item.quantityRejected || "",
      unit: item.uom || "",
      date: item.dateRequired || "",
      purpose: item.remark || "",
      vehicle: inwardEntry.fields["03"]?.value || "",
      images: item.images || [],
      unsaved: true,
    }));

    console.log('itemsArray : ', itemsArray)
  
    return { additionalInfo, itemsArray };
  }


  export default extractInwardEntryData;
  