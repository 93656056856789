import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Fade,
  Grid,
  Divider,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SendIcon from "@mui/icons-material/Send";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import firebase from "../../../config/firebase";
import toast from "react-hot-toast";
import InvoiceButton from "./generatePdf";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import NumbersIcon from "@mui/icons-material/Numbers";
import MoneyIcon from "@mui/icons-material/Money";
import SimCardAlertIcon from "@mui/icons-material/SimCardAlert";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import DialpadIcon from "@mui/icons-material/Dialpad";
import { useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import DisplaySiteImages from "./DisplaySiteImages";
import "./RequestAmountModal";
import { sendNotification } from "./helper";

const RequestAmountModal = ({
  index,
  open,
  name,
  handleClose,
  milestoneAmount,
}) => {
  const invoiceId = new Date().getTime();

  const location = useLocation();
  const data = location.state.selectedProject;

  const [invoiceItem, setInvoiceItem] = useState({
    id: invoiceId,
    amount: null,
    milestoneName: name,
    unseenComment: {
      client: 1,
      firm: 0,
    },
    comment: {
      [invoiceId]: {
        id: invoiceId,
        text: "Hey! We have raised the invoice for the payment, Do confirm it for proceedings.",
        user: "firm",
      },
    },
    reject: false,
    dueDate: null,
    name: data.ClientName,
    remark: null,
    status: "Requested",
  });

  let projectId = data.ProjectId;

  const [clientGST, setClientGST] = useState(null);
  const [woRefNumber, setWORefNumber] = useState(null);
  const [lutSerialNumber, setLutSerialNumber] = useState(null);
  const [arnNumber, setARNNumber] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [stateCode, setStateCode] = useState(null);
  const [placeOfSupply, setPlaceOfSupply] = useState("");
  const [poNos, setPONos] = useState("");

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getInvoiceCredentials = async () => {
    console.log("data.ProjectId : ", data.ProjectId);
    var useRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/invoiceCredentials/`);

    useRef.once("value", function (snapshot) {
      var values = snapshot.val();
      if (values !== null) {
        setClientGST(values.clientGST);
        setWORefNumber(values.woRefNumber);
        setLutSerialNumber(values.lutSerialNumber);
        setARNNumber(values.arnNumber);
        setStateName(values.stateName);
        setStateCode(values.stateCode);
        setPlaceOfSupply(values.placeOfSupply);
        setPONos(values.poNos);
      }
    });
  };

  useEffect(() => {
    getInvoiceCredentials();
  }, []);

  const [fcmClient, setFCMClient] = useState("");
  const [device, setDevice] = useState("");
  const userData = JSON.parse(localStorage.getItem('User'));

  const fetchFCM = async () => {
    var useRef = firebase
      .database()
      .ref(`clientUserDetails/${data.clientNumber}/tokens/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setDevice(data.deviceType);
        setFCMClient(data.fcmToken);
      }
    });
  };

  useEffect(() => {
    fetchFCM();
  }, []);

  const updateTheInvoiceCount = async () => {

    firebase
        .database()
        .ref(`invoiceCounts/${userData.firmId}`)
        .set({
          count : (invoiceCount + 1)
    });
  }

  const writeIntoTheDatabase = (url) => {

    if (url === true) {

      if (invoiceItem.remark === null || invoiceItem.dueDate === null) {
        // this is the hings whoich we all want
        if (invoiceItem.remark === null) {
          toast.error("Remark not given while updating");
        }
        if (invoiceItem.dueDate === null) {
          toast.error("Due Date not given while updating");
        }
        return false;
      } else {
        return true;
      }
    } else {
      let tempInvoiceItem = { ...invoiceItem };
      let amountToPay = 0;
      if (checked) {
        amountToPay = milestoneAmount;
      } else {
        amountToPay =
          Number(milestoneAmount) + Number((milestoneAmount * 0.18).toFixed(0));
      }
      tempInvoiceItem.amount = amountToPay;

      tempInvoiceItem["attachments"] = {
        invoice: {
          url: url,
        },
      };

      let images = {};
      let imageId = new Date().getTime();

      for (let index = 0; index < selectedImages.length; index++) {
        images[imageId] = {
          url: selectedImages[index],
        };
        imageId = imageId + 1000;
      }

      if (selectedImages.length > 0) {
        tempInvoiceItem["attachments"]["images"] = images;
      }

      firebase
        .database()
        .ref(`clientDetails/${data.ProjectId}/invoiceDetails/${invoiceItem.id}`)
        .set(tempInvoiceItem);
      
      sendNotification(
        "Invoice raised",
        device,
        fcmClient,
        `${data.Firm} has raised the invoice of ₹ ${tempInvoiceItem.amount} with you.`,
        data.clientNumber,
        "invoices"
      );
      updateTheInvoiceCount();
    }

    handleClose();
    toast.success("Request sent Successfully");
    // }
  };

  const [visibility, setVisibility] = useState(false);
  const [displayImages, setDisplayImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const handleSelectedImages = (url) => {
    let tempSelectedImages = [...selectedImages];
    if (tempSelectedImages.includes(url)) {
      tempSelectedImages.splice(tempSelectedImages.indexOf(url), 1);
    } else {
      tempSelectedImages.push(url);
    }
    setSelectedImages(tempSelectedImages);
  };

  const fetchMilestoneImages = async () => {
    console.log("index : ", index);
    console.log("data.ProjectId : ", data.ProjectId);
    var useRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/milestoneImages/${index}`);
    useRef.once("value", function (snapshot) {
      var values = snapshot.val();
      console.log("displayImages : ", values);
      if (values !== null) {
        setDisplayImage(Object.values(values));
      }
    });
  };

  useEffect(() => {
    fetchMilestoneImages();
  }, []);

  const [receiverData, setReceiverData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pin: "",
    gstNo: "",
    panNo: "",
  });

  const [consigneeData, setConsigneeData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pin: "",
    gstNo: "",
    panNo: "",
  });

  const checkAddressDetails = async () => {
    const receiverRef = firebase
      .database()
      .ref(`clientDetails/${projectId}/receiverDetails/`);
    const consigneeRef = firebase
      .database()
      .ref(`clientDetails/${projectId}/consigneeDetails/`);

    receiverRef.on("value", (snapshot) => {
      const values = snapshot.val();
      if (values !== null) {
        setReceiverData(values);
      }
    });

    consigneeRef.on("value", (snapshot) => {
      const values = snapshot.val();
      if (values !== null) {
        setConsigneeData(values);
      }
    });

    return () => {
      receiverRef.off("value");
      consigneeRef.off("value");
    };
  };

  useEffect(() => {
    checkAddressDetails();
  }, [projectId]);

  const style = {
    position: "absolute",
    top: visibility ? "20%" : "50%",
    left: visibility ? "20%" : "50%",
    transform: visibility ? "translate(-20%, -20%)" : "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "#ffffff",
    borderRadius: "32px",
    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    p: 4,
  };

  const customInputStyle = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "16px",
      backgroundColor: "#f8fafc",
      "&:hover fieldset": { borderColor: "#3b82f6" },
      "&.Mui-focused fieldset": { borderColor: "#3b82f6" },
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 16px) scale(1)",
      "&.Mui-focused, &.MuiFormLabel-filled": {
        transform: "translate(14px, -9px) scale(0.75)",
      },
    },
  };

  const [invoiceCount, setInvoiceCount] = useState(0);

  const fetchInvoiceCount = async () => {
    const invoiceCountRef = firebase.database().ref(`invoiceCounts/${userData.firmId}`);
    invoiceCountRef.on('value', snapshot => {
      const values = snapshot.val();
      if(values !== null){
        setInvoiceCount(values.count);
      }else{
        setInvoiceCount(0);
      }
    })

  }

  useEffect(() => {
    fetchInvoiceCount()
  }, [])

  return (
    <>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 5,
              }}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  mr: 2, // Add margin to the right
                  fontWeight: 800,
                  color: "#fdd34d",
                  letterSpacing: "-0.5px",
                }}>
                Request Amount
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                />
                <Typography>SEZ Unit</Typography>
              </Box>
            </Box>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div style={{ width: "50%", paddingRight: "16px" }}>
                <TextField
                  fullWidth
                  label={<p style={{ paddingLeft: 25 }}>Amount</p>}
                  variant="outlined"
                  disabled={true}
                  // value={String(milestoneAmount) + '+' + checked ? 0 : String(milestoneAmount*0.18) + ' = ' + String(milestoneAmount + Number((milestoneAmount*0.18).toFixed(0))) }
                  value={
                    !checked
                      ? String(milestoneAmount) +
                        " + " +
                        (milestoneAmount * 0.18).toFixed(0) +
                        " = " +
                        (Number(milestoneAmount) +
                          Number((milestoneAmount * 0.18).toFixed(0))) +
                        " including taxes."
                      : milestoneAmount
                  }
                  onChange={(e) =>
                    setInvoiceItem((prevState) => ({
                      ...prevState,
                      amount: e.target.value,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>

              <div style={{ width: "50%", paddingLeft: "16px" }}>
                <TextField
                  fullWidth
                  label={<p style={{ paddingLeft: 25 }}>Client GST No.</p>}
                  variant="outlined"
                  value={clientGST}
                  onChange={(e) => setClientGST(e.target.value)}
                  onBlur={(e) => {
                    firebase
                      .database()
                      .ref(
                        `clientDetails/${projectId}/invoiceCredentials/clientGST/`
                      )
                      .set(clientGST);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBalanceIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  paddingRight: "16px",
                  marginTop: "32px",
                }}>
                <TextField
                  fullWidth
                  label={<p style={{ paddingLeft: 25 }}>WO Ref NO.</p>}
                  variant="outlined"
                  value={woRefNumber}
                  onBlur={(e) => {
                    firebase
                      .database()
                      .ref(
                        `clientDetails/${projectId}/invoiceCredentials/woRefNumber/`
                      )
                      .set(woRefNumber);
                  }}
                  onChange={(e) => setWORefNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  paddingLeft: "16px",
                  marginTop: "32px",
                }}>
                <TextField
                  fullWidth
                  label={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <p style={{ paddingLeft: 25 }}>LUT Serial No.</p>
                      <p
                        style={{
                          color: "red",
                          fontWeight: 700,
                          fontSize: 30,
                          marginLeft: "10px",
                        }}>
                        {checked ? "*" : ""}
                      </p>
                    </div>
                  }
                  variant="outlined"
                  value={lutSerialNumber}
                  onBlur={(e) => {
                    firebase
                      .database()
                      .ref(
                        `clientDetails/${projectId}/invoiceCredentials/lutSerialNumber/`
                      )
                      .set(lutSerialNumber);
                  }}
                  onChange={(e) => setLutSerialNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MoneyIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  paddingRight: "16px",
                  marginTop: "32px",
                }}>
                <TextField
                  fullWidth
                  label={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <p style={{ paddingLeft: 25 }}>ARN No.</p>
                      <p
                        style={{
                          color: "red",
                          fontWeight: 700,
                          fontSize: 30,
                          marginLeft: "10px",
                        }}>
                        {"   "}
                        {checked ? "*" : ""}
                      </p>
                    </div>
                  }
                  variant="outlined"
                  value={arnNumber}
                  onBlur={(e) => {
                    firebase
                      .database()
                      .ref(
                        `clientDetails/${projectId}/invoiceCredentials/arnNumber/`
                      )
                      .set(arnNumber);
                  }}
                  onChange={(e) => setARNNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SimCardAlertIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  paddingLeft: "16px",
                  marginTop: "32px",
                }}>
                <TextField
                  fullWidth
                  label="State Name"
                  variant="outlined"
                  value={stateName}
                  onBlur={(e) => {
                    firebase
                      .database()
                      .ref(
                        `clientDetails/${projectId}/invoiceCredentials/stateName/`
                      )
                      .set(stateName);
                  }}
                  onChange={(e) => setStateName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCityIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  paddingRight: "16px",
                  marginTop: "32px",
                }}>
                <TextField
                  fullWidth
                  label={<p style={{ paddingLeft: 25 }}>State Code</p>}
                  variant="outlined"
                  value={stateCode}
                  onBlur={(e) => {
                    firebase
                      .database()
                      .ref(
                        `clientDetails/${projectId}/invoiceCredentials/stateCode/`
                      )
                      .set(stateCode);
                  }}
                  onChange={(e) => setStateCode(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DialpadIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  paddingLeft: "16px",
                  marginTop: "32px",
                }}>
                <TextField
                  fullWidth
                  label={<p style={{ paddingLeft: 25 }}>Place of Supply</p>}
                  variant="outlined"
                  value={placeOfSupply}
                  onBlur={(e) => {
                    firebase
                      .database()
                      .ref(
                        `clientDetails/${projectId}/invoiceCredentials/placeOfSupply/`
                      )
                      .set(placeOfSupply);
                  }}
                  onChange={(e) => setPlaceOfSupply(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  paddingRight: "16px",
                  marginTop: "32px",
                }}>
                <TextField
                  fullWidth
                  label={<p style={{ paddingLeft: 25 }}>PO Nos</p>}
                  variant="outlined"
                  value={poNos}
                  onBlur={(e) => {
                    firebase
                      .database()
                      .ref(
                        `clientDetails/${projectId}/invoiceCredentials/poNos/`
                      )
                      .set(poNos);
                  }}
                  onChange={(e) => setPONos(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DoneOutlineIcon sx={{ color: "#fdd34d" }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={customInputStyle}
                />
              </div>
              <div
                style={{
                  width: "50%",
                  paddingLeft: "32px",
                  marginTop: "16px",
                }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Due Date"
                    format="dd/MM/yyyy"
                    value={invoiceItem.dueDate}
                    onChange={(date) =>
                      setInvoiceItem((prevState) => ({
                        ...prevState,
                        dueDate: date.getTime(),
                      }))
                    }
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    disablePast={true}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div style={{ width: "100%", marginTop: "32px" }}>
                <TextField
                  fullWidth
                  label="Remarks"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={invoiceItem.remarks}
                  onChange={(e) =>
                    setInvoiceItem((prevState) => ({
                      ...prevState,
                      remark: e.target.value,
                    }))
                  }
                  sx={customInputStyle}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "16px",
              }}>
              {selectedImages.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                  {selectedImages.map((image, index) => (
                    <img
                      src={image}
                      style={{
                        height: "100%",
                        width: "100px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow
                        borderRadius: "8px", // Rounded corners
                        border: "1px solid #ddd", // Border
                        objectFit: "cover",
                      }}
                    />
                  ))}
                </div>
              ) : null}

              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textTransform: "none",
                  borderRadius: "16px",
                  padding: "24px",
                  width: "100%",
                  justifyContent: "center",
                  backgroundColor: "#eff6ff",
                  borderColor: "#fdd34d",
                  color: "#fdd34d",
                  "&:hover": {
                    backgroundColor: "#dbeafe",
                    borderColor: "#2563eb",
                  },
                }}
                onClick={handleVisibility}>
                <AddPhotoAlternateIcon fontSize="large" sx={{ mb: 0.5 }} />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontSize: "1rem" }}>
                  Add Image
                </Typography>
              </Button>
            </div>

            <Divider sx={{ my: 2 }} />

            <InvoiceButton
              request={true}
              writeIntoTheDatabase={writeIntoTheDatabase}
              name={name}
              amount={milestoneAmount}
              clientGST={clientGST}
              woRefNumber={woRefNumber}
              lutSerialNumber={lutSerialNumber}
              arnNumber={arnNumber}
              stateName={stateName}
              stateCode={stateCode}
              checked={checked}
              CGST={
                checked || stateName !== "Telangana"
                  ? "Nil" 
                  : String(
                      Number(
                        (milestoneAmount * 0.09).toFixed(0)
                      ).toLocaleString("en-IN")
                    )
              }
              SGST={
                checked || stateName !== "Telangana"
                  ? "Nil"
                  : String(
                      Number(
                        (milestoneAmount * 0.09).toFixed(0)
                      ).toLocaleString("en-IN")
                    )
              }
              IGST={
                stateName === "Telangana" || checked
                  ? "Nil"
                  : String(
                      Number(
                        (milestoneAmount * 0.18).toFixed(0)
                      ).toLocaleString("en-IN")
                    )
              }
              totalTax={
                checked ? "Nil" : String((milestoneAmount * 0.18).toFixed(0))
              }
              placeOfSupply={placeOfSupply}
              poNos={poNos}
              remark={invoiceItem.remark}
              receiverData={receiverData}
              consigneeData={consigneeData}
              invoiceCount={invoiceCount}
            />

            <DisplaySiteImages
              visibility={visibility}
              displayImages={displayImages}
              selectedImages={selectedImages}
              handleSelectedImages={handleSelectedImages}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RequestAmountModal;
