/* eslint-disable react/jsx-pascal-case */
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import OpportunityService from "../../api/opportunityService";
import Req_Rec_Card from "./Req_Rec_Card";
import { CircularProgress } from "@material-ui/core";
import { filterData } from "./Requests_Fin";
import CustomDateRangePicker from "../../components/DateRangePicker/DateRangePicker";

const Records_Fin = () => {
  const userContext = useContext(UserContext);
  const [rawData, setRawData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [finRecordsData, setFinRecordsData] = useState({});
  const [DateRangeValue, setDateRangeValue] = useState([null, null]);

  const getFinanceRecords = async () => {
    const opportunityService = new OpportunityService();
    const recordData = await opportunityService.getFinanceRecord(
      userContext.user.Firm
    );
    console.log('recordData.results : ', recordData.results);
    setRawData(recordData.results);
    setLoading(false);
  };

  useEffect(() => {
    getFinanceRecords();
  }, []);

  useEffect(() => {
    if (rawData) {
      const projectwiseData = filterData(
        rawData,
        "All",
        "All",
        DateRangeValue,
        "All"
      );
      console.log("project wiser Data", projectwiseData);
      setFinRecordsData(projectwiseData);
    }
  }, [rawData, DateRangeValue]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          marginRight: "2em",
        }}
      >
        <p style={{ margin: "0 5em 0 0" }}>Select Range</p>
        <CustomDateRangePicker
          DateRange={DateRangeValue}
          setDateRange={setDateRangeValue}
        />
      </div>
     
      <div style={{ height: "75vh", overflow: "scroll" }}>
        {
          isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{ width: "30px", height: "30px", color: "#fdd34d" }}
              />
            </div>
          ) : finRecordsData.length > 0 ? (
          
            finRecordsData.map((request, rIndex) => (
              <Req_Rec_Card
                key={rIndex}
                rIndex={rIndex}
                setRawData={setRawData}
                request={request}
                isRecord={true}
                setFinRequestsData={setFinRecordsData}
              />
            ))
          ) : (
            <h4 style={{ textAlign: "center" }}>Nothing to show here!</h4>
          )
        }
      </div>
    </div>
  );
};
export default Records_Fin;
