import { useState } from "react";
import DrawerTemplate from "../../hoc/drawerTemplate";
import AnalyticsBrowser from "./AnalyticsBrowser";
import AnalyticsGraphs from "./AnalyticsGraphs";


const POAnalytics = () => {


  return (

    <div style={{ width: "100%", height: "100%", overflow: "scroll", display: 'flex', flexDirection: 'row' }}>
      <AnalyticsBrowser 
        width={"25%"}
      />
      <AnalyticsGraphs
        width={"75%"}
        />
    </div>

  );
};

export default DrawerTemplate(POAnalytics);
