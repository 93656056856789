/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react";
import "./drawerTemplate1.scss";
import {
  FiHome,
  FiFileText,
  FiEdit,
  FiChevronDown,
  FiChevronUp,
  FiUpload,
  FiDownload,
  FiBarChart,
} from "react-icons/fi";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Portrait from "@mui/icons-material/Portrait";
import { BiRupee } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { Button1, FileExplorer, Tooltip } from "../components";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ProjectService from "../api/projectService";
import { toast } from "react-toast";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { UserContext } from "../Context/UserContext";
import { downloadBOQ, importBOQ } from "../containers/ProjectPage/helper";
import firebase from "../config/firebase";
import ProjectDetailsModal from "../containers/DashboardPage/projectDetailsModal";
import { useAnalyticsContext } from "../Context/Analytics";
import { sendNotification } from "../containers/Finance/ClientComponents/helper";

export default (OldComponent) => {
  const NewComponent = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const projectService = new ProjectService();

    const { record } = useAnalyticsContext();

    const history = useHistory();
    const location = useLocation();

    const userContext = useContext(UserContext);
    const accountType = userContext.user.accountType;

    const [selectedProject, setSelectedProject] = useState();

    const [showVersionHistoryMenu, toggleVersionHistoryMenu] = useState(false);
    const [showRevisionShareMenu, toggleRevisionShareMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState(); // project
    const [revisions, setRevisions] = useState();

    const [showUpdateProjectDetailsModal, setShowUpdateProjectDetailsModal] =
      useState(false);
    const [versionShareCheckBoxes, setVersionShareCheckBoxes] = useState({
      unit: true,
      component: false,
    });
    const [activeRevision, setActiveRevision] = useState(null);

    const [fcmClient, setFCMClient] = useState("");
    const [device, setDevice] = useState("");

    const fetchFCM = async () => {
      if (selectedProject?.clientNumber) {
        var useRef = firebase
          .database()
          .ref(`clientUserDetails/${selectedProject?.clientNumber}/tokens/`);

        useRef.on("value", function (snapshot) {
          var data = snapshot.val();
          if (data !== null) {
            setDevice(data.deviceType);
            setFCMClient(data.fcmToken);
          }
        });
      }
    };

    useEffect(() => {
      // write the code here
      fetchFCM();
    }, [selectedProject]);

    useEffect(() => {
      // console.log("changed the selected PRojects");
      projectService
        .getProjectById(location.pathname.split("/")[2])
        .then((response) => {
          setRevisions(JSON.parse(response.payload[0].Revisions));
          setData({
            ...response.payload[0],
            Rooms: JSON.parse(response.payload[0].Rooms),
          });
        });
    }, [location.pathname, selectedProject]);

    useEffect(() => {
      // console.log("locationt changed");
      if (location.state !== undefined) {
        if ("data" in location.state) {
          setSelectedProject(location.state.data);
        } else if ("selectedProject" in location.state) {
          setSelectedProject(location.state.selectedProject);
        }
      }
    }, [location]);

    const newRevision = async () => {
      firebase
        .database()
        .ref("liveBOQ/" + location.pathname.split("/")[2].toString())
        .once("value", async (snapshot) => {
          if (snapshot.exists()) {
            const verstionData = {
              showUnit: versionShareCheckBoxes.unit,
              showComponent: versionShareCheckBoxes.component,
            };
            const _revisions =
              !revisions || revisions.length === 0
                ? [
                    {
                      Rooms: JSON.parse(snapshot.val().rooms),
                      active: true,
                      ...verstionData,
                    },
                  ]
                : [
                    ...revisions,
                    {
                      Rooms: JSON.parse(snapshot.val().rooms),
                      active: true,
                      ...verstionData,
                    },
                  ];
            const _data = {
              ProjectId: location.pathname.split("/")[2],
              Revisions: _revisions,
            };
            const response = await projectService.setProject(_data);
            await firebase
              .database()
              .ref("liveBOQ/" + location.pathname.split("/")[2].toString())
              .update({ Revisions: JSON.stringify(_revisions) });
            if (response.status === 200) {
              toast.success("Revision created successfully", {
                backgroundColor: "rgb(255, 211, 77)",
                color: "black",
              });

              console.log(
                "selectedProject while sharing the BOQ and sending the notification : ",
                selectedProject
              );
              sendNotification(
                "BOQ Shared",
                device,
                fcmClient,
                `${selectedProject?.Firm} has shared BOQ revision with you.`,
                selectedProject.clientNumber,
                "boq"
              );

              setRevisions(_revisions);
            } else {
              console.log(response);
              toast.error("Error saving revision!", {
                backgroundColor: "rgb(255, 211, 77)",
                color: "black",
              });
            }
          }
        });
    };

    history.onpushstate = function (e) {
      updateFBRDBEditStatus();
      console.log("History has been modified!");
      console.log(e);
    };

    const updateFBRDBEditStatus = () => {
      firebase
        .database()
        .ref(
          "liveBOQ/" +
            location.pathname.split("/")[2].toString() +
            "/" +
            "editStatus"
        )
        .set({ isEditing: false, currentUser: "null", lastEdited: Date.now() })
        .then(() => {});
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const renderMenu = () => {
      return (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem>
            <div
              style={{
                justifyContent: "center",
                fontWeight: "700",
                fontSize: 14,
              }}>
              {data?.ClientName || selectedProject?.clientName}
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setShowUpdateProjectDetailsModal(true);
              // record(TAGS["@edit_project_button_click_success", { description: "Edit project button click success" }])
              record("@edit_project_button_click_success", {
                description: "Edit project button click success",
              });
            }}>
            <FiEdit size={16} style={{ marginRight: 10 }} />
            <p style={{ fontSize: 13 }}>Edit Project</p>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              downloadBOQ();
              // record(TAGS["@sample_boq_button_click_success", { description: "Sample BOQ button click success" }])
              record("@sample_boq_button_click_success", {
                description: "Sample BOQ button click success",
              });
            }}>
            <FiDownload size={16} style={{ marginRight: 10 }} />
            <p style={{ fontSize: 14 }}>Sample BOQ</p>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              // record(TAGS["@import_boq_button_click_success", { description: "Import BOQ button click success" }])
              record("@import_boq_button_click_success", {
                description: "Import BOQ button click success",
              });
            }}>
            <FiUpload size={16} style={{ marginRight: 10 }} />
            <FileExplorer
              accept=".xlsx"
              onFileSelected={(e) => {
                const file = e.currentTarget.files[0];
                importBOQ(
                  file,
                  selectedProject,
                  setSelectedProject,
                  updateFBRDB
                );
              }}>
              <p style={{ fontSize: 14 }}>Import BOQ</p>
            </FileExplorer>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              firebase
                .database()
                .ref("liveBOQ/" + data.ProjectId.toString())
                .once("value", (snapshot) => {
                  if (snapshot.exists()) {
                    const response = {
                      ...snapshot.val(),
                      rooms: JSON.parse(snapshot.val().rooms),
                      Rooms: JSON.parse(snapshot.val().rooms),
                    }; // has to replace with rooms or Rooms
                    downloadBOQ(response);
                  }
                });
              // record(TAGS["@download_boq_button_click_success", { description: "Download BOQ button click success" }])
              record("@download_boq_button_click_success", {
                description: "Download BOQ button click success",
              });
            }}>
            <FiDownload size={16} style={{ marginRight: 10 }} />
            <p style={{ fontSize: 14 }}>Download BOQ</p>
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleRevisionShareMenu((st) => !st);
              // record(TAGS["@share_to_client_button_click_success", { description: "Share to client button click success" }])
              record("@share_to_client_button_click_success", {
                description: "Share to client button click success",
              });
            }}>
            <p style={{ fontSize: 14 }}>Share to Client</p>
            {showRevisionShareMenu ? (
              <FiChevronUp
                size="15"
                style={{ color: "black", marginLeft: "82px" }}
              />
            ) : (
              <FiChevronDown
                size="15"
                style={{ color: "black", marginLeft: "82px" }}
              />
            )}
          </MenuItem>
          {showRevisionShareMenu &&
            (accountType === "Admin" || accountType === "Owner") && (
              <div>
                <div style={{ paddingLeft: 20 }}>
                  <FormControlLabel
                    label={<p style={{ fontSize: 12 }}>Unit</p>}
                    style={{ fontSize: 12 }}
                    control={
                      <Checkbox
                        size="small"
                        disabled
                        checked={versionShareCheckBoxes.unit}
                        onChange={() => {
                          setVersionShareCheckBoxes((prvSt) => ({
                            ...prvSt,
                            unit: !prvSt.unit,
                          }));
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={<p style={{ fontSize: 12 }}>Component</p>}
                    control={
                      <Checkbox
                        size="small"
                        checked={versionShareCheckBoxes.component}
                        onChange={() => {
                          setVersionShareCheckBoxes((prvSt) => ({
                            ...prvSt,
                            component: !prvSt.component,
                          }));
                        }}
                      />
                    }
                  />
                </div>
                <MenuItem>
                  <Button1
                    content="Release Revision"
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(255, 211, 77)",
                      color: "rgba(0,0,0,0.6)",
                      height: 30,
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      newRevision();
                    }}
                  />
                </MenuItem>
              </div>
            )}
          <MenuItem
            onClick={() => {
              toggleVersionHistoryMenu((st) => !st);
              // record(TAGS["@version_history_button_click_success", { description: "Version history button click success" }])
              record("@version_history_button_click_success", {
                description: "Version history button click success",
              });
            }}>
            <p style={{ fontSize: 14 }}>Version History</p>
            {showVersionHistoryMenu ? (
              <FiChevronUp
                size="15"
                style={{ color: "black", marginLeft: "80px" }}
              />
            ) : (
              <FiChevronDown
                size="15"
                style={{ color: "black", marginLeft: "80px" }}
              />
            )}
          </MenuItem>
          {showVersionHistoryMenu &&
            (accountType === "Admin" || accountType === "Owner") && (
              <>
                <MenuItem>
                  <p
                    style={{
                      fontSize: 14,
                      textDecoration: "underline",
                      marginRight: "auto",
                    }}
                    onClick={() => setActiveRevision(null)}>
                    Current
                  </p>
                </MenuItem>
                {revisions?.map((_revison, index) => {
                  return (
                    <MenuItem key={index}>
                      <p
                        style={{
                          fontSize: 14,
                          textDecoration: "underline",
                          marginRight: "auto",
                        }}
                        onClick={() => setActiveRevision(index)}>
                        R{index + 1}
                      </p>
                    </MenuItem>
                  );
                })}
              </>
            )}
        </Menu>
      );
    };

    const updateFBRDB = (payload) =>
      firebase
        .database()
        .ref("liveBOQ/" + location.pathname.split("/")[2].toString())
        .set({ ...payload, rooms: JSON.stringify(payload.rooms) });

    const updateClientFBRDB = (payload) => {
      firebase
        .database()
        .ref(
          `clientUserDetails/${payload.clientNumber}/projects/${payload.ProjectId}`
        )
        .set({
          projectId: payload.ProjectId,
          projectName: payload.ClientName,
        });
      firebase
        .database()
        .ref("liveBOQ/" + payload.ProjectId.toString() + "/clientNumber")
        .set(payload.clientNumber);
    };
    const checkClientNumberExists = (payload) => {
      firebase

        .database()
        .ref("liveBOQ/" + payload.ProjectId.toString() + "/clientNumber")
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            let tempClientNumber = snapshot.val();
            console.log("tempClientNumber", tempClientNumber);
            if (tempClientNumber !== payload.clientNumber) {
              console.log("ClientNumber changed");
              deleteClientIfNumberChanged(payload, tempClientNumber);
              updateClientFBRDB(payload);
            } else {
              console.log("ClientNumber does not exist");
              updateClientFBRDB(payload);
            }
          } else {
            console.log("ClientNumber does not exist");
            updateClientFBRDB(payload);
          }
        });
    };
    const deleteClientIfNumberChanged = (payload, tempClientNumber) => {
      console.log("payload", payload);
      firebase
        .database()
        .ref(
          `clientUserDetails/${tempClientNumber}/projects/${payload.ProjectId}`
        )
        .remove();
    };

    const UpdateProject = async (data) => {
      const ProjectId = location.pathname.split("/")[2];
      const ClientName = data.projectName;
      const ClientNumber = data.projectAccessNumber;
      const Address = data.projectAddress;
      const SiteEngineersId = data.seCode;
      const additionalSEIds = data.additionalSEIds
        .filter((num) => num !== "")
        .map((num) => parseInt(num, 10));
      const response = await projectService.setProject({
        ProjectId,
        ClientName,
        ClientNumber,
        Address,
        SiteEngineersId,
        additionalSEIds,
      });

      const tempSelectedProject = { ...selectedProject };
      delete tempSelectedProject.AmountRecieved;
      // sessionStorage.setItem('clientName', ClientName)
      console.log("tempSelectedProject", tempSelectedProject);
      console.log("data", data);
      await checkClientNumberExists({
        ...tempSelectedProject,
        clientName: data.projectName,
        clientNumber: data.projectAccessNumber,
        address: data.projectAddress,
        siteEngineersId: data.seCode,
      });
      await updateFBRDB({
        ...tempSelectedProject,
        clientName: data.projectName,
        clientNumber: data.projectAccessNumber,
        address: data.projectAddress,
        siteEngineersId: data.seCode,
      });

      setSelectedProject({
        ...selectedProject,
        clientName: data.projectName,
        clientNumber: data.projectAccessNumber,
        address: data.projectAddress,
        siteEngineersId: data.seCode,
      });

      if (response.status === 200) {
        console.log("UpdateProject response: ", response.payload);
        toast.success("Project updated successfully", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
        setShowUpdateProjectDetailsModal(false);
      } else {
        toast.error("Error while updating project!", {
          backgroundColor: "red",
          color: "black",
        });
      }
    };

    // useEffect(() => {
    //     console.log('data', data, '\n', 'selectedProject', selectedProject)
    // }, [data, selectedProject])

    let style = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    };

    let style1 = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      padding: "5px",
      paddingRight:
        location.pathname.slice(1, location.pathname.lastIndexOf("/")) ===
        "ProjectPage"
          ? "18px "
          : "10px",
      paddingLeft:
        location.pathname.slice(1, location.pathname.lastIndexOf("/")) ===
        "ProjectPage"
          ? "18px "
          : "10px",
      marginLeft:
        location.pathname.slice(1, location.pathname.lastIndexOf("/")) ===
        "ProjectPage"
          ? "3px"
          : "4px",
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
      color: "#2f4858",
      fontWeight: "500",
    };

    return (
      <div id="drawerTemplate1">
        <div id="drawer">
          <div onClick={(event) => setAnchorEl(event.currentTarget)}>
            <Tooltip
              content="Click to share or duplicate BOQ"
              left={40}
              width={200}>
              {selectedProject?.clientName?.substring(0, 2)?.toUpperCase() ||
                selectedProject?.ClientName?.substring(0, 2)?.toUpperCase()}
            </Tooltip>
          </div>
          <div
            style={style}
            onClick={() => {
              updateFBRDBEditStatus(); //update the editStatus after naviagating away from the projectPage
              history.push("/");
              record("@home_icon_click_success", {
                description: "Home icon click success",
              });
            }}>
            <Tooltip content="Home">
              <FiHome size={20} />
            </Tooltip>
            <p style={{ fontSize: "10px" }}>Home</p>
          </div>
          <div
            style={
              location.pathname.slice(1, location.pathname.lastIndexOf("/")) ===
              "ProjectPage"
                ? style1
                : style
            }
            onClick={() => {
              history.push("/ProjectPage/" + selectedProject.ProjectId, {
                selectedProject,
              });

              // record(TAGS["@boq_icon_click_success", { description: "BOQ icon click success" }])
              record("@boq_icon_click_success", {
                description: "BOQ icon click success",
              });
            }}>
            <Tooltip content="BOQ">
              <FiFileText size={20} />
            </Tooltip>
            <p style={{ fontSize: "10px" }}>BOQ</p>
          </div>
          <div
            style={style}
            onClick={() => {
              updateFBRDBEditStatus(); //update the editStatus after naviagating away from the projectPage
              history.push("/Analytics/" + selectedProject.ProjectId, {
                selectedProject,
              });
              // record(TAGS["@analytics_icon_click_success", { description: "Analytics icon click success" }])
              record("@analytics_icon_click_success", {
                description: "Analytics icon click success",
              });
            }}>
            <Tooltip content="Analytics">
              <FiBarChart size={20} />
            </Tooltip>
            <p style={{ fontSize: "10px" }}>Analytics</p>
          </div>
          <div
            style={
              location.pathname.slice(1, location.pathname.lastIndexOf("/")) ===
              "Finance"
                ? style1
                : style
            }
            onClick={() => {
              updateFBRDBEditStatus(); //update the editStatus after naviagating away from the projectPage
              history.push("/Finance/" + selectedProject.ProjectId, {
                selectedProject,
              });

              // record(TAGS["@finance_icon_click_success", { description: "Finance icon click success" }])
              record("@finance_icon_click_success", {
                description: "Finance icon click success",
              });
            }}>
            <Tooltip content="Finance">
              {(accountType === "Admin" ||
                accountType === "Owner" ||
                accountType === "Finance") && <BiRupee size={20} />}
            </Tooltip>
            <p style={{ fontSize: "10px" }}>Finance</p>
          </div>

          <div
            style={
              location.pathname.slice(1, location.pathname.lastIndexOf("/")) ===
              "Billing"
                ? style1
                : style
            }
            onClick={() => {
              // updateFBRDBEditStatus(); //update the editStatus after naviagating away from the projectPage
              // history.push("/Finance/" + selectedProject.ProjectId, {
              //   selectedProject,
              // });
              history.push("/Billing/" + selectedProject.ProjectId, {
                selectedProject,
              });
              // record(TAGS["@finance_icon_click_success", { description: "Finance icon click success" }])
              record("@billing_icon_click_success", {
                description: "billing icon click success",
              });
            }}>
            <Tooltip content="Billing">
              <ReceiptLongIcon size={20} />
            </Tooltip>
            <p style={{ fontSize: "10px" }}>Billing</p>
          </div>

          {/* <div
            style={
              location.pathname.slice(1, location.pathname.lastIndexOf("/")) ===
              "Billing"
                ? style1
                : style
            }
            onClick={() => {
              // updateFBRDBEditStatus(); //update the editStatus after naviagating away from the projectPage
              // history.push("/Finance/" + selectedProject.ProjectId, {
              //   selectedProject,
              // });
              history.push("/Billing/" + selectedProject.ProjectId, {
                selectedProject,
              });
              // record(TAGS["@finance_icon_click_success", { description: "Finance icon click success" }])
              record("@billing_icon_click_success", {
                description: "billing icon click success",
              });
            }}
          >
            <Tooltip content="Billing">
              <Portrait size={20} />
            </Tooltip>
            <p style={{ fontSize: "10px" }}>Client</p>
          </div> */}
        </div>

        <OldComponent
          setSelectedProject={(project) => setSelectedProject(project)}
          {...{ activeRevision }}
        />

        {renderMenu()}

        {showUpdateProjectDetailsModal && (
          <ProjectDetailsModal
            onClose={() => setShowUpdateProjectDetailsModal(false)}
            onSave={UpdateProject}
            project={data}
          />
        )}
      </div>
    );
  };

  return NewComponent;
};
