import React, { useEffect, useState } from "react";
import "./MilestoneModal.css";
import HorizontalForm from "./HorizontalForm";
import { FaTimes } from "react-icons/fa";
import "./HorizontalForm.css";
import firebase from "../../../config/firebase";
import toast from "react-hot-toast";
import ConfirmationModal from "./ConfirmationModal";
import { sendNotification } from "./helper";
import { useLocation } from "react-router-dom";

const MilestoneModal = ({
  isOpen,
  setOpen,
  onClose,
  projectValue,
  ProjectId,
}) => {
  const [data, setData] = useState([]);
  const [viewingVersion, setViewingVersion] = useState(false);
  const [revisionData, setRevisionData] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);

  let totalMilestones = 0;
  let totalPercentage = 0;
  const [averagePercentage, setAveragePercentage] = useState(0);

  const location = useLocation();
  const data2 = location.state.selectedProject;

  const [fcmClient, setFCMClient] = useState("");
  const [device, setDevice] = useState("");

  const fetchFCM = async () => {
    var useRef = firebase
      .database()
      .ref(`clientUserDetails/${data2.clientNumber}/tokens/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setDevice(data.deviceType);
        setFCMClient(data.fcmToken);
      }
    });
  };

  useEffect(() => {
    // write the code here
    fetchFCM();
  }, []);

  const handleAdd = (newData) => {
    setData([...data, newData]);
  };

  const udpateDataInTheFirebase = () => {
    firebase.database().ref(`clientDetails/${ProjectId}/milestones/`).set(data);
  };

  useEffect(() => {
    if (data.length > 0) {
      udpateDataInTheFirebase();
    }

    if (data.length > 0) {
      totalMilestones = data.length;
      totalPercentage = data.reduce((sum, milestone) => {
        const completedTasks = milestone.items.reduce(
          (sum, item) => sum + Number(item.completed),
          0
        );

        const totalTasks = milestone.items.length;
        return sum + completedTasks / totalTasks;
      }, 0);
      setAveragePercentage(totalPercentage / totalMilestones);
    }
  }, [data]);

  const getMilestones = async () => {
    var useRef = firebase
      .database()
      .ref(`clientDetails/${ProjectId}/milestones/`);
    useRef.on("value", function (snapshot) {
      var values = snapshot.val();
      console.log("values while fetcing the data : ", values);
      if (values !== null) {
        setData(Object.values(values));
      }
    });
  };

  useEffect(() => {
    getMilestones();
  }, []);

  const dealMilestoneModal = async (val) => {
    setOpen(val);
  };

  const handleShareWithClient = async () => {
    console.log("This is what we have...");
    if (data.length === 0) {
      toast.error("define the milestones first");
      return;
    }
    let timeStamp = new Date().getTime();
    firebase
      .database()
      .ref(`clientDetails/${ProjectId}/milestonesForClients/${timeStamp}`)
      .set(data);
    setConfirmationModal(false);
    sendNotification(
      "Milestone Shared",
      device,
      fcmClient,
      `${data2.Firm} has shared payment milestones udpate with you.`,
      data2.clientNumber,
      "siteDetails"
    );
    toast.success("Successfully shared with the client");
  };

  const handleViewRevisions = async () => {
    setViewingVersion(true);

    var useRef = firebase
      .database()
      .ref(`clientDetails/${ProjectId}/milestonesForClients/`);
    useRef.on("value", function (snapshot) {
      var values = snapshot.val();
      if (values !== null) {
        let tempData = Object.values(values);
        tempData.unshift(data);
        setRevisionData(tempData);
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          <FaTimes />
        </button>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 24px",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
            fontFamily: "Arial, sans-serif",
          }}>
          <h2
            style={{
              margin: 0,
              fontSize: "20px",
              fontWeight: "600",
              color: "#333",
              borderBottom: "2px solid #007bff",
              paddingBottom: "4px",
            }}>
            Payment milestone
          </h2>
          <div>
            <p
              style={{
                margin: 0,
                fontSize: "14px",
                color: "#666",
                marginBottom: "4px",
                fontWeight: 700,
              }}>
              Completed
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "18px",
                fontWeight: "600",
                color: "#28a745",
              }}>
              {averagePercentage.toFixed(2)}
              {"%"}
            </p>
          </div>
          <div style={{ display: "flex", gap: "32px" }}>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: "14px",
                  color: "#666",
                  marginBottom: "4px",
                  fontWeight: 700,
                }}>
                Total Project Value
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#28a745",
                }}>
                {"₹ "}
                {projectValue.toLocaleString()}
              </p>
            </div>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: "14px",
                  color: "#666",
                  marginBottom: "4px",
                  fontWeight: 700,
                }}>
                Total Milestone Amount
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#dc3545",
                }}>
                {"₹ "}
                {data
                  .reduce((acc, curr) => acc + Number(curr.amount), 0)
                  .toLocaleString()}
              </p>
            </div>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: "14px",
                  color: "#666",
                  marginBottom: "4px",
                  fontWeight: 700,
                }}>
                Total Milestone Percentage
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#dc3545",
                }}>
                {data.reduce((acc, curr) => acc + Number(curr.percentage), 0)}
                {" %"}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "16px",
              justifyContent: "center",
              padding: "20px",
            }}>
            {["View Revision", "Share with client"].map((text, index) => (
              <button
                key={index}
                style={{
                  padding: "12px 24px",
                  backgroundColor: index === 0 ? "#3498db" : "#2ecc71",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "600",
                  borderRadius: "8px",
                  border: "none",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  textAlign: "center",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  textTransform: "uppercase",
                  letterSpacing: "0.5px",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor =
                    index === 0 ? "#2980b9" : "#27ae60";
                  e.currentTarget.style.transform = "translateY(-2px)";
                  e.currentTarget.style.boxShadow =
                    "0 6px 8px rgba(0,0,0,0.15)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor =
                    index === 0 ? "#3498db" : "#2ecc71";
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "0 4px 6px rgba(0,0,0,0.1)";
                }}
                onClick={() => {
                  /* Add your functionality here */
                  if (text === "Share with client") {
                    // handleShareWithClient();
                    // <ConfirmationModal
                    //   isOpen={confirmationModal}
                    //   onClose={() => setConfirmationModal(false)}
                    //   onConfirm={handleShareWithClient}
                    //   message={`Are you sure you want to delete milestone?`}
                    // />;
                    setConfirmationModal(true);
                  } else {
                    if (viewingVersion) {
                      setViewingVersion(false);
                    } else {
                      handleViewRevisions();
                    }
                  }
                }}>
                {text}
              </button>
            ))}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "80vh",
          }}>
          {!viewingVersion && (
            <div className="modal-body" style={{ maxHeight: "70vh" }}>
              {data.map((item, index) => (
                <HorizontalForm
                  index={index}
                  dealMilestoneModal={dealMilestoneModal}
                  onAdd={handleAdd}
                  displayHead={index === 0 ? true : false}
                  milestone={false}
                  item={item}
                  data={data}
                  setData={setData}
                  projectValue={projectValue}
                />
              ))}
              <div style={{ height: 100 }} />
            </div>
          )}
          <div className="modal-body" style={{ maxHeight: "auto" }}>
            {viewingVersion &&
              revisionData.map((versionData, dataIndex) => (
                <>
                  {dataIndex === 0 ? (
                    <p>Current Version</p>
                  ) : (
                    <p>Version {` ${dataIndex}`}</p>
                  )}
                  {versionData.map((item, index) => (
                    <HorizontalForm
                      index={index}
                      dealMilestoneModal={dealMilestoneModal}
                      onAdd={handleAdd}
                      displayHead={index === 0 ? true : false}
                      milestone={false}
                      item={item}
                      data={versionData}
                      setData={setData}
                      projectValue={projectValue}
                    />
                  ))}
                  <div style={{ height: 100 }} />
                </>
              ))}
          </div>
          {!viewingVersion && (
            <div style={{ position: "sticky" }}>
              <h3>Add New Milestone</h3>
              <HorizontalForm
                index={data.length}
                dealMilestoneModal={dealMilestoneModal}
                onAdd={handleAdd}
                displayHead={false}
                milestone={true}
                data={data}
                setData={setData}
                projectValue={projectValue}
              />
            </div>
          )}
        </div>
        <ConfirmationModal
          isOpen={confirmationModal}
          onClose={() => setConfirmationModal(false)}
          onConfirm={handleShareWithClient}
          message={`Are you sure you want to share current milestone with the client?`}
        />
      </div>
    </div>
  );
};

export default MilestoneModal;
