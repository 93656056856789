import React from 'react';
import drawerTemplate from '../../hoc/drawerTemplate';
import { FaClock, FaCheckCircle } from 'react-icons/fa';

const MaterialRequest = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto'}}>
      <h2 style={{ color: '#fdd34d', textAlign: 'center' }}>
        Material Requests
      </h2>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
        <FaClock size={80} color="#fdd34d" />
      </div>
      <h3 style={{ textAlign: 'center', marginTop: '20px', color: '#fdd34d' }}>Coming Soon</h3>
      <p style={{ textAlign: 'center', marginTop: '20px' }}>
        <FaCheckCircle size={24} color="#fdd34d" /> We are working on adding the ability to submit material requests. Please check back soon!
      </p>
    </div>
  );
};

export default drawerTemplate(MaterialRequest);