/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  DropDown,
  Input,
  PopOverView,
  TextArea,
} from "../../components";
import {
  FiPaperclip,
  FiTrash2,
  FiEdit2,
  FiInfo,
  FiMoreHorizontal,
} from "react-icons/fi";
import { CgFileDocument } from "react-icons/cg";
import { AiFillCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import ContentEditable from "react-contenteditable";
import { UserContext } from "../../Context/UserContext";
import AnalyticsService from "../../api/analyticsService";
import ProjectService from "../../api/projectService";
import POService from "../../api/poService";
import SmsService from "../../api/smsService";
import OpportunityService from "../../api/opportunityService";
import QuotationService from "../../api/quotationService";
import NotificationService from "../../api/notificationService";
import DraftService from "../../api/draftService";
import AddVendor from "../AuthPage/signUpVendor";
import AddItemsForm from "./AddItemsForm";
import UnreleasedItemsForm from "./UnreleasedItemsForm";
import Preview from "./Preview";
import toast from "react-hot-toast";
import config from "../../config/config";
import POServicefirebase from "../../api/poToFirebaseService";
import fileDialog from "file-dialog";
import firebase from "../../config/firebase";
import environment from "../../config/config";
import { useAnalyticsContext } from "../../Context/Analytics";
import drawerTemplate1 from "../../hoc/drawerTemplate1";
import { getDifference, saveWorkButtonClicked } from "../ProjectPage/helper";
import getVendorPrice from "../../helpers/getVendorPrice";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import SuccessPage from "./SuccessPage";
import { BsArrowLeftShort } from "react-icons/bs";
import AllVendors from "./allVendors";
import "./table1.scss";
import PaymentTerms from "./paymentTerms";
import AddCustomMilestones from "./addCustomMilestones";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { confirmAlert } from "react-confirm-alert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import fetchIndexcesForAddNewItem from "../../helpers/fetchIndexcesForAddNewItem";
import dealFinanceRequest from "./DealFinanceRequests";


const ImageMandatoryMaterialCategories = [
  "Acrylic Sheet",
  "Bed",
  "Brass",
  "Carpet",
  "CP fitting",
  "Crockery",
  "Decorative Light",
  "Door bell",
  "Door lock",
  "Exhaust Fan",
  "Fabric",
  "Fan",
  "flush door",
  "glass",
  "Glass shutter",
  "Granite",
  "KitchenAppliances",
  "Kitchen Sink & Accessories",
  "Laminate",
  "Laquered glass",
  "Light",
  "Light fixture",
  "Limestone",
  "Marble",
  "Metal Paint",
  "metal patti",
  "Mirror",
  "Office chair",
  "Paint",
  "Quartz",
  "Sanitary ware",
  "Seating",
  "Side table",
  "Sink",
  "Slate",
  "Spout",
  "SS Jali",
  "SS Patti",
  "Stone",
  "Switches",
  "Terracotta",
  "Tiles",
  "Tv",
  "veneer wood",
  "vinyl flooring",
  "wall paintings",
  "Wall/Ceilling",
  "Wardrobe",
  "WB",
  "WC",
  "Wood Paint",
  "Wooden Door",
  "WPC",
  "HANDLE",
  "CNC",
  "MATTRESS",
  "BED SPREADS",
  "RUGS/CARPETS",
  "CURTAINS",
  "BLINDS",
];

const OrdersQuotations = () => {

  let isMandatoryImages = useRef(false);
  const [itemsAdded, setItemsAdded] = useState("");
  const { record } = useAnalyticsContext();
  const userContext = useContext(UserContext);
  const accountType = userContext.user.accountType;
  const [activeMainTab, setActiveMainTab] = useState(1);
  const [spinner, setSpinner] = useState(false);
  const analyticsService = new AnalyticsService();
  const projectService = new ProjectService();
  const poService = new POService();
  const poservicefirebse = new POServicefirebase();
  const draftService = new DraftService();
  const [displayPurchaseAmount, setDisplayPurchaseAmount] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const data = location.state.data;
  const selectedWorks = location.state.selectedWorks;
  const selectedMaterials = location.state.selectedMaterials;
  const orderOrQuotation = location.state.orderOrQuotation;
  const quotationId = location.state.quotationId;
  const quotationVendorName = location.state.quotationVendorName;
  const quotationVendorMobile = location.state.quotationVendorMobile;
  const draft = location.state.draft;
  const FromReleasedItem = location.state.releasedItem;
  const FromApproval = location.state.ApprovalItem;
  const releasedOrderDataFromReleased =
    location.state.releasedOrderDataFromReleased;
  const [poresponsefromAPi, setPoresponse] = useState({});
  const comingFromQuotation = location.state.comingFromQuotation;
  const [revision, setRevision] = useState(data.rooms);
  console.log("data rooms", data.rooms)
  const [orderType, setOrderType] = useState(
    orderOrQuotation === "OrderButton" ? "Purchase Order" : "Work Quotation"
  );
  const [textFieldValue, setTextFieldValue] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activeCategory, setActiveCategory] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorMobile, setVendorMobile] = useState("Open Vendor");
  const [vendorFirm, setVendorFirm] = useState("");
  const [vendorGSTIN, setVendorGSTIN] = useState("");
  const [vendorCompanyName, setVendorCompanyName] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [vendorPinCode, setVendorPinCode] = useState("");
  const [vendorCity, setVendorCity] = useState("");
  const [vendorState, setVendorState] = useState("");
  const [vendorDetails, setVendorDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAddItemsFormOpen, setIsAddItemsFormOpen] = useState(false);
  const [isUnreleasedItemsFormOpen, setIsUnreleasedItemsFormOpen] =
    useState(false);
  const [discount, setDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [termsAndConditions, setTermsAndConditions] = useState(
    sessionStorage.getItem("termsAndConditions") || ""
  );
  const [paymentTerms, setPaymentTerms] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [priceBase, setPriceBase] = useState("");
  const [freight, setFreight] = useState({
    value: null,
    description: null
  });
  const [insurance, setInsurance] = useState("");
  const [contactPersonName, setContactPersonName] = useState(
    sessionStorage.getItem("contactPersonName") || ""
  );
  const [contactPersonNumber, setContactPersonNumber] = useState(
    sessionStorage.getItem("contactPersonNumber") || ""
  );
  const [siteEngineerDetails, setSiteEngineerDetails] = useState({
    siteEngineerName: "",
    siteEngineerNumber: "",
    siteEngineerDevice: {},
  });
  const [shippingAddress, setShippingAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState(
    sessionStorage.getItem("billingAddress") || ""
  );
  const [poSuggestions, setPOSuggestions] = useState([]);
  const [checkedWorks, setCheckedWorks] = useState([]);
  const [addedItems, setAddedItems] = useState([]);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [firmName, setFirmName] = useState("");
  const [firmAddress, setFirmAddress] = useState("");
  const [firmPhoneNumber, setFirmPhoneNumber] = useState("");
  const [firmGSTIN, setFirmGSTIN] = useState("");
  const [firmContactPersonName, setFirmContactPersonName] = useState("");
  const [firmContactPersonNumber, setFirmContactPersonNumber] = useState("");
  const [firmContactPersonEmail, setFirmContactPersonEmail] = useState("");
  const [firmSignature, setFirmSignature] = useState("");
  const [isSuccessPageOpen, setIsSuccessPageOpen] = useState(false);
  const [selectedItemsNonEdited, setSelectedItemsNonEdited] = useState([]);
  const [showPaymentTerms, setShowPaymentTerms] = useState(false);
  const [milestoneTobeAddedIndex, setMilestoneTobeAddedIndex] = useState([]);
  const [currentMilestoneTobeEditedIndex, setCurrentMilestoneTobeEditedIndex] =
    useState();
  const [totalPaidAmountReleasedOrder, setTotalPaidAmountReleasedOrder] =
    useState({ isChanged: true, value: 0 });
  const [isApproval, setIsApproval] = useState(false);
  const [OlderVersionofOrderData, setOlderVersionofOrderData] = useState([]);
  const [deletedItemsToUnfreeze, setDeltedItemsToUnfreeze] = useState([]);
  const [isUpdatingProgress, setIsUpdatingProgress] = useState(false);
  let contactPersonNameRef = useRef(null);
  let contactPersonNumberRef = useRef(null);
  let siteEngineerNameRef = useRef(null);
  let siteEngineerNumberRef = useRef(null);
  let shippingAddressRef = useRef(null);
  let billingAddressRef = useRef(null);
  const imageUploader = useRef(null);
  const transactionDetailsofPaidOrder = useRef([]);
  const [imageULS, setImageULS] = useState({});
  var storageRef = firebase.storage().ref();
  var database = firebase.database();
  var orderIds = [];
  const [imagesFromFirebase, setImagesFromFirebase] = useState({});
  var urlForImage = "";
  const milestonesForCalculation = useRef(new Array());
  const VendorDetailsSelected = useRef(null);
  const [showAllVendors, setShowAllVendors] = useState(false);
  const [allVendorData, setAllVendorData] = useState([]);
  const [bankAccountDetails, setBankAccountDetails] = useState([]);
  const defaultMilestonesState = [
    {
      name: "Completion",
      percentage: 100,
      totalAmount: totalAmount,
    },
  ];
  const [defaultMilestones, setDefaultMilestones] = useState(
    defaultMilestonesState
  );
  const [tempStateForTesting, setTempStateForTesting] = useState([])
  const [freightExists, setFreightExists] = useState(null);
  const [freightItemIndex, setFreightItemIndex] = useState(null);
  const [project, setProject] = useState({});
  const { pathname } = useLocation();
  const [draftId, setDraftId] = useState(null);
  const [customMilestones, setCustomMilestones] = useState(false);
  var orderNo = 0;

  useEffect(() => {
    if (location.state && location.state.releasedOrderDataFromReleased) {
      const orderId = location.state.releasedOrderDataFromReleased.OrderId;
      checkFreightExists(orderId);
    }
  }, [location.state.releasedOrderDataFromReleased]);

  useEffect(() => {
    if (orderOrQuotation === "OrderButton") {
      if (selectedWorks && selectedWorks.length > 0) {
        setOrderType("Work Order");
        setTempStateForTesting(
          selectedWorks.map((item) => ({
            ...item,
            milestones: defaultMilestones,
          }))
        );
      }
      if (selectedMaterials && selectedMaterials.length > 0) {
        setOrderType("Purchase Order");
        setTempStateForTesting(
          selectedMaterials.map((item) => ({
            ...item,
            milestones: defaultMilestones,
          }))
        );
      }
    }

    if (orderOrQuotation === "QuatationButton") {
      if (selectedWorks && selectedWorks.length > 0) {
        setOrderType("Work Quotation");
        setTempStateForTesting(selectedWorks);
      }

      if (selectedMaterials && selectedMaterials.length > 0) {
        setOrderType("Material Quotation");
        setTempStateForTesting(selectedMaterials);
      }

      if (comingFromQuotation) {
        if (
          selectedMaterials[0].workType == "Only Work" ||
          selectedMaterials[0].workType == "Work + Material"
        ) {
          setOrderType("Work Order");
        } else {
          setOrderType("Purchase Order");
        }
      }
    }
  }, [orderOrQuotation]);

  useEffect(() => {
    setSelectedItems(tempStateForTesting);
    let tempImageULS = {};
    for (let index = 0; index < tempStateForTesting.length; index++) {
      tempImageULS[tempStateForTesting[index].OrderId] = null;
      orderIds.push(tempStateForTesting[index].OrderId);
    }
    if ((FromReleasedItem || draft) && imagesFromFirebase) {
      for (var key of Object.keys(tempImageULS)) {
        tempImageULS[key] = { ...imagesFromFirebase[key] };
      }
    }
    setImageULS(tempImageULS);
  }, [tempStateForTesting, imagesFromFirebase]);

  useEffect(() => {
    if (orderOrQuotation == "QuatationButton") {
      let tempMilestonesArray = [];
      for (let index = 0; index < selectedItems.length; index++) {
        tempMilestonesArray.push(selectedItems[index].milestones);
      }

      let commonMiestones = findMilestonesFromDraft(tempMilestonesArray);
      let tempIndexes = [];
      let statusMilestone = false;

      if (selectedItems.length > 0) {
        if (selectedItems[0].milestones) {
          statusMilestone = true;
        }
      } else {
      }
      if (statusMilestone) {
        for (let index = 0; index < selectedItems.length; index++) {
          if (
            checkMilestonesToBeEqaul(
              commonMiestones,
              selectedItems[index].milestones
            )
          ) {
          } else {
            tempIndexes.push(index);
          }
        }
        commonMiestones && setDefaultMilestones(commonMiestones);
        commonMiestones && setMilestoneTobeAddedIndex(tempIndexes);
      } else {
      }
    } else {
    }
  }, [selectedItems]);

  useEffect(() => {
    if (vendorMobile && vendorDetails && !draft) {
      vendorDetails.filter((detail) => {
        if (
          detail.Mobile.toString() ==
          vendorMobile.slice(vendorMobile.indexOf(":") + 2)
        ) {
          setVendorFirm(detail.Firm);
          setVendorGSTIN(detail.GSTIN || "");
          setVendorAddress(detail.Address);
          setVendorPinCode(detail.PinCode);
          setVendorCity(detail.City);
          setVendorState(detail.State);
          setVendorCompanyName(detail.Company);
          VendorDetailsSelected.current = detail.Id;
          if (
            !FromReleasedItem &&
            detail.Milestones &&
            JSON.parse(detail.Milestones).length > 0
          ) {
            setDefaultMilestones(() => {
              let tempMilestoneswithoutAmounts = [
                ...JSON.parse(detail.Milestones),
              ];
              for (let i = 0; i < tempMilestoneswithoutAmounts.length; i++) {
                tempMilestoneswithoutAmounts[i].totalAmount =
                  (totalAmount * tempMilestoneswithoutAmounts[i].percentage) /
                  100;
              }
              return tempMilestoneswithoutAmounts;
            });
          }
        }
      });
    } else if (vendorMobile === "Open Vendor") {
      VendorDetailsSelected.current = null;
    }
    if (vendorMobile && vendorDetails && draft) {
      vendorDetails.filter((detail) => {
        if (
          detail.Mobile.toString() ==
          vendorMobile.slice(vendorMobile.indexOf(":") + 2)
        ) {
          setVendorFirm(detail.Firm);
          setVendorGSTIN(detail.GSTIN || "");
          setVendorAddress(detail.Address);
          setVendorPinCode(detail.PinCode);
          setVendorCity(detail.City);
          setVendorState(detail.State);
          setVendorCompanyName(detail.Company);
          VendorDetailsSelected.current = detail.Id;
        }
      });
    }
  }, [vendorMobile, vendorDetails]);

  useEffect(() => {
    let tempImages = { ...imageULS };

    for (let i = 0; i < deletedItemsToUnfreeze.length; i++) {
      if (tempImages[deletedItemsToUnfreeze[i].OrderId]) {
        delete tempImages[deletedItemsToUnfreeze[i].OrderId];
      }
    }
    setImageULS(tempImages);
  }, [deletedItemsToUnfreeze]);

  useEffect(() => {
    if (vendorMobile != "Open Vendor") {
      getBankAccountDetails(vendorMobile);
    }
  }, [vendorMobile]);

  useEffect(() => {
    if (data) {
      setRevision(data.rooms);
    }
  }, [data]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      getVendors();
    }, 300);
    if (ImageMandatoryMaterialCategories.includes(activeCategory[0])) {
      isMandatoryImages.current = true;
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [activeCategory]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const response = await projectService.getSuggestiveTextPO();
    if (response.status == 200) {
      setPOSuggestions(
        response.payload.filter(
          (item) =>
            item.firm == userContext.user.Firm ||
            item.firm == "Staart Buildtech" ||
            item.firm == null
        )
      );
    } else {
      toast.error("Error getting suggestions!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
    }
  }, [data]);

  useEffect(() => {
    let projectId = pathname.split("/")[2];
    firebase
      .database()
      .ref("liveBOQ/" + projectId.toString())
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          const response = {
            ...snapshot.val(),
            rooms: JSON.parse(snapshot.val().rooms),
            Rooms: JSON.parse(snapshot.val().rooms),
          };
          setProject(response);
        }
      });
    if (FromReleasedItem || draft) {
      getURLsOfImageForPO();
    }
  }, []);

  useEffect(() => {
    if (project) {
      fetch(`${config.authService}SiteEngineerAnalytics`, {
        method: "GET",
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          console.log('All SE  json.payload : ', json.payload);
          console.log('project : ', project)
          console.log('project.siteEngineersId : ', project.siteEngineersId)
          let seData = json.payload.find((item) =>
            "siteEngineersId" in project
              ? item.Id == project.siteEngineersId
              : item.Id == project.SiteEngineersId ||
              item.Id == project.siteEngineerId
          );
          console.log('seData : ', seData)
          setSiteEngineerDetails({
            siteEngineerName: seData?.Name,
            siteEngineerNumber: seData?.Mobile,
            siteEngineerDevice: seData.deviceDetails
              ? JSON.parse(seData.deviceDetails)
              : {},
          });
          setShippingAddress(project.address || project.Address || "");
        })
        .catch((e) => { });
    }
  }, [project]);

  useEffect(
    () =>
      setTotalAmount(
        (
          selectedItems.reduce(
            (total, item) =>
              Number(total) +
              Number(
                (
                  Number(item["quantity"] * item["rate"]) +
                  Number(item["quantity"] * item["rate"] * (item["gst"] / 100))
                ).toFixed(2)
              ),
            0
          ) -
          (selectedItems.reduce(
            (total, item) =>
              Number(total) +
              Number(
                (
                  Number(item["quantity"] * item["rate"]) +
                  Number(item["quantity"] * item["rate"] * (item["gst"] / 100))
                ).toFixed(2)
              ),
            0
          ) *
            Number(discount)) /
          100 +
          (isNaN(Number(freight?.value)) ? 0 : Number(freight.value))
        ).toFixed(2)
      ),
    [selectedItems, discount, freight]
  );

  useEffect(() => {
    if (defaultMilestones[0].name === "Paid") {
    } else {
      let tempDefaultMilestones = [...defaultMilestones];
      for (let index = 0; index < tempDefaultMilestones.length; index++) {
        tempDefaultMilestones[index].totalAmount = (
          (tempDefaultMilestones[index].percentage * totalAmount) /
          100
        ).toFixed(2);
      }
      setDefaultMilestones(tempDefaultMilestones);
    }
  }, [totalAmount]);

  useEffect(() => {
    if (
      releasedOrderDataFromReleased &&
      selectedItems.length > 0 &&
      totalPaidAmountReleasedOrder.value > 0
    ) {
      let tempTotalCalAmount = (
        selectedItems.reduce(
          (total, item) =>
            Number(total) +
            Number(
              (
                Number(item["quantity"] * item["rate"]) +
                Number(item["quantity"] * item["rate"] * (item["gst"] / 100))
              ).toFixed(2)
            ),
          0
        ) -
        (selectedItems.reduce(
          (total, item) =>
            Number(total) +
            Number(
              (
                Number(item["quantity"] * item["rate"]) +
                Number(item["quantity"] * item["rate"] * (item["gst"] / 100))
              ).toFixed(2)
            ),
          0
        ) *
          Number(discount)) /
        100 +
        (isNaN(Number(freight.value)) ? 0 : Number(freight.value))
      ).toFixed(2);

      let tempMilestones = JSON.parse(JSON.stringify(defaultMilestones));
      let tempPayments = [];

      tempMilestones.map((mItem) => {
        if (mItem?.payments) {
          tempPayments.push(...mItem.payments);
          delete mItem.payments;
        }
      });

      transactionDetailsofPaidOrder.current = [...tempPayments];

      if (defaultMilestones[0].name === "Paid") {
        let tempdiffAmount = Number(totalPaidAmountReleasedOrder.value);

        tempMilestones.splice(0, 1);
        let temp = [
          {
            name: "Paid",
            totalAmount: tempdiffAmount,
            percentage: Math.ceil(
              (totalPaidAmountReleasedOrder.value / tempTotalCalAmount) * 100
            ),
            payments: [...transactionDetailsofPaidOrder.current],
          },
        ];

        const originalPercentages = defaultMilestones.map(
          (item) => item.percentage
        );

        tempMilestones.forEach((mItem, mIndex) => {
          temp.push({
            name: mItem.name,
            percentage: originalPercentages[mIndex],
            totalAmount: (
              (originalPercentages[mIndex] / 100) *
              (tempTotalCalAmount - tempdiffAmount)
            ).toFixed(2),
          });
        });

        let diffvalue = temp.reduce(
          (percent, mitem) => {
            percent.percentage += +mitem.percentage;
            percent.amount += Number(mitem.totalAmount);
            return { ...percent, percentage: Number(percent.percentage) };
          },
          { percentage: 0, amount: 0 }
        );

        if (
          diffvalue.amount < Number(tempTotalCalAmount) ||
          diffvalue.percentage < 100
        ) {
          temp[temp.length - 1].percentage =
            Number(temp[temp.length - 1].percentage) +
            (100 - diffvalue.percentage);
          temp[temp.length - 1].totalAmount =
            Number(temp[temp.length - 1].totalAmount) +
            (tempTotalCalAmount - diffvalue.amount);
        }

        setDefaultMilestones([...temp]);
      }
    } else if (
      releasedOrderDataFromReleased &&
      selectedItems.length > 0 &&
      totalPaidAmountReleasedOrder.value <= 0
    ) {
      let tempDefaultMilestones = [
        ...JSON.parse(releasedOrderDataFromReleased.CommonMilestones),
      ];

      for (let index = 0; index < tempDefaultMilestones.length; index++) {
        tempDefaultMilestones[index].totalAmount = (
          (tempDefaultMilestones[index].percentage * totalAmount) /
          100
        ).toFixed(2);
      }
      setDefaultMilestones(tempDefaultMilestones);
      milestonesForCalculation.current = [...tempDefaultMilestones];
    }
  }, [
    selectedItems,
    totalPaidAmountReleasedOrder.isChanged,
    freight,
    discount,
  ]);

  useEffect(() => {
    let tempActiveCategory = [];
    if (
      selectedItems != undefined &&
      selectedItems != [] &&
      selectedItems[0] != undefined
    ) {
      selectedItems.forEach((item) => {
        if ("type" in item) {
          tempActiveCategory.push(
            item.type
              .replace('("', "")
              .replace(/"/g, "")
              .replace(', "', ", ")
              .replace(")", "")
          );
        } else {
          tempActiveCategory.push(item.vendorCategory);
        }
      });
    }
    setActiveCategory([...new Set(tempActiveCategory)]);
  }, [selectedItems]);

  useEffect(() => {
    if (draft) {
      setContactPersonNumber(draft?.contactPersonNumber);
    } else if (
      poSuggestions.filter(
        (item) => item.ContactPersonName.localeCompare(contactPersonName) == 0
      )
    ) {
      setContactPersonNumber(
        poSuggestions.filter(
          (item) => item.ContactPersonName.localeCompare(contactPersonName) == 0
        )[0]?.ContactPersonNumber
      );
    }
  }, [contactPersonName, poSuggestions]);

  useEffect(() => {
    sessionStorage.setItem("contactPersonName", contactPersonName);
    sessionStorage.setItem("contactPersonNumber", contactPersonNumber);
    sessionStorage.setItem("termsAndConditions", termsAndConditions);
    sessionStorage.setItem("billingAddress", billingAddress);
  }, [
    contactPersonName,
    contactPersonNumber,
    termsAndConditions,
    billingAddress,
  ]);

  useEffect(() => {
    if (quotationVendorName && quotationVendorMobile) {
      setVendorMobile(quotationVendorName + " : " + quotationVendorMobile);
    }
  }, [quotationVendorName, quotationVendorMobile]);

  useEffect(() => {
    (async () => {
      if (draft) {
        setDraftId(draft.draftId);
        setOrderType(draft.orderType);
        setTempStateForTesting(JSON.parse(draft.selectedItems));
        setAddedItems(JSON.parse(draft.addedItems));
        setActiveCategory(draft.activeCategory);
        setContactPersonName(draft.contactPersonName);
        setContactPersonNumber(draft.contactPersonNumber);
        setSiteEngineerDetails((prev) => ({
          ...prev,
          siteEngineerName: draft.siteEngineerName,
          siteEngineerNumber: draft.siteEngineerNumber,
        }));
        setDiscount(draft.discount);
        setStartDate(draft.startDate);
        setEndDate(draft.endDate);
        setVendorMobile(draft.vendorMobile);
        setVendorFirm(draft.vendorFirm);
        setVendorGSTIN(draft.vendorGSTIN || "");
        setVendorAddress(draft.vendorAddress);
        setVendorPinCode(draft.vendorPinCode);
        setVendorCity(draft.vendorCity);
        setVendorState(draft.vendorState);
        setTermsAndConditions(draft.termsAndConditions);
        setPaymentTerms(draft.paymentTerms);
        setSpecialInstructions(draft.specialInstructions);
        setPriceBase(draft.priceBase);
        setFreight(draft.freight);
        setInsurance(draft.insurance);
        setShippingAddress(draft.shippingAddress);
        setBillingAddress(draft.billingAddress);
        setAttachments(JSON.parse(draft.attachments));
        setDefaultMilestones(
          JSON.parse(draft.commonMilestones) &&
          JSON.parse(draft.commonMilestones)
        );
      }
      if (FromReleasedItem) {
        setIsUpdatingProgress(true);
        let poresponse = await poService.getPoByOrderId(
          releasedOrderDataFromReleased.OrderId
        );

        setPoresponse(poresponse);

        if (poresponse.status === 200) {
          setOrderType(poresponse.payload[0].orderType);
          setTempStateForTesting(
            JSON.parse(releasedOrderDataFromReleased.Data).data
          );
          setAddedItems([]);
          setContactPersonName(poresponse.payload[0].projectContactPerson);
          setContactPersonNumber(poresponse.payload[0].projectContactNumber);
          setSiteEngineerDetails((prev) => ({
            ...prev,
            siteEngineerName: poresponse.payload[0].siteEngineerName,
            siteEngineerNumber: poresponse.payload[0].siteEngineerMobile,
          }));
          setDiscount(poresponse.payload[0].discount);
          setStartDate(poresponse.payload[0].startDate);
          setEndDate(poresponse.payload[0].endDate);
          setVendorMobile(poresponse.payload[0].vendorMobile);
          setVendorFirm(poresponse.payload[0].vendorFirm);
          setVendorGSTIN(poresponse.payload[0].vendorGSTIN || "");
          setVendorAddress(poresponse.payload[0].vendorAddress);
          setVendorPinCode(poresponse.payload[0].vendorPinCode);
          setVendorCity(poresponse.payload[0].vendorCity);
          setVendorState(poresponse.payload[0].vendorState);
          setTermsAndConditions(poresponse.payload[0].termsAndCondition);
          setPaymentTerms(poresponse.payload[0].paymentTerms);
          setSpecialInstructions(poresponse.payload[0].specialInstructions);
          setPriceBase(poresponse.payload[0].priceBase);
          setFreight(JSON.parse(poresponse.payload[0].freight));
          setInsurance(poresponse.payload[0].insurance);
          setShippingAddress(poresponse.payload[0].shipToAddress);
          setBillingAddress(poresponse.payload[0].billToAddress);
          setAttachments(JSON.parse(poresponse.payload[0].attachments));
          setOlderVersionofOrderData(
            (releasedOrderDataFromReleased.OlderVersions &&
              JSON.parse(releasedOrderDataFromReleased.OlderVersions)) ||
            []
          );

          setTotalPaidAmountReleasedOrder(() => {
            let tempOrderMilestones = JSON.parse(
              releasedOrderDataFromReleased.CommonMilestones
            );

            let total_Paid = Number(
              tempOrderMilestones.reduce((mTotalPaid, mItem) => {
                return (mTotalPaid =
                  mTotalPaid +
                  (mItem.name !== "Paid" && "payments" in mItem
                    ? mItem.payments.reduce(
                      (total, item) => (total = total + Number(item.amount)),
                      0
                    )
                    : 0.0));
              }, 0)
            );

            total_Paid =
              tempOrderMilestones[0].name === "Paid"
                ? Number(
                  total_Paid + tempOrderMilestones[0].totalAmount
                ).toFixed(2)
                : Number(total_Paid + 0).toFixed(2);
            return { isChanged: false, value: total_Paid };
          });
        }
        setIsUpdatingProgress(false);
      }
    })();
  }, [draft]);

  useEffect(() => {
    getSuggestiveTextFirm();
  }, []);

  const checkFreightExists = async (orderId, freightChargesIndex) => {
    try {
      const dbRef = firebase.database().ref(`freightUpdate/${orderId}`);
      const snapshot = await dbRef.once('value');

      if (snapshot.exists()) {
        const data = snapshot.val();
        setFreightExists(true);
        setFreightItemIndex(data.index);
        await dbRef.update({ exists: true });
        return data.index;
      }
    } catch (error) {
      return null;
    }
  };

  const createNewFreightEntry = async (orderId, freightChargesIndex) => {
    try {
      const initialData = { index: freightChargesIndex };
      const newRef = firebase.database().ref(`freightUpdate/${orderId}`);
      await newRef.set(initialData);
      return initialData.index;
    } catch (error) {
      return null;
    }
  };

  const updateFreightRate = async (rate, freightChargesIndex) => {
    try {
      const boqRef = firebase.database().ref(`liveBOQ/${data.ProjectId.toString()}/rooms`);
      const boqSnapshot = await boqRef.once('value');
      const roomsJson = boqSnapshot.val();

      let rooms;
      if (typeof roomsJson === 'string') {
        rooms = JSON.parse(roomsJson);
      } else {
        rooms = roomsJson;
      }

      const nonTenderRoom = rooms.find(room => room["Room Name"] === "Non tender items");
      if (nonTenderRoom) {
        const freightUnit = nonTenderRoom.Units.find(unit => unit["Unit Name"] === "Freight Charges");


        if (freightUnit && freightUnit.Components) {
          const freightComponent = freightUnit.Components[0];
          if (freightComponent && freightComponent?.Material) {
            const freightItemIndex = freightComponent?.Material.findIndex((item, index) => index === freightChargesIndex.freightChargesIndex);
            if (freightItemIndex !== -1) {
              if (freightComponent && freightComponent?.Material && freightComponent?.Material[freightItemIndex]) {
                freightComponent.Material[freightItemIndex].rate = String(rate);
              }
              await boqRef.set(JSON.stringify(rooms));
            }
          }

          if (freightComponent && freightComponent?.Work) {
            const freightItemIndexWork = freightComponent?.Work.findIndex((item, index) => index === freightChargesIndex.freightChargesIndex);
            if (freightItemIndexWork !== -1) {
              if (freightComponent && freightComponent?.Work && freightComponent?.Work[freightItemIndexWork]) {
                freightComponent.Work[freightItemIndexWork].rate = String(rate);
              }
              await boqRef.set(JSON.stringify(rooms));
            }
          }
        }
      }
    } catch (error) {
    }
  };

  const handleFileUpload = async (files) => {
    for (let i = 0; i < files.length; i++) {
      toast.success("Uploading attachment. Please wait.", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#1F9A5E",
          backgroundColor: "#81B29A",
          maxWidth: "100%",
        },
        icon: <AiFillCheckCircle color={"#1F9A5E"} />,
      });
      const response = await firebase
        .storage()
        .ref("/WebUploads/" + new Date().getTime() + files[i].name)
        .put(files[i]);
      response.ref.getDownloadURL().then((url) => {
        let cAttachments = [...attachments];
        cAttachments.push({ name: files[i].name, url: url });
        setAttachments(cAttachments);
      });
    }
  };

  const handleDeleteAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ/" + data.ProjectId.toString())
      .set({ ...payload, rooms: JSON.stringify(payload.rooms) });

  const findMilestonesFromDraft = (array) => {
    if (array.length == 0) return null;
    var modeMap = {};
    var maxEl = array[0],
      maxCount = 1;
    for (var i = 0; i < array.length; i++) {
      var el = array[i];
      if (modeMap[el] == null) modeMap[el] = 1;
      else modeMap[el]++;
      if (modeMap[el] > maxCount) {
        maxEl = el;
        maxCount = modeMap[el];
      }
    }
    return maxEl;
  };

  const checkMilestonesToBeEqaul = (obj1, obj2) => {
    let tempComp = true;
    if (obj1.length == obj2.length) {
      for (let key = 0; key < obj1.length; key++) {
        if (
          obj1[key].name == obj2[key].name &&
          obj1[key].percentage == obj2[key].percentage
        ) {
        } else {
          tempComp = false;
        }
      }
    } else {
      tempComp = false;
    }
    return tempComp;
  };

  const getBankAccountDetails = (Mobile) => {
    const Token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ";
    fetch(`${config.profileService}bankAccount/${Mobile}`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: "Bearer " + Token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.payload.length != 0) {
          setBankAccountDetails(JSON.stringify(json.payload[0]));
        }
      })
      .catch((e) => { });
  };

  const getVendors = () => {
    analyticsService
      .getFirmBasedAnalytics(localStorage.getItem("firm"))
      .then((res) => {
        if (res.status === 200) {
          setVendorDetails(res.Vendor);
          prepareTheListOfAllVendors(res.Vendor);
          let vendorsList = res.Vendor.filter((item) =>
            activeCategory.includes(
              item.Category.replace('("', "")
                .replace(/"/g, "")
                .replace(', "', ", ")
                .replace(")", "")
            )
          )
            .map((item) => item.Name + " : " + item.Mobile)
            .sort((a, b) => a.localeCompare(b));
          vendorsList.unshift("Open Vendor");
          vendorsList.unshift("Show All Vendors");
          setVendors(vendorsList);
        }
      })
      .catch((e) => { });
  };

  const calculateDiscountedAmount = (lineItem) => {
    let discountedRate = (
      lineItem.rate -
      (lineItem.rate * discount) / 100
    ).toFixed(2);
    return discountedRate;
  };

  const getProfitability = (component) => {
    const differ = getDifference(
      Number(component["quantity"] || 0) * Number(component["rate"] || 0),
      component.Work.reduce(
        (total, item) =>
          Number(total) +
          (Number(
            (item?.discountedRate && item.discountedRate !== "null"
              ? item.discountedRate
              : item.rate == "quotation"
                ? 0
                : item.rate) || 0
          ) *
            Number(item.quantity || 0) +
            Number(
              (item?.discountedRate && item?.discountedRate !== "null"
                ? item.discountedRate
                : item.rate == "quotation"
                  ? 0
                  : item.rate) || 0
            ) *
            Number(item.quantity || 0) *
            (Number(item.gst || 0) / 100)),
        0
      ) +
      component.Material.reduce(
        (total, item) =>
          Number(total) +
          (Number(
            (item?.discountedRate && item?.discountedRate !== "null"
              ? item.discountedRate
              : item.rate == "quotation"
                ? 0
                : item.rate) || 0
          ) *
            Number(item.quantity || 0) +
            Number(
              (item?.discountedRate && item.discountedRate !== "null"
                ? item.discountedRate
                : item.rate == "quotation"
                  ? 0
                  : item.rate) || 0
            ) *
            Number(item.quantity || 0) *
            (Number(item.gst || 0) / 100)),
        0
      )
    );
    return differ;
  };

  // const editingTheBOQPage = () => {
  //   const workAndMaterials = [...selectedItems];

  //   let tempWorks = [];
  //   let tempMaterials = [];
  //   let newRivision = [...revision];


  //   for (const [index, item] of workAndMaterials.entries()) {
  //     let cWork = item;

  //     if (item["Unit Name"] === "Freight Charges") {
  //       continue;
  //     }

  //     let cRoom =
  //       item["Unit Name"] === "Extra items"
  //         ? newRivision[newRivision.length - 1]["Room Name"] === "Non tender items"
  //           ? newRivision[newRivision.length - 1]
  //           : undefined
  //         : newRivision[cWork.indexces.roomIndex];

  //     if (!cRoom) {
  //       continue;
  //     }

  //     let Units = cRoom.Units;
  //     let cUnit = Units[cWork.indexces.unitIndex];
  //     let Components = cUnit.Components;
  //     let cComponent = Components[cWork.indexces.componentIndex];


  //     if (!cComponent) {
  //       continue;
  //     }

  //     if ("vendorCategory" in cWork) {
  //       let Work = [...cComponent.Work];
  //       Work.splice(cWork.indexces.workIndex, 1, {
  //         ...cWork,
  //         status: isApproval ? "inApproval" : "ordered",
  //         discountedRate:
  //           discount > 0 ? calculateDiscountedAmount(cWork) : "null",
  //       });


  //       cComponent = { ...cComponent, Work };
  //       const profitabilityOfComponent = getProfitability(cComponent);
  //       tempWorks.push({
  //         ...cWork,
  //         profitabilityOfComponent,
  //       });
  //     }
  //     else {
  //       let Material = [...cComponent.Material];
  //       Material.splice(cWork.indexces.materialIndex, 1, {
  //         ...cWork,
  //         status: isApproval ? "inApproval" : "ordered",
  //         discountedRate:
  //           discount > 0 ? calculateDiscountedAmount(cWork) : "null",
  //       });
  //       cComponent = { ...cComponent, Material };
  //       const profitabilityOfComponent = getProfitability(cComponent);
  //       tempMaterials.push({
  //         ...cWork,
  //         profitabilityOfComponent,
  //       });
  //     }

  //     Components.splice(cWork.indexces.componentIndex, 1, cComponent);
  //     cUnit = { ...cUnit, Components };
  //     Units.splice(cWork.indexces.unitIndex, 1, cUnit);
  //     cRoom = { ...cRoom, Units };
  //     newRivision.splice(cWork.indexces.roomIndex, 1, cRoom);
  //     setRevision(newRivision);
  //   }

  //   const addedWorkItems = new Set();
  //   const addedMaterialItems = new Set();

  //   addedItems.forEach((item) => {
  //     if (item["UnitName"] === "Freight Charges") {
  //       return;
  //     }

  //     if (item.workType === "Only Material") {
  //       const materialKey = `${item["UnitName"]}-${item.indexces.materialIndex}`;
  //       if (!addedMaterialItems.has(materialKey)) {
  //         tempMaterials.push({ ...item });
  //         addedMaterialItems.add(materialKey);
  //       }
  //     } else {
  //       const workKey = `${item["UnitName"]}-${item.indexces.workIndex}`;
  //       if (!addedWorkItems.has(workKey)) {
  //         tempWorks.push({ ...item });
  //         addedWorkItems.add(workKey);
  //       }
  //     }
  //   });

  //   const removeDuplicates = (items, keyGenerator) => {
  //     const itemMap = new Map();
  //     const duplicatesToRemove = new Set();

  //     items.forEach(item => {
  //       const key = keyGenerator(item);
  //       if (itemMap.has(key)) {
  //         duplicatesToRemove.add(key);
  //       } else {
  //         itemMap.set(key, item);
  //       }
  //     });

  //     const result = [];
  //     const seen = new Set();

  //     items.forEach(item => {
  //       const key = keyGenerator(item);
  //       if (duplicatesToRemove.has(key) && !seen.has(key)) {
  //         seen.add(key);
  //       } else {
  //         result.push(item);
  //       }
  //     });

  //     return result;
  //   };

  //   tempWorks = removeDuplicates(tempWorks, (obj) => `${obj["UnitName"]}-${obj.indexces.workIndex}`);
  //   tempMaterials = removeDuplicates(tempMaterials, (obj) => `${obj["UnitName"]}-${obj.indexces.materialIndex}`);

  //   return [tempWorks, tempMaterials];
  // };


  // const editingTheBOQPage = () => {
  //   const workAndMaterials = [...selectedItems];

  //   let tempWorks = [];
  //   let tempMaterials = [];
  //   let newRevision = [...revision];

  //   for (const item of workAndMaterials) {
  //     let cWork = item;

  //     if (item["Unit Name"] === "Freight Charges") {
  //       continue;
  //     }

  //     let cRoom;
  //     if (item["Unit Name"] === "Extra items") {
  //       cRoom = newRevision[newRevision.length - 1]["Room Name"] === "Non tender items"
  //         ? newRevision[newRevision.length - 1]
  //         : undefined;
  //     } else {
  //       cRoom = newRevision[cWork.indexces.roomIndex];
  //     }

  //     if (!cRoom) {
  //       continue;
  //     }

  //     let Units = cRoom.Units;
  //     let cUnit = Units[cWork.indexces.unitIndex];
  //     let Components = cUnit.Components;
  //     let cComponent = Components[cWork.indexces.componentIndex];

  //     if (!cComponent) {
  //       continue;
  //     }

  //     if ("vendorCategory" in cWork) {
  //       let Work = [...cComponent.Work];
  //       Work.splice(cWork.indexces.workIndex, 1, {
  //         ...cWork,
  //         status: isApproval ? "inApproval" : "ordered",
  //         discountedRate: discount > 0 ? calculateDiscountedAmount(cWork) : "null",
  //       });

  //       cComponent = { ...cComponent, Work };
  //       const profitabilityOfComponent = getProfitability(cComponent);
  //       tempWorks.push({
  //         ...cWork,
  //         profitabilityOfComponent,
  //       });
  //     } else {
  //       let Material = [...cComponent.Material];
  //       Material.splice(cWork.indexces.materialIndex, 1, {
  //         ...cWork,
  //         status: isApproval ? "inApproval" : "ordered",
  //         discountedRate: discount > 0 ? calculateDiscountedAmount(cWork) : "null",
  //       });

  //       cComponent = { ...cComponent, Material };
  //       const profitabilityOfComponent = getProfitability(cComponent);
  //       tempMaterials.push({
  //         ...cWork,
  //         profitabilityOfComponent,
  //       });
  //     }

  //     Components.splice(cWork.indexces.componentIndex, 1, cComponent);
  //     cUnit = { ...cUnit, Components };
  //     Units.splice(cWork.indexces.unitIndex, 1, cUnit);
  //     cRoom = { ...cRoom, Units };
  //     newRevision.splice(cWork.indexces.roomIndex, 1, cRoom);
  //     setRevision(newRevision);
  //   }

  //   const addedWorkItems = new Set();
  //   const addedMaterialItems = new Set();

  //   addedItems.forEach((item) => {
  //     if (item["UnitName"] === "Freight Charges") {
  //       return;
  //     }

  //     if (item.workType === "Only Material") {
  //       const materialKey = `${item["UnitName"]}-${item.indexces.materialIndex}`;
  //       if (!addedMaterialItems.has(materialKey)) {
  //         tempMaterials.push({ ...item });
  //         addedMaterialItems.add(materialKey);
  //       }
  //     } else {
  //       const workKey = `${item["UnitName"]}-${item.indexces.workIndex}`;
  //       if (!addedWorkItems.has(workKey)) {
  //         tempWorks.push({ ...item });
  //         addedWorkItems.add(workKey);
  //       }
  //     }
  //   });

  //   const removeDuplicates = (items, keyGenerator) => {
  //     const itemMap = new Map();
  //     const duplicatesToRemove = new Set();

  //     items.forEach(item => {
  //       const key = keyGenerator(item);
  //       if (itemMap.has(key)) {
  //         duplicatesToRemove.add(key);
  //       } else {
  //         itemMap.set(key, item);
  //       }
  //     });

  //     const result = [];
  //     const seen = new Set();

  //     items.forEach(item => {
  //       const key = keyGenerator(item);
  //       if (duplicatesToRemove.has(key) && !seen.has(key)) {
  //         seen.add(key);
  //       } else {
  //         result.push(item);
  //       }
  //     });

  //     return result;
  //   };

  //   tempWorks = removeDuplicates(tempWorks, (obj) => `${obj.description || obj.specification}-${obj.quantity}-${obj.rate}-${obj.unit}`);
  //   tempMaterials = removeDuplicates(tempMaterials, (obj) => `${obj.description || obj.specification}-${obj.quantity}-${obj.rate}-${obj.unit}`);


  //   return [tempWorks, tempMaterials];
  // };

  const editingTheBOQPage = () => {

    const workAndMaterials = [...selectedItems];
    console.log("work and material", workAndMaterials)
    let tempWorks = [];
    let tempMaterials = [];
    console.log("revision is ", revision)
    let newRevision = [...revision];

    for (const item of workAndMaterials) {
      let cWork = item;

      if (item["Unit Name"] === "Freight Charges") {
        continue;
      }


      let cRoom;
      if (item["Unit Name"] === "Extra items") {
        cRoom = newRevision[newRevision.length - 1]["Room Name"] === "Non tender items"
          ? newRevision[newRevision.length - 1]
          : undefined;
      } else {
        cRoom = newRevision[cWork.indexces.roomIndex];
      }

      console.log("c rom  value si", cRoom)

      if (!cRoom) {
        continue;
      }

      let Units = cRoom.Units;
      let cUnit = Units[cWork.indexces.unitIndex];
      let Components = cUnit.Components;
      let cComponent = Components[cWork.indexces.componentIndex];

      if (!cComponent) {
        continue;
      }

      if ("vendorCategory" in cWork) {
        let Work = [...cComponent.Work];
        Work.splice(cWork.indexces.workIndex, 1, {
          ...cWork,
          status: isApproval ? "inApproval" : "ordered",
          discountedRate: discount > 0 ? calculateDiscountedAmount(cWork) : "null",
        });

        cComponent = { ...cComponent, Work };
        const profitabilityOfComponent = getProfitability(cComponent);
        tempWorks.push({
          ...cWork,
          profitabilityOfComponent,
        });
      } else {
        let Material = [...cComponent.Material];
        Material.splice(cWork.indexces.materialIndex, 1, {
          ...cWork,
          status: isApproval ? "inApproval" : "ordered",
          discountedRate: discount > 0 ? calculateDiscountedAmount(cWork) : "null",
        });

        cComponent = { ...cComponent, Material };
        const profitabilityOfComponent = getProfitability(cComponent);
        tempMaterials.push({
          ...cWork,
          profitabilityOfComponent,
        });
      }

      Components.splice(cWork.indexces.componentIndex, 1, cComponent);
      cUnit = { ...cUnit, Components };
      Units.splice(cWork.indexces.unitIndex, 1, cUnit);
      cRoom = { ...cRoom, Units };
      newRevision.splice(cWork.indexces.roomIndex, 1, cRoom);
      setRevision(newRevision);
    }

    const addedWorkItems = new Set();
    const addedMaterialItems = new Set();

    addedItems.forEach((item) => {
      if (item["UnitName"] === "Freight Charges") {
        return;
      }

      if (item.workType === "Only Material") {
        const materialKey = `${item["UnitName"]}-${item.indexces.materialIndex}`;
        if (!addedMaterialItems.has(materialKey)) {
          tempMaterials.push({ ...item });
          addedMaterialItems.add(materialKey);
        }
      } else {

        const workKey = `${item["UnitName"]}-${item.indexces.workIndex}`;
        if (!addedWorkItems.has(workKey)) {
          tempWorks.push({ ...item });
          addedWorkItems.add(workKey);
        }
      }
    });

    const removeDuplicates = (items, keyGenerator) => {

      const itemMap = new Map();

      items.forEach(item => {
        const key = keyGenerator(item);
        itemMap.set(key, item);
      });

      return Array.from(itemMap.values());
    };

    tempWorks = removeDuplicates(tempWorks, (obj) => `${obj.description}-${obj.quantity}-${obj.rate}-${obj.unit}`);
    tempMaterials = removeDuplicates(tempMaterials, (obj) => `${obj.specification}-${obj.quantity}-${obj.rate}-${obj.unit}`);

    return [tempWorks, tempMaterials];
  };


  const makeOpportunity = async () => {
    if (
      selectedItems.length > 0 &&
      contactPersonName &&
      contactPersonNumber &&
      siteEngineerDetails.siteEngineerName &&
      siteEngineerDetails.siteEngineerNumber &&
      shippingAddress &&
      billingAddress
    ) {
      console.log("selected itemd", selectedItems)
      const [tempWorks, tempMaterials] = editingTheBOQPage();
      const opportunityService = new OpportunityService();
      const notificationService = new NotificationService();

      let errorFound = "";

      let UniqueIdtoOrderandPO;
      console.log('freightDescription tempWorks.length : ', tempWorks.length)
      if (tempWorks.length > 0) {
        UniqueIdtoOrderandPO = new Date().getTime();

        const response = await opportunityService.add(
          tempWorks,
          project.clientName ? project.clientName : project.ClientName,
          data.Address,
          data.AmountRecieved,
          data.ProjectId,
          startDate,
          endDate,
          "work",
          vendorMobile == null ||
            vendorMobile == "null" ||
            vendorMobile == "Open Vendor"
            ? "Open Vendor"
            : vendorMobile.split(":")[1].trim(),
          activeCategory,
          isApproval,
          { raised: userContext.user.email, sendTo: "" },
          userContext.user.Firm,
          Number(totalAmount),
          defaultMilestones,
          vendorCompanyName,
          UniqueIdtoOrderandPO
        );
        console.log('freight desc : ', response)
        if (response.status !== 200) {
          errorFound = "Error while making opportunity!";
        }
      } else {
      }

      if (tempMaterials.length > 0) {
        UniqueIdtoOrderandPO = new Date().getTime();

        const response = await opportunityService.add(
          tempMaterials,
          project.clientName ? project.clientName : project.ClientName,
          data.Address,
          data.AmountRecieved,
          data.ProjectId,
          startDate,
          endDate,
          "material",
          vendorMobile == null ||
            vendorMobile == "null" ||
            vendorMobile == "Open Vendor"
            ? "Open Vendor"
            : vendorMobile.split(":")[1].trim(),
          activeCategory,
          isApproval,
          { raised: userContext.user.email, sendTo: "" },
          userContext.user.Firm,
          Number(totalAmount),
          defaultMilestones,
          vendorCompanyName,
          UniqueIdtoOrderandPO
        );

        if (response.status !== 200) {
          errorFound = "Error while making opportunity!";
        }
      }

      if (tempWorks.length > 0 || tempMaterials.length > 0) {
        if (errorFound.length > 0) {
          toast.error(errorFound, {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#E72424",
              backgroundColor: "#FEF0F0 ",
              maxWidth: "100%",
            },
            icon: <FiInfo color={"#E72424"} />,
          });
        } else {
          setIsSuccessPageOpen(true);
          setIsUpdatingProgress(false);

          if (quotationId) {
            deleteQuotation();
          }

          if (draftId && !FromReleasedItem) {
            deleteDraft();
          }

          let categoryType;

          if (tempWorks.length > 0) {
            categoryType = "workCategory";
          } else {
            categoryType = "materialCategory";
          }

          notificationService.sendNotificationsOnCategory(
            activeCategory,
            categoryType
          );

          await releasePO(UniqueIdtoOrderandPO, tempWorks, tempMaterials);

          notificationService.notifySEOnOrderRelease(
            project,
            siteEngineerDetails,
            activeCategory,
            orderType
          );

          saveWorkButtonClicked(
            { ...data, rooms: revision },
            undefined,
            updateFBRDB
          );

          updatePOImagesFirebase();

          record("@release_order_button_click_success", {
            description: "Release order button click success",
          });
        }
      }
    } else {
      if (!contactPersonName) {
        contactPersonNameRef.current.focus();
      } else if (!contactPersonNumber) {
        contactPersonNumberRef.current.focus();
      } else if (!siteEngineerDetails.siteEngineerName) {
        siteEngineerNameRef.current.focus();
      } else if (!siteEngineerDetails.siteEngineerNumber) {
        siteEngineerNumberRef.current.focus();
      } else if (!shippingAddress) {
        shippingAddressRef.current.focus();
      } else if (!billingAddress) {
        billingAddressRef.current.focus();
      }

      setIsUpdatingProgress(false);

      toast.error("Mandatory fields incomplete!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
      record("@release_order_button_click_error", {
        description: "Release order button click error",
      });
    }
  };

  const sendSignatureMessage = async (to) => {
    try {
      const response = await (
        await fetch(`${config.whatsAppService.URL}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${config.whatsAppService.AuthKey}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            messaging_product: "whatsapp",
            to: to,
            type: "template",
            template: {
              name: "vendor_sign_po",
              language: {
                code: "en",
              },
            },
          }),
        })
      ).json();
      return response;
    } catch (e) {
      return { status: 400, statusMsg: e };
    }
  };

  const releasePO = async (orderId, tempWorks, tempMaterials) => {
    const response = await poService.releasePO(
      new Date().getTime(),
      data.ProjectId,
      new Date(),
      contactPersonName,
      contactPersonNumber,
      siteEngineerDetails.siteEngineerName,
      siteEngineerDetails.siteEngineerNumber,
      tempWorks.length > 0 ? tempWorks : tempMaterials, //selected Items
      defaultMilestones,
      termsAndConditions,
      shippingAddress,
      billingAddress,
      specialInstructions,
      discount,
      totalAmount,
      orderId,
      startDate,
      endDate,
      firmGSTIN,
      sessionStorage.getItem("firmLogoURL"), //firmLogoURL
      firmSignature,
      firmName,
      firmAddress,
      firmPhoneNumber,
      firmContactPersonName,
      firmContactPersonNumber,
      firmContactPersonEmail,
      vendorMobile,
      vendorCompanyName, // vendorFirm
      vendorAddress,
      vendorPinCode,
      vendorCity,
      vendorState,
      vendorGSTIN,
      priceBase,
      JSON.stringify(freight),
      insurance,
      paymentTerms,
      orderType,
      attachments,
      bankAccountDetails
    );

    if (response.status === 200) {
      const smsService = new SmsService();
      if (
        vendorMobile != null &&
        vendorMobile != "null" &&
        vendorMobile != "Open Vendor" &&
        !isApproval
      ) {

        await smsService.sendWhatsapp({
          to: `91${vendorMobile.split(":")[1].trim()}`,
          firm: userContext.user.Firm,
          orderType: orderType,
          category: activeCategory,
          link:
            environment.environment == "staging"
              ? `https://staartstaging.netlify.app/?po_id=${orderId}`
              : `https://staartbuild.netlify.app/?po_id=${orderId}`,
        });

        await sendSignatureMessage(`91${vendorMobile.split(":")[1].trim()}`);

      }
      await poservicefirebse.sendPOtoFirebase(
        data.ProjectId,
        orderId,
        selectedItems
      );
    } else {
      toast.error(`Error releasing ${orderType}!`, {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
    }
    backtrackBOQ(orderId);
  };

  const makeQuotation = async () => {
    if (selectedItems.length > 0) {
      const workAndMaterials = selectedItems;
      let tempWorks = [];
      let tempMaterials = [];
      let newRivision = [...revision];

      workAndMaterials.forEach((item, index) => {
        if (addedItems.length > 0 && item["Unit Name"] == "Extra items") {
          if (item.workType == "Only Material") {
            const materialObject = { ...item, rate: "quotation" };
            tempMaterials.push(materialObject);
          } else {
            const workObject = { ...item, rate: "quotation" };
            tempWorks.push(workObject);
          }
        } else {
          let cWork = workAndMaterials[index];
          let cRoom = newRivision[cWork.indexces.roomIndex];
          let Units = cRoom.Units;
          let cUnit = Units[cWork.indexces.unitIndex];
          let Components = cUnit.Components;
          let cComponent = Components[cWork.indexces.componentIndex];
          if ("vendorCategory" in workAndMaterials[index]) {
            let Work = [...cComponent.Work];
            Work.splice(cWork.indexces.workIndex, 1, {
              ...cWork,
              rate: "quotation",
            });
            cComponent = { ...cComponent, Work };
            tempWorks.push(workAndMaterials[index]);
          } else {
            let Material = [...cComponent.Material];
            Material.splice(cWork.indexces.materialIndex, 1, {
              ...cWork,
              rate: "quotation",
            });
            cComponent = { ...cComponent, Material };
            tempMaterials.push(workAndMaterials[index]);
          }
          Components.splice(cWork.indexces.componentIndex, 1, cComponent);
          cUnit = { ...cUnit, Components };
          Units.splice(cWork.indexces.unitIndex, 1, cUnit);
          cRoom = { ...cRoom, Units };
          newRivision.splice(cWork.indexces.roomIndex, 1, cRoom);
          setRevision(newRivision);
        }
      });

      const quotationService = new QuotationService();

      let errorFound = "";
      if (tempWorks.length > 0) {
        const response = await quotationService.add(
          tempWorks,
          project.clientName ? project.clientName : project.ClientName,
          data.Address,
          data.AmountRecieved,
          data.ProjectId,
          startDate,
          endDate,
          "work",
          vendorMobile == null ||
            vendorMobile == "null" ||
            vendorMobile == "Open Vendor"
            ? "Open Vendor"
            : vendorMobile.split(":")[1].trim()
        );
        if (response.status !== 200) {
          errorFound = "Error while releasing quotation!";
        }
      }

      if (tempMaterials.length > 0) {
        const response = await quotationService.add(
          tempMaterials,
          // data.ClientName,
          project.clientName ? project.clientName : project.ClientName,
          data.Address,
          data.AmountRecieved,
          data.ProjectId,
          startDate,
          endDate,
          "material",
          vendorMobile == null ||
            vendorMobile == "null" ||
            vendorMobile == "Open Vendor"
            ? "Open Vendor"
            : vendorMobile.split(":")[1].trim()
        );
        if (response.status !== 200) {
          errorFound = "Error while releasing quotation!";
        }
      }

      if (tempWorks.length > 0 || tempMaterials.length > 0) {
        if (errorFound.length > 0) {
          alert(errorFound);
        } else {
          toast.success(
            `Quotation for ${activeCategory} category with ${selectedItems.length} items released.`,
            {
              duration: 4000,
              position: "bottom-center",
              style: {
                borderRadius: "8px",
                padding: "16px",
                color: "#F4696C",
                backgroundColor: "#FFF0F0",
                maxWidth: "100%",
              },
              icon: <HiOutlineCurrencyRupee color={"#F4696C"} />,
            }
          );
          if (draftId) {
            deleteDraft();
          }
          saveWorkButtonClicked(
            { ...data, rooms: newRivision },
            undefined,
            updateFBRDB
          );
          history.push("/Timeline/" + data.ProjectId, { data: data });
          record("@release_quotation_button_click_success", {
            description: "Release quotation button click success",
          });
        }
      }
    } else {
      toast.error("Please add an item to proceed.", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
      record("@release_quotation_button_click_error", {
        description: "Release quotation button click error",
      });
    }
  };

  const deleteQuotation = async () => {
    const quotationService = new QuotationService();
    const response = await quotationService.delete(quotationId);
    if (response.status != 200) {
      toast.error("Error while deleting quotation!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
    }
  };

  const backtrackBOQ = async (orderId) => {
    if (addedItems.length > 0) {
      delete data.AmountRecieved;
      let worksToBeBacktracked = [];
      let materialsToBeBacktracked = [];
      let freightChargesIndex = null;

      let freightItemsWork = [
        ...(data.rooms[data.rooms.length - 1]?.Units.find(
          (unit) => unit["Unit Name"] === "Freight Charges"
        )?.Components[0]?.Work || []),
      ];

      let freightItemsMaterial = [
        ...(data.rooms[data.rooms.length - 1]?.Units.find(
          (unit) => unit["Unit Name"] === "Freight Charges"
        )?.Components[0]?.Material || []),
      ];

      addedItems.forEach((item) => {
        if (item.UnitName === "Freight Charges") {
          const freightObject = { ...item };

          if (item.workType === "Only Work") {
            freightItemsWork.push(freightObject);
            freightChargesIndex = freightItemsWork.length - 1;
          } else if (item.workType === "Only Material") {
            freightItemsMaterial.push(freightObject);
            freightChargesIndex = freightItemsMaterial.length - 1;
          }
        } else {
          if (item.workType === "Only Material") {
            const materialObject = {
              ...item,
              status: isApproval ? "inApproval" : "ordered",
              discountedRate:
                discount > 0 ? calculateDiscountedAmount(item) : "null",
            };
            materialsToBeBacktracked.push(materialObject);
          } else {
            const workObject = {
              ...item,
              status: isApproval ? "inApproval" : "ordered",
              discountedRate:
                discount > 0 ? calculateDiscountedAmount(item) : "null",
            };
            worksToBeBacktracked.push(workObject);
          }
        }
      });

      createNewFreightEntry(orderId, { freightChargesIndex });

      const updateFreightCharges = (components) => {
        return components.map((component) => {
          if (component.description === "charges") {
            return {
              ...component,
              rate: "0",
              Work: freightItemsWork,
              Material: freightItemsMaterial,
            };
          }
          return component;
        });
      };

      const rooms = data.rooms;
      const roomIndex = rooms.length - 1;

      if (rooms[roomIndex]["Room Name"] === "Non tender items") {
        const unitIndex = rooms[roomIndex].Units.findIndex(
          (unit) => unit["Unit Name"] === "Freight Charges"
        );

        if (unitIndex !== -1) {
          rooms[roomIndex].Units[unitIndex].Components = updateFreightCharges(
            rooms[roomIndex].Units[unitIndex].Components
          );
        } else {
          rooms[roomIndex].Units.push({
            "Unit Name": "Freight Charges",
            Drawings: [],
            Components: [
              {
                description: "charges",
                quantity: "1",
                rate: "0",
                unit: "unit",
                Work: freightItemsWork,
                Material: freightItemsMaterial,
                Remarks: "",
              },
            ],
          });
        }

        const extraUnitIndex = rooms[roomIndex].Units.findIndex(
          (unit) => unit["Unit Name"] === "Extra items"
        );
        console.log(extraUnitIndex, "extraUnitIndex")

        if (extraUnitIndex !== -1) {
          console.log("it is coming  in 1", rooms[roomIndex].Units[extraUnitIndex].Components)

          rooms[roomIndex].Units[extraUnitIndex].Components.push({
            description: "component",
            quantity: "1",
            rate: (
              worksToBeBacktracked.reduce(
                (total, item) =>
                  total +
                  Number(
                    item.quantity * item.rate +
                    item.quantity * item.rate * (item.gst / 100)
                  ),
                0
              ) +
              materialsToBeBacktracked.reduce(
                (total, item) =>
                  total +
                  Number(
                    item.quantity * item.rate +
                    item.quantity * item.rate * (item.gst / 100)
                  ),
                0
              )
            ).toString(),
            unit: "unit",
            Work: worksToBeBacktracked,
            Material: materialsToBeBacktracked,
            Remarks: "",
          });
        } else {
          console.log("it is coming  in 2", rooms[roomIndex].Units[extraUnitIndex].Components)

          rooms[roomIndex].Units[extraUnitIndex].Components.push({
            "Unit Name": "Extra items",
            Drawings: [],
            Components: [
              {
                description: "component",
                quantity: "1",
                rate: (
                  worksToBeBacktracked.reduce(
                    (total, item) =>
                      total +
                      Number(
                        item.quantity * item.rate +
                        item.quantity * item.rate * (item.gst / 100)
                      ),
                    0
                  ) +
                  materialsToBeBacktracked.reduce(
                    (total, item) =>
                      total +
                      Number(
                        item.quantity * item.rate +
                        item.quantity * item.rate * (item.gst / 100)
                      ),
                    0
                  )
                ).toString(),
                unit: "unit",
                Work: worksToBeBacktracked,
                Material: materialsToBeBacktracked,
                Remarks: "",
              },
            ],
          });
        }

        setRevision(data.rooms);
        await updateFBRDB({ ...data, rooms });

      }
      else {
        const newRoom = {
          "Room Name": "Non tender items",
          Units: [
            {
              "Unit Name": "Extra items",
              Drawings: [],
              Components: [
                ...(worksToBeBacktracked.length > 0 || materialsToBeBacktracked.length > 0
                  ? [
                    {
                      description: "component",
                      quantity: "1",
                      rate: (
                        worksToBeBacktracked.reduce(
                          (total, item) =>
                            total +
                            Number(
                              item.quantity * item.rate +
                              item.quantity * item.rate * (item.gst / 100)
                            ),
                          0
                        ) +
                        materialsToBeBacktracked.reduce(
                          (total, item) =>
                            total +
                            Number(
                              item.quantity * item.rate +
                              item.quantity * item.rate * (item.gst / 100)
                            ),
                          0
                        )
                      ).toString(),
                      unit: "unit",
                      Work: worksToBeBacktracked,
                      Material: materialsToBeBacktracked,
                      Remarks: "",
                    },
                  ]
                  : []
                ),
              ],
            },
            {
              "Unit Name": "Freight Charges",
              Drawings: [],
              Components: [
                {
                  description: "charges",
                  quantity: "1",
                  rate: "0",
                  unit: "unit",
                  Work: freightItemsWork,
                  Material: freightItemsMaterial,
                  Remarks: "",
                },
              ],
            },
          ],
        };


        rooms.push(newRoom);
        setRevision(data.rooms);
        await updateFBRDB({ ...data, rooms });
      }
    }
  };

  const getURLsOfImageForPO = async () => {
    let projectId = pathname.split("/")[2];
    var useRef = database.ref(`POImages/${projectId}`);
    useRef
      .once("value", function (snapshot) {
        var data = snapshot.val();
        setImagesFromFirebase(data);
      })
      .then((res) => {
        return res;
      });
  };

  const updateDraft = async () => {
    if (
      selectedItems.length > 0 &&
      contactPersonName &&
      contactPersonNumber &&
      siteEngineerDetails.siteEngineerName &&
      siteEngineerDetails.siteEngineerNumber &&
      shippingAddress &&
      billingAddress
    ) {
      const response = await draftService.updateDraft(
        data.ProjectId,
        draftId,
        orderType,
        JSON.stringify(selectedItems),
        JSON.stringify(defaultMilestones),
        JSON.stringify(addedItems),
        activeCategory,
        contactPersonName,
        contactPersonNumber,
        siteEngineerDetails.siteEngineerName,
        siteEngineerDetails.siteEngineerNumber,
        discount,
        startDate,
        endDate,
        vendorMobile,
        vendorFirm,
        vendorGSTIN,
        vendorAddress,
        vendorPinCode,
        vendorCity,
        vendorState,
        termsAndConditions,
        paymentTerms,
        specialInstructions,
        priceBase,
        freight,
        insurance,
        shippingAddress,
        billingAddress,
        JSON.stringify(attachments)
      );

      if (response.status === 200) {
        updatePOImagesFirebase();

        toast.success(
          `Draft for ${activeCategory} category with ${selectedItems.length} items updated.`,
          {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1A85B1",
              backgroundColor: "#F1FBFF",
              maxWidth: "100%",
            },
            icon: <CgFileDocument color={"#1A85B1"} size={20} />,
          }
        );

        history.push("/Timeline/" + data.ProjectId, { data: data });
        // record(TAGS["@update_draft_button_click_success", { description: "Update draft button click success" }])
        record("@update_draft_button_click_success", {
          description: "Update draft button click success",
        });
      } else {
        toast.error("Error updating draft!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
        record("@update_draft_button_click_error", {
          description: "Update draft button click error",
        });
      }
    } else {
      if (!contactPersonName) {
        contactPersonNameRef.current.focus();
      } else if (!contactPersonNumber) {
        contactPersonNumberRef.current.focus();
      } else if (!siteEngineerDetails.siteEngineerName) {
        siteEngineerNameRef.current.focus();
      } else if (!siteEngineerDetails.siteEngineerNumber) {
        siteEngineerNumberRef.current.focus();
      } else if (!shippingAddress) {
        shippingAddressRef.current.focus();
      } else if (!billingAddress) {
        billingAddressRef.current.focus();
      }

      toast.error("Mandatory fields incomplete!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
    }
  };

  const saveDraft = async () => {
    if (
      selectedItems.length > 0 &&
      contactPersonName &&
      contactPersonNumber &&
      siteEngineerDetails.siteEngineerName &&
      siteEngineerDetails.siteEngineerNumber &&
      shippingAddress &&
      billingAddress
    ) {
      const draftIdtobeUpdated = new Date().getTime();
      const workAndMaterials = selectedItems;
      let tempWorks = [];
      let tempMaterials = [];
      let newRivision = [...revision];

      workAndMaterials.forEach((item, index) => {
        if (addedItems.length > 0 && item["Unit Name"] === "Extra items") {
          if (item.workType == "Only Material") {
            const materialObject = {
              ...item,
              status: "inDraft",
              AssociatedID: draftIdtobeUpdated,
            };
            tempMaterials.push(materialObject);
          } else {
            const workObject = {
              ...item,
              status: "inDraft",
              AssociatedID: draftIdtobeUpdated,
            };
            tempWorks.push(workObject);
          }
        } else {
          let cWork = workAndMaterials[index];
          let cRoom = newRivision[cWork.indexces.roomIndex];
          let Units = cRoom.Units;
          let cUnit = Units[cWork.indexces.unitIndex];
          let Components = cUnit.Components;
          let cComponent = Components[cWork.indexces.componentIndex];
          if ("vendorCategory" in workAndMaterials[index]) {
            let Work = [...cComponent.Work];
            Work.splice(cWork.indexces.workIndex, 1, {
              ...cWork,
              status: "inDraft",
              AssociatedID: draftIdtobeUpdated,
            });
            cComponent = { ...cComponent, Work };
            tempWorks.push(workAndMaterials[index]);
          } else {
            let Material = [...cComponent.Material];
            Material.splice(cWork.indexces.materialIndex, 1, {
              ...cWork,
              status: "inDraft",
              AssociatedID: draftIdtobeUpdated,
            });
            cComponent = { ...cComponent, Material };
            tempMaterials.push(workAndMaterials[index]);
          }
          Components.splice(cWork.indexces.componentIndex, 1, cComponent);
          cUnit = { ...cUnit, Components };
          Units.splice(cWork.indexces.unitIndex, 1, cUnit);
          cRoom = { ...cRoom, Units };
          newRivision.splice(cWork.indexces.roomIndex, 1, cRoom);
          setRevision(newRivision);
        }
      });

      const response = await draftService.postDraft(
        data.ProjectId,
        draftIdtobeUpdated, // new Date().getTime(), // draftId
        orderType,
        selectedItems,
        defaultMilestones,
        addedItems,
        activeCategory,
        contactPersonName,
        contactPersonNumber,
        siteEngineerDetails.siteEngineerName,
        siteEngineerDetails.siteEngineerNumber,
        discount,
        startDate,
        endDate,
        vendorMobile,
        vendorFirm,
        vendorGSTIN,
        vendorAddress,
        vendorPinCode,
        vendorCity,
        vendorState,
        termsAndConditions,
        paymentTerms,
        specialInstructions,
        priceBase,
        freight,
        insurance,
        shippingAddress,
        billingAddress,
        attachments
      );
      if (response.status === 200) {
        saveWorkButtonClicked(
          { ...data, rooms: revision },
          undefined,
          updateFBRDB
        );
        updatePOImagesFirebase();

        toast.success(
          `Draft for ${activeCategory} category with ${selectedItems.length} items saved.`,
          {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1A85B1",
              backgroundColor: "#F1FBFF",
              maxWidth: "100%",
            },
            icon: <CgFileDocument color={"#1A85B1"} />,
          }
        );

        history.push("/Timeline/" + data.ProjectId, { data: data });
      } else {
        toast.error("Error saving draft!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
        record("@save_draft_button_click_success", {
          description: "Save draft button click success",
        });
      }
    } else {
      if (!contactPersonName) {
        contactPersonNameRef.current.focus();
      } else if (!contactPersonNumber) {
        contactPersonNumberRef.current.focus();
      } else if (!siteEngineerDetails.siteEngineerName) {
        siteEngineerNameRef.current.focus();
      } else if (!siteEngineerDetails.siteEngineerNumber) {
        siteEngineerNumberRef.current.focus();
      } else if (!shippingAddress) {
        shippingAddressRef.current.focus();
      } else if (!billingAddress) {
        billingAddressRef.current.focus();
      }
      toast.error("Mandatory fields incomplete!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
      record("@save_draft_button_click_error", {
        description: "Save draft button click error",
      });
    }
  };

  const deleteDraft = async () => {

    const response = await draftService.deleteDraft(draftId);
    if (response.status != 200) {
      toast.error("Error deleting draft!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
    }
  };

  const getSuggestiveTextFirm = async () => {
    const response = await projectService.getSuggestiveTextFirm();
    if (response.status == 200) {
      const firmDetails = response.payload.filter(
        (item) => item.firmName == userContext.user.Firm
      );
      if (firmDetails.length > 0) {
        setFirmName(firmDetails[0].firmName);
        setFirmAddress(firmDetails[0].firmAddress);
        setFirmPhoneNumber(firmDetails[0].firmPhoneNumber);
        setFirmGSTIN(firmDetails[0].firmGSTIN);
        setFirmContactPersonName(firmDetails[0].firmContactPersonName);
        setFirmContactPersonNumber(firmDetails[0].firmContactPersonNumber);
        setFirmContactPersonEmail(firmDetails[0].firmContactPersonEmail);
        setFirmSignature(JSON.parse(firmDetails[0].firmSignature));
      }
    } else {
      toast.error("Error getting suggestions!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
    }
  };

  const removeHTML = (str) => {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  };

  const DateCreated = () => {
    let dateObj = new Date(
      Number(
        location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
    );
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    let newdate = year + "/" + month + "/" + day;
    return newdate;
  };

  const calculateDate = () => {
    var date1 = new Date();
    var date2 = new Date(
      Number(
        location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
    );
    var diffTime = date1.getTime() - date2.getTime();
    var diffDays = diffTime / (1000 * 3600 * 24);
    return Math.floor(diffDays);
  };

  const prepareTheListOfAllVendors = (allVendors) => {
    let tempCategoryArray = [];
    let tempObjectForVendorData = {};
    let tempCat, concCat;
    for (let index = 0; index < allVendors.length; index++) {
      if (tempCategoryArray.includes(allVendors[index].Category)) {
      } else {
        if (allVendors[index].Category[0] == "(") {
          tempCat = allVendors[index].Category.slice(2);
          concCat = tempCat.slice(0, tempCat.length - 2);
          tempCategoryArray.push(concCat);
        } else {
          tempCategoryArray.push(allVendors[index].Category);
        }
      }
    }
    tempCategoryArray = [...new Set(tempCategoryArray)].sort();
    for (let index = 0; index < allVendors.length; index++) {
      if (allVendors[index].Category[0] == "(") {
        tempCat = allVendors[index].Category.slice(2);
        concCat = tempCat.slice(0, tempCat.length - 2);
        if (tempObjectForVendorData.hasOwnProperty(concCat)) {
          tempObjectForVendorData[concCat].push([
            allVendors[index].Name + " : " + allVendors[index].Mobile,
          ]);
        } else {
          tempObjectForVendorData[concCat] = [
            allVendors[index].Name + " : " + allVendors[index].Mobile,
          ];
        }
      } else {
        if (
          tempObjectForVendorData.hasOwnProperty(allVendors[index].Category)
        ) {
          tempObjectForVendorData[allVendors[index].Category].push([
            allVendors[index].Name + " : " + allVendors[index].Mobile,
          ]);
        } else {
          tempObjectForVendorData[allVendors[index].Category] = [
            allVendors[index].Name + " : " + allVendors[index].Mobile,
          ];
        }
      }
    }
    for (let i in tempObjectForVendorData) {
      tempObjectForVendorData[i] = tempObjectForVendorData[i].sort();
    }
    let tempArray = [];
    for (let i in tempObjectForVendorData) {
      tempArray.push({ [i]: tempObjectForVendorData[i] });
    }
    setAllVendorData(tempArray);
  };

  const updatePOImagesFirebase = async () => {
    let index = 0;
    for (let key of Object.keys(imageULS)) {
      if (
        imageULS[key] &&
        Object.keys(imageULS[key] !== null) &&
        Object.keys(imageULS[key]).length > 0
      ) {
        if (imageULS[key].url) {
          index++;
          continue;
        }
        await handleImageUpload(imageULS[key], index);
      } else {
      }
      index++;
    }
  };

  const handleImageUpload = async (image, index) => {
    const [file] = image;
    if (file) {
      let timeStamp = new Date().getTime();
      await storageRef
        .child(
          `POImages/${project.ProjectId}/${selectedItems[index].OrderId}/${timeStamp}.jpg`
        )
        .put(file);
      storageRef
        .child(
          `POImages/${project.ProjectId}/${selectedItems[index].OrderId}/${timeStamp}.jpg`
        )
        .getDownloadURL()
        .then((url) => {
          urlForImage = url;
        })
        .then(() => {
          let updates = {
            url: urlForImage,
          };

          database
            .ref(
              `POImages/${project.ProjectId}/${selectedItems[index].OrderId}`
            )
            .update(updates);
        })
        .catch((error) => { });
    }
  };

  const previewHandler = () => {
    if (selectedItems.length != 0) {
      if (defaultMilestones.length == 1) {
        if (defaultMilestones[0].name == "Type your milestone here") {
          alert("Kindly define the payment terms");
        } else {
          if (selectedItemsNonEdited.length > 0) {
            confirmAlert({
              title: "Line items changes detected!",
              message:
                "You have made some changes to the line items. Do you want to keep them?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    setIsPreviewModalOpen(true);
                  },
                },
                {
                  label: "No",
                  onClick: () => {
                    setSelectedItems(selectedItemsNonEdited);
                    setIsPreviewModalOpen(true);
                  },
                },
              ],
            });
          } else {
            setIsPreviewModalOpen(true);
          }
          record("@preview_release_button_click_success", {
            description: "preview&release button click success",
          });
        }
      } else {
        if (selectedItemsNonEdited.length > 0) {
          confirmAlert({
            title: "Line items changes detected!",
            message:
              "You have made some changes to the line items. Do you want to keep them?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  setIsPreviewModalOpen(true);
                },
              },
              {
                label: "No",
                onClick: () => {
                  setSelectedItems(selectedItemsNonEdited);
                  setIsPreviewModalOpen(true);
                },
              },
            ],
          });
        } else {
          setIsPreviewModalOpen(true);
        }
        record("@preview_release_button_click_success", {
          description: "preview&release button click success",
        });
      }
    } else {
      alert("Kindly select the Items first");
    }
  };

  const AmmendPO = async (tempWorks, tempMaterials) => {

    if (poresponsefromAPi.status === 200) {
      let po = { ...poresponsefromAPi.payload[0] };
      let poOlderVesionsofPO =
        (po.OlderVersions && JSON.parse(po.OlderVersions)) || [];
      poOlderVesionsofPO.unshift({
        ...po,
        AmendmentDetails: {
          isUnreleased: true,
          UnreleasedOn: new Date().getTime(),
        },
        discription: JSON.parse(po.discription),
        firmSignature: JSON.parse(po.firmSignature),
        attachments: JSON.parse(po.attachments),
        CommonMilestones: JSON.parse(po.CommonMilestones),
        OlderVersions: "null",
        bankDetails: po.bankDetails ? JSON.parse(po.bankDetails) : null,
      });
      console.log('poOlderVesionsofPO.length - 1] : ', poOlderVesionsofPO.length - 1)
      console.log('this is the poOlderVesionsofPO : ', poOlderVesionsofPO);
      console.log('this is the poOlderVesionsofPO.freight : ', poOlderVesionsofPO[poOlderVesionsofPO.length - 1].freight);
      console.log('this is the typeof poOlderVesionsofPO.freight : ', typeof (poOlderVesionsofPO[poOlderVesionsofPO.length - 1].freight));
      console.log('this is the freight : ', freight)
      poOlderVesionsofPO[0].freight = JSON.parse(poOlderVesionsofPO[0].freight);

      // if (typeof poOlderVesionsofPO[poOlderVesionsofPO.length - 1].freight !== 'string') {
      //   poOlderVesionsofPO[poOlderVesionsofPO.length - 1].freight = JSON.stringify(poOlderVesionsofPO[poOlderVesionsofPO.length - 1].freight);
      // }
      // freight = JSON.stringify(freight);
      let poDataObjetToUpdate = {
        discription: tempWorks.length > 0 ? tempWorks : tempMaterials,
        shipToAddress: shippingAddress,
        totalAmount,
        projectContactPerson: contactPersonName,
        projectContactNumber: contactPersonNumber,
        siteEngineerName: siteEngineerDetails.siteEngineerName,
        siteEngineerMobile: siteEngineerDetails.siteEngineerNumber,
        discount,
        startDate,
        endDate,
        vendorFirm: vendorCompanyName,
        vendorAddress,
        vendorPinCode,
        vendorGSTIN,
        vendorCity,
        vendorState,
        termsAndCondition: termsAndConditions,
        paymentTerms,
        specialInstructions,
        priceBase,
        freight: JSON.stringify(freight),
        insurance,
        billToAddress: billingAddress,
        attachments,
        OlderVersions: FromApproval ? "null" : poOlderVesionsofPO,
        AmendmentDetails: { isUnreleased: false },
        poDate: new Date(),
        vendorMobile:
          vendorMobile == null ||
            vendorMobile == "null" ||
            vendorMobile == "Open Vendor"
            ? "Open Vendor"
            : vendorMobile,
        VendorAcceptedDate: "",
        vendorSignature: "",
        CommonMilestones: defaultMilestones,
        bankDetails: bankAccountDetails,
      };
      const updateresponse = await poService.updatePO(
        po.Id,
        poDataObjetToUpdate
      );
      console.log('updateresponse from Ammend PO : ', updateresponse);
      const smsService = new SmsService();
      if (
        vendorMobile != null &&
        vendorMobile != "null" &&
        vendorMobile != "Open Vendor" &&
        !isApproval
      ) {
        let link =
          environment.environment == "staging"
            ? `https://staartstaging.netlify.app/?po_id=${releasedOrderDataFromReleased.OrderId}`
            : `https://staartbuild.netlify.app/?po_id=${releasedOrderDataFromReleased.OrderId}`;

        if (po.vendorMobile != vendorMobile) {
          await smsService.sendWhatsapp({
            to: `91${vendorMobile.split(":")[1].trim()}`,
            firm: userContext.user.Firm,
            orderType: orderType,
            category: activeCategory,
            link: link,
          });
        } else {
          let components = [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: userContext.user.Firm,
                },
                {
                  type: "text",
                  text: orderType,
                },
                {
                  type: "text",
                  text: activeCategory[0],
                },
                {
                  type: "text",
                  text: link,
                },
              ],
            },
          ];
          await smsService.whatsAppSMS(
            `${vendorMobile.split(":")[1].trim()}`,
            "amended_po_notify_vendors",
            "en",
            components
          );
        }
      }
    }
  };

  const UnReleaseDeletedItems = () => {
    if (deletedItemsToUnfreeze.length > 0) {
      let newRivision = [...revision];
      deletedItemsToUnfreeze.forEach((order) => {
        let cWork = order;
        let cRoom = newRivision[cWork.indexces.roomIndex];
        let Units = cRoom.Units;
        let cUnit = Units[cWork.indexces.unitIndex];
        let Components = cUnit.Components;
        let cComponent = Components[cWork.indexces.componentIndex];
        if ("vendorCategory" in order) {
          let Work = [...cComponent.Work];
          Work.splice(cWork.indexces.workIndex, 1, { ...cWork, status: "" });
          cComponent = { ...cComponent, Work };
        } else {
          let Material = [...cComponent.Material];
          Material.splice(cWork.indexces.materialIndex, 1, {
            ...cWork,
            status: "",
          });
          cComponent = { ...cComponent, Material };
        }
        Components.splice(cWork.indexces.componentIndex, 1, cComponent);
        cUnit = { ...cUnit, Components };
        Units.splice(cWork.indexces.unitIndex, 1, cUnit);
        cRoom = { ...cRoom, Units };
        newRivision.splice(cWork.indexces.roomIndex, 1, cRoom);
        setRevision(newRivision);
      });
      saveWorkButtonClicked(
        { ...data, rooms: newRivision },
        undefined,
        updateFBRDB
      );
    }
  };

  const reReleaseOrder = async () => {
    if (
      selectedItems.length > 0 &&
      contactPersonName &&
      contactPersonNumber &&
      siteEngineerDetails.siteEngineerName &&
      siteEngineerDetails.siteEngineerNumber &&
      shippingAddress &&
      billingAddress
    ) {
      const opportunityService = new OpportunityService();
      if (
        (releasedOrderDataFromReleased.VendorMobile == null &&
          vendorMobile !== "Open Vendor") ||
        (releasedOrderDataFromReleased.VendorMobile !== null &&
          releasedOrderDataFromReleased.VendorMobile !==
          vendorMobile?.split(":")[1]?.trim())
      ) {
        makeOpportunity();
        UnReleaseDeletedItems();
        const poService = new POService();
        await poService.unReleasePO(releasedOrderDataFromReleased.OrderId, {
          AmendmentDetails: {
            isUnreleased: true,
            UnreleasedOn: new Date().getTime(),
          },
        });
        await opportunityService.deleteOrderByID(
          releasedOrderDataFromReleased.OrderId
        );
        if (freightExists && textFieldValue !== '') {
          updateFreightRate(textFieldValue, freightItemIndex);
        }
      } else {
        
        let currentDraft = {
          CommonMilestones: JSON.parse(
            releasedOrderDataFromReleased.CommonMilestones
          ),
          Data: JSON.parse(releasedOrderDataFromReleased.Data),
          OrderTotalAmount: releasedOrderDataFromReleased.OrderTotalAmount,
        };
        let tempOlderVerions = OlderVersionofOrderData && [
          ...OlderVersionofOrderData,
        ];
        tempOlderVerions.unshift(currentDraft);

        const [tempWorks, tempMaterials] = editingTheBOQPage();

        let dataObjectToUpdate = {};
        let errorFound = "";
        dataObjectToUpdate.Data = {
          data: tempWorks.length > 0 ? tempWorks : tempMaterials,
          ClientName: project.clientName
            ? project.clientName
            : project.ClientName,
          StartDate: startDate,
          EndDate: endDate,
          releasedDate: new Date(),
        };
        dataObjectToUpdate = {
          ...dataObjectToUpdate,
          Address: shippingAddress,
          OrderTotalAmount: totalAmount,
          CommonMilestones: defaultMilestones,
          OlderVersions: tempOlderVerions,
          Category: activeCategory.toString(),
        };
        dataObjectToUpdate.ApprovalStatus = isApproval ? "inApproval" : "";
        let lastUpdatedTimestamp = new Date().getTime();
        let lastUpdatedDate =
          new Date(lastUpdatedTimestamp).getDate() +
          "-" +
          (new Date(lastUpdatedTimestamp).getMonth() + 1) +
          "-" +
          new Date(lastUpdatedTimestamp).getFullYear();
        let lastUpdatedTime =
          new Date(lastUpdatedTimestamp).getHours() +
          ":" +
          new Date(lastUpdatedTimestamp).getMinutes();

        let previousHistory = {
          ...JSON.parse(releasedOrderDataFromReleased.requestHistory),
        };

        let reqHistoryObjectTOUpdate = {
          ...previousHistory,
          [new Date().getTime()]: {
            sendTo: "", //will be same, for now empty
            raised: userContext.user.email, //same all the time
            comments: isApproval ? "Edited For Approval" : "",
            status: isApproval ? "Requested" : "Ordered",
            date: lastUpdatedDate,
            time: lastUpdatedTime,
            userId: userContext.user.email, //  email of current  updating user
            By: userContext.user.email.slice(
              0,
              userContext.user.email.indexOf("@")
            ), // name/email of current  updating user
          },
        };

        if (isApproval) {
          dataObjectToUpdate["ApprovalStatus"] = "inApproval"; // change the status to "inApproval" ,default will be ""
          dataObjectToUpdate["requestHistory"] = reqHistoryObjectTOUpdate;
        } else {
          dataObjectToUpdate["ApprovalStatus"] = "Ordered";
          dataObjectToUpdate["requestHistory"] = reqHistoryObjectTOUpdate;
        }

        dataObjectToUpdate.VendorMobile =
          vendorMobile == null ||
            vendorMobile == "null" ||
            vendorMobile == "Open Vendor"
            ? "Open Vendor"
            : Number(vendorMobile.split(":")[1].trim());
        dataObjectToUpdate.VendorName =
          vendorMobile == null ||
            vendorMobile == "null" ||
            vendorMobile == "Open Vendor"
            ? "Open Vendor"
            : vendorMobile.split(":")[0].trim();
        dataObjectToUpdate.Status =
          vendorMobile == null ||
            vendorMobile == "null" ||
            vendorMobile == "Open Vendor"
            ? "Order Placed"
            : "Order accepted";
        await opportunityService
          .updateOpportunityById(
            releasedOrderDataFromReleased.OrderId,
            dataObjectToUpdate
          )
          .then((res) => {
            if (res.status !== 200) {
              errorFound = "Error while making opportunity!";
            }
          });
        if (tempWorks.length > 0 || tempMaterials.length > 0) {
          if (errorFound.length > 0) {
            toast.error(errorFound, {
              duration: 4000,
              position: "bottom-center",
              style: {
                borderRadius: "8px",
                padding: "16px",
                color: "#E72424",
                backgroundColor: "#FEF0F0 ",
                maxWidth: "100%",
              },
              icon: <FiInfo color={"#E72424"} />,
            });
          } else {
            setIsUpdatingProgress(false);
            setIsSuccessPageOpen(true); //open success modal
            await saveWorkButtonClicked(
              { ...data, rooms: revision },
              undefined,
              updateFBRDB
            );
            if (freightExists && textFieldValue !== '') {
              updateFreightRate(textFieldValue, freightItemIndex);
            }
            updatePOImagesFirebase();
            backtrackBOQ();
            AmmendPO(tempWorks, tempMaterials);
            UnReleaseDeletedItems();
          }
        }
      }
      dealFinanceRequest(location.state.releasedOrderDataFromReleased.OrderId);
    } else {
      if (!contactPersonName) {
        contactPersonNameRef.current.focus();
      } else if (!contactPersonNumber) {
        contactPersonNumberRef.current.focus();
      } else if (!siteEngineerDetails.siteEngineerName) {
        siteEngineerNameRef.current.focus();
      } else if (!siteEngineerDetails.siteEngineerNumber) {
        siteEngineerNumberRef.current.focus();
      } else if (!shippingAddress) {
        shippingAddressRef.current.focus();
      } else if (!billingAddress) {
        billingAddressRef.current.focus();
      }

      toast.error("Mandatory fields incomplete!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });

      record("@release_order_button_click_error", {
        description: "Release order button click error",
      });
    }
  };

  return (
    <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <div className={"mainTabs"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: "18%",
            marginTop: "18px",
          }}>
          <BsArrowLeftShort
            size={30}
            style={{
              marginRight: "20px",
              border: "1px solid  #fdd34d",
              borderRadius: "50%",
              backgroundColor: "#fdd34d",
              cursor: "pointer",
            }}
            onClick={() => {
              setAddedItems([]);
              setSelectedItems([]);
              history.push("/UnreleasedItems/" + data.ProjectId, {
                data: data,
              });
            }}
          />
          <div
            style={{ color: "#2F4858", fontSize: "18px", fontWeight: "400" }}>
            {project && (
              <p
                style={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  cursor: "default",
                }}>
                {project.clientName ? project.clientName : project.ClientName}
              </p>
            )}
          </div>
        </div>

        <div>
          <div
            className={"boqTab"}
            onClick={() => {
              setActiveMainTab(0);
              history.push("/ProjectPage/" + data.ProjectId);
              record("@boq_tab_click_success", {
                description: "BOQ tab click success",
              });
            }}>
            <div style={{ fontWeight: "bold" }}>BOQ</div>
            {(accountType == "Admin" ||
              accountType == "Finance" ||
              accountType == "Owner") && (
                <div style={{ whiteSpace: "nowrap" }}>
                  Rs {(data && getComponentsPrice(data).toFixed(2)) || 0}
                </div>
              )}
          </div>
          <div className={activeMainTab === 0 ? "active" : ""}></div>
        </div>

        <div>
          <div
            className={"indentTab"}
            onClick={() => {
              if (accountType == "BOQ" || accountType == "Finance") {
                toast.error("You don't have access to Purchase!", {
                  backgroundColor: "red",
                  color: "black",
                });
              } else {
                if (data?.rooms) {
                  setActiveMainTab(1);
                  saveWorkButtonClicked(data, setSpinner);
                } else {
                  toast.error("Empty BOQ. Please add to proceed!", {
                    backgroundColor: "red",
                    color: "black",
                  });
                }
              }
              record("@purchase_tab_click_success", {
                description: "Purchase tab click success",
              });
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <div style={{ fontWeight: "bold" }}>Purchase</div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (displayPurchaseAmount) {
                    setDisplayPurchaseAmount(false);
                  } else {
                    setDisplayPurchaseAmount(true);
                  }
                }}>
                {!displayPurchaseAmount ? (
                  <VisibilityOff
                    style={{
                      marginLeft: 10,
                    }}
                  />
                ) : (
                  <Visibility
                    style={{
                      marginLeft: 10,
                    }}
                  />
                )}
              </div>
            </div>
            {(accountType == "Admin" ||
              accountType == "Finance" ||
              accountType == "Owner") && (
                <div style={{ whiteSpace: "nowrap" }}>
                  Rs{" "}
                  {(data &&
                    !displayPurchaseAmount &&
                    getVendorPrice(data).toFixed(2)) ||
                    "**********"}
                </div>
              )}
          </div>
          <div className={activeMainTab === 1 ? "active" : ""}></div>
        </div>

        <div
          style={{
            marginLeft: "18%",
            color: "#2F4858",
            fontSize: "18px",
            fontWeight: "400",
            marginTop: "20px",
          }}>
          <h5>
            started Date : {DateCreated()} | {calculateDate()} days ago
          </h5>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: 40,
          gap: 40,
        }}>
        <div
          style={{
            width: "100%",
            margin: "auto",
            height: "5%",
            marginTop: "20px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 20,
              cursor: "pointer",
            }}>
            {quotationVendorMobile != null ? (
              <p
                style={{
                  fontWeight: "bold",
                  textDecorationLine: "underline",
                  fontSize: "28px",
                  cursor: "text",
                }}>
                {orderType}
              </p>
            ) : (
              <>
                <div
                  style={
                    orderType == "Work Order"
                      ? {
                        backgroundColor: "#F5F5F5",
                        color: "#353535",
                        fontWeight: 700,
                        padding: "10px",
                      }
                      : { color: "#CFCFCF" }
                  }
                  onClick={() => {
                    setOrderType("Work Order");
                    setSelectedItems([]);
                    setAddedItems([]);
                    setDefaultMilestones(defaultMilestonesState);
                    record("@work_order_tab_click_success", {
                      description: "Work order tab click success",
                    });
                  }}>
                  Work Order
                </div>

                <div
                  style={
                    orderType == "Purchase Order"
                      ? {
                        backgroundColor: "#F5F5F5",
                        color: "#353535",
                        fontWeight: 700,
                        padding: "10px",
                      }
                      : { color: "#CFCFCF" }
                  }
                  onClick={() => {
                    setOrderType("Purchase Order");
                    setSelectedItems([]);
                    setAddedItems([]);
                    setDefaultMilestones(defaultMilestonesState);
                    record("@purchase_order_tab_click_success", {
                      description: "Purchase order tab click success",
                    });
                  }}>
                  Purchase Order
                </div>

                <div
                  style={
                    orderType == "Work Quotation"
                      ? {
                        backgroundColor: "#F5F5F5",
                        color: "#353535",
                        fontWeight: 700,
                        padding: "10px",
                      }
                      : { color: "#CFCFCF" }
                  }
                  onClick={() => {
                    setOrderType("Work Quotation");
                    setSelectedItems([]);
                    setAddedItems([]);
                    setDefaultMilestones(defaultMilestonesState);
                    record("@work_quotation_tab_click_success", {
                      description: "Work quotation tab click success",
                    });
                  }}>
                  Work Quotation
                </div>

                <div
                  style={
                    orderType == "Material Quotation"
                      ? {
                        backgroundColor: "#F5F5F5",
                        color: "#353535",
                        fontWeight: 700,
                        padding: "10px",
                      }
                      : { color: "#CFCFCF" }
                  }
                  onClick={() => {
                    setOrderType("Material Quotation");
                    setSelectedItems([]);
                    setAddedItems([]);
                    setDefaultMilestones(defaultMilestonesState);
                    record("@material_quotation_tab_click_success", {
                      description: "Material quotation tab click success",
                    });
                  }}>
                  Material Quotation
                </div>
              </>
            )}
          </div>
          <div />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p style={{ fontWeight: "bold" }}>Release Date: </p>
              <p style={{ marginLeft: 10 }}>{`${new Date().getDate()} / ${new Date().getMonth() + 1
                } / ${new Date().getFullYear()}`}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <img
              src={sessionStorage.getItem("firmLogoURL")}
              style={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                borderStyle: "solid",
                borderWidth: 0.25,
                borderColor: "grey",
              }}
            />
            <p
              style={{
                textAlign: "center",
                justifyContent: "center",
                color: "rgba(0, 0, 0, 0.54)",
                marginTop: 10,
              }}>
              {userContext.user.Firm}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: 8,
            marginBottom: 100,
          }}>
          <div
            style={{
              width: 9,
              backgroundColor: "#FDD34D",
              borderRadius: "8px 0px 0px 8px",
            }}
          />
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: 20,
              }}>
              <abbr
                title="Default milestones"
                style={{ textDecoration: "none" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: 10,
                  }}>
                  <div
                    style={{
                      width: "7%",
                      maxWidth: "7%",
                      minWidth: "7%",
                      marginTop: 20,
                      flexDirection: "column",
                      textAlign: "center",
                      marginLeft: "1%",
                      gap: 5,
                    }}>
                    <div
                      style={{
                        color: "#999999",
                        fontSize: 10,
                        fontWeight: 600,
                        fontFamily: "Montserrat",
                        marginBottom: 5,
                      }}>
                      Alloted Amount
                    </div>
                    <div
                      style={{
                        border: "2px solid #FDD34D",
                        borderRadius: "16px",
                      }}>
                      Total {"\u20B9 "} {totalAmount}
                    </div>
                    <div
                      style={{
                        color: "#353535",
                        fontSize: 10,
                        fontWeight: 600,
                        fontFamily: "Montserrat",
                        marginTop: 5,
                      }}>
                      Milestone
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "12%",
                      marginTop: 20,
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        height: 5,
                        width: "14%",
                        backgroundColor: "#FFECB3",
                      }}></div>
                    <div
                      style={{
                        height: 5,
                        width: "14%",
                        backgroundColor: "#FFA000",
                      }}></div>
                    <div
                      style={{
                        height: 5,
                        width: "14%",
                        backgroundColor: "#FFECB3",
                      }}></div>
                    <div
                      style={{
                        height: 5,
                        width: "14%",
                        backgroundColor: "#FFA000",
                      }}></div>
                    <div
                      style={{
                        height: 5,
                        width: "14%",
                        backgroundColor: "#FFECB3",
                      }}></div>
                    <div
                      style={{
                        height: 5,
                        width: "15%",
                        backgroundColor: "#FFA000",
                      }}></div>
                    <div
                      style={{
                        height: 5,
                        width: "15%",
                        backgroundColor: "#FFECB3",
                      }}></div>
                  </div>

                  {defaultMilestones.length == 1 ? (
                    <>
                      {defaultMilestones[0].name ==
                        "Type your milestone here" ? (
                        <>
                          <div
                            style={{
                              width: "4%",
                              maxWidth: "4%",
                              minWidth: "4%",
                              marginTop: 20,
                              flexDirection: "column",
                              textAlign: "center",
                            }}>
                            <div
                              style={{
                                color: "#999999",
                                fontSize: 10,
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                marginBottom: 5,
                              }}>
                              {"\u20B9 "} 0.00
                            </div>
                            <div
                              style={{
                                border: "2px solid #FDD34D",
                                borderRadius: "16px",
                              }}>
                              0 %
                            </div>
                            <div
                              style={{
                                color: "#353535",
                                fontSize: 10,
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                marginTop: 5,
                              }}>
                              Starting
                            </div>
                          </div>
                          <div
                            style={{
                              height: 5,
                              width: "69%",
                              backgroundColor: "#FFECB3",
                              marginTop: 20,
                            }}></div>
                          <div
                            style={{
                              width: "4%",
                              maxWidth: "4%",
                              minWidth: "4%",
                              marginTop: 20,
                              flexDirection: "column",
                              textAlign: "center",
                            }}>
                            <div
                              style={{
                                color: "#999999",
                                fontSize: 10,
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                marginBottom: 5,
                              }}>
                              {"\u20B9 "} 0.00
                            </div>
                            <div
                              style={{
                                border: "2px solid #FDD34D",
                                borderRadius: "16px",
                              }}>
                              100 %
                            </div>
                            <div
                              style={{
                                color: "#353535",
                                fontSize: 10,
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                marginTop: 5,
                              }}>
                              Completion
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              width: "2%",
                              maxWidth: "2%",
                              minWidth: "2%",
                              marginTop: 20,
                              flexDirection: "column",
                              textAlign: "center",
                            }}>
                            <div
                              style={{
                                border: "2px solid #FDD34D",
                                borderRadius: "16px",
                                height: "20px",
                                width: "20px",
                              }}></div>
                          </div>
                          <div
                            style={{
                              height: 5,
                              width: "69%",
                              backgroundColor: "#FFECB3",
                              marginTop: 20,
                            }}></div>
                          <div
                            style={{
                              width: "4%",
                              maxWidth: "4%",
                              minWidth: "4%",
                              marginTop: 20,
                              flexDirection: "column",
                              textAlign: "center",
                            }}>
                            <div
                              style={{
                                color: "#999999",
                                fontSize: 10,
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                marginBottom: 5,
                              }}>
                              {"\u20B9 "} {defaultMilestones[0].totalAmount}
                            </div>
                            <div
                              style={{
                                border: "2px solid #FDD34D",
                                borderRadius: "16px",
                              }}>
                              100 %
                            </div>
                            <div
                              style={{
                                color: "#353535",
                                fontSize: 10,
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                marginTop: 5,
                              }}>
                              {defaultMilestones[0].name}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    defaultMilestones.map((item, index) => (
                      <>
                        {index == defaultMilestones.length - 1 ? (
                          <div
                            style={{
                              width: "4%",
                              maxWidth: "4%",
                              minWidth: "4%",
                              marginTop: 20,
                              flexDirection: "column",
                              textAlign: "center",
                            }}>
                            <div
                              style={{
                                color: "#999999",
                                fontSize: 10,
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                marginBottom: 5,
                              }}>
                              {"\u20B9 "} {item.totalAmount}
                            </div>
                            <div
                              style={{
                                border: "2px solid #FDD34D",
                                borderRadius: "16px",
                              }}>
                              {item.percentage} %
                            </div>
                            <div
                              style={{
                                color: "#353535",
                                fontSize: 10,
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                marginTop: 5,
                              }}>
                              {item.name}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                width: "4%",
                                maxWidth: "4%",
                                minWidth: "4%",
                                marginTop: 20,
                                flexDirection: "column",
                                textAlign: "center",
                              }}>
                              <div
                                style={{
                                  color: "#999999",
                                  fontSize: 10,
                                  fontWeight: 600,
                                  fontFamily: "Montserrat",
                                  marginBottom: 5,
                                }}>
                                {"\u20B9 "} {item.totalAmount}
                              </div>
                              <div
                                style={{
                                  border: "2px solid #FDD34D",
                                  borderRadius: "16px",
                                }}>
                                {item.percentage} %
                              </div>
                              <div
                                style={{
                                  color: "#353535",
                                  fontSize: 10,
                                  fontWeight: 600,
                                  fontFamily: "Montserrat",
                                  marginTop: 5,
                                }}>
                                {item.name}
                              </div>
                            </div>
                            <div
                              style={{
                                height: 5,
                                width: "69%",
                                backgroundColor: "#FFECB3",
                                marginTop: 20,
                              }}></div>
                          </>
                        )}
                      </>
                    ))
                  )}

                  {defaultMilestones?.length == 1 ? (
                    <>
                      {defaultMilestones[0].name ==
                        "Type your milestone here" ? (
                        <div
                          style={{
                            width: "6%",
                            marginTop: 20,
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (selectedItems.length == 0) {
                              alert("Add Items First!");
                            } else {
                              setDefaultMilestones([
                                {
                                  name: "Type your milestone here",
                                  percentage: 100,
                                  totalAmount: totalAmount,
                                },
                              ]);
                              setShowPaymentTerms(true);
                            }
                          }}>
                          <p
                            style={{
                              display: "inline-block",
                              backgroundColor: "#FDD34D",
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              lineHeight: "50px",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              fontSize: "40px",
                            }}>
                            +
                          </p>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "6%",
                            marginTop: 20,
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowPaymentTerms(true);
                          }}>
                          <div
                            style={{
                              display: "inline-block",
                              backgroundColor: "#FDD34D",
                              height: "50px",
                              width: "50px",
                              borderRadius: "50%",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              textAlign: "center",
                              flexDirection: "column",
                            }}>
                            <FiEdit2
                              style={{ marginTop: "0.75em" }}
                              size={25}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        width: "6%",
                        marginTop: 20,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowPaymentTerms(true);
                      }}>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#FDD34D",
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          textAlign: "center",
                          flexDirection: "column",
                        }}>
                        <FiEdit2 style={{ marginTop: "0.75em" }} size={25} />
                      </div>
                    </div>
                  )}
                </div>
              </abbr>

              <p
                style={{
                  textAlign: "center",
                  marginTop: 10,
                  marginBottom: 20,
                  fontWeight: 700,
                  fontSize: 20,
                  color: "#9b9b9a",
                }}>
                Line Items - common milestones
              </p>
              <table id="table1">
                <tbody>
                  <tr>
                    <th
                      style={{
                        color: "#C4C4C4",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}>
                      Items
                    </th>
                    <th
                      style={{
                        color: "#C4C4C4",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}>
                      Description
                    </th>
                    <th
                      style={{
                        color: "#C4C4C4",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}>
                      Image
                    </th>
                    <th
                      style={{
                        color: "#C4C4C4",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}>
                      Quantity
                    </th>
                    <th
                      style={{
                        color: "#C4C4C4",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}>
                      Unit
                    </th>
                    {orderType == "Work Quotation" ||
                      orderType == "Material Quotation" ? (
                      <>
                        {quotationVendorMobile != null ? (
                          <th
                            style={{
                              backgroundColor: "white",
                              color: "#C4C4C4",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                            }}>
                            Rate
                          </th>
                        ) : null}
                      </>
                    ) : (
                      <th
                        style={{
                          backgroundColor: "white",
                          color: "#C4C4C4",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}>
                        Rate
                      </th>
                    )}

                    <th
                      style={{
                        backgroundColor: "white",
                        color: "#C4C4C4",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}>
                      GST
                    </th>
                    {orderType == "Work Quotation" ||
                      orderType == "Material Quotation" ? null : (
                      <th
                        style={{
                          backgroundColor: "white",
                          color: "#C4C4C4",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}>
                        Amount
                      </th>
                    )}
                  </tr>
                </tbody>
                {selectedItems.map((item, index) => {
                  if (milestoneTobeAddedIndex.includes(index)) {
                    orderNo = orderNo + 1;
                  } else {
                    return (
                      <tbody key={index}>
                        <tr style={{ textAlign: "center" }} key={index}>
                          {"type" in item ||
                            ("workType" in item &&
                              item.workType == "Only Material") ? (
                            <td>{item.type || item.category}</td>
                          ) : (
                            <td>{item.category || item.vendorCategory}</td>
                          )}

                          {"type" in item ? (
                            <td>
                              <ContentEditable
                                // className="wrapInput"
                                style={{
                                  wordWrap: "break-word", // Ensures text wraps to the next line
                                  overflowWrap: "break-word", // Handles text wrapping for modern browsers
                                  maxWidth: "500px", // Limits the width of the content
                                  whiteSpace: "pre-wrap", // Preserves newlines and wraps text
                                  overflow: "hidden", // Prevents content from overflowing
                                  display: "inline-block", // Keeps layout consistent
                                }}
                                contentEditable
                                html={item.specification
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')}
                                onChange={(e) => {
                                  if (selectedItemsNonEdited.length == 0) {
                                    let nonEditedSelectedItems = JSON.parse(
                                      JSON.stringify(selectedItems)
                                    );

                                    setSelectedItemsNonEdited(
                                      nonEditedSelectedItems
                                    );
                                  }
                                  let value = removeHTML(e.target.value)
                                    .replaceAll("<div>", "")
                                    .replaceAll("</div>", "")
                                    .replaceAll("<br>", "")
                                    .replaceAll("</br>", "")
                                    .replaceAll("&amp;", "and ")
                                    .replaceAll("&nbsp;", " ")
                                    .replaceAll("'", "<single-quote>")
                                    .replaceAll('"', "<double-quote>")
                                    .replaceAll("\n", "<new-line>");
                                  selectedItems[index].specification = value;
                                  setSelectedItems([...selectedItems]);
                                }}
                              />
                            </td>
                          ) : (
                            <td>
                              <ContentEditable
                                // className="wrapInput"
                                style={{
                                  wordWrap: "break-word", // Ensures text wraps to the next line
                                  overflowWrap: "break-word", // Handles text wrapping for modern browsers
                                  maxWidth: "500px", // Limits the width of the content
                                  whiteSpace: "pre-wrap", // Preserves newlines and wraps text
                                  overflow: "hidden", // Prevents content from overflowing
                                  display: "inline-block", // Keeps layout consistent
                                }}
                                contentEditable
                                html={item.description
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')}
                                onChange={(e) => {
                                  if (selectedItemsNonEdited.length == 0) {
                                    let nonEditedSelectedItems = JSON.parse(
                                      JSON.stringify(selectedItems)
                                    );
                                    setSelectedItemsNonEdited(
                                      nonEditedSelectedItems
                                    );
                                  }

                                  let value = removeHTML(e.target.value)
                                    .replaceAll("<div>", "")
                                    .replaceAll("</div>", "")
                                    .replaceAll("<br>", "")
                                    .replaceAll("</br>", "")
                                    .replaceAll("&amp;", "and ")
                                    .replaceAll("&nbsp;", " ")
                                    .replaceAll("'", "<single-quote>")
                                    .replaceAll('"', "<double-quote>")
                                    .replaceAll("\n", "<new-line>");
                                  selectedItems[index].description = value;
                                  setSelectedItems([...selectedItems]);
                                }}
                              />
                            </td>
                          )}

                          <td
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}>
                            <input
                              id="img"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                let tempImageULS = { ...imageULS };
                                tempImageULS[item.OrderId] = e.target.files;
                                setImageULS(tempImageULS);
                              }}
                              ref={imageUploader}
                              style={{}}
                            />
                            <p style={{ marginLeft: "-6em" }} htmlFor="img">
                              {((imageULS &&
                                imageULS[
                                  selectedItems[index]?.OrderId
                                ]?.url?.substring(0, 20)) ||
                                "") + "..." || ""}
                            </p>
                          </td>

                          <td>
                            <ContentEditable
                              className="wrapInput"
                              contentEditable
                              disabled={
                                item?.category === "Freight" ||
                                  item?.type === "Freight" ||
                                  item?.vendorCategory === "Freight"
                                  ? true
                                  : false
                              }
                              html={item.quantity}
                              onChange={(e) => {
                                if (selectedItemsNonEdited.length == 0) {
                                  let nonEditedSelectedItems = JSON.parse(
                                    JSON.stringify(selectedItems)
                                  );
                                  setSelectedItemsNonEdited(
                                    nonEditedSelectedItems
                                  );
                                }

                                let value = e.target.value
                                  .replace(/(?!-)[^0-9.]/g, "")
                                  .replaceAll("<div>", "")
                                  .replaceAll("</div>", "")
                                  .replaceAll("<br>", "")
                                  .replaceAll("</br>", "")
                                  .replaceAll("&amp;", "and ")
                                  .replaceAll("&nbsp;", " ");
                                selectedItems[index].quantity = value;
                                setSelectedItems([...selectedItems]);
                              }}
                            />
                          </td>

                          <td>
                            <ContentEditable
                              className="wrapInput"
                              contentEditable
                              disabled={
                                item?.category === "Freight" ||
                                  item?.type === "Freight" ||
                                  item?.vendorCategory === "Freight"
                                  ? true
                                  : false
                              }
                              html={item.unit}
                              onChange={(e) => {
                                if (selectedItemsNonEdited.length == 0) {
                                  let nonEditedSelectedItems = JSON.parse(
                                    JSON.stringify(selectedItems)
                                  );
                                  setSelectedItemsNonEdited(
                                    nonEditedSelectedItems
                                  );
                                }
                                let value = e.target.value
                                  .replaceAll("<div>", "")
                                  .replaceAll("</div>", "")
                                  .replaceAll("<br>", "")
                                  .replaceAll("</br>", "")
                                  .replaceAll("&amp;", "and ")
                                  .replaceAll("&nbsp;", " ");
                                selectedItems[index].unit = value;
                                setSelectedItems([...selectedItems]);
                              }}
                            />
                          </td>

                          {orderType == "Work Quotation" ||
                            orderType == "Material Quotation" ? (
                            <>
                              {quotationVendorMobile != null ? (
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}>
                                    {"\u20B9 "}
                                    {quotationVendorMobile != null ? (
                                      item.rate
                                    ) : (
                                      <ContentEditable
                                        className="wrapInput"
                                        contentEditable
                                        html={item.rate}
                                        onChange={(e) => {
                                          if (
                                            selectedItemsNonEdited.length == 0
                                          ) {
                                            let nonEditedSelectedItems =
                                              JSON.parse(
                                                JSON.stringify(selectedItems)
                                              );
                                            setSelectedItemsNonEdited(
                                              nonEditedSelectedItems
                                            );
                                          }

                                          let value = e.target.value
                                            .replaceAll("<div>", "")
                                            .replaceAll("</div>", "")
                                            .replaceAll("<br>", "")
                                            .replaceAll("</br>", "")
                                            .replaceAll("&amp;", "and ")
                                            .replaceAll("&nbsp;", " ");
                                          selectedItems[index].rate = value;
                                          if (item?.category === "Freight" || item?.type === "Freight" || item?.vendorCategory === "Freight") {
                                            setFreight(value)
                                          }

                                          setSelectedItems([...selectedItems]);
                                        }}
                                      />
                                    )}
                                  </div>
                                </td>
                              ) : null}
                            </>
                          ) : (
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}>
                                {"\u20B9 "}
                                {quotationVendorMobile != null ? (
                                  item.rate
                                ) : (
                                  <ContentEditable
                                    className="wrapInput"
                                    contentEditable
                                    html={item.rate}
                                    onChange={(e) => {
                                      if (selectedItemsNonEdited.length == 0) {
                                        let nonEditedSelectedItems = JSON.parse(
                                          JSON.stringify(selectedItems)
                                        );
                                        setSelectedItemsNonEdited(
                                          nonEditedSelectedItems
                                        );
                                      }

                                      let value = e.target.value
                                        .replaceAll("<div>", "")
                                        .replaceAll("</div>", "")
                                        .replaceAll("<br>", "")
                                        .replaceAll("</br>", "")
                                        .replaceAll("&amp;", "and ")
                                        .replaceAll("&nbsp;", " ");
                                      selectedItems[index].rate = value;
                                      if (item?.category === "Freight" || item?.type === "Freight" || item?.vendorCategory === "Freight") {
                                        setFreight(value)
                                      }
                                      setSelectedItems([...selectedItems]);
                                    }}
                                  />
                                )}
                              </div>
                            </td>
                          )}
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}>
                              <ContentEditable
                                className="wrapInput"
                                contentEditable
                                disabled={
                                  item?.category === "Freight" ||
                                    item?.type === "Freight" ||
                                    item?.vendorCategory === "Freight"
                                    ? true
                                    : false
                                }
                                html={item.gst}
                                onChange={(e) => {
                                  if (selectedItemsNonEdited.length == 0) {
                                    let nonEditedSelectedItems = JSON.parse(
                                      JSON.stringify(selectedItems)
                                    );
                                    setSelectedItemsNonEdited(
                                      nonEditedSelectedItems
                                    );
                                  }

                                  let value = e.target.value
                                    .replaceAll("<div>", "")
                                    .replaceAll("</div>", "")
                                    .replaceAll("<br>", "")
                                    .replaceAll("</br>", "")
                                    .replaceAll("&amp;", "and ")
                                    .replaceAll("&nbsp;", " ");
                                  selectedItems[index].gst = value;
                                  setSelectedItems([...selectedItems]);
                                }}
                              />
                              %
                            </div>
                          </td>
                          {orderType == "Work Quotation" ||
                            orderType == "Material Quotation" ? null : (
                            <td>
                              {"\u20B9 "}{" "}
                              {(
                                item["quantity"] * item["rate"] +
                                item["quantity"] *
                                item["rate"] *
                                (item["gst"] / 100)
                              ).toFixed(2)}{" "}
                            </td>
                          )}
                          <div style={{ pointer: "cursor" }}>
                            <PopOverView
                              position="left"
                              component={
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                    fontSize: 12,
                                    color: "grey",
                                    alignItems: "flex-start",
                                    width: 140,
                                  }}>
                                  <p
                                    className="menuItem"
                                    onMouseDown={(e) => {
                                      e.stopPropagation();
                                      setDeltedItemsToUnfreeze((prev) => {
                                        return [...prev, selectedItems[index]];
                                      });
                                      selectedItems.splice(index, 1);
                                      setSelectedItems([...selectedItems]);

                                      let tempmilestoneTobeAddedIndex = [
                                        ...milestoneTobeAddedIndex,
                                      ];
                                      let tempIndex;
                                      if (
                                        tempmilestoneTobeAddedIndex.indexOf(
                                          index
                                        )
                                      ) {
                                        for (
                                          let temp = 0;
                                          temp <
                                          tempmilestoneTobeAddedIndex.length;
                                          temp++
                                        ) {
                                          if (
                                            tempmilestoneTobeAddedIndex[temp] >
                                            index
                                          ) {
                                            tempIndex = temp;
                                            break;
                                          }
                                        }
                                      } else {
                                        tempIndex =
                                          tempmilestoneTobeAddedIndex.indexOf(
                                            index
                                          );
                                      }
                                      for (
                                        let item = tempIndex;
                                        item <
                                        tempmilestoneTobeAddedIndex.length;
                                        item++
                                      ) {
                                        if (
                                          tempmilestoneTobeAddedIndex[item] == 0
                                        ) {
                                        } else {
                                          tempmilestoneTobeAddedIndex[item] =
                                            tempmilestoneTobeAddedIndex[item] -
                                            1;
                                        }
                                      }

                                      setMilestoneTobeAddedIndex(
                                        tempmilestoneTobeAddedIndex
                                      );
                                      record("@removed_selected_item_success", {
                                        description:
                                          "removed selected item success",
                                      });
                                    }}>
                                    Delete
                                  </p>
                                </div>
                              }>
                              <FiMoreHorizontal />
                            </PopOverView>
                          </div>
                        </tr>
                      </tbody>
                    );
                  }
                })}
              </table>

              {milestoneTobeAddedIndex.length == 0 ? null : (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: 50,
                    marginBottom: 20,
                    fontWeight: 700,
                    fontSize: 20,
                    color: "#9b9b9a",
                  }}>
                  Line Items - custom milestones
                </p>
              )}
              {<div style={{ display: "none" }}>{(orderNo = 0)}</div>}

              {milestoneTobeAddedIndex.length == 0 ? null : (
                <table id="table1">
                  <tbody>
                    <tr>
                      <th
                        style={{
                          color: "#C4C4C4",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}>
                        Items
                      </th>
                      <th
                        style={{
                          color: "#C4C4C4",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}>
                        Description
                      </th>
                      <th
                        style={{
                          color: "#C4C4C4",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}>
                        Image
                      </th>
                      <th
                        style={{
                          color: "#C4C4C4",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}>
                        Quantity
                      </th>
                      <th
                        style={{
                          color: "#C4C4C4",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}>
                        Unit
                      </th>

                      {orderType == "Work Quotation" ||
                        orderType == "Material Quotation" ? (
                        <>
                          {quotationVendorMobile != null ? (
                            <th
                              style={{
                                backgroundColor: "white",
                                color: "#C4C4C4",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                              }}>
                              Rate
                            </th>
                          ) : null}
                        </>
                      ) : (
                        <th
                          style={{
                            backgroundColor: "white",
                            color: "#C4C4C4",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                          }}>
                          Rate
                        </th>
                      )}
                      <th
                        style={{
                          backgroundColor: "white",
                          color: "#C4C4C4",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}>
                        GST
                      </th>
                      {orderType == "Work Quotation" ||
                        orderType == "Material Quotation" ? null : (
                        <th
                          style={{
                            backgroundColor: "white",
                            color: "#C4C4C4",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                          }}>
                          Amount
                        </th>
                      )}
                    </tr>
                  </tbody>
                  {selectedItems.map((item, index) => {
                    if (milestoneTobeAddedIndex.includes(index)) {
                      orderNo = orderNo + 1;
                      return (
                        <tbody key={index}>
                          <tr style={{ textAlign: "center" }} key={index}>
                            {"type" in item ||
                              ("workType" in item &&
                                item.workType == "Only Material") ? (
                              <td>{item.type || item.category}</td>
                            ) : (
                              <td>{item.category || item.vendorCategory}</td>
                            )}
                            {"type" in item ? (
                              <td>
                                <ContentEditable
                                  // className="wrapInput"
                                  style={{
                                    wordWrap: "break-word", // Ensures text wraps to the next line
                                    overflowWrap: "break-word", // Handles text wrapping for modern browsers
                                    maxWidth: "500px", // Limits the width of the content
                                    whiteSpace: "pre-wrap", // Preserves newlines and wraps text
                                    overflow: "hidden", // Prevents content from overflowing
                                    display: "inline-block", // Keeps layout consistent
                                  }}
                                  contentEditable
                                  html={item.specification}
                                  onChange={(e) => {
                                    if (selectedItemsNonEdited.length == 0) {
                                      let nonEditedSelectedItems = JSON.parse(
                                        JSON.stringify(selectedItems)
                                      );
                                      setSelectedItemsNonEdited(
                                        nonEditedSelectedItems
                                      );
                                    }

                                    let value = removeHTML(e.target.value)
                                      .replaceAll("<div>", "")
                                      .replaceAll("</div>", "")
                                      .replaceAll("<br>", "")
                                      .replaceAll("</br>", "")
                                      .replaceAll("&amp;", "and ")
                                      .replaceAll("&nbsp;", " ")
                                      .replaceAll("'", "<single-quote>")
                                      .replaceAll('"', "<double-quote>");
                                    selectedItems[index].specification = value;
                                    setSelectedItems([...selectedItems]);
                                  }}
                                />
                              </td>
                            ) : (
                              <td>
                                <ContentEditable
                                  // className="wrapInput"
                                  style={{
                                    wordWrap: "break-word", // Ensures text wraps to the next line
                                    overflowWrap: "break-word", // Handles text wrapping for modern browsers
                                    maxWidth: "500px", // Limits the width of the content
                                    whiteSpace: "pre-wrap", // Preserves newlines and wraps text
                                    overflow: "hidden", // Prevents content from overflowing
                                    display: "inline-block", // Keeps layout consistent
                                  }}
                                  contentEditable
                                  html={item.description
                                    .replace(/<new-line>/g, "\n")
                                    .replace(/<single-quote>/g, "'")
                                    .replace(/<double-quote>/g, '"')}
                                  onChange={(e) => {
                                    if (selectedItemsNonEdited.length == 0) {
                                      let nonEditedSelectedItems = JSON.parse(
                                        JSON.stringify(selectedItems)
                                      );
                                      setSelectedItemsNonEdited(
                                        nonEditedSelectedItems
                                      );
                                    }
                                    let value = removeHTML(e.target.value)
                                      .replaceAll("<div>", "")
                                      .replaceAll("</div>", "")
                                      .replaceAll("<br>", "")
                                      .replaceAll("</br>", "")
                                      .replaceAll("&amp;", "and ")
                                      .replaceAll("&nbsp;", " ")
                                      .replaceAll("'", "<single-quote>")
                                      .replaceAll('"', "<double-quote>");
                                    selectedItems[index].description = value;
                                    setSelectedItems([...selectedItems]);
                                  }}
                                />
                              </td>
                            )}

                            <td
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}>
                              <input
                                id="img"
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  let tempImageULS = [...imageULS];
                                  tempImageULS[index] = e.target.files;
                                  setImageULS(tempImageULS);
                                }}
                                ref={imageUploader}
                                style={{}}
                              />
                              <p style={{ marginLeft: "-6em" }} htmlFor="img">
                                {((imageULS &&
                                  imageULS[
                                    selectedItems[index]?.OrderId
                                  ]?.url?.substring(0, 20)) ||
                                  "") + "..." || ""}
                              </p>
                            </td>

                            <td>
                              <ContentEditable
                                className="wrapInput"
                                contentEditable
                                html={item.quantity}
                                onChange={(e) => {
                                  if (selectedItemsNonEdited.length == 0) {
                                    let nonEditedSelectedItems = JSON.parse(
                                      JSON.stringify(selectedItems)
                                    );
                                    setSelectedItemsNonEdited(
                                      nonEditedSelectedItems
                                    );
                                  }
                                  let value = e.target.value
                                    .replace(/(?!-)[^0-9.]/g, "")
                                    .replaceAll("<div>", "")
                                    .replaceAll("</div>", "")
                                    .replaceAll("<br>", "")
                                    .replaceAll("</br>", "")
                                    .replaceAll("&amp;", "and ")
                                    .replaceAll("&nbsp;", " ");
                                  selectedItems[index].quantity = value;
                                  setSelectedItems([...selectedItems]);
                                }}
                              />
                            </td>
                            <td>
                              <ContentEditable
                                className="wrapInput"
                                contentEditable
                                html={item.unit}
                                onChange={(e) => {
                                  if (selectedItemsNonEdited.length == 0) {
                                    let nonEditedSelectedItems = JSON.parse(
                                      JSON.stringify(selectedItems)
                                    );
                                    setSelectedItemsNonEdited(
                                      nonEditedSelectedItems
                                    );
                                  }
                                  let value = e.target.value
                                    .replaceAll("<div>", "")
                                    .replaceAll("</div>", "")
                                    .replaceAll("<br>", "")
                                    .replaceAll("</br>", "")
                                    .replaceAll("&amp;", "and ")
                                    .replaceAll("&nbsp;", " ");
                                  selectedItems[index].unit = value;
                                  setSelectedItems([...selectedItems]);
                                }}
                              />
                            </td>
                            {orderType == "Work Quotation" ||
                              orderType == "Material Quotation" ? (
                              <>
                                {quotationVendorMobile != null ? (
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}>
                                      {"\u20B9 "}
                                      {quotationVendorMobile != null ? (
                                        item.rate
                                      ) : (
                                        <ContentEditable
                                          className="wrapInput"
                                          contentEditable
                                          html={item.rate}
                                          onChange={(e) => {
                                            if (
                                              selectedItemsNonEdited.length == 0
                                            ) {
                                              let nonEditedSelectedItems =
                                                JSON.parse(
                                                  JSON.stringify(selectedItems)
                                                );
                                              setSelectedItemsNonEdited(
                                                nonEditedSelectedItems
                                              );
                                            }
                                            let value = e.target.value
                                              .replaceAll("<div>", "")
                                              .replaceAll("</div>", "")
                                              .replaceAll("<br>", "")
                                              .replaceAll("</br>", "")
                                              .replaceAll("&amp;", "and ")
                                              .replaceAll("&nbsp;", " ");
                                            selectedItems[index].rate = value;
                                            setSelectedItems([
                                              ...selectedItems,
                                            ]);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                ) : null}
                              </>
                            ) : (
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}>
                                  {"\u20B9 "}
                                  {quotationVendorMobile != null ? (
                                    item.rate
                                  ) : (
                                    <ContentEditable
                                      className="wrapInput"
                                      contentEditable
                                      html={item.rate}
                                      onChange={(e) => {
                                        if (
                                          selectedItemsNonEdited.length == 0
                                        ) {
                                          let nonEditedSelectedItems =
                                            JSON.parse(
                                              JSON.stringify(selectedItems)
                                            );
                                          setSelectedItemsNonEdited(
                                            nonEditedSelectedItems
                                          );
                                        }
                                        let value = e.target.value
                                          .replaceAll("<div>", "")
                                          .replaceAll("</div>", "")
                                          .replaceAll("<br>", "")
                                          .replaceAll("</br>", "")
                                          .replaceAll("&amp;", "and ")
                                          .replaceAll("&nbsp;", " ");
                                        selectedItems[index].rate = value;
                                        setSelectedItems([...selectedItems]);
                                      }}
                                    />
                                  )}
                                </div>
                              </td>
                            )}

                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}>
                                <ContentEditable
                                  className="wrapInput"
                                  contentEditable
                                  html={item.gst}
                                  onChange={(e) => {
                                    if (selectedItemsNonEdited.length == 0) {
                                      let nonEditedSelectedItems = JSON.parse(
                                        JSON.stringify(selectedItems)
                                      );
                                      setSelectedItemsNonEdited(
                                        nonEditedSelectedItems
                                      );
                                    }

                                    let value = e.target.value
                                      .replaceAll("<div>", "")
                                      .replaceAll("</div>", "")
                                      .replaceAll("<br>", "")
                                      .replaceAll("</br>", "")
                                      .replaceAll("&amp;", "and ")
                                      .replaceAll("&nbsp;", " ");
                                    selectedItems[index].gst = value;
                                    setSelectedItems([...selectedItems]);
                                  }}
                                />
                                %
                              </div>
                            </td>

                            {orderType == "Work Quotation" ||
                              orderType == "Material Quotation" ? null : (
                              <td>
                                {"\u20B9 "}{" "}
                                {(
                                  item["quantity"] * item["rate"] +
                                  item["quantity"] *
                                  item["rate"] *
                                  (item["gst"] / 100)
                                ).toFixed(2)}{" "}
                              </td>
                            )}
                            <td>
                              <div style={{ pointer: "cursor" }}>
                                <PopOverView
                                  position="left"
                                  component={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 10,
                                        fontSize: 12,
                                        color: "grey",
                                        alignItems: "flex-start",
                                        width: 140,
                                      }}>
                                      <p
                                        className="menuItem"
                                        onMouseDown={(e) => {
                                          e.stopPropagation();
                                          selectedItems.splice(index, 1);
                                          setSelectedItems([...selectedItems]);
                                          let tempmilestoneTobeAddedIndex = [
                                            ...milestoneTobeAddedIndex,
                                          ];
                                          let tempIndex =
                                            tempmilestoneTobeAddedIndex.indexOf(
                                              index
                                            );
                                          tempmilestoneTobeAddedIndex.splice(
                                            tempIndex,
                                            1
                                          );
                                          for (
                                            let item = tempIndex;
                                            item <
                                            tempmilestoneTobeAddedIndex.length;
                                            item++
                                          ) {
                                            tempmilestoneTobeAddedIndex[item] =
                                              tempmilestoneTobeAddedIndex[
                                              item
                                              ] - 1;
                                          }
                                          setMilestoneTobeAddedIndex(
                                            tempmilestoneTobeAddedIndex
                                          );
                                          record(
                                            "@removed_selected_item_success",
                                            {
                                              description:
                                                "removed selected item success",
                                            }
                                          );
                                        }}>
                                        Delete
                                      </p>
                                    </div>
                                  }>
                                  <FiMoreHorizontal />
                                </PopOverView>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    } else {
                    }
                  })}
                </table>
              )}
            </div>

            <div>
              {quotationVendorMobile != null ? null : (
                <div
                  style={{
                    border: `1px dashed ${selectedItems.length > 0 ? "#C4C4C4" : "tomato"
                      }`,
                    borderRadius: "6px",
                    padding: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px 0px",
                    gap: 40,
                  }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #FDD34D",
                      padding: "10px 30px",
                      minWidth: "300px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsAddItemsFormOpen(true);
                    }}>
                    <p>Add new item *</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #FDD34D",
                      padding: "10px 30px",
                      minWidth: "300px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsUnreleasedItemsFormOpen(true)}>
                    <p>Select from unreleased items *</p>
                  </div>
                </div>
              )}

              <Dialog
                open={isAddItemsFormOpen}
                onClose={() => setIsAddItemsFormOpen(false)}>
                <AddItemsForm
                  handleClose={() => {
                    setIsAddItemsFormOpen(false);
                  }}
                  data={data}
                  sendItems={(itemsReceived) => {
                    itemsReceived = itemsReceived.map((item) => ({
                      ...item,
                      milestones: defaultMilestones,
                    }));
                    let tempSelectedItems = [...selectedItems];
                    let totalItems = tempSelectedItems.concat(itemsReceived);
                    setSelectedItems(totalItems);
                    let tempAddedItems = [...addedItems];
                    let totalAddedItems = tempAddedItems.concat(itemsReceived);
                    setAddedItems(totalAddedItems);
                  }}
                  orderType={orderType}
                  addedItems={addedItems}
                />
              </Dialog>
              <Dialog
                open={isUnreleasedItemsFormOpen}
                onClose={() => setIsUnreleasedItemsFormOpen(false)}>
                <UnreleasedItemsForm
                  handleClose={() => {
                    setIsUnreleasedItemsFormOpen(false);
                  }}
                  sendItems={(itemsReceived) => {
                    itemsReceived = itemsReceived.map((item) => ({
                      ...item,
                      milestones: defaultMilestones,
                    }));
                    let totalItems = selectedItems.concat(itemsReceived);
                    setSelectedItems(totalItems);
                  }}
                  data={data}
                  orderType={orderType}
                  sendCheckedWorks={(checkedWorksReceived) => {
                    let totalCheckedWorks =
                      checkedWorks.concat(checkedWorksReceived);
                    setCheckedWorks(totalCheckedWorks);
                  }}
                  selectedItems={selectedItems}
                />
              </Dialog>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
          }}>
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <div
              style={{
                padding: 5,
                backgroundColor: "#FDD34D",
                borderStyle: "none",
                borderWidth: "1",
                borderTopRightRadius: 3,
                borderTopLeftRadius: 3,
                opacity: "0.5",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                padding: 10,
                gap: 20,
                backgroundColor: "#FCFCFC",
              }}>
              <p
                style={{
                  color: "#353535",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}>
                Contact Person & Site Engineer Details
              </p>
              <p style={{ color: "#353535", fontWeight: "600" }}>
                Contact Person
              </p>
              <TextArea
                placeholder={"Contact Person Name *"}
                value={contactPersonName}
                onChange={(text) => setContactPersonName(text)}
                autoFocus={contactPersonNameRef}
                suggestions={true}
                suggestionOptions={(poSuggestions || []).map((item) =>
                  item.ContactPersonName.replace(/<new-line>/g, "\n")
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"')
                )}
              />
              <TextArea
                placeholder={"Contact Person Number *"}
                value={contactPersonNumber}
                onChange={(text) =>
                  setContactPersonNumber(text.replace(/[^0-9]+/gm, ""))
                }
                autoFocus={contactPersonNumberRef}
                suggestions={true}
                suggestionOptions={(poSuggestions || []).map((item) =>
                  item.ContactPersonNumber.replace(/<new-line>/g, "\n")
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"')
                )}
              />

              <p style={{ color: "#353535", fontWeight: "600" }}>
                Site Engineer
              </p>
              <Input
                placeholder={"Site Engineer Name *"}
                value={siteEngineerDetails.siteEngineerName}
                onChange={(text) => {
                  setSiteEngineerDetails((prev) => ({
                    ...prev,
                    siteEngineerName: text,
                  }));
                }}
                placeHolderAlwaysOnTop={true}
                autoFocus={siteEngineerNameRef}
              />
              <Input
                placeholder={"Site Engineer Number *"}
                value={siteEngineerDetails.siteEngineerNumber}
                onChange={(text) => {
                  // setSiteEngineerNumber(text.replace(/[^0-9]+/gm, ""))
                  setSiteEngineerDetails((prev) => ({
                    ...prev,
                    siteEngineerNumber: text.replace(/[^0-9]+/gm, ""),
                  }));
                }}
                placeHolderAlwaysOnTop={true}
                autoFocus={siteEngineerNumberRef}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 20,
            }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  padding: 5,
                  backgroundColor: "#FDD34D",
                  borderStyle: "none",
                  borderWidth: "1",
                  borderTopRightRadius: 3,
                  borderTopLeftRadius: 3,
                  opacity: "0.5",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "space-between",
                  padding: 10,
                  gap: 20,
                  backgroundColor: "#FCFCFC",
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}>
                  <p style={{ color: "#353535" }}>Basic value</p>
                  <Input
                    value={selectedItems.reduce(
                      (total, item) =>
                        Number(total) +
                        Number(
                          (
                            Number(item["quantity"] * item["rate"]) +
                            Number(
                              item["quantity"] *
                              item["rate"] *
                              (item["gst"] / 100)
                            )
                          ).toFixed(2)
                        ),
                      0
                    )}
                    disabled
                    hideRedLine
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}>
                  <p style={{ color: "#353535" }}>Discount</p>
                  <Input
                    value={discount}
                    onChange={(text) => {
                      if (text <= 100) {
                        setDiscount(text.replace(/[^0-9.]+/gm, ""));
                      }
                    }}
                    hideRedLine
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}>
                  <p style={{ color: "#353535", fontWeight: "bold" }}>
                    Total value including Taxes and Duties
                  </p>
                  <Input value={totalAmount} disabled />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  padding: 5,
                  backgroundColor: "#FDD34D",
                  borderStyle: "none",
                  borderWidth: "1",
                  borderTopRightRadius: 3,
                  borderTopLeftRadius: 3,
                  opacity: "0.5",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 10,
                  gap: 20,
                  backgroundColor: "#FCFCFC",
                }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="dd/MM/yyyy"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    disablePast={draft || FromReleasedItem ? false : true}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="End Date"
                    format="dd/MM/yyyy"
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    minDate={new Date(startDate)}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
          }}>
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <div
              style={{
                padding: 5,
                backgroundColor: "#FDD34D",
                borderStyle: "none",
                borderWidth: "1",
                borderTopRightRadius: 3,
                borderTopLeftRadius: 3,
                opacity: "0.5",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                padding: 10,
                gap: 20,
                backgroundColor: "#FCFCFC",
              }}>
              <p
                style={{
                  color: "#353535",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}>
                Vendor Details
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <DropDown
                  data={vendors}
                  value={vendorMobile}
                  onChange={(value) =>
                    value == "Show All Vendors"
                      ? setShowAllVendors(true)
                      : setVendorMobile(value)
                  }
                  disabled={quotationVendorMobile != null}
                />
                {quotationVendorMobile != null ? null : (
                  <AddVendor
                    getIsVendorModalClosed={(itemReceived) => {
                      itemReceived && getVendors();
                    }}
                  />
                )}
              </div>
              {vendorMobile == null ||
                vendorMobile == "null" ||
                vendorMobile == "Open Vendor" ||
                quotationVendorMobile != null ? null : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 20,
                    }}>
                    <TextArea
                      placeholder={"Name"}
                      value={vendorMobile.split(":")[0].trim()}
                      disabled
                      hideRedLine
                    />
                    <TextArea
                      placeholder={"Contact Number"}
                      value={vendorMobile.split(":")[1].trim()}
                      disabled
                      hideRedLine
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 20,
                    }}>
                    <TextArea
                      placeholder={"Firm Name"}
                      value={vendorFirm}
                      onChange={(text) => setVendorFirm(text)}
                      hideRedLine
                      disabled
                    />
                    <TextArea
                      placeholder={"Vendor Company Name"}
                      value={vendorCompanyName}
                      onChange={(text) => setVendorCompanyName(text)}
                      hideRedLine
                      disabled
                    />
                  </div>

                  <TextArea
                    placeholder={"GSTIN"}
                    value={vendorGSTIN}
                    onChange={(text) => setVendorGSTIN(text)}
                    hideRedLine
                  />
                  <p style={{ color: "#353535", fontWeight: "600" }}>Address</p>
                  <TextArea
                    placeholder={"Address"}
                    value={vendorAddress || ""}
                    onChange={(text) => setVendorAddress(text)}
                    hideRedLine
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 20,
                    }}>
                    <TextArea
                      placeholder={"Pin Code"}
                      value={vendorPinCode || ""}
                      onChange={(text) => setVendorPinCode(text)}
                      hideRedLine
                    />
                    <TextArea
                      placeholder={"City"}
                      value={vendorCity}
                      onChange={(text) => setVendorCity(text)}
                      hideRedLine
                    />
                    <TextArea
                      placeholder={"State"}
                      value={vendorState}
                      onChange={(text) => setVendorState(text)}
                      hideRedLine
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: 20,
            }}>
            <div>
              <div
                style={{
                  padding: 5,
                  backgroundColor: "#FDD34D",
                  borderStyle: "none",
                  borderWidth: "1",
                  borderTopRightRadius: 3,
                  borderTopLeftRadius: 3,
                  opacity: "0.5",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "space-between",
                  padding: 10,
                  gap: 20,
                  backgroundColor: "#FCFCFC",
                }}>
                <p
                  style={{
                    color: "#353535",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}>
                  Terms & Conditions
                </p>
                <TextArea
                  placeholder={"Enter T&C"}
                  value={termsAndConditions}
                  onChange={(text) => setTermsAndConditions(text)}
                  suggestions={true}
                  suggestionOptions={poSuggestions.map((item) =>
                    item.TermsandConditions.replace(/<new-line>/g, "\n")
                      .replace(/<single-quote>/g, "'")
                      .replace(/<double-quote>/g, '"')
                  )}
                  hideRedLine
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              padding: 5,
              backgroundColor: "#FDD34D",
              borderStyle: "none",
              borderWidth: "1",
              borderTopRightRadius: 3,
              borderTopLeftRadius: 3,
              opacity: "0.5",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
              padding: 10,
              gap: 20,
              backgroundColor: "#FCFCFC",
            }}>
            <p
              style={{
                color: "#353535",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}>
              Overall Payment Terms
            </p>

            <TextArea
              placeholder={"Enter Terms"}
              value={paymentTerms}
              onChange={(text) => setPaymentTerms(text)}
              hideRedLine
            />
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              padding: 5,
              backgroundColor: "#FDD34D",
              borderStyle: "none",
              borderWidth: "1",
              borderTopRightRadius: 3,
              borderTopLeftRadius: 3,
              opacity: "0.5",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 10,
              gap: 20,
              backgroundColor: "#FCFCFC",
            }}>
            <p
              style={{
                color: "#353535",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}>
              Special Instructions
            </p>

            <TextArea
              placeholder={"Enter Instructions"}
              value={specialInstructions}
              onChange={(text) => setSpecialInstructions(text)}
              suggestions={true}
              suggestionOptions={poSuggestions.map((item) =>
                item.SpecialInstructions.replace(/<new-line>/g, "\n")
                  .replace(/<single-quote>/g, "'")
                  .replace(/<double-quote>/g, '"')
              )}
              hideRedLine
            />

            <p
              style={{
                color: "#353535",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}>
              Details
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 20,
              }}>
              <div>
                <TextArea
                  placeholder={'Freight Amount'}
                  value={freight?.value ? freight.value : null}
                  onChange={(text) => {
                    setFreight(
                      {
                        value: text
                          .replace(/[^0-9]+/gm, '')
                          .replace(/<single-quote>/g, "'")
                          .replace(/<double-quote>/g, '"'),
                        description: freight.description == null ? null : freight.description
                      }
                    );

                    setTextFieldValue(text);
                    if (!freightExists) {
                      let indexces = fetchIndexcesForAddNewItem({ rooms: data.rooms });

                      if (orderType === 'Purchase Order' || orderType === 'Material Quotation') {
                        const materialObject = {
                          OrderId: new Date().getTime(),
                          UnitName: 'Freight Charges',
                          specification: 'Freight Charges material',
                          indexces: {
                            roomIndex: indexces.roomIndex,
                            unitIndex: indexces.freightChargesIndex,
                            componentIndex: indexces.componentIndex,
                            materialIndex:
                              itemsAdded.length > 0
                                ? itemsAdded[itemsAdded.length - 1].indexces.materialIndex + 1
                                : addedItems.length > 0
                                  ? addedItems[addedItems.length - 1].indexces.materialIndex + 1
                                  : 0,
                          },
                          quantity: '1',
                          unit: 'unit',
                          rate: text,
                          gst: '0',
                          type: 'Freight',
                          workType: 'Only Material',
                          status: 'ordered',
                          orderStatus: '',
                          item: '',
                          freight: text,
                        };
                        setAddedItems([...itemsAdded, materialObject]);
                      } else {
                        const workObject = {
                          Drawings: [],
                          OrderId: new Date().getTime(),
                          UnitName: 'Freight Charges',
                          description: 'Freight Charges Work',
                          indexces: {
                            roomIndex: indexces.roomIndex,
                            unitIndex: indexces.freightChargesIndex,
                            componentIndex: indexces.componentIndex,
                            workIndex:
                              itemsAdded.length > 0
                                ? itemsAdded[itemsAdded.length - 1].indexces.workIndex + 1
                                : addedItems.length > 0
                                  ? addedItems[addedItems.length - 1].indexces.workIndex + 1
                                  : 0,
                          },
                          unit: 'unit',
                          quantity: '1',
                          rate: text,
                          gst: '0',
                          vendorCategory: 'Freight',
                          workType: 'Only Work',
                          status: 'ordered',
                          orderStatus: '',
                          item: 'Freight',
                          freight: text,
                        };
                        setAddedItems([...itemsAdded, workObject]);
                      }
                    }
                  }}
                  hideRedLine
                />
              </div>

              <TextArea
                placeholder={"Freight Description"}
                value={freight?.description ? freight.description : null}
                onChange={(text) =>
                  // setInsurance(text)
                  setFreight(
                    {
                      value: freight.value == null ? null : freight.value,
                      description: text
                        .replace(/<single-quote>/g, "'")
                        .replace(/<double-quote>/g, '"')
                    }
                  )
                }
                suggestions={true}
                suggestionOptions={poSuggestions.map((item) =>
                  item.SpecialInstructions.replace(/<new-line>/g, "\n")
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"')
                )}
                hideRedLine
              />

              <TextArea
                placeholder={"Insurance"}
                value={insurance}
                onChange={(text) => setInsurance(text)}
                suggestions={true}
                suggestionOptions={poSuggestions.map((item) =>
                  item.SpecialInstructions.replace(/<new-line>/g, "\n")
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"')
                )}
                hideRedLine
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
          }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div
              style={{
                padding: 5,
                backgroundColor: "#FDD34D",
                borderStyle: "none",
                borderWidth: "1",
                borderTopRightRadius: 3,
                borderTopLeftRadius: 3,
                opacity: "0.5",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                gap: 20,
                backgroundColor: "#FCFCFC",
              }}>
              <p
                style={{
                  color: "#353535",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}>
                Ship To Address
              </p>
              <TextArea
                placeholder={"Shipping Address *"}
                value={shippingAddress}
                onChange={(text) => setShippingAddress(text)}
                autoFocus={shippingAddressRef}
              />
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div
              style={{
                padding: 5,
                backgroundColor: "#FDD34D",
                borderStyle: "none",
                borderWidth: "1",
                borderTopRightRadius: 3,
                borderTopLeftRadius: 3,
                opacity: "0.5",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                gap: 20,
                backgroundColor: "#FCFCFC",
              }}>
              <p
                style={{
                  color: "#353535",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}>
                Bill To Address
              </p>
              <TextArea
                placeholder={"Billing Address *"}
                value={billingAddress}
                onChange={(text) => setBillingAddress(text)}
                suggestions={true}
                suggestionOptions={poSuggestions.map((item) =>
                  item.BillingAddress.replace(/<new-line>/g, "\n")
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"')
                )}
                autoFocus={billingAddressRef}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              color: "grey",
            }}>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                fileDialog().then(async (files) => {
                  handleFileUpload(files);
                });
              }}>
              <FiPaperclip size={15} style={{ marginRight: 10 }} />
              Add Attachments
            </p>
            {attachments.map((item, index) => (
              <div key={index}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.name}
                </a>
                <button onClick={() => handleDeleteAttachment(index)}>
                  <FiTrash2 />
                </button>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {firmSignature == "" ? (
              <p>
                No signature found! Save your firm signature in suggestive
                items.
              </p>
            ) : (
              <>
                <img
                  src={firmSignature.url}
                  style={{ width: 100, height: 100 }}
                />
                Signature
              </>
            )}
          </div>
        </div>

        <div
          style={{
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            gap: 20,
          }}>
          {!FromReleasedItem && (
            <Button
              invert
              content={draftId ? `Update Draft` : `Save as Draft`}
              onClick={() => {
                draftId ? updateDraft() : saveDraft();
              }}
            />
          )}
          {/* {FromReleasedItem ?
          <Button
            content="Preview & Re-Release"
            onClick={()=>{

            }}
          /> :  */}
          {orderType == "Work Quotation" ||
            orderType == "Material Quotation" ? (
            ""
          ) : (
            <Button
              invert
              // style={{flex: " 0.75 1"}}
              content={"Send for Approval"}
              onClick={() => {
                setIsApproval(true);
                if (isMandatoryImages.current) {
                  if (
                    selectedItems?.length -
                    Object.values(imageULS).filter((item) => item !== null)
                      .length ===
                    0
                  ) {
                    previewHandler();
                  } else {
                    toast.error("Please Upload all Images!", {
                      duration: 4000,
                      position: "bottom-center",
                      style: {
                        borderRadius: "8px",
                        padding: "16px",
                        color: "#E72424",
                        backgroundColor: "#FEF0F0",
                        maxWidth: "100%",
                      },
                      icon: <FiInfo color={"#E72424"} />,
                    });
                  }
                } else {
                  previewHandler();
                }
              }}
            />
          )}
          <Button
            content={"Preview & Release"}
            onClick={() => {
              if (isMandatoryImages.current) {
                if (
                  selectedItems?.length -
                  Object.values(imageULS).filter((item) => item !== null)
                    .length ===
                  0
                ) {
                  previewHandler();
                } else {
                  toast.error("Please Upload all Images!", {
                    duration: 4000,
                    position: "bottom-center",
                    style: {
                      borderRadius: "8px",
                      padding: "16px",
                      color: "#E72424",
                      backgroundColor: "#FEF0F0",
                      maxWidth: "100%",
                    },
                    icon: <FiInfo color={"#E72424"} />,
                  });
                }
              } else {
                previewHandler();
              }
            }}
          />
          <Dialog
            open={isPreviewModalOpen}
            onClose={() => {
              setIsPreviewModalOpen(false);
              setIsApproval(false);
            }}
            fullWidth
            maxWidth="md">
            <Preview
              handleClose={() => {
                setIsPreviewModalOpen(false);
              }}
              isApproval={isApproval}
              orderType={orderType}
              selectedItems={selectedItems}
              contactPersonName={contactPersonName}
              contactPersonNumber={contactPersonNumber}
              siteEngineerName={siteEngineerDetails.siteEngineerName}
              siteEngineerNumber={siteEngineerDetails.siteEngineerNumber}
              discount={discount}
              totalAmount={totalAmount}
              startDate={startDate}
              endDate={endDate}
              vendorMobile={vendorMobile}
              // vendorFirm={vendorFirm}
              vendorFirm={vendorCompanyName}
              vendorGSTIN={vendorGSTIN}
              vendorAddress={vendorAddress}
              vendorPinCode={vendorPinCode}
              vendorCity={vendorCity}
              vendorState={vendorState}
              termsAndConditions={termsAndConditions}
              paymentTerms={paymentTerms}
              specialInstructions={specialInstructions}
              priceBase={priceBase}
              freight={freight}
              insurance={insurance}
              shippingAddress={shippingAddress}
              billingAddress={billingAddress}
              firmName={firmName}
              firmAddress={firmAddress}
              firmPhoneNumber={firmPhoneNumber}
              firmGSTIN={firmGSTIN}
              firmContactPersonName={firmContactPersonName}
              firmContactPersonNumber={firmContactPersonNumber}
              firmContactPersonEmail={firmContactPersonEmail}
              firmSignature={firmSignature}
              attachments={attachments}
              releaseOpportunityQuotation={() => {
                if (FromReleasedItem) {
                  reReleaseOrder();
                } else {
                  orderType == "Purchase Order" || orderType == "Work Order"
                    ? makeOpportunity()
                    : makeQuotation();
                }
              }}
              milestoneTobeAddedIndex={milestoneTobeAddedIndex}
              imageULS={imageULS}
              setIsUpdatingProgress={setIsUpdatingProgress}
              totalPaidAmountReleasedOrder={totalPaidAmountReleasedOrder}
              defaultMilestones={defaultMilestones}
              ImagesObjectFetchedFromFirebase={imagesFromFirebase}
            />
          </Dialog>
          <Dialog
            open={isSuccessPageOpen}
            onClose={() => {
              setIsApproval(false);
              setIsSuccessPageOpen(false);
              history.push("/ReleasedOrders/" + data.ProjectId, {
                data: data,
              });
            }}
            maxWidth="md">
            <SuccessPage
              isApproval={isApproval}
              info={data}
              activeCategory={activeCategory}
              selectedItems={selectedItems}
              orderType={orderType}
            />
          </Dialog>
        </div>
      </div>

      {customMilestones && (
        <AddCustomMilestones
          customMilestones={customMilestones}
          setCustomMilestones={setCustomMilestones}
          currentMilestoneTobeEditedIndex={currentMilestoneTobeEditedIndex}
          selectedItems={selectedItems}
          defaultMilestones={defaultMilestones}
          setSelectedItems={setSelectedItems}
          milestoneTobeAddedIndex={milestoneTobeAddedIndex}
          setMilestoneTobeAddedIndex={setMilestoneTobeAddedIndex}
        />
      )}

      {showAllVendors && (
        <AllVendors
          getBankAccountDetails={getBankAccountDetails}
          showAllVendors={showAllVendors}
          setShowAllVendors={setShowAllVendors}
          allVendorData={allVendorData}
          setVendorMobile={setVendorMobile}
        />
      )}

      {showPaymentTerms && (
        <PaymentTerms
          defaultMilestones={defaultMilestones}
          totalAmount={totalAmount}
          showPaymentTerms={showPaymentTerms}
          setShowPaymentTerms={setShowPaymentTerms}
          selectedItems={selectedItems}
          setTheValueOfDefaultMilestones={(itemsReceived) => {
            setDefaultMilestones(itemsReceived);
            milestonesForCalculation.current = itemsReceived;
          }}
          totalPaidAmountReleasedOrder={totalPaidAmountReleasedOrder.value}
          setTotalPaidAmountReleasedOrder={setTotalPaidAmountReleasedOrder}
          VendorDetailsSelected={VendorDetailsSelected}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: 999555555 }}
        open={isUpdatingProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isMandatoryImages.current && orderType === "Purchase Order" ? (
        <div
          style={{
            position: "fixed",
            background:
              selectedItems?.length -
                Object.values(imageULS).filter((item) => item !== null)
                  .length ===
                0
                ? "darkseagreen"
                : "red",
            left: "60px",
            top: "96vh",
            height: "4vh",
            width: "calc(100vw - 60px)",
            textAlign: "center",
          }}>
          {selectedItems?.length -
            Object.values(imageULS).filter((item) => {
              return item !== null && item && Object.keys(item)?.length !== 0;
            }).length ===
            0 ? (
            <p style={{ fontWeight: "600", padding: "0.3em" }}>
              <TiTick style={{ margin: "-1px" }} /> All Images Uploaded
            </p>
          ) : (
            <p style={{ fontWeight: "600", padding: "0.3em", color: "white" }}>
              {" "}
              <AiOutlineExclamationCircle style={{ margin: "-1px" }} />{" "}
              {selectedItems?.length -
                Object.values(imageULS).filter(
                  (item) => item !== null && item && item?.length !== 0
                ).length}{" "}
              Pending image to upload and{" "}
              {
                Object.values(imageULS).filter(
                  (item) =>
                    item !== null && item && Object.keys(item)?.length !== 0
                ).length
              }{" "}
              uploaded{" "}
            </p>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default drawerTemplate1(OrdersQuotations);
