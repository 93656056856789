import { secondDatabase } from "../../../config/firebase";
import extractInwardEntryData from "./Helpers/extractInwardObject";

const deleteInwardEntry = async (firmId, projectId, entryId, itemData) => {


  // Firebase reference path for the inward entry

  const basePath = `${firmId}/projects/${projectId}/records/allInward/${entryId}`;

  let NewItemData = await extractInwardEntryData(itemData);
  let onlyItems = NewItemData.itemsArray;

  for(let item of onlyItems){
    let ItemRef = secondDatabase.ref(`${firmId}/${projectId}/allInventory/${item.category}/${item.itemID}/history/${item.historyId}`).remove();
  }
    const entryRef = secondDatabase.ref(`${basePath}`).remove();
};

export default deleteInwardEntry;
