// BreakdownModal.js
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./Modal.css";

// Image Preview Modal Component
const ImagePreviewModal = ({ breakdown, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!breakdown || !breakdown.breakdownImages) {
    return null;
  }

  const breakdownImages = breakdown.breakdownImages || [];

  return (
    <div className="image-preview-overlay">
      <div className="compact-preview-modal">
        <div className="preview-header">
          <IconButton
            className="nav-button"
            onClick={() =>
              setCurrentIndex((prev) =>
                prev > 0 ? prev - 1 : breakdownImages.length - 1
              )
            }
            disabled={breakdownImages.length <= 1}>
            <ChevronLeftIcon />
          </IconButton>
          <span className="preview-title">Breakdown {currentIndex + 1}</span>
          <div className="header-right">
            <IconButton
              className="nav-button"
              onClick={() =>
                setCurrentIndex((prev) =>
                  prev < breakdownImages.length - 1 ? prev + 1 : 0
                )
              }
              disabled={breakdownImages.length <= 1}>
              <ChevronRightIcon />
            </IconButton>
            <IconButton className="close-button" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div className="modal-content-wrapper">
          <div className="description-cost-section">
            <div className="description-section">
              <span className="label">Description</span>
              <p className="value">{breakdown.breakdownReason || "--"}</p>
            </div>
            <div className="cost-section">
              <span className="label">Cost</span>
              <p className="value">
                ₹ {breakdown.repairCost?.toLocaleString() || "--"}
              </p>
            </div>
          </div>

          <div className="main-image-section">
            {breakdownImages[currentIndex] && (
              <img
                src={breakdownImages[currentIndex].uri}
                alt={`Breakdown ${currentIndex + 1}`}
              />
            )}
          </div>

          <div className="thumbnails-section">
            {breakdownImages.map((image, index) => (
              <div
                key={index}
                className={`thumbnail ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => setCurrentIndex(index)}>
                <img src={image.uri} alt={`Thumbnail ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const BreakdownModal = ({ breakdownData, onClose }) => {
  const [previewImages, setPreviewImages] = useState(null);
  const [startImageIndex, setStartImageIndex] = useState(0);
  const [selectedBreakdown, setSelectedBreakdown] = useState(null);

  if (!breakdownData) return null;

  const breakdowns = Object.entries(breakdownData).map(([id, data]) => ({
    id,
    ...data,
  }));

  const totalCost = breakdowns.reduce(
    (sum, breakdown) => sum + (breakdown.repairCost || 0),
    0
  );

  const repaired = breakdowns.filter((b) => b.alreadyRepaired).length;
  const pending = breakdowns.length - repaired;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Breakdown History</h2>
          <IconButton
            className="close-button"
            onClick={onClose}
            size="small"
            sx={{ color: "#333" }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>

        <div className="modal-body">
          <div className="maintenance-section">
            <div className="maintenance-summary">
              <div className="summary-header">
                <h3>Summary</h3>
                <span className="total-cost">
                  Total Cost: ₹{totalCost.toLocaleString()}
                </span>
              </div>
              <div className="summary-details">
                <div className="summary-item">
                  <label>Total Breakdowns</label>
                  <span>{breakdowns.length}</span>
                </div>
                <div className="summary-item">
                  <label>Repaired</label>
                  <span>{repaired}</span>
                </div>
                <div className="summary-item">
                  <label>Pending</label>
                  <span>{pending}</span>
                </div>
              </div>
            </div>

            <div className="service-history">
              <h4>Breakdown Details</h4>
              <div className="breakdown-table-container">
                <table className="breakdown-table">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Repair Date</th>
                      <th width="30%">Description</th>
                      <th>Cost</th>
                      <th>Images</th>
                    </tr>
                  </thead>
                  <tbody>
                    {breakdowns.map((breakdown) => (
                      <tr key={breakdown.id}>
                        <td>
                          <span
                            className="status-badge"
                            style={{
                              backgroundColor: breakdown.alreadyRepaired
                                ? "#4CAF50"
                                : "#FF9800",
                            }}>
                            {breakdown.alreadyRepaired ? "Repaired" : "Pending"}
                          </span>
                        </td>
                        <td>
                          {new Date(
                            breakdown.breakdownTime
                          ).toLocaleDateString()}
                        </td>
                        <td>
                          {breakdown.alreadyRepaired
                            ? new Date(
                                breakdown.repairTime
                              ).toLocaleDateString()
                            : "-"}
                        </td>
                        <td className="description-cell">
                          <div className="description-content">
                            {breakdown.breakdownReason}
                          </div>
                        </td>
                        <td>₹{breakdown.repairCost?.toLocaleString() || 0}</td>
                        <td>
                          {breakdown.breakdownImages?.length > 0 ? (
                            <IconButton
                              onClick={() => setSelectedBreakdown(breakdown)} // Changed this line
                              size="small">
                              <ImageIcon />
                              <span className="image-count">
                                {breakdown.breakdownImages.length}
                              </span>
                            </IconButton>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedBreakdown && (
        <ImagePreviewModal
          breakdown={selectedBreakdown}
          onClose={() => setSelectedBreakdown(null)}
        />
      )}
    </div>
  );
};

export default BreakdownModal;
