import "./button.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

export default ({ invert, onClick, content, isLoading }) => {
  return (
    <button
      className={`${invert ? "invert" : "button"} commonBtn `}
      // style={{ cursor: `${isLoading ? "progress" : "pointer"}` }}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading && (
        <CircularProgress
          style={{
            position: "absolute",
            width: "20px",
            height: "20px",
            color: "rgb(70 155 240)",
          }}
        />
      )}
      {content}
    </button>
  );
};
