import { useEffect, useState, useContext } from "react";
import "./timelineView.scss";
import { DropDown, Button } from "../../components";
import { AiOutlineBars, AiOutlineDelete } from "react-icons/ai";
import config from "../../config/config";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-toast";
import AnalyticsService from "../../api/analyticsService";
import DraftService from "../../api/draftService";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { UserContext } from "../../Context/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import drawerTemplate1 from "../../hoc/drawerTemplate1";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";
import getVendorPrice from "../../helpers/getVendorPrice";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import { saveWorkButtonClicked } from "./helper";
import { BiUpArrowAlt } from "react-icons/bi";
import { FiTruck } from "react-icons/fi";
import { GrUserWorker } from "react-icons/gr";
import { AiOutlineInfoCircle } from "react-icons/ai";
import exclamation from "../../assets/img/exclamation.png";
import Dialog from "@material-ui/core/Dialog";
import firebase from "../../config/firebase";
import Form from "../AuthPage/signUpVendor/Form";
import { GrClose } from "react-icons/gr";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Timeline = () => {
  // Main Tabs

  const { record } = useAnalyticsContext();

  const location = useLocation();
  const data = location.state.data;
  const [displayPurchaseAmount, setDisplayPurchaseAmount] = useState(true);


  const history = useHistory();

  const userContext = useContext(UserContext);
  const accountType = userContext.user.accountType;

  const [activeMainTab, setActiveMainTab] = useState(1);

  const [spinner, setSpinner] = useState(false);

  const [dateMonth, setDateMonth] = useState(new Date());

  const [releasedOrders, setReleasedOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [backToTop, setBackToTop] = useState(false);
  const [showMore, setShowMore] = useState([]);
  console.log("showMore:", showMore);

  const _tempClientName = sessionStorage.getItem("clientName");
  const [projectDetails, setProjectDetails] = useState();

  //vendor registration form state
  const [vendorFormOpen, setVendorFormOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTop(true);
      } else {
        setBackToTop(false);
      }
    });
  }, []);

  useEffect(() => {
    // setSpinner(true)
    let projectId = location.pathname.split("/")[2];
    firebase
      .database()
      .ref("liveBOQ/" + projectId.toString())
      .on("value", (snapshot) => {
        // console.log('Firebase SS', snapshot.val())
        setSpinner(false);
        if (snapshot.exists()) {
          const response = {
            ...snapshot.val(),
            rooms: JSON.parse(snapshot.val().rooms),
            Rooms: JSON.parse(snapshot.val().rooms),
          }; // has to replace with rooms or Rooms
          console.log("response :", response);
          setProjectDetails(response);
          // setSelectedProject(response);
        }
      });
  }, []);

  const scrollHandler = () => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  };
  useEffect(() => {
    fetch(`${config.orderService}getServiceOrders/${data.ProjectId}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from fetchOrders API: ", json);
        setReleasedOrders(json.servicesPayload);
      })
      .catch((e) => {
        console.log("fetchQuotations catch: ", e.toString());
      });
  }, []);

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const getLeftPosition = (startDate) => {
    if (startDate) {
      return new Date(startDate).getDate() * 40;
    }
    return 0;
  };

  useEffect(() => {
    setFilteredOrders(
      releasedOrders.filter((order) => {
        const obj = JSON.parse(order.Data);
        if (obj && obj.StartDate && obj.EndDate) {
          const stMonth =
            new Date(obj.StartDate).getMonth() ==
            new Date(dateMonth).getMonth();
          const stYear =
            new Date(obj.StartDate).getFullYear() ==
            new Date(dateMonth).getFullYear();
          const enMonth =
            new Date(obj.EndDate).getMonth() == new Date(dateMonth).getMonth();
          const enYear =
            new Date(obj.EndDate).getFullYear() ==
            new Date(dateMonth).getFullYear();

          if ((stMonth && stYear) || (enMonth && enYear)) {
            return order;
          }
        }
      })
    );
  }, [dateMonth, releasedOrders]);

  // Quotations

  const [quotationId, setQuotationId] = useState(null);

  useEffect(() => {
    fetchQuotations();
  }, []);

  const fetchQuotations = () => {
    fetch(`${config.quotationService}getQuotation/${data.ProjectId}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from fetchQuotations API: ", json);
        setReleasedQuotations(json.servicesPayload);
      })
      .catch((e) => {
        console.log("fetchQuotations catch: ", e.toString());
      });
  };

  const [releasedQuotations, setReleasedQuotations] = useState([]);
  const [filteredQuotations, setFilteredQuotations] = useState([]);

  useEffect(() => {
    setFilteredQuotations(
      releasedQuotations.filter((order) => {
        const obj = JSON.parse(order.Data);
        if (obj && obj.StartDate && obj.EndDate) {
          const stMonth =
            new Date(obj.StartDate).getMonth() ==
            new Date(dateMonth).getMonth();
          const stYear =
            new Date(obj.StartDate).getFullYear() ==
            new Date(dateMonth).getFullYear();
          const enMonth =
            new Date(obj.EndDate).getMonth() == new Date(dateMonth).getMonth();
          const enYear =
            new Date(obj.EndDate).getFullYear() ==
            new Date(dateMonth).getFullYear();

          if ((stMonth && stYear) || (enMonth && enYear)) {
            return order;
          }
        }
      })
    );
  }, [dateMonth, releasedQuotations]);

  const [openQuotationModal, setOpenQuotationModal] = useState(false);
  const [quotationData, setQuotationData] = useState([]);
  const [orderIdSelectedQuotation, setOrderIdSelectedQuotation] = useState();
  const [categorySelectedQuotation, setCategorySelectedQuotation] = useState();

  useEffect(() => {
    console.log(`quotationData`, quotationData);
  }, [quotationData]);

  const updateQuotation = () => {
    fetch(`${config.quotationService}quotation/${orderIdSelectedQuotation}`, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Data: JSON.stringify(quotationData),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from updateQuotation API: ", json);
        json.status == 200
          ? toast.success("Quotation updated successfully", {
              backgroundColor: "rgb(255, 211, 77)",
              color: "black",
            })
          : toast.error("Error updating quotation", {
              backgroundColor: "red",
              color: "white",
            });
        setOpenQuotationModal(false);
        fetchQuotations();
      })
      .catch((e) => {
        console.log("updateQuotation catch: ", e.toString());
      });
  };

  const [addManually, setAddManually] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [vendorMobile, setVendorMobile] = useState(null);

  const analyticsService = new AnalyticsService();

  useEffect(() => {
    vendorList();
  }, [categorySelectedQuotation]);

  const vendorList = () => {
    analyticsService
      .getFirmBasedAnalytics(localStorage.getItem("firm"))
      .then((res) => {
        console.log(`Response from analyticsService API: `, res);
        if (res.status === 200) {
          let vendorsList = res.Vendor.filter((item) =>
            item.Category.includes(categorySelectedQuotation)
          )
            .map((item) => item.Name + " : " + item.Mobile)
            .sort((a, b) => a.localeCompare(b));
          setVendors(vendorsList);
          setVendorMobile(vendorsList[0]);
        }
      })
      .catch((e) => {
        console.log(`Catch from analyticsService API: `, e.toString());
      });
  };

  var rateAdded = "";

  const [tempQuotationData, setTempQuotationData] = useState([]);
  const [totalOfRates, setTotalOfRates] = useState([]);

  useEffect(() => {
    var tempTotalOfRates = [];
    quotationData &&
      quotationData.data &&
      "quotations" in quotationData?.data[0] &&
      quotationData?.data.map((dItem, dIndex) =>
        dItem.quotations.map(
          (qItem, qIndex) =>
            (tempTotalOfRates[qIndex] =
              (tempTotalOfRates[qIndex] == undefined
                ? 0
                : tempTotalOfRates[qIndex]) +
              Number(qItem.rate * dItem.quantity))
        )
      );
    setTotalOfRates(tempTotalOfRates);
  }, [quotationData]);

  // Drafts

  useEffect(() => {
    fetchDrafts();
  }, []);

  const draftService = new DraftService();

  const fetchDrafts = async () => {
    const response = await draftService.getDraft(data.ProjectId);
    console.log(`Response from fetchDrafts API: `, response);
    setSavedDrafts(response.payload);
  };

  const [savedDrafts, setSavedDrafts] = useState([]);
  const [filteredDrafts, setFilteredDrafts] = useState([]);
  console.log("filteredDrafts", filteredDrafts);

  useEffect(() => {
    setFilteredDrafts(
      savedDrafts.filter((draft) => {
        if (draft && draft.startDate && draft.endDate) {
          const stMonth =
            new Date(draft.startDate).getMonth() ==
            new Date(dateMonth).getMonth();
          const stYear =
            new Date(draft.startDate).getFullYear() ==
            new Date(dateMonth).getFullYear();
          const enMonth =
            new Date(draft.endDate).getMonth() ==
            new Date(dateMonth).getMonth();
          const enYear =
            new Date(draft.endDate).getFullYear() ==
            new Date(dateMonth).getFullYear();

          if ((stMonth && stYear) || (enMonth && enYear)) {
            return draft;
          }
        }
      })
    );
  }, [dateMonth, savedDrafts]);

  useEffect(() => {
    console.log(`savedDrafts`, savedDrafts);
  }, [savedDrafts]);

  useEffect(() => {
    console.log(`filteredDrafts`, filteredDrafts);
  }, [filteredDrafts]);

  const deleteDraft = async (draftId) => {
    const response = await draftService.deleteDraft(draftId);
    console.log(`Response from deleteDraft API: `, response);
    if (response.status == 200) {
      toast.error("Draft deleted successfully!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
      fetchDrafts();
    } else {
      toast.error("Error while deleting draft!", {
        backgroundColor: "red",
        color: "white",
      });
    }
  };

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <div className={"mainTabs"}>
        <div>
          <div
            className={"boqTab"}
            onClick={() => {
              setActiveMainTab(0);
              history.push("/ProjectPage/" + data.ProjectId);
              record(
                TAGS[
                  ("@boq_tab_click_success",
                  { description: "BOQ tab click success" })
                ]
              );
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 20 }}>BOQ</div>
            {(accountType == "Admin" ||
              accountType == "Finance" ||
              accountType == "Owner") && (
              <div>Rs {(data && getComponentsPrice(data).toFixed(2)) || 0}</div>
            )}
          </div>
          <div className={activeMainTab === 0 ? "active" : ""}></div>
        </div>

        <div>
          <div
            className={"indentTab"}
            onClick={() => {
              if (accountType == "BOQ" || accountType == "Finance") {
                toast.error("You don't have access to Purchase!", {
                  backgroundColor: "red",
                  color: "black",
                });
              } else {
                if (data?.rooms) {
                  setActiveMainTab(1);
                  saveWorkButtonClicked(data, setSpinner);
                } else {
                  toast.error("Empty BOQ. Please add to proceed!", {
                    backgroundColor: "red",
                    color: "black",
                  });
                }
              }
              record(
                TAGS[
                  ("@purchase_tab_click_success",
                  { description: "Purchase tab click success" })
                ]
              );
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontWeight: "bold" }}>Purchase</div>
              <div onClick={(e) => {
                e.stopPropagation();
                if(displayPurchaseAmount){
                  setDisplayPurchaseAmount(false)
                }else{
                  setDisplayPurchaseAmount(true)
                }
              }}>
                {!displayPurchaseAmount ? (
                  <VisibilityOff style={{marginLeft: 2}} />
                ) : (
                    <Visibility style={{ marginLeft: 2 }} />
                )}
              </div>
              </div>
            {(accountType == "Admin" ||
              accountType == "Finance" ||
              accountType == "Owner") && (
              <div>Rs {(data && !displayPurchaseAmount && getVendorPrice(data).toFixed(2)) || '*********'}</div>
            )}
          </div>
          <div className={activeMainTab === 1 ? "active" : ""}></div>
        </div>
      </div>

      <div
        style={{
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            cursor: "pointer",
          }}
        >
          {projectDetails && (
            <p
              style={{
                fontWeight: "bold",
                marginRight: "20px",
                cursor: "default",
              }}
            >
              {projectDetails.clientName
                ? projectDetails.clientName
                : projectDetails.ClientName}
            </p>
          )}
          {/* <p style={{ fontWeight: 'bold', marginRight: '20px', cursor: 'default', }}>{_tempClientName}</p> */}
          <div
            onClick={() =>
              history.push("/UnreleasedItems/" + data.ProjectId, { data: data })
            }
          >
            <p>Unreleased Items</p>
          </div>
          <div
            onClick={() =>
              history.push("/ReleasedOrders/" + data.ProjectId, { data: data })
            }
          >
            <p>Released Orders</p>
          </div>
          <div className={"activeText"}>
            <p>Timeline</p>
            <div className={"activeTab"}></div>
          </div>
          {/* <div
          onClick={() => {
            if(accountType == "Admin"){
              history.push("/Approvals/" + data.ProjectId, { 
                data: data
                 
              });
              record("@approvals_tab_click_success", {
                description: "approvals tab click success",
              });
            }
            else{
              toast.error("You Can't Approve Orders!", {
                backgroundColor: "red",
                color: "black",
              })
            }
          }}
            >
            <p>Approvals</p>
          </div> */}
        </div>
        <div style={{ width: "20%" }}>
          <Button
            content="Generate PO/ WO/ WQ/ MQ"
            onClick={() => {
              history.push("/OrdersQuotations/" + data.ProjectId, {
                data: {
                  ...data,
                  rooms: data.rooms,
                  ProjectId: data.ProjectId,
                  ClientName: data.ClientName,
                  Address: data.Address,
                  AmountRecieved: data.AmountReceived,
                  SiteEngineerId: data.SiteEngineersId,
                },
              });
            }}
          />
        </div>
      </div>

      <div style={{ padding: "0px 20px", display: "block" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["month", "year"]}
            label="Month and Year"
            minDate={new Date("2021-01-01")}
            maxDate={new Date("2028-01-01")}
            value={dateMonth}
            onChange={(newValue) => {
              setDateMonth(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText="Date" />
            )}
          />
        </LocalizationProvider>
      </div>

      <div id="time-line">
        <div id="time-line-header">
          <div id="time-line-header-left">Category</div>
          <div id="time-line-header-right">
            {[
              ...Array(
                daysInMonth(
                  new Date(dateMonth).getMonth() + 1,
                  new Date(dateMonth).getFullYear()
                )
              ).keys(),
            ].map((x) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
              >
                {x + 1}
              </div>
            ))}
          </div>
        </div>

        <div id="time-line-body">
          {filteredQuotations.map((quotation, index) => {
            const Data = JSON.parse(quotation.Data).data;
            const DataWithDate = JSON.parse(quotation.Data);
            return (
              <div key={index} className="time-line-row">
                <div className="time-line-left">
                  <div>{quotation.Category}</div>
                  <div>{Data.length} Items</div>
                </div>
                <div
                  className="time-line-right-quotation"
                  style={{
                    left: getLeftPosition(
                      JSON.parse(quotation.Data)?.StartDate
                    ),
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenQuotationModal(true);
                    setQuotationData(DataWithDate);
                    setTempQuotationData(DataWithDate);
                    setOrderIdSelectedQuotation(quotation.OrderId);
                    setQuotationId(quotation.Id);
                    setCategorySelectedQuotation(
                      Data[0]?.type || Data[0]?.vendorCategory
                    );
                  }}
                >
                  <div></div>
                  <div>
                    <div>{quotation.Category}</div>
                    <div>
                      {Data.length} Items | {quotation.Status}
                    </div>
                  </div>
                  <div>
                    <AiOutlineBars />
                  </div>
                </div>
              </div>
            );
          })}
          {filteredDrafts.map((draft, index) => {
            return (
              <div key={index} className="time-line-row">
                <div className="time-line-left">
                  <div>{draft.activeCategory}</div>
                </div>
                <div
                  style={{
                    left: getLeftPosition(draft?.startDate),
                    cursor: "pointer",
                    backgroundColor: "yellow",
                    backgroundColor: "#585858",
                    height: "50px",
                    color: "white",
                    position: "relative",
                    width: "300px",
                    borderRadius: "6px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translateX(-40px)",
                  }}
                >
                  {draft.orderType === "Purchase Order" && (
                    <div style={{ padding: "10px" }}>
                      <FiTruck size={20} style={{ color: "white" }} />
                    </div>
                  )}
                  {draft.orderType === "Material Quotation" && (
                    <div style={{ padding: "10px" }}>
                      <FiTruck size={20} style={{ color: "white" }} />
                    </div>
                  )}
                  {draft.orderType === "Work Order" && (
                    <div style={{ padding: "10px" }}>
                      <GrUserWorker size={20} className="workerIcon" />
                    </div>
                  )}
                  {draft.orderType === "Work Quotation" && (
                    <div style={{ padding: "10px" }}>
                      <GrUserWorker size={20} className="workerIcon" />
                    </div>
                  )}
                  <div
                    style={{
                      backgroundColor: "#474747",
                      display: "flex",
                      flexDirection: "column",
                      width: "80%",
                      padding: "6px ",
                      color: "white",
                      borderRadius: "6px",
                    }}
                    onClick={() => {
                      console.log(
                        "This is where we are calling the onclick of the draft"
                      );
                      history.push("/OrdersQuotations/" + data.ProjectId, {
                        data: {
                          ...data,
                          rooms: data.rooms,
                          ProjectId: data.ProjectId,
                          ClientName: data.ClientName,
                          Address: data.Address,
                          AmountRecieved: data.AmountReceived,
                          SiteEngineerId: data.SiteEngineersId,
                        },
                        draft: draft,
                      });
                    }}
                  >
                    {/* <p style={{ fontWeight: 'bold', }}>{'Draft'}</p>
                                                <p>{draft.activeCategory}</p> */}
                    <span>
                      <p style={{ fontWeight: "bold" }}>
                        {draft.orderType === "Purchase Order" ||
                        draft.orderType === "Work Order"
                          ? "Order Draft"
                          : draft.orderType === "Work Quotation" ||
                            draft.orderType === "Material Quotation"
                          ? "Quatation Draft"
                          : "null"}
                      </p>
                      <p style={{ fontSize: "small" }}>
                        {JSON.parse(draft.selectedItems).length} Items |{" "}
                        {draft.activeCategory}
                      </p>
                    </span>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#585858",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      width: "20%",
                      padding: "6px",
                      borderRadius: "6px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteDraft(draft.draftId);
                    }}
                  >
                    <AiOutlineDelete />
                  </div>
                </div>
              </div>
            );
          })}
          {filteredOrders.map((order, index) => {
            const Data = JSON.parse(order.Data).data;
            return (
              <div key={index} className="time-line-row">
                <div className="time-line-left">
                  <div>{order.Category}</div>
                  <div>{Data.length} Items</div>
                </div>
                <div
                  className="time-line-right"
                  style={{
                    left: getLeftPosition(JSON.parse(order.Data)?.StartDate),
                  }}
                >
                  <div></div>
                  <div>
                    <div>{order.Category}</div>
                    <div>
                      {Data.length} Items | {order.Status}
                    </div>
                  </div>
                  <div>
                    <AiOutlineBars />
                  </div>
                </div>
              </div>
            );
          })}
          <Modal
            open={openQuotationModal}
            onClose={() => {
              setOpenQuotationModal(false);
              setAddManually(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 4,
                minWidth: "75%",
                maxHeight: "90%",
                maxWidth: "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <h2>Quote Comparison</h2>
                <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                  <div
                    style={{
                      backgroundColor: "rgb(255, 211, 77)",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAddManually(true);
                    }}
                  >
                    Add manually
                  </div>
                  <div
                    style={{
                      border: "2px solid rgb(255, 211, 77)",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => updateQuotation()}
                  >
                    Update Quotation
                  </div>
                </div>
              </div>

              <div
                style={{
                  margin: "20px 0px",
                  borderBottomStyle: "solid",
                  borderColor: "grey",
                }}
              />

              <table id="table" style={{ textAlign: "center" }}>
                <tr>
                  <th>Items</th>
                  <th>Quantity</th>
                  <th>Budget</th>
                  {quotationData &&
                    quotationData.data &&
                    "quotations" in quotationData?.data[0] &&
                    quotationData?.data[0].quotations.map((qItem, qIndex) => (
                      <th>
                        {qItem.vendorName}
                        <br />
                        {qItem.vendorMobileNumber}
                      </th>
                    ))}
                  {addManually && vendors.length > 0 && (
                    <th style={{ minWidth: 100 }}>
                      <DropDown
                        data={vendors}
                        value={vendorMobile}
                        onChange={(value) => setVendorMobile(value)}
                      />
                    </th>
                  )}
                </tr>
                {quotationData &&
                  quotationData.data &&
                  quotationData?.data.map((item, index) => (
                    <tr>
                      {/* <td>
                                                    {item.vendorCategory || item.type}
                                                    <br />
                                                    {
                                                        item.description && item.description.replace(/<new-line>/g, "\n").replace(/<single-quote>/g, "'").replace(/<double-quote>/g, "\"")
                                                        ||
                                                        item.specification && item.specification.replace(/<new-line>/g, "\n").replace(/<single-quote>/g, "'").replace(/<double-quote>/g, "\"")
                                                    }
                                                </td> */}
                      <td style={{ width: "40%" }}>
                        {item.vendorCategory || item.type}
                        <br />
                        {item.description && item.description.length > 20 ? (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ width: "90%" }}>
                              {showMore.includes(item.description) ? (
                                <span>
                                  {item.description
                                    .replace(/<new-line>/g, "\n")
                                    .replace(/<single-quote>/g, "'")
                                    .replace(/<double-quote>/g, '"')}
                                </span>
                              ) : (
                                <span>
                                  {item.description
                                    .replace(/<new-line>/g, "\n")
                                    .replace(/<single-quote>/g, "'")
                                    .replace(/<double-quote>/g, '"')
                                    .substring(0, 100)}
                                  ...
                                </span>
                              )}
                            </span>
                            <AiOutlineInfoCircle
                              title={
                                showMore.length > 0 ? "ShowLess" : "ShowMore"
                              }
                              size={20}
                              style={{
                                color: "gray",
                                cursor: "pointer",
                                marginRight: "25px",
                              }}
                              onClick={() => {
                                if (showMore.includes(item.description)) {
                                  console.log(
                                    "if statement:",
                                    item.description
                                  );
                                  setShowMore(
                                    showMore.filter(
                                      (showitem) =>
                                        showitem !== item.description
                                    )
                                  );
                                } else {
                                  console.log(
                                    "elseStatement:",
                                    item.description
                                  );
                                  setShowMore([...showMore, item.description]);
                                }
                              }}
                            />
                          </span>
                        ) : (
                          <span>
                            {item.description &&
                              item.description
                                .replace(/<new-line>/g, "\n")
                                .replace(/<single-quote>/g, "'")
                                .replace(/<double-quote>/g, '"')}
                          </span>
                        )}
                        {item &&
                        item.specification &&
                        item.specification.length > 20 ? (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "90%",
                            }}
                          >
                            <span>
                              {showMore.includes(item.specification) ? (
                                <span>
                                  {item.specification
                                    .replace(/<new-line>/g, "\n")
                                    .replace(/<single-quote>/g, "'")
                                    .replace(/<double-quote>/g, '"')}
                                </span>
                              ) : (
                                <span>
                                  {item.specification
                                    .replace(/<new-line>/g, "\n")
                                    .replace(/<single-quote>/g, "'")
                                    .replace(/<double-quote>/g, '"')
                                    .substring(0, 100)}
                                  ...
                                </span>
                              )}
                            </span>
                            <AiOutlineInfoCircle
                              title={
                                showMore.length > 0 ? "ShowLess" : "ShowMore"
                              }
                              size={20}
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() => {
                                if (showMore.includes(item.specification)) {
                                  setShowMore(
                                    showMore.filter(
                                      (showitem) =>
                                        showitem !== item.specification
                                    )
                                  );
                                } else {
                                  setShowMore([
                                    ...showMore,
                                    item.specification,
                                  ]);
                                }
                              }}
                            />
                          </span>
                        ) : (
                          <span>
                            {item.specification &&
                              item.specification
                                .replace(/<new-line>/g, "\n")
                                .replace(/<single-quote>/g, "'")
                                .replace(/<double-quote>/g, '"')}
                          </span>
                        )}
                      </td>
                      <td>
                        {item.quantity} {item.unit}
                      </td>
                      <td>
                        {"\u20B9 "}
                        {item.rate}
                      </td>
                      {"quotations" in item &&
                        item.quotations.map((qItem, qIndex) => (
                          <td>
                            <div
                              contentEditable="true"
                              suppressContentEditableWarning={true}
                              onBlur={(e) => {
                                let tempQuotationData3 = JSON.parse(
                                  JSON.stringify(quotationData)
                                );
                                tempQuotationData3.data[index].quotations[
                                  qIndex
                                ].rate = e.currentTarget.innerHTML;
                                setQuotationData(tempQuotationData3);
                              }}
                            >
                              {qItem.rate}
                            </div>
                          </td>
                        ))}
                      {addManually && (
                        <td>
                          <div
                            contentEditable="true"
                            style={{ height: 40 }}
                            suppressContentEditableWarning={true}
                            onBlur={(e) => {
                              rateAdded = e.currentTarget.innerHTML;

                              let tempObj = Object.assign({
                                rate: e.currentTarget.innerHTML,
                                vendorName: vendorMobile.split(":")[0].trim(),
                                vendorMobileNumber: Number(
                                  vendorMobile.split(":")[1].trim()
                                ),
                              });

                              let tempQuotationData2 = JSON.parse(
                                JSON.stringify(tempQuotationData)
                              );

                              if (
                                "quotations" in tempQuotationData2?.data[index]
                              ) {
                                tempQuotationData2?.data[
                                  index
                                ]?.quotations.push(tempObj);
                              } else {
                                let obj = tempQuotationData2?.data[index];
                                Object.assign(obj, { quotations: [tempObj] });
                              }

                              setTempQuotationData(tempQuotationData2);

                              if (
                                tempQuotationData2?.data.length - 1 ==
                                index
                              ) {
                                setQuotationData(tempQuotationData2);
                                setAddManually(false);
                              }
                            }}
                          >
                            {rateAdded}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                <tr style={{ backgroundColor: "white", fontSize: "14px" }}>
                  {quotationData &&
                    quotationData.data &&
                    "quotations" in quotationData?.data[0] && (
                      <>
                        <td style={{ fontWeight: "bold" }}>Total</td>
                        <td>
                          {quotationData?.data.reduce(
                            (total, item) => (total += Number(item.quantity)),
                            0
                          )}
                        </td>
                        <td>
                          {"\u20B9 "}
                          {quotationData?.data.reduce(
                            (total, item) =>
                              (total += Number(item.rate * item.quantity)),
                            0
                          )}
                        </td>
                        {quotationData?.data[0].quotations.map(
                          (item, index) => (
                            <td>
                              <div
                                style={{
                                  border: "2px solid rgb(255, 211, 77)",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  quotationData.data.map(
                                    (dItem, dIndex) =>
                                      (dItem.rate =
                                        dItem.quotations[index].rate)
                                  );
                                  setQuotationData(quotationData);

                                  setOpenQuotationModal(false);

                                  console.log(
                                    "quotationData?.data : ",
                                    quotationData?.data
                                  );

                                  history.push(
                                    "/OrdersQuotations/" + data.ProjectId,
                                    {
                                      data: {
                                        ...data,
                                        rooms: data.rooms,
                                        ProjectId: data.ProjectId,
                                        ClientName: data.ClientName,
                                        Address: data.Address,
                                        AmountRecieved: data.AmountReceived,
                                        SiteEngineerId: data.SiteEngineersId,
                                      },
                                      selectedWorks: quotationData?.data,
                                      selectedMaterials: quotationData?.data,
                                      quotationId: quotationId,
                                      quotationVendorName:
                                        quotationData?.data[0].quotations[index]
                                          .vendorName,
                                      quotationVendorMobile:
                                        quotationData?.data[0].quotations[index]
                                          .vendorMobileNumber,
                                      orderOrQuotation: "QuatationButton",
                                      // orderOrQuotation: "quotation",
                                      comingFromQuotation: true,
                                      // quotationButtonClick: true
                                    }
                                  );
                                }}
                              >
                                {"\u20B9 "}
                                {totalOfRates[index]}
                              </div>
                            </td>
                          )
                        )}
                      </>
                    )}
                </tr>
              </table>
            </Box>
          </Modal>

          <Dialog
            open={addManually && vendors.length === 0}
            onClose={() => setAddManually(false)}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <div>
                  <img
                    src={exclamation}
                    alt="image"
                    style={{ width: "60px" }}
                  />
                </div>
                <div>
                  <p
                    style={{
                      color: "balck",
                      fontSize: "24px",
                      fontWeight: "600",
                      padding: "10px 0px",
                    }}
                  >
                    Error! No vendors found
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      color: "#484848",
                      fontSize: "16px",
                      padding: "5px 0px",
                      fontWeight: "500",
                    }}
                  >
                    There are no vendors registered in this category
                  </p>
                </div>
                <div style={{ padding: "10px 0px", marginTop: "15px" }}>
                  <button
                    onClick={() => {
                      setVendorFormOpen(true);
                      setAddManually(false);
                      setOpenQuotationModal(false);
                    }}
                    style={{
                      padding: "8px 25px",
                      backgroundColor: "#0052cc",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      boxShadow: "0px 2px",
                      cursor: "pointer",
                      fontSize: "16px",
                      letterSpacing: "1px",
                      fontWeight: "600",
                      marginRight: "20px",
                    }}
                  >
                    Add Vendor
                  </button>
                  {/* <button
                                        onClick={() => setAddManually(false)}
                                        style={{
                                            padding: "8px 25px",
                                            backgroundColor: "#0052cc",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "4px",
                                            boxShadow: "0px 2px",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            letterSpacing: "1px",
                                            fontWeight: "600"
                                        }}>
                                        OK
                                    </button> */}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                right: 15,
                top: 15,
                cursor: "pointer",
              }}
            >
              <GrClose size={20} onClick={() => setAddManually(false)} />
            </div>
          </Dialog>
        </div>
      </div>
      <div style={{ zIndex: 10 }}>
        <Dialog
          open={vendorFormOpen}
          onClose={() => {
            setVendorFormOpen(false);
          }}
          fullWidth
          maxWidth="md"
        >
          <Form
            handleClose={() => {
              setVendorFormOpen(false);
              vendorList();
              setOpenQuotationModal(true);
            }}
          />
        </Dialog>
      </div>
      {backToTop && (
        <button
          id="btn"
          title="back to top"
          onClick={scrollHandler}
          style={{
            width: "35px",
            height: "35px",
            backgroundColor: "#2f4858",
            marginTop: "5px",
            position: "fixed",
            bottom: "4%",
            left: "96%",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
            color: "white",
          }}
        >
          <BiUpArrowAlt size={30} style={{ marginTop: "2px" }} />
        </button>
      )}
    </div>
  );
};

export default drawerTemplate1(Timeline);
