import config from "../config/config";
const axios = require("axios").default;

class POServicefirebase {
  makePostRequest(path, elements) {
    return new Promise(function (resolve, reject) {
      axios.post(path, elements).then(
        (response) => {
          var result = response.data;
          // console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async sendPOtoFirebase(pid, oid, selectedItems) {
    try {
      const response = await this.makePostRequest(
        "https://us-central1-charming-shield-300804.cloudfunctions.net/drawings/update_inventory",
        {
          projectID: pid,
          orderID: oid,
          items_recieved: selectedItems,
        }
      );
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }
}

export default POServicefirebase;
