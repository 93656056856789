import { useState } from "react";
import Button from "@material-ui/core/Button";
import ModalDialog from "./ModalDialog";

const AddSiteEngineer = ({ setSiteEngineers, siteEngineers }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="AddVendor">
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
          height: "50px",
        }}
      >
        Add Site Engineer
      </Button>
      <ModalDialog
        setSiteEngineers={setSiteEngineers}
        open={open}
        handleClose={handleClose}
        siteEngineers={siteEngineers}
      />
    </div>
  );
};

export default AddSiteEngineer;
