import { secondDatabase } from "../../../config/firebase";

const modifyInwardNetInventory = async (
  firmId,
  projectId,
  existingArray,
  updatedArray,
  entryId,
  userName
) => {
  // Create a map of existing inventory items by id for quick lookup
  const basePath = `${firmId}/${projectId}/allInventory/`;

  const existingItemsMap = existingArray.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});

  // Loop through the updated array
  for (const updatedItem of updatedArray) {
    const existingItem = existingItemsMap[updatedItem.id];
    const categoryRef = secondDatabase.ref(
      `${basePath}${updatedItem.category}`
    );
    const descriptionRef = secondDatabase.ref(
      `${basePath}${updatedItem.category}/${updatedItem.itemID}`
    );

    // Check if item is new or updated
    if (existingItem) {
      // Item exists in both arrays, check for quantity change

      const quantityChange =
        parseFloat(updatedItem.quantityAccepted) -
        parseFloat(existingItem.quantityAccepted);
    
        console.log('quantityChange : ', quantityChange);

      const newQuantity = parseFloat(existingItem.quantityAccepted) + quantityChange;
        // quantityChange > 0
        //   ? parseFloat(existingItem.quantityAccepted) + quantityChange
        //   : parseFloat(existingItem.quantityAccepted) - quantityChange;
        
        console.log('newQuantity : ', newQuantity);

      if (quantityChange !== 0) {
        // Quantity has changed, update the inventory and add to history
        const historyData = {
          invoiceNumber: updatedItem.purpose || "Not provided",
          name: userName,
          poNumber: updatedItem.vehicle || "Not provided",
          quantity: newQuantity,
          status: "Inward",
          timeStamp: updatedItem.historyId,
          entryId: entryId,
          itemEntryId: updatedItem.id,
          purpose: updatedItem.purpose
        };

        const descriptionSnapshot = await descriptionRef.once("value");
        if (descriptionSnapshot.exists()) {
          const existingData = descriptionSnapshot.val();
          existingData.history[updatedItem.historyId] = historyData;
          await descriptionRef.update({
            availableQuantity:
              parseFloat(existingData.availableQuantity || 0) + quantityChange,
            history: existingData.history,
          });
        } else {
          // If description doesn't exist, create a new one with history
          await descriptionRef.set({
            availableQuantity: parseFloat(updatedItem.quantityAccepted || 0),
            description: updatedItem.description || "No description",
            uom: updatedItem.unit || "Units",
            history: historyData,
          });
        }
      }
    } else {
      // Item is new, add it to inventory
      const historyData = {
        [updatedItem.historyId]: {
          invoiceNumber: updatedItem.purpose || "Not provided",
          name: userName,
          poNumber: updatedItem.vehicle || "Not provided",
          quantity: parseFloat(updatedItem.quantityAccepted || 0),
          status: "Inward",
          timeStamp: updatedItem.historyId,
          entryId: entryId,
          itemEntryId: updatedItem.id,
          purpose: updatedItem.purpose
        },
      };

      const categorySnapshot = await categoryRef.once("value");
      if (!categorySnapshot.exists()) {
        await categoryRef.set({});
      }

      await descriptionRef.set({
        availableQuantity: parseFloat(updatedItem.quantityAccepted || 0),
        description: updatedItem.description || "No description",
        uom: updatedItem.unit || "Units",
        history: historyData,
      });
    }
  }

  for (const existingItem of existingArray) {
    const isItemDeleted = !updatedArray.some(
      (updatedItem) => updatedItem.id === existingItem.id
    );
    if (isItemDeleted) {
      // Item is deleted, adjust the inventory accordingly
      const historyData = {
        [existingItem.historyId]: {
          invoiceNumber: existingItem.purpose || "Not provided",
          name: userName,
          poNumber: existingItem.vehicle || "Not provided",
          quantity: -parseFloat(existingItem.quantityAccepted || 0),
          status: "Outward",
          timeStamp: existingItem.historyId,
          entryId: entryId,
          itemEntryId: existingItem.id,
          purpose: existingItem.purpose
        },
      };

      let path = `${firmId}/${projectId}/allInventory/${existingItem.category}/${existingItem.itemID}`;

      const descriptionRef = secondDatabase.ref(
        `${basePath}${existingItem.category}/${existingItem.itemID}`
      ).remove();
      
      // const descriptionSnapshot = await descriptionRef.once("value");

      // if (descriptionSnapshot.exists()) {
      //   const existingData = descriptionSnapshot.val();
      //   await descriptionRef.update({
      //     availableQuantity:
      //       parseFloat(existingData.availableQuantity || 0) -
      //       parseFloat(existingItem.quantityAccepted || 0),
      //     history: { ...existingData.history, ...historyData },
      //   });
      // }

    }
  }
  console.log("Inventory data updated successfully.");
};

export default modifyInwardNetInventory;
