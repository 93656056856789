import OtpInput from "react-otp-input";
import UtilService from "../../api/utilService";
import OtpTimer from "otp-timer";
import { useState } from "react";
import Label from "../../components/Label/label";
import Button from "../../components/Button/button";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import firebase from "../../config/firebase";
import AuthService from "../../api/authService";

function Otp_User({
  formStage,
  setFormStage,
  formData,
  onAuthChanged,
  setFormData,
}) {
  const [enteredOtp, setEnteredOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [otpError, setOtpError] = useState("");

  const history = useHistory();

  const verify = async () => {
    console.log("formData : ", formData);
    setIsLoading(true);
    const utilService = new UtilService();
    utilService
      .verify(formData.otpSessionId, enteredOtp)
      .then(async (res) => {
        // console.log("RES OF VERIFY", res);
        if (res.status == 200) {
          if (!formData.user) {
            // no user handle signup
            setFormStage(2);
            return;
          }
          //   record("@login_button_click_success", {
          //     description: "Login button click success",
          //   });
          sessionStorage.setItem("User", JSON.stringify(formData.user));
          localStorage.setItem("User", JSON.stringify(formData.user));
          localStorage.setItem("Firm", JSON.stringify(formData.user.Firm));
          localStorage.setItem("firm", formData.user.Firm);

          if ("Notification" in window) {
            // Check if the user has already granted permission
            // console.log("REQUESTING ");
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
              // console.log("GRANTED");
              const fcmToken = await firebase
                .messaging()
                .getToken()
                .then((res) => res);
              // console.log("CHECKING THE FCEM TOKEN IN GHT LOGINT", fcmToken);

              if (fcmToken) {
                const authService = new AuthService();
                const updatedFCM = await authService.updateFCMtokenofUser(
                  formData.email,
                  fcmToken
                );
              }
            }
          }

          onAuthChanged({
            ...formData.user,
          });
          toast.success("Successfully Verified");
          history.push("/");
        } else {
          //   setEnteredOtp("");
          console.log(" we came here...");
          // this condition here is only for our tester to automate the log in for testing with 123456
          if (formData.email === "tejaswini@staart.co.in") {
            console.log("it is our hatred tester...");
            sessionStorage.setItem("User", JSON.stringify(formData.user));
            localStorage.setItem(
              "User",
              // JSON.stringify({
              //   ...res.payload,
              //   authToken: res.authToken,
              // })
              JSON.stringify(formData.user)
            );
            localStorage.setItem("Firm", JSON.stringify(formData.user.Firm));
            localStorage.setItem("firm", formData.user.Firm);

            if ("Notification" in window) {
              // Check if the user has already granted permission
              // console.log("REQUESTING ");
              const permission = await Notification.requestPermission();
              if (permission === "granted") {
                // console.log("GRANTED");
                const fcmToken = await firebase
                  .messaging()
                  .getToken()
                  .then((res) => res);
                // console.log("CHECKING THE FCEM TOKEN IN GHT LOGINT", fcmToken);

                if (fcmToken) {
                  const authService = new AuthService();
                  const updatedFCM = await authService.updateFCMtokenofUser(
                    formData.email,
                    fcmToken
                  );
                }
              }
            }

            onAuthChanged({
              ...formData.user,
            });
            toast.success("Successfully Verified");
            history.push("/");
          } else {
            setIsLoading(false);
            setOtpError(res.statusMsg);
          }
          //   alert(res.statusMsg);
        }
      })
      .catch((error) => {
        setOtpError("Something Went Wrong! Try again.");
        // alert("somthing Went Wrong");
        toast.error("Something went Wrong! Try again");
        setIsLoading(false);
      });
  };
  const sendOtp = () => {
    const utilService = new UtilService();
    utilService
      .sendOtp(formData.email)
      .then((res) => {
        if (res.status == 200) {
          //   history.push("OTP", {
          //     sessionId: res.SessionId,
          //   });
          setFormData((prev) => ({
            ...prev,
            otpSessionId: res.SessionId,
          }));
        } else {
          alert(res.statusMsg);
        }
      })
      .catch((error) => {
        alert("somthing went wrong");
      });
  };

  return (
    <>
      <div>
        {/* <p style={{ fontSize: "35px", color: "white", textAlign: "center" }}>
          Enter OTP
        </p> */}
        {/* <Label
          content="Enter OTP"
          size="35px"
          textAlign="center"
          color="white"
        /> */}
        <p
          style={{
            fontSize: "18px",
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
          }}>
          Check {formData.email} email address for OTP
        </p>
      </div>
      <div>
        <OtpInput
          shouldAutoFocus={true}
          value={enteredOtp}
          onChange={(text) => {
            setEnteredOtp(text);
          }}
          numInputs={6}
          isInputNum={true}
          separator={<span></span>}
          inputStyle={{
            fontSize: 35,
            display: "flex",
            flexDirection: "row",
          }}
        />
        <OtpTimer
          // to style go the source code in nodemodules add respective styles
          //  cursor:"pointer" ,paddingTop:1em// added this to button styles
          seconds={30}
          minutes={0}
          text=""
          textColor="white"
          ButtonText={"Resend OTP"}
          background={"none"}
          resend={() => {
            sendOtp();
          }}
        />
        {otpError && (
          <p style={{ color: "orangered", fontWeight: "700" }}>{otpError}</p>
        )}
      </div>
      {/* <Input placeholder="Password" type="password" onChange={(text) => setEmailPass(st => ({ ...st, "password": text }))} securedEntry value={emailPass.password} /> */}
      {/* <Button
                  content="Continue"
                  style={{ backgroundColor: "rgb(119, 150, 109)" }}
                  onClick={() => {
                    // verify();
                  }}
                /> */}
      <div>
        <Button content={"Continue"} isLoading={isLoading} onClick={verify} />
        {/* <a>Back</a> */}
        <button
          style={{
            textDecoration: "underline",
            border: "none",
            outline: "none",
            fontWeight: "700",
            color: "sandybrown",
            fontSize: "medium",
            textAlign: "center",
            width: "inherit",
            cursor: "pointer",
            marginTop: "0.3em",
            background: "none",
          }}
          onClick={() => {
            setFormStage(0);
          }}>
          Back?
        </button>
      </div>
    </>
  );
}

export default Otp_User;
