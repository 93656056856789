import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import "./VendorStatement.scss";
import { environment } from "../../config";

const calculateBalanceAmount = (CommonMilestones, TotalOrderAmount) => {
  const paid_amount = calculateOrderTotalPaidAmount(CommonMilestones);
  return Number(TotalOrderAmount).toFixed(2) - Number(paid_amount).toFixed(2);
};

const calculateOrderTotalPaidAmount = (CommonMilestones) => {
  // CommonMilestones = JSON.parse(CommonMilestones)
  if (CommonMilestones && CommonMilestones.length === 0) {
    return;
  } else if (CommonMilestones) {
    return Number(
      CommonMilestones.filter((item) => item.name !== "Paid").reduce(
        (mTotalPaid, mItem) => {
          // console.log('mtotalPaid',mTotalPaid,mItem)
          return (mTotalPaid =
            mTotalPaid +
            ("payments" in mItem
              ? mItem.payments.reduce(
                (total, item) => (total = total + Number(item.amount)),
                0
              )
              : 0.0));
        },
        0
      ) +
      (CommonMilestones[0].name === "Paid"
        ? CommonMilestones[0].totalAmount
        : 0)
    ).toFixed(2);
  }
};

const calculateDateOfPayment = (timestamp) => {
  let dateObj = new Date(timestamp);
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();

  // let newdate = year + "/" + month + "/" + day;
  let newdate = day + "/" + month + "/" + year;

  return newdate;
};
function ordersMap(vendorOrders) {
  return vendorOrders.map((sItem, sIndex) => {
    return (
      <div key={sIndex} className="vendor-statement-order">
        {/* {sItem.Category} */}
        <button
          className="view-po-link"
          onClick={(e) => {
            e.stopPropagation();
            // const win = window.open(
            //   `https://65fe5ce0800776ae5a7c6c4f--glittery-speculoos-a28890.netlify.app//?po_id=${sItem.OrderId || sItem.Id}`,
            //   "_blank"
            // );
            // win.focus();

            const url =
              environment.environment === "staging"
                ? `https://staartstaging.netlify.app/?po_id=${sItem.OrderId || sItem.Id
                }`
                : `https://staartbuild.netlify.app/?po_id=${sItem.OrderId || sItem.Id
                }`;

            const win = window.open(url, "_blank");
            win.focus();
          }}
        >
          View PO
        </button>
        <div className="vendor-statement-order-head-details">
          <div className="order-head-details-left-container">
            <dl className="detail-pair">
              <dt>Category</dt>
              <dd> {sItem.Category}</dd>
            </dl>
            <dl className="detail-pair">
              <dt>
                {JSON.parse(sItem.Data)?.data[0]?.workType == "Only Work"
                  ? "Work Order"
                  : "Purchase Order"}{" "}
                Id
              </dt>
              <dd> {sItem.OrderId || sItem.Id} </dd>
            </dl>
            <dl className="detail-pair">
              <dt>Order Value</dt>{" "}
              <dd>
                {sItem?.OlderVersions && sItem.OlderVersions !== "null"
                  ? JSON.parse(sItem.OlderVersions)[0].OrderTotalAmount
                  : sItem.OrderTotalAmount}
              </dd>
            </dl>
            <dl className="detail-pair">
              <dt>Ammended Value</dt>{" "}
              <dd className="order-totalamount">{sItem.OrderTotalAmount}</dd>
            </dl>
          </div>

          <div className="order-head-details-right-container">
            {/* <dl>
              <dt>Payment Summary</dt>
            </dl> */}
            <p style={{ textAlign: "center" }}>Payment Summary</p>
            <hr />
            <dl className="detail-pair">
              <dt>Actual Bill</dt>-
            </dl>
            <dl className="detail-pair">
              <dt>Paid Amount</dt>
              <dd className="order-paidAmount">
                {" "}
                {calculateOrderTotalPaidAmount(
                  JSON.parse(sItem.CommonMilestones)
                )}
              </dd>
            </dl>
            <dl className="detail-pair">
              <dt>Balance Amount</dt>{" "}
              <dd className="order-balanceAmount">
                {calculateBalanceAmount(
                  JSON.parse(sItem.CommonMilestones),
                  sItem.OrderTotalAmount
                ).toFixed(2)}
              </dd>
            </dl>
          </div>
          {/* <table>
            <tbody>
              <tr style={{ backgroundColor: "#ffd34d" }}>
                <th>Order Value</th>
                <th>Amended Value</th>
                <th>Actual Bill</th>
                <th>Paid</th>
                <th>Balance</th>
              </tr>
              <tr>
                <td className="order-totalamount">
                  {sItem?.OlderVersions && sItem.OlderVersions !== "null"
                    ? JSON.parse(sItem.OlderVersions)[0].OrderTotalAmount
                    : sItem.OrderTotalAmount}
                </td>
                <td>{sItem.OrderTotalAmount}</td>
                <td>-</td>
                // <td>{sItem.CommonMilestones ? (Number(commonMilestonestoDisplay.reduce((mTotalPaid,mItem)=>{
                //          // console.log('mtotalPaid',mTotalPaid,mItem)
                //         return mTotalPaid = mTotalPaid +
                //          ("payments" in mItem ? 
                //            (mItem.payments.reduce((total, item) => (total = total + Number(item.amount)),0)) : 0.00)
                //        },0)) + (commonMilestonestoDisplay[0].name === 'Paid' ? (commonMilestonestoDisplay[0].totalAmount): 0))
                //        : 
                //        '-'}
                //        </td> 
                <td className="order-paidAmount">
                  {calculateOrderTotalPaidAmount(
                    JSON.parse(sItem.CommonMilestones)
                  )}
                </td>
                <td className="order-balanceAmount">
                  {calculateBalanceAmount(
                    JSON.parse(sItem.CommonMilestones),
                    sItem.OrderTotalAmount
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
        <div className="vendor-statement-order-payments">
          <p
            style={{
              background: "#ffd34d",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Payment Details
          </p>
          <table>
            <tbody>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Remarks</th>
                <th>Mode</th>
              </tr>
              {/* {sItem.CommonMilestones &&
              JSON.parse(sItem.CommonMilestones)[0].name === "Paid" ? (
                <tr>
                  <td>-</td>
                  <td>{JSON.parse(sItem.CommonMilestones)[0].totalAmount}</td>
                  <td>Paid</td>
                  <td>-</td>
                </tr>
              ) : (
                ""
              )} */}
              {sItem.CommonMilestones &&
                JSON.parse(sItem.CommonMilestones).map((cmItem, cmIndex) => {
                  return cmItem.payments
                    ? "payments" in cmItem &&
                    cmItem.payments.map((pItem, pIndex) => {
                      return (
                        <tr
                          key={`${sItem.OrderId} + ${cmIndex} + ${pIndex}`}
                        >
                          <td>{calculateDateOfPayment(pItem.date)}</td>
                          <td>{pItem.amount}</td>
                          {pItem.remarks ? (
                            <td>
                              {pItem.remarks
                                .replace(/<new-line>/g, "\n")
                                .replace(/<single-quote>/g, "'")
                                .replace(/<rupee-symbol>/g, "₹")
                                .replace(/<double-quote>/g, '"')}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          <td>{pItem.mode}</td>
                        </tr>
                      );
                    })
                    : null;
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  });
}

const VendorStatement = ({
  allOrders,
  vendorStatementModalisOpen,
  setVendorStatementModal,
  vendorDetailsForStatement,
}) => {
  // console.log('vendor',vendorStatementModalisOpen,allOrders)
  const [vendorOrders, setVendorOrders] = useState(
    allOrders.filter(
      (item, oIndex) =>
        item.VendorMobile === vendorDetailsForStatement.VendorMobile
    )
  );
  // console.log('vendorOrders',vendorOrders)
  const [totalVendorDetails, setVendorTotalDetails] = useState({
    TotalProjectAmount: 0,
    ProjectstotalBalance: 0,
    Projectstotalpaid: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      let Projectstotalpaid = 0;
      let ProjectstotalBalance = 0;
      let TotalProjectAmount = 0;
      const paidElementsArr =
        document.getElementsByClassName("order-paidAmount");
      const balanceElementsArr = document.getElementsByClassName(
        "order-balanceAmount"
      );
      const totalorderAmount =
        document.getElementsByClassName("order-totalamount");

      // console.log(paidElementsArr,paidElementsArr.length)

      for (let i = 0; i < paidElementsArr.length; i++) {
        Projectstotalpaid += Number(paidElementsArr[i].innerHTML);
      }
      for (let i = 0; i < totalorderAmount.length; i++) {
        TotalProjectAmount += Number(totalorderAmount[i].innerHTML);
      }
      for (let i = 0; i < balanceElementsArr.length; i++) {
        console.log(".item", balanceElementsArr[i]);
        if (
          Number(balanceElementsArr[i].innerHTML) !== NaN &&
          balanceElementsArr[i].innerHTML.length > 0 &&
          balanceElementsArr[i].innerHTML !== "NaN"
        ) {
          ProjectstotalBalance += Number(balanceElementsArr[i].innerHTML);
        }
      }
      // console.log("totalPAid", Projectstotalpaid);
      setVendorTotalDetails({
        TotalProjectAmount: TotalProjectAmount.toFixed(2),
        ProjectstotalBalance: ProjectstotalBalance.toFixed(2),
        Projectstotalpaid: Projectstotalpaid.toFixed(2),
      });
    }, 1000);
  }, []);

  return (
    <Modal
      sx={{}}
      open={vendorStatementModalisOpen}
      onClose={() => setVendorStatementModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="transactionDetailsModal"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "80%",
          maxHeight: "90%",
          maxWidth: "80%",
        }}
      >
        <div className="vendor-statement-container">
          <div className="vendor-statement-header">
            <h2 style={{ textAlign: "center" }}>Cash Flow Statement</h2>
            <div className="vendor-statement-details-container">
              <div className="vendor-statement-vendor-details">
                <h2>
                  {vendorDetailsForStatement.VendorName}
                  <span>{`(${vendorDetailsForStatement.VendorMobile})`}</span>
                </h2>
                <h4>{vendorDetailsForStatement.VendorCompany}</h4>
              </div>
              <div className="vendor-statement-project-acc-summary">
                <div className="project-acc-summary-header">
                  <p>Project Account Summary</p>
                  <hr />
                </div>
                <div className="project-acc-summary-body">
                  <dl className="detail-pair">
                    <dt>Total Project Amount</dt>
                    <dd>{`${totalVendorDetails.TotalProjectAmount}`}</dd>
                  </dl>
                  <dl className="detail-pair">
                    <dt>Total Paid</dt>
                    <dd>{`${totalVendorDetails.Projectstotalpaid}`}</dd>
                  </dl>
                  <dl className="detail-pair">
                    <dt style={{ color: "darkseagreen" }}>Balance</dt>
                    <dd>{`${totalVendorDetails.ProjectstotalBalance}`}</dd>
                  </dl>

                  {/* <div className="project-acc-summary-values"> */}

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          <div>{ordersMap(vendorOrders)}</div>
        </div>
      </Box>
    </Modal>
  );
};
export {
  VendorStatement,
  ordersMap,
  calculateBalanceAmount,
  calculateOrderTotalPaidAmount,
  calculateDateOfPayment,
};
