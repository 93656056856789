export default (data, cat) => {
  let object = {
    Material: [],
    Work: [],
  };

  const { rooms } = data;
  rooms.forEach((room, roomIndex) => {
    const { Units } = room;
    Units.forEach((Unit, unitIndex) => {
      const { Components } = Unit;
      if (Components) {
        Components.forEach((component, componentIndex) => {
          const { Material, Work } = component;
          Material.forEach((material, materialIndex) => {
            if (material.type === cat) {
              object["Material"].push({
                "Unit Name": Unit["Unit Name"],
                ...material,
                indexces: {
                  roomIndex,
                  unitIndex,
                  componentIndex,
                  materialIndex,
                },
              });
            }
          });
          Work.forEach((work, workIndex) => {
            if (work.vendorCategory === cat) {
              object["Work"].push({
                "Unit Name": Unit["Unit Name"],
                ...work,
                indexces: { roomIndex, unitIndex, componentIndex, workIndex },
                Drawings: Unit["Drawings"],
              });
            }
          });
        });
      }
    });
  });

  return object;
};
