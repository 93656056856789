import { secondDatabase } from "../../../config/firebase";

async function fetchVehicleInformation(firmId, projectId) {
  try {
    // Define the reference to the vehicle path
    console.log('firmId : ', firmId);
    console.log('projectId : ', projectId)
    const vehiclePathRef = secondDatabase.ref(`${firmId}/${projectId}/vehicles/`);
    // Fetch data using 'once' method to get the snapshot
    const snapshot = await vehiclePathRef.once('value');
    // Check if data exists in the snapshot
    if (snapshot.exists()) {
      // Extract data and transform into the required format
      const vehicleData = snapshot.val();
      console.log('fetchVehicleInformation vehicleData : ', vehicleData)
      const vehicleMap = Object.values(vehicleData || {}).reduce((acc, vehicle) => {
        if (vehicle.tag) {
          acc[vehicle.tag] = vehicle;
        }
        return acc;
      }, {});
      console.log('Transformed Vehicle Map:', vehicleMap);
      return vehicleMap;
    } else {
      console.log('No vehicle information found for the given path.');
      return {};
    }
  } catch (error) {
    console.error('Error fetching vehicle information:', error);
    throw error; // Optionally propagate the error for the caller to handle
  }
}

export default fetchVehicleInformation;
