import { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AuthService from "../../../api/authService";
import config from "../../../config/config";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { UserContext } from "../../../Context/UserContext";
import SmsService from "../../../api/smsService";
import { toast } from "react-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Dialog from "@material-ui/core/Dialog";
import done1 from "../../../assets/img/done1.png";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { AiOutlineDelete } from "react-icons/ai";
import AnalyticsService from "../../../api/analyticsService";
import Input from "@material-ui/core/Input";

const Form = ({ handleClose, selectedVendorDetails }) => {
  const addNewMilestones = () => {
    let tempMilestones = [...vendorMilestones];
    // }
    tempMilestones.push({
      name: "",
      percentage: 0,
    });

    setVendorMilestones(tempMilestones);
  };

  const handleAadharPanFileChange = (event, type) => {
    const file = event.target.files[0];
    console.log("Selected file:", file);
  };

  const analyticsService = new AnalyticsService();

  const userContext = useContext(UserContext);

  const [selectedVendor, setSelectedVendor] = useState();
  const [BAID, setBAID] = useState(null);
  const [data, setData] = useState({
    AccountNumber: " ",
    IFSC: "",
    AccountHolderName: "",
    PAN: "",
    AADHAR: "",
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [firm, setFirm] = useState(userContext.user.Firm);
  const [state, setState] = useState("");
  const [role, setRole] = useState("");
  const [selectedWork, setSelectedWork] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [emergencyContact, setEmergencyContact] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [pincode, setPincode] = useState("");
  const [gstIn, setGstIn] = useState("");
  const [city, setCity] = useState("");
  const [workMaterialCategories, setWorkMaterialCategories] = useState([]);
  const [materialCategories, setMaterialCategories] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [detailsSUbSuccess, setDetailsSubSuccess] = useState(false);
  const [bankDetailsSubSuccess, setBankDetailsSubSuccess] = useState(false);
  const [updateDetailSubSuccess, setUpdateDetailsSubSuccess] = useState(false);
  const [updateBankDeatailSubSuccess, setUpdatebankdetailsSubSuccess] =
    useState(false);
  const [vendorMilestones, setVendorMilestones] = useState([]);

  const fetchCategories = () => {
    fetch(`${config.utilService}categories`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setWorkMaterialCategories(json.vendorCategories);
        setMaterialCategories(json.materialCategories);
      })
      .catch((e) => {
        console.log(e.toString());
        alert("Something went wrong!");
      });
  };

  useEffect(() => {
    if (selectedVendorDetails) {
      getVendors();
    }
  }, [selectedVendorDetails]);

  const getVendors = () => {
    analyticsService
      .getFirmBasedAnalytics(localStorage.getItem("firm"))
      .then((res) => {
        if (res.status === 200) {
          let selected;
          if (selectedVendorDetails?.Mobile) {
            selected = res.Vendor.filter(
              (item) => item.Mobile === selectedVendorDetails.Mobile
            );
          } else {
            selected = res.Vendor.filter(
              (item) => item.Mobile === selectedVendorDetails.VendorMobile
            );
          }
          setSelectedVendor(selected[0]);
          setRole(selected[0].Role);
          if (selected[0].Role !== "Only Material") {
            setSelectedWork(selected[0].Category);
          } else {
            setSelectedMaterial(selected[0].Category);
          }
          setName(selected[0].Name);
          setPassword(selected[0].Password);
          setMobile(selected[0].Mobile.toString());
          setState(selected[0].State);
          setAddress(selected[0].Address);
          setCompanyName(selected[0].Company);
          setPincode(selected[0].PinCode);
          setGstIn(selected[0].GSTIN);
          setCity(selected[0].City);
          selected[0].Milestones &&
            setVendorMilestones(JSON.parse(selected[0].Milestones));
        }
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedVendorDetails?.Mobile) {
      getBankAccountDetails();
    }
  }, [selectedVendorDetails]);

  const getBankAccountDetails = () => {
    const Mobile = selectedVendorDetails.Mobile;
    const Token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ";
    fetch(`${config.profileService}bankAccount/${Mobile}`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: "Bearer " + Token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from getBankAccountDetails API: ", json);
        if (json.payload.length > 0) {
          setBAID(json.payload[0].BAID);
          setData(json.payload[0]);
        } else {
          setBAID(null);
        }
      })
      .catch((e) => {
        console.log("getBankAccountDetails error: ", e.toString());
      });
  };

  const bankDetailsSubmission = () => {
    if (
      name == "" ||
      mobile == "" ||
      role == "" ||
      password == "" ||
      (selectedMaterial == "" && selectedWork == "")
    ) {
      alert("Fill all the mandatory fields!");
    } else {
      if (BAID == null) {
        const Mobile = sanitizeString(mobile);
        const Token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ";
        console.log("gstin:", gstIn);
        fetch(`${config.profileService}bankAccount`, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + Token,
          },
          body: JSON.stringify({
            CreatedBy: Mobile,
            AccountNumber: sanitizeString(data.AccountNumber),
            IFSC: data.IFSC,
            AccountHolderName: sanitizeString(data.AccountHolderName),
            PAN: sanitizeString(data.PAN),
            AADHAR: sanitizeString(data.AADHAR),
            GSTIN: sanitizeString(gstIn),
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            console.log("Response from addBankAccountDetails API: ", json);
            if (json.status == 200) {
              setBankDetailsSubSuccess(true);
            } else {
              alert("Something went wrong in add bank details!");
            }
          })
          .catch((e) => {
            console.log("addBankAccountDetails error: ", e.toString());
          });
      } else {
        const Token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ";
        let payload = {};
        if (data.AccountNumber) {
          payload.AccountNumber = data.AccountNumber;
        }
        if (data.IFSC) {
          payload.IFSC = data.IFSC;
        }
        if (data.AccountNumber) {
          payload.AccountNumber = data.AccountNumber;
        }
        if (data.AccountHolderName) {
          payload.AccountHolderName = data.AccountHolderName;
        }
        if (data.PAN) {
          payload.PAN = data.PAN;
        }
        if (data.AADHAR) {
          payload.AADHAR = data.AADHAR;
        }
        if (mobile) {
          payload.CreatedBy = mobile;
        }
        fetch(`${config.profileService}updateBankAccount/${BAID}`, {
          method: "PUT",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + Token,
          },
          body: JSON.stringify(payload),
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            console.log("Response from updateBankAccountDetails API: ", json);
            if (json.status == 200) {
              setUpdatebankdetailsSubSuccess(true);
            } else {
              toast.error("Please fill all your details and try again.", {
                backgroundColor: "red",
                color: "white",
              });
            }
          })
          .catch((e) => {
            console.log("updateBankAccountDetails error: ", e.toString());
          });
      }
    }
  };

  const sanitizeString = (input) => {
    return input?.replace(/['"\\]/g, "").trim();
  };

 

  const onSubmit = async () => {
    console.log("FORM DETAILS FILLED", companyName, name, mobile);
    if (
      name == "" ||
      mobile == "" ||
      role == "" ||
      password == "" ||
      (selectedMaterial == "" && selectedWork == "")
    ) {
      alert("Kindly fill all the details of vendor!");
    } else if (
      vendorMilestones.length > 0 &&
      vendorMilestones.some((element, index) => element.name.length <= 0)
    ) {
      alert("Milestone description cannot be empty");
    } else if (
      (vendorMilestones.length > 0 &&
        vendorMilestones
          .reduce(
            (percentage, item) => (percentage += Number(item.percentage)),
            0
          )
          .toFixed(0) != 100) ||
      0
    ) {
      console.log("milestonessssssss");
      console.log(
        vendorMilestones.some((element, index) => element.name.length <= 0)
      );
   
    } else {
      let category = selectedWork ? selectedWork : selectedMaterial;
      let selectedMaterialItems;
      if (Array.isArray(category)) {
        let selectedCategory = [];
        category.forEach((item) => {
          selectedCategory.push(item.replace("(", "").replace(")", ""));
        });
        selectedMaterialItems = `(${selectedCategory})`;
      } else {
        selectedMaterialItems = category;
      }
      if (selectedVendorDetails) {
        const Token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ";
        const userId = selectedVendorDetails.Id;
        let payload = {};
        if (name) {
          payload.Name = sanitizeString(name);
        }
        if (city) {
          payload.City = sanitizeString(city);
        }
        if (mobile) {
          payload.Mobile = mobile;
        }
        if (selectedMaterial) {
          payload.Category = selectedMaterialItems;
        }
        if (state) {
          payload.State = sanitizeString(state);
        }
        if (address) {
          payload.Address = sanitizeString(address);
        }
        if (pincode) {
          payload.PinCode = sanitizeString(pincode);
        }
        if (gstIn) {
          payload.GSTIN = sanitizeString(gstIn);
        }
        if (role) {
          payload.Role = role;
        }
        if (companyName) {
          payload.Company = sanitizeString(companyName);
        }
        if (vendorMilestones.length > 0) {
          payload.Milestones = vendorMilestones;
        }
        fetch(`${config.profileService}vendor/profile/${userId}`, {
          method: "PUT",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + Token,
          },
          body: JSON.stringify(payload),
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            if (json.status == 200) {
              setUpdateDetailsSubSuccess(true);
              bankDetailsSubmission();
            } else if (
              json.status == 400 &&
              json.statusMsg.includes("Duplicate entry")
            ) {
              alert(
                "A vendor with this mobile number has already been registered! Try registering with a different mobile number."
              );
            } else {
              alert("Please fill all your details and try again.");
            }
          })
          .catch((e) => {
            console.log("updateDetails error: ", e.toString());
          });
      } else {
        const authService = new AuthService();
        if (
          password.trim().length > 0 &&
          name.trim().length > 0 &&
          mobile.trim().length > 0 &&
          role.trim().length > 0
        ) {
          try {
            const response = await authService.vendorSignup(
              sanitizeString(name),
              sanitizeString(mobile),
              selectedMaterialItems,
              sanitizeString(state),
              role,
              email,
              sanitizeString(password),
              firm,
              emergencyContact,
              sanitizeString(city),
              sanitizeString(address),
              sanitizeString(pincode),
              sanitizeString(gstIn),
              sanitizeString(companyName),
              vendorMilestones
            );
            if (response.status == 200) {
              setDetailsSubSuccess(true);
              bankDetailsSubmission();
            } else if (
              response.status == 400 &&
              response.statusMsg.includes("Duplicate entry")
            ) {
              alert(
                "A vendor with this mobile number has already been registered! Try registering with a different mobile number."
              );
            } else {
              alert("Please fill all your details and try again.");
            }
            await new SmsService().sendSms(
              "Thanks for registering as a vendor with staart.build",
              `+91${mobile}`
            );
          } catch (e) {
            alert(e);
          }
        } else {
          alert("Please fill all the details!");
        }
      }
    }
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "33%", padding: "20px" }}>
          <div
            style={{
              borderBottom: "1px solid gray",
              marginBottom: "5px",
              paddingBottom: "3px",
              color: "#404040",
              fontWeight: "800",
            }}
          >
            <p>VENDOR DETAILS</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="Name"
                variant="filled"
                required
                onChange={(e) => setName(e.currentTarget.value)}
                value={name}
              />
            </div>
            <FormControl
              variant="filled"
              required
              style={{
                borderRadius: "8px",
                width: "100%",
                fontSize: "20px", 
                marginBottom: "10px",
              }}
            >
              <InputLabel>Role</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                onChange={(event) => setRole(event.target.value)}
                value={role}
              >
                <MenuItem value={"Only Work"}>Only Work</MenuItem>
                <MenuItem value={"Only Material"}>Only Material</MenuItem>
                <MenuItem value={"Work + Material"}>Work + Material</MenuItem>
              </Select>
            </FormControl>

            {role === "Only Work" || role === "Work + Material" ? (
              <FormControl
                variant="filled"
                required
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Category</InputLabel>
                <Select
                  value={selectedWork}
                  onChange={(event) => {
                    setSelectedWork(event.target.value);
                    setSelectedMaterial([]);
                  }}
                  renderValue={(selectedWork) => selectedWork}
                >
                  {workMaterialCategories.map((item, index) => (
                    <MenuItem value={item.Category} key={index}>
                      {item.Category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl
                variant="filled"
                required
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Category</InputLabel>
                <Select
                  value={
                    selectedVendor?.Role === "Only Material"
                      ? selectedVendor?.Category.replace("(", "")
                        .replace(")", "")
                        .split(",")
                      : selectedMaterial
                  }
                  onChange={(event) => {
                    setSelectedMaterial(event.target.value);
                    setSelectedWork("");
                  }}
                  multiple
                  renderValue={(selectedMaterial) => {
                    let selectedCategory = [];
                    if (Array.isArray(selectedMaterial)) {
                      selectedMaterial.forEach((item) => {
                        selectedCategory.push(
                          item
                            .replace("(", "")
                            .replace(")", "")
                            .replace('"', "")
                            .replace('"', "")
                        );
                      });
                    }
                    return `${selectedCategory}`;
                  }}
                >
                  {materialCategories.map((item, index) => (
                    <MenuItem value={`("${item.name}")`} key={index}>
                      <Checkbox
                        checked={
                          selectedMaterial.indexOf(`("${item.name}")`) > -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* <div>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="Firm Name"
                variant="filled"
                onChange={(e) => setFirm(e.currentTarget.value)}
                value={firm}
              />
            </div> */}
            <div>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="Company Name"
                variant="filled"
                onChange={(e) => setCompanyName(e.currentTarget.value)}
                value={companyName}
              />
            </div>
            <div>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="GSTIN"
                variant="filled"
                onChange={(e) => {
                  setGstIn(e.currentTarget.value);
                  console.log("onchange event is triggered");
                }}
                value={gstIn}
              />
            </div>
            <div>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="Mobile"
                inputProps={{ maxLength: 10 }}
                variant="filled"
                required
                onChange={(e) => setMobile(e.currentTarget.value)}
                value={mobile.replace(/(?!-)[^0-9]/g, "")}
              />
            </div>
            <div style={{ position: "relative" }}>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="Password"
                variant="filled"
                type={showPassword ? password : "password"}
                required
                onChange={(e) => setPassword(e.currentTarget.value)}
                value={password}
                inputProps={{
                  autoComplete: "new-password",
                  form: { autocomplete: "off" },
                }}
                disabled={selectedVendorDetails ? true : false}
              />
              {!selectedVendorDetails && (
                <div>
                  {showPassword ? (
                    <AiFillEye
                      style={{
                        position: "absolute",
                        color: "gray",
                        left: "81%",
                        bottom: "18px",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                      size={30}
                    />
                  ) : (
                    <AiFillEyeInvisible
                      style={{
                        position: "absolute",
                        left: "81%",
                        bottom: "18px",
                        color: "gray",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                      size={30}
                    />
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
        <div style={{ width: "33%", padding: "20px" }}>
          <div
            style={{
              borderBottom: "1px solid gray",
              marginBottom: "5px",
              paddingBottom: "3px",
              color: "#404040",
              fontWeight: "800",
              marginBottom: "15px",
            }}
          >
            <p>Address</p>
          </div>
          <div>
            <TextField
              style={{ width: "100%", marginBottom: "10px" }}
              label="Address"
              variant="filled"
              onChange={(e) => setAddress(e.currentTarget.value)}
              value={address}
            />
          </div>
          <div>
            <TextField
              style={{ width: "100%", marginBottom: "10px" }}
              label="Pincode"
              variant="filled"
              onChange={(e) => setPincode(e.currentTarget.value)}
              value={pincode}
            />
          </div>
          <div>
            <TextField
              style={{ width: "100%", marginBottom: "10px" }}
              label="City"
              variant="filled"
              onChange={(e) => setCity(e.currentTarget.value)}
              value={city}
            />
          </div>
          <div>
            <TextField
              style={{ width: "100%", marginBottom: "10px" }}
              label="State"
              variant="filled"
              onChange={(e) => setState(e.currentTarget.value)}
              value={state}
            />
          </div>
        </div>

        <div style={{ width: "33%", padding: "20px" }}>
          <div
            style={{
              borderBottom: "1px solid gray",
              marginBottom: "5px",
              paddingBottom: "3px",
              color: "#404040",
              fontWeight: "800",
              marginBottom: "15px",
            }}
          >
            <p>BANK DETAILS</p>
          </div>
          <form>
            <div>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="Account Holder Name"
                variant="filled"
                onChange={(e) =>
                  setData({ ...data, AccountHolderName: e.currentTarget.value })
                }
                value={data.AccountHolderName}
              />
            </div>
            <div>
              <TextField
                label="Account number"
                style={{ width: "100%", marginBottom: "10px" }}
                variant="filled"
                onChange={(e) =>
                  setData({ ...data, AccountNumber: e.currentTarget.value })
                }
                value={data.AccountNumber.replace(/(?!-)[^0-9]/g, "")}
              />
            </div>
            <div>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="IFSC CODE"
                variant="filled"
                onChange={(e) =>
                  setData({ ...data, IFSC: e.currentTarget.value })
                }
                value={data.IFSC}
              />
            </div>
            <div>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="Aadhaar Card"
                variant="filled"
                onChange={(e) =>
                  setData({ ...data, AADHAR: e.currentTarget.value })
                }
                value={data.AADHAR}
              />
            </div>
            <div>
              <Input
                type="file"
                name="Aadhar File"
                onChange={(e) => {
                  handleAadharPanFileChange(e, "Aadhar");
                }}
                inputProps={{ accept: "image/*,.pdf" }} // Optional: Set accepted file types
              />
            </div>
            <div>
              <TextField
                label="PAN Card"
                style={{ width: "100%", marginBottom: "10px" }}
                variant="filled"
                onChange={(e) =>
                  setData({ ...data, PAN: e.currentTarget.value })
                }
                value={data.PAN}
              />
            </div>
            <div>
              <Input
                label="PAN Card"
                type="file"
                name="Pan File"
                onChange={(e) => {
                  handleAadharPanFileChange(e, "Pan");
                }}
                inputProps={{ accept: "image/*,.pdf" }} // Optional: Set accepted file types
              />
            </div>
            <div
              style={{
                marginTop: "50%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onSubmit();
                }}
              >
                {selectedVendorDetails ? "Update" : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <p>Milestones</p>
        <div style={{ width: "100%", padding: "20px" }}>
          <h2 style={{ textAlign: "center" }}>Set Milestones for Vendor</h2>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                width: "10%",
                maxWidth: "10%",
                minWidth: "10%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Sr. No
            </p>
            <p
              style={{
                width: "60%",
                maxWidth: "60%",
                minWidth: "60%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Milestone /RA Description
            </p>
            <p
              style={{
                width: "30%",
                maxWidth: "30%",
                minWidth: "30%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Payment %
            </p>
          </div>
          <div>
            {vendorMilestones.map((mItem, mIndex) => (
              <div
                style={{ display: "flex", flexDirection: "column" }}
                key={mIndex}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <p
                    style={{
                      width: "10%",
                      maxWidth: "10%",
                      minWidth: "10%",
                      textAlign: "center",
                      padding: 5,
                    }}
                  >
                    {mIndex + 1}
                  </p>
                  <input
                    style={{
                      width: "60",
                      maxWidth: "60%",
                      minWidth: "60%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                    placeholder="Type your milestone here"
                    value={mItem.name
                      ?.replace(/<single-quote>/g, "'")
                      .replace(/<double-quote>/g, '"')
                      .replace(/<rupee-quote>/g, "₹")
                      .split("<new-line>")}
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      value = value
                        .replace(/'/g, "<single-quote>")
                        .replace(/"/g, "<double-quote>")
                        .replace(/₹/g, "<rupee-symbol>")
                        .replace(/\n/g, "<new-line>");
                      let tempMilestones = [...vendorMilestones];
                      tempMilestones[mIndex].name = value;
                      setVendorMilestones(tempMilestones);
                    }}
                  />
                  <input
                    style={{
                      width: "30%",
                      maxWidth: "30%",
                      minWidth: "30%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                    value={mItem.percentage + "%"}
                    placeholder="%"
                    // value=''
                    onChange={(e) => {
                      let value = e.currentTarget.value.replace(
                        /(?!-)[^0-9.]/g,
                        ""
                      );
                      let tempMilestones = [...vendorMilestones];
                      tempMilestones[mIndex].percentage = value;
                      setVendorMilestones(tempMilestones);
                    }}
                  />
                  <div
                    style={{
                      width: "5%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // write the code here
                      let tempMilestones = [...vendorMilestones];
                      tempMilestones.splice(mIndex, 1);
                      setVendorMilestones(tempMilestones);
                    }}
                  >
                    <AiOutlineDelete />
                  </div>
                </div>
                <hr style={{ height: "3px" }} />
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.03)",
            }}
          >
            <p
              style={{
                width: "10%",
                maxWidth: "10%",
                minWidth: "10%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
              }}
            >
              Total
            </p>

            <p
              style={{
                width: "60%",
                maxWidth: "60%",
                minWidth: "60%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
              }}
            ></p>
            <p
              style={{
                width: "30%",
                maxWidth: "30%",
                minWidth: "30%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
                fontWeight: 500,
                color: `${vendorMilestones
                    .reduce(
                      (total, item) =>
                        (total = total + Number(item.percentage)),
                      0
                    )
                    .toFixed(0) == 100
                    ? "green"
                    : "red"
                  }`,
              }}
            >
              {vendorMilestones
                .reduce(
                  (total, item) => (total = total + Number(item.percentage)),
                  0
                )
                .toFixed(0)}{" "}
              %
            </p>
           
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              cursor: "pointer",
              marginTop: 20,
            }}
            onClick={() => {
              addNewMilestones();
            }}
          >
            <p
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                lineHeight: "20px",
                fontSize: "20px",
                textAlign: "center",
                border: "1px solid #4685D4",
                color: "#4685D4",
              }}
            >
              +
            </p>
            <p style={{ color: "#4685D4" }}>Add New Milestone</p>
          </div>
        </div>
      </div>
      <Dialog
        open={
          detailsSUbSuccess ||
          bankDetailsSubSuccess ||
          updateDetailSubSuccess ||
          updateBankDeatailSubSuccess
        }
        onClose={handleClose}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 30px 10px 30px",
          }}
        >
          <div style={{ padding: "10px" }}>
            <img src={done1} alt="done image" style={{ width: "280px" }} />
          </div>
          <div style={{ padding: "5px 0px" }}>
            <p style={{ color: "black", fontSize: "24px", fontWeight: "600" }}>
              Thank You!
            </p>
          </div>
          {detailsSUbSuccess && bankDetailsSubSuccess && (
            <div style={{ padding: "5px 0px" }}>
              <p
                style={{
                  color: "#484848",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Vendor details have been registered successfully!
              </p>
            </div>
          )}
          {(updateDetailSubSuccess || updateBankDeatailSubSuccess) && (
            <div style={{ padding: "5px 0px" }}>
              <p
                style={{
                  color: "#484848",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Vendor details have been updated successfully!
              </p>
            </div>
          )}
          <div style={{ padding: "15px" }}>
            <Button variant="contained" color="primary" onClick={handleClose}>
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Form;
