import React, { useState, useEffect } from "react";
import ImageModal from "./ImageModal";
import { FaCamera, FaTrashAlt } from "react-icons/fa";

const ImageUpload = ({
  parIndex,
  item,
  items,
  setItems,
  itemsImages,
  setItemsImages,
}) => {
  const [images, setImages] = useState([]);

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  useEffect(() => {
    setImages(itemsImages[parIndex])
  }, [itemsImages]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      id: URL.createObjectURL(file),
      file,
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);

    let tempItemsImages = [...itemsImages];
    tempItemsImages[parIndex] = [...tempItemsImages[parIndex], ...newImages];
    setItemsImages(tempItemsImages);
  };

  const handleDeleteImage = (id, index) => {
    // Update local images state
    setImages((prevImages) => prevImages.filter((image) => image.url !== id));
  
    // Create a new copy of itemsImages
    const updatedItemsImages = itemsImages.map((images, i) => {
      if (i === parIndex) {
        // Return a new array for the specific index
        return images.filter((_, imgIndex) => imgIndex !== index);
      }
      return images; // Return the existing array for other indices
    });
  
    // Update the itemsImages state with the new copy
    setItemsImages(updatedItemsImages);
  };

  // Open modal for selected image
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  // Close modal
  const handleCloseModal = () => {
    setSelectedImageIndex(null);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
        width: "100%",
      }}
    >
      {/* Camera Icon for Upload */}
      <label htmlFor={`image-upload-${parIndex}`} style={{ cursor: "pointer" }}>
        <FaCamera size={40} color={"#aeaeae"} />
      </label>
      <input
        id={`image-upload-${parIndex}`}
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />

      {/* Horizontal Scrollable Container */}
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          maxHeight: "15vh",
          width: "100%",
          border: images.length > 0 ? "1px solid #ccc" : null,
          padding: "10px",
          borderRadius: "8px",
          gap: "10px",
        }}
      >
        {console.log("images : ", images, typeof images, Array.isArray(images))}

        {Array.isArray(images)
          ? images.map((image, index) => (
              <div
                key={image.url || image.id}
                style={{
                  position: "relative",
                  flex: "0 0 auto",
                  maxHeight: "9vh",
                  cursor: "pointer",
                }}
              >
                <img
                  src={image.url || image.id}
                  alt="Uploaded"
                  style={{
                    maxHeight: "9vh",
                    width: "auto",
                    borderRadius: "8px",
                    objectFit: "contain",
                  }}
                  onClick={() => handleImageClick(index)}
                />
                <FaTrashAlt
                  size={20}
                  color="red"
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDeleteImage(image.url || image.id, index)}
                />
              </div>
            ))
          : Object.values(images).map((image, index) => (
              <div
                key={image.url || image.id}
                style={{
                  position: "relative",
                  flex: "0 0 auto",
                  maxHeight: "9vh",
                  cursor: "pointer",
                }}
              >
                <img
                  src={image.url || image.id}
                  alt="Uploaded"
                  style={{
                    maxHeight: "9vh",
                    width: "auto",
                    borderRadius: "8px",
                    objectFit: "contain",
                  }}
                  onClick={() => handleImageClick(index)}
                />
                <FaTrashAlt
                  size={20}
                  color="red"
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDeleteImage(image.url || image.id)}
                />
              </div>
            ))}
      </div>

      {/* Image Modal */}
      {selectedImageIndex !== null && (
        <ImageModal
          images={images}
          selectedImageIndex={selectedImageIndex}
          onClose={handleCloseModal}
          onDelete={handleDeleteImage}
        />
      )}
    </div>
  );
};

export default ImageUpload;
