export default (data) => {
  let object = {
    Material: [],
    Work: [],
  };

  const { rooms } = data;

  rooms.forEach((room, roomIndex) => {
    const { Units } = room;
    Units.forEach((Unit, unitIndex) => {
      const { Components } = Unit;
      if (Components) {
        Components.forEach((component, componentIndex) => {
          const { Material, Work } = component;
          Material.forEach((material, materialIndex) => {
            object["Material"].push({
              "Unit Name": Unit["Unit Name"],
              heading: room["Room Name"],
              ...material,
              indexces: { roomIndex, unitIndex, componentIndex, materialIndex },
            });
          });
          Work.forEach((work, workIndex) => {
            object["Work"].push({
              "Unit Name": Unit["Unit Name"],
              heading: room["Room Name"],
              ...work,
              indexces: { roomIndex, unitIndex, componentIndex, workIndex },
              Drawings: Unit["Drawings"],
            });
          });
        });
      }
    });
  });

  return object;
};
