import React from "react";

export default ({
  content,
  size,
  bold,
  textAlign,
  onClick,
  color,
  fontWeight,
}) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      style={{
        fontSize: size
          ? size
          : size === "xlarge"
          ? 24
          : size === "large"
          ? 20
          : 16,
        fontWeight: fontWeight ? fontWeight : bold ? "500" : "normal",
        textAlign: textAlign ? textAlign : "left",
        color: color ? color : onClick ? "blue" : "grey",
        textDecoration: onClick ? "underline" : "normal",
      }}
    >
      {content}
    </div>
  );
};
