import { secondDatabase } from "../../../config/firebase";

const modifyOutwardNetInventory = async (
  firmId,
  projectId,
  existingArray,
  updatedArray,
  entryId,
  userName
) => {
  // Create a map of existing inventory items by id for quick lookup
  const basePath = `${firmId}/${projectId}/allInventory/`;

  const existingItemsMap = existingArray.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});

  // Loop through the updated array
  for (const updatedItem of updatedArray) {

    const existingItem = existingItemsMap[updatedItem.id];

    const categoryRef = secondDatabase.ref(
      `${basePath}${updatedItem.category}`
    );

    const descriptionRef = secondDatabase.ref(
      `${basePath}${updatedItem.category}/${updatedItem.itemID}`
    );

    // Check if item is new or updated
    if (existingItem) {
      // Item exists in both arrays, check for quantity change
      const quantityChange =
        parseFloat(existingItem.quantityAccepted) -
        parseFloat(updatedItem.quantityAccepted); // Note: Quantity is reduced for outward

      const newQuantity =
        parseFloat(existingItem.quantityAccepted) - quantityChange;

      if (quantityChange !== 0) {
        // Quantity has changed, update the inventory and add to history
        const historyData = {
          invoiceNumber: "Not provided",
          name: userName || "Not provided",
          poNumber: updatedItem.vehicle || "Not provided",
          quantity: -updatedItem.quantityAccepted, // Negative because it's outward
          //   quantity: -newQuantity, // Negative because it's outward
          status: "Outward",
          timeStamp: updatedItem.historyId,
          entryId: entryId,
          itemEntryId: updatedItem.id,
          purpose: updatedItem.purpose,
          tag: updatedItem.tag || null,
          vehicleType: updatedItem.tag || null,
        };

        const descriptionSnapshot = await descriptionRef.once("value");

        if (descriptionSnapshot.exists()) {
          const existingData = descriptionSnapshot.val();
          existingData.history[updatedItem.historyId] = historyData;

          await descriptionRef.update({
            availableQuantity:
              parseFloat(existingData.availableQuantity || 0) - quantityChange,
            history: existingData.history,
          });

        } else {

          console.error(
            `Outward entry for a non-existing item: ${updatedItem.id}`
          );

        }
      }
    } else {
      console.error(
        `Outward entry cannot be created for non-existing item: ${updatedItem.id}`
      );
    }
  }

  // Handle deleted items
  for (const existingItem of existingArray) {
    const isItemDeleted = !updatedArray.some(
      (updatedItem) => updatedItem.id === existingItem.id
    );
    if (isItemDeleted) {
      // Item is deleted, adjust the inventory accordingly
      console.log("Deleted item detected for outward:", existingItem.id);

      const historyData = {
        invoiceNumber: "Not provided",
        name: userName || "Not provided",
        poNumber: existingItem.vehicle || "Not provided",
        quantity: -parseFloat(existingItem.quantityAccepted || 0), // Negative for outward
        status: "Outward",
        timeStamp: existingItem.historyId,
        entryId: entryId,
        itemEntryId: existingItem.id,
        purpose: existingItem.purpose,
        tag: existingItem.tag,
        vehicleType: existingItem.tag,
      };

      const descriptionRef = secondDatabase.ref(
        `${basePath}${existingItem.category}/${existingItem.itemID}`
      );

      const descriptionSnapshot = await descriptionRef.once("value");
      if (descriptionSnapshot.exists()) {
        const existingData = descriptionSnapshot.val();
        await descriptionRef.update({
          availableQuantity:
            parseFloat(existingData.availableQuantity || 0) -
            parseFloat(existingItem.quantityAccepted || 0),
          history: {
            ...existingData.history,
            [existingItem.historyId]: historyData,
          },
        });
      } else {
        console.error(
          `Cannot update outward entry for non-existing item: ${existingItem.id}`
        );
      }
    }
  }
  console.log("Outward inventory data updated successfully.");
};

export default modifyOutwardNetInventory;
