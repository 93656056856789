import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import firebase from "../../../config/firebase";
import toast from "react-hot-toast";


import { TextArea } from "../../../components";
import ProjectService from "../../../api/projectService";
import { useHistory, useLocation } from "react-router-dom";
const ClientDetailsModal = ({ isOpen, onClose, onSubmit, data }) => {

  const [receiverData, setReceiverData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pin: "",
    gstNo: "",
    panNo: "",
  });

  const [consigneeData, setConsigneeData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pin: "",
    gstNo: "",
    panNo: "",
  });

  const [sameAsReceiver, setSameAsReceiver] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [projectsData, setProjectsData] = useState();
  const location = useLocation();
  const projectService = new ProjectService();
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  useEffect(() => {
    console.log("location.pathname", location.pathname);
    projectService
      .getProjectById(location.pathname.split("/")[2])
      .then((response) => {
        setProjectsData({
          ...response.payload[0],
          Rooms: JSON.parse(response.payload[0].Rooms),
        });
      });
  }, []);
  useEffect(() => {
    console.log("projectsData", projectsData);
  }, [projectsData]);
  useEffect(() => {
    if (sameAsReceiver) {
      setConsigneeData(receiverData);
    }
  }, [sameAsReceiver, receiverData]);
  const updateClientFBRDB = () => {
    console.log("ClientNumber is updated here", phoneNumber);
    firebase
      .database()
      .ref(`clientUserDetails/${phoneNumber}/projects/${data.ProjectId}`)
      .set({
        projectId: data.ProjectId,
        projectName: data.ClientName,
      });
    firebase
      .database()
      .ref(`liveBOQ/${data.ProjectId}/clientNumber`)
      .set(phoneNumber);
  };
  const checkClientNumberExists = async () => {
    firebase

      .database()
      .ref("liveBOQ/" + data.ProjectId.toString() + "/clientNumber")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          let tempClientNumber = snapshot.val();
          console.log("tempClientNumber", tempClientNumber);
          if (tempClientNumber !== phoneNumber) {
            deleteClientIfNumberChanged(tempClientNumber);
            updateClientFBRDB();
          } else {
            console.log("ClientNumber does not exist");
            updateClientFBRDB();
          }
        } else {
          console.log("ClientNumber does not exist");
          updateClientFBRDB();
        }
      });
  };
  const deleteClientIfNumberChanged = (tempClientNumber) => {
    firebase
      .database()
      .ref(`clientUserDetails/${tempClientNumber}/projects/${data.ProjectId}`)
      .remove();
    
  };
  const fetchClientPhoneNumber = async () => {
    const clientDetailsRef = firebase
      .database()
      .ref(`liveBOQ/${data.ProjectId}/clientNumber/`);

    clientDetailsRef.on("value", (snapshot) => {
      const values = snapshot.val();
      if (values !== null) {
        setPhoneNumber(values);
        setClientNumber(values);
      }
    });

    return () => {
      clientDetailsRef.off("value");
    };
  };

  const checkAddressDetails = async () => {
    const receiverRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/receiverDetails/`);
    const consigneeRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/consigneeDetails/`);

    receiverRef.on("value", (snapshot) => {
      const values = snapshot.val();
      if (values !== null) {
        setReceiverData(values);
      }
    });

    consigneeRef.on("value", (snapshot) => {
      const values = snapshot.val();
      if (values !== null) {
        setConsigneeData(values);
      }
    });
    
    return () => {
      receiverRef.off("value");
      consigneeRef.off("value");
    };
  };

  useEffect(() => {
    checkAddressDetails();
    fetchClientPhoneNumber();
  }, []);

  const handleReceiverChange = (e) => {
    const { name, value } = e.target;
    setReceiverData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleConsigneeChange = (e) => {
    const { name, value } = e.target;
    setConsigneeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(receiverData, consigneeData);
    toast.success("Details Saved Successfully");
    checkClientNumberExists();
    updateInSQL();
    onClose();
  };
  const updateInSQL = async () => {
    const ProjectId = location.pathname.split("/")[2];

    console.log("ProjectId", ProjectId);

    const ClientName = data.ClientName;
    console.log("ClientName", ClientName);

    const ClientNumber = phoneNumber;

    const Address = data.Address;
    console.log("data.Address", data.Address);
    console.log("Address", Address);
    const SiteEngineersId = data.SiteEngineersId;
    console.log("data.SiteEngineersId", data.SiteEngineersId);
    console.log("SiteEngineersId", SiteEngineersId);
    // const additionalSEIds = projectsData.additionalSEIds
    //   .filter((num) => num !== "")
    //   .map((num) => parseInt(num, 10));
    console.log("data", data);
    await projectService.setProject({
      ProjectId,
      ClientName,
      ClientNumber,
      Address,
      SiteEngineersId,
    });
  };
  if (!isOpen) return null;

  return (

    <div>

      <style>
        {`
        /* Modal and button base styles */
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background-color: #ffffff;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
          max-width: 1200px;
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
        }

        .modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ddd;
          padding-bottom: 10px;
          margin-bottom: 20px;
        }

        .modal-title {
          font-size: 28px;
          font-weight: bold;
          color: #333;
        }

        .close-modal-button {
          background: #f5f5f5;
          border: none;
          border-radius: 50%;
          font-size: 24px;
          cursor: pointer;
          color: #333;
          transition: all 0.3s ease;
          padding: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        .close-modal-button:hover {
          background: #e0e0e0;
          transform: scale(1.1);
        }

        .form-container {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }

        .form-wrapper {
          flex: 1;
          background-color: #f9f9f9;
          border-radius: 8px;
          padding: 20px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        .form-group {
          margin-bottom: 20px;
        }

        .form-label {
          display: block;
          margin-bottom: 8px;
          font-weight: bold;
          color: #555;
        }

        .form-input {
          width: 100%;
          padding: 12px;
          border: 1px solid #ddd;
          border-radius: 6px;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
          font-size: 16px;
          transition: border-color 0.3s ease;
        }

        .form-input:focus {
          border-color: #007bff;
          outline: none;
        }

        .toggle-container {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }

        .toggle-label {
          margin-left: 12px;
          font-weight: bold;
          color: #555;
          font-size: 14px;
        }

        .modal-footer {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          border-top: 1px solid #ddd;
          padding-top: 10px;
        }

        .btn {
          padding: 12px 20px;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease, transform 0.3s ease;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .btn-secondary {
          background-color: #6c757d;
          color: #ffffff;
          margin-right: 10px;
          background-image: linear-gradient(to right, #6c757d, #5a6268);
        }

        .btn-secondary:hover {
          background-image: linear-gradient(to right, #5a6268, #6c757d);
          transform: scale(1.05);
        }

        .btn-primary {
          background-color: #007bff;
          color: #ffffff;
          background-image: linear-gradient(to right, #007bff, #0056b3);
        }

        .btn-primary:hover {
          background-image: linear-gradient(to right, #0056b3, #007bff);
          transform: scale(1.05);
        }
        `}
      </style>

      <div className="modal-overlay" onClick={onClose}>

        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h2 className="modal-title">Client Details</h2>
              <input
                id="hey"
                type="text"
                maxLength={10}
                placeholder="Enter Phone Number"
                placeholderTextColor="#9ca3af"
                value={phoneNumber}
                onChange={(e) => {
                  const inputValue = e.currentTarget.value.trim(); // Trim whitespace from input
                  console.log("Input Value: ", inputValue);
                  const regex = /^[0-9]{0,10}$/; // Regex to match 0 to 10 digits

                  if (regex.test(inputValue)) {
                    setPhoneNumber(inputValue);
                  } else {
                    console.log(
                      "Invalid input: Only numbers up to 10 digits are allowed."
                    );
                  }
                }}
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                  transition: "border-color 0.3s ease",
                  marginLeft: "10px",
                  height: "40px",
                  alignContent: "center",
                  width: "200px",
                  justifyContent: "center",
                  alignSelf: "center",
                  paddingLeft: "10px",
                  fontSize: "14px",
                }}
              />
            </div>
            <button className="close-modal-button" onClick={onClose}>
              <FaTimes />
            </button>
          </div>
          <div className="form-container">
            <div className="form-wrapper">
              <h3 className="modal-title">Details of the Receiver</h3>
              <form>
                {[
                  "name",
                  "addressLine1",
                  "addressLine2",
                  "city",
                  "state",
                  "pin",
                  "gstNo",
                  "panNo",
                ].map((field) => (
                  <div className="form-group" key={field}>
                    <label className="form-label">{field}</label>
                    <input
                      type="text"
                      name={field}
                      value={receiverData[field]}
                      onChange={handleReceiverChange}
                      className="form-input"
                    />
                  </div>
                ))}
              </form>
            </div>
            <div className="form-wrapper">
              <h3 className="modal-title">Details of the Consignee</h3>
              <form>
                {[
                  "name",
                  "addressLine1",
                  "addressLine2",
                  "city",
                  "state",
                  "pin",
                  "gstNo",
                  "panNo",
                ].map((field) => (
                  <div className="form-group" key={field}>
                    <label className="form-label">{field}</label>
                    <input
                      type="text"
                      name={field}
                      value={consigneeData[field]}
                      onChange={handleConsigneeChange}
                      className="form-input"
                    />
                  </div>
                ))}
              </form>
              <div className="toggle-container">
                <input
                  type="checkbox"
                  checked={sameAsReceiver}
                  onChange={() => setSameAsReceiver(!sameAsReceiver)}
                />
                <label className="toggle-label">Same as Receiver</label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={onClose}>
              Close
            </button>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Save changes
            </button>
          </div>
        </div>
        
      </div>

    </div>

  );
};

export default ClientDetailsModal;
