/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */

import { useState } from "react";
import "./index.scss";
import { Label } from "../../components";
import { useHistory } from "react-router-dom";

import backgroundvideo from "../../backgroundvideo.mp4";

import Email_User from "./Email_user";
import Otp_User from "./Otp_User";
import SignUp_user from "./SignUp_user";

export default ({ onAuthChanged }) => {

  const [formStage, setFormStage] = useState(0);

  const [formData, setFormData] = useState({
    email: "",
    otpSessionID: "",
    user: null,
  });

  const history = useHistory();


  const renderStep = () => {
    switch (formStage) {
      case 0:
        return (
          <Email_User setFormStage={setFormStage} setFormData={setFormData} />
        );
      case 1:
        return (
          <Otp_User
            formStage={formStage}
            setFormStage={setFormStage}
            formData={formData}
            onAuthChanged={onAuthChanged}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <SignUp_user
            formData={formData}
            onAuthChanged={onAuthChanged}
          />
        );
      default:
        return null;
    }
  };


  return (
    <div id="userNameForm">
      <video id="background-video" loop={true} autoPlay="autoplay" muted>
        <source src={backgroundvideo} type="video/mp4" />
      </video>
      <div className="overlay-video" />
      <p className="mainHeading">
        STAART
      </p>
      <div className="formFeilds">
        <div className="formFeild_leftside">
          {formStage === 0 && (
            <Label
              content="Enter email to continue"
              size="25px"
              color="white"
              fontWeight="500"
              textAlign="center"
            />
          )}

          {renderStep()}

      
        </div>

        <div className="formFeild_Rightside">
          <div style={{ padding: "50px 0px" }}>
            <div style={{ paddingLeft: "30px" }}>
              <div>
                <p
                  style={{
                    fontSize: "25px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Features
                </p>
              </div>
              <ul style={{ fontSize: "16px", margin: "25px 0px" }}>
                <li style={{ margin: "25px 0px", fontWeight: "400" }}>
                  Check live profitability .
                </li>
                <li style={{ margin: "25px 0px", fontWeight: "400" }}>
                  Release & Reconcile purchase orders.
                </li>
                <li style={{ margin: "25px 0px", fontWeight: "400" }}>
                  Manage Finance.
                </li>
                <li style={{ margin: "25px 0px", fontWeight: "400" }}>
                  Get progress and final billing from site.
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};
