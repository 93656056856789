import React, { useState } from "react";
import { FaTimes, FaArrowLeft, FaArrowRight, FaTrashAlt } from "react-icons/fa";
import ImageWithLoader from "./ImageWithLoader";

const ImageModal = ({ images, selectedImageIndex, onClose, onDelete }) => {
  const [currentIndex, setCurrentIndex] = useState(selectedImageIndex);

  // Navigate to the previous image
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Navigate to the next image
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Delete current image
  const handleDeleteCurrentImage = () => {
    onDelete(images[currentIndex].id);
    if (images.length === 1) {
      onClose();
    } else {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex
      );
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "white",
        }}
      >
        <FaTimes size={30} />
      </button>
      <button
        onClick={handlePrevious}
        style={{
          position: "absolute",
          left: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "white",
        }}
      >
        <FaArrowLeft size={30} />
      </button>
      <div style={{ position: "relative", textAlign: "center" }}>
        <ImageWithLoader
          src={images[currentIndex].id || images[currentIndex].url || images[currentIndex].uri}
          alt="Images"
        />
        <div
          style={{
            color: "white",
            marginTop: "10px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {`${currentIndex + 1}/${images.length}`}
        </div>
        {onDelete && (
          <FaTrashAlt
            size={30}
            color="red"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={handleDeleteCurrentImage}
          />
        )}
      </div>
      <button
        onClick={handleNext}
        style={{
          position: "absolute",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "white",
        }}
      >
        <FaArrowRight size={30} />
      </button>
    </div>
  );
};

export default ImageModal;
