import React, { useEffect, useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai"; // Icon from React Icons
import ImageModal from "../Components/ImageModal";

const TopInfoBar = ({ fields, inTime, outTime, itemData }) => {
  // States to handle the ImageModal
  const [displayImage, setDisplayImage] = useState(false);
  const [imageItems, setImageItems] = useState([]);

  // Function to open the ImageModal and set images

  const invoiceImages =
    itemData?.invoices && Object.keys(itemData.invoices).length > 0
      ? itemData.invoices[Object.keys(itemData.invoices)[0]]?.images
      : null;
  const handleViewBills = () => {
    let dummyImages = itemData.images ? Object.values(itemData.images) : []; // Replace with actual data or API response\

    if (
      Object.keys(
        itemData.invoices[Object.keys(itemData.invoices)[0]]
      ).includes("images")
    ) {
      dummyImages = [
        ...dummyImages,
        ...Object.values(
          itemData.invoices[[Object.keys(itemData.invoices)[0]]].images
        ),
      ];
    }
    if(dummyImages.length > 0){
      setImageItems(dummyImages);
      setDisplayImage(true);
    }else{
      alert('No bills are uploaded')
    }
  };



  return (
    <div style={styles.container}>
      <div style={styles.field}>
        <strong>Invoice No:</strong> {fields["04"]["01"].value}
      </div>
      <div style={styles.field}>
        <strong>Invoice Date:</strong> {fields["04"]["02"].value}
      </div>
      <div style={styles.field}>
        <strong>MRN No:</strong> {fields["02"].value}
      </div>
      <div style={styles.field}>
        <strong>Supplier:</strong> {fields["07"].value}
      </div>
      <div style={styles.field}>
        <strong>in Time - </strong> {inTime}
      </div>
      <div style={styles.field}>
        <strong>Gate Pass No:</strong> {fields["05"]["01"].value}
      </div>
      <div style={styles.field}>
        <strong>DC Number:</strong> {fields["06"]["01"].value}
      </div>
      <div style={styles.field}>
        <strong>Vehicle No:</strong> {fields["03"].value}
      </div>
      <div style={styles.field}>
        <strong>PO Number:</strong> {fields["01"].value}
      </div>
      <div style={styles.field}>
        <strong>out Time -</strong> {outTime}
      </div>

      {/* 
      {console.log(
        "itemData.invoices[[Object.keys(itemData.invoices)[0]]].images : ",
        itemData.invoices[[Object.keys(itemData.invoices)[0]]].images
      )} */}


      {/* Vector Icon to open ImageModal */}
      {itemData && (itemData.images || invoiceImages) ? (
        <AiOutlineFileSearch
          style={styles.icon}
          title="View Bills"
          onClick={handleViewBills}
        />
      ) : null}

      {/* <AiOutlineFileSearch
        style={styles.icon}
        title="View Bills"
        onClick={handleViewBills}
      /> */}

      {/* ImageModal Integration */}
      {displayImage && (
        <ImageModal
          images={imageItems} // List of images
          selectedImageIndex={0} // Index of the initial image
          onClose={() => {
            setDisplayImage(false); // Close the modal
            setImageItems([]); // Clear images
          }}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "10px",
    backgroundColor: "#fdf8e8",
    padding: "10px",
    borderRadius: "8px",
    marginBottom: "20px",
    position: "relative",
  },
  field: {
    fontSize: "14px",
    color: "#333",
    padding: "5px 0",
  },
  icon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "24px",
    color: "#333",
    cursor: "pointer",
  },
};

export default TopInfoBar;
