/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Button1, TextArea, Input } from "../../components";
import TextField from "@material-ui/core/TextField";
import { FormHelperText } from "@material-ui/core";
import firebase from "../../config/firebase";

export default ({ onClose, onSave, project }) => {
  const [projectFirm, setProjectFirm] = useState({
    projectName: "",
    projectAccessNumber: "",
    projectAddress: "",
    seCode: "",
    additionalSEIds: [],
  });

  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (project) {
      console.log("project", project);
      console.log("project.ClientNumber", project.ClientNumber);

      setProjectFirm((st) => ({
        ...st,
        projectName: project.clientName || project.ClientName,
        projectAccessNumber: project.clientNumber || project.ClientNumber,
        projectAddress: project.address || project.Address,
        seCode: project.siteEngineersId || project.SiteEngineersId,
        additionalSEIds:
          project.additionalSEIds && JSON.parse(project.additionalSEIds),
      }));
    }
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(2px)",
        backgroundColor: "rgba(0,0,0,0.1)",
        left: 0,
        top: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClose && onClose}>
      <div
        style={{
          width: 300,
          backgroundColor: "white",
          boxShadow: "1px 1px 3px grey",
          borderRadius: 4,
          display: "flex",
          flexDirection: "column",
          gap: 20,
          padding: 20,
        }}
        onClick={(e) => e.stopPropagation()}>
        <div style={{ textAlign: "center" }}>Project Details</div>
        <Input
          placeholder="Project name *"
          value={projectFirm.projectName}
          onChange={(value) => {
            setProjectFirm((st) => ({ ...st, projectName: value }));
          }}
        />
        <TextField
          label="Client number *"
          helperText="Enter client phone number."
          type="text"
          color="black"
          variant="standard"
          value={projectFirm.projectAccessNumber}
          onChange={(e) => {
            const inputValue = e.currentTarget.value.trim(); // Trim whitespace from input
            console.log("Input Value: ", inputValue);
            const regex = /^[0-9]{0,10}$/; // Regex to match 1 to 10 digits

            if (regex.test(inputValue)) {
              setProjectFirm((st) => ({
                ...st,
                projectAccessNumber: inputValue,
              }));
            } else {
              console.log(
                "Invalid input: Only numbers up to 10 digits are allowed."
              );
            }
          }}
        />

        <TextArea
          placeholder="Project address"
          value={projectFirm.projectAddress}
          onChange={(value) => {
            setProjectFirm((st) => ({ ...st, projectAddress: value }));
          }}
        />
        <Input
          placeholder="Site engineer code"
          value={projectFirm.seCode}
          onChange={(value) => {
            setProjectFirm((st) => ({
              ...st,
              seCode: value.trim() === " " ? " " : value,
            }));
          }}
        />

        <TextField
          label="Add more SE"
          helperText="Enter Comma(,) seperated values"
          type="text"
          color="black"
          variant="standard"
          value={projectFirm.additionalSEIds?.join(",")}
          onChange={(e) => {
            const inputValue = e.currentTarget.value;
            const regex = /^[0-9,]*$/;

            if (regex.test(inputValue) || inputValue === "") {
              const extractedNumbers = inputValue.split(",");
              setProjectFirm((st) => ({
                ...st,
                additionalSEIds: extractedNumbers,
              }));
            }
          }}
        />
        <Button1
          content={isSaved ? "SAVING..." : "SAVE"}
          onClick={(event) => {
            if (!isSaved) {
              if (onSave) {
                event.stopPropagation();
                onSave(projectFirm);
                setIsSaved(true);
              }
            }
          }}
        />
      </div>
    </div>
  );
};
