import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import getWorksMaterials from "../../helpers/getWorksMaterials";
import config from "../../config/config";
import { toast } from "react-toast";
import RequestAmountModal from "./ClientComponents/RequestAmountModal";
import IssuesModal from "./ClientComponents/IssuesModal";
import { PopOverView, DropDownMUI } from "../../components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import {
//   FiChevronDown,
//   FiChevronUp,
//   FiInfo,
//   FiMoreVertical,
//   FiTruck,
// } from "react-icons/fi";
// import { FaCheck } from "react-icons/fa";
// import { GrUserWorker } from "react-icons/gr";
import { AiOutlineCheckCircle, AiOutlineSearch } from "react-icons/ai";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import ViewDetails from "./viewDetails";
import { useAnalyticsContext } from "../../Context/Analytics";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import OrderItem from "./OrderItem";
import PaymentRequest from "./ClientComponents/PaymentRequest";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BiUpArrowAlt } from "react-icons/bi";
import QueueIcon from "@mui/icons-material/Queue";
import firebase from "../../config/firebase";
import ChatComponent from "./ClientComponents/ChatComponent";

import MilestoneModal from "./ClientComponents/MilestoneModal";
import DisplaySiteImages from "./ClientComponents/DisplaySiteImages";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import ClientDetailsModal from "./ClientComponents/ClientDetailsModal";
import { deviceType } from "react-device-detect";

export default function Client() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const location = useLocation();
  const { record } = useAnalyticsContext();

  const data = location.state.selectedProject;
  const [clientExist, setClientExist] = useState(false);
  const [backToTop, setBackTotop] = useState(false);
  const [revision, setRevision] = useState(data.rooms);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All");
  const [materials, setMaterials] = useState([]);
  const [works, setWorks] = useState([]);
  // const [showStatus, setShowStatus] = useState([]);
  const [searchItems, setSearchItems] = useState("");
  const [workType, setWorkType] = useState("All");
  const [status, setStatus] = useState("All");
  const [isSpinner, setSpinner] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("invoices");
  const [invoices, setInvoices] = useState([]);
  const [activeChat, setActiveChat] = useState("");
  const [chatThreads, setChatThreads] = useState([]);
  const [issues, setIssues] = useState([]);
  const [dummyIssues, setDummyIssues] = useState([
    {
      chats: {
        1721463177353: {
          sender: "client",
          text: "This is a dummy issue",
          timestamp: 1721463177353,
        },
      },
      page: 1,
      status: "In progress",
      text: "This is a dummy issue",
      timestamp: 1721463177353,
      unseenComment: {
        client: 0,
        firm: 0,
      },
    },
  ]);
  // const [displayImage, setDisplayImage] = useState(false);

  const getChatThreads = async () => {
    var useRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/projectChatThreads/`);
    useRef.on("value", function (snapshot) {
      var values = snapshot.val();
      if (values !== null) {
        setChatThreads(values);
      }
    });
  };

  // also used in the finance.js to fetch the invoices
  const getInvoicesDetails = async () => {
    var useRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/invoiceDetails/`);
    // var useRef = firebase.database().ref(`clientDetails/${data.ProjectId}/invoiceDetails/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setInvoices(Object.values(data).reverse());
      }
    });
  };

  const getIssuesDetails = async () => {
    var useRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/agreement/issues/`);
    // var useRef = firebase.database().ref(`clientDetails/${data.ProjectId}/invoiceDetails/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setIssues(Object.values(data).reverse());
      }
    });
  };

  const getProgressDetails = async () => {
    console.log("here we are going to get the progress cetails here");
    var useRef = firebase
      .database()
      .ref(`progress/${data.ProjectId}/workProgress`);
    // var useRef = firebase.database().ref(`clientDetails/${data.ProjectId}/invoiceDetails/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        calculateProgressDetails(data);
      }
    });
  };

  const calculateProgressDetails = async (progress) => {
    console.log("data : ", data);
    let array = data.rooms;
    let tempProgress = [];
    console.log("boq : ", array);
    console.log("progress : ", progress);
    let totalCost = 0;
    let totalCompletedCost = 0;

    for (let hIndex = 0; hIndex < array.length; hIndex++) {
      let totalCostOfItems = 0;
      let totalCompletedCostofItems = 0;

      for (let sIndex = 0; sIndex < array[hIndex]["Units"].length; sIndex++) {
        for (
          let cIndex = 0;
          cIndex < array[hIndex]["Units"][sIndex]["Components"].length;
          cIndex++
        ) {
          if (
            array[hIndex]["Units"][sIndex]["Components"][cIndex].hasOwnProperty(
              "Work"
            )
          ) {
            for (
              let wIndex = 0;
              wIndex <
              array[hIndex]["Units"][sIndex]["Components"][cIndex]["Work"]
                .length;
              wIndex++
            ) {
              let work =
                array[hIndex]["Units"][sIndex]["Components"][cIndex]["Work"][
                  wIndex
                ];

              let costOfItem = Number(work["quantity"]) * Number(work["rate"]);
              totalCostOfItems += costOfItem;

              // Safely access progress data
              let progressValue =
                progress?.[hIndex]?.[sIndex]?.[cIndex]?.[wIndex]?.progressDone;

              if (progressValue !== undefined) {
                totalCompletedCostofItems += costOfItem * Number(progressValue);
              } else {
                console.log("Progress data not available for this item");
              }
            }
          }
        }
      }

      let percentage =
        totalCostOfItems > 0
          ? (totalCompletedCostofItems / totalCostOfItems).toFixed(2)
          : "0.00";
      tempProgress.push({
        name: array[hIndex]["Room Name"],
        percentage: percentage,
      });
      totalCost += totalCostOfItems;
      totalCompletedCost += totalCompletedCostofItems;
    }

    let overallPercentage =
      totalCost > 0 ? (totalCompletedCost / totalCost).toFixed(2) : "0.00";
    tempProgress.unshift({
      name: "Overall",
      percentage: overallPercentage,
    });

    setOverAllProgress(tempProgress);
  };

  useEffect(() => {
    getInvoicesDetails();
    getChatThreads();
    getProgressDetails();
    getIssuesDetails();
  }, []);

  const fixedDivRef = useRef(null);
  const [marginTop, setMarginTop] = useState(0);

  useEffect(() => {
    const updateMarginTop = () => {
      if (fixedDivRef.current) {
        setMarginTop(fixedDivRef.current.offsetHeight);
      }
    };

    // Set initial margin-top
    updateMarginTop();

    // Update margin-top on window resize
    window.addEventListener("resize", updateMarginTop);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateMarginTop);
  }, []);

  const backToTopHandler = () => {
    // document?.getElementById("btn")?.addEventListener("click", function () {
    document
      ?.getElementById("container")
      ?.scroll({ top: 0, behavior: "smooth" });
    // });
  };

  const [releasedOrders, setReleasedOrders] = useState([]);

  useEffect(() => {
    fetchReleasedOrders();
    // setSpinner(false);
  }, []);

  const fetchReleasedOrders = async () => {
    setSpinner(true);
    fetch(`${config.orderService}getServiceOrders/${data.ProjectId}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setAllOrders(json.servicesPayload);

        let completedCategories = [];

        json.servicesPayload.map((item, index) =>
          completedCategories.push(item.Category)
        );

        setCategories([...new Set(completedCategories)]);
        setReleasedOrders(json.servicesPayload);

        setSpinner(false);
      })
      .catch((e) => {
        console.log("getServiceOrders catch: ", e.toString());
        toast.error("Error fetching orders data!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });
  };

  const [overAllProgress, setOverAllProgress] = useState([]);

  const chatOptions = [
    {
      id: 3,
      name: "Group",
    },

    {
      id: 1,
      name: "Site Engineer",
    },

    {
      id: 2,
      name: "Client",
    },
  ];

  const [milestoneModal, setMilestoneModal] = useState(false);

  const handleDefineMilestonesOpen = async () => {
    // setDisplayImage(!displayImage)
    setMilestoneModal(true);
  };

  const handleDefineMilestonesClose = async () => {
    setMilestoneModal(false);
  };

  const [clientDetail, setClientDetail] = useState(false);

  const handleClientDetail = () => {
    setClientDetail(!clientDetail);
  };

  const submitClientDetails = async (receiver, consignee) => {
    console.log("this is thhe details we have to write here and there...");
    firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/receiverDetails`)
      .set(receiver);
    firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/consigneeDetails`)
      .set(consignee);
  };

  const [chatThreadInfo, setChatThreadInfo] = useState({
    groupCount: 0,
    seCount: 0,
    clientCount: 0,
  });
  const [chatCountInfo, setChatCountInfo] = useState({});

  const fetchProjectThreadsDetails = async () => {
    var useRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/projectChatThreadsDetails/`);
    // var useRef = firebase.database().ref(`clientDetails/${data.ProjectId}/invoiceDetails/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setChatThreadInfo({
          groupCount:
            data.OverAllMessages.Firm !== undefined
              ? data.OverAllMessages.Firm
              : 0,
          seCount:
            data.FirmSiteEngineerMessages.Firm !== undefined
              ? data.FirmSiteEngineerMessages.Firm
              : 0,
          clientCount:
            data.ClientFirmMessages.Firm !== undefined
              ? data.ClientFirmMessages.Firm
              : 0,
        });
        setChatCountInfo(data);
      }
    });
  };

  const changeUnseenMessageFoAudience = async () => {
    let count = 0;
    if (activeChat === "Group") {
      count = chatCountInfo.OverAllMessages.Client + 1;
      if (count === undefined) {
        count = 0;
      }
      firebase
        .database()
        .ref(
          `clientDetails/${data.ProjectId}/projectChatThreadsDetails/OverAllMessages/Client`
        )
        .set(count);

      let count2 = chatCountInfo.OverAllMessages.SE + 1;

      firebase
        .database()
        .ref(
          `clientDetails/${data.ProjectId}/projectChatThreadsDetails/OverAllMessages/SE`
        )
        .set(count2);
    } else if (activeChat === "Site Engineer") {
      count = chatCountInfo.FirmSiteEngineerMessages.SE + 1;
      if (count === undefined) {
        count = 0;
      }
      firebase
        .database()
        .ref(
          `clientDetails/${data.ProjectId}/projectChatThreadsDetails/FirmSiteEngineerMessages/SE`
        )
        .set(count);
    } else if (activeChat === "Client") {
      count = chatCountInfo.ClientFirmMessages.Client + 1;
      if (count === undefined) {
        count = 0;
      }
      firebase
        .database()
        .ref(
          `clientDetails/${data.ProjectId}/projectChatThreadsDetails/ClientFirmMessages/Client`
        )
        .set(count);
    } else {
      console.log("it is never gonna reach here...");
    }
  };

  const handleChatOptionPress = async (name) => {
    setActiveChat(name);
    if (name === "Group") {
      firebase
        .database()
        .ref(
          `clientDetails/${data.ProjectId}/projectChatThreadsDetails/OverAllMessages/Firm`
        )
        .set(0);
    } else if (name === "Site Engineer") {
      firebase
        .database()
        .ref(
          `clientDetails/${data.ProjectId}/projectChatThreadsDetails/FirmSiteEngineerMessages/Firm`
        )
        .set(0);
    } else if (name === "Client") {
      firebase
        .database()
        .ref(
          `clientDetails/${data.ProjectId}/projectChatThreadsDetails/ClientFirmMessages/Firm`
        )
        .set(0);
    } else {
      console.log("never gonna happen...");
    }
  };

  useEffect(() => {
    fetchProjectThreadsDetails();
  }, []);

  // here we are going to fetch the fcmToken of the client

  const [fcmClient, setFCMClient] = useState("");
  const [device, setDevice] = useState("");

  const fetchFCM = async () => {
    var useRef = firebase
      .database()
      .ref(`clientUserDetails/${data.clientNumber}/tokens/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setDevice(data.deviceType);
        setFCMClient(data.fcmToken);
      }
    });
  };

  useEffect(() => {
    // write the code here
    fetchFCM();
  }, []);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
      <div
        style={{
          border: "1px solid #E1E1E1",
          width: "15%",
          height: "86%",
          fontSize: "13px",
          position: "fixed",
          overflow: "scroll",
        }}>
        <div
          style={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            overflow: "hidden",
            maxWidth: "400px",
            // margin: "20px auto",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 20px",
              backgroundColor: "#fdd34d",
              borderBottom: "2px solid #f0c330",
            }}>
            <h2
              style={{
                margin: 0,
                fontSize: "1.2rem",
                color: "#333",
              }}>
              Chat
            </h2>
            <div
              style={{
                cursor: "pointer",
                backgroundColor: "#fff",
                borderRadius: "50%",
                padding: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
              onClick={handleClientDetail}>
              <PersonAddIcon style={{ fontSize: "1.2rem", color: "#333" }} />
            </div>
          </div>

          {chatOptions.map((item, index) => (
            <div
              key={index}
              style={{
                padding: "15px 20px",
                cursor: "pointer",
                backgroundColor: activeChat === item.name ? "#FFF8E1" : "white",
                borderBottom:
                  index !== chatOptions.length - 1
                    ? "1px solid #E1E1E1"
                    : "none",
                transition: "background-color 0.3s ease",
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() => handleChatOptionPress(item.name)}>
              <p
                style={{
                  margin: 0,
                  color: "#333",
                  fontWeight: activeChat === item.name ? "bold" : "normal",
                }}>
                {item.name}
              </p>
              <p
                style={{
                  position: "absolute",
                  right: "10px",
                  // top: '10px',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px",
                  height: "24px",
                  backgroundColor: "#f55454",
                  borderRadius: "50%",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "12px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  transform: "translateY(-50%)",
                  opacity:
                    index === 0 && chatThreadInfo.groupCount > 0
                      ? 1
                      : index === 1 && chatThreadInfo.seCount > 0
                      ? 1
                      : index === 2 && chatThreadInfo.clientCount > 0
                      ? 1
                      : 0,
                }}>
                {index === 0 && chatThreadInfo.groupCount > 0
                  ? chatThreadInfo.groupCount
                  : index === 1 && chatThreadInfo.seCount > 0
                  ? chatThreadInfo.seCount
                  : index === 2 && chatThreadInfo.clientCount > 0
                  ? chatThreadInfo.clientCount
                  : null}
              </p>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            marginTop: 10,
          }}>
          <h2 style={{ fontWeight: "bold", marginTop: 10 }}>Work Progress</h2>
        </div>

        {overAllProgress.map((category, index) => (
          <div
            key={index}
            style={
              category == activeCategory
                ? {
                    backgroundColor: "#FFF8E1",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 10,
                    cursor: "pointer",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 10,
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              setActiveCategory(category);
            }}>
            <p>{category.name}</p>
            <p>
              {String(category.percentage)}
              {" %"}
            </p>
          </div>
        ))}
      </div>

      <MilestoneModal
        isOpen={milestoneModal}
        setOpen={setMilestoneModal}
        onClose={handleDefineMilestonesClose}
        projectValue={Number(getComponentsPrice(data).toFixed(0))}
        ProjectId={data.ProjectId}
      />

      <ClientDetailsModal
        isOpen={clientDetail}
        onClose={handleClientDetail}
        onSubmit={submitClientDetails}
        data={data}
      />

      {
        // div below encloses entire thing for client thing
        activeChat !== "" ? (
          <div
            style={{
              width: "80%",
              height: "90%",
              padding: "0px 10px 10px 10px",
              marginLeft: "15%",
              position: "fixed",
              overflow: "scroll",
            }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  backgroundColor: "#fdd34d",
                  borderRadius: 5,
                  padding: 10,
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  alignItems: "center",
                  position: "fixed",
                  width: "80%",
                  zIndex: 1000000,
                }}>
                <ArrowBackIosIcon
                  style={{
                    color: "#757575",
                    transition: "color 0.3s ease",
                  }}
                  cursor="pointer"
                  onClick={() => setActiveChat("")}
                />
                <AccountCircleIcon
                  style={{
                    height: 40,
                    width: 40,
                    color: "#757575",
                    transition: "color 0.3s ease",
                  }}
                />
                <p
                  style={{
                    color: "#757575",
                    fontSize: 20,
                    fontWeight: 600,
                    margin: 0,
                  }}>
                  {activeChat}
                </p>
              </div>
            </div>

            <ChatComponent
              activeChat={activeChat}
              chatsAreActive={true}
              item={
                activeChat == "Client"
                  ? chatThreads?.ClinetFirmsMessages
                  : activeChat == "Site Engineer"
                  ? chatThreads?.FirmSiteEngineer
                  : chatThreads?.ClinetOverallMessages
              }
              invoiceId={data.ProjectId}
              activeThread={
                activeChat == "Client"
                  ? "ClinetFirmsMessages"
                  : activeChat == "Site Engineer"
                  ? "FirmSiteEngineer"
                  : "ClinetOverallMessages"
              }
              changeUnseenMessageFoAudience={changeUnseenMessageFoAudience}
              fcmToken={fcmClient}
              device={device}
            />
          </div>
        ) : (
          <div
            style={{
              width: "80%",
              height: "90%",
              padding: "0px 10px 10px 10px",
              marginLeft: "15%",
              position: "fixed",
              overflow: "scroll",
            }}>
            <div
              ref={fixedDivRef}
              style={{
                position: "fixed",
                width: "80%",
                backgroundColor: "white",
                zIndex: 1000,
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  justifyContent: "space-between",
                  // position: 'fixed'
                }}>
                {/* <div style={{ marginBottom: 10 }}>
                  <DropDownMUI
                    placeholder={"Work Type"}
                    data={[
                      "All",
                      "Only Work",
                      "Work + Material",
                      "Only Material",
                    ]}
                    value={workType}
                    onChange={(value) => {
                      setWorkType(value);
                    }}
                  />
                </div> */}
                <div style={{ marginBottom: 10 }}>
                  <DropDownMUI
                    placeholder={"Status"}
                    data={["All", "Requested", "Approved"]}
                    value={status}
                    onChange={(value) => {
                      setStatus(value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    border: "1px solid #3535354d",
                    width: "100%",
                    marginBottom: 10,
                    borderRadius: 5,
                  }}>
                  <AiOutlineSearch
                    color="grey"
                    size="20"
                    style={{ margin: "0px 10px" }}
                  />
                  <input
                    placeholder="Search by  name"
                    style={{
                      width: "100%",
                      fontSize: "16px",
                      borderWidth: 0,
                      border: "none",
                      outline: "none",
                    }}
                    value={searchItems}
                    onChange={(e) => {
                      setSearchItems(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: 50,
                  width: "100%",
                  backgroundColor: "white",
                  justifyContent: "space-between",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "0 10px",
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "75%",
                    marginRight: 5,
                  }}>
                  {overAllProgress.length > 0 && (
                    <div
                      style={{
                        height: 50,
                        width:
                          overAllProgress.length > 0
                            ? String(overAllProgress[0].percentage) + "%"
                            : "0%",
                        backgroundColor: "#fdd34d",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        borderTop: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        borderBottom: "1px solid #e0e0e0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <p
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "600",
                          fontStyle: "italic",
                          color: "#333",
                          margin: 0,
                        }}>
                        Completed{" "}
                        {overAllProgress.length > 0
                          ? String(overAllProgress[0].percentage)
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  )}
                  <div
                    style={{
                      height: 50,
                      width:
                        overAllProgress.length > 0
                          ? String(100 - overAllProgress[0].percentage) + "%"
                          : "100%",
                      borderTop: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      borderBottom: "1px solid #e0e0e0",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                    }}>
                    <p
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "600",
                        fontStyle: "italic",
                        color: "#666",
                        margin: 0,
                      }}>
                      Remaining{" "}
                      {overAllProgress.length > 0
                        ? String(100 - overAllProgress[0].percentage)
                        : 100}{" "}
                      %
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    width: "10%",
                    marginRight: 5,
                  }}
                  onClick={() => setActiveTab("invoices")}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 50,
                      backgroundColor: "#579BB1",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      transition: "all 0.3s ease",
                    }}>
                    <p
                      style={{
                        color: "white",
                        margin: 0,
                        fontWeight: "600",
                        fontSize: activeTab === "invoices" ? 26 : 18,
                      }}>
                      Invoices
                    </p>
                  </div>
                  <div
                    style={{
                      height: activeTab === "invoices" ? 8 : 3,
                      width: "100%",
                      backgroundColor: "#579BB1",
                      marginTop: 5,
                      opacity: activeTab === "invoices" ? 1 : 0,
                      transition: "opacity 0.3s ease",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    width: "10%",
                    marginRight: 5,
                  }}
                  onClick={() => setActiveTab("progress")}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 50,
                      backgroundColor: "#FFE8C8",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      transition: "all 0.3s ease",
                    }}>
                    <p
                      style={{
                        color: "#333",
                        margin: 0,
                        fontWeight: "600",
                        fontSize: activeTab === "progress" ? 26 : 18,
                      }}>
                      Progress
                    </p>
                  </div>

                  <div
                    style={{
                      height: activeTab === "progress" ? 8 : 3,
                      width: "100%",
                      backgroundColor: "#FFE8C8",
                      marginTop: 5,
                      opacity: activeTab === "progress" ? 1 : 0,
                      transition: "opacity 0.3s ease",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    width: "10%",
                  }}
                  onClick={() => setActiveTab("issues")}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 50,
                      backgroundColor: "#FFAAAA",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      transition: "all 0.3s ease",
                    }}>
                    <p
                      style={{
                        color: "#000000",
                        margin: 0,
                        fontWeight: "600",
                        fontSize: activeTab === "issues" ? 26 : 18,
                      }}>
                      Issues
                    </p>
                  </div>
                  <div
                    style={{
                      height: activeTab === "issues" ? 8 : 3,
                      width: "100%",
                      backgroundColor: "#FFAAAA",
                      marginTop: 5,
                      opacity: activeTab === "issues" ? 1 : 0,
                      transition: "opacity 0.3s ease",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 50,
                    width: "3%",
                    cursor: "pointer",
                  }}
                  onClick={handleDefineMilestonesOpen}>
                  <QueueIcon
                    style={{
                      height: 40,
                      width: 40,
                      color: "#667BC6",
                      transition: "color 0.3s ease",
                    }}
                  />
                </div>
              </div>
            </div>

            <RequestAmountModal
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              projectName={data.ClientName}
              projectId={data.ProjectId}
              fromClient={true}
            />

            {/* <DisplaySiteImages
              isOpen={displayImage}
              setIsOpen={handleDefineMilestonesOpen}
            /> */}

            <div style={{ marginTop: marginTop + 20 }}>
              {isSpinner ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <CircularProgress style={{ color: "#fdd34d" }} />
                </div>
              ) : activeTab === "progress" ? (
                releasedOrders && releasedOrders.length > 0 ? (
                  releasedOrders.map((item, index) => {
                    return (
                      (activeCategory == item?.Category ||
                        activeCategory == "All") &&
                      (searchItems.length > 0
                        ? item?.VendorName?.toLowerCase().includes(
                            searchItems.toLowerCase()
                          )
                        : item?.VendorName == null
                        ? true
                        : item?.VendorName?.toLowerCase().includes(
                            searchItems.toLowerCase()
                          )) &&
                      ((JSON.parse(item.Data)?.data[0]?.workType == undefined
                        ? workType == "Only Material"
                        : workType ==
                          JSON.parse(item.Data)?.data[0]?.workType) ||
                        workType == "All") &&
                      (item?.Status.toLowerCase().includes(
                        status.toLowerCase()
                      ) ||
                        status == "All") && (
                        <OrderItem
                          key={index}
                          releasedOrderIndex={index}
                          item={item}
                          isCompletedOrder={true}
                          allOrders={allOrders}
                          setReleasedOrders={setReleasedOrders}
                          client={true}
                        />
                      )
                    );
                  })
                ) : (
                  <div
                    style={{
                      position: "relative",
                      top: 200,
                      left: 600,
                      height: 100,
                      width: 400,
                      backgroundColor: "#e6e4e4",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 10,
                    }}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#949191",
                      }}>
                      No ordres to display
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#949191",
                      }}>
                      {"(Release PO/WO to raise One)"}
                    </p>
                  </div>
                )
              ) : activeTab === "invoices" ? (
                invoices && invoices.length > 0 ? (
                  invoices.map((item, index) => {
                    if (item.remark.toLowerCase().includes(searchItems)) {
                      if (status === "All") {
                        return (
                          <PaymentRequest
                            item={item}
                            key={index}
                            projectId={data.ProjectId}
                          />
                        );
                      } else {
                        if (
                          item.status.toLowerCase() === status.toLowerCase()
                        ) {
                          return (
                            <PaymentRequest
                              item={item}
                              key={index}
                              projectId={data.ProjectId}
                            />
                          );
                        }
                      }
                    }
                  })
                ) : (
                  <div
                    style={{
                      position: "relative",
                      top: 200,
                      left: 600,
                      height: 100,
                      width: 400,
                      backgroundColor: "#e6e4e4",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 10,
                    }}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#949191",
                      }}>
                      No invoices to display
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#949191",
                      }}>
                      {"(Click on + icon to raise One)"}
                    </p>
                  </div>
                )
              ) : activeTab === "issues" ? (
                issues && issues.length > 0 ? (
                  issues.map((item, index) => {
                    return (
                      <IssuesModal
                        item={item}
                        key={index}
                        projectId={data.ProjectId}
                        dummyIssues={false}
                      />
                    );
                  })
                ) : (
                  dummyIssues.map((item, index) => {
                    return (
                      <IssuesModal
                        item={item}
                        key={index}
                        projectId={data.ProjectId}
                        dummyIssues={true}
                      />
                    );
                  })
                )
              ) : null}
            </div>

            {backToTop && (
              <button
                id="btn"
                title="back to top"
                onClick={backToTopHandler}
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#2f4858",
                  marginTop: "5px",
                  position: "fixed",
                  bottom: "4%",
                  left: "95%",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "white",
                }}>
                <BiUpArrowAlt size={30} style={{ marginTop: "2px" }} />
              </button>
            )}
          </div>
        )
      }
    </div>
  );
}
