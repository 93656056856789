/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
export default (data) => {
  const { rooms } = data;
  let maxIndices = {
    roomIndex: rooms.length,
    extraItemsIndex: 0,
    freightChargesIndex: 0,
    componentIndex: 0,
  };
  rooms.forEach((room, roomIndex) => {
    if (room["Room Name"] === "Non tender items") {
      maxIndices.roomIndex = roomIndex;
      maxIndices.extraItemsIndex = 0;
      maxIndices.freightChargesIndex = 0;
    }
    const { Units } = room;
    Units.forEach((unit, unitIndex) => {
      if (unit["Unit Name"] === "Extra items") {
        maxIndices.extraItemsIndex = unitIndex;
        maxIndices.componentIndex = (unit.Components.length - 1) || 0;
      } else if (unit["Unit Name"] === "Freight Charges") {
        maxIndices.freightChargesIndex = unitIndex;
        maxIndices.componentIndex = (unit.Components.length - 1) || 0;
      }
    });
  });
  return maxIndices;
};






