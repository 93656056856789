import React, { useState, useEffect, useRef } from "react";

const Dropdown = ({
  options,
  placeholder,
  value,
  onChange,
  onOptionSelect,
  index,
  validationError,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);

  // Handle input changes and filter the options
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Filter options based on input
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);

    // Show the dropdown when typing
    setIsDropdownVisible(value.length > 0);
  };

  // Handle option selection
  const handleOptionSelect = (option) => {
    setInputValue(option); // Update the input field with the selected option
    onOptionSelect(option, index); // Call the onOptionSelect callback with the selected option
    setIsDropdownVisible(false); // Collapse dropdown on selection
  };

  // Handle click outside to close dropdown
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
    }
  };

  // Add event listener for clicks outside the dropdown
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Clear the input value
  const handleClearInput = () => {
    setInputValue("");
    setFilteredOptions(options); // Reset filtered options
    setIsDropdownVisible(false); // Close the dropdown
    onChange(""); // Call the onChange callback with an empty string
  };

  // Update the input value when the value prop changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Update filteredOptions when options prop changes
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <div
      className="dropdown-container"
      style={styles.dropdownContainer}
      ref={dropdownRef}
    >
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          style={{
            ...styles.input,
            borderColor: validationError ? "red" : "#ddd", // Show red border if there's a validation error
          }}
          onFocus={() => setIsDropdownVisible(true)} // Show dropdown when focused
        />
        {inputValue && (
          <span onClick={handleClearInput} style={styles.clearButton}>
            &#10005; {/* Cross icon */}
          </span>
        )}
      </div>

      {isDropdownVisible && (
        <div className="dropdown" style={styles.dropdown}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                style={styles.option}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))
          ) : (
            <div style={styles.noOption}>No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  dropdownContainer: {
    width: "100%",
    position: "relative",
    fontFamily: "Arial, sans-serif",
    marginBottom: "20px",
  },
  inputContainer: {
    position: "relative",
  },
  input: {
    width: "100%",
    fontSize: "18px",
    padding: "20px",
    borderRadius: "12px",
    border: "1px solid #ddd",
    fontSize: "20px",
    backgroundColor: "#f9f9f9",
    boxSizing: "border-box",
    marginBottom: "10px",
    backgroundColor: "#fff",
  },
  clearButton: {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    fontSize: "18px",
    color: "#999",
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
    zIndex: 1000, // Increase the z-index to ensure the dropdown is on top
    maxHeight: "200px",
    overflowY: "auto",
    boxSizing: "border-box",
    opacity: 1, // Remove the fade-in transition, as it's not necessary
  },
  option: {
    padding: "12px",
    fontSize: "16px",
    cursor: "pointer",
    borderBottom: "1px solid #ddd",
    backgroundColor: "#fff",
    transition: "background-color 0.3s ease",
  },
  noOption: {
    padding: "12px",
    fontSize: "16px",
    color: "#999",
  },
};

export default Dropdown;
