import React, { Fragment, useRef, cloneElement } from "react";

export default ({ onFileSelected, children, accept }) => {
  const fileRef = useRef(null);

  if (Array.isArray(children)) return null;
  return (
    <Fragment>
      <input
        type="file"
        ref={fileRef}
        style={{ display: "none" }}
        accept={accept}
        onChange={onFileSelected}
      />
      {cloneElement(children, {
        onClick: () => fileRef.current.click(),
      })}
    </Fragment>
  );
};
