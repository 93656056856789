// import firebase from "../../config/firebase";

const dealFinanceRequest = async (orderId) => {
    
    // const requestHistoryRef = firebase.database().ref(`poRequests/${orderId}/`);
    // console.log('requestHistoryRef : ', requestHistoryRef)
    // const requestHistorySnapshot = await requestHistoryRef.child(``).once('value');
    // console.log('requestHistorySnapshot : ', requestHistorySnapshot);
    // let requestData = requestHistorySnapshot.val();
    // console.log('dealFinanceRequest requestData : ', requestData);

}

export default dealFinanceRequest;