import React, { useEffect, useState } from "react";
import firebase from "../../config/firebase";
import "./Modal.css"; // Import the CSS file for styling
import { CircularProgress } from "@material-ui/core";
import "./SearchInput.css";

const Modal = ({
  key,
  isOpen,
  dataForModal,
  onClose,
  firmId,
  selectedGraph,
}) => {
  console.log("dataForModal : ", dataForModal);

  // const [poData, setPOData] = useState([]);
  const [poData, setPOData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState("");

  const [filteredAmount, setFilteredAmount] = useState(0);

  const getRemainingPo = (id) => {
    console.log("getRemaining po is called...");
    console.log("getRemaining : ", dataForModal.date);

    let idToSend = [];

    if (selectedGraph === "Daily") {
      // write the code here
      idToSend = [id, id];
    } else {
      // write the code here
      console.log("dataForModal.date : ", dataForModal.date);
      let dateArray = dataForModal.date.split("-");
      idToSend[0] = new Date(
        dateArray[0].split("/")[1] +
          "/" +
          dateArray[0].split("/")[0] +
          "/" +
          dateArray[0].split("/")[2]
      ).getTime();
      idToSend[1] = new Date(
        dateArray[1].split("/")[1] +
          "/" +
          dateArray[1].split("/")[0] +
          "/" +
          dateArray[1].split("/")[2]
      ).getTime();
    }

    console.log("idToSend : ", idToSend);

    firebase
      .database()
      .ref("poInformation/" + firmId + "/poHistory/")
      .orderByKey()
      .startAt(String(idToSend[0]))
      .endAt(String(idToSend[1]))
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          let data = Object.values(snapshot.val());
          let array = [];
          for (let index = 0; index < data.length; index++) {
            array = array.concat(Object.values(data[index]));
          }
          setPOData(array);
          setLoading(false);
        } else {
          console.log("No data found here for the PO history");
          setLoading(false);
        }
      });
  };

  const calculateTotalValue = () => {
    // Filter data based on the query
    console.log("poData.length : ", poData.length);
    const filteredData = poData.filter((item) =>
      item?.projectName?.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
    // Calculate the total value of filtered items
    const totalValue = filteredData.reduce((sum, item) => sum + item.value, 0);
    return totalValue;
  };

  const [totalPO, setTotalPO] = useState(0); // Total POs

  const calculateTotalPo = () => {
    // Filter data based on the query
    console.log("poData.length : ", poData.length);
    const filteredData = poData.filter((item) =>
      item?.projectName?.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
    return filteredData.length;
  };

  useEffect(() => {
    console.log("data got filtered");
    setFilteredAmount(() => calculateTotalValue());
    setTotalPO(() => calculateTotalPo());
  }, [query]);

  // useEffect(() => {
  //   let timeStamp = dataForModal?.date;
  //   if (timeStamp) {
  //     console.log("timeStamp : ", timeStamp.split("/").reverse().join(","));
  //     console.log(
  //       "timeStamp : ",
  //       new Date(timeStamp.split("/").reverse().join(","))
  //     );
  //     console.log(
  //       "timeStamp : ",
  //       new Date(timeStamp.split("/").reverse().join(",")).getTime()
  //     );
  //     setLoading(true);
  //     getRemainingPo(dataForModal.id);
  //   }
  // });

  useEffect(() => {
    if (dataForModal && dataForModal.date && isOpen) {
      let timeStamp = dataForModal.date;
      console.log("timeStamp : ", timeStamp.split("/").reverse().join(","));
      console.log(
        "timeStamp : ",
        new Date(timeStamp.split("/").reverse().join(","))
      );
      console.log(
        "timeStamp : ",
        new Date(timeStamp.split("/").reverse().join(",")).getTime()
      );
      setLoading(true);
      getRemainingPo(dataForModal.id);
    }
  }, [dataForModal, selectedGraph]);

  if (!isOpen) return null;

  const clear = async () => {
    setFilteredAmount(0);
    setQuery("");
  };

  return (
    <div
      className="modal-overlay"
      onClick={async () => {
        await clear();
        onClose();
      }}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {loading ? (
          <div
            style={{
              alignSelf: "center",
              marginTop: "25vh",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}>
            <CircularProgress style={{ color: "#fdd34d" }} />
            <p style={{ color: "#fdd34d", fontSize: "16px" }}>
              fetching...
              {" %"}
            </p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}>
                  Date
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 20,
                  }}>
                  {dataForModal && dataForModal.date}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <p style={{ opacity: 0 }}>Total Value</p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 20,
                  }}>
                  {query !== "" ? totalPO : dataForModal && dataForModal.count}{" "}
                  POs
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}>
                  Total Value
                </p>

                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 20,
                  }}>
                  {query !== ""
                    ? "₹ " + filteredAmount.toLocaleString("en-IN")
                    : "₹ " + dataForModal.value.toLocaleString("en-IN")}
                </p>
              </div>
            </div>
            <div className="search-container">
              <input
                autocomplete="off"
                type="text"
                placeholder="Search projects..."
                value={query}
                onChange={(e) => {
                  console.log("value : ", e.target.value);
                  setQuery(e.target.value);
                }}
                id="search-input"
              />
            </div>
            <ul
              className="modal-list"
              style={{ overflowY: "scroll", marginTop: 20 }}>
              {poData &&
                poData.map((item, index) =>
                  item?.projectName
                    ?.toLocaleLowerCase()
                    .includes(query.toLocaleLowerCase()) ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 20,
                      }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <li
                          key={index}
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#333",
                          }}>
                          {item.name}
                        </li>
                        <li
                          key={index}
                          className="modal-list-item"
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#333",
                          }}>
                          {"₹ "}
                          {item.value?.toLocaleString("en-IN")}
                        </li>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <li
                          key={index}
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#333",
                          }}>
                          {item.projectName
                            ? item.projectName
                            : "NA (NOT AVAILABLE)"}
                        </li>
                        <li
                          key={index}
                          style={{ fontSize: "14px", color: "#333" }}>
                          {item.category}
                        </li>
                      </div>

                      <div
                        style={{
                          height: 1,
                          width: "100%",
                          backgroundColor: "#ddd",
                          marginTop: 5,
                        }}
                      />
                    </div>
                  ) : null
                )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
