/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import { useState } from "react";

export default ({ children, component, position }) => {
  const [showPopOver, togglePopOver] = useState(false);

  useEffect(() => {
    window.addEventListener("mousedown", (event) => {
      togglePopOver(false);
    });
    return () => {
      window.removeEventListener("mousedown", null);
    };
  }, []);

  return (
    <div style={{ position: "relative" }} className="pop_over_menu">
      <div
        onClick={(event) => {
          togglePopOver((st) => !st);
        }}
      >
        {children}
      </div>
      {showPopOver && (
        <div
          style={{
            position: "absolute",
            top: 0,
            backgroundColor: "white",
            padding: 20,
            boxShadow: "1px 1px 5px grey",
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderColor: "grey",
            zIndex: 1000,
            left:
              position === "left" &&
              -React.cloneElement(component)?.props?.style?.width,
            cursor: "pointer",
          }}
          onClick={(event) => {
            togglePopOver((st) => !st);
          }}
        >
          {component}
        </div>
      )}
    </div>
  );
};
