import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { UserContext } from "../../Context/UserContext";
import { useContext, useEffect, useRef, useState } from "react";
import "./ChatModal.scss";
import RemarkTextBox from "../Finance/RemarksTextBox";
import TextField from "@material-ui/core/TextField";
import { IoSend } from "react-icons/io5";
import firebase from "../../config/firebase";

let a = {
  1686382356921: {
    id: 1686382356921,
    message: "Hi! This is a test message",
    user: "admin",
    userId: "Staart@uniworksdesigns.com",
    time: "time",
    date: "date",
  },
};

const ChatModal = ({ chatModal, setChatModal, selectedOrder }) => {
  console.log(selectedOrder);
  const [chatData, setChatData] = useState({});
  useEffect(() => {
    firebase
      .database()
      .ref("PoMessages/" + `${selectedOrder.item.OrderId}`)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          console.log("database value", snapshot.val());
          setChatData(snapshot.val() || a);
          reftoFocus.current?.scrollIntoView({ behavior: "smooth" });
        }
      });

    return () => {
      firebase
        .database()
        .ref("PoMessages/" + `${selectedOrder.item.OrderId}`)
        .off();
    };
  }, []);

  const messageInputRef = useRef(null);
  const reftoFocus = useRef(null);
  const updateFBRDB = (timeStamp, data) => {
    // console.log("timestaomp", timeStamp, data);
    firebase
      .database()
      .ref("PoMessages/" + `${selectedOrder.item.OrderId}` + "/" + timeStamp)
      // .child("editStatus")
      .set(data);
  };

  useEffect(() => {
    reftoFocus.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatData]);

  const userContext = useContext(UserContext);
  const UpdateChatData = () => {
    let lastUpdatedTimestamp = new Date().getTime();
    let lastUpdatedDate =
      new Date(lastUpdatedTimestamp).getDate() +
      "-" +
      (new Date(lastUpdatedTimestamp).getMonth() + 1);
    let lastUpdatedTime =
      new Date(lastUpdatedTimestamp).getHours() +
      ":" +
      new Date(lastUpdatedTimestamp).getMinutes();
    let newObjectOfChat = {
      [lastUpdatedTimestamp]: {
        id: lastUpdatedTimestamp,
        message: messageInputRef.current.value,
        user: userContext.user.email.slice(
          0,
          userContext.user.email.indexOf("@")
        ),
        userId: userContext.user.email,
        time: lastUpdatedTime,
        date: lastUpdatedDate,
      },
    };
    setChatData((prev) => {
      return {
        ...prev,
        ...newObjectOfChat,
      };
    });
    messageInputRef.current.value = "";
    // reftoFocus.current.scrollIntoView();
    updateFBRDB(lastUpdatedTimestamp, newObjectOfChat[lastUpdatedTimestamp]);
    // reftoFocus.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Modal
      open={chatModal}
      onClose={() => {
        setChatModal(false);
      }}
      // maxWidth="sm"

      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

      // fullWidth={true}
    >
      <Box
        className="chat-box-approval"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          width: "35%",
          height: "90%",
          //   maxWidth: "50%",
        }}
      >
        <div className="chat-modal-container">
          <div className="chat-modal-header">
            <h2 style={{ textAlign: "center" }}>Chat</h2>
          </div>
          <div className="chat-modal-body">
            <div className="body-chat-container">
              {Object.values(chatData).map((sChat, scIndex) => {
                return (
                  <div
                    key={scIndex}
                    className={`chat-bubble ${
                      userContext.user.email === sChat.userId
                        ? "same-user"
                        : "diff-user"
                    }`}
                  >
                    <div
                      style={{
                        color:
                          sChat.userId == userContext.user.email
                            ? "white"
                            : "black",
                        background:
                          sChat.userId == userContext.user.email
                            ? "#394867"
                            : "#E3F4F4",
                        padding: "10px",
                        borderRadius: "10px",
                        fontSize: "0.9em",
                        maxWidth: "70%",
                      }}
                    >
                      <p style={{ fontWeight: "600" }}>
                        {userContext.user.email === sChat.userId ? (
                          "You"
                        ) : (
                          <span style={{ color: "red" }}>{sChat.userId}</span>
                        )}
                      </p>
                      <p>{sChat.message}</p>
                    </div>
                    <p style={{ color: "black", fontSize: "0.7em" }}>
                      {sChat.date} | {sChat.time}
                    </p>
                  </div>
                );
              })}
              {/* <div style={{ paddingTop: "1em" }}></div> */}
              <div ref={reftoFocus}></div>
            </div>
            <div className="chat-modal-bottom-container">
              <TextField
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    console.log("shift pressed");

                    e.preventDefault();
                    if (messageInputRef.current.value.length > 0) {
                      UpdateChatData();
                    }
                  }
                }}
                multiline
                style={{ width: "100%" }}
                placeholder="Message"
                maxRows={4}
                inputRef={messageInputRef}
                //   ref={messageInputRef}
                //   width="420px"
                onBlur={() => {
                  console.log(messageInputRef.current.value);
                }}
              />
              <IoSend
                size="27px"
                onClick={() => {
                  console.log(
                    "Text Field value",
                    messageInputRef.current.value
                  );
                  if (messageInputRef.current.value.length > 0) {
                    UpdateChatData();
                    // reftoFocus.current?.scrollIntoView({ behavior: "smooth" });
                    // console.log()
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default ChatModal;
