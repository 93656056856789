import React, { useState, useEffect, useContext } from "react";
import DrawerTemplate from "../../hoc/drawerTemplate";
import "./SuggestiveItems.scss";
import { TextArea, Input, DropDown } from "../../components";
import { FiPlus, FiTrash, FiEdit2, FiSave, FiPaperclip } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import config from "../../config/config";
import ProjectService from "../../api/projectService";
import { ToastContainer, toast } from "react-toast";
import Switch from "@material-ui/core/Switch";
import { CircularProgress } from "@material-ui/core";
import { UserContext } from "../../Context/UserContext";
import fileDialog from "file-dialog";
import firebase from "../../config/firebase";
import { GrClose } from "react-icons/gr";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";

const SuggestiveItems = () => {
  const { record } = useAnalyticsContext();

  const userContext = useContext(UserContext);

  const projectService = new ProjectService();

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [showComponent, setShowComponent] = useState(false);
  const [showMaterial, setShowWorkMaterial] = useState(true);
  const [showWork, setShowWork] = useState(false);
  const [showPO, setShowPO] = useState(false);
  const [showFirm, setShowFirm] = useState(false);

  // Component
  const [selectedComponentDescription, setSelectedComponentDescription] =
    useState("");
  const [selectedComponentRate, setSelectedComponentRate] = useState("");
  const [selectedComponentUnit, setSelectedComponentUnit] = useState("");
  const [components, setComponents] = useState([]);
  const [showVendorsMaterial, setShowVendorsMaterial] = useState(false);
  const [viewVendorsMaterial, setViewVendorsMaterial] = useState(false);
  const [activeLabourType, setActiveLabourType] = useState("work");
  const [editableComponentId, setEditableComponentId] = useState("");
  const [selectedComponentToHighlight, setSelectedComponentToHighlight] =
    useState("");
  // Component Work
  const [isComponentWorkWithMaterial, setIsComponentWorkWithMaterial] =
    useState(true);
  const [selectedComponentWorkType, setSelectedComponentWorkType] =
    useState("Work + Material");
  useEffect(() => {
    setSelectedComponentWorkType(
      isComponentWorkWithMaterial ? "Work + Material" : "Only Work"
    );
  }, [isComponentWorkWithMaterial]);
  const [selectedComponentWorkCategory, setSelectedComponentWorkCategory] =
    useState("");
  const [
    selectedComponentWorkDescription,
    setSelectedComponentWorkDescription,
  ] = useState("");
  const [selectedComponentWorkRate, setSelectedComponentWorkRate] =
    useState("");
  const [selectedComponentWorkUnit, setSelectedComponentWorkUnit] =
    useState("");
  const [selectedComponentWorkGST, setSelectedComponentWorkGST] = useState("");
  const [selectedComponentWorks, setSelectedComponentWorks] = useState([]);
  // Component Material
  const [
    selectedComponentMaterialCategory,
    setSelectedComponentMaterialCategory,
  ] = useState("");
  const [
    selectedComponentMaterialSpecification,
    setSelectedComponentMaterialSpecification,
  ] = useState("");
  const [selectedComponentMaterialRate, setSelectedComponentMaterialRate] =
    useState("");
  const [selectedComponentMaterialUnit, setSelectedComponentMaterialUnit] =
    useState("");
  const [selectedComponentMaterialGST, setSelectedComponentMaterialGST] =
    useState("");
  const [selectedComponentMaterials, setSelectedComponentMaterials] = useState(
    []
  );

  // Material
  const [selectedMaterialCategory, setSelectedMaterialCategory] = useState("");
  const [selectedMaterialSpecification, setSelectedMaterialSpecification] =
    useState("");
  const [selectedMaterialRate, setSelectedMaterialRate] = useState("");
  const [selectedMaterialUnit, setSelectedMaterialUnit] = useState("");
  const [selectedMaterialGST, setSelectedMaterialGST] = useState("");
  const [materials, setMaterials] = useState([]);
  const [editableMaterialId, setEditableMaterialId] = useState("");

  // Work
  const [isWorkWithMaterial, setIsWorkWithMaterial] = useState(true);
  const [selectedWorkType, setSelectedWorkType] = useState("Work + Material");
  useEffect(() => {
    setSelectedWorkType(isWorkWithMaterial ? "Work + Material" : "Only Work");
  }, [isWorkWithMaterial]);
  const [selectedWorkCategory, setSelectedWorkCategory] = useState("");
  const [selectedWorkDescription, setSelectedWorkDescription] = useState("");
  const [selectedWorkRate, setSelectedWorkRate] = useState("");
  const [selectedWorkUnit, setSelectedWorkUnit] = useState("");
  const [selectedWorkGST, setSelectedWorkGST] = useState("");
  const [works, setWorks] = useState([]);
  const [editableWorkId, setEditableWorkId] = useState("");

  // PO
  const [selectedContactPersonName, setSelectedContactPersonName] =
    useState("");
  const [selectedContactPersonNumber, setSelectedContactPersonNumber] =
    useState("");
  const [selectedTC, setSelectedTC] = useState("");
  const [selectedSI, setSelectedSI] = useState("");
  const [selectedBillingAddress, setSelectedBillingAddress] = useState("");
  const [po, setPO] = useState([]);
  const [editablePOId, setEditablePOId] = useState("");

  // Firm
  const [firmAddress, setFirmAddress] = useState("");
  const [firmPhoneNumber, setFirmPhoneNumber] = useState("");
  const [firmGSTIN, setFirmGSTIN] = useState("");
  const [firmContactPersonName, setFirmContactPersonName] = useState("");
  const [firmContactPersonNumber, setFirmContactPersonNumber] = useState("");
  const [firmContactPersonEmail, setFirmContactPersonEmail] = useState("");
  const [firmSignature, setFirmSignature] = useState("");
  const [firmDetailsId, setFirmDetailsId] = useState("");

  useEffect(() => {
    if (activeTab === 0) {
      setShowComponent(true);
      setShowWorkMaterial(false);
      setShowWork(false);
      setShowPO(false);
      setShowFirm(false);
      record(
        TAGS[
          ("@suggestive_text_component_tab_click_success",
          { description: "Suggestive text component tab click success" })
        ]
      );
    } else if (activeTab === 1) {
      setShowComponent(false);
      setShowWorkMaterial(true);
      setShowWork(false);
      setShowPO(false);
      setShowFirm(false);
      record(
        TAGS[
          ("@suggestive_text_material_tab_click_success",
          { description: "Suggestive text material tab click success" })
        ]
      );
    } else if (activeTab === 2) {
      setShowComponent(false);
      setShowWorkMaterial(false);
      setShowWork(true);
      setShowPO(false);
      setShowFirm(false);
      record(
        TAGS[
          ("@suggestive_text_work_tab_click_success",
          { description: "Suggestive text work tab click success" })
        ]
      );
    } else if (activeTab === 3) {
      setShowComponent(false);
      setShowWorkMaterial(false);
      setShowWork(false);
      setShowPO(true);
      setShowFirm(false);
      record(
        TAGS[
          ("@suggestive_text_po_tab_click_success",
          { description: "Suggestive text po tab click success" })
        ]
      );
    } else if (activeTab === 4) {
      setShowComponent(false);
      setShowWorkMaterial(false);
      setShowWork(false);
      setShowPO(false);
      setShowFirm(true);
      record(
        TAGS[
          ("@suggestive_text_firm_tab_click_success",
          { description: "Suggestive text firm tab click success" })
        ]
      );
    }
  }, [activeTab]);

  const [categories, setCategories] = useState(null);

  useEffect(() => {
    fetch(`${config.utilService}categories`)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setCategories({
            materialCategories: res.materialCategories.sort((a, b) =>
              a.name.localeCompare(b.name)
            ),
            vendorCategories: res.vendorCategories.sort((a, b) =>
              a.Category.localeCompare(b.Category)
            ),
          });
          setSelectedMaterialCategory(res.materialCategories[0].name);
          setSelectedWorkCategory(res.vendorCategories[0].Category);
          setSelectedComponentWorkCategory(res.vendorCategories[0].Category);
          setSelectedComponentMaterialCategory(res.materialCategories[0].name);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    getSuggestiveText();
    getSuggestiveTextPO();
    getSuggestiveTextFirm();
  }, []);

  const getSuggestiveText = async () => {
    setIsLoading(true);
    const response = await projectService.getSuggestiveText();
    if (response.status == 200) {
      setIsLoading(false);
      setComponents(
        response.componenet.filter(
          (item) =>
            item.firm == userContext.user.Firm ||
            item.firm == "Staart Buildtech" ||
            item.firm == null
        )
      );
      setMaterials(
        response.material.filter(
          (item) =>
            item.firm == userContext.user.Firm ||
            item.firm == "Staart Buildtech" ||
            item.firm == null
        )
      );
      setWorks(
        response.work.filter(
          (item) =>
            item.firm == userContext.user.Firm ||
            item.firm == "Staart Buildtech" ||
            item.firm == null
        )
      );
    } else {
      setIsLoading(false);
      toast.error("Error getting suggestions!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
  };

  const addSuggestiveTextComponent = async (
    description,
    rate,
    unit,
    works,
    materials
  ) => {
    const response = await projectService.addSuggestiveTextComponent(
      description,
      rate,
      unit,
      works,
      materials,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion saved successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error saving suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const updateSuggestiveTextComponent = async (
    Id,
    description,
    rate,
    unit,
    works,
    materials
  ) => {
    const response = await projectService.updateSuggestiveTextComponent(
      Id,
      description,
      rate,
      unit,
      works,
      materials,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion updated successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error updating suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const deleteSuggestiveTextComponent = async (Id) => {
    const response = await projectService.deleteSuggestiveTextComponent(Id);
      setShowVendorsMaterial(false)
    if (response.status == 200) {
      toast.success("Suggestion deleted successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error deleting suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const addSuggestiveTextMaterial = async (
    category,
    specification,
    rate,
    unit,
    gst
  ) => {
    const response = await projectService.addSuggestiveTextMaterial(
      category,
      specification,
      rate,
      unit,
      gst,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion saved successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error saving suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const updateSuggestiveTextMaterial = async (
    Id,
    category,
    specification,
    rate,
    unit,
    gst
  ) => {
    const response = await projectService.updateSuggestiveTextMaterial(
      Id,
      category,
      specification,
      rate,
      unit,
      gst,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion updated successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error updating suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const deleteSuggestiveTextMaterial = async (Id) => {
    const response = await projectService.deleteSuggestiveTextMaterial(Id);
    setShowVendorsMaterial(false)
    if (response.status == 200) {
      toast.success("Suggestion deleted successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error deleting suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const addSuggestiveTextWork = async (
    category,
    workType,
    description,
    rate,
    unit,
    gst
  ) => {
    const response = await projectService.addSuggestiveTextWork(
      category,
      workType,
      description,
      rate,
      unit,
      gst,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion saved successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error saving suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const updateSuggestiveTextWork = async (
    Id,
    category,
    workType,
    description,
    rate,
    unit,
    gst
  ) => {
    const response = await projectService.updateSuggestiveTextWork(
      Id,
      category,
      workType,
      description,
      rate,
      unit,
      gst,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion updated successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error updating suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const deleteSuggestiveTextWork = async (Id) => {
    const response = await projectService.deleteSuggestiveTextWork(Id);
      setShowVendorsMaterial(false)
    if (response.status == 200) {
      toast.success("Suggestion deleted successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error deleting suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const getSuggestiveTextPO = async () => {
    setIsLoading(true);
    const response = await projectService.getSuggestiveTextPO();
    if (response.status == 200) {
      setIsLoading(false);
      setPO(
        response.payload.filter(
          (item) =>
            item.firm == userContext.user.Firm ||
            item.firm == "Staart Buildtech" ||
            item.firm == null
        )
      );
    } else {
      setIsLoading(false);
      toast.error("Error getting suggestions!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
  };

  const addSuggestiveTextPO = async (
    ContactPersonName,
    ContactPersonNumber,
    TermsandConditions,
    SpecialInstructions,
    BillingAddress
  ) => {
    const response = await projectService.addSuggestiveTextPO(
      ContactPersonName,
      ContactPersonNumber,
      TermsandConditions,
      SpecialInstructions,
      BillingAddress,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion saved successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error saving suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveTextPO();
  };

  const updateSuggestiveTextPO = async (
    Id,
    ContactPersonName,
    ContactPersonNumber,
    TermsandConditions,
    SpecialInstructions,
    BillingAddress
  ) => {
    const response = await projectService.updateSuggestiveTextPO(
      Id,
      ContactPersonName,
      ContactPersonNumber,
      TermsandConditions,
      SpecialInstructions,
      BillingAddress,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion updated successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error updating suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveTextPO();
  };

  const deleteSuggestiveTextPO = async (Id) => {
    const response = await projectService.deleteSuggestiveTextPO(Id);
      setShowVendorsMaterial(false)
    if (response.status == 200) {
      toast.success("Suggestion deleted successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error deleting suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveTextPO();
  };

  const getSuggestiveTextFirm = async () => {
    setIsLoading(true);
    const response = await projectService.getSuggestiveTextFirm();
    if (response.status == 200) {
      setIsLoading(false);
      const firmDetails = response.payload.filter(
        (item) => item.firmName == userContext.user.Firm
      );
      if (firmDetails.length > 0) {
        setFirmDetailsId(firmDetails[0].Id);
        setFirmAddress(firmDetails[0].firmAddress);
        setFirmPhoneNumber(firmDetails[0].firmPhoneNumber);
        setFirmGSTIN(firmDetails[0].firmGSTIN);
        setFirmContactPersonName(firmDetails[0].firmContactPersonName);
        setFirmContactPersonNumber(firmDetails[0].firmContactPersonNumber);
        setFirmContactPersonEmail(firmDetails[0].firmContactPersonEmail);
        setFirmSignature(JSON.parse(firmDetails[0].firmSignature));
      }
    } else {
      setIsLoading(false);
      toast.error("Error getting suggestions!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
  };

  const addSuggestiveTextFirm = async (
    firmAddress,
    firmPhoneNumber,
    firmGSTIN,
    firmContactPersonName,
    firmContactPersonNumber,
    firmContactPersonEmail,
    firmSignature
  ) => {
    const response = await projectService.addSuggestiveTextFirm(
      userContext.user.Firm,
      firmAddress,
      firmPhoneNumber,
      firmGSTIN,
      firmContactPersonName,
      firmContactPersonNumber,
      firmContactPersonEmail,
      JSON.stringify(firmSignature)
    );
    if (response.status == 200) {
      toast.success("Suggestion saved successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error saving suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveTextFirm();
  };

  const updateSuggestiveTextFirm = async (
    firmAddress,
    firmPhoneNumber,
    firmGSTIN,
    firmContactPersonName,
    firmContactPersonNumber,
    firmContactPersonEmail,
    firmSignature
  ) => {
    const response = await projectService.updateSuggestiveTextFirm(
      firmDetailsId,
      userContext.user.Firm,
      firmAddress,
      firmPhoneNumber,
      firmGSTIN,
      firmContactPersonName,
      firmContactPersonNumber,
      firmContactPersonEmail,
      JSON.stringify(firmSignature)
    );
    if (response.status == 200) {
      toast.success("Suggestion updated successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error updating suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveTextFirm();
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            color: "#fdd34d",
          }}
        >
          <CircularProgress style={{ color: "#fdd34d" }} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            minWidth: "400px",
            zoom: 0.75,
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "auto",
              minHeight: "80px",
              backgroundColor: "#ebebeb",
              color: "black",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#353535",
                fontSize: "30px",
                fontWeight: 700,
                margin: "0px 20px",
              }}
            >
              Suggestive Items
            </p>

            <Paper>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, newVal) => {
                  setActiveTab(newVal);
                  getSuggestiveText();
                }}
                value={activeTab}
              >
                <Tab label="Component" />
                <Tab label="Material" />
                <Tab label="Work" />
                <Tab label="PO" />
                <Tab label="Firm" />
              </Tabs>
            </Paper>

            <div />

            <ToastContainer delay={2000} position="bottom-center" />
          </div>

          {showComponent ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                flex: 1,
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "600px",
                  margin: "20px",
                  paddingBottom: "40px",
                }}
              >
                <div id="search">
                  <AiOutlineSearch color="rgb(53, 53, 53)" size="25" />
                  <input
                    placeholder="Search by component description"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      setComponents(
                        components.filter((item) =>
                          item.description
                            .toLowerCase()
                            .includes(e.currentTarget.value.toLowerCase())
                        )
                      )
                    }
                  />
                </div>

                <div style={{ margin: "20px 0px" }}>
                  <TextArea
                    placeholder={"Description"}
                    value={selectedComponentDescription}
                    onChange={(value) => setSelectedComponentDescription(value)}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Input
                    placeholder={"Rate"}
                    value={selectedComponentRate}
                    onChange={(value) =>
                      setSelectedComponentRate(value.replace(/[^0-9.]+/g, ""))
                    }
                  />
                  <Input
                    placeholder={"Unit"}
                    value={selectedComponentUnit}
                    onChange={(value) => setSelectedComponentUnit(value)}
                  />
                  <p
                    style={{ color: "blue", cursor: "pointer", fontSize: 14 }}
                    onClick={() => {
                      setShowVendorsMaterial(true);
                      setViewVendorsMaterial(false);
                      setSelectedComponentWorks([]);
                      setSelectedComponentMaterials([]);
                      record(
                        TAGS[
                          ("@suggestive_text_add_work_material_text_click_success",
                          {
                            description:
                              "Suggestive text add work material text click success",
                          })
                        ]
                      );
                    }}
                  >
                    Add Vendors/ Material
                  </p>
                  <div
                    style={{
                      width: "70px",
                      height: "50px",
                      backgroundColor: "#fdd34d",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (
                        selectedComponentDescription.length > 0 &&
                        selectedComponentRate.length > 0 &&
                        selectedComponentUnit.length > 0 &&
                        (selectedComponentWorks.length > 0 ||
                          selectedComponentMaterials.length > 0)
                      ) {
                        if (components.length > 0) {
                          let isSpecEqual = components.map((item) =>
                            item.description.localeCompare(
                              selectedComponentDescription
                            )
                          );
                          if (isSpecEqual.every((x) => x != 0)) {
                            addSuggestiveTextComponent(
                              selectedComponentDescription,
                              selectedComponentRate,
                              selectedComponentUnit,
                              selectedComponentWorks,
                              selectedComponentMaterials
                            );
                            setShowVendorsMaterial(false);
                            setViewVendorsMaterial(false);
                          } else {
                            toast.error(
                              "Cannot add same description suggestion!",
                              {
                                backgroundColor: "rgb(255, 211, 77)",
                                color: "black",
                              }
                            );
                          }
                        } else {
                          addSuggestiveTextComponent(
                            selectedComponentDescription,
                            selectedComponentRate,
                            selectedComponentUnit,
                            selectedComponentWorks,
                            selectedComponentMaterials
                          );
                          setShowVendorsMaterial(false);
                          setViewVendorsMaterial(false);
                        }
                      } else {
                        toast.error("Please fill all the details!", {
                          backgroundColor: "rgb(255, 211, 77)",
                          color: "black",
                        });
                      }
                    }}
                  >
                    <FiPlus size={40} />
                  </div>
                </div>

                <div
                  style={{ marginTop: "2em", height: "76%", overflow: "auto" }}
                >
                  {components &&
                    components.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: `${
                            selectedComponentToHighlight ===
                            components[index].Id
                              ? "#efefeffa"
                              : ""
                          }`,
                          padding: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                            gap: "10px",
                          }}
                        >
                          <TextArea
                            disabled={
                              !(editableComponentId == components[index].Id)
                            }
                            placeholder={"Description"}
                            value={item.description}
                            onChange={(value) => {
                              components[index].description = value;
                              setComponents([...components]);
                            }}
                          />
                          {editableComponentId == components[index].Id ? (
                            <FiSave
                              size={33}
                              style={{
                                marginTop: "15px",
                                cursor: "pointer",
                                color: "grey",
                              }}
                              onClick={() => {
                                updateSuggestiveTextComponent(
                                  components[index].Id,
                                  components[index].description,
                                  components[index].rate,
                                  components[index].unit,
                                  selectedComponentWorks,
                                  selectedComponentMaterials
                                );
                                setEditableComponentId("");
                                record(
                                  TAGS[
                                    ("@suggestive_text_save_work_material_icon_click_success",
                                    {
                                      description:
                                        "Suggestive text save work material icon click success",
                                    })
                                  ]
                                );
                              }}
                            />
                          ) : (
                            <FiEdit2
                              size={30}
                              style={{
                                marginTop: "15px",
                                cursor: "pointer",
                                color: "grey",
                              }}
                              onClick={() => {
                                setEditableComponentId(components[index].Id);
                                setSelectedComponentToHighlight(
                                  components[index].Id
                                );
                                setSelectedComponentWorks(
                                  JSON.parse(item.works)
                                );
                                setSelectedComponentMaterials(
                                  JSON.parse(item.materials)
                                );
                                record(
                                  TAGS[
                                    ("@suggestive_text_edit_work_material_icon_click_success",
                                    {
                                      description:
                                        "Suggestive text edit work material icon click success",
                                    })
                                  ]
                                );
                              }}
                            />
                          )}
                          <FiTrash
                            size={30}
                            style={{
                              marginTop: "15px",
                              cursor: "pointer",
                              color: "grey",
                            }}
                            onClick={() => {
                              deleteSuggestiveTextComponent(
                                components[index].Id
                              );
                              record(
                                TAGS[
                                  ("@suggestive_text_delete_work_material_icon_click_success",
                                  {
                                    description:
                                      "Suggestive text delete work material icon click success",
                                  })
                                ]
                              );
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            marginTop: "20px",
                          }}
                        >
                          <Input
                            disabled={
                              !(editableComponentId == components[index].Id)
                            }
                            placeholder={"Rate"}
                            value={item.rate}
                            onChange={(value) => {
                              components[index].rate = value.replace(
                                /[^0-9]+/gm,
                                ""
                              );
                              setComponents([...components]);
                            }}
                          />
                          <Input
                            disabled={
                              !(editableComponentId == components[index].Id)
                            }
                            placeholder={"Unit"}
                            value={item.unit}
                            onChange={(value) => {
                              components[index].unit = value;
                              setComponents([...components]);
                            }}
                          />
                          <p
                            style={{
                              color: "grey",
                              cursor: "pointer",
                              fontSize: 14,
                            }}
                            onClick={() => {
                              setShowVendorsMaterial(true);
                              setEditableComponentId("");
                              // setViewVendorsMaterial(true);
                              setSelectedComponentToHighlight(
                                components[index].Id
                              );
                              setSelectedComponentWorks(JSON.parse(item.works));
                              setSelectedComponentMaterials(
                                JSON.parse(item.materials)
                              );
                            }}
                          >
                            View Vendors/ Material
                          </p>
                        </div>
                        <hr style={{ margin: "1.5em 0" }} />
                      </div>
                    ))}
                </div>
              </div>

              {showVendorsMaterial && (
                <div
                  style={{
                    width: "650px",
                    margin: "90px 40px 20px 40px",
                    paddingBottom: "40px",
                    borderWidth: 1,
                    boxShadow: "0px 0px 3px grey",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    // overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 0px",
                      backgroundColor: "#fbfbfb",
                      fontSize: "36px",
                      fontWeight: "700px",
                      color: "rgba(53,53,53,0.75)",
                      letterSpacing: "0px",
                      lineHeight: "1.2",
                      textAlign: "center",
                      minHeight: "80px",
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div
                      onClick={() => setActiveLabourType("work")}
                      style={{
                        width: "50%",
                        fontWeight: "700",
                        color: "#000000",
                        fontSize: "25px",
                        textAlign: "center",
                        lineHeight: "1.2",
                        cursor: "pointer",
                        opacity: activeLabourType == "work" ? 1 : 0.3,
                      }}
                    >
                      Work
                    </div>
                    <div
                      onClick={() => setActiveLabourType("material")}
                      style={{
                        width: "50%",
                        fontWeight: "700",
                        color: "#000000",
                        fontSize: "25px",
                        textAlign: "center",
                        lineHeight: "1.2",
                        cursor: "pointer",
                        opacity: activeLabourType == "material" ? 1 : 0.3,
                      }}
                    >
                      Material
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "-13px",
                        top: "-18.1px",
                      }}
                    >
                      <GrClose
                        size={30}
                        onClick={() => {
                          setShowVendorsMaterial(false);
                          setViewVendorsMaterial(false);
                          setSelectedComponentToHighlight("");
                        }}
                      />
                    </div>
                  </div>

                  {activeLabourType == "work" ? (
                    <div style={{ padding: "40px" }}>
                      {(!viewVendorsMaterial ||
                        (viewVendorsMaterial && editableComponentId)) && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: "20px",
                            }}
                          >
                            <div style={{ width: "40%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ fontWeight: "bold" }}>
                                  {isComponentWorkWithMaterial
                                    ? "Add material"
                                    : "Only Work"}
                                </p>
                                <Switch
                                  checked={isComponentWorkWithMaterial}
                                  onChange={() =>
                                    setIsComponentWorkWithMaterial(
                                      !isComponentWorkWithMaterial
                                    )
                                  }
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </div>
                              <div>
                                <p>
                                  {isComponentWorkWithMaterial
                                    ? "Work with material"
                                    : "Material not required"}
                                </p>
                              </div>
                            </div>

                            <div style={{ width: "60%" }}>
                              {categories && (
                                <DropDown
                                  data={
                                    categories &&
                                    categories.vendorCategories.map(
                                      (item) => item.Category
                                    )
                                  }
                                  value={selectedComponentWorkCategory}
                                  onChange={(value) => {
                                    setSelectedComponentWorkCategory(value);
                                    setSelectedComponentWorkDescription("");
                                    setSelectedComponentWorkRate("");
                                    setSelectedComponentWorkUnit("");
                                    setSelectedComponentWorkGST("");
                                  }}
                                />
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              margin: "20px 0px",
                            }}
                          >
                            <TextArea
                              placeholder={"Description"}
                              value={selectedComponentWorkDescription}
                              onChange={(value) =>
                                setSelectedComponentWorkDescription(value)
                              }
                            />
                            <div
                              style={{
                                marginLeft: "auto",
                                width: "70px",
                                height: "50px",
                                backgroundColor: "#fdd34d",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (selectedComponentWorks.length > 0) {
                                  let isSpecEqual = selectedComponentWorks.map(
                                    (item) =>
                                      item.description.localeCompare(
                                        selectedComponentWorkDescription
                                      )
                                  );
                                  isSpecEqual.every((x) => x != 0)
                                    ? setSelectedComponentWorks([
                                        ...selectedComponentWorks,
                                        {
                                          workType: selectedComponentWorkType,
                                          category:
                                            selectedComponentWorkCategory,
                                          description:
                                            selectedComponentWorkDescription,
                                          rate: selectedComponentWorkRate,
                                          unit: selectedComponentWorkUnit,
                                          gst: selectedComponentWorkGST,
                                        },
                                      ])
                                    : toast.error(
                                        "Cannot add same description suggestion!",
                                        {
                                          backgroundColor: "rgb(255, 211, 77)",
                                          color: "black",
                                        }
                                      );
                                } else {
                                  setSelectedComponentWorks([
                                    ...selectedComponentWorks,
                                    {
                                      category: selectedComponentWorkCategory,
                                      workType: selectedComponentWorkType,
                                      description:
                                        selectedComponentWorkDescription,
                                      rate: selectedComponentWorkRate,
                                      unit: selectedComponentWorkUnit,
                                      gst: selectedComponentWorkGST,
                                    },
                                  ]);
                                }
                              }}
                            >
                              <FiPlus size={40} />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Input
                              placeholder={"Rate"}
                              value={selectedComponentWorkRate}
                              onChange={(value) =>
                                setSelectedComponentWorkRate(
                                  value.replace(/[^0-9]+/gm, "")
                                )
                              }
                            />
                            <Input
                              placeholder={"Unit"}
                              value={selectedComponentWorkUnit}
                              onChange={(value) =>
                                setSelectedComponentWorkUnit(value)
                              }
                            />
                            <Input
                              placeholder={"GST"}
                              value={selectedComponentWorkGST}
                              onChange={(value) =>
                                value <= 100 &&
                                setSelectedComponentWorkGST(
                                  value.replace(/[^0-9]+/gm, "")
                                )
                              }
                            />
                          </div>
                        </>
                      )}

                      {selectedComponentWorks &&
                        selectedComponentWorks.map((item, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "40px 0px 20px 0px",
                              }}
                            >
                              <TextArea
                                disabled={!editableComponentId}
                                placeholder={"Work Type"}
                                value={item.workType}
                                onChange={(value) => {
                                  selectedComponentWorks[index].workType =
                                    value;
                                  setSelectedComponentWorks([
                                    ...selectedComponentWorks,
                                  ]);
                                }}
                              />
                              <TextArea
                                disabled={!editableComponentId}
                                placeholder={"Category"}
                                value={item.category}
                                onChange={(value) => {
                                  selectedComponentWorks[index].category =
                                    value;
                                  setSelectedComponentWorks([
                                    ...selectedComponentWorks,
                                  ]);
                                }}
                              />
                              {(!viewVendorsMaterial ||
                                (viewVendorsMaterial &&
                                  editableComponentId)) && (
                                <FiTrash
                                  size={60}
                                  style={{
                                    marginTop: "5px",
                                    cursor: "pointer",
                                    color: "grey",
                                  }}
                                  onClick={() => {
                                    selectedComponentWorks.splice(index, 1);
                                    setSelectedComponentWorks([
                                      ...selectedComponentWorks,
                                    ]);
                                  }}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "0px 0px 20px 0px",
                              }}
                            >
                              <TextArea
                                disabled={!editableComponentId}
                                placeholder={"Description"}
                                value={item.description}
                                onChange={(value) => {
                                  selectedComponentWorks[index].description =
                                    value;
                                  setSelectedComponentWorks([
                                    ...selectedComponentWorks,
                                  ]);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Input
                                disabled={!editableComponentId}
                                placeholder={"Rate"}
                                value={item.rate}
                                onChange={(value) => {
                                  selectedComponentWorks[index].rate =
                                    value.replace(/[^0-9]+/gm, "");
                                  setSelectedComponentWorks([
                                    ...selectedComponentWorks,
                                  ]);
                                }}
                              />
                              <Input
                                disabled={!editableComponentId}
                                placeholder={"Unit"}
                                value={item.unit}
                                onChange={(value) => {
                                  selectedComponentWorks[index].unit = value;
                                  setSelectedComponentWorks([
                                    ...selectedComponentWorks,
                                  ]);
                                }}
                              />
                              <Input
                                disabled={!editableComponentId}
                                placeholder={"GST"}
                                value={item.gst}
                                onChange={(value) => {
                                  if (value <= 100) {
                                    selectedComponentWorks[index].gst =
                                      value.replace(/[^0-9]+/gm, "");
                                  }
                                  setSelectedComponentWorks([
                                    ...selectedComponentWorks,
                                  ]);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div style={{ padding: "40px" }}>
                      {(!viewVendorsMaterial ||
                        (viewVendorsMaterial && editableComponentId)) && (
                        <>
                          {categories && (
                            <DropDown
                              data={
                                categories &&
                                categories.materialCategories.map(
                                  (item) => item.name
                                )
                              }
                              value={selectedComponentMaterialCategory}
                              onChange={(value) => {
                                setSelectedComponentMaterialCategory(value);
                                setSelectedComponentMaterialSpecification("");
                                setSelectedComponentMaterialRate("");
                                setSelectedComponentMaterialUnit("");
                                setSelectedComponentMaterialGST("");
                              }}
                            />
                          )}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              margin: "20px 0px",
                            }}
                          >
                            <TextArea
                              placeholder={"Specification"}
                              value={selectedComponentMaterialSpecification}
                              onChange={(value) =>
                                setSelectedComponentMaterialSpecification(value)
                              }
                            />
                            <div
                              style={{
                                marginLeft: "auto",
                                width: "70px",
                                height: "50px",
                                backgroundColor: "#fdd34d",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (selectedComponentMaterials.length > 0) {
                                  let isSpecEqual =
                                    selectedComponentMaterials.map((item) =>
                                      item.specification.localeCompare(
                                        selectedComponentMaterialSpecification
                                      )
                                    );
                                  isSpecEqual.every((x) => x != 0)
                                    ? setSelectedComponentMaterials([
                                        ...selectedComponentMaterials,
                                        {
                                          category:
                                            selectedComponentMaterialCategory,
                                          specification:
                                            selectedComponentMaterialSpecification,
                                          rate: selectedComponentMaterialRate,
                                          unit: selectedComponentMaterialUnit,
                                          gst: selectedComponentMaterialGST,
                                        },
                                      ])
                                    : toast.error(
                                        "Cannot add same specification suggestion!",
                                        {
                                          backgroundColor: "rgb(255, 211, 77)",
                                          color: "black",
                                        }
                                      );
                                } else {
                                  setSelectedComponentMaterials([
                                    ...selectedComponentMaterials,
                                    {
                                      category:
                                        selectedComponentMaterialCategory,
                                      specification:
                                        selectedComponentMaterialSpecification,
                                      rate: selectedComponentMaterialRate,
                                      unit: selectedComponentMaterialUnit,
                                      gst: selectedComponentMaterialGST,
                                    },
                                  ]);
                                }
                              }}
                            >
                              <FiPlus size={40} />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Input
                              placeholder={"Rate"}
                              value={selectedComponentMaterialRate}
                              onChange={(value) =>
                                setSelectedComponentMaterialRate(
                                  value.replace(/[^0-9]+/gm, "")
                                )
                              }
                            />
                            <Input
                              placeholder={"Unit"}
                              value={selectedComponentMaterialUnit}
                              onChange={(value) =>
                                setSelectedComponentMaterialUnit(value)
                              }
                            />
                            <Input
                              placeholder={"GST"}
                              value={selectedComponentMaterialGST}
                              onChange={(value) =>
                                value <= 100 &&
                                setSelectedComponentMaterialGST(
                                  value.replace(/[^0-9]+/gm, "")
                                )
                              }
                            />
                          </div>
                        </>
                      )}

                      {selectedComponentMaterials &&
                        selectedComponentMaterials.map((item, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "40px 0px 20px 0px",
                              }}
                            >
                              <TextArea
                                disabled={!editableComponentId}
                                placeholder={"Category"}
                                value={item.category}
                                onChange={(value) => {
                                  selectedComponentMaterials[index].category =
                                    value;
                                  setSelectedComponentMaterials([
                                    ...selectedComponentMaterials,
                                  ]);
                                }}
                              />
                              <TextArea
                                disabled={!editableComponentId}
                                placeholder={"Specification"}
                                value={item.specification}
                                onChange={(value) => {
                                  selectedComponentMaterials[
                                    index
                                  ].specification = value;
                                  setSelectedComponentMaterials([
                                    ...selectedComponentMaterials,
                                  ]);
                                }}
                              />
                              {(!viewVendorsMaterial ||
                                (viewVendorsMaterial &&
                                  editableComponentId)) && (
                                <FiTrash
                                  size={60}
                                  style={{
                                    marginTop: "5px",
                                    cursor: "pointer",
                                    color: "grey",
                                  }}
                                  onClick={() => {
                                    selectedComponentMaterials.splice(index, 1);
                                    setSelectedComponentMaterials([
                                      ...selectedComponentMaterials,
                                    ]);
                                  }}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Input
                                disabled={!editableComponentId}
                                placeholder={"Rate"}
                                value={item.rate}
                                onChange={(value) => {
                                  selectedComponentMaterials[index].rate =
                                    value.replace(/[^0-9]+/gm, "");
                                  setSelectedComponentMaterials([
                                    ...selectedComponentMaterials,
                                  ]);
                                }}
                              />
                              <Input
                                disabled={!editableComponentId}
                                placeholder={"Unit"}
                                value={item.unit}
                                onChange={(value) => {
                                  selectedComponentMaterials[index].unit =
                                    value;
                                  setSelectedComponentMaterials([
                                    ...selectedComponentMaterials,
                                  ]);
                                }}
                              />
                              <Input
                                disabled={!editableComponentId}
                                placeholder={"GST"}
                                value={item.gst}
                                onChange={(value) => {
                                  if (value <= 100) {
                                    selectedComponentMaterials[index].gst =
                                      value.replace(/[^0-9]+/gm, "");
                                  }
                                  setSelectedComponentMaterials([
                                    ...selectedComponentMaterials,
                                  ]);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : showMaterial ? (
            <div
              style={{ width: "600px", margin: "20px", paddingBottom: "40px" }}
            >
              <div id="search">
                <AiOutlineSearch color="rgb(53, 53, 53)" size="25" />
                <input
                  placeholder="Search by materials specification"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setMaterials(
                      materials.filter((item) =>
                        item.specification
                          .toLowerCase()
                          .includes(e.currentTarget.value.toLowerCase())
                      )
                    );
                  }}
                />
              </div>

              {categories && (
                <DropDown
                  data={
                    categories &&
                    categories.materialCategories.map((item) => item.name)
                  }
                  value={selectedMaterialCategory}
                  onChange={(value) => {
                    setSelectedMaterialCategory(value);
                    setSelectedMaterialSpecification("");
                    setSelectedMaterialRate("");
                    setSelectedMaterialUnit("");
                    setSelectedMaterialGST("");
                  }}
                />
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  gap: "10px",
                  margin: "20px 0px",
                }}
              >
                <TextArea
                  placeholder={"Specification"}
                  value={selectedMaterialSpecification}
                  onChange={(value) => setSelectedMaterialSpecification(value)}
                />
                <div
                  style={{
                    width: "70px",
                    height: "50px",
                    backgroundColor: "#fdd34d",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (
                      selectedMaterialCategory.length <= 0 ||
                      selectedMaterialSpecification.length <= 0 ||
                      selectedMaterialRate.length <= 0 ||
                      selectedMaterialUnit.length <= 0 ||
                      selectedMaterialGST.length <= 0
                    ) {
                      toast.error("Please fill all the details!", {
                        backgroundColor: "rgb(255, 211, 77)",
                        color: "black",
                      });
                    } else {
                      if (materials.length > 0) {
                        let isSpecEqual = materials.map((item) =>
                          item.specification.localeCompare(
                            selectedMaterialSpecification
                          )
                        );
                        isSpecEqual.every((x) => x != 0)
                          ? addSuggestiveTextMaterial(
                              selectedMaterialCategory,
                              selectedMaterialSpecification,
                              selectedMaterialRate,
                              selectedMaterialUnit,
                              selectedMaterialGST
                            )
                          : toast.error(
                              "Cannot add same specification suggestion!",
                              {
                                backgroundColor: "rgb(255, 211, 77)",
                                color: "black",
                              }
                            );
                      } else {
                        addSuggestiveTextMaterial(
                          selectedMaterialCategory,
                          selectedMaterialSpecification,
                          selectedMaterialRate,
                          selectedMaterialUnit,
                          selectedMaterialGST
                        );
                      }
                    }
                  }}
                >
                  <FiPlus size={40} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  gap: "10px",
                }}
              >
                <Input
                  placeholder={"Rate"}
                  value={selectedMaterialRate}
                  onChange={(value) =>
                    setSelectedMaterialRate(value.replace(/[^0-9]+/gm, ""))
                  }
                />
                <Input
                  placeholder={"Unit"}
                  value={selectedMaterialUnit}
                  onChange={(value) => setSelectedMaterialUnit(value)}
                />
                <Input
                  placeholder={"GST"}
                  value={selectedMaterialGST}
                  onChange={(value) =>
                    value <= 100 &&
                    setSelectedMaterialGST(value.replace(/[^0-9]+/gm, ""))
                  }
                />
              </div>

              {materials &&
                materials.map(
                  (item, index) =>
                    item.category == selectedMaterialCategory && (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                          }}
                        >
                          <TextArea
                            disabled={
                              !(editableMaterialId == materials[index].Id)
                            }
                            placeholder={"Specification"}
                            value={item.specification}
                            onChange={(value) => {
                              materials[index].specification = value;
                              setMaterials([...materials]);
                            }}
                          />
                          {editableMaterialId == materials[index].Id ? (
                            <FiSave
                              size={33}
                              style={{
                                marginTop: "15px",
                                cursor: "pointer",
                                color: "grey",
                              }}
                              onClick={() => {
                                updateSuggestiveTextMaterial(
                                  materials[index].Id,
                                  materials[index].category,
                                  materials[index].specification,
                                  materials[index].rate,
                                  materials[index].unit,
                                  materials[index].gst
                                );
                                setEditableMaterialId("");
                                record(
                                  TAGS[
                                    ("@suggestive_text_save_material_icon_click_success",
                                    {
                                      description:
                                        "Suggestive text save material icon click success",
                                    })
                                  ]
                                );
                              }}
                            />
                          ) : (
                            <FiEdit2
                              size={30}
                              style={{
                                marginTop: "15px",
                                cursor: "pointer",
                                color: "grey",
                              }}
                              onClick={() => {
                                setEditableMaterialId(materials[index].Id);
                                record(
                                  TAGS[
                                    ("@suggestive_text_edit_material_icon_click_success",
                                    {
                                      description:
                                        "Suggestive text edit material icon click success",
                                    })
                                  ]
                                );
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            gap: "10px",
                            marginTop: "20px",
                          }}
                        >
                          <Input
                            disabled={
                              !(editableMaterialId == materials[index].Id)
                            }
                            placeholder={"Rate"}
                            value={item.rate}
                            onChange={(value) => {
                              materials[index].rate = value.replace(
                                /[^0-9]+/gm,
                                ""
                              );
                              setMaterials([...materials]);
                            }}
                          />
                          <Input
                            disabled={
                              !(editableMaterialId == materials[index].Id)
                            }
                            placeholder={"Unit"}
                            value={item.unit}
                            onChange={(value) => {
                              materials[index].unit = value;
                              setMaterials([...materials]);
                            }}
                          />
                          <Input
                            disabled={
                              !(editableMaterialId == materials[index].Id)
                            }
                            placeholder={"GST"}
                            value={item.gst}
                            onChange={(value) => {
                              if (value <= 100) {
                                materials[index].gst = value.replace(
                                  /[^0-9]+/gm,
                                  ""
                                );
                              }
                              setMaterials([...materials]);
                            }}
                          />
                          <FiTrash
                            size={35}
                            style={{
                              marginTop: "15px",
                              cursor: "pointer",
                              color: "grey",
                            }}
                            onClick={() => {
                              deleteSuggestiveTextMaterial(materials[index].Id);
                              record(
                                TAGS[
                                  ("@suggestive_text_delete_material_icon_click_success",
                                  {
                                    description:
                                      "Suggestive text delete material icon click success",
                                  })
                                ]
                              );
                            }}
                          />
                        </div>
                      </div>
                    )
                )}
            </div>
          ) : showWork ? (
            <div
              style={{ width: "600px", margin: "20px", paddingBottom: "40px" }}
            >
              <div id="search">
                <AiOutlineSearch color="rgb(53, 53, 53)" size="25" />
                <input
                  placeholder="Search by works description"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setWorks(
                      works.filter((item) =>
                        item.description
                          .toLowerCase()
                          .includes(e.currentTarget.value.toLowerCase())
                      )
                    );
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      {isWorkWithMaterial ? "Add material" : "Only Work"}
                    </p>
                    <Switch
                      checked={isWorkWithMaterial}
                      onChange={() =>
                        setIsWorkWithMaterial(!isWorkWithMaterial)
                      }
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                  <div>
                    <p>
                      {isWorkWithMaterial
                        ? "Work with material"
                        : "Material not required"}
                    </p>
                  </div>
                </div>

                <div style={{ width: "60%" }}>
                  {categories && (
                    <DropDown
                      data={
                        categories &&
                        categories.vendorCategories.map((item) => item.Category)
                      }
                      value={selectedWorkCategory}
                      onChange={(value) => {
                        setSelectedWorkCategory(value);
                        setSelectedWorkDescription("");
                        setSelectedWorkRate("");
                        setSelectedWorkUnit("");
                        setSelectedWorkGST("");
                      }}
                    />
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "20px 0px",
                  gap: "10px",
                }}
              >
                <TextArea
                  placeholder={"Description"}
                  value={selectedWorkDescription}
                  onChange={(value) => setSelectedWorkDescription(value)}
                />
                <div
                  style={{
                    width: "70px",
                    height: "50px",
                    backgroundColor: "#fdd34d",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (
                      selectedWorkCategory.length <= 0 ||
                      selectedWorkType.length <= 0 ||
                      selectedWorkDescription.length <= 0 ||
                      selectedWorkRate.length <= 0 ||
                      selectedWorkUnit.length <= 0 ||
                      selectedWorkGST.length <= 0
                    ) {
                      toast.error("Please fill all the details!", {
                        backgroundColor: "rgb(255, 211, 77)",
                        color: "black",
                      });
                    } else {
                      if (works.length > 0) {
                        let isSpecEqual = works.map((item) =>
                          item.description.localeCompare(
                            selectedWorkDescription
                          )
                        );
                        isSpecEqual.every((x) => x != 0)
                          ? addSuggestiveTextWork(
                              selectedWorkCategory,
                              selectedWorkType,
                              selectedWorkDescription,
                              selectedWorkRate,
                              selectedWorkUnit,
                              selectedWorkGST
                            )
                          : toast.error(
                              "Cannot add same description suggestion!",
                              {
                                backgroundColor: "rgb(255, 211, 77)",
                                color: "black",
                              }
                            );
                      } else {
                        addSuggestiveTextWork(
                          selectedWorkCategory,
                          selectedWorkType,
                          selectedWorkDescription,
                          selectedWorkRate,
                          selectedWorkUnit,
                          selectedWorkGST
                        );
                      }
                    }
                  }}
                >
                  <FiPlus size={40} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Input
                  placeholder={"Rate"}
                  value={selectedWorkRate}
                  onChange={(value) =>
                    setSelectedWorkRate(value.replace(/[^0-9]+/gm, ""))
                  }
                />
                <Input
                  placeholder={"Unit"}
                  value={selectedWorkUnit}
                  onChange={(value) => setSelectedWorkUnit(value)}
                />
                <Input
                  placeholder={"GST"}
                  value={selectedWorkGST}
                  onChange={(value) =>
                    value <= 100 &&
                    setSelectedWorkGST(value.replace(/[^0-9]+/gm, ""))
                  }
                />
              </div>

              {works &&
                works.map(
                  (item, index) =>
                    item.category == selectedWorkCategory && (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "40px 0px 20px 0px",
                          }}
                        >
                          <TextArea
                            disabled={!(editableWorkId == works[index].Id)}
                            placeholder={"Description"}
                            value={item.description}
                            onChange={(value) => {
                              works[index].description = value;
                              setWorks([...works]);
                            }}
                          />
                          {editableWorkId == works[index].Id ? (
                            <FiSave
                              size={33}
                              style={{
                                marginTop: "15px",
                                cursor: "pointer",
                                color: "grey",
                              }}
                              onClick={() => {
                                updateSuggestiveTextWork(
                                  works[index].Id,
                                  works[index].category,
                                  works[index].workType,
                                  works[index].description,
                                  works[index].rate,
                                  works[index].unit,
                                  works[index].gst
                                );
                                setEditableWorkId("");
                                record(
                                  TAGS[
                                    ("@suggestive_text_save_work_icon_click_success",
                                    {
                                      description:
                                        "Suggestive text save work icon click success",
                                    })
                                  ]
                                );
                              }}
                            />
                          ) : (
                            <FiEdit2
                              size={30}
                              style={{
                                marginTop: "15px",
                                cursor: "pointer",
                                color: "grey",
                              }}
                              onClick={() => {
                                setEditableWorkId(works[index].Id);
                                record(
                                  TAGS[
                                    ("@suggestive_text_edit_work_icon_click_success",
                                    {
                                      description:
                                        "Suggestive text edit work icon click success",
                                    })
                                  ]
                                );
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Input
                            disabled={!(editableWorkId == works[index].Id)}
                            placeholder={"Rate"}
                            value={item.rate}
                            onChange={(value) => {
                              works[index].rate = value.replace(
                                /[^0-9]+/gm,
                                ""
                              );
                              setWorks([...works]);
                            }}
                          />
                          <Input
                            disabled={!(editableWorkId == works[index].Id)}
                            placeholder={"Unit"}
                            value={item.unit}
                            onChange={(value) => {
                              works[index].unit = value;
                              setWorks([...works]);
                            }}
                          />
                          <Input
                            disabled={!(editableWorkId == works[index].Id)}
                            placeholder={"GST"}
                            value={item.gst}
                            onChange={(value) => {
                              if (value <= 100) {
                                works[index].gst = value.replace(
                                  /[^0-9]+/gm,
                                  ""
                                );
                              }
                              setWorks([...works]);
                            }}
                          />
                          <FiTrash
                            size={30}
                            style={{
                              marginTop: "15px",
                              cursor: "pointer",
                              color: "grey",
                            }}
                            onClick={() => {
                              deleteSuggestiveTextWork(works[index].Id);
                              record(
                                TAGS[
                                  ("@suggestive_text_delete_work_icon_click_success",
                                  {
                                    description:
                                      "Suggestive text delete work icon click success",
                                  })
                                ]
                              );
                            }}
                          />
                        </div>
                      </div>
                    )
                )}
            </div>
          ) : showPO ? (
            <div
              style={{ width: "600px", margin: "20px", paddingBottom: "40px" }}
            >
              <div id="search">
                <AiOutlineSearch color="rgb(53, 53, 53)" size="25" />
                <input
                  placeholder="Search by contact person name"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setPO(
                      po.filter((item) =>
                        item.ContactPersonName.toLowerCase().includes(
                          e.currentTarget.value.toLowerCase()
                        )
                      )
                    );
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  gap: "10px",
                }}
              >
                <Input
                  placeholder={"Contact Person Name"}
                  value={selectedContactPersonName}
                  onChange={(value) => setSelectedContactPersonName(value)}
                />
                <Input
                  placeholder={"Contact Person Number"}
                  value={selectedContactPersonNumber}
                  onChange={(value) =>
                    setSelectedContactPersonNumber(
                      value.replace(/[^0-9]+/gm, "")
                    )
                  }
                />
                <div
                  style={{
                    width: "70px",
                    height: "50px",
                    backgroundColor: "#fdd34d",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (
                      selectedContactPersonName.length <= 0 ||
                      selectedContactPersonNumber.length <= 0 ||
                      selectedTC.length <= 0 ||
                      selectedSI.length <= 0 ||
                      selectedBillingAddress.length <= 0
                    ) {
                      toast.error("Please fill all the details!", {
                        backgroundColor: "rgb(255, 211, 77)",
                        color: "black",
                      });
                    } else {
                      if (po.length > 0) {
                        let isSpecEqual = po.map((item) =>
                          item.ContactPersonName.localeCompare(
                            selectedContactPersonName
                          )
                        );
                        isSpecEqual.every((x) => x != 0)
                          ? addSuggestiveTextPO(
                              selectedContactPersonName,
                              selectedContactPersonNumber,
                              selectedTC,
                              selectedSI,
                              selectedBillingAddress
                            )
                          : toast.error(
                              "Cannot add same contact person name suggestion!",
                              {
                                backgroundColor: "rgb(255, 211, 77)",
                                color: "black",
                              }
                            );
                      } else {
                        addSuggestiveTextPO(
                          selectedContactPersonName,
                          selectedContactPersonNumber,
                          selectedTC,
                          selectedSI,
                          selectedBillingAddress
                        );
                      }
                    }
                  }}
                >
                  <FiPlus size={40} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  gap: "20px",
                }}
              >
                <TextArea
                  placeholder={"Terms and Conditions"}
                  value={selectedTC}
                  onChange={(value) => setSelectedTC(value)}
                />
                <TextArea
                  placeholder={"Special Instructions"}
                  value={selectedSI}
                  onChange={(value) => setSelectedSI(value)}
                />
                <TextArea
                  placeholder={"Billing Address"}
                  value={selectedBillingAddress}
                  onChange={(value) => setSelectedBillingAddress(value)}
                />
              </div>

              {po &&
                po.map((item, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "40px",
                        gap: "10px",
                      }}
                    >
                      <Input
                        disabled={!(editablePOId == po[index].Id)}
                        placeholder={"Contact Person Name"}
                        value={item.ContactPersonName}
                        onChange={(value) => {
                          po[index].ContactPersonName = value;
                          setPO([...po]);
                        }}
                      />
                      <Input
                        disabled={!(editablePOId == po[index].Id)}
                        placeholder={"Contact Person Number"}
                        value={item.ContactPersonNumber}
                        onChange={(value) => {
                          po[index].ContactPersonNumber = value.replace(
                            /[^0-9]+/gm,
                            ""
                          );
                          setPO([...po]);
                        }}
                      />
                      {editablePOId == po[index].Id ? (
                        <FiSave
                          size={33}
                          style={{
                            marginTop: "15px",
                            cursor: "pointer",
                            color: "grey",
                          }}
                          onClick={() => {
                            updateSuggestiveTextPO(
                              po[index].Id,
                              po[index].ContactPersonName,
                              po[index].ContactPersonNumber,
                              po[index].TermsandConditions,
                              po[index].SpecialInstructions,
                              po[index].BillingAddress
                            );
                            setEditablePOId("");
                            record(
                              TAGS[
                                ("@suggestive_text_save_po_icon_click_success",
                                {
                                  description:
                                    "Suggestive text save po icon click success",
                                })
                              ]
                            );
                          }}
                        />
                      ) : (
                        <FiEdit2
                          size={30}
                          style={{
                            marginTop: "15px",
                            cursor: "pointer",
                            color: "grey",
                          }}
                          onClick={() => {
                            setEditablePOId(po[index].Id);
                            record(
                              TAGS[
                                ("@suggestive_text_edit_po_icon_click_success",
                                {
                                  description:
                                    "Suggestive text edit po icon click success",
                                })
                              ]
                            );
                          }}
                        />
                      )}
                      <FiTrash
                        size={30}
                        style={{
                          marginTop: "15px",
                          cursor: "pointer",
                          color: "grey",
                        }}
                        onClick={() => {
                          deleteSuggestiveTextPO(po[index].Id);
                          record(
                            TAGS[
                              ("@suggestive_text_delete_po_icon_click_success",
                              {
                                description:
                                  "Suggestive text delete po icon click success",
                              })
                            ]
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        gap: "20px",
                      }}
                    >
                      <TextArea
                        disabled={!(editablePOId == po[index].Id)}
                        placeholder={"Terms and Conditions"}
                        value={item.TermsandConditions}
                        onChange={(value) => {
                          po[index].TermsandConditions = value;
                          setPO([...po]);
                        }}
                      />
                      <TextArea
                        disabled={!(editablePOId == po[index].Id)}
                        placeholder={"Special Instructions"}
                        value={item.SpecialInstructions}
                        onChange={(value) => {
                          po[index].SpecialInstructions = value;
                          setPO([...po]);
                        }}
                        hideRedLine
                      />
                      <TextArea
                        disabled={!(editablePOId == po[index].Id)}
                        placeholder={"Billing Address"}
                        value={item.BillingAddress}
                        onChange={(value) => {
                          po[index].BillingAddress = value;
                          setPO([...po]);
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          ) : showFirm ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "600px",
                margin: "20px",
                paddingBottom: "40px",
                gap: 20,
              }}
            >
              <TextArea
                placeholder={"Address"}
                value={firmAddress}
                onChange={(value) => setFirmAddress(value)}
              />
              <Input
                placeholder={"Phone Number"}
                value={firmPhoneNumber}
                onChange={(value) =>
                  setFirmPhoneNumber(value.replace(/[^0-9]+/gm, ""))
                }
              />
              <Input
                placeholder={"GSTIN"}
                value={firmGSTIN}
                onChange={(value) => setFirmGSTIN(value)}
              />
              <Input
                placeholder={"Contact Person Name"}
                value={firmContactPersonName}
                onChange={(value) => setFirmContactPersonName(value)}
              />
              <Input
                placeholder={"Contact Person Number"}
                value={firmContactPersonNumber}
                onChange={(value) =>
                  setFirmContactPersonNumber(value.replace(/[^0-9]+/gm, ""))
                }
              />
              <Input
                placeholder={"Contact Person Email"}
                value={firmContactPersonEmail}
                onChange={(value) => setFirmContactPersonEmail(value)}
              />

              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  fileDialog().then(async (files) => {
                    for (let i = 0; i < files.length; i++) {
                      const response = await firebase
                        .storage()
                        .ref(
                          "/WebUploads/" + new Date().getTime() + files[i].name
                        )
                        .put(files[i]);
                      response.ref
                        .getDownloadURL()
                        .then((url) => {
                          setFirmSignature({ name: files[i].name, url: url });
                        })
                        .catch((e) => {
                          toast.error("Error uploading attachment!", {
                            backgroundColor: "red",
                            color: "white",
                          });
                        });
                    }
                  });
                }}
              >
                <FiPaperclip size={15} style={{ marginRight: 10 }} />
                Upload signature
              </p>

              {firmSignature == "" ? null : (
                <a href={firmSignature.url} target="_blank">
                  {firmSignature.name}
                </a>
              )}

              <div
                style={{
                  width: "600px",
                  height: "50px",
                  backgroundColor: "#fdd34d",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (
                    firmAddress.length <= 0 ||
                    firmPhoneNumber.length <= 0 ||
                    firmGSTIN.length <= 0 ||
                    firmContactPersonName.length <= 0 ||
                    firmContactPersonNumber.length <= 0 ||
                    firmContactPersonEmail.length <= 0 ||
                    firmSignature == ""
                  ) {
                    toast.error("Please fill all the details!", {
                      backgroundColor: "rgb(255, 211, 77)",
                      color: "black",
                    });
                  } else {
                    if (firmDetailsId.length <= 0) {
                      addSuggestiveTextFirm(
                        firmAddress,
                        firmPhoneNumber,
                        firmGSTIN,
                        firmContactPersonName,
                        firmContactPersonNumber,
                        firmContactPersonEmail,
                        firmSignature
                      );
                    } else {
                      updateSuggestiveTextFirm(
                        firmAddress,
                        firmPhoneNumber,
                        firmGSTIN,
                        firmContactPersonName,
                        firmContactPersonNumber,
                        firmContactPersonEmail,
                        firmSignature
                      );
                    }
                  }
                }}
              >
                SAVE
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default DrawerTemplate(SuggestiveItems);
