import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import Legend from "./Legend";
import Header from "./Header";
import AdditionalInfoRow from "./Components/AdditionalInfoRow";

const Summary = ({ items, onDeleteItem }) => {
  return (
    <div style={styles.summaryContainer}>
      <Header items={items} />
      <div style={styles.tableWrapper}>
        <table style={styles.table}>
          <thead style={styles.tableHeader}>
            <tr>
              <th style={{ ...styles.th, width: "30%" }}>Category</th>
              <th style={{ ...styles.th, width: "50%" }}>Description</th>
              <th style={{ ...styles.th, width: "20%", alignSelf: 'flex-end' }}>Quantity</th>
            </tr>
          </thead>
        </table>
        <div style={styles.scrollableBody}>
          <table style={styles.table}>
            <tbody>
              {items.length === 0 ? (
                <tr>
                  <td colSpan="3" style={styles.noEntryCell}>
                    <div style={styles.noEntryMessage}>
                      <p>
                        No items added. Please add items from the <br /> entry
                        section on the right <br /> to view entries here.
                      </p>
                    </div>
                  </td>
                </tr>
              ) : (
                items.map((item, index) =>
                  item.hasOwnProperty("inTime") ? (
                    <tr key={index} style={{ marginTop: 10 }}>
                      <td colSpan="3" style={{ padding: 0 }}>
                        <AdditionalInfoRow additionalInfo={item} />
                      </td>
                    </tr>
                  ) : (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: item.unsaved ? "#ffebeb" : "#e8f5e9",
                      }}
                    >
                      <td style={{ ...styles.td, ...styles.categoryCell }}>
                        {item.category}
                      </td>
                      <td style={{ ...styles.td, ...styles.descriptionCell }}>
                        {item.description}
                      </td>
                      <td style={{ ...styles.td, ...styles.quantityCell }}>
                        {item.quantityAccepted} {item.unit}
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const styles = {
  summaryContainer: {
    width: "30%",
    maxWidth: "30%",
    padding: "20px",
    backgroundColor: "#f7f7f7",
    height: "100%",
    borderRight: "1px solid #ddd",
    borderRadius: "12px",
    maxHeight: "90vh", // Prevent exceeding the height of modalContainer
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  tableWrapper: {
    height: "100%", // Full height of the container
    display: "flex",
    flexDirection: "column",
  },
  tableHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#f7f7f7", // Matches the container background
  },
  scrollableBody: {
    overflowY: "auto", // Enable vertical scrolling
    flexGrow: 1, // Allow the body to take up remaining space
    maxHeight: "calc(100% - 40px)", // Ensure it fits within the modal's height
    paddingBottom: "10px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed", // Ensures consistent column widths
  },
  th: {
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    fontSize: "14px",
    borderBottom: "1px solid #ddd",
  },
  td: {
    padding: "8px",
    borderBottom: "1px solid #ddd",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  rowWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    borderBottom: "1px solid #ddd",
  },
  categoryCell: {
    width: "30%",
    textAlign: "left",
  },
  descriptionCell: {
    width: "50%",
    textAlign: "left",
  },
  quantityCell: {
    width: "20%",
    textAlign: "right",
  },
  noEntryCell: {
    padding: "20px 10px",
  },
  noEntryMessage: {
    display: "block",
    width: "100%",
    fontSize: "14px",
    color: "#666",
    fontWeight: "500",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    borderRadius: "6px",
    border: "1px solid #ddd",
    padding: "15px",
    lineHeight: "1.4",
    wordWrap: "break-word",
  },
};

export default Summary;
