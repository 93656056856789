import config from "../config/config";

class DraftService {
  
  async getDraft(projectId) {
    try {
      const response = await (
        await fetch(`${config.draftService}getDraft/ByProjectId/${projectId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
      ).json();
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }
  async getDraftByDraftId(draftId) {
    try {
      const response = await (
        await fetch(`${config.draftService}getDraft/ByDraftId/${draftId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
      ).json();
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async updateDraft(
    projectId,
    draftId,
    orderType,
    selectedItems,
    commonMilestones,
    addedItems,
    activeCategory,
    contactPersonName,
    contactPersonNumber,
    siteEngineerName,
    siteEngineerNumber,
    discount,
    startDate,
    endDate,
    vendorMobile,
    vendorFirm,
    vendorGSTIN,
    vendorAddress,
    vendorPinCode,
    vendorCity,
    vendorState,
    termsAndConditions,
    paymentTerms,
    specialInstructions,
    priceBase,
    freight,
    insurance,
    shippingAddress,
    billingAddress,
    attachments
  ) {
    try {
     

      const response = await (
        await fetch(`${config.draftService}updateDraft/${draftId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            projectId,
            draftId,
            orderType,
            selectedItems,
            commonMilestones,
            addedItems,
            activeCategory,
            contactPersonName,
            contactPersonNumber,
            siteEngineerName,
            siteEngineerNumber,
            discount,
            startDate,
            endDate,
            vendorMobile,
            vendorFirm,
            vendorGSTIN,
            vendorAddress,
            vendorPinCode,
            vendorCity,
            vendorState,
            termsAndConditions,
            paymentTerms,
            specialInstructions,
            priceBase,
            freight,
            insurance,
            shippingAddress,
            billingAddress,
            attachments,
          }),
        })
      ).json();
      console.log("added item are1", addedItems)
      return response;
    } catch (e) {
      console.log(e);

      return { status: 400, statusMsg: e };
    }
  }

  async postDraft(
    projectId,
    draftId,
    orderType,
    selectedItems,
    commonMilestones,
    addedItems,
    activeCategory,
    contactPersonName,
    contactPersonNumber,
    siteEngineerName,
    siteEngineerNumber,
    discount,
    startDate,
    endDate,
    vendorMobile,
    vendorFirm,
    vendorGSTIN,
    vendorAddress,
    vendorPinCode,
    vendorCity,
    vendorState,
    termsAndConditions,
    paymentTerms,
    specialInstructions,
    priceBase,
    freight,
    insurance,
    shippingAddress,
    billingAddress,
    attachments
  ) {
    try {
     

      const response = await (
        await fetch(`${config.draftService}draft/projectId`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            projectId,
            draftId,
            orderType,
            selectedItems,
            commonMilestones,
            addedItems,
            activeCategory,
            contactPersonName,
            contactPersonNumber,
            siteEngineerName,
            siteEngineerNumber,
            discount,
            startDate,
            endDate,
            vendorMobile,
            vendorFirm,
            vendorGSTIN,
            vendorAddress,
            vendorPinCode,
            vendorCity,
            vendorState,
            termsAndConditions,
            paymentTerms,
            specialInstructions,
            priceBase,
            freight,
            insurance,
            shippingAddress,
            billingAddress,
            attachments,
          }),
        })
      ).json();

       console.log("added item are2", addedItems) 
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async deleteDraft(draftId) {
    try {
      let response = await fetch(
        `${config.draftService}deleteDraft/${draftId}`,
        {
          method: "DELETE",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      return { ...response };
    } catch (e) {
      console.log(`deleteDraft catch `, e);
      return {
        status: 400,
        statusMsg: "Error while deleting draft!",
        payload: e,
      };
    }
  }

}

export default DraftService;
