import { useEffect, useState, useContext } from "react";
import "./index.scss";
import { Tab, Tabs as NewTab, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DrawerTemplate from "../../hoc/drawerTemplate";
import AnalyticsService from "../../api/analyticsService";
import AddVendor from "../AuthPage/signUpVendor";
import AddSiteEngineer from "../AuthPage/signUpSiteEngineer";
import { AiOutlineSearch } from "react-icons/ai";
import { UserContext } from "../../Context/UserContext";
import AuthService from "../../api/authService";
import AddAdminUsers from "../AuthPage/adminSignup";
import { Tabs } from "../../components";
import AddMaterialCategory from "./materialCategories/index.js";
import AddVendorCategories from "./VendorCategories/index.js";
import config from "../../config/config";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { AiOutlineEdit } from "react-icons/ai";
import Dialog from "@material-ui/core/Dialog";
import Form from "../AuthPage/signUpVendor/Form";
import AssignProjects from "./AssignProjects";
import ProjectService from "../../api/projectService";
import toast from "react-hot-toast";
import { CircularProgress } from "@material-ui/core";
import { AiOutlineEye } from "react-icons/ai";
import VendorAccStatement from "./VendorAccStatement";
import BrandsEdit from "./BrandsEdit";

// function compareStrings(a,b){
//   a.Role == "Only Material"? a.Category.replace('("', "")
//                                 .replace(/"/g, "")
//                                 .replace(', "', ", ")
//                                 .replace(")", "")
//                         : a.Category
// }

const AnalyticsPage = (onAuthChanged) => {
  const [activeTab, setActiveTab] = useState("Vendors");
  const [vendors, setVendors] = useState({});
  const [vendorsToDisplay, setVendorstoDisplay] = useState({});
  const [adminUsers, setAdminUsers] = useState([]);
  const [siteEngineers, setSiteEngineers] = useState([]);
  const [search, setSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const analyticsService = new AnalyticsService();
  const userContext = useContext(UserContext);
  const [workMaterialCategories, setWorkMaterialCategories] = useState([]);
  const [materialCategories, setMaterialCategories] = useState([]);
  const [edit, setEdit] = useState("");
  const [open, setOpen] = useState(false);
  const [materialCategoriesUpdate, setMaterialCategoriesUpdate] = useState();
  const [vendorCategoriesUpdate, setVendorCategoriesUpdate] = useState([]);
  const [edit1, setEdit1] = useState("");
  const [vendorEditHandler, setVendorEditHandler] = useState(false);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState();
  const [selectedProjectAssingnedDetials, setSelectedProjectAssingnedDetials] =
    useState();
  const [isLoading, setLoading] = useState(false);
  const [editBrandsofVendorOrMaterial, setEditBrandsofVendorOrMaterial] =
    useState({ isModalOpen: false, data: {} });

  const [showAssignProjectsModal, setShowAssignProjectsModal] = useState(false);

  const [showVendorStatement, setShowVendorStatement] = useState(false); // for vendor accStatement

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [clientNames, setClientNames] = useState([]);
  useEffect(async () => {
    if (activeTab === "Admin Users") {
      const projectService = new ProjectService();

      const res = await projectService.getClientNames();
      setClientNames(res.payload);
      // console.log('client Names',res.payload)
    }
  }, [activeTab]);

  useEffect(() => {
    getVendors();
  }, []);

  const categorizeVendors = (vendorsArr) => {
    // console.log('vendors arr',vendorsArr);
    const sorted = vendorsArr.sort((a, b) =>
      a.Category.toLowerCase() < b.Category.toLowerCase()
        ? -1
        : b.Category.toLowerCase() > a.Category.toLowerCase()
        ? 1
        : 0
    );

    const CategorizedList = sorted.reduce((newArr, item) => {
      const { Category } = item;
      if (!newArr[Category]) {
        newArr[Category] = [];
      }
      newArr[Category].push(item);
      return newArr;
    }, {});
    // console.log('Catef',CategorizedList);
    setVendors(CategorizedList);
  };

  const getVendors = () => {
    analyticsService
      .getFirmBasedAnalytics(localStorage.getItem("firm"))
      .then((res) => {
        console.log("Analytics response: ", res);
        if (res.status === 200) {
          categorizeVendors(res.Vendor);
          // setVendors(res.Vendor);
          setSiteEngineers(res.SiteEngineer);
          setAdminUsers(res.Admin);
        }
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };

  useEffect(() => {
    fetch(`${config.utilService}categories`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from fetchCategories API: ", json);
        if (json.status === 200) {
          setWorkMaterialCategories(json.vendorCategories);
          setMaterialCategories(json.materialCategories);
        }
      })
      .catch((e) => {
        console.log(e.toString());
        alert("Something went wrong!");
      });
  }, []);

  const onChange = (val) => {
    setSearch(val);
  };

  const onAdminChange = (
    email,
    firm,
    status,
    accountType,
    AssignedProjectstoUpdate,
    ContactNumber
  ) => {
    const authService = new AuthService();
    // console.log("in the index assigned projecst", AssignedProjectstoUpdate);
    // console.log("email and co to update : ", email, firm, status, accountType);
    authService
      .firmUpdate(
        email,
        firm,
        status,
        accountType,
        AssignedProjectstoUpdate,
        ContactNumber
      )
      .then((res) => {
        if (res.status == 200) {
          setAdminUsers((prv) => {
            return prv.map((rec) => {
              if (rec.email == email) {
                console.log("updating the things a", {
                  ...rec,
                  active: status,
                  accountType,
                  AssignedProjects: JSON.stringify(AssignedProjectstoUpdate),
                  ContactNumber: ContactNumber,
                });
                return {
                  ...rec,
                  active: status,
                  accountType,
                  AssignedProjects: JSON.stringify(AssignedProjectstoUpdate),
                  ContactNumber: ContactNumber,
                };
              }
              return rec;
            });
          });
          toast.success("Updated Successfully");
        } else {
          alert(res.statusMsg);
        }
      })
      .catch((error) => {
        alert("Somthing went wrong!");
      });
  };

  const updateMaterialCategories = (val, materialCategoriesUpdate) => {
    const authService = new AuthService();
    authService
      .updateMaterialCategories(val, materialCategoriesUpdate)
      .then((res) => {
        if (res.status == 200) {
          alert("Category updated!");
        } else {
          alert("Invalid category!");
        }
      })
      .catch((error) => {
        alert("Somthing went wrong!");
      });
  };

  const updateVendorCategories = (val1, vendorCategoriesUpdate) => {
    const authService = new AuthService();
    authService
      .updateVendorCategories(val1, vendorCategoriesUpdate)
      .then((res) => {
        if (res.status == 200) {
          alert("Category updated!");
        } else {
          alert("Invalid category!");
        }
      })
      .catch((error) => {
        alert("Somthing went wrong!");
      });
  };

  useEffect(() => {
    setLoading(true);
    if (search.length === 0 || !search) {
      setVendorstoDisplay(vendors);
    } else {
      const allKeys = Object.keys(vendors);
      let arraytoDisplay = allKeys.reduce((item, index) => {
        const tempItemarr = vendors[index].filter((vItem, vIndex) =>
          vItem.Name.toLowerCase().includes(search.toLowerCase())
        );
        if (tempItemarr.length > 0) {
          item[index] = tempItemarr;
        }
        return item;
      }, {});
      setVendorstoDisplay(arraytoDisplay);
    }
    setLoading(false);
  }, [search, vendors]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div id="AnalyticsPage">
      <h1>Users</h1>
      <Tabs
        tabs={
          userContext.user.accountType === "Owner"
            ? ["Vendors", "Site Engineers", "Admin Users", "Categories"]
            : ["Vendors", "Site Engineers", "Categories"]
        }
        activeTab={activeTab}
        onChange={(tab) => {
          setActiveTab(tab);
        }}>
        {activeTab === "Vendors" ? (
          <div>
            <div style={{ display: "flex", width: "100%" }}>
              <div className="vendor-search-category">
                <AiOutlineSearch color="rgb(53, 53, 53)" size="30" />
                <input
                  value={categorySearch}
                  onChange={(e) => setCategorySearch(e.currentTarget.value)}
                  placeholder="Search by Category"
                  style={{ width: "50%" }}
                />
              </div>
              <div id="searchVendor">
                <AiOutlineSearch color="rgb(53, 53, 53)" size="30" />
                <input
                  value={search}
                  onChange={(e) => onChange(e.currentTarget.value)}
                  placeholder="Search by Vendor Name"
                  style={{ width: "50%" }}
                />
              </div>
            </div>
            <AddVendor
              getIsVendorModalClosed={(itemReceived) => {
                itemReceived && getVendors();
              }}
            />

            <div className="vendor-table-container">
              {isLoading ? (
                <CircularProgress />
              ) : (
                Object.keys(vendorsToDisplay).map((item, index) => {
                  return item
                    .toLowerCase()
                    .includes(categorySearch.toLowerCase()) ? (
                    <div
                      className="vendor-table-individual-category"
                      key={index}>
                      {
                        <h3 className="vendor-table-category-heading">
                          {item.replace(/[()""]/g, "")}
                        </h3>
                      }
                      <table className="vendor-table-category-table">
                        <tbody>
                          <tr className="vendor-table-category-header-items">
                            <th>#</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>City</th>
                            <th>Status</th>
                            <th>Class</th>
                            <th>Edit</th>
                            <th>Acc Statement</th>
                          </tr>
                          {vendorsToDisplay[item].map((vItem, vIndex) => {
                            return vItem.Name.toLowerCase().includes(
                              search.toLowerCase()
                            ) ? (
                              <tr key={index + vIndex + 1}>
                                <td className="vItem-sNo">{vIndex + 1}</td>
                                <td className="vItem-name">{vItem.Name}</td>
                                <td className="vItem-mobile">{vItem.Mobile}</td>
                                <td className="vItem-email">{vItem.Email}</td>
                                <td className="vItem-role">{vItem.Role}</td>
                                <td className="vItem-city">{vItem.City}</td>
                                <td className="vItem-status">{vItem.Status}</td>
                                <td className="vItem-class">
                                  {vItem.Class == null ? "-" : vItem.Class}
                                </td>
                                <td
                                  className="vItem-edit"
                                  title="edit"
                                  style={{ cursor: "pointer" }}>
                                  <AiOutlineEdit
                                    size={25}
                                    style={{ color: "#454545" }}
                                    onClick={() => {
                                      setVendorEditHandler(true);
                                      setSelectedVendorDetails(vItem);
                                    }}
                                  />
                                </td>
                                <td className="vItem-acc-statement">
                                  <AiOutlineEye
                                    onClick={() => {
                                      setShowVendorStatement(true);
                                      setSelectedVendorDetails(vItem);
                                    }}
                                  />
                                </td>
                              </tr>
                            ) : null;
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null;
                })
              )}
            </div>
          </div>
        ) : activeTab === "Site Engineers" ? (
          <div>
            <div id="searchSE">
              <AiOutlineSearch color="rgb(53, 53, 53)" size="30" />
              <input
                value={search}
                onChange={(e) => onChange(e.currentTarget.value)}
                placeholder="Search by Site Engineer Name"
                style={{ width: "100%" }}
              />
            </div>
            <AddSiteEngineer
              setSiteEngineers={setSiteEngineers}
              siteEngineers={(val) => {
                val && getVendors();
              }}
            />
            <table id="table" style={{ minWidth: "75vw", marginTop: "20px" }}>
              <tr>
                <th>#</th>
                <th>Id</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
              </tr>
              {siteEngineers.map((val, index) => {
                return val.Name.toLowerCase().includes(search.toLowerCase()) ? (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{val.Id}</td>
                    <td>{val.Name}</td>
                    <td>{val.Mobile}</td>
                    <td>{val.Email}</td>
                  </tr>
                ) : null;
              })}
            </table>
          </div>
        ) : activeTab === "Admin Users" ? (
          <div>
            <AddAdminUsers setAdminUsers={setAdminUsers} />
            <table id="table" style={{ marginTop: "10px" }}>
              <tr>
                <th>Id</th>
                <th>Email</th>
                <th>Account Type</th>
                <th>Status</th>
                <th>Projects</th>
              </tr>
              {adminUsers.map((val, index) => {
                if (val.email === userContext.user.email) return;
                return (
                  <tr key={index}>
                    <td>{val.adminId}</td>
                    <td>{val.email}</td>
                    <td>
                      <select
                        value={val.accountType}
                        onChange={(event) =>
                          onAdminChange(
                            val.email,
                            val.firm,
                            val.active,
                            event.currentTarget.value,
                            JSON.parse(val.AssignedProjects),
                            val.ContactNumber
                          )
                        }>
                        <option>SELECT</option>
                        <option>Admin</option>
                        <option>Finance</option>
                        <option>Project</option>
                        <option>BOQ</option>
                      </select>
                    </td>
                    <td>
                      <select
                        value={
                          (val.active == 1) |
                          (val.active === "null") |
                          (val.active == null)
                            ? "Inactive"
                            : "Active"
                        }
                        onChange={(event) => {
                          const active = event.currentTarget.value;
                          onAdminChange(
                            val.email,
                            val.firm,
                            active == "Active" ? "0" : "1",
                            val.accountType,
                            JSON.parse(val.AssignedProjects),
                            val.ContactNumber
                          );
                        }}>
                        <option>Inactive</option>
                        <option>Active</option>
                      </select>
                    </td>
                    <td title="edit" style={{ cursor: "pointer" }}>
                      <AiOutlineEdit
                        size={25}
                        style={{ color: "#454545" }}
                        onClick={() => {
                          setShowAssignProjectsModal(true);
                          setSelectedProjectAssingnedDetials(val);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        ) : (
          <div>
            <NewTab Categories defaultIndex={1}>
              <TabPanel>
                <NewTab Categories>
                  <TabList style={{ activeTab: "red" }}>
                    <Tab>Material Categories</Tab>
                    <Tab>Work Categories</Tab>
                  </TabList>
                  <TabPanel>
                    <div id="Categories">
                      <AiOutlineSearch color="rgb(53, 53, 53)" size="30" />
                      <input
                        value={search}
                        onChange={(e) => onChange(e.currentTarget.value)}
                        placeholder="Search by Category"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <AddMaterialCategory
                        setMaterialCategories={setMaterialCategories}
                      />
                    </div>
                    <table
                      id="table"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}>
                      <tr style={{ width: "100%" }}>
                        <th>Category Name</th>
                        <th>Brands</th>
                        <th>Edit</th>
                      </tr>
                      {materialCategories.map((val, index) => {
                        return val?.name
                          ?.toLowerCase()
                          .includes(search.toLowerCase()) ? (
                          <tr key={index}>
                            <td
                              style={{ maxWidth: "30%" }}
                              onDoubleClick={(event) => {
                                event.currentTarget.value = val.name;
                                setEdit((event.currentTarget.value = val.name));
                              }}>
                              {val.name}
                            </td>
                            <td>{val.Brands && val.Brands}</td>
                            <td>
                              <AiOutlineEdit
                                size={25}
                                style={{ color: "#454545" }}
                                onClick={() => {
                                  setEditBrandsofVendorOrMaterial((prev) => {
                                    return {
                                      ...prev,
                                      isMaterialCategory: true,
                                      isModalOpen: true,
                                      data: val,
                                      dataIndex: index,
                                    };
                                  });
                                }}
                              />
                            </td>
                            {edit == val.name && (
                              <div>
                                <Button
                                  variant="contained"
                                  onClick={handleOpen}>
                                  edit
                                </Button>
                                <Modal
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description">
                                  <Box sx={style}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "15px",
                                      }}>
                                      <input
                                        Value={val.name}
                                        onChange={(eventName) => {
                                          console.log(
                                            "12344321",
                                            eventName.currentTarget.value
                                          );
                                          setMaterialCategoriesUpdate(
                                            eventName.currentTarget.value
                                          );
                                        }}></input>
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          updateMaterialCategories(
                                            val.id,
                                            materialCategoriesUpdate
                                          );
                                        }}>
                                        Update
                                      </Button>
                                    </div>
                                  </Box>
                                </Modal>
                              </div>
                            )}
                          </tr>
                        ) : null;
                      })}
                    </table>
                  </TabPanel>
                  <TabPanel>
                    <div id="Categories">
                      <AiOutlineSearch color="rgb(53, 53, 53)" size="30" />
                      <input
                        value={search}
                        onChange={(e) => onChange(e.currentTarget.value)}
                        placeholder="Search by vendorCategory"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <AddVendorCategories
                        setWorkMaterialCategories={setWorkMaterialCategories}
                      />
                    </div>
                    <table
                      id="table"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}>
                      <tr>
                        <th style={{ maxWidth: "30%" }}>Work Categories</th>
                        <th>Brands</th>
                        <th>Edit</th>
                      </tr>

                      {workMaterialCategories.map((val1, index) => {
                        return val1.Category.toLowerCase().includes(
                          search.toLowerCase()
                        ) ? (
                          <tr style={{ maxWidth: "30%" }} key={index}>
                            <td
                              onDoubleClick={(event) => {
                                event.currentTarget.value = val1.Category;
                                setEdit1(
                                  (event.currentTarget.value = val1.Category)
                                );
                              }}>
                              {val1.Category}
                            </td>
                            <td>{val1.Brands}</td>
                            <td>
                              <AiOutlineEdit
                                size={25}
                                style={{ color: "#454545" }}
                                onClick={() => {
                                  setEditBrandsofVendorOrMaterial((prev) => {
                                    return {
                                      ...prev,
                                      isMaterialCategory: false,
                                      isModalOpen: true,
                                      data: val1,
                                      dataIndex: index,
                                    };
                                  });
                                }}
                              />
                            </td>
                            {edit1 == val1 && (
                              <div>
                                <Button
                                  variant="contained"
                                  onClick={handleOpen}>
                                  edit
                                </Button>
                                <Modal
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description">
                                  <Box sx={style}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "15px",
                                      }}>
                                      <input
                                        Value={val1}
                                        onChange={(eventName) => {
                                          console.log(
                                            "12344321",
                                            eventName.currentTarget.value
                                          );
                                          setVendorCategoriesUpdate(
                                            eventName.currentTarget.value
                                          );
                                        }}></input>
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          // setMaterialCategoriesUpdate(val.name)
                                          updateVendorCategories(
                                            val1,
                                            vendorCategoriesUpdate
                                          );
                                        }}>
                                        Update
                                      </Button>
                                    </div>
                                  </Box>
                                </Modal>
                              </div>
                            )}
                          </tr>
                        ) : null;
                      })}
                    </table>
                  </TabPanel>
                </NewTab>
              </TabPanel>
            </NewTab>
          </div>
        )}
      </Tabs>
      {vendorEditHandler && (
        <Dialog
          open={vendorEditHandler}
          onClose={() => {
            setVendorEditHandler(false);
          }}
          fullWidth
          maxWidth="md">
          <Form
            handleClose={() => {
              getVendors();
              setVendorEditHandler(false);
            }}
            selectedVendorDetails={selectedVendorDetails}
          />
        </Dialog>
      )}
      <Dialog
        open={showAssignProjectsModal}
        onClose={() => setShowAssignProjectsModal(false)}
        maxWidth="xs"
        fullWidth={true}>
        <AssignProjects
          handleClose={() => {
            setShowAssignProjectsModal(false);
          }}
          selectedProjectAssingnedDetials={selectedProjectAssingnedDetials}
          options={clientNames}
          onAdminChange={onAdminChange}
        />
      </Dialog>
      {showVendorStatement && (
        <VendorAccStatement
          showVendorStatement={showVendorStatement}
          setShowVendorStatement={setShowVendorStatement}
          selectedVendorDetails={selectedVendorDetails}
        />
      )}

      {editBrandsofVendorOrMaterial.isModalOpen && (
        <BrandsEdit
          editBrandsofVendorOrMaterial={editBrandsofVendorOrMaterial}
          setEditBrandsofVendorOrMaterial={setEditBrandsofVendorOrMaterial}
          setMaterialCategories={setMaterialCategories}
          setWorkMaterialCategories={setWorkMaterialCategories}
        />
      )}
    </div>
  );
};
export default DrawerTemplate(AnalyticsPage);
