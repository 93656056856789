import React from 'react';
import './AdditionalInfoRow.css';

const AdditionalInfoRow = ({ additionalInfo }) => {
  const {
    invoiceNumber,
    invoiceDate,
    supplierVendor,
    poNumber,
    referralPONo,
    mrn,
    vehicleNo,
    gatePassNo,
    gatePassDate,
    dcNo,
    dcDate,
    inTime,
    outTime
  } = additionalInfo;

  return (
    <div className="additional-info-wrapper">
      <div className="info-grid">
        <div className="info-item">
          <span className="info-label">Invoice No:</span>
          <span className="info-value">{invoiceNumber || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Invoice Date:</span>
          <span className="info-value">{invoiceDate || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Supplier/Vendor:</span>
          <span className="info-value">{supplierVendor || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">PO Number:</span>
          <span className="info-value">{poNumber || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Referral PO No:</span>
          <span className="info-value">{referralPONo || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">MRN:</span>
          <span className="info-value">{mrn || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Vehicle No:</span>
          <span className="info-value">{vehicleNo || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Gate Pass No:</span>
          <span className="info-value">{gatePassNo || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Gate Pass Date:</span>
          <span className="info-value">{gatePassDate || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">DC No:</span>
          <span className="info-value">{dcNo || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">DC Date:</span>
          <span className="info-value">{dcDate || '-'}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Time:</span>
          <span className="info-value">{inTime && outTime ? `${inTime} - ${outTime}` : '-'}</span>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfoRow;