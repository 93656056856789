import config from "../config/config";

class AuthService {
  async login(email, password, Firm, fcmToken) {
    try {
      const response = await (
        await fetch(`${config.authService}admin/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            email,
            password,
            Firm,
            deviceDetails: { Platform: "web", fcmToken },
          }),
        })
      ).json();
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async adminSignup(
    email,
    password,
    accountType,
    Firm,
    userName,
    active,
    ContactNumber,
    id
  ) {
    try {
      const response = await (
        await fetch(`${config.authService}admin/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
            accountType: accountType,
            Firm: Firm,
            userName: userName,
            active: active,
            ContactNumber,
            id: id
          }),
        })
      ).json();
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }
  
  async updateAdmin(email, expireDate) {
    try {
      const response = await (
        await fetch(`${config.authService}admin/Update/"${email}"`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },

          body: JSON.stringify({
            expireDate,
          }),
        })
      ).json();
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async updateVendorCategories(OldCategory, NewCategory) {
    console.log("12345678", OldCategory);
    console.log("12345678", NewCategory);
    try {
      const response = await (
        await fetch(
          `${config.authService}update/VendorCategories/${OldCategory}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ NewCategory }),
          }
        )
      ).json();
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async updateMaterialCategories(id, name) {
    try {
      const response = await (
        await fetch(`${config.authService}update/MaterialCategories/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ name }),
        })
      ).json();
      console.log("12345", id);
      console.log("12345", name);
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async getAdminByEmail(email) {
    try {
      let response = await fetch(
        `${config.authService}get/admin/by/email/"${email}"`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      console.log(`Response getAdminByEmail API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching getAdminByEmail!",
        payload: e,
      };
    }
  }

  async firmUpdate(
    email,
    Firm,
    active,
    accountType,
    AssignedProjects,
    ContactNumber
  ) {
    const data = { email, Firm, active, ContactNumber };
    if (accountType) {
      data.accountType = accountType;
    }
    if (AssignedProjects) {
      data.AssignedProjects = AssignedProjects;
    }
    try {
      const response = await (
        await fetch(`${config.authService}admin/Update/"${email}"`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        })
      ).json();
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async  createNewFirm(id, name, url, owner) {
  try {
    const response = await (
      await fetch(`${config.authService}firm/registration`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id,
          name: name,
          url: url,
          owner: owner,
        }),
      })
    ).json();
    console.log(response);
    return response;
  } catch (e) {
    console.log(e);
    return { status: 400, statusMsg: e };
  }
}

  async vendorSignup(
    name,
    mobile,
    category,
    state,
    role,
    email,
    password,
    firm,
    emergencyContact,
    city,
    address,
    pincode,
    gstIn,
    companyName,
    VendorMilestones
  ) {
    try {
      const response = await (
        await fetch(`${config.authService}vendor/register`, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Name: name,
            Password: password.toString(),
            Mobile: mobile,
            AccountType: "Contractor",
            Role: role,
            Category: category,
            State: state,
            EmergencyContact: emergencyContact,
            Email: email,
            City: city,
            ContractorMobile: "0",
            Firm: firm,
            Address: address,
            PinCode: pincode,
            GSTIN: gstIn,
            Company: companyName,
            VendorMilestones,
          }),
        })
      ).json();

      console.log("Response from vendorSignup API: ", response);

      // Check if the response indicates a duplicate entry error
      if (response.status === 400 && response.statusMsg.includes("Duplicate entry")) {
        const updatedResponse = await this.updateFirmNameAndResendRequest(mobile, firm, name, password, category, state, role, email, city, address, pincode, gstIn, companyName, VendorMilestones);
        console.log("updated resposne is", updatedResponse)
        return updatedResponse;

      }
      console.log("Response from vendorSignup API2: ", response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async updateFirmNameAndResendRequest(mobile, firm, name, password, category, state, role, email, city, address, pincode, gstIn, companyName, VendorMilestones) {
    try {
      const updatedFirmResponse = await fetch(`${config.authService}updatedFirmName`, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Mobile: mobile,
          Firm: firm,
        }),
      });

      const updatedFirmData = await updatedFirmResponse.json();

      console.log("Updated firm name response: ", updatedFirmData);

      const resendResponse = await this.vendorSignup(name, mobile, category, state, role, email, password, updatedFirmData.updatedFirm, city, address, pincode, gstIn, companyName, VendorMilestones);

      return resendResponse;
    } catch (error) {
      console.log("Error updating firm name and resending request: ", error);
      return { status: 400, statusMsg: error };
    }
  }

  async siteEngineerRegister(id, name, password, mobile, email, firm) {
    try {
      const response = await (
        await fetch(`${config.authService}SiteEngineer/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            Id: id,
            Name: name,
            Password: password,
            Mobile: mobile,
            Email: email,
            Firm: firm,
          }),
        })
      ).json();
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async changePassword(Email, OldPassword, NewPassword) {
    try {
      const response = await (
        await fetch(`${config.authService}admin/ChangePassword`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            Email,
            OldPassword,
            NewPassword,
          }),
        })
      ).json();
      console.log("Response from changePassword API: ", response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async forgetPassword(password, email) {
    try {
      const response = await (
        await fetch(`${config.authService}admin/forgetPassword`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        })
      ).json();
      console.log("Response from forgetPassword  API: ", response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async getFirmByFirm(firmName) {
    try {
      let response = await fetch(
        `${config.authService}getFirm/By/${firmName}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      console.log(`Response getFirmByFirm API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching suggestive text!",
        payload: e,
      };
    }
  }

  async getUserForShare(firmName) {
    try {
      let response = await fetch(
        `${config.authService}getUserForShare/By/${firmName}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      console.log(`Response getUserForSearchbyFirm API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching UserDetails!",
        payload: e,
      };
    }
  }

  async getEmailByEmail(email) {
    try {
      let response = await fetch(`${config.authService}get/Email/By/${email}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      response = await response.json();
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching suggestive text!",
        payload: e,
      };
    }
  }

  async getSiteEngineerById(Id) {
    try {
      let response = await fetch(
        `${config.authService}getSiteEngineer/ById/${Id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      console.log(`Response getSiteEngineerById API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching getSiteEngineerById!",
        payload: e,
      };
    }
  }

  async addMaterialCategories(name) {
    try {
      const response = await (
        await fetch(`${config.authService}addMaterialCategories`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: name,
          }),
        })
      ).json();
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async addVendorCategories(Category) {
    try {
      const response = await (
        await fetch(`${config.authService}addVendorCategories`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            Category: Category,
          }),
        })
      ).json();
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async getMaterialCategories() {
    try {
      let response = await fetch(`${config.authService}getMaterialCategories`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      response = await response.json();
      console.log(`Response getMaterialCategories  API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching getMaterialCategories!",
        payload: e,
      };
    }
  }

  async getVendorCategories() {
    try {
      let response = await fetch(`${config.authService}getVendorCategories`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      response = await response.json();
      console.log(`Response getVendorCategories API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching getVendorCategories!",
        payload: e,
      };
    }
  }

  async getAllOrdersForVendorAccStatement(VendorMobile) {
    try {
      let response = await fetch(
        `${config.authService}getVendorAllOrdersForAccStatement/${VendorMobile}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching Orders For Vendor!",
        payload: e,
      };
    }
  }

  async UpdateMaterialCategoryBrands(CategoryName, Brands) {
    try {
      let response = await fetch(
        `${config.authService}materialCatergory/BrandsUpdate/${CategoryName}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Brands: Brands,
          }),
        }
      );
      response = await response.json();
      console.log(`Response UpdateBrands Material API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error Updating Brands!",
        payload: e,
      };
    }
  }

  async UpdateVendorCategoryBrands(CategoryName, Brands) {
    try {
      let response = await fetch(
        `${config.authService}vendorCategory/BrandsUpdate/${CategoryName}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Brands: Brands,
          }),
        }
      );
      response = await response.json();
      console.log(`Response UpdateBrands Vendor API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error Updating Brands!",
        payload: e,
      };
    }
  }

  async CheckFirmAvailability(firmName) {
    try {
      let response = await fetch(
        `${config.authService}checkFirmAvailability/${firmName}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      // console.log(`Response Check Availabiliy : `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error checking availability!",
        payload: e,
      };
    }
  }

  async updateFCMtokenofUser(email, fcmToken) {
    try {
      let response = await fetch(
        `${config.authService}updateFCMtoken/${email}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            deviceDetails: { Platform: "web", fcmToken },
          }),
        }
      );
      response = await response.json();
      // console.log(`Response Check Availabiliy : `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error Updating FCM token!",
        payload: e,
      };
    }
  }
}

export default AuthService;
