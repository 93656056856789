import { uploadImagesToFirebase } from "../uploadImagesToFirebase";

async function createInwardEntry(
  additionalInformation,
  itemsData,
  itemsImages,
  previewImages,
  userName
) {
  const isLocalURL = (url) =>
    url.startsWith("blob:") || url.startsWith("data:");

  // Helper function to get the correct URL or ID field
  const getImageUrlOrId = (image) => image.url || image.id; // Priority to 'url' if exists

  // // Updated createImageObject function
  const createImageObject = async (itemObject) => {
    let imageObject = {};
    let imageUploadQueue = []; // Queue for images to be uploaded
    let imageTimestamp = Date.now(); // Ensure unique timestamps

    for (let index = 0; index < itemObject.length; index++) {
      const image = itemObject[index];
      const imageUrlOrId = getImageUrlOrId(image); // Get the correct value (either url or id)

      if (isLocalURL(imageUrlOrId)) {
        imageUploadQueue.push({ id: image.id, file: image.file });
      } else {
        imageObject[imageTimestamp] = { url: imageUrlOrId }; // Already a Firebase URL
        imageTimestamp++;
      }
    }

    // Upload local images and map to their Firebase URLs
    if (imageUploadQueue.length > 0) {
      const uploadedURLs = await uploadImagesToFirebase(
        imageUploadQueue,
        "items"
      );
      uploadedURLs.forEach((url) => {
        imageObject[imageTimestamp] = { url };
        imageTimestamp++;
      });
    }
    console.log("createImageObject imageObject : ", imageObject);
    return imageObject;
  };

  // // Updated createBillImageObject function
  const createBillImageObject = async () => {
    let imageObject = {};
    let imageUploadQueue = []; // Queue for images to be uploaded
    let imageTimestamp = Date.now(); // Ensure unique timestamps

    for (let index = 0; index < previewImages.length; index++) {
      const image = previewImages[index];
      const imageUrlOrId = getImageUrlOrId(image); // Get the correct value (either url or id)

      if (isLocalURL(imageUrlOrId)) {
        imageUploadQueue.push({ id: image.id, file: image.file });
      } else {
        imageObject[imageTimestamp] = { url: imageUrlOrId }; // Already a Firebase URL
        imageTimestamp++;
      }
    }

    // Upload local images and map to their Firebase URLs
    if (imageUploadQueue.length > 0) {
      const uploadedURLs = await uploadImagesToFirebase(
        imageUploadQueue,
        "bills"
      );
      uploadedURLs.forEach((url) => {
        imageObject[imageTimestamp] = { url };
        imageTimestamp++;
      });
    }

    return imageObject;
  };

  const timestamp = new Date().getTime(); // Generate a unique timestamp for the entry

  // Constructing the fields object
  const fields = {
    "01": {
      name: "Referral PO No.",
      value: additionalInformation.referralPONo || "Not provided",
    },
    "02": {
      name: "Material Requisition Note (MRN)",
      value: additionalInformation.mrn || "Not provided",
    },
    "03": {
      name: "Vehicle No.",
      value: additionalInformation.vehicleNo || "Not provided",
    },
    "04": {
      "01": {
        name: "Invoice No.",
        value: additionalInformation.invoiceNumber || "Not provided",
      },
      "02": {
        name: "Invoice Date",
        value: additionalInformation.invoiceDate || "Not provided",
      },
    },
    "05": {
      "01": {
        name: "Gate Pass No.",
        value: additionalInformation.gatePassNo || "Not provided",
      },
      "02": {
        name: "Gate Pass Date",
        value: additionalInformation.gatePassDate || "Not provided",
      },
    },
    "06": {
      "01": {
        name: "DC No.",
        value: additionalInformation.dcNo || "Not provided",
      },
      "02": {
        name: "DC Date",
        value: additionalInformation.dcDate || "Not provided",
      },
    },
    "07": {
      name: "Supplier/Vendor",
      value: additionalInformation.supplierVendor || "Not provided",
    },
  };

  // Constructing the invoices object
  let itemCount = -1;

  // const invoiceItems = await itemsData.reduce(async (acc, item) => {
  //   itemCount = itemCount + 1;
  //   console.log('createInwardEntry itemCount : ', itemCount)
  //   let itemImageObject = await createImageObject(itemsImages[itemCount]);
  //   console.log('createInwardEntry item.id : ', item.id)
  //   acc[item.id] = {
  //     id: item.id,
  //     amount: "0",
  //     category: item.category || "Not provided",
  //     dateRequired: item.date || "Not provided",
  //     description: item.description || "Not provided",
  //     gst: "0",
  //     historyId: item.historyId || timestamp,
  //     images: itemImageObject || {},
  //     itemID: item.itemID,
  //     quantityAccepted: item.quantityAccepted || "0",
  //     quantityOrdered: "0",
  //     quantityReceived: "0",
  //     quantityRejected: item.quantityRejected || "0",
  //     quantityRequired: "0",
  //     rate: "0",
  //     remark: item.purpose || "None",
  //     uom: item.unit || "Not provided",
  //   };
  //   return acc;
  // }, {});

  let invoiceItems = {};

  for (const item of itemsData) {
    itemCount += 1;

    const itemImageObject = await createImageObject(itemsImages[itemCount]);

    invoiceItems[item.id] = {
      id: item.id,
      amount: "0",
      category: item.category || "Not provided",
      dateRequired: item.date || "Not provided",
      description: item.description || "Not provided",
      gst: "0",
      historyId: item.historyId || timestamp,
      images: itemImageObject || {},
      itemID: item.itemID,
      quantityAccepted: item.quantityAccepted || "0",
      quantityOrdered: "0",
      quantityReceived: "0",
      quantityRejected: item.quantityRejected || "0",
      quantityRequired: "0",
      rate: "0",
      remark: item.purpose || "None",
      uom: item.unit || "Not provided",
    };
  }

  const invoices = {
    [timestamp]: {
      invoiceItems,
      invoiceNumber: additionalInformation.invoiceNumber || "Not provided",
      timeStamp: additionalInformation.invoiceDate || "Not provided",
      id: timestamp,
    },
  };

  // Constructing the request history
  const requestHistory = {
    ...(itemsData.requestHistory || {}), // Use an empty object if requestHistory is missing
    [timestamp]: {
      associatedPersons:
        itemsData.requestHistory?.[timestamp]?.associatedPersons || "",
      doneBy: userName,
      forwardedTo: itemsData.requestHistory?.[timestamp]?.forwardedTo || "",
      noOfItem: itemsData.length || 0, // Fallback to 0 if itemsData.length is missing
      remark: itemsData.requestHistory?.[timestamp]?.remark || "None",
      status: "Released",
      timestamp: timestamp,
    },
  };

  // Constructing the full inwardEntry object
  const inwardEntry = {
    fields,
    invoices,
    poNumber: additionalInformation.poNumber || "Not provided",
    referalPONumber: additionalInformation.referralPONo || "-",
    requestHistory,
    status: "Released",
    id: additionalInformation.id,
    vendorName: additionalInformation.supplierVendor || "Not provided",
    inTime: additionalInformation.inTime || "",
    outTime: additionalInformation.outTime || "",
    images: (await createBillImageObject()) || {},
    timeStamp: additionalInformation.id
  };

  return inwardEntry;
}

export default createInwardEntry;
