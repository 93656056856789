import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./dashboardPage.scss";

function TryOurAppsModal({ tryOurAppsModal, setTryOurAppsModal }) {
  const LinkedApps = [
    {
      AppName: "Staart Vendor App",
      qrLink:
        "https://firebasestorage.googleapis.com/v0/b/startstaging.appspot.com/o/app_qr_codes%2Fvendor.png?alt=media&token=659cd472-9f53-4c29-a679-47ede38f4d5f",
      loginDetails: { id: 99090, password: "1234" },
      Features: ["Contract Management", "Paymen updates", " "],
      for: "For Vendors",
    },
    {
      AppName: "Staart Site Update",
      qrLink:
        "https://firebasestorage.googleapis.com/v0/b/startstaging.appspot.com/o/app_qr_codes%2FStaart%20E.png?alt=media&token=e3a82430-b02a-4f5c-9b64-994975ff843e",
      loginDetails: { id: 99090, password: "1234" },
      Features: ["Site Updates", "Billing", "Work scope clarity"],
      for: "For Site Engineers",
    },
    {
      AppName: "Staart Client",
      qrLink:
        "https://firebasestorage.googleapis.com/v0/b/startstaging.appspot.com/o/app_qr_codes%2FInventory%20(1).png?alt=media&token=076c2a67-cd5b-4be5-ae32-7f8ef6dde9d5",
      loginDetails: { id: 99090, password: "1234" },
      Features: ["Site Updates", "Billing", "Payment Schedule"],
      for: "For Clients",
    },
  ];
  const freemiumApps = [
    {
      AppName: "Staart Works",
      qrLink:
        "https://firebasestorage.googleapis.com/v0/b/startstaging.appspot.com/o/app_qr_codes%2FStaart%20works_QR.png?alt=media&token=0b6b4072-1893-48f5-b05e-b9c2ce114f26",
      loginDetails: "Self Login",
      Features: ["Manage Expenses", "Chat", "Request Money"],
    },
    {
      AppName: "Staart Inventory",
      qrLink:
        "https://firebasestorage.googleapis.com/v0/b/startstaging.appspot.com/o/app_qr_codes%2FInventory%20(1).png?alt=media&token=076c2a67-cd5b-4be5-ae32-7f8ef6dde9d5",
      loginDetails: "Self Login",
      Features: ["Inward & Outward", "Net Inventory"],
    },
  ];

  return (
    <Modal
      open={tryOurAppsModal}
      onClose={() => {
        setTryOurAppsModal(false);
      }}
      // maxWidth="sm"

      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

      // fullWidth={true}
    >
      <Box
        // className="chat-box-approval"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          //   p: 4,
          width: "70%",
          height: "90%",
          //   maxWidth: "50%",
        }}
      >
        <div className="appsContainer">
          <div className="LinkedAppsContainer">
            <h2 style={{ textAlign: "center" }}>Linked Apps</h2>
            <div className="apps-list">
              {LinkedApps.map((app, index) => {
                return (
                  <div key={index}>
                    <img height="160px" src={app.qrLink} alt={app.AppName} />
                    <h3>{app.AppName}</h3>
                    <p>
                      <span style={{ fontWeight: "bold" }}>ID: </span>
                      {app.loginDetails.id}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Password: </span>
                      {app.loginDetails.password}
                    </p>
                    <div className="app-features-container">
                      {app.Features.map((feature, fIndex) => {
                        return <p key={fIndex}>{feature}</p>;
                      })}
                    </div>
                    <p style={{ fontWeight: "bold", paddingTop: "1em" }}>
                      {app.for}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="FreemiumAppsContainer">
            <h2 style={{ textAlign: "center" }}>Freemium Apps</h2>
            <div className="apps-list">
              {freemiumApps.map((app, index) => {
                return (
                  <div key={index}>
                    <img height="160px" src={app.qrLink} alt={app.AppName} />
                    <h3>{app.AppName}</h3>
                    <p style={{ paddingBottom: "20px" }}>{app.loginDetails}</p>
                    {/* <p>{}</p> */}
                    <div
                      style={{ paddingBottom: "20px" }}
                      className="app-features-container"
                    >
                      {app.Features.map((feature, fIndex) => {
                        return <p key={fIndex}>{feature}</p>;
                      })}
                      <p style={{ fontWeight: "bold", paddingTop: "1em" }}> </p>
                      {/* just to make them all same height */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default TryOurAppsModal;
