import React, { useState } from "react";

const SearchAndFilterComponent = ({
  allProjects,
  onItemSelection,
  loading,
  viewMaterialDetails,
  searchQuery,
  setSearchQuery,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleExcelExport,
  selectedTab,
}) => {
  const [hovered, setHovered] = useState(false);

  const styles = {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff",
      position: "relative",
      left: 0,
      right: 0,
    },
    dateLabelsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      padding: "0 20px",
      gap: "10px",
    },
    dateLabel: {
      fontSize: "14px",
      color: "#555",
      textAlign: "left",
      marginBottom: "5px",
    },
    searchContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "10px 20px",
      gap: "10px",
      backgroundColor: "#fff",
    },
    dropdown: {
      width: "200px",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ddd",
      fontSize: "16px",
      height: "42px",
    },
    searchInput: {
      flex: "2",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ddd",
      fontSize: "16px",
      height: "42px",
    },
    dateInput: {
      width: "200px",
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "1px solid #ddd",
      height: "42px",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#0066cc",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      fontSize: "16px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      height: "42px",
      whiteSpace: "nowrap",
    },
    buttonHover: {
      backgroundColor: "#0055bb",
    },
  };

  return (
    <div style={styles.container}>
      {selectedTab === "Inward" && <div style={styles.dateLabelsContainer}>
        <div style={{ flex: "0 0 200px" }}></div>
        {!viewMaterialDetails && <div style={{ flex: 2 }}></div>}
        <div style={{ width: "200px" }}>
          <div style={styles.dateLabel}>Start Date:</div>
        </div>
        <div style={{ width: "200px" }}>
          <div style={styles.dateLabel}>End Date:</div>
        </div>
        <div style={{ width: "120px" }}></div>
      </div>}
      <div style={styles.searchContainer}>
        <select
          style={styles.dropdown}
          onChange={(e) => onItemSelection(allProjects[e.target.value])}
          disabled={loading}
        >
          {allProjects.map((item, index) => (
            <option key={index} value={index}>
              {item.projectName}
            </option>
          ))}
          <option key={allProjects.length} value={"o"}>
            All Sites
          </option>
        </select>

        {!viewMaterialDetails && (
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={styles.searchInput}
            placeholder="Search for materials..."
          />
        )}

        {selectedTab !== "All Materials" && (
          <>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={styles.dateInput}
            />

            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={styles.dateInput}
            />

            <button
              onClick={handleExcelExport}
              style={{
                ...styles.button,
                ...(hovered && styles.buttonHover),
                ...{backgroundColor:  selectedTab === 'Inward' ? '#28a745' : '#dc3545'}
              }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              Export to Excel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchAndFilterComponent;
