import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import getWorksMaterials from "../../helpers/getWorksMaterials";
import config from "../../config/config";
import { toast } from "react-toast";
import { PopOverView, DropDownMUI } from "../../components";
// import {
//   FiChevronDown,
//   FiChevronUp,
//   FiInfo,
//   FiMoreVertical,
//   FiTruck,
// } from "react-icons/fi";
// import { FaCheck } from "react-icons/fa";
// import { GrUserWorker } from "react-icons/gr";
import { AiOutlineCheckCircle, AiOutlineSearch } from "react-icons/ai";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import ViewDetails from "./viewDetails";
import { useAnalyticsContext } from "../../Context/Analytics";
import OrderItem from "./OrderItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BiUpArrowAlt } from "react-icons/bi";

export default function Ongoing() {
  const location = useLocation();
  const { record } = useAnalyticsContext();

  const data = location.state.selectedProject;
  const [backToTop, setBackTotop] = useState(false);
  const [revision, setRevision] = useState(data.rooms);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All");
  const [materials, setMaterials] = useState([]);
  const [works, setWorks] = useState([]);
  // const [showStatus, setShowStatus] = useState([]);
  const [searchItems, setSearchItems] = useState("");
  const [workType, setWorkType] = useState("All");
  const [status, setStatus] = useState("All");
  const [isSpinner, setSpinner] = useState(true);
  const [allOrders, setAllOrders] = useState([]);
  // const [openBankDetailsModel, setOpenBankDetailsModel] = useState(false);
  // const [viewDetailsModal, setViewDetailsModal] = useState(false);
  // const [viewDetailsItem, setViewDetaisItem] = useState({});
  // const [bankAccountDetails, setBankAccountDetails] = useState({});

  useEffect(() => {
    if (data) {
      setRevision(data.rooms);
    }
  }, [data]);

  const backToTopHandler = () => {
    // document?.getElementById("btn")?.addEventListener("click", function () {
    document
      ?.getElementById("container")
      ?.scroll({ top: 0, behavior: "smooth" });
    // });
  };

  // useEffect(() => {
  //   document
  //     .getElementById("container")
  //     .addEventListener("scroll", function () {
  //       if (this.scrollTop === 0) {
  //         setBackTotop(false);
  //       } else {
  //         setBackTotop(true);
  //       }
  //     });
  // }, []);
  // useEffect(() => {
  //   backToTopHandler();
  // }, [activeCategory]);

  useEffect(() => {
    if (activeCategory) {
      const { Work, Material } = getWorksMaterials({ rooms: revision });
      const works = Work;
      const materials = Material;
      setWorks(works);
      setMaterials(materials);
    }
  }, [revision]);

  const [releasedOrders, setReleasedOrders] = useState([]);

  useEffect(() => {
    fetchReleasedOrders();
  }, []);

  const fetchReleasedOrders = async () => {
    setSpinner(true);
    fetch(`${config.orderService}getServiceOrders/${data.ProjectId}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setAllOrders(json.servicesPayload);
        // console.log('json.servicesPayload : ', json.servicesPayload)
        let completedOrders = [];
        let totalOrderAmount = 0;
        let totalMilestoneAmount = 0;
        // console.log('==========================================')
        for (
          let index = 0;
          index < Object.keys(json.servicesPayload).length;
          index++
        ) {
          let tempMilestonesObject = JSON.parse(
            json.servicesPayload[index].CommonMilestones
          );
          // totalOrderAmount = 0;
          totalOrderAmount = json.servicesPayload[index].OrderTotalAmount || 0;
          totalMilestoneAmount = 0;
          for (
            let jindex = 0;
            jindex < tempMilestonesObject?.length;
            jindex++
          ) {
            if (
              tempMilestonesObject[jindex].name !== "Paid" &&
              tempMilestonesObject[jindex]["payments"]
            ) {
              for (
                let pindex = 0;
                pindex < tempMilestonesObject[jindex]["payments"]?.length;
                pindex++
              ) {
                // console.log('payment object',tempMilestonesObject[jindex]["payments"][pindex])
                // console.log('amount',tempMilestonesObject[jindex]["payments"][pindex].amount)
                totalMilestoneAmount =
                  totalMilestoneAmount +
                  Number(
                    tempMilestonesObject[jindex]["payments"][pindex].amount
                  );
              }
              //  console.log('total Milestone Amount',totalMilestoneAmount)
            }
          }
          totalMilestoneAmount =
            Number(totalMilestoneAmount) +
            (tempMilestonesObject
              ? tempMilestonesObject[0]?.name === "Paid"
                ? Number(tempMilestonesObject[0]?.totalAmount)
                : 0
              : 0);

          // let tempDataObject = JSON.parse(
          //   json.servicesPayload[index].Data
          // ).data;
          // totalOrderAmount = 0;
          // totalMilestoneAmount = 0;

          // for (let jindex = 0; jindex < tempDataObject.length; jindex++) {
          //   console.log('tempDataObject : index ==> ', index, ' | jindex ===>> ', jindex, ' | ', tempDataObject)
          //   totalOrderAmount = totalOrderAmount +
          //     tempDataObject[jindex]?.quantity * tempDataObject[jindex]?.rate +
          //     tempDataObject[jindex]?.quantity *
          //       tempDataObject[jindex]?.rate *
          //       (tempDataObject[jindex]?.gst / 100);
          //   if (tempDataObject[jindex]["milestones"]) {
          //     // write the code here
          //     console.log('tempDataObject[jindex]["milestones"] ' ,tempDataObject[jindex]["milestones"])
          //     for (
          //       let milestone = 0;
          //       milestone <
          //       Object.keys(tempDataObject[jindex]["milestones"]).length;
          //       milestone++
          //     ) {
          //       if (tempDataObject[jindex]["milestones"][milestone].payments) {
          //         for (
          //           let payment = 0;
          //           payment <
          //           Object.keys(
          //             tempDataObject[jindex]["milestones"][milestone].payments
          //           ).length;
          //           payment++
          //         ) {
          //           totalMilestoneAmount =
          //             totalMilestoneAmount +
          //             tempDataObject[jindex]["milestones"][milestone]?.payments[
          //               payment
          //             ]?.amount;
          //         }
          //       } else {
          //       }
          //     }
          //   } else {
          //   }
          // }
          // console.log('totalOrderAmount : ', totalOrderAmount.toFixed(0))
          // console.log('totalMilestoneAmount : ', totalMilestoneAmount.toFixed(0))
          if (
            totalOrderAmount > 0 &&
            totalMilestoneAmount > 0 &&
            totalOrderAmount - totalMilestoneAmount <= 1
          ) {
            completedOrders.push(json.servicesPayload[index]);
          } else {
          }
        }

        let completedCategories = [];

        completedOrders.map((item, index) =>
          completedCategories.push(item.Category)
        );

        // console.log('completedCategories : ', completedCategories)

        setCategories([...new Set(completedCategories)]);
        setReleasedOrders(completedOrders);

        console.log("completedOrders : ", completedOrders);
        setSpinner(false);
      })
      .catch((e) => {
        console.log("getServiceOrders catch: ", e.toString());
        toast.error("Error fetching orders data!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });
  };
  // const getBankAccountDetails = (mobile) => {
  //   fetch(`${config.profileService}bankAccount/${mobile}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "*/*",
  //       Authorization:
  //         "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ",
  //     },
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((json) => {
  //       if (json.payload.length > 0) {
  //         setBankAccountDetails(json.payload[0]);
  //         setOpenBankDetailsModel(true);
  //       } else {
  //         setBankAccountDetails({});
  //         setOpenBankDetailsModel(true);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("getBankAccountDetails error: ", e.toString());
  //     });
  // };

  // const styles = {
  //   outerCircle: {
  //     height: 25,
  //     width: 25,
  //     borderRadius: 50,
  //     border: "1px solid #FFA000",
  //     backgroundColor: "#FFF8E1",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },
  //   innerCircle: {
  //     height: 15,
  //     width: 15,
  //     borderRadius: 50,
  //     backgroundColor: "#FFA000",
  //   },

  //   leftRightDashedUnit: {
  //     height: 4,
  //     width: 100,
  //     border: "2px dashed #FFECB3",
  //   },

  //   leftRightUnit: {
  //     height: 4,
  //     width: 100,
  //     backgroundColor: "#FFECB3",
  //   },
  //   centerUnit: {
  //     height: 25,
  //     width: 10,
  //     backgroundColor: "#FFECB3",
  //     cursor: "pointer",
  //   },

  //   leftRightUnitSelected: {
  //     height: 7.5,
  //     width: 30,
  //     backgroundColor: "#FFECB3",
  //   },
  //   selectedMilestone: {
  //     height: 30,
  //     width: 30,
  //     borderRadius: 50,
  //     border: "1px solid #FFA000",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },
  // };

  var counter = 0;

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
      <div
        style={{
          border: "1px solid #E1E1E1",
          width: "15%",
          height: "86%",
          fontSize: "13px",
          position: "fixed",
          overflow: "scroll",
        }}
      >
        <p style={{ color: "#231700", margin: "10px" }}>
          {activeCategory == "All"
            ? releasedOrders.length
            : releasedOrders.filter((item) => item.Category == activeCategory)
                .length}
          {" released orders"}
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <p style={{ fontWeight: "bold" }}>Category</p>
        </div>

        {isSpinner ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{ width: "30px", height: "30px", color: "#fdd34d" }}
            />
          </div>
        ) : (
          <div
            style={
              "All" == activeCategory
                ? {
                    backgroundColor: "#FFF8E1",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 10,
                    cursor: "pointer",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 10,
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              setActiveCategory("All");
            }}
          >
            <p>All</p>
            <p>
              {"\u20B9 "}
              {/* {releasedOrders
                .map(
                  (item, index) =>
                    item?.ApprovalStatus != "inApproval" &&
                    JSON.parse(item.Data)
                      .data.map((dItem, dIndex) =>
                        (
                          dItem.quantity * dItem.rate +
                            dItem.quantity * dItem.rate * (dItem.gst / 100) || 0
                        ).toFixed(2)
                      )
                      .reduce((total, item) => (total += Number(item)), 0)
                      .toFixed(2)
                )
                .reduce((total, item) => (total += Number(item)), 0)
                .toFixed(2)} */}
              {releasedOrders
                .filter((item) => item.ApprovalStatus != "inApproval")
                .reduce((totalCategoryAmount, item) => {
                  return (totalCategoryAmount += Number(item.OrderTotalAmount));
                }, 0)
                .toFixed(2)}
            </p>
          </div>
        )}

        {categories
          .sort((a, b) => a.localeCompare(b))
          .map((category, index) => (
            <div
              key={index}
              style={
                category == activeCategory
                  ? {
                      backgroundColor: "#FFF8E1",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
                  : {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
              }
              onClick={() => {
                setActiveCategory(category);
              }}
            >
              <p>{category}</p>
              <p>
                {"\u20B9 "}
                {/* {releasedOrders
                  .map(
                    (item, index) =>
                      item.ApprovalStatus != "inApproval" &&
                      item.Category == category &&
                      JSON.parse(item.Data)
                        .data.map((dItem, dIndex) =>
                          (
                            dItem.quantity * dItem.rate +
                              dItem.quantity * dItem.rate * (dItem.gst / 100) ||
                            0
                          ).toFixed(2)
                        )
                        .reduce((total, item) => (total += Number(item)), 0)
                        .toFixed(2)
                  )
                  .reduce((total, item) => (total += Number(item)), 0)
                  .toFixed(2)} */}
                {releasedOrders
                  .filter(
                    (item) =>
                      item.ApprovalStatus != "inApproval" &&
                      item.Category == category
                  )
                  .reduce((totalCategoryAmount, item) => {
                    return (totalCategoryAmount += Number(
                      item.OrderTotalAmount
                    ));
                  }, 0)
                  .toFixed(2)}
              </p>
            </div>
          ))}
      </div>
      <div
        style={{
          width: "80%",
          height: "90%",
          padding: "0px 10px 10px 10px",
          marginLeft: "15%",
          position: "fixed",
          overflow: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <DropDownMUI
              placeholder={"Work Type"}
              data={["All", "Only Work", "Work + Material", "Only Material"]}
              value={workType}
              onChange={(value) => {
                setWorkType(value);
              }}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <DropDownMUI
              placeholder={"Status"}
              data={[
                "All",
                "Order placed",
                "Order accepted",
                "Order completed",
                "Quotation released",
              ]}
              value={status}
              onChange={(value) => {
                setStatus(value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              border: "1px solid #3535354d",
              width: "100%",
              marginBottom: 10,
              borderRadius: 5,
            }}
          >
            <AiOutlineSearch
              color="grey"
              size="20"
              style={{ margin: "0px 10px" }}
            />
            <input
              placeholder="Search by vendor name"
              style={{
                width: "100%",
                fontSize: "16px",
                borderWidth: 0,
                border: "none",
                outline: "none",
              }}
              value={searchItems}
              onChange={(e) => {
                setSearchItems(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        {isSpinner ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#fdd34d" }} />
          </div>
        ) : (
          releasedOrders &&
          releasedOrders.map((item, index) => {
            return (
              (activeCategory == item?.Category || activeCategory == "All") &&
              (searchItems.length > 0
                ? item?.VendorName?.toLowerCase().includes(
                    searchItems.toLowerCase()
                  )
                : item?.VendorName == null
                ? true
                : item?.VendorName?.toLowerCase().includes(
                    searchItems.toLowerCase()
                  )) &&
              ((JSON.parse(item.Data)?.data[0]?.workType == undefined
                ? workType == "Only Material"
                : workType == JSON.parse(item.Data)?.data[0]?.workType) ||
                workType == "All") &&
              (item?.Status.toLowerCase().includes(status.toLowerCase()) ||
                status == "All") && (
                // <div
                //   key={index}
                //   style={{
                //     width: "100%",
                //     borderStyle: "solid",
                //     borderWidth: 1,
                //     borderColor: "#FDD34D",
                //     borderRadius: "4px",
                //     marginBottom: "20px",
                //   }}
                // >
                //   <div
                //     style={{
                //       backgroundColor: "#FFF8E1",
                //       display: "flex",
                //       flexDirection: "row",
                //       justifyContent: "space-between",
                //       fontWeight: "bold",
                //       padding: "10px",
                //       borderRadius: "4px",
                //     }}
                //   >
                //     <div
                //       style={{
                //         width: "48%",
                //         display: "flex",
                //         flexDirection: "column",
                //         gap: "10px",
                //       }}
                //     >
                //       <div
                //         style={{
                //           display: "flex",
                //           flexDirection: "row",
                //           gap: 20,
                //           alignItems: "center",
                //         }}
                //       >
                //         <div
                //           style={{
                //             display: "flex",
                //             flexDirection: "row",
                //             justifyContent: "center",
                //             alignItems: "center",
                //             height: 30,
                //             width: 30,
                //             borderStyle: "solid",
                //             borderWidth: 1,
                //             borderRadius: "50%",
                //             borderColor: "#FDD34D",
                //           }}
                //         >
                //           {JSON.parse(item.Data)?.data[0]?.workType ==
                //           "Only Work" ? (
                //             <GrUserWorker size={15} />
                //           ) : JSON.parse(item.Data)?.data[0]?.workType ==
                //             "Work + Material" ? (
                //             <>
                //               <GrUserWorker size={10} />
                //               <FiTruck size={10} />
                //             </>
                //           ) : (
                //             <FiTruck size={15} />
                //           )}
                //         </div>
                //         <p style={{ fontWeight: "500", width: "auto" }}>
                //           {item?.Status}
                //           {" | "}
                //           {item?.VendorName == null &&
                //           item?.VendorMobile == null
                //             ? "Not accepted by any vendor yet!"
                //             : `${item?.VendorName} (${item?.VendorMobile})`}
                //         </p>
                //       </div>
                //       <div
                //         style={{
                //           display: "flex",
                //           flexDirection: "row",
                //           gap: 25,
                //           fontWeight: "400",
                //           fontSize: "14px",
                //         }}
                //       >
                //         <p>
                //           Start Date:{" "}
                //           {JSON.parse(item.Data)
                //             ?.StartDate.substring(0, 10)
                //             .split("-")
                //             .reverse()
                //             .join(" / ")}
                //         </p>
                //         <p>
                //           End Date:{" "}
                //           {JSON.parse(item.Data)
                //             ?.EndDate.substring(0, 10)
                //             .split("-")
                //             .reverse()
                //             .join(" / ")}
                //         </p>
                //       </div>
                //     </div>
                //     <p style={{ width: "10%", paddingTop: 5 }}>Quantity</p>
                //     <p style={{ width: "10%", paddingTop: 5 }}>Unit</p>
                //     <p style={{ width: "10%", paddingTop: 5 }}>Rate</p>
                //     <p style={{ width: "10%", paddingTop: 5 }}>GST</p>
                //     <p style={{ width: "10%", paddingTop: 5 }}>Amount</p>
                //     <p
                //       style={{ width: "2%", paddingTop: 5, cursor: "pointer" }}
                //     >
                //       <PopOverView
                //         position="left"
                //         component={
                //           <div
                //             style={{
                //               display: "flex",
                //               flexDirection: "column",
                //               gap: 10,
                //               fontSize: 12,
                //               color: "grey",
                //               alignItems: "flex-start",
                //               width: 140,
                //             }}
                //           >
                //             <div
                //               onMouseDown={(e) => {
                //                 e.stopPropagation();
                //                 const win = window.open(
                //                   `/PO/${item?.Id}`,
                //                   "_blank"
                //                 );
                //                 win.focus();
                //                 record("@view_order_button_click", {
                //                   description: "view order button click",
                //                 });
                //               }}
                //               className="menuItem"
                //             >
                //               View Order
                //             </div>
                //             {item.VendorMobile && (
                //               <p
                //                 className="menuItem"
                //                 onMouseDown={(e) => {
                //                   e.stopPropagation();
                //                   getBankAccountDetails(item.VendorMobile);
                //                   record("@bank_details_button_click", {
                //                     description: "bank details button click",
                //                   });
                //                 }}
                //               >
                //                 Bank Details
                //               </p>
                //             )}
                //           </div>
                //         }
                //       >
                //         <FiMoreVertical />
                //       </PopOverView>
                //     </p>
                //   </div>
                //   {JSON.parse(item?.Data) &&
                //     JSON.parse(item?.Data)?.data.map((dataItem, dataIndex) => (
                //       // (dataItem?.description?.toLowerCase().includes(searchItems.toLowerCase()) || dataItem?.specification?.toLowerCase().includes(searchItems.toLowerCase())) &&
                //       // console.log('dataItem.status : ', dataItem.status),
                //       <>
                //         <div
                //           key={dataIndex}
                //           style={{
                //             padding: "10px",
                //             display: "flex",
                //             flexDirection: "column",
                //             justifyContent: "space-between",
                //             boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                //             borderRadius: "8px",
                //             margin: "10px",
                //           }}
                //         >
                //           <div
                //             style={{
                //               display: "flex",
                //               flexDirection: "row",
                //               justifyContent: "space-between",
                //               alignItems: "flex-start",
                //             }}
                //           >
                //             <p style={{ width: "48%" }}>
                //               <p style={{ fontWeight: "600" }}>
                //                 {dataItem["Unit Name"]}
                //               </p>
                //               <br />
                //               {"type" in dataItem
                //                 ? dataItem?.type
                //                 : dataItem?.vendorCategory}
                //               {" | "}
                //               {dataItem?.description
                //                 ?.replace(/<new-line>/g, "\n")
                //                 .replace(/<single-quote>/g, "'")
                //                 .replace(/<double-quote>/g, '"') ||
                //                 dataItem?.specification
                //                   ?.replace(/<new-line>/g, "\n")
                //                   .replace(/<single-quote>/g, "'")
                //                   .replace(/<double-quote>/g, '"')}
                //             </p>
                //             <p style={{ width: "10%" }}>
                //               {dataItem?.quantity.length == 0
                //                 ? 0
                //                 : dataItem?.quantity}
                //             </p>
                //             <p style={{ width: "10%" }}>{dataItem?.unit}</p>
                //             <p style={{ width: "10%" }}>
                //               {"\u20B9 "}
                //               {dataItem?.rate}
                //             </p>
                //             <p style={{ width: "10%" }}>{dataItem?.gst} %</p>
                //             <p style={{ width: "10%" }}>
                //               {"\u20B9 "}
                //               {(
                //                 dataItem?.quantity * dataItem?.rate +
                //                   dataItem?.quantity *
                //                     dataItem?.rate *
                //                     (dataItem?.gst / 100) || 0
                //               ).toFixed(2)}
                //             </p>
                //             <p style={{ width: "2%", cursor: "pointer" }}>
                //               {showStatus &&
                //               showStatus.includes(`${index}${dataIndex}`) ? (
                //                 <FiChevronUp
                //                   onClick={() => {
                //                     let popStatus = [...showStatus];
                //                     popStatus.pop(`${index}${dataIndex}`);
                //                     setShowStatus(popStatus);
                //                   }}
                //                 />
                //               ) : (
                //                 <FiChevronDown
                //                   onClick={() => {
                //                     let pushStatus = [...showStatus];
                //                     pushStatus.push(`${index}${dataIndex}`);
                //                     setShowStatus(pushStatus);
                //                   }}
                //                 />
                //               )}
                //             </p>
                //           </div>
                //           {showStatus.includes(`${index}${dataIndex}`) && (
                //             <div style={{ margin: "20px 0px" }}>
                //               <div
                //                 style={{
                //                   display: "flex",
                //                   flexDirection: "row",
                //                   justifyContent: "flex-start",
                //                   alignItems: "flex-start",
                //                 }}
                //               >
                //                 <div
                //                   style={{
                //                     display: "flex",
                //                     flexDirection: "column",
                //                     gap: 10,
                //                   }}
                //                 >
                //                   <div
                //                     style={{
                //                       display: "flex",
                //                       flexDirection: "row",
                //                       alignItems: "center",
                //                     }}
                //                   >
                //                     <div style={styles.outerCircle}>
                //                       <div style={styles.innerCircle} />
                //                     </div>
                //                     <div style={styles.leftRightDashedUnit} />
                //                   </div>
                //                   <small>Placed</small>
                //                 </div>
                //                 <div
                //                   style={{
                //                     display: "flex",
                //                     flexDirection: "column",
                //                     gap: 10,
                //                   }}
                //                 >
                //                   <div
                //                     style={{
                //                       display: "flex",
                //                       flexDirection: "row",
                //                       alignItems: "center",
                //                     }}
                //                   >
                //                     <div style={styles.selectedMilestone}>
                //                       {item.Status == "Order accepted" && (
                //                         <FaCheck color={"#FDD34D"} />
                //                       )}
                //                     </div>
                //                     <div style={styles.leftRightUnit} />
                //                   </div>
                //                   <small>Accepted</small>
                //                 </div>
                //                 {"milestones" in dataItem &&
                //                   dataItem?.milestones.map((mItem, mIndex) => (
                //                     <div
                //                       style={{
                //                         display: "flex",
                //                         flexDirection: "column",
                //                         gap: 10,
                //                         maxWidth: 120,
                //                       }}
                //                       key={mIndex}
                //                     >
                //                       <div
                //                         style={{
                //                           display: "flex",
                //                           flexDirection: "row",
                //                           alignItems: "center",
                //                         }}
                //                       >
                //                         <div style={styles.selectedMilestone}>
                //                           {"status" in mItem &&
                //                             mItem?.status == "confirmed" && (
                //                               <FaCheck color={"#FDD34D"} />
                //                             )}
                //                         </div>
                //                         {mItem.percentage != 100 && (
                //                           <div style={styles.leftRightUnit} />
                //                         )}
                //                       </div>
                //                       <small>{mItem?.name}</small>
                //                     </div>
                //                   ))}
                //               </div>
                //               <br />
                //               <div
                //                 style={{
                //                   display: "flex",
                //                   flexDirection: "row",
                //                   justifyContent: "flex-start",
                //                   alignItems: "flex-start",
                //                 }}
                //               >
                //                 <div
                //                   style={{
                //                     minWidth: 120,
                //                     maxWidth: 120,
                //                     display: "flex",
                //                     flexWrap: "wrap",
                //                   }}
                //                 >
                //                   <small style={{ fontWeight: "bold" }}>
                //                     Total Amount
                //                     <br />
                //                     <p style={{ fontWeight: "normal" }}>
                //                       [{"\u20B9 "}
                //                       {(
                //                         dataItem?.quantity * dataItem?.rate +
                //                           dataItem?.quantity *
                //                             dataItem?.rate *
                //                             (dataItem?.gst / 100) || 0
                //                       ).toFixed(2)}
                //                       ]
                //                     </p>
                //                   </small>
                //                 </div>
                //                 <div
                //                   style={{
                //                     minWidth: 130,
                //                     maxWidth: 130,
                //                     display: "flex",
                //                     flexWrap: "wrap",
                //                   }}
                //                 />
                //                 {"milestones" in dataItem &&
                //                   dataItem?.milestones.map((mItem, mIndex) => (
                //                     <small
                //                       style={{
                //                         minWidth: 120,
                //                         maxWidth: 120,
                //                         display: "flex",
                //                         flexWrap: "wrap",
                //                       }}
                //                     >
                //                       {"\u20B9 "}
                //                       {(
                //                         (dataItem.quantity * dataItem.rate +
                //                           dataItem.quantity *
                //                             dataItem.rate *
                //                             (dataItem.gst / 100)) *
                //                           (mItem.percentage / 100)
                //                           // - counter
                //                       ).toFixed(2)}
                //                       <div style={{ display: "none" }}>
                //                         {
                //                           (counter =
                //                             mIndex ==
                //                             dataItem.milestones.length - 1
                //                               ? 0
                //                               : (dataItem.quantity *
                //                                   dataItem.rate +
                //                                   dataItem.quantity *
                //                                     dataItem.rate *
                //                                     (dataItem.gst / 100)) *
                //                                 (mItem.percentage / 100))
                //                         }
                //                       </div>
                //                     </small>
                //                   ))}
                //               </div>
                //               <br />
                //               <div
                //                 style={{
                //                   display: "flex",
                //                   flexDirection: "row",
                //                   justifyContent: "flex-start",
                //                   alignItems: "flex-start",
                //                 }}
                //               >
                //                 <div
                //                   style={{
                //                     minWidth: 120,
                //                     maxWidth: 120,
                //                     display: "flex",
                //                     flexWrap: "wrap",
                //                   }}
                //                 >
                //                   <small style={{ fontWeight: "bold" }}>
                //                     Paid
                //                     <br />
                //                     <p style={{ fontWeight: "normal" }}>
                //                       [{"\u20B9 "}
                //                       {(
                //                         dataItem?.quantity * dataItem?.rate +
                //                           dataItem?.quantity *
                //                             dataItem?.rate *
                //                             (dataItem?.gst / 100) || 0
                //                       ).toFixed(2)}
                //                       ]
                //                     </p>
                //                   </small>
                //                 </div>
                //                 <div
                //                   style={{
                //                     minWidth: 130,
                //                     maxWidth: 130,
                //                     display: "flex",
                //                     flexWrap: "wrap",
                //                   }}
                //                 />
                //                 {"milestones" in dataItem &&
                //                   dataItem?.milestones.map((mItem, mIndex) => (
                //                     <small
                //                       style={{
                //                         minWidth: 120,
                //                         maxWidth: 120,
                //                         display: "flex",
                //                         flexWrap: "wrap",
                //                       }}
                //                     >
                //                       {"\u20B9 "}
                //                       {"payments" in mItem
                //                         ? mItem.payments
                //                             .reduce(
                //                               (total, item) =>
                //                                 (total =
                //                                   total + Number(item.amount)),
                //                               0
                //                             )
                //                             .toFixed(2)
                //                         : "0.00"}
                //                     </small>
                //                   ))}
                //               </div>
                //               <br />
                //               <div
                //                 style={{
                //                   display: "flex",
                //                   flexDirection: "row",
                //                   justifyContent: "flex-start",
                //                   alignItems: "flex-start",
                //                 }}
                //               >
                //                 <div
                //                   style={{
                //                     minWidth: 120,
                //                     maxWidth: 120,
                //                     display: "flex",
                //                     flexWrap: "wrap",
                //                   }}
                //                 >
                //                   <small style={{ fontWeight: "bold" }}>
                //                     Remaining
                //                     <br />
                //                     <p style={{ fontWeight: "normal" }}>
                //                       [{"\u20B9 "}
                //                       {(
                //                         (
                //                           dataItem?.quantity * dataItem?.rate +
                //                             dataItem?.quantity *
                //                               dataItem?.rate *
                //                               (dataItem?.gst / 100) || 0
                //                         ).toFixed(2) -
                //                         (
                //                           "milestones" in dataItem &&
                //                           dataItem?.milestones.map(
                //                             (mItem, mIndex) =>
                //                               "payments" in mItem
                //                                 ? mItem.payments
                //                                     .reduce(
                //                                       (total, item) =>
                //                                         (total =
                //                                           total +
                //                                           Number(item.amount)),
                //                                       0
                //                                     )
                //                                     .toFixed(2)
                //                                 : 0
                //                           )
                //                         ).reduce(
                //                           (previousAmount, currentAmount) =>
                //                             previousAmount +
                //                             Number(currentAmount),
                //                           0
                //                         )
                //                       ).toFixed(2)}
                //                       ]
                //                     </p>
                //                   </small>
                //                 </div>
                //                 <div
                //                   style={{
                //                     minWidth: 130,
                //                     maxWidth: 130,
                //                     display: "flex",
                //                     flexWrap: "wrap",
                //                   }}
                //                 />
                //                 {"milestones" in dataItem &&
                //                   dataItem?.milestones.map((mItem, mIndex) => (
                //                     <small
                //                       style={{
                //                         minWidth: 120,
                //                         maxWidth: 120,
                //                         display: "flex",
                //                         flexWrap: "wrap",
                //                       }}
                //                     >
                //                       {"\u20B9 "}
                //                       {(
                //                         (dataItem.quantity * dataItem.rate +
                //                           dataItem.quantity *
                //                             dataItem.rate *
                //                             (dataItem.gst / 100)) *
                //                           (mItem.percentage / 100) -
                //                         // counter -
                //                         ("payments" in mItem
                //                           ? mItem.payments.reduce(
                //                               (total, item) =>
                //                                 (total =
                //                                   total + Number(item.amount)),
                //                               0
                //                             )
                //                           : 0)
                //                       ).toFixed(2)}
                //                       <div style={{ display: "none" }}>
                //                         {
                //                           (counter =
                //                             mIndex ==
                //                             dataItem.milestones.length - 1
                //                               ? 0
                //                               : (dataItem.quantity *
                //                                   dataItem.rate +
                //                                   dataItem.quantity *
                //                                     dataItem.rate *
                //                                     (dataItem.gst / 100)) *
                //                                 (mItem.percentage / 100))
                //                         }
                //                       </div>
                //                     </small>
                //                   ))}
                //               </div>
                //               <br />
                //               <div
                //                 style={{
                //                   display: "flex",
                //                   flexDirection: "row",
                //                   justifyContent: "flex-start",
                //                   alignItems: "flex-start",
                //                 }}
                //               >
                //                 <div
                //                   style={{
                //                     minWidth: 120,
                //                     maxWidth: 120,
                //                     display: "flex",
                //                     flexWrap: "wrap",
                //                     flexDirection: "column",
                //                     justifyContent: "center",
                //                     height: 35,
                //                   }}
                //                 >
                //                   <small style={{ fontWeight: "bold" }}>
                //                     Pay now
                //                   </small>
                //                 </div>
                //                 <div
                //                   style={{
                //                     minWidth: 130,
                //                     maxWidth: 130,
                //                     display: "flex",
                //                     flexWrap: "wrap",
                //                   }}
                //                 />
                //                 {"milestones" in dataItem &&

                //                   dataItem?.milestones.map((mItem, mIndex) =>

                //                       // console.log((
                //                       //   (mItem?.percentage / 100) *
                //                       //     (dataItem?.quantity * dataItem?.rate +
                //                       //       dataItem?.quantity *
                //                       //         dataItem?.rate *
                //                       //         (dataItem?.gst / 100) || 0) -
                //                       //     ("payments" in mItem
                //                       //       ? mItem.payments.reduce(
                //                       //           (total, item) =>
                //                       //             (total =
                //                       //               total + Number(item.amount)),
                //                       //           0
                //                       //         )
                //                       //       : 0)
                //                       // ).toFixed(2),'checking here')
                //                        (
                //                       (mItem?.percentage / 100) *
                //                         (dataItem?.quantity * dataItem?.rate +
                //                           dataItem?.quantity *
                //                             dataItem?.rate *
                //                             (dataItem?.gst / 100) || 0) -
                //                         ("payments" in mItem
                //                           ? mItem.payments.reduce(
                //                               (total, item) =>
                //                                 (total =
                //                                   total + Number(item.amount)),
                //                               0
                //                             )
                //                           : 0)
                //                     ).toFixed(2) <= 0.0 ? (
                //                       <div
                //                         style={{
                //                           minWidth: 60,
                //                           maxWidth: 60,
                //                           marginRight: 60,
                //                           display: "flex",
                //                           flexWrap: "wrap",
                //                           flexDirection: "row",
                //                           padding: "10px 15px",
                //                           color: "#43A047",
                //                           borderRadius: 5,
                //                           cursor: "pointer",
                //                           fontWeight: 700,
                //                         }}
                //                       >
                //                         <div
                //                           style={{
                //                             display: "flex",
                //                             flexDirection: "row",
                //                             color: "#43A047",
                //                           }}
                //                           onClick={() => {
                //                             setViewDetailsModal(true);
                //                             setViewDetaisItem(item);
                //                           }}
                //                         >
                //                           <AiOutlineCheckCircle />
                //                           <p
                //                             style={{
                //                               color: "#43A047",
                //                               borderRadius: 5,
                //                               cursor: "pointer",
                //                               fontWeight: 700,
                //                             }}
                //                           >
                //                             View
                //                           </p>
                //                         </div>
                //                       </div>
                //                     ) : (
                //                       <div
                //                         style={{
                //                           minWidth: 60,
                //                           maxWidth: 60,
                //                           marginRight: 60,
                //                           display: "flex",
                //                           flexWrap: "wrap",
                //                           padding: "10px 15px",
                //                           backgroundColor: "#FFF6DB",
                //                           borderRadius: 5,
                //                           cursor: "pointer",
                //                         }}
                //                         onClick={() => {
                //                           // alert('This order is completed')
                //                           toast.error(
                //                             "This Order has been completed",
                //                             {
                //                               duration: 4000,
                //                               position: "bottom-center",
                //                               style: {
                //                                 borderRadius: "8px",
                //                                 padding: "16px",
                //                                 color: "#E72424",
                //                                 backgroundColor: "#FEF0F0 ",
                //                                 maxWidth: "100%",
                //                               },
                //                               icon: (
                //                                 <FiInfo color={"#E72424"} />
                //                               ),
                //                             }
                //                           );
                //                         }}
                //                       >
                //                         Pay
                //                       </div>
                //                     )
                //                       )}
                //               </div>
                //             </div>
                //           )}
                //         </div>
                //       </>
                //     ))}
                // </div>
                // the above code is moved to OrderItem to avoid duplicating
                <OrderItem
                  key={index}
                  releasedOrderIndex={index}
                  item={item}
                  isCompletedOrder={true}
                  allOrders={allOrders}
                  setReleasedOrders={setReleasedOrders}
                />
              )
            );
          })
        )}
        {/* <Modal
          open={openBankDetailsModel}
          onClose={() => setOpenBankDetailsModel(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              overflow: "auto",
              bgcolor: "background.paper",
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
              minWidth: "40%",
              maxHeight: "90%",
              maxWidth: "40%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ marginBottom: 30, fontWeight: 700, fontSize: 25 }}>
                Bank Account Details
              </p>
              <div style={{ display: "flex", flexDirection: "row", gap: 50 }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 20 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>Account Holder Name</p>
                    <p
                      style={{
                        padding: 5,
                        borderColor: "black",
                        backgroundColor: "#f3f3f1",
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.AccountHolderName}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>Account Number</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.AccountNumber}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>IFSC</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.IFSC}
                    </p>
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 20 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>PAN</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.PAN}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>Aadhar</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.AADHAR}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>GSTIN</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.GSTIN}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal> */}
        {/* {viewDetailsModal && (
          <ViewDetails
            viewDetailsModal={viewDetailsModal}
            setViewDetailsModal={setViewDetailsModal}
            viewDetailsItem={viewDetailsItem}
          />
        )} */}
        {backToTop && (
          <button
            id="btn"
            title="back to top"
            onClick={backToTopHandler}
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#2f4858",
              marginTop: "5px",
              position: "fixed",
              bottom: "4%",
              left: "95%",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              color: "white",
            }}
          >
            <BiUpArrowAlt size={30} style={{ marginTop: "2px" }} />
          </button>
        )}
      </div>
    </div>
  );
}
