import React from "react";
import "./tabs.scss";

export default ({ tabs, children, activeTab, onChange }) => {
  return (
    <div id="tabs">
      <div>
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={tab === activeTab ? "activeTabHighlight" : ""}
              onClick={() => onChange(tab)}
            >
              {tab}
            </div>
          );
        })}
      </div>
      <div>{children}</div>
    </div>
  );
};
