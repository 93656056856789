import { secondDatabase } from "../../../config/firebase";

/**
 * Updates totalDieselConsumed for vehicles based on changes in tag or quantity.
 *
 * @param {string} firmId - The firm ID.
 * @param {string} projectId - The project ID.
 * @param {Array} arrayOfVehicles - Array of vehicle objects, each containing vehicle details.
 * @param {Object} oldItem - The old item containing `tag` and `quantity`.
 * @param {Object} newItem - The new item containing `tag` and `quantity`.
 */
async function editVehicleDieselConsumed(firmId, projectId, arrayOfVehicles, oldItem, newItem) {
  try {
    // Find old and new vehicles in the array based on their tags
    const oldVehicle = arrayOfVehicles.find(vehicle => vehicle.tag === oldItem.tag);
    const newVehicle = arrayOfVehicles.find(vehicle => vehicle.tag === newItem.tag);

    if (!oldVehicle) {
      console.error(`Vehicle with tag "${oldItem.tag}" not found in old item.`);
      return;
    }

    // Firebase references for old and new vehicles
    const oldVehiclePathRef = secondDatabase.ref(`${firmId}/${projectId}/vehicles/${oldVehicle.id}`);
    const newVehiclePathRef = newVehicle
      ? secondDatabase.ref(`${firmId}/${projectId}/vehicles/${newVehicle.id}`)
      : null;

    // Case 1: Only quantity is changed
    if (oldItem.tag === newItem.tag) {
      const quantityDifference = newItem.quantityAccepted - oldItem.quantityAccepted;

      // Use oldVehicle's totalDieselConsumed for calculations
      if (typeof oldVehicle.totalDieselConsumed === 'number' || Number(oldVehicle.totalDieselConsumed)) {

        const updatedDieselConsumed = oldVehicle.totalDieselConsumed + quantityDifference;
        await oldVehiclePathRef.update({ totalDieselConsumed: updatedDieselConsumed });

        console.log(
          `Updated totalDieselConsumed for vehicle with tag "${oldItem.tag}" to ${updatedDieselConsumed}.`
        );
      } else {
        console.error('Invalid or missing totalDieselConsumed for the old vehicle.');
      }
    }
    // Case 2: Vehicle (tag) is changed
    else {
      // Subtract the old quantity from the old vehicle
      if (typeof oldVehicle.totalDieselConsumed === 'number' || Number(oldVehicle.totalDieselConsumed)) {
        const updatedOldDieselConsumed = Number(oldVehicle.totalDieselConsumed) - oldItem.quantity;
        await oldVehiclePathRef.update({ totalDieselConsumed: updatedOldDieselConsumed });

        console.log(
          `Subtracted ${oldItem.quantity} from totalDieselConsumed for vehicle with tag "${oldItem.tag}". New value: ${updatedOldDieselConsumed}.`
        );
      } else {
        console.error('Invalid or missing totalDieselConsumed for the old vehicle.');
      }

      // Add the new quantity to the new vehicle
      if (newVehicle && typeof newVehicle.totalDieselConsumed === 'number') {
        const updatedNewDieselConsumed = newVehicle.totalDieselConsumed + newItem.quantity;
        await newVehiclePathRef.update({ totalDieselConsumed: updatedNewDieselConsumed });

        console.log(
          `Added ${newItem.quantity} to totalDieselConsumed for vehicle with tag "${newItem.tag}". New value: ${updatedNewDieselConsumed}.`
        );
      } else if (!newVehicle) {
        console.error(`Vehicle with tag "${newItem.tag}" not found in new item.`);
      } else {
        console.error('Invalid or missing totalDieselConsumed for the new vehicle.');
      }
    }
  } catch (error) {
    console.error('Error editing vehicle totalDieselConsumed:', error);
    throw error; // Optionally propagate the error for the caller to handle
  }
}

export default editVehicleDieselConsumed;
