import React, { createContext, useContext } from "react";

const RemoteConfigContext = createContext(null);

let data = {
  theme: {
    Button: {
      style: {
        height: "50px",
        backgroundColor: "#00aaff",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 0px 3px grey",
        color: "white",
        fontWeight: "500",
        padding: "10px",
        hover: {
          boxShadow: "none",
          cursor: "pointer",
        },
      },
      invert: {
        height: "50px",
        backgroundColor: "rgb(255, 211, 77)",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 0px 3px grey",
        color: "00aaff",
        fontWeight: "500",
        padding: "10px",
        hover: {
          boxShadow: "none",
          cursor: "pointer",
        },
      },
    },
    AddButton: {
      style: {
        color: "grey",
        height: "30",
        overflow: "visible",
        width: "30",
      },
    },
    DeleteButton: {
      style: {
        color: "rgb(255, 97, 88)",
        width: "30",
        marginRight: -10,
        marginLeft: 10,
      },
    },

    dashbaordpage: {
      dashbaordpage: {
        style: {
          backgroundColor: "#f5f9ff",
        },
      },

      OrderCard: {
        style: {
          backgroundColor: "white",
        },
      },

      newOrderCard: {
        style: {
          backgroundColor: "white",
          color: "grey",
        },
      },
      Navbar: {
        style: {
          backgroundColor: "#ebebeb",
        },
      },
      drawer: {
        style: {
          backgroundColor: "#353535",
        },
      },
    },
    ProjectPage: {
      navbar: {
        style: {
          backgroundColor: "#ebebeb",
          color: "black",
        },
      },
      addComponentsButtonView: {
        style: {
          color: "#00aaff",
        },
      },

      StartWork: {
        style: {
          backgroundColor: "white",
        },
      },
    },
  },
  text: {
    // routeName and component name with child in json format
    dashbaordpage: {
      //    sidebar, Navbar, Cart, ProjectDetails,

      Navbar: {
        searchPlaceholder: "Search by Client Name",
      },
      Card: {
        CardActions: {
          Action1: "DELETE",
          Action2: "DUPLICATE",
        },
      },

      ProjectDetails: {
        title: "Project Details",

        placeHolderclientName: "Enter Client's Name",

        WorkLocationDivider: "Work Location",
        placeHolderaddress: "Enter Client's Address",

        ProjectDurationDivider: "Project Duration",
        placeHolderDuration: "Duration",

        SiteEngineerIdDivider: "Site Engineer Id",
        placeHolderSiteEngineersId: "Site Engineer's Id (optional)",

        FinanceDivider: "Finance",
        placeHolderamountReceived: "Amount Received (optional)",
        Finance: {
          Ongoing: "",
          Completed: "",
          Upcoming: "",
          PettyCash: "",
          AmountReceived: "",
        },

        HeadingDivider: "Heading",
        placeHolderHeading: "Heading Name",

        proceedButton: "PROCEED",
        updateButton: "UPDATE",
      },
    },
    projectPage: {
      //  AddUnits
      title: "Unit Name",
      placeHolderUnitName: "Unit Name",
      AddComponentButton: " component",
      SaveButton: "Save",
      StartButton: "Start Work",
      ViewRevisionButton: "View Revision",
      UploadButton: "Upload Drawing/3D",
      //  Component
      AddComponent: "- Add Components",
      placeHolderDescription: "Add Description",
      placeHolderQuantity: "Quantity",
      placeHolderRate: "Rate",
      placeHolderUnits: "Units",
      AddVendersButton: " Vendors/Material",

      Work: {
        placeHolderHeading: "Heading",
        placeHolderAddDescription: "Add Description",
        placeHolderQuantity: "Quantity",
        placeHolderRate: "Rate",
        placeHolderUnit: "Unit",
        placeHolderGST: "GST",
        AddMilestonButton: " milestones",
      },

      Material: {
        placeHolderSpecification: "Specification",
        placeHolderGST: "GST",
        placeHolderUnits: "Units",
        placeHolderQuantity: "Quantity",
        placeHolderRate: "Rate",
      },

      Vendor: {
        MileStone: "Milestone",
        MileStonePercentage: "%",

        TermsAndConditions: "Terms & Conditions",
      },

      Indent: {
        SaveButton: "SAVE",
        ReleaseRequestButton: "Release Request",
        DeleteIndentButton: "Delete Indent",
        Vendor: "Vendor Mobile",
        StartDate: "",
        EndDate: "",
      },
    },
  },
};

const RemoteConfigContextProvider = (props) => {
  return (
    <RemoteConfigContext.Provider value={{ ...data }}>
      {props.children}
    </RemoteConfigContext.Provider>
  );
};

const useRemoteConfig = () => {
  const ctx = useContext(RemoteConfigContext);
  return ctx;
};

export { RemoteConfigContextProvider, useRemoteConfig };
