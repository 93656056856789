/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useRef, useState } from "react";
import "./textArea.scss";

export default ({
  filterwork,
  workIndex,
  brandSuggestion,
  arry,
  placeholder,
  onChange,
  value,
  disabled,
  suggestions,
  setSuggestions,
  suggestionOptions,
  onClick,
  onMouseDown,
  hideRedLine,
  autoFocus
}) => {


  const tref = useRef(null);
  const getCaretPosition = (e) => (e && e.selectionStart) || -1;

  const [editValue, setEditValue] = useState(null);

  const [enteredValueforBrandPurpose, setEnteredvalueforBrandPurpose] =
    useState({ value: value, cursorPos: 0 });


  useEffect(() => {
    // setTimeout(() => {
    setEnteredvalueforBrandPurpose((prev) => {
      console.log('item from sugegstions prev : ', prev)
      return {
        ...prev,
        value:
          value &&
          value
            .trim()
            .replace(/<new-line>/g, "\n")
            .replace(/<single-quote>/g, "'")
            .replace(/<double-quote>/g, '"')
            .replace(/<rupee-symbol>/g, "₹"),
      };
    });

    return () => {

    };
  }, [value]);

  const [isBrandlistOpen, setBrandList] = useState(false);
  const [filterBrandtext, setFiterBrandText] = useState(null);
  const [pos, setPos] = useState();
  const [orderId, setOrderId] = useState("");

  function lastWOrd(words, Id) {
    let caretPosition = getCaretPosition(document.getElementById(`${Id}`));

    let [before, after] = split(words, caretPosition);

    let lastword = before.split(" ");
    return lastword[lastword.length - 1];

  }

  function split(str, index) {
    const result = [str.slice(0, index), str.slice(index)];
    return result;
  }

  const testing = (value) => {

    if (filterwork && value && value.length > 0) {

      value.trim();
      let lastwrodin = lastWOrd(value, orderId);
      // console.log("lastword", lastwrodin);
      if (lastwrodin.includes("@")) {
        // console.log("inclues open the setttt");
        console.log('testing found that last alphabet is @');
        setBrandList(true);
        setFiterBrandText(lastwrodin.substring(1));
      } else {
        console.log('testing could not found that last alphabet is @')
        setBrandList(false);
      }
    } else {
      setEnteredvalueforBrandPurpose((prev) => {
        return {
          ...prev,
          value: "",
        };
      });
    }
  };

  return (
    <>
      {suggestions == true ? (
        <>

          <Autocomplete
            key={`${filterwork?.OrderId}`}
            freeSolo
            disableClearable
            // id="combo-box-demo"
            id={`${filterwork?.OrderId}`}
            disabled={disabled}
            filterOptions={() => {
              let values = enteredValueforBrandPurpose.value ? enteredValueforBrandPurpose.value.trim().split(" ") || "" : [''];

              let temp = suggestionOptions.filter((option) =>
                values?.some((word) =>
                  option.toLowerCase().includes(word.toLowerCase())
                )
              );
              // console.log("splitte words  temp sugg", temp);
              if (isBrandlistOpen) {
                temp = [];
              }
              return temp;
            }}
            options={
              suggestionOptions
            }
            onChange={(event, newValue) => {
              let value = newValue == null ? "" : newValue;
              value = value.replace(/\n/g, "<new-line>");
              value = value.replace(/'/g, "<single-quote>");
              value = value.replace(/"/g, "<double-quote>");
              value = value.replace(/₹/g, "<rupee-symbol>");
              value = value.replace(/\\/g, "");
              value = value.replace(/\t/g, "");
              onChange(value.trim(), "onChangeAutocomplete");
              tref.current = true;
            }}
            value={
              enteredValueforBrandPurpose.value !== null
                ? enteredValueforBrandPurpose.value
                : value &&
                value
                  .trim()
                  .replace(/<new-line>/g, "\n")
                  .replace(/<single-quote>/g, "'")
                  .replace(/<double-quote>/g, '"')
                  .replace(/<rupee-symbol>/g, "₹")
            }
            renderInput={(params) => (
              <TextField
                key={`${filterwork?.OrderId}`}
                onFocus={() => {
                  filterwork && setOrderId(filterwork.OrderId);
                }}
                {...params}
                error={value || hideRedLine ? false : true}
                style={{ minWidth: 380, width: "100%", fontSize: 14 }}
                // id={`${filterwork?.OrderId}`}
                label={placeholder}
                multiline
                // ref={tref}
                maxRows={4}
                disabled={disabled}
                onChange={(e) => {
                  tref.current = true;
                  let value = e.currentTarget.value && e.currentTarget.value;
                  testing(value);
                  // console.log('workiINdex',workIndex,'filtercategory',filterwork)
                  // settest(value);
                  setEnteredvalueforBrandPurpose((prev) => {
                    return { ...prev, value };
                  });
                }}
                onBlur={async (e) => {
                  setTimeout(() => {
                    setBrandList(false);
                  }, 200);
                  let value = e.currentTarget.value && e.currentTarget.value;
                  // console.log(`value inside TextField`, value)
                  value = value.replace(/\n/g, "<new-line>");
                  value = value.replace(/'/g, "<single-quote>");
                  value = value.replace(/"/g, "<double-quote>");
                  value = value.replace(/₹/g, "<rupee-symbol>");
                  value = value.replace(/\\/g, "");
                  value = value.replace(/\t/g, "");

                  const textElement = document.getElementById(
                    `${filterwork?.OrderId}`
                  );
                  // setPos(postoadd);
                  setEnteredvalueforBrandPurpose((prev) => {
                    return {
                      ...prev,
                      cursorPos: getCaretPosition(textElement), // need the cursor postion to add the selected brand from list
                    };
                  });

                  onChange(value.trim(), "onChangeTextField");
                }}
                onClick={onClick}
                onMouseDown={onMouseDown}
                inputRef={autoFocus}
              />
            )}
          />
          {isBrandlistOpen && (
            <div className="brands-container">
              {brandSuggestion &&
                arry &&
                JSON.parse(arry.Brands) !== null &&
                JSON.parse(arry?.Brands).map((item, indx) => {
                  console.log('testing we are the items which are appearing there in the sugestions')
                  return (
                    item
                      .toLowerCase()
                      .includes(filterBrandtext.toLowerCase()) && (
                      <p
                        className="item-of-brands"
                        onClick={() => {
                          const textElement = document.getElementById(
                            `${filterwork?.OrderId}`
                          );
                          // let postoadd = getCaretPosition(textElement);
                          let postoadd = enteredValueforBrandPurpose.cursorPos;
                          setEnteredvalueforBrandPurpose((prev) => {

                            // return strtoset + " @" + item.title + " ";
                            let textBeforeCUrsor = prev.value.slice(
                              0,
                              postoadd
                            );
                            let textAfterCUrsor = prev.value.slice(postoadd);
                            let lastIndex = textBeforeCUrsor.lastIndexOf(" ");
                            // txt1.slice(0, 3) + "bar" + txt1.slice(3);
                            textBeforeCUrsor = textBeforeCUrsor.substring(
                              0,
                              lastIndex
                            );
                            setBrandList(false);

                            // return prev.slice(0,postoadd) + "@"
                            return {
                              ...prev,
                              value:
                                textBeforeCUrsor +
                                " @" +
                                item +
                                textAfterCUrsor +
                                " ",
                            };
                          });
                          // tref.current.focus();
                          // document.getElementById(`${filterwork?.OrderId}`).focus();
                          let toset = postoadd + item.length;
                          textElement.focus();
                          setTimeout(() => {
                            textElement.setSelectionRange(toset, toset);
                          }, 0);
                        }}
                        key={indx}
                      >
                        {item}
                      </p>
                    )
                  );
                })}
              {/* <p>Add Brand</p> */}
              {filterBrandtext &&
                arry &&
                JSON.parse(arry.Brands) !== null &&
                !JSON.parse(arry?.Brands).some((brand) =>
                  brand.toLowerCase().includes(filterBrandtext.toLowerCase())
                ) && <p>No Options</p>}
            </div>
          )}
        </>
      ) : (
        <TextField
          multiline
          maxRows={4}
          error={value || hideRedLine ? false : true}
          style={{ minWidth: 80, width: "100%" }}
          id="standard-multiline-flexible"
          label={placeholder}
          disabled={disabled}
          onChange={(e) => {
            let value = e.currentTarget.value;
            console.log('in the values value in onCHange :', value);
            console.log('e.key : ', e)
            value = value.replace(/\n/g, "<new-line>");
            value = value.replace(/'/g, "<single-quote>");
            value = value.replace(/"/g, "<double-quote>");
            value = value.replace(/₹/g, "<rupee-symbol>");
            value = value.replace(/\\/g, "");
            value = value.replace(/\t/g, "");
            setEditValue(value);
            // onChange(value);
          }}
          onBlur={(e) => {
            // console.log('in the values  value in ONblur : ',e.currentTarget)
            let value = e.currentTarget.value;
            value = value.replace(/\n/g, "<new-line>");
            value = value.replace(/'/g, "<single-quote>");
            value = value.replace(/"/g, "<double-quote>");
            value = value.replace(/₹/g, "<rupee-symbol>");
            value = value.replace(/\\/g, "");
            value = value.replace(/\t/g, "");
            onChange(value.trim());
            setEditValue(null);
          }}
          onClick={onClick}
          onMouseDown={onMouseDown}
          value={
            editValue === null
              ? value &&
              value
                .trim()
                .toString()
                .replace(/<new-line>/g, "\n")
                .replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .replace(/<rupee-symbol>/g, "₹")
              : editValue &&
              editValue
                .toString()
                .replace(/<new-line>/g, "\n")
                .replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .replace(/<rupee-symbol>/g, "₹")
          }
          autoComplete="off"
          inputRef={autoFocus}
        />
      )}
    </>
  );
};
