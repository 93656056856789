import { secondDatabase } from "../../../config/firebase";
import saveOutwardInventoryData from "./updateOutwardNetInventoryData";
import { uploadImagesToFirebase } from "./uploadImagesToFirebase";

async function saveOutwardEntriesToFirebase(firmId, projectId, items, additionalInfo, itemsImages, previewImages, userName) {
  // Firebase reference path
  const basePath = `${firmId}/projects/${projectId}/records/allOutward/`;

  // Current timestamp for unique ID
  const timestamp = new Date().getTime();

  let imageTimestamp = new Date().getTime();

  for (let index = 0; index < items.length; index++) {
    if(itemsImages[index].length > 0){
      let imageArray = await uploadImagesToFirebase(itemsImages[index]);
      let imageObject = {};
      for(let url of imageArray){
        imageObject[imageTimestamp] = {
          url : url
        }
        imageTimestamp = imageTimestamp + 1;
      }
      items[index]['images'] = imageObject;
    }
  }

  const getBillImages = async () => {
    let imageObject = {};
    if(previewImages.length > 0){
      console.log('previewImages : ', previewImages)
      let imageArray = await uploadImagesToFirebase(previewImages);
      for(let url of imageArray){
        imageObject[imageTimestamp] = {
          url : url
        }
        imageTimestamp = imageTimestamp + 1;
      }
    }
    return imageObject;
  }

  // Construct the outward entry object
  const outwardEntry = {
    contractor: additionalInfo.contractor || "",
    id: timestamp,
    entryId: timestamp,
    isDraft: additionalInfo.isDraft || false,
    outwardItems: items.reduce((acc, item) => {
      acc[item.id] = {
        id: item.id,
        category: item.category || "Not provided",
        description: item.description || "Not provided",
        historyId: item.historyId || new Date().getTime(),
        issueHistory: {
          [timestamp]: {
            by: userName || "Not provided",
            purpose: item.purpose || "Not provided",
            quantity: item.quantityAccepted || "0",
            timestamp: timestamp,
            type: item.vehicle || "Not provided",
            images: item.images
          },
        },
        itemID: item.descriptionId,
        listOfPurpose: {
          [item.historyId || new Date().getTime()]: {
            label: "Add New Type/ Vehicle",
            value: "Add New Type/ Vehicle",
          },
        },
        quantityAvailable: "0",
        quantityAccepted: item.quantityAccepted,
        quantityIssued: item.quantityAccepted || "0",
        quantityRequired: "0",
        type: item.category === "Fuel" ? "vehicle" : null,
        tag: item.category === "Fuel" ? item.tag : null,
        uom: item.unit || "Not provided",
        unit: item.unit || "Not provided",
        images: item.images,
        purpose: item.purpose
      };
      return acc;
    }, {}),
    requestHistory: {
      [timestamp]: {
        associatedPersons: additionalInfo.associatedPersons || "Not provided",
        doneBy: userName || "System",
        forwardedTo: additionalInfo.forwardedTo || "Not provided",
        noOfItem: items.length,
        remark: additionalInfo.remark || "None",
        status: "Updated",
        timestamp: timestamp,
      },
    },
    requestNo: additionalInfo.requestNo || "",
    siteEngineer: additionalInfo.siteEngineer || "",
    status: additionalInfo.status || "Pending",
    timestamp: timestamp,
    villas: additionalInfo.villas || "",
    workProgress: additionalInfo.workProgress || "",
    images: await getBillImages()
  };

  // Save each outward item to inventory data
  for (let index = 0; index < items.length; index++) {
    await saveOutwardInventoryData(firmId, projectId, items[index], additionalInfo, timestamp, userName);
  }

  // Reference to the outward entry in Firebase
  const refPath = secondDatabase.ref(`${basePath}${timestamp}`);
  return refPath.update(outwardEntry)
    .then(() => console.log("Outward entry saved successfully."))
    .catch((error) => console.error("Error saving outward entry:", error));
}

export default saveOutwardEntriesToFirebase;
