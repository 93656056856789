import React, { useState, useEffect, useRef } from "react";
import { FiUpload, FiEye, FiX } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import firebase from "../../../config/firebase";
import "./../OrderItem.scss";
import IssuesChat from "./IssuesChat";
import PDFViewerModal from "./PdfViewModal";
import { MdErrorOutline } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";
import { sendNotification } from "./helper";
import { useLocation } from "react-router-dom";

const IssuesModal = ({ item, projectId, dummyIssues }) => {
  console.log("item", item);
  console.log("projectId", projectId);
  console.log("dummyIssues", dummyIssues);
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const pad = (num) => num.toString().padStart(2, "0");
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear().toString().slice(-2);
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const amPm = hours >= 12 ? "PM" : "AM";
    return `${day}/${month}/${year} : ${hours}:${minutes} ${amPm}`;
  };
  const [pdfUrl, setPdfUrl] = useState("");

  const location = useLocation();
  const data = location.state.selectedProject;

  const [fcmClient, setFCMClient] = useState("");
  const [device, setDevice] = useState("");

  const fetchFCM = async () => {
    var useRef = firebase
      .database()
      .ref(`clientUserDetails/${data.clientNumber}/tokens/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setDevice(data.deviceType);
        setFCMClient(data.fcmToken);
      }
    });
  };

  useEffect(() => {
    // write the code here
    fetchFCM();
  }, []);

  useEffect(() => {
    const ref = firebase
      .database()
      .ref(`clientDetails/${projectId}/agreement/url`);
    const onValueChange = (snapshot) => {
      const url = snapshot.val();
      setPdfUrl(url);
    };
    ref.on("value", onValueChange, (error) => {
      console.error("Error fetching PDF URL:", error);
    });
    // Cleanup function to detach the listener when the component unmounts
    return () => {
      ref.off("value", onValueChange);
    };
  }, [projectId]);

  const markIssueAsSolved = async (item) => {
    setOpenConfirmationModal(false);

    try {
      const path = `clientDetails/${projectId}/agreement/issues/${item.timestamp}/status`;
      const newStatus = item.status === "Solved" ? "In progress" : "Solved";
      await firebase.database().ref(path).set(newStatus);

      // call the function here to send the notification
      let mess = "";
      if (newStatus === "Solved") {
        mess = `Your issue ${item.text} is marked as solved by the Firm`;
      } else {
        mess = `Your issue ${item.text} is marked in progress by the Firm`;
      }

      sendNotification(
        "Issue ressolved",
        device,
        fcmClient,
        `${mess}`,
        data.clientNumber,
        "issues"
      );
    } catch (error) {
      console.error("Error marking issue as solved:", error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setUploading(true);
      setUploadProgress(0);

      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`agreements/${projectId}/${file.name}`);

      try {
        const uploadTask = fileRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Upload failed:", error);
            setUploading(false);
          },
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            await firebase
              .database()
              .ref(`clientDetails/${projectId}/agreement/url`)
              .set(downloadURL);
            setUploading(false);
          }
        );
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploading(false);
      }
    } else {
      alert("Please select a PDF file.");
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const incrementClientUnseenCount = async () => {
    let count = item?.unseenComment?.client + 1;

    if (count === undefined) {
      count = 0;
    }

    firebase
      .database()
      .ref(
        `clientDetails/${projectId}/agreement/issues/${item.timestamp}/unseenComment/client/`
      )
      .set(count);
  };

  return (
    <div
      style={{
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: item.status === "Solved" ? "#78C9A7" : "#FF7777",
        borderRadius: "4px",
        marginBottom: "20px",
      }}>
      <div
        className="order-heading-container"
        style={{
          backgroundColor: item.status === "Solved" ? "#DDF7E3" : "#FAD4D4",
        }}>
        <div className="order-details">
          <div
            className="order-details-vendor"
            style={{ alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  item.status === "Solved" ? "#78C9A7" : "#FF7777",
                borderRadius: "50%",
                width: 30,
                height: 30,
              }}>
              {item.status === "Solved" ? (
                <FaCheck size={20} color="#fff" />
              ) : (
                <MdErrorOutline size={20} color="#fff" />
              )}
            </div>
            <div className="order-heading-dates">
              <p
                style={{
                  color: item.status === "Solved" ? "#078420" : "#FF7777",
                  fontSize: 18,
                  fontWeight: 500,
                }}>
                Issue #{item.timestamp.toString().slice(-4)}
              </p>
              {item?.unseenComment?.firm > 0 ? (
                <p
                  style={{
                    color: "red",
                  }}>{`( ${item?.unseenComment?.firm} new messages from client.)`}</p>
              ) : null}
            </div>
          </div>

          <p style={{ fontWeight: "600" }}>Remark</p>

          <p style={{ width: "auto" }}>{item.text}</p>

          <div className="order-heading-dates">
            <p
              style={{
                color: "#333",
                fontSize: 14,
                fontWeight: 600,
              }}>
              Raising date and time : {formatTimestamp(item.timestamp)}
            </p>
          </div>
        </div>
        {!dummyIssues && (
          <div
            className="order-heading-amount"
            style={{ alignItems: "center" }}>
            <p
              style={{
                color: item.status === "Solved" ? "#078420" : "#000000",
              }}>
              Status : {" ("}
              {item.status}
              {")"}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 10,
                backgroundColor:
                  item.status === "Solved" ? "#078420" : "#6b7280",
                padding: 10,
                borderRadius: 10,
                cursor: "pointer",
                width: 200,
                justifyContent: "center",
              }}
              onClick={() => setOpenConfirmationModal(true)}>
              <FaCheck size={18} color="#fff" />
              <p
                style={{
                  fontWeight: "600",
                  color: "white",
                  marginLeft: 10,
                }}>
                {item.status === "Solved" ? "Solved" : "Mark as solved"}
              </p>
            </div>
          </div>
        )}

        <ConfirmationModal
          isOpen={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          onConfirm={() => markIssueAsSolved(item)}
          message={
            item.status === "Solved"
              ? "Are you sure you want to mark this issue as unsolved?"
              : "Are you sure you want to mark this issue as solved?"
          }
        />
      </div>

      <div style={{ height: 20 }} />

      <IssuesChat
        item={item.chats}
        invoiceId={item.timestamp}
        projectId={projectId}
        incrementClientUnseenCount={incrementClientUnseenCount}
        dummyIssues={dummyIssues}
      />

      {uploading && (
        <div
          style={{
            position: "fixed",
            top: 50,
            right: 45,
            width: 300,
            backgroundColor: "white",
            borderRadius: 12,
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.8), 0 1px 3px rgba(0, 0, 0, 0.8)",
            overflow: "hidden",
            zIndex: 9999,
          }}>
          <div style={{ padding: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}>
              <h3 style={{ margin: 0, fontSize: 18, fontWeight: 600 }}>
                Uploading Agreement
              </h3>
              <button
                onClick={() => setUploading(false)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: 5,
                }}>
                <FiX size={20} />
              </button>
            </div>
            <div
              style={{
                height: 6,
                backgroundColor: "#e0e0e0",
                borderRadius: 3,
                overflow: "hidden",
              }}>
              <div
                style={{
                  width: `${uploadProgress}%`,
                  height: "100%",
                  backgroundColor: "#4CAF50",
                  transition: "width 0.3s ease-in-out",
                }}
              />
            </div>
            <p style={{ marginTop: 10, fontSize: 14, color: "#666" }}>
              {uploadProgress.toFixed(1)}% Completed
            </p>
          </div>
        </div>
      )}

      <div
        style={{
          position: "fixed",
          bottom: "30px",
          right: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          zIndex: 1000,
        }}>
        <input
          type="file"
          accept="application/pdf"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileUpload}
        />
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px 25px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "30px",
            cursor: "pointer",
            transition: "all 0.3s ease",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            fontSize: "16px",
            fontWeight: "600",
            width: "250px",
          }}
          onClick={triggerFileInput}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#45a049";
            e.target.style.transform = "translateY(-2px)";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "#4CAF50";
            e.target.style.transform = "translateY(0)";
          }}>
          <FiUpload style={{ marginRight: "10px", fontSize: "20px" }} />
          Upload Agreement
        </button>
        <div>
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px 25px",
              backgroundColor: "#008CBA",
              color: "white",
              border: "none",
              borderRadius: "30px",
              cursor: "pointer",
              transition: "all 0.3s ease",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              fontSize: "16px",
              fontWeight: "bold",
              width: "250px",
            }}
            onClick={handleOpen}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#007B9A";
              e.target.style.transform = "translateY(-2px)";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#008CBA";
              e.target.style.transform = "translateY(0)";
            }}>
            <FiEye style={{ marginRight: "10px", fontSize: "20px" }} />
            View Agreement
          </button>
          <PDFViewerModal
            open={open}
            handleClose={handleClose}
            pdfUrl={pdfUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default IssuesModal;
