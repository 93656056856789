import { secondStorage, secondDatabase } from "../../../config/firebase";

/**
 * Uploads an array of image objects to Firebase using secondStorage
 * and stores metadata in secondDatabase.
 *
 * @param {Array} images - Array of image objects, each with `id` and `file` properties.
 * @param {string} basePath - Base path in Firebase Storage to save images.
 * @returns {Promise<Array>} - Returns a promise that resolves with an array of URLs.
 */
export const uploadImagesToFirebase = async (images, basePath = "images") => {

  if (!Array.isArray(images)) {
    throw new Error("Input must be an array of image objects.");
  }

  const uploadPromises = images.map(async (image, index) => {
    try {
      const file = image.file;
      const uniqueFileName = `${basePath}/image_${Date.now()}_${index}`;
      const fileRef = secondStorage.ref(uniqueFileName);

      // Upload file to Firebase Storage
      await fileRef.put(file);

      // Get the download URL for the uploaded file
      const downloadURL = await fileRef.getDownloadURL();

      // Save metadata in secondDatabase (optional)
      await secondDatabase
        .ref(`${basePath}/metadata/${index}`)
        .set({ id: image.id, url: downloadURL });

      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  });

  // Wait for all uploads to complete
  const uploadedURLs = await Promise.all(uploadPromises);
  return uploadedURLs;
};
