import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DropDown } from "../../components";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toast";
import { AiFillCheckCircle } from "react-icons/ai";
import LinearProgress from "@mui/material/LinearProgress";
import { CircularProgress } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import OpportunityService from "../../api/opportunityService";
import { UserContext } from "../../Context/UserContext";
import NotificationService from "../../api/notificationService";
import { getFinanceApprovalsData } from "./RequestedPayments";
import firebase from "../../config/firebase";

export default function PaymentOption({
  category,
  paymentOption,
  setPaymentOption,
  totalOrderAmount,
  totalPaidAmount,
  totalRemainingAmount,
  fetchRazorpayOrder,
  selectedOrderId,
  selectedOrderDataIndex,
  selectedMilestoneIndex,
  selectedOrderMilestoneData,
  startDate,
  ProjectId,
  ClientName,
  requestedAmount,
  vendorCompanyName,
  fromFinanceReqApprovals,
  PayFromFinanceApprovals,
  selectedProject,
  onUpdateUTRNumber,
}) {

  const handleReleasedOrder = async () => {

    const id = localStorage.getItem('User');
    if (!id) {
      console.error("User ID not found in session storage.");
      return;
    }
    const parsedId = JSON.parse(id);
    if (!parsedId || !parsedId.firmId) {
      console.error("Firm ID not found in parsed user data.");
      return;
    }

    const FirmId = parsedId.firmId;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-GB');
    const getDateTimestamp = (date) => {
      const startOfDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      return startOfDay.getTime().toString();
    };

    const timestamp = getDateTimestamp(currentDate);
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const weekName = weekDays[currentDate.getDay()];

    const updatedAnalyticsData = {
      count: 1,
      date: formattedDate,
      id: timestamp,
      name: weekName,
      topHistory: {},
      value: parseFloat(totalOrderAmount),
    };
    const updatedHistoryData = {
      category: category,
      name: JSON.parse(vendorCompanyName) && JSON.parse(vendorCompanyName).vendorCompanyName !== "" ? JSON.parse(vendorCompanyName).vendorCompanyName : "NA",
      value: parseFloat(totalOrderAmount),
      id: timestamp,
      poId: selectedOrderId,
      projectName: ClientName
    };
    const releasedPORef = firebase.database().ref(`poInformation/${FirmId}/releasedPO`);
    const poAnalyticsRef = firebase.database().ref(`poInformation/${FirmId}/poAnalytics`);
    const poHistoryRef = firebase.database().ref(`poInformation/${FirmId}/poHistory`);
    try {
      const releasedSnapshot = await releasedPORef
        .child(selectedOrderId)
        .once("value");
      const isReleased = releasedSnapshot.val();
      if (isReleased) {
        console.log("PO already released. Skipping the update");
      } else {
        const updatePoAnalytics = async (ref, updatedData) => {
          try {
            console.log('Starting updatePoAnalytics function...');
            console.log('Reference path:', ref.toString());
            console.log('Updated data:', updatedData);
            const snapshot = await ref.child(updatedData.id).once('value');
            const existingData = snapshot.val();
            console.log('Existing data in poAnalytics:', existingData);
            let updates;
            if (existingData) {
              const updatedCount = existingData.count + 1;
              const updatedValue = parseFloat(existingData.value) + parseFloat(updatedData.value);
              const historySnapshot = await poHistoryRef.child(updatedData.id).once('value');
              const historyData = historySnapshot.val();
              console.log('Fetched history data:', historyData);
              let topHistory = {};
              if (historyData) {
                const sortedHistory = Object.values(historyData)
                  .sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
                  .slice(0, 3);
                sortedHistory.forEach(entry => {
                  topHistory[entry.poId] = entry;
                });
                console.log('Updated topHistory data:', topHistory);
              }
              updates = {
                count: updatedCount,
                value: updatedValue,
                topHistory: topHistory,
              };
            } else {
              const historySnapshot = await poHistoryRef
                .child(updatedData.id)
                .once("value");
              const historyData = historySnapshot.val();
              console.log('Fetched history data:', historyData);
              if (historyData) {
                const sortedHistory = Object.values(historyData)
                  .sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
                  .slice(0, 3);
                sortedHistory.forEach(entry => {
                  updatedData.topHistory[entry.poId] = entry;
                });
                console.log('Updated topHistory data:', updatedData.topHistory);
              }
              updates = updatedData;
            }
            await ref.child(updatedData.id).update(updates);
            console.log("Count, value, and topHistory updated in poAnalytics.");
          } catch (error) {
            console.error("Error updating poAnalytics data:", error);
          }
        };
        const updatePoHistory = async (ref, updatedData) => {
          try {
            console.log('Starting updatePoHistory function...');
            console.log('Reference path:', ref.toString());
            console.log('Updated data:', updatedData);
            const snapshot = await ref.child(updatedData.id).once('value');
            const historyData = snapshot.val() || {};
            if (historyData[updatedData.poId]) {
              console.log('PO already exists in poHistory. No update performed.');
              return;
            }
            historyData[updatedData.poId] = updatedData;
            await ref.child(updatedData.id).set(historyData);
            console.log('poHistory data updated successfully.');
          } catch (error) {
            console.error("Error updating poHistory data:", error);
          }
        };
        await updatePoHistory(poHistoryRef, updatedHistoryData);
        await updatePoAnalytics(poAnalyticsRef, updatedAnalyticsData);
        await releasedPORef.child(selectedOrderId).set({
          released: true,
          day: timestamp
        });
        console.log('PO release status and day timestamp updated.');
      }
    } catch (error) {
      console.error("Error handling released order", error);
    }
  };
  
  const [reqRecData, setReqRecData] = useState([]);

  useEffect(() => {

    if (paymentOption.isRequest) {
      const financeApprovalsData = getFinanceApprovalsData(
        selectedOrderId
      ).then((res) => {
        res && setReqRecData(res);
      });
    }

  }, []);

  const [utrNumber, setUtrNumber] = useState("");
  const userContext = useContext(UserContext);
  const [amountPayable, setAmountPayable] = useState(0);
  const [isProgress, setIsProgress] = useState(false);
  const [modeOfPayment, setModeOfPayment] = useState("Bank");
  const [dateofPayment, setDateofPayment] = useState(new Date().getTime());
  const [remarks, setRemarks] = useState();

  const updateTheDataToTheFirebase = async (requestId, amountPayable) => {

    firebase
      .database()
      .ref("poRequests/" + selectedOrderId + "/" + requestId)
      .set({
        paymentMilestoneName:
          selectedOrderMilestoneData[selectedMilestoneIndex],
        requestedAmount: amountPayable,
        totalPaidAmount: 0,
        utrDetails: "",
        status: "Bill Raised",
      });

    var useRef = firebase
      .database()
      .ref(`poRequests/${selectedOrderId}/balances/requestedAmount`);
    
    let current_paid_amount = amountPayable;
    let ttlAmount = false;
    
    useRef
      .once("value", function (snapshot) {
        var data = snapshot.val();
        if (data == null) {
          ttlAmount = true;
        } else {
          current_paid_amount = Number(data) + Number(amountPayable);
        }
      })
      .then(async () => {
        
        await firebase
          .database()
          .ref("poRequests/" + selectedOrderId + "/balances/requestedAmount/")
          .set(current_paid_amount);

        await firebase
          .database()
          .ref("poRequests/" + selectedOrderId + "/balances/totalAmount/")
          .set(totalOrderAmount);
        
      });
  };

  const generateNewRequest = async () => {

    if (amountPayable == undefined || amountPayable <= 0) {
      return window.alert('Please enter amount greater than "0" ');
    }

    setIsProgress(true);

    let dataObject = {};
    let requestId = new Date().getTime();
    dataObject["OrderId"] = selectedOrderId;
    dataObject["RequestId"] = requestId;
    dataObject["Status"] = "Request";
    dataObject["History"] = {
      [requestId]: {
        accountType: userContext.user.accountType,
        raised: userContext.user.email,
        status: "Requested",
        date: dateofPayment,
        remarks: remarks,
        By: userContext.user.email.slice(
          0,
          userContext.user.email.indexOf("@")
        ),
      },
    };
    dataObject["AmountRequested"] = amountPayable;
    dataObject["ProjectDetails"] = { [ProjectId]: ClientName };
    dataObject["Firm"] = userContext.user.Firm;
    dataObject["vendorCompanyName"] = vendorCompanyName;
    dataObject["details"] = {
      milestoneIndexRequested: selectedMilestoneIndex,
      RequestedOn: new Date().getTime(),
    };
    dataObject["DOFReq"] = new Date();

    const opportunityService = new OpportunityService();

    const responseUpdate = await opportunityService.addFinanceRequest(
      dataObject
    );

    if (responseUpdate.status === 200) {
      const notificationService = new NotificationService();
      updateTheDataToTheFirebase(requestId, amountPayable);
      handleReleasedOrder();
      notificationService.financeNotifications(
        userContext.user.Firm,
        {
          message: `Request raised for payment of Rs.${amountPayable} in ${ClientName} `,
          Status: "NotRead",
          sectionSource: "Request",
          user: userContext.user.email,
          accountType: "Admin",
          requestId: responseUpdate.results.insertId,
          time: new Date().getTime(),
        },
        "Admin"
      );
      toast.success("Request Sent");
    } else {
      toast.error("Error while generating a Request");
    }

    setIsProgress(false);
    setPaymentOption((prev) => {
      return { ...prev, isModalOpen: false };
    });
  };

  const validateUTR = (utr) => {
    const utrRegex = /^[a-zA-Z0-9]{16}$/;
    return utrRegex.test(utr);
  };

  const handleUTRChange = (e) => {
    setUtrNumber(e.target.value);
  };

  const handleValidation = () => {
    if (!validateUTR(utrNumber)) {
      setUtrNumber("");

      alert("Invalid UTR number. Please enter a valid UTR number.");
    }
  };

  return (
    <Modal
      open={paymentOption.isModalOpen}
      onClose={() =>
        !isProgress &&
        setPaymentOption((prev) => {
          return { ...prev, isModalOpen: false };
        })
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "80%",
          maxHeight: "90%",
          maxWidth: "70%",
        }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}>
          {/* <LinearProgress style={{    top: 0, position: "absolute",width: "100%", left: "0"}}/> */}
          {isProgress ? (
            <LinearProgress
              style={{ top: 0, position: "absolute", width: "100%", left: "0" }}
            />
          ) : (
            ""
          )}
          {paymentOption.isRequest && (
            <div style={{ width: "100%", marginBottom: "1em" }}>
              <div>
                <h4 style={{ textAlign: "center" }}>Requests and Records</h4>
              </div>
              {reqRecData.length > 0 ? (
                <table
                  style={{ width: "75%", margin: "auto", borderSpacing: 0 }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid", borderRight: "0px" }}>
                        S.No
                      </th>
                      <th style={{ border: "1px solid", borderRight: "0px" }}>
                        Amount Requested
                      </th>
                      <th style={{ border: "1px solid", borderRight: "0px" }}>
                        Status
                      </th>
                      <th style={{ border: "1px solid" }}>Requested By</th>
                      <th style={{ border: "1px solid", borderLeft: "0px" }}>
                        Milestone Requested
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {reqRecData.map((request, rIndex) => (
                      <tr
                        style={{
                          // display: "flex",
                          // justifyContent: "center",
                          background: `${
                            JSON.parse(request.details)
                              ?.milestoneIndexRequested ===
                            selectedMilestoneIndex
                              ? "khaki"
                              : "none"
                          }`,
                          textAlign: "center",
                        }}
                        key={request.uuid}>
                        <td
                          style={{
                            width: "5%",
                            border: "1px solid",
                            borderRight: 0,
                          }}>
                          {rIndex + 1}
                        </td>
                        <td
                          style={{
                            width: "25%",
                            border: "1px solid",
                            borderRight: "none",
                          }}>
                          {request.AmountRequested}
                        </td>
                        <td
                          style={{
                            width: "15%",
                            border: "1px solid",
                            borderRight: "none",
                          }}>
                          {request.Status}
                        </td>
                        <td
                          style={{
                            width: "25%",
                            border: "1px solid",
                          }}>
                          {request.History &&
                            Object.values(JSON.parse(request.History))[0].By}
                        </td>
                        <td
                          style={{
                            width: "5%",
                            border: "1px solid",
                            borderLeft: "none",
                          }}>
                          {request.details &&
                            JSON.parse(request.details)
                              ?.milestoneIndexRequested + 1}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h5 style={{ textAlign: "center" }}>No Records or Requests</h5>
              )}
              
            </div>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              gap: 40,
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
              <div style={{ display: "flex", gap: 4 }}>
                <p style={{ fontWeight: 600 }}> Total order amount = </p>
                <p> {totalOrderAmount}</p>
                <p> /-</p>
              </div>
              {selectedOrderMilestoneData && <div style={{ display: "flex", gap: 4 }}>
                <p style={{ fontWeight: 600 }}> Total milestone amount = </p>
                <p>
                  {" "}
                  {
                    selectedOrderMilestoneData[selectedMilestoneIndex]
                      .totalAmount
                  }
                </p>
                <p> /-</p>
              </div>}
             
            </div>
            <div style={{ display: "flex", gap: 4 }}>
              <p style={{ fontWeight: 600 }}> Paid = </p>
              <p> {totalPaidAmount}</p>
              <p> /-</p>
            </div>
            <div style={{ display: "flex", gap: 4 }}>
              <p style={{ fontWeight: 600 }}> Remaining = </p>
              <p> {totalRemainingAmount}</p>
              <p> /-</p>
            </div>
            {fromFinanceReqApprovals && (
              <div style={{ display: "flex", gap: 4 }}>
                <p style={{ fontWeight: 600 }}> Requested Amonunt = </p>
                <p> {requestedAmount}</p>
                <p> /-</p>
              </div>
            )}
          </div>
          <div
            style={{
              margin: "1em 0em",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <input
              disabled={isProgress}
              placeholder="Amount"
              value={amountPayable}
              style={{
                width: "43%",
                fontSize: "16px",
                borderWidth: 1,
                border: "1px solid #3535354d",
                outline: "none",
                height: "2em",
              }}
              type="number"
              min="0"
              onChange={(e) => {
                setAmountPayable(e.currentTarget.value);
              }}></input>
            <div
              style={{
                display: "flex",
                width: "40%",
                justifyContent: "space-between",
              }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  // style={{margin:"-8px"}}
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  value={dateofPayment}
                  onChange={(date) => {
                    console.log(
                      "datee",
                      date,
                      "timestamp : ",
                      new Date(date).getTime()
                    );
                    setDateofPayment(new Date(date).getTime());
                  }}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  minDate={startDate}
                />
              </MuiPickersUtilsProvider>
              {!paymentOption.isRequest && (
                <DropDown
                  disabled={isProgress}
                  data={["Bank", "Online", "Cash", "Cheque", "Other"]}
                  value={modeOfPayment}
                  onChange={(value) => {
                    setModeOfPayment(value);
                    // console.log("value : ", value);
                    // if (value == "Cash" ) {
                    //   // write the code here
                    //   // setCashPay(false);
                    // } else if(value == "Online") {
                    //   // console.log('other mode value : ',value)
                    //   // setCashPay(true);
                    // }
                    // else{
                    //   // setCashPay(false);
                    // }
                  }}></DropDown>
              )}
            </div>

            {/* {!paymentOption.isRequest && (
              <div style={{ width: "100%" }}>
                <input
                  placeholder="UTR Number"
                  value={utrNumber}
                  onChange={handleUTRChange}
                  onBlur={handleValidation}
                  style={{
                    padding: "1em",
                    fontSize: "1rem",
                    width: "40%",
                    margin: "auto",
                    height: "40px",
                    borderWidth: 1,
                    border:
                      validateUTR(utrNumber) || utrNumber === ""
                        ? "1px solid #3535354d"
                        : "1px solid red",

                    outline: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
            )} */}
          </div>

          <div style={{ width: "40%" }}>
            <textarea
              placeholder="Remarks"
              style={{
                padding: "1em",
                fontSize: "1rem",
                width: "100%",
                margin: "auto",
                height: "100px",
                resize: "none",
              }}
              cols="70"
              rows="5"
              onChange={(e) => {
                let value = e.currentTarget.value.replace(/\n/g, "<new-line>");
                value = value.replace(/'/g, "<single-quote>");
                value = value.replace(/"/g, "<double-quote>");
                value = value.replace(/₹/g, "<rupee-symbol>");
                value = value.replace(/\\/g, "");
                value = value.replace(/\t/g, "");
                setRemarks(value);
              }}></textarea>
          </div>
          {
            !paymentOption.isRequest && (
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#FDD34D",
                  width: "30%",
                  justifyContent: "center",
                  padding: 15,
                  borderRadius: 5,
                  cursor: `${isProgress ? "progress" : "pointer"}`,
                }}
                onClick={() => {
                  if (fromFinanceReqApprovals) {
                    setIsProgress(true);
                    PayFromFinanceApprovals(
                      amountPayable,
                      modeOfPayment,
                      remarks,
                      dateofPayment,
                      utrNumber
                    );
                  } else {
                    if (amountPayable == undefined || amountPayable <= 0) {
                      return window.alert(
                        'Please enter amount greater than "0" '
                      );
                    }
                    if (Number(amountPayable) > Number(totalRemainingAmount)) {
                      // alert('Amount entered is more than the remaining amount')
                      if (
                        window.confirm(
                          "Entered amount is more than remaining amount. Do you want to make extra payment?"
                        )
                      ) {
                        // write the code here
                        if (
                          modeOfPayment == "Cash" ||
                          modeOfPayment == "Cheque" ||
                          modeOfPayment == "Other" ||
                          modeOfPayment == "Bank"
                        ) {
                          setIsProgress(true);

                          fetchRazorpayOrder(
                            selectedOrderMilestoneData,
                            selectedOrderId,
                            selectedOrderDataIndex,
                            selectedMilestoneIndex,
                            amountPayable * 100,
                            dateofPayment,
                            remarks,
                            utrNumber,
                            false,
                            modeOfPayment
                          );
                        } else {
                          setIsProgress(true);
                          fetchRazorpayOrder(
                            selectedOrderMilestoneData,
                            selectedOrderId,
                            selectedOrderDataIndex,
                            selectedMilestoneIndex,
                            amountPayable * 100,
                            dateofPayment,
                            remarks,
                            utrNumber,
                            true,
                            modeOfPayment
                          );
                        }
                      } else {
                        setPaymentOption((prev) => {
                          return { ...prev, isModalOpen: false };
                        });
                      }
                    } else {
                      if (
                        modeOfPayment == "Cash" ||
                        modeOfPayment == "Cheque" ||
                        modeOfPayment == "Other" ||
                        modeOfPayment == "Bank"
                      ) {
                        // write the code here
                        setIsProgress(true);
                        // console.log("mode of payment is cash");
                        toast.success(`Payment in progress`, {
                          duration: 4000,
                          position: "bottom-center",
                          style: {
                            borderRadius: "8px",
                            padding: "16px",
                            color: "#1F9A5E",
                            backgroundColor: "#F2FFF9",
                            maxWidth: "100%",
                          },
                          icon: <AiFillCheckCircle color={"#1F9A5E"} />,
                        });
                        fetchRazorpayOrder(
                          // selectedOrderData,
                          selectedOrderMilestoneData,
                          selectedOrderId,
                          selectedOrderDataIndex,
                          selectedMilestoneIndex,
                          amountPayable * 100,
                          dateofPayment,
                          remarks,
                          false,
                          modeOfPayment,
                          utrNumber
                        );
                        // console.log("fetchRazorpayOrder has been called");
                        // setIsProgress(false)
                      } else {
                        // write the code here
                        // console.log("mode of payment is online");
                        setIsProgress(true);
                        fetchRazorpayOrder(
                          // selectedOrderData,
                          selectedOrderMilestoneData,
                          selectedOrderId,
                          selectedOrderDataIndex,
                          selectedMilestoneIndex,
                          amountPayable * 100,
                          dateofPayment,
                          remarks,
                          true,
                          modeOfPayment,
                          utrNumber
                        );
                      }
                    }
                    // }
                  }
                }}>
                Pay
                {isProgress && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      width: "20px",
                      height: "20px",
                      color: "rgb(70 155 240)",
                    }}
                  />
                )}
              </div>
            )
            // )
            // )
          }
          {paymentOption.isRequest && (
            <div>
              <button
                style={{
                  display: "flex",
                  backgroundColor: "#FDD34D",
                  justifyContent: "center",
                  padding: 15,
                  border: "none",
                  fontWeight: "700",
                  borderRadius: 5,
                  cursor: `${isProgress ? "progress" : "pointer"}`,
                }}
                disabled={isProgress ? true : false}
                onClick={() => {
                  generateNewRequest();
                  // handleReleasedOrder();
                }}>
                Request
                {isProgress && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      width: "20px",
                      height: "20px",
                      color: "rgb(70 155 240)",
                    }}
                  />
                )}
              </button>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}
