import { createContext, useContext } from "react";
// import firebase from '../config/firebase';
// const { analytics } = firebase;
import { analytics } from "../config/firebase";

const AnalyticsContext = createContext(null);

let TAGS = [
  "@boq_tab_click",
  "@purchase_tab_click",
  "@edit_project_button_click",
  "@edit_project_button_click",
  "@sample_boq_button_click",
  "@import_boq_button_click",
  "@download_boq_button_click",
  "@share_to_client_button_click",
  "@version_history_button_click",
  "@home_icon_click",
  "@boq_icon_click",
  "@analytics_icon_click",
  "@finance_icon_click",
  "@hide_work_icon_click",
  "@show_work_icon_click",
  "@hide_material_icon_click",
  "@show_material_icon_click",
  "@new_project_button_click",
  "@live_button_click",
  "@duplicate_archive_menu_icon_click",
  "@work_order_tab_click",
  "@purchase_order_tab_click",
  "@work_quotation_tab_click",
  "@material_quotation_tab_click",
  "@update_draft_button_click",
  "@save_draft_button_click",
  "@preview_button_click",
  "@release_order_button_click",
  "@release_quotation_button_click",
  "@suggestive_text_component_tab_click",
  "@suggestive_text_material_tab_click",
  "@suggestive_text_work_tab_click",
  "@suggestive_text_po_tab_click",
  "@suggestive_text_firm_tab_click",
  "@suggestive_text_save_work_material_icon_click",
  "@suggestive_text_edit_work_material_icon_click",
  "@suggestive_text_delete_work_material_icon_click",
  "@suggestive_text_save_material_icon_click",
  "@suggestive_text_edit_material_icon_click",
  "@suggestive_text_delete_material_icon_click",
  "@suggestive_text_save_work_icon_click",
  "@suggestive_text_edit_work_icon_click",
  "@suggestive_text_delete_work_icon_click",
  "@suggestive_text_save_po_icon_click",
  "@suggestive_text_edit_po_icon_click",
  "@suggestive_text_delete_po_icon_click",
].reduce(
  (final, currentItem) => ({
    ...final,
    [`${currentItem}_success`]: `${currentItem.slice(1)}_success`,
    [`${currentItem}_error`]: `${currentItem.slice(1)}_error`,
  }),
  {}
);

// console.log(TAGS)

const AnalyticsContextProvider = (props) => {
  const record = (TAG, object) => analytics.logEvent(TAG, object);
  return (
    <AnalyticsContext.Provider value={{ record }}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};

const useAnalyticsContext = () => {
  const ctx = useContext(AnalyticsContext);
  return ctx;
};

export { AnalyticsContextProvider, useAnalyticsContext, TAGS };
