import React, { useContext, useEffect, useState } from "react";
import firebase from "../../../config/firebase";
import { UserContext } from "../../../Context/UserContext";

function Logo() {
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const userContext = useContext(UserContext);
  const [imageUrl, setImageUrl] = useState(null);
  var storageRef = firebase.storage().ref();

  useEffect(() => {
    const localUrl = sessionStorage.getItem("firmLogoURL");
    if (localUrl) {
      setImageUrl(localUrl);
      return;
    }
    storageRef
      .child(`firm/${userContext.user.Firm}.jpg`)
      .getDownloadURL()
      .then((url) => {
        setImageUrl(url);
        sessionStorage.setItem("firmLogoURL", url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleImageUpload = async (e) => {
    const [file] = e.target.files;
    if (file) {
      // const reader = new FileReader();
      // const { current } = uploadedImage;
      // current.file = file;
      // reader.onload = e => {
      //     current.src = e.target.result;
      await storageRef.child(`firm/${userContext.user.Firm}.jpg`).put(file);

      storageRef
        .child(`firm/${userContext.user.Firm}.jpg`)
        .getDownloadURL()
        .then((url) => {
          setImageUrl(url);
        })
        .catch((error) => {
          console.log(error);
        });

      // var storage = firebase.storage();
      // storage.ref(`firm/${userContext.user.Id}.jpg`);
      // storage.refFromURL('gs://startstaging.appspot.com/firm/undefined.jpg');
      // storage.refFromURL('https://firebasestorage.googleapis.com/v0/b/startstaging.appspot.com/o/firm%2Fundefined.jpg?alt=media&token=f3f00934-2aaf-4468-8685-1be4106ce6e3');
      // };
      // reader.readAsDataURL(file);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={imageUploader}
        style={{
          display: "none",
        }}
      />
      <div
        style={{
          height: "80px",
          width: "80px",
          borderRadius: 50,
        }}
        onClick={() => imageUploader.current.click()}
      >
        <img
          ref={uploadedImage}
          src={imageUrl}
          style={{
            width: "100%",
            height: "100%",
            position: "acsolute",
            display: "",
            borderRadius: 50,
          }}
        />
      </div>
    </div>
  );
}

export default Logo;
