import React from 'react';

const ImagePreviewModal = ({ image, onClose }) => (
  <div style={styles.overlay}>
    <div style={styles.modal}>
      <button style={styles.closeButton} onClick={onClose}>Close</button>
      <img src={image} alt="Preview" style={styles.image} />
    </div>
  </div>
);

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '90%',
    maxHeight: '90%',
    textAlign: 'center',
  },
  closeButton: {
    color: '#fff',
    backgroundColor: '#e57373',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '80vh',
  },
};

export default ImagePreviewModal;
