import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import NoData from "./Components/NoData";

// Sample data

const columnWidths = {
    invoiceNumber: '10%',
    name: '10%',
    poNumber: '12%',
    purpose: '28%',
    quantity: '8%',
    status: '8%',
    timestamp: '12%',
    entryBy: '8%',
    actions: '4%'
  };

const MaterialDetails = ({material}) => {

  console.log('material : ', material);

  const [materials, setMaterials] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  useEffect(() => {
    // write the code here
    if(material.historyDetails){
      setMaterials(() => Object.values(material.historyDetails).reverse())
    }
  }, [])

  const handleEdit = (material) => {
    setSelectedMaterial(material);
    setOpenDialog(true);
  };

  const handleDelete = (poNumber) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      setMaterials(
        materials.filter((material) => material.poNumber !== poNumber)
      );
    }
  };

  const handleSave = (updatedMaterial) => {
    setMaterials(
      materials.map((material) =>
        material.poNumber === updatedMaterial.poNumber
          ? updatedMaterial
          : material
      )
    );
    setOpenDialog(false);
  };

  const formatTimestamp = (timestamp) => {
    return new Date(parseInt(timestamp)).toLocaleString();
  };

  const commonCellStyles = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    padding: '16px 8px',
    fontSize: '0.875rem',
  };

  const headerCellStyles = {
    ...commonCellStyles,
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#1a237e',
  };

  return (
    <div style={{ padding: "20px" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 100px)" }}>
          <Table stickyHeader sx={{ width: "100%", tableLayout: "fixed" }}>
            {
              console.log('materials : ', JSON.stringify(material))
            }
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.invoiceNumber,
                    zIndex: 1,
                  }}
                >
                  Invoice Number
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.poNumber,
                  }}
                >
                  PO Number
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.purpose,
                  }}
                >
                  {material.category === 'Fuel' && `Vehicle Tag | `}Purpose
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.quantity,
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.status,
                  }}
                >
                  Status
                </TableCell>
                {/* <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.timestamp,
                  }}
                >
                  Timestamp
                </TableCell> */}
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.entryBy,
                  }}
                >
                  Entry By
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.actions,
                    position: "sticky",
                    right: 0,
                    zIndex: 2,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {materials.length > 0 ? materials.map((material) => (
                <TableRow
                  key={material.poNumber}
                  sx={{
                    backgroundColor:
                      material.status === "Inward" ? "#e8f5e9" : "#ffebee",
                    "&:hover": {
                      backgroundColor:
                        material.status === "Inward" ? "#c8e6c9" : "#ffcdd2",
                    },
                  }}
                >
                  <TableCell sx={commonCellStyles}>
                    {material.invoiceNumber !== 'Not provided' ? material.invoiceNumber : '-'}
                  </TableCell>
                  <TableCell sx={commonCellStyles}>
                    {material.poNumber !== 'Not provided' ? material.poNumber : '-'}
                  </TableCell>
                  <TableCell sx={commonCellStyles}>
                  <span style={{fontWeight: 'bold'}}>{material.vehicleType && `${material.vehicleType} | `}</span>{material.purpose}
                  </TableCell>
                  <TableCell sx={commonCellStyles}>
                    {material.quantity}
                  </TableCell>
                  <TableCell sx={commonCellStyles}>{material.status}</TableCell>
                  {/* <TableCell sx={commonCellStyles}>
                    {formatTimestamp(material.timeStamp)}
                  </TableCell> */}
                  <TableCell sx={commonCellStyles}>
                    {material.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...commonCellStyles,
                      position: "sticky",
                      right: 0,
                      backgroundColor:
                        material.status === "Inward" ? "#e8f5e9" : "#ffebee",
                      "&:hover": {
                        backgroundColor:
                          material.status === "Inward" ? "#c8e6c9" : "#ffcdd2",
                      },
                      padding: "8px 4px",
                      display: "flex",
                      justifyContent: "center",
                      gap: "4px",
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleEdit(material)}
                      sx={{ padding: "4px" }}
                    >
                      <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(material.poNumber)}
                      sx={{ padding: "4px" }}
                    >
                      <DeleteIcon fontSize="small" color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )) : <NoData />}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Edit Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Material Entry</DialogTitle>
        <DialogContent>
          {selectedMaterial && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "16px",
              }}
            >
              <TextField
                label="Invoice Number"
                defaultValue={selectedMaterial.invoiceNumber}
                onChange={(e) =>
                  setSelectedMaterial({
                    ...selectedMaterial,
                    invoiceNumber: e.target.value,
                  })
                }
              />
              <TextField
                label="Name"
                defaultValue={selectedMaterial.name}
                onChange={(e) =>
                  setSelectedMaterial({
                    ...selectedMaterial,
                    name: e.target.value,
                  })
                }
              />
              <TextField
                label="Purpose"
                defaultValue={selectedMaterial.purpose}
                onChange={(e) =>
                  setSelectedMaterial({
                    ...selectedMaterial,
                    purpose: e.target.value,
                  })
                }
              />
              <TextField
                label="Quantity"
                defaultValue={selectedMaterial.quantity}
                onChange={(e) =>
                  setSelectedMaterial({
                    ...selectedMaterial,
                    quantity: e.target.value,
                  })
                }
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => handleSave(selectedMaterial)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MaterialDetails;
