import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "../../../config/config";
import { PopOverView, TextArea } from "../../../components";
import { FiMoreVertical } from "react-icons/fi";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { environment } from "../../../config/config";
import toast from "react-hot-toast";

import "./../OrderItem.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChatComponent from "./ChatComponent";
import PDFViewerModal from "./PdfViewModal";
import firebase from "../../../config/firebase";
import ConfirmationModal from "./ConfirmationModal";
import rejectedStamp from "./rejected-stamp.png";
import paidStamp from "./paid-stamp.png";
import { sendNotification } from "./helper";
import MarkAsPaidButton from "./MarkAsPaidButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PaymentRequest = ({ item, projectId }) => {
  const [pendingDays, setPendingDays] = useState(0);
  const [pdfUrl, setPdfUrl] = useState("");
  const [viewInvoice, setViewInvoice] = useState(false);
  const location = useLocation();
  const data = location.state.selectedProject;

  const handleViewInvoice = () => {
    setViewInvoice(!viewInvoice);
  };

  function calculateDaysDifference(timestamp1, timestamp2) {
    // Convert timestamps to Date objects
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date2 - date1);

    // Convert milliseconds to days
    let differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

    if (timestamp1 < timestamp2) {
      differenceDays = differenceDays * -1;
    }

    return differenceDays;
  }

  useEffect(() => {
    let timeStampToday = new Date().getTime();
    setPendingDays(() => calculateDaysDifference(item.dueDate, timeStampToday));
  }, []);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const pad = (num) => num.toString().padStart(2, "0");

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${day}/${month}/${year} : ${hours}:${minutes}:${seconds}`;
  }

  function formatNumberToIndianLocale(number) {
    const numberParts = number.toString().split(".");
    let integerPart = numberParts[0];
    const decimalPart = numberParts.length > 1 ? "." + numberParts[1] : "";

    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== "") {
      integerPart =
        otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        lastThreeDigits;
    } else {
      integerPart = lastThreeDigits;
    }

    return integerPart + decimalPart;
  }

  const updateUnseeForTheClient = async () => {
    let count = item.unseenComment.client + 1;

    if (count === undefined) {
      count = 0;
    }

    firebase
      .database()
      .ref(
        `clientDetails/${data.ProjectId}/invoiceDetails/${item.id}/unseenComment/client/`
      )
      .set(count);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  const rejectInvoice = async () => {
    firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/invoiceDetails/${item.id}/reject/`)
      .set(true);
    setConfirmationModal(false);
    sendNotification(
      "Invoice rejected",
      device,
      fcmClient,
      `${data.Firm} has rejected the invoice of ₹ ${item.amount} with you.`,
      data.clientNumber,
      "invoices"
    );
  };

  const restoreInvoice = async () => {
    firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/invoiceDetails/${item.id}/reject/`)
      .set(false);
    setConfirmationModal(false);
    sendNotification(
      "Invoice restored",
      device,
      fcmClient,
      `${data.Firm} has restored the invoice of ₹ ${item.amount} with you.`,
      data.clientNumber,
      "invoices"
    );
  };

  // here we are going to fetch the fcmToken of the client

  const [fcmClient, setFCMClient] = useState("");
  const [device, setDevice] = useState("");

  const fetchFCM = async () => {
    var useRef = firebase
      .database()
      .ref(`clientUserDetails/${data.clientNumber}/tokens/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setDevice(data.deviceType);
        setFCMClient(data.fcmToken);
      }
    });
  };

  useEffect(() => {
    // write the code here
    fetchFCM();
  }, []);

  const handleMarkAsPaid = async () => {
    console.log("here is what we are going to mark");
    firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/invoiceDetails/${item.id}/status/`)
      .set("Paid");
  };

  return (
    <div
      style={{
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#FDD34D",
        borderRadius: "4px",
        marginBottom: "40px",
        position: "relative",
      }}>
      {item?.status === "Paid" && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "10%",
            transform: "translate(-50%, -50%) rotate(-15deg)",
            fontSize: "100px",
            fontWeight: "bold",
            fontFamily: "Arial, sans-serif",
            opacity: 0.3,
            color: "#808080",
            zIndex: 1,
            pointerEvents: "none",
            userSelect: "none",
            width: "100%",
            textAlign: "center",
            textTransform: "uppercase",
            letterSpacing: "10px",
            WebkitTextStroke: "2px #606060",
            textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
          }}>
          {/* <p>REJECTED</p> */}
          <img
            src={paidStamp}
            alt="Watermark"
            style={{
              width: "15%",
              height: "15%",
              objectFit: "contain",
            }}
          />
        </div>
      )}
      {item?.reject && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "10%",
            transform: "translate(-50%, -50%) rotate(-15deg)",
            fontSize: "100px",
            fontWeight: "bold",
            fontFamily: "Arial, sans-serif",
            opacity: 0.15,
            color: "#808080",
            zIndex: 1,
            pointerEvents: "none",
            userSelect: "none",
            width: "100%",
            textAlign: "center",
            textTransform: "uppercase",
            letterSpacing: "10px",
            WebkitTextStroke: "2px #606060",
            textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
          }}>
          {/* <p>REJECTED</p> */}
          <img
            src={rejectedStamp}
            alt="Watermark"
            style={{
              width: "15%",
              height: "15%",
              objectFit: "contain",
            }}
          />
        </div>
      )}

      <div
        className="order-heading-container"
        style={{
          backgroundColor: item?.status === "Requested" ? "#ecf7b2" : "#e0f0e9",
        }}>
        <div className="order-details">
          <div
            className="order-details-vendor"
            style={{ alignItems: "center" }}>
            <div className="order-details-icon">
              <GrUserWorker size={15} />
            </div>
            <div className="order-heading-dates">
              <p>Raising Date: {formatTimestamp(item.id).split(":")[0]}</p>
              <p>Due Date: {formatTimestamp(item.dueDate).split(":")[0]}</p>
              <p>
                {pendingDays > 0
                  ? "Due in " + pendingDays + " days"
                  : "pending since " + Math.abs(pendingDays) + " days"}
              </p>
              {item?.unseenComment?.firm > 0 ? (
                <p
                  style={{
                    color: "red",
                  }}>{`( ${item?.unseenComment?.firm} new messages from client.)`}</p>
              ) : null}
            </div>
          </div>
          <p style={{ fontWeight: "600" }}>Remark</p>
          <p style={{ width: "auto" }}>{item.remark}</p>
        </div>

        <div className="order-heading-amount" style={{ alignItems: "center" }}>
          <p
            style={{
              paddingTop: 5,
              fontWeight: "bold",
              color: item.status === "Requested" ? "#000000" : "#078420",
            }}>
            Requested Amount
          </p>
          <p
            style={{
              color: item.status === "Requested" ? "#000000" : "#078420",
            }}>
            {"\u20B9"} {formatNumberToIndianLocale(item.amount)}
            {" ("}
            {item.status}
            {")"}
          </p>
          {!item?.reject && item?.status === "Approved" ? (
            <MarkAsPaidButton status={item.status} onClick={handleMarkAsPaid} />
          ) : item?.status === "Paid" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "8px 16px",
                backgroundColor: "#E8F5E9", // Very light green background for the container
                borderRadius: "25px", // Slightly rounded corners
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}>
              <CheckCircleIcon
                style={{
                  color: "#4CAF50", // Green color indicating success
                  fontSize: "20px", // Adjust icon size for a formal look
                  marginRight: "8px", // Space between icon and text
                }}
              />
              <p
                style={{
                  margin: 0, // Remove default margin
                  padding: "4px 12px", // Padding for the text
                  backgroundColor: "#4CAF50", // Green color indicating success
                  color: "white",
                  borderRadius: "15px", // Rounded corners
                  fontSize: "14px", // Slightly smaller font size
                  fontWeight: "500", // Medium weight for a formal look
                  border: "1px solid #388E3C", // Slightly thinner border
                }}>
                Paid
              </p>
            </div>
          ) : null}
        </div>

        <div className="order-heading-util">
          <div style={{ width: "2%", paddingTop: 5, cursor: "pointer" }}>
            <PopOverView
              position="left"
              component={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    fontSize: 12,
                    color: "grey",
                    alignItems: "flex-start",
                    width: "140",
                  }}>
                  <p
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      setPdfUrl(item?.attachments?.invoice?.url);
                      setViewInvoice(true);
                    }}
                    className="menuItem">
                    View Invoice
                  </p>

                  <p
                    className="menuItem"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      setConfirmationModal(true);
                    }}>
                    {item?.reject === true
                      ? "Restore Invoice"
                      : "Reject Invoice"}
                  </p>
                </div>
              }>
              <FiMoreVertical />
            </PopOverView>
          </div>
        </div>
      </div>
      {/* <div style={{ height: 20 }} /> */}

      <ChatComponent
        item={item.comment}
        invoiceId={item.id}
        projectId={projectId}
        updateUnseeForTheClient={updateUnseeForTheClient}
        fcmToken={fcmClient}
        device={device}
      />

      <PDFViewerModal
        open={viewInvoice}
        handleClose={handleViewInvoice}
        pdfUrl={pdfUrl}
      />

      <ConfirmationModal
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={item?.reject === true ? restoreInvoice : rejectInvoice}
        message={
          item?.reject === true
            ? `Are you sure you want to restore this invoice?`
            : `Are you sure you want to reject this invoice?`
        }
      />
    </div>
  );
};

export default PaymentRequest;
