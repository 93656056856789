import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import config from "../../config/config";
import {  DropDownMUI } from "../../components";
import { FiInfo, FiSave } from "react-icons/fi";
import { Tooltip } from "antd";
import {InfoCircleOutlined} from '@ant-design/icons';

import {
  AiFillCheckCircle,
  AiOutlineInfoCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import getVendorPrice from "../../helpers/getVendorPrice";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { environment } from "../../config/config";
import toast from "react-hot-toast";
import { BiUpArrowAlt } from "react-icons/bi";
import CircularProgress from "@material-ui/core/CircularProgress";
import OrderItem from "./OrderItem";
import ReceivedAmountCard from "./ReceivedAmountCard";

let orderCheckStatus = [];
let milestoneCheckStatus = [];
let lineItemRemainingAmounts = [];

export default function Ongoing({ selectedProject }) {

  const location = useLocation();
  const data = location.state?.selectedProject || selectedProject;
   const [revision, setRevision] = useState(data.rooms);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All");
 const [searchItems, setSearchItems] = useState("");
  const [workType, setWorkType] = useState("All");
  const [status, setStatus] = useState("All");
    const [received, setReceived] = useState(0);
  const [receivedInput, setReceivedInput] = useState("");
  const [bufferPercentage, setBufferPercentage] = useState(0);
  const [payableTaxPercentage, setPayableTaxPercentage] = useState(0);
  const [amountHistory, setAmountHistory] = useState([]);
  const [editableitem, setEditableItem] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPosValue, setTotalPosValue] = useState(0);
  const [backToTop, setBackTotop] = useState(false);
  const [isSpinner, setSpinner] = useState(true);
  const [remarks, setRemarks] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipPay, setShowTooltipPay] = useState(false);
  const [showTooltipSpend, setShowTooltipSpend] = useState(false);
  const tooltipRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltipPay(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltipSpend(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTooltipToggle = () => {
    setShowTooltip(!showTooltip);
  };

  
  const handleTooltipTogglePay = () => {
    setShowTooltipPay(!showTooltipPay);
  };
  const handleTooltipToggleSpend = () => {
    setShowTooltipSpend(!showTooltipSpend);
  };

  useEffect(() => {
    document
      .getElementById("container")
      .addEventListener("scroll", function () {
        if (this.scrollTop === 0) {
          setBackTotop(false);
        } else {
          setBackTotop(true);
        }
      });
  }, []);

  const backToTopHandler = () => {
    document
      ?.getElementById("container")
      ?.scroll({ top: 0, behavior: "smooth" });
  };


  const Razorpay = useRazorpay();

  useEffect(() => {
    if (data) {
      setRevision(data.rooms);
    }
  }, [data]);


  const [allOrders, setAllOrders] = useState([]);
  const [releasedOrders, setReleasedOrders] = useState([]);

  useEffect(() => {
    fetchReleasedOrders();
  }, []);

  const fetchReleasedOrders = async () => {
    // setSpinner(true)
    orderCheckStatus = [];
    milestoneCheckStatus = [];
    lineItemRemainingAmounts = [];

    fetch(`${config.orderService}getServiceOrders/${data.ProjectId}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from getServiceOrders API: ", json);
        // console.log("json.servicesPayload : ", json.servicesPayload);
        setAllOrders(json.servicesPayload);
        // console.log('Calling ;- rewritingGetTotalSpent');
        rewritingGetTotalSpent(json.servicesPayload);

        let inCompleteOrders = [];
        let totalOrderAmount = 0;
        let totalMilestoneAmount = 0;

        for (
          let index = 0;
          index < Object.keys(json.servicesPayload).length;
          index++
        ) {
          let tempMilestonesObject = JSON.parse(
            json.servicesPayload[index].CommonMilestones
          );
          // totalOrderAmount = 0;
          totalOrderAmount = json.servicesPayload[index].OrderTotalAmount || 0;
          totalMilestoneAmount = 0;
          for (
            let jindex = 0;
            jindex < tempMilestonesObject?.length;
            jindex++
          ) {
            if (
              tempMilestonesObject[jindex].name !== "Paid" &&
              tempMilestonesObject[jindex]["payments"]
            ) {
              for (
                let pindex = 0;
                pindex < tempMilestonesObject[jindex]["payments"]?.length;
                pindex++
              ) {
                // console.log('payment object',tempMilestonesObject[jindex]["payments"][pindex])
                // console.log('amount',tempMilestonesObject[jindex]["payments"][pindex].amount)
                totalMilestoneAmount =
                  totalMilestoneAmount +
                  Number(
                    tempMilestonesObject[jindex]["payments"][pindex].amount
                  );
              }

            }
          }
          // totalMilestoneAmount = totalMilestoneAmount + tempMilestonesObject && (tempMilestonesObject[0]?.name === 'Paid' ? tempMilestonesObject[0]?.totalAmount : 0)
          totalMilestoneAmount =
            Number(totalMilestoneAmount) +
            (tempMilestonesObject
              ? tempMilestonesObject[0]?.name === "Paid"
                ? Number(tempMilestonesObject[0]?.totalAmount)
                : 0
              : 0);
              

          if (
            totalOrderAmount == 0 ||
            totalOrderAmount - totalMilestoneAmount > 1
          ) {
            inCompleteOrders.push(json.servicesPayload[index]);
          }
        }

        let inCompletedCategories = [];

        inCompleteOrders
          .filter((item) => item.ApprovalStatus != "inApproval")
          .map((item, index) => inCompletedCategories.push(item.Category));
        setCategories([...new Set(inCompletedCategories)]);
        setReleasedOrders(inCompleteOrders);
        console.log("inCompleteOrders : ", inCompleteOrders);
        setSpinner(false);
      })
      .catch((e) => {
        console.log("getServiceOrders catch: ", e.toString());
        toast.error("Error fetching released orders data!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0 ",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
      });
  };

  const [totalSpent, setTotalSpent] = useState(0);
  const [totalToPay, setTotalToPay] = useState(0);

  // const getTotalSpent = (releasedOrders) => {
  //   let totalAmount = 0;
  //   let totalSpent = 0;
  //   for (let index = 0; index < Object.keys(releasedOrders).length; index++) {
  //     let milestoneObject = {};
  //     let category = activeCategory;
  //     if (activeCategory == "All") {
  //       // milestoneObject = JSON.parse(releasedOrders[index]['Data'])['data'][0]['milestones'];
  //       milestoneObject = JSON.parse(releasedOrders[index]["Data"])["data"];
  //     } else {
  //       if (activeCategory == releasedOrders[index].Category) {
  //         milestoneObject = JSON.parse(releasedOrders[index]["Data"])[
  //           "data"
  //         ][0]["milestones"];
  //       } else {
  //       }
  //     }
  //     if (milestoneObject) {
  //       for (let mItem = 0; mItem < milestoneObject.length; mItem++) {
  //         if (milestoneObject[mItem].hasOwnProperty("milestones")) {
  //           if (activeCategory == "All") {
  //             if (
  //               milestoneObject[mItem]["milestones"][0].hasOwnProperty(
  //                 "payments"
  //               )
  //             ) {
  //               for (
  //                 let payment = 0;
  //                 payment <
  //                 milestoneObject[mItem]["milestones"][0]["payments"].length;
  //                 payment++
  //               ) {
  //                 totalSpent += Number(
  //                   milestoneObject[mItem]["milestones"][0]["payments"][
  //                     payment
  //                   ]["amount"]
  //                 );
  //               }
  //             } else {
  //             }
  //           } else {
  //             // writing the things here for getting the totalSPent amount for selected category
  //           }
  //         } else {
  //         }
  //         if (activeCategory == "All") {
  //           totalAmount = (
  //             works
  //               .map(
  //                 (work) =>
  //                   work.status == "ordered" &&
  //                   work.rate != "quotation" &&
  //                   (
  //                     work.quantity * work.rate +
  //                       work.quantity * work.rate * (work.gst / 100) || 0
  //                   ).toFixed(0)
  //               )
  //               .filter((item) => item != false)
  //               .reduce(
  //                 (total, item) => (total = Number(total) + Number(item)),
  //                 0
  //               ) +
  //             materials
  //               .map(
  //                 (material) =>
  //                   material.status == "ordered" &&
  //                   material.rate != "quotation" &&
  //                   (
  //                     material.quantity * material.rate +
  //                       material.quantity *
  //                         material.rate *
  //                         (material.gst / 100) || 0
  //                   ).toFixed(0)
  //               )
  //               .filter((item) => item != false)
  //               .reduce(
  //                 (total, item) => (total = Number(total) + Number(item)),
  //                 0
  //               )
  //           ).toFixed(0);
  //           setSpent((totalSpent / 100000).toFixed(2));
  //           setTotalToPay(((totalAmount - totalSpent) / 100000).toFixed(2));
  //           setTotalSpent((totalSpent / 100000).toFixed(2));
  //         } else {
  //           totalAmount = (
  //             works
  //               .map(
  //                 (work) =>
  //                   work.status == "ordered" &&
  //                   work.rate != "quotation" &&
  //                   category == work.vendorCategory &&
  //                   (
  //                     work.quantity * work.rate +
  //                       work.quantity * work.rate * (work.gst / 100) || 0
  //                   ).toFixed(0)
  //               )
  //               .filter((item) => item != false)
  //               .reduce(
  //                 (total, item) => (total = Number(total) + Number(item)),
  //                 0
  //               ) +
  //             materials
  //               .map(
  //                 (material) =>
  //                   material.status == "ordered" &&
  //                   material.rate != "quotation" &&
  //                   category == material.type &&
  //                   (
  //                     material.quantity * material.rate +
  //                       material.quantity *
  //                         material.rate *
  //                         (material.gst / 100) || 0
  //                   ).toFixed(0)
  //               )
  //               .filter((item) => item != false)
  //               .reduce(
  //                 (total, item) => (total = Number(total) + Number(item)),
  //                 0
  //               )
  //           ).toFixed(0);
  //           setTotalSpent((totalSpent / 100000).toFixed(2));
  //           setTotalToPay(((totalAmount - totalSpent) / 100000).toFixed(2));
  //         }
  //       }
  //     } else {
  //     }
  //   }
  // };

  useEffect(() => {
    // getTotalSpent(releasedOrders);
    // console.log('allOrders : ', allOrders)
    allOrders && rewritingGetTotalSpent(allOrders);
    backToTopHandler();
  }, [activeCategory]);

  useEffect(() => {
    let startDateArray = [];
    let endDateArray = [];

    releasedOrders &&
      releasedOrders.map((item, index) => {
        startDateArray.push(Date.parse(JSON.parse(item.Data)?.StartDate));

        endDateArray.push(Date.parse(JSON.parse(item.Data)?.EndDate));
      });

    startDateArray = startDateArray.sort((a, b) => a - b);

    endDateArray = endDateArray.sort((a, b) => b - a);

    startDateArray[0] &&
      setStartDate(new Date(startDateArray[0]).toString().substring(4, 10));
    endDateArray[0] &&
      setEndDate(new Date(endDateArray[0]).toString().substring(4, 10));

    // console.log('startDate : ', new Date(startDateArray[0]).toString().substring(4, 10));

    // console.log('endDate : ', new Date(endDateArray[0]).toString().substring(4, 10));
  }, [releasedOrders]);

  // const getBankAccountDetails = (mobile) => {
  //   fetch(`${config.profileService}bankAccount/${mobile}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "*/*",
  //       Authorization:
  //         "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ",
  //     },
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((json) => {
  //       if (json.payload.length > 0) {
  //         setBankAccountDetails(json.payload[0]);
  //         setOpenBankDetailsModel(true);
  //       } else {
  //         setBankAccountDetails({});
  //         setOpenBankDetailsModel(true);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("getBankAccountDetails error: ", e.toString());
  //     });
  // };

  const [razorpayOrder, setRazorpayOrder] = useState({});

  // const fetchRazorpayOrder = (
  //   orderData,
  //   orderId,
  //   index,
  //   milestoneIndex,
  //   orderAmount,
  //   onlineMode,
  //   // multiplePaymentOption
  // ) => {
  //   console.log('checking values in ongoing','orderAmount: ', orderAmount)
  //   fetch(
  //     `${config.transactionService}` + "createRazorpayOrder/" + orderAmount,
  //     {
  //       method: "GET",
  //       headers: {
  //         Authorization:
  //           "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
  //         Accept: "/",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((json) => {
  //       console.log("Response from fetchRazorpayOrder API: ", json);
  //       setRazorpayOrder(json.payload);
  //       if (json.status == 200) {
  //         payment(
  //           orderData,
  //           orderId,
  //           index,
  //           milestoneIndex,
  //           razorpayOrder.id,
  //           orderAmount,
  //           onlineMode
  //         );
  //       }else{
  //         payment(
  //           orderData,
  //           orderId,
  //           index,
  //           milestoneIndex,
  //           'not available',
  //           orderAmount,
  //           onlineMode
  //         );
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("fetchRazorpayOrder catch: ", e.toString());
  //       toast.error("Error fetching razorpay order data!", {
  //         duration: 4000,
  //         position: "bottom-center",
  //         style: {
  //           borderRadius: "8px",
  //           padding: "16px",
  //           color: "#E72424",
  //           backgroundColor: "#FEF0F0 ",
  //           maxWidth: "100%",
  //         },
  //         icon: <FiInfo color={"#E72424"} />,
  //       });
  //     });
  // };



  const fetchRazorpayOrderMultiplePayment = (orderAmount, paymentMode) => {
    fetch(
      `${config.transactionService}` + "createRazorpayOrder/" + orderAmount,
      {
        method: "GET",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
          Accept: "/",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from fetchRazorpayOrder API: ", json);
        setRazorpayOrder(json.payload);
        if (json.status == 200) {
          multiplePaymentFunction(
            razorpayOrder.id,
            orderAmount * 100,
            true,
            paymentMode
          );
        }
      })
      .catch((e) => {
        console.log("fetchRazorpayOrder catch: ", e.toString());
        toast.error("Error fetching razorpay order data!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0 ",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
      });
  };

 
  const multiplePaymentFunction = (
    razorpayOrderId,
    orderAmount,
    onlineMode,
    paymentMode
  ) => {
    if (onlineMode) {
      // write the code here
      if (orderAmount > 0) {
        const options = {
          key:
            environment == "staging"
              ? "rzp_test_NT8hdIJJv8h4io"
              : "rzp_live_5zu6ALnDKySSQb", // Enter the Key ID generated from the Dashboard
          Amount: orderAmount,
          currency: "INR",
          name: "Staart Buildtech",
          description: "Vendor Payment",
          image:
            "https://static.wixstatic.com/media/1797c0_bc28110ef07142e7807421547ca506de~mv2.png/v1/fill/w_108,h_60,al_c,q_85,usm_0.66_1.00_0.01/Staart-Logo_edited.webp",
          order_id: razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: function (response) {
 updateMultipleMilestonesPayments(orderAmount);
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });

        rzp1.open();
      } else {
        alert("Please enter amount");
      }
    } 
  };

  const updateMilestonePayment = async (
    orderData,
    orderId,
    index,
    milestoneIndex,
    paymentID,
    orderAmount,
    modeOfPayment,
    multiple
  ) => {

    await fetch(`${config.orderService}serviceOrder/${orderId}`, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Data: JSON.stringify({
          ...orderData,
          data: [...orderData.data].map((dataItem, dataIndex) =>
            dataIndex == index
              ? {
                  ...dataItem,

                  milestones: dataItem.milestones.map((mItem, mIndex) =>
                    mIndex == milestoneIndex
                      ? "payments" in mItem
                        ? {
                            ...mItem,
                            payments: [
                              ...mItem.payments,
                              {
                                paymentID: paymentID,
                                date: new Date().getTime(),
                                amount: orderAmount / 100,
                                status: "Done",
                                mode: modeOfPayment,
                              },
                            ],
                          }
                        : {
                            ...mItem,
                            payments: [
                              {
                                paymentID: paymentID,
                                date: new Date().getTime(),
                                amount: orderAmount / 100,
                                status: "Done",
                                mode: modeOfPayment,
                              },
                            ],
                          }
                      : mItem
                  ),
                }
              : (console.log(`unequal`), dataItem)
          ),
        }),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from updateMilestonePayment API: ", json);
        if (json.status == 200) {
          toast.success(`${orderAmount / 100} paid successfully!`, {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1F9A5E",
              backgroundColor: "#F2FFF9",
              maxWidth: "100%",
            },
            icon: <AiFillCheckCircle color={"#1F9A5E"} />,
          });
          console.log("Paid.");
          if (multiple) {
          } else {
            fetchReleasedOrders();
          }
        }
      })
      .catch((e) => {
        console.log("updateMilestonePayment error: ", e.toString());
      });
  };

  const updateAccountDetails = (amountHistorytoUpdate = amountHistory) => {
    let totalReceivedAmount = Number(
      amountHistorytoUpdate.reduce(
        (totalAmount, item) => (totalAmount += +item.amount),
        0
      )
    );
 setReceivedInput("");
    setRemarks("");
    fetch(`${config.projectService}${data.ProjectId}`, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Finance: JSON.stringify({
          AmountReceived: totalReceivedAmount,
          bufferPercentage,
          payableTaxPercentage,
          amountHistory: amountHistorytoUpdate,
        }),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        toast.success(
          `Amount received (\u20b9 ${
            totalReceivedAmount - +received
          }) updated successfully!`,
          {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1F9A5E",
              backgroundColor: "#F2FFF9",
              maxWidth: "100%",
            },
            icon: <AiFillCheckCircle color={"#1F9A5E"} />,
          }
        );
      })
      .catch((e) => {
        console.log("updateAccountDetails catch: ", e.toString());
        toast.error("Error updating account details!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0 ",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
      });
  };

  const updateMultipleMilestonesPayments = async (orderAmount) => {
    let orderIdMultiplePayment = "paid_via_group_item_pay";
    let totalRemainingAmountForPO = lineItemRemainingAmounts[
      orderCheckStatus.indexOf(true)
    ].reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue),
      0
    );
    for (
      let index = 0;
      index <
      JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data).data
        .length;
      index++
    ) {
      console.log("This what we are looking : ");
      console.log(
        JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data).data[
          index
        ]
      );
      console.log(
        "orderId for the updateMilestonePayment API : ",
        JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data).data[
          index
        ].OrderId
      );
      let orderId = JSON.parse(
        releasedOrders[orderCheckStatus.indexOf(true)].Data
      ).data[index].OrderId;
     if (
        JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data).data[
          index
        ]["milestones"]
      ) {
        let lineItemAmountToPay = (
          lineItemRemainingAmounts[orderCheckStatus.indexOf(true)][index] /
          totalRemainingAmountForPO
        ).toFixed(2);
        counter = 0;
       
        for (
          let jindex = 0;
          jindex <
          JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data).data[
            index
          ]["milestones"].length;
          jindex++
        ) {
          let mItem = JSON.parse(
            releasedOrders[orderCheckStatus.indexOf(true)].Data
          ).data[index]["milestones"][jindex];
          let orderData = JSON.parse(
            releasedOrders[orderCheckStatus.indexOf(true)].Data
          );
          let milestoneIndex = jindex;
          let paymentID = orderIdMultiplePayment;
          let modeOfPayment = "online";
          let milestonePaid = 0;
          if (mItem.hasOwnProperty("payments")) {
            let pItem = mItem["payments"];
            for (let pIndex = 0; pIndex < mItem["payments"].length; pIndex++) {
              milestonePaid += Number(mItem["payments"][pIndex].amount);
            }
          } 
          let totalMilestoneAmount =
            (JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data)
              .data[index].quantity *
              JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data)
                .data[index].rate +
              JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data)
                .data[index].quantity *
                JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data)
                  .data[index].rate *
                (JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data)
                  .data[index].gst /
                  100)) *
              (mItem.percentage / 100) -
            counter;

          counter =
            jindex ==
            JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data)
              .data[index].milestones.length -
              1
              ? 0
              : (JSON.parse(releasedOrders[orderCheckStatus.indexOf(true)].Data)
                  .data[index].quantity *
                  JSON.parse(
                    releasedOrders[orderCheckStatus.indexOf(true)].Data
                  ).data[index].rate +
                  JSON.parse(
                    releasedOrders[orderCheckStatus.indexOf(true)].Data
                  ).data[index].quantity *
                    JSON.parse(
                      releasedOrders[orderCheckStatus.indexOf(true)].Data
                    ).data[index].rate *
                    (JSON.parse(
                      releasedOrders[orderCheckStatus.indexOf(true)].Data
                    ).data[index].gst /
                      100)) *
                (mItem.percentage / 100);
          let milestoneRemaining = totalMilestoneAmount - milestonePaid;
         await updateMilestonePayment(
            orderData,
            orderId,
            index,
            milestoneIndex,
            paymentID,
            (
              orderAmount *
              (lineItemAmountToPay *
                (milestoneRemaining /
                  lineItemRemainingAmounts[orderCheckStatus.indexOf(true)][
                    index
                  ]))
            ).toFixed(2),
            modeOfPayment,
            true
          );
        }
      } 
    }
    initialiseChecks();
    fetchReleasedOrders();
  };

  const rewritingGetTotalSpent = (releasedOrders) => {
     let totalAmount = 0;
    let totalSpent = 0;
    lineItemRemainingAmounts = [];
    releasedOrders = releasedOrders.filter(
      (item) => item.ApprovalStatus != "inApproval"
    );
    

    for (let index = 0; index < Object.keys(releasedOrders).length; index++) {
      let milestoneObj = {};
      if (activeCategory == "All") {
        milestoneObj = JSON.parse(releasedOrders[index]["CommonMilestones"]);
      } else {
if (activeCategory === releasedOrders[index]["Category"]) {
          milestoneObj = JSON.parse(releasedOrders[index]["CommonMilestones"]);
        }
      }
      if (milestoneObj) {
        if (activeCategory === "All") {
          totalAmount += Number(releasedOrders[index]["OrderTotalAmount"]);
          for (let mindex = 0; mindex < milestoneObj.length; mindex++) {
            if (
              milestoneObj[mindex].name !== "Paid" &&
              milestoneObj[mindex].payments
            ) {
              for (
                let pindex = 0;
                pindex < milestoneObj[mindex].payments.length;
                pindex++
              ) {
                totalSpent += Number(
                  milestoneObj[mindex].payments[pindex].amount
                );
              }
            }
          }
          totalSpent = Number(
            totalSpent +
              (milestoneObj[0].name === "Paid"
                ? Number(milestoneObj[0].totalAmount)
                : 0)
          );
        } else {
          if (activeCategory === releasedOrders[index]["Category"]) {
            totalAmount += Number(releasedOrders[index]["OrderTotalAmount"]);
            for (let mindex = 0; mindex < milestoneObj.length; mindex++) {
              if (
                milestoneObj[mindex].name !== "Paid" &&
                milestoneObj[mindex].payments
              ) {
                for (
                  let pindex = 0;
                  pindex < milestoneObj[mindex].payments.length;
                  pindex++
                ) {
                  totalSpent += Number(
                    milestoneObj[mindex].payments[pindex].amount
                  );
                }
              }
            }
            totalSpent = Number(
              totalSpent +
                (milestoneObj[0].name === "Paid"
                  ? Number(milestoneObj[0].totalAmount)
                  : 0)
            );
          }
        }
      } else {
      }
    }

    if (activeCategory == "All") {
      setTotalPosValue(Number(totalAmount.toFixed(2)));
      setTotalToPay(Number((totalAmount - totalSpent).toFixed(2)));
      setTotalSpent(Number(totalSpent.toFixed(2)));
    } else {
      setTotalPosValue(Number(totalAmount.toFixed(2)));
      setTotalSpent(Number(totalSpent.toFixed(2)));
      setTotalToPay(Number((totalAmount - totalSpent).toFixed(2)));
    }
  };

  const initialiseChecks = () => {
    for (let index = 0; index < orderCheckStatus.length; index++) {
      orderCheckStatus[index] = false;
    }
    for (let index = 0; index < milestoneCheckStatus.length; index++) {
      for (
        let jindex = 0;
        jindex < milestoneCheckStatus[index].length;
        jindex++
      ) {
        milestoneCheckStatus[index][jindex] = false;
      }
    }
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  const getProjectDetails = () => {
    fetch(`${config.projectService}getProjectsByProjectId/${data.ProjectId}`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let tempFinance = JSON.parse(json.payload[0].Finance);
        setReceived(
          tempFinance?.AmountReceived?.length == 0
            ? 0
            : Number(tempFinance?.AmountReceived)
        );
      
        setPayableTaxPercentage(tempFinance?.payableTaxPercentage);
        setBufferPercentage(tempFinance?.bufferPercentage);
        setAmountHistory(tempFinance.amountHistory || []);
      })
      .catch((e) => {
        console.log("getProjectDetails catch: ", e.toString());
        toast.error("Error getting project details!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0 ",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
      });
  };


  var counter = 0;

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
     
      <div
        style={{
          border: "1px solid #E1E1E1",
          width: "15%",
          height: "86%",
          fontSize: "13px",
          position: "fixed",
          overflow: "scroll",
        }}
      >
        <p style={{ color: "#231700", margin: "10px", position: "sticky" }}>
          {activeCategory == "All"
            ? releasedOrders.filter(
                (item) => item.ApprovalStatus != "inApproval"
              ).length
            : releasedOrders.filter(
                (item) =>
                  item.ApprovalStatus != "inApproval" &&
                  item.Category == activeCategory
              ).length}
          {" released orders"}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            position: "sticky",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Category</p>
          <p style={{ fontWeight: "bold" }}>Amount</p>
        </div>
        {isSpinner ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{ width: "30px", height: "30px", color: "#fdd34d" }}
            />
          </div>
        ) : (
          <div
            style={
              "All" == activeCategory
                ? {
                    backgroundColor: "#FFF8E1",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 10,
                    cursor: "pointer",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 10,
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              setActiveCategory("All");
            }}
          >
            <p>All</p>
            <p>
              {"\u20B9 "}
         
              {releasedOrders
                .filter((item) => item.ApprovalStatus != "inApproval")
                .reduce((totalCategoryAmount, item) => {
                  return (totalCategoryAmount += Number(item.OrderTotalAmount));
                }, 0)
                .toFixed(2)}
            </p>
          </div>
        )}
        {categories
          .sort((a, b) => a.localeCompare(b))
          .map((category, index) => (
            <div
              key={index}
              style={
                category == activeCategory
                  ? {
                      backgroundColor: "#FFF8E1",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
                  : {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
              }
              onClick={() => {
                setActiveCategory(category);
              }}
            >
              <p>{category}</p>
              <p>
                {"\u20B9 "}
                {releasedOrders
                  .filter(
                    (item) =>
                      item.ApprovalStatus != "inApproval" &&
                      item.Category == category
                  )
                  .reduce((totalCategoryAmount, item) => {
                    return (totalCategoryAmount += Number(
                      item.OrderTotalAmount
                    ));
                  }, 0)
                  .toFixed(2)}
              </p>
            </div>
          ))}
      </div>
      <div
        style={{
          width: "80%",
          height: "90%",
          padding: "0px 10px 10px 10px",
          marginLeft: "15%",
          position: "fixed",
          overflow: "scroll",
        }}
        id="container"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 20,
            width: "100%",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.03)",
              borderRadius: "4px",
              padding: 10,
              paddingLeft: 15,
              maxHeight: 300,
              minHeight: 300,
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontWeight: "bold" }}>Insight-{activeCategory}</p>
            <br />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "23%",
                  maxWidth: "23%",
                  minWidth: "23%",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginRight: 3, position: "relative" }}>
                  Total Value
                  <Tooltip title="Total value of the project">
                    <InfoCircleOutlined size={12} />
                  </Tooltip>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#99C39E",
                  width: "76%",
                  borderRadius: 10,
                  maxWidth: "77%",
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "8%",
                    fontSize: 14,
                    // color: "#99C39E",
                    color: "black",
                    fontWeight: 700,
                    alignItems: "center",
                    marginLeft: 2,
                  }}
                >
               
                  {totalPosValue.toLocaleString("en-IN")}
                </div>
              </div>
            </div>
            <br />
            
            <br />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "23%",
                  maxWidth: "23%",
                  minWidth: "23%",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginRight: 3, position:"relative" }}>
                  Paid Amount
                  <Tooltip title="Total amount paid till date to the vendor">
                    <InfoCircleOutlined size={12} />
                  </Tooltip>
                </div>
                
              </div>
              <div
                style={{
                  backgroundColor: "#C8DDF4",
                  width:
                    String(
                      76 / totalPosValue
                   
                    ) *
                      totalSpent +
                    "%",
                  borderRadius: 10,
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "8%",
                    fontSize: 14,
                    // color: "#8FACCB",
                    color: "black",
                    fontWeight: 700,
                    alignItems: "center",
                    marginLeft: 2,
                  }}
                >
                  {totalSpent.toLocaleString("en-IN")}
                </div>
              </div>
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "23%",
                  maxWidth: "23%",
                  minWidth: "23%",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginRight: 3, position: "relative" }}>To Pay</div>
                <Tooltip title="Total amount to be paid till date to the vendor">
                  <InfoCircleOutlined size={12} />
                </Tooltip>
              
              </div>
              <div
                style={{
                  backgroundColor: "#F5C9C9",
                  width:
                    String(
                      76 / totalPosValue
                    ) *
                      totalToPay +
                    "%",
                  borderRadius: 10,
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "8%",
                    fontSize: 14,
                    fontWeight: 700,
                    // color: "#D99B9B",
                    color: "black",
                    alignItems: "center",
                    marginLeft: 2,
                  }}
                >
                  {totalToPay.toLocaleString("en-IN")}
                </div>
              </div>
            </div>
            <br />
            <div
              style={{ display: "flex", flexDirection: "row", marginLeft: 25 }}
            >
              <div style={{ width: "12%", opacity: 0 }}>.</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  borderRadius: 10,
                  height: 2,
                  gap: "10%",
                }}
              >
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    height: "5px",
                    width: "1px",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", marginLeft: "12%" }}>
                {startDate && startDate}
              </div>
              <div style={{ display: "flex", marginLeft: "70%" }}>
                {endDate && endDate}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "50%",
              maxHeight: 300,
              minHeight: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.03)",
                  borderRadius: "4px",
                  padding: 10,
                  width: "75%",
                  height: 120,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    gap: 10,
                  }}
                >
                  <div
                    style={{ display: "flex", width: "50%", color: "#C0BEC8" }}
                  >
                    Budget
                    <Tooltip title="Total budget of the project by the client">
                      <InfoCircleOutlined size={12} />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "60%",
                      maxWidth: "60%",
                      minWidth: "60%",
                    }}
                  >
                    Rs. {getVendorPrice(data)}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: "1px",
                    width: "95%",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      marginRight: 5,
                      color: "#C0BEC8",
                    }}
                  >
                    Proposed
                    <Tooltip title="Total BOQ value">
                      <InfoCircleOutlined size={12} />
                    </Tooltip>
                  </div>
                  <div style={{ display: "flex", width: "60%" }}>
                    Rs. {getComponentsPrice(data).toFixed(0)}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.03)",
                  borderRadius: "4px",
                  padding: 10,
                  width: "75%",
                  height: 120,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "35%",
                      paddingRight: 2,
                      color: "#94919F",
                    }}
                  >
                    Buffer
                    <Tooltip title="Set by the company">
                      <InfoCircleOutlined size={12} />
                    </Tooltip>
                  </div>
                  <input
                    value={`${bufferPercentage}`}
                    style={{
                      display: "flex",
                      width: "15%",
                      marginRight: "2%",
                      fontSize: "16px",
                      borderWidth: 0,
                      border: "none",
                      outline: "none",
                      backgroundColor: "#FFF6DB",
                    }}
                    onChange={(e) => {
                      setBufferPercentage(
                        e.currentTarget.value.replace(/(?!-)[^0-9.]/g, "")
                      );
                    }}
                    onBlur={() => {
                      updateAccountDetails();
                    }}
                  />
                  <div
                    style={{ display: "flex", width: "10%", marginRight: 5 }}
                  >
                    %
                  </div>
                  <div style={{ display: "flex", width: "45%" }}>
                    Rs. {(received * (bufferPercentage / 100)).toFixed(0)}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: "1px",
                    width: "95%",
                    backgroundColor: "#C4C4C4",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "60%", color: "#94919F" }}
                  >
                    Payable tax
                    <Tooltip title="Tax to be paid by the company">
                      <InfoCircleOutlined size={12} />
                    </Tooltip>
                  </div>
                  <input
                    value={payableTaxPercentage}
                    style={{
                      display: "flex",
                      width: "15%",
                      marginRight: "2%",
                      fontSize: "16px",
                      borderWidth: 0,
                      border: "none",
                      outline: "none",
                      backgroundColor: "#FFF6DB",
                    }}
                    onChange={(e) => {
                      setPayableTaxPercentage(
                        e.currentTarget.value.replace(/(?!-)[^0-9.]/g, "")
                      );
                    }}
                    onBlur={() => {
                      updateAccountDetails();
                    }}
                  />
                  <div
                    style={{ display: "flex", width: "10%", marginRight: 5 }}
                  >
                    %
                  </div>
                  <div style={{ display: "flex", width: "45%" }}>
                    Rs. {(received * (payableTaxPercentage / 100)).toFixed(0)}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.03)",
                  borderRadius: "4px",
                  padding: 10,
                  overflow: "scroll",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#94919F",
                    fontWeight: 700,
                    alignItems: "center",
                  }}
                >
                  <p>Received Amount   <Tooltip title="Total amount received from the client till date">
                    <InfoCircleOutlined size={12} />
                  </Tooltip></p>
                
                  <p>{received}</p>
                </div>
                <br />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    <input
                      placeholder="Enter Amount"
                      value={receivedInput}
                      style={{
                        width: "100%",
                        fontSize: "16px",
                        backgroundColor: "#FFF6DB",
                        border: "1px solid #3535354d",
                      }}
                      min="0"
                      type="number"
                      onChange={(e) => {
                        setReceivedInput(
                          e.currentTarget.value.replace(/(?!-)[^0-9.]/g, "")
                        );
                      }}
                    />
                    <input
                      placeholder="Remarks"
                      style={{
                        backgroundColor: "#FFF6DB",
                        border: "1px solid #3535354d",
                        width: "100%",
                        fontSize: "16px",
                      }}
                      value={remarks}
                      onChange={(e) => {
                        setRemarks(e?.currentTarget?.value);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (Number(receivedInput) > 0) {
                        console.log("previous dateaaa", amountHistory);
                        let tempamountHistory = [...amountHistory];
                        setAmountHistory((prev) => [
                          {
                            date: Date.now(),
                            amount: Number(receivedInput),
                            remarks: remarks
                              .replace(/<single-quote>/g, "'")
                              .replace(/<double-quote>/g, '"')
                              .replace(/<rupee-symbol>/g, "₹")
                              .replace(/<new-line>/g, "\n"),
                          },
                          ...prev,
                        ]);
                         setReceived((prev) => +prev + +receivedInput);
                         updateAccountDetails([
                          {
                            date: Date.now(),
                            amount: Number(receivedInput),
                            remarks: remarks
                              .replace(/'/g, "<single-quote>")
                              .replace(/"/g, "<double-quote>")
                              .replace(/₹/g, "<rupee-symbol>")
                              .replace(/\n/g, "<new-line>"),
                          },
                          ...tempamountHistory,
                        ]);
                      }
                    }}
                  >
                    <FiSave
                      color="#94919F"
                      style={{ height: "3em", width: "1.2em" }}
                      height="3em"
                      width="1.2em"
                    />
                  </div>
                </div>

                <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
                  <p
                    style={{
                      display: "flex",
                      color: "#94919F",
                      fontWeight: 700,
                      padding: "5px 0px",
                      justifyContent: "center",
                    }}
                  >
                    Amount History
                    <Tooltip title="Records of the installments received from client">
                      <InfoCircleOutlined size={12} />
                    </Tooltip>
                  </p>
                  {amountHistory?.map((item, index) => (
                    <ReceivedAmountCard
                      key={index}
                      data={item}
                      index={index}
                      setAmountHistory={setAmountHistory}
                      setReceived={setReceived}
                      setEditableItem={setEditableItem}
                      editableitem={editableitem}
                      amountHistory={amountHistory}
                      updateAccountDetails={updateAccountDetails}
                      startDate={startDate}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <DropDownMUI
              placeholder={"Work Type"}
              data={["All", "Only Work", "Work + Material", "Only Material"]}
              value={workType}
              onChange={(value) => {
                setWorkType(value);
              }}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <DropDownMUI
              placeholder={"Status"}
              data={[
                "All",
                "Order placed",
                "Order accepted",
                "Order completed",
                "Quotation released",
              ]}
              value={status}
              onChange={(value) => {
                setStatus(value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              border: "1px solid #3535354d",
              width: "100%",
              marginBottom: 10,
              borderRadius: 5,
            }}
          >
            <AiOutlineSearch
              color="grey"
              size="20"
              style={{ margin: "0px 10px" }}
            />
            <input
              placeholder="Search by vendor name"
              style={{
                width: "100%",
                fontSize: "16px",
                borderWidth: 0,
                border: "none",
                outline: "none",
              }}
              value={searchItems}
              onChange={(e) => {
                setSearchItems(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        {isSpinner ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#fdd34d" }} />
          </div>
        ) : (
          releasedOrders &&
          releasedOrders.map((item, index) => {
            return (
              item.ApprovalStatus != "inApproval" &&
              (activeCategory == item?.Category || activeCategory == "All") &&
              (searchItems.length > 0
                ? item?.VendorName?.toLowerCase().includes(
                    searchItems.toLowerCase()
                  )
                : item?.VendorName == null
                ? true
                : item?.VendorName?.toLowerCase().includes(
                    searchItems.toLowerCase()
                  )) &&
              ((JSON.parse(item.Data)?.data[0]?.workType == undefined
                ? workType == "Only Material"
                : workType == JSON.parse(item.Data)?.data[0]?.workType) ||
                workType == "All") &&
              (item?.Status.toLowerCase().includes(status.toLowerCase()) ||
                status == "All") && (
                <OrderItem
                  key={item.OrderId}
                  releasedOrderIndex={index}
                  item={item}
                  fetchReleasedOrdersOngoing={fetchReleasedOrders}
                  allOrders={allOrders}
                  setReleasedOrders={setReleasedOrders}
                  ProjectId={data.ProjectId}
                  ClientName={data.clientName || data.ClientName}
                />
              )
            );
          })
        )}
        {backToTop && (
          <button
            id="btn"
            title="back to top"
            onClick={backToTopHandler}
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#2f4858",
              marginTop: "5px",
              position: "fixed",
              bottom: "4%",
              left: "95%",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              color: "white",
            }}
          >
            <BiUpArrowAlt size={30} style={{ marginTop: "2px" }} />
          </button>
        )}
      </div>
    </div>
  );
}
