import { secondDatabase } from "../../../config/firebase";

/**
 * Updates the totalDieselConsumed for a specific vehicle in Firebase.
 * 
 * @param {Array} vehicles - Array of vehicle objects returned by fetchVehicleInformation.
 * @param {string} tag - Tag of the vehicle to update.
 * @param {number} quantity - Quantity to increment the totalDieselConsumed by.
 */
async function writeVehicleInformation(firmId, projectId, vehicles, tag, quantity) {
  try {
    // Find the vehicle matching the specified tag
    const vehicleToUpdate = vehicles.find(vehicle => vehicle.tag === tag);

    if (!vehicleToUpdate) {
      console.error(`Vehicle with tag "${tag}" not found.`);
      return;
    }

    // Compute the new value for totalDieselConsumed
    const updatedDieselConsumed = (Number(vehicleToUpdate.totalDieselConsumed) || 0) + Number(quantity);

    // Define the Firebase reference path for the specific vehicle
    const vehiclePathRef = secondDatabase.ref(
      `${firmId}/${projectId}/vehicles/${vehicleToUpdate.id}`
    );

    // Update the totalDieselConsumed in Firebase
    await vehiclePathRef.update({
      totalDieselConsumed: updatedDieselConsumed,
    });

    console.log(
      `Updated totalDieselConsumed for vehicle with tag "${tag}" to ${updatedDieselConsumed}.`
    );
  } catch (error) {
    console.error('Error updating vehicle totalDieselConsumed:', error);
    throw error; // Optionally propagate the error for the caller to handle
  }
}

export default writeVehicleInformation;
