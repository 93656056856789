import config from "../config/config";

class projectService {
  async addProject(
    ClientName,
    ClientNumber,
    Address,
    Amount,
    SiteEngineersId,
    Rooms,
    duration,
    additionalSEIds,
    firmFromCreatingNewFirm
  ) {
    console.log("init_addProject");
    try {
      let firm =
        JSON.parse(localStorage.getItem("Firm")) || firmFromCreatingNewFirm;
      console.log("firm", firm);
      let response = await fetch(`${config.projectService}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProjectId: new Date().getTime() + "",
          ClientName,
          ClientNumber,
          Address,
          Finance: { AmountReceived: Amount },
          SiteEngineersId,
          Rooms,
          duration,
          Firm: firm,
          lastOpen: new Date().getTime() + "",
          additionalSEIds,
        }),
      });
      response = await response.json();

      console.log(`Response from addProject API: `, response);
      return { ...response };
    } catch (e) {
      console.log({
        status: 400,
        statusMsg: "Error adding project!",
        payload: e,
      });
      return { status: 400, statusMsg: "Error adding project!", payload: e };
    }
  }

  async getProjects() {
    try {
      let firm = JSON.parse(localStorage.getItem("Firm"));
      let response = await fetch(`${config.projectService}${firm}`);
      response = await response.json();
      console.log(`Response from getProjects API: `, response);
      return { ...response };
    } catch (e) {
      return { status: 400, statusMsg: "Error fetching project!", payload: e };
    }
  }

  async getClientNames() {
    // this is to get all Client Names for Assiging the projects
    try {
      let firm = JSON.parse(localStorage.getItem("Firm"));
      let response = await fetch(
        `${config.projectService}get/ClientNames/${firm}`
      );
      response = await response.json();
      // console.log('ClientNames fetched : ',response)
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching Client Names",
        payload: e,
      };
    }
  }

  async getProjectsByUser(projectIdstobefetched, offset) {
    // this is to get all assigned projects to a user
    try {
      let firm = JSON.parse(localStorage.getItem("Firm"));
      let response = await fetch(
        `${config.projectService}get/Projects/By/User/${firm}/${projectIdstobefetched}/${offset}`
      );
      response = await response.json();
      // console.log('Projects Fetched by user : ',response)
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching Project Names",
        payload: e,
      };
    }
  }

  async getProjectById(projectId) {
    try {
      let response = await fetch(
        `${config.projectService}getProjectsByProjectId/${projectId}`
      );
      response = await response.json();
      return { ...response };
    } catch (e) {
      return { status: 400, statusMsg: "Error fetching project!", payload: e };
    }
  }

  async get10Projects(offset) {
    try {
      let firm = JSON.parse(localStorage.getItem("Firm"));
      let response = await fetch(
        `${config.projectService}get/Pagination/B/y/index/${firm}/${offset}`
      );
      response = await response.json();
      return { ...response };
    } catch (e) {
      return { status: 400, statusMsg: "Error fetching project!", payload: e };
    }
  }

  async searchProject(ClientName) {
    try {
      let firm = JSON.parse(localStorage.getItem("Firm"));
      let response = await fetch(
        `${config.projectService}get/Projects/By/Searching/${firm}/${ClientName}`
      );
      response = await response.json();
      console.log(`Response from searchProject API: `, response);
      return { ...response };
    } catch (e) {
      return { status: 400, statusMsg: "Error fetching project!", payload: e };
    }
  }

  async searchUserProjects(ClientName, projectIdstobefetched) {
    try {
      let firm = JSON.parse(localStorage.getItem("Firm"));
      let response = await fetch(
        `${config.projectService}get/UserProjects/By/User/Searching/${firm}/${ClientName}`,
        {
          method: "POST",
          body: JSON.stringify({
            Projects: projectIdstobefetched,
          }),
        }
      );
      response = await response.json();
      // console.log(`Response from searchUserProjects API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching Userproject!",
        payload: e,
      };
    }
  }

  async setProject(payload) {
    const ProjectId = payload.ProjectId;
    const data = { ...payload };
    delete data["ProjectId"];
    try {
      let response = await fetch(`${config.projectService}${ProjectId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      response = await response.json();
      return { ...response };
    } catch (e) {
      return { status: 400, statusMsg: "Error updating project!", payload: e };
    }
  }

  async deleteProject(ProjectId) {
    try {
      let response = await fetch(
        `${config.projectService}deleteProjects/${ProjectId}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProjectId,
          }),
        }
      );
      response = await response.json();
      console.log("Response from DeleteService API: ", response);
      return { ...response };
    } catch (e) {
      return { status: 400, statusMsg: "Error fetching id!", payload: e };
    }
  }

  async getSuggestiveText() {
    try {
      let response = await fetch(`${config.projectService}getSt/all`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      response = await response.json();
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching suggestive text!",
        payload: e,
      };
    }
  }

  async addSuggestiveTextComponent(
    description,
    rate,
    unit,
    works,
    materials,
    firm
  ) {
    try {
      let response = await fetch(`${config.projectService}componentSt`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          description,
          rate,
          unit,
          works,
          materials,
          firm,
        }),
      });
      response = await response.json();
      console.log(`Response from addSuggestiveTextComponent API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error adding suggestive text for component!",
        payload: e,
      };
    }
  }

  async updateSuggestiveTextComponent(
    Id,
    description,
    rate,
    unit,
    works,
    materials,
    firm
  ) {
    try {
      let response = await fetch(
        `${config.projectService}updateComponentSt/${Id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description,
            rate,
            unit,
            works,
            materials,
            firm,
          }),
        }
      );
      response = await response.json();
      console.log(
        `Response from updateSuggestiveTextComponent API: `,
        response
      );
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error updating suggestive text for Component!",
        payload: e,
      };
    }
  }

  async deleteSuggestiveTextComponent(Id) {
    try {
      let response = await fetch(
        `${config.projectService}deleteComponentSt/${Id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Id,
          }),
        }
      );
      response = await response.json();
      console.log(
        `Response from deleteSuggestiveTextComponent API: `,
        response
      );
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error deleting suggestive text for component!",
        payload: e,
      };
    }
  }

  async addSuggestiveTextMaterial(
    category,
    specification,
    rate,
    unit,
    gst,
    firm
  ) {
    try {
      let response = await fetch(`${config.projectService}materialSt`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          category,
          specification,
          rate,
          unit,
          gst,
          firm,
        }),
      });
      response = await response.json();
      console.log(`Response from addSuggestiveTextMaterial API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error adding suggestive text for material!",
        payload: e,
      };
    }
  }

  async updateSuggestiveTextMaterial(
    Id,
    category,
    specification,
    rate,
    unit,
    gst,
    firm
  ) {
    try {
      let response = await fetch(
        `${config.projectService}updateMaterialSt/${Id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            category,
            specification,
            rate,
            unit,
            gst,
            firm,
          }),
        }
      );
      response = await response.json();
      console.log(`Response from updateSuggestiveTextMaterial API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error updating suggestive text for material!",
        payload: e,
      };
    }
  }

  async deleteSuggestiveTextMaterial(Id) {
    try {
      let response = await fetch(
        `${config.projectService}Material/deleteSt/${Id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Id,
          }),
        }
      );
      response = await response.json();
      console.log(`Response from deleteSuggestiveTextMaterial API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error deleting suggestive text for material!",
        payload: e,
      };
    }
  }

  async addSuggestiveTextWork(
    category,
    workType,
    description,
    rate,
    unit,
    gst,
    firm
  ) {
    try {
      let response = await fetch(`${config.projectService}workSt`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          category,
          workType,
          description,
          rate,
          unit,
          gst,
          firm,
        }),
      });
      response = await response.json();
      console.log(`Response from addSuggestiveTextWork API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error adding suggestive text for work!",
        payload: e,
      };
    }
  }

  async updateSuggestiveTextWork(
    Id,
    category,
    workType,
    description,
    rate,
    unit,
    gst,
    firm
  ) {
    try {
      let response = await fetch(`${config.projectService}updateWorkSt/${Id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          category,
          workType,
          description,
          rate,
          unit,
          gst,
          firm,
        }),
      });
      response = await response.json();
      console.log(`Response from updateSuggestiveTextWork API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error updating suggestive text for work!",
        payload: e,
      };
    }
  }

  async deleteSuggestiveTextWork(Id) {
    try {
      let response = await fetch(`${config.projectService}WorkStdelete/${Id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Id,
        }),
      });
      response = await response.json();
      console.log(`Response from deleteSuggestiveTextWork API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error deleting suggestive text for work!",
        payload: e,
      };
    }
  }

  async getSuggestiveTextPO() {
    try {
      let response = await fetch(`${config.projectService}get/Po/St`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      response = await response.json();
      console.log(`Response from getSuggestiveTextPO API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching suggestive text for PO!",
        payload: e,
      };
    }
  }

  async addSuggestiveTextPO(
    ContactPersonName,
    ContactPersonNumber,
    TermsandConditions,
    SpecialInstructions,
    BillingAddress,
    firm
  ) {
    try {
      let response = await fetch(`${config.projectService}poSt`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContactPersonName,
          ContactPersonNumber,
          TermsandConditions,
          SpecialInstructions,
          BillingAddress,
          firm,
        }),
      });
      response = await response.json();
      console.log(`Response from addSuggestiveTextPO API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error adding suggestive text for PO!",
        payload: e,
      };
    }
  }

  async updateSuggestiveTextPO(
    Id,
    ContactPersonName,
    ContactPersonNumber,
    TermsandConditions,
    SpecialInstructions,
    BillingAddress,
    firm
  ) {
    try {
      let response = await fetch(`${config.projectService}updatePoSt/${Id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContactPersonName,
          ContactPersonNumber,
          TermsandConditions,
          SpecialInstructions,
          BillingAddress,
          firm,
        }),
      });
      response = await response.json();
      console.log(`Response from updateSuggestiveTextPO API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error updating suggestive text for PO!",
        payload: e,
      };
    }
  }

  async deleteSuggestiveTextPO(Id) {
    try {
      let response = await fetch(`${config.projectService}poStDelete/${Id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Id,
        }),
      });
      response = await response.json();
      console.log(`Response from deleteSuggestiveTextPO API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error deleting suggestive text for PO!",
        payload: e,
      };
    }
  }

  async getSuggestiveTextFirm() {
    try {
      let response = await fetch(`${config.projectService}get/Firm/St`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      response = await response.json();
      console.log(`Response from getSuggestiveTextFirm API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error fetching suggestive text for firm!",
        payload: e,
      };
    }
  }

  async addSuggestiveTextFirm(
    firmName,
    firmAddress,
    firmPhoneNumber,
    firmGSTIN,
    firmContactPersonName,
    firmContactPersonNumber,
    firmContactPersonEmail,
    firmSignature
  ) {
    try {
      let response = await fetch(`${config.projectService}firmSt`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firmName,
          firmAddress,
          firmPhoneNumber,
          firmGSTIN,
          firmContactPersonName,
          firmContactPersonNumber,
          firmContactPersonEmail,
          firmSignature,
        }),
      });
      response = await response.json();
      console.log(`Response from addSuggestiveTextFirm API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error adding suggestive text for firm!",
        payload: e,
      };
    }
  }

  async updateSuggestiveTextFirm(
    Id,
    firmName,
    firmAddress,
    firmPhoneNumber,
    firmGSTIN,
    firmContactPersonName,
    firmContactPersonNumber,
    firmContactPersonEmail,
    firmSignature
  ) {
    try {
      let response = await fetch(`${config.projectService}updateFirmSt/${Id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firmName,
          firmAddress,
          firmPhoneNumber,
          firmGSTIN,
          firmContactPersonName,
          firmContactPersonNumber,
          firmContactPersonEmail,
          firmSignature,
        }),
      });
      response = await response.json();
      console.log(`Response from updateSuggestiveTextFirm API: `, response);
      return { ...response };
    } catch (e) {
      return {
        status: 400,
        statusMsg: "Error updating suggestive text for firm!",
        payload: e,
      };
    }
  }

  async addProjectBackup(
    ProjectId,
    ClientName,
    Address,
    Finance,
    Rooms,
    SiteEngineersId,
    duration,
    ClientNumber
  ) {
    try {
      let Firm = JSON.parse(localStorage.getItem("Firm"));
      let response = await fetch(`${config.projectService}backupProject`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          BackupId: new Date().getTime(),
          ProjectId,
          ClientName,
          Address,
          Finance,
          Rooms,
          SiteEngineersId,
          duration,
          Firm,
          ClientNumber,
        }),
      });
      response = await response.json();
      console.log(`Response from add backup project API: `, response);
      return { ...response };
    } catch (e) {
      console.log({
        status: 400,
        statusMsg: "Error in backup project!",
        payload: e,
      });
      return { status: 400, statusMsg: "Error in backup project!", payload: e };
    }
  }

  async addAmount(ProjectId, boqAmount, actualAmount) {
    console.log("init_addProject");
    try {
      // let firm = JSON.parse(localStorage.getItem('Firm'))
      // console.log("firm", firm)
      let response = await fetch(`${config.projectService}project/Service`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProjectId,
          boqAmount,
          actualAmount,
        }),
      });
      response = await response.json();
      console.log(`Response from addProject API: `, response);
      return { ...response };
    } catch (e) {
      console.log({
        status: 400,
        statusMsg: "Error adding project!",
        payload: e,
      });
      return { status: 400, statusMsg: "Error adding project!", payload: e };
    }
  }

  async getAmount(projectId) {
    try {
      let response = await fetch(
        `${config.projectService}get/Project/Service/${projectId}`
      );
      response = await response.json();
      console.log(`Response from getProjects by Id API: `, response);
      return { ...response };
    } catch (e) {
      return { status: 400, statusMsg: "Error fetching project!", payload: e };
    }
  }
}

export default projectService;
