import React from "react";
import "./searchBar.scss";
import { AiOutlineSearch } from "react-icons/ai";

export default ({ onChange, value, onSearchClick }) => {
  return (
    <div id="searchBar">
      <input
        onChange={(e) => onChange && onChange(e.currentTarget.value)}
        placeholder="Search"
        value={value}
      />
      <AiOutlineSearch color="rgb(53, 53, 53)" size="16" />
    </div>
  );
};
