import React, { useState, useEffect, useRef } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { PopOverView, TextArea } from "../../../components";
import firebase from "../../../config/firebase";
import "./IssuesChat.css";
import { sendNotification } from "./helper";
import { useLocation } from "react-router-dom";

const styles = {
  chatContainer: {
    maxHeight: "400px",
    overflowY: "auto",
    padding: "10px",
    backgroundColor: "#f0f2f5",
    borderRadius: "8px",
  },
  chatContainerFullScreen: {
    marginTop: "100px",
    zIndex: 9999,
    backgroundColor: "#f0f2f5",
    minHeight: "80%",
    width: "100%",
    overflowY: "auto",
    padding: "10px",
    borderRadius: "8px",
  },
  messageWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
  },
  clientMessageWrapper: {
    alignItems: "flex-start",
  },
  firmMessageWrapper: {
    alignItems: "flex-end",
  },
  messageContainer: {
    display: "flex",
    alignItems: "flex-end",
    maxWidth: "80%",
  },
  avatar: {
    fontSize: "40px",
    margin: "0 8px",
  },
  firmAvatar: {
    color: "#3b82f6",
  },
  clientAvatar: {
    color: "#9ca3af",
  },
  messageBubble: {
    padding: "12px 16px",
    borderRadius: "18px",
    margin: "0",
    maxWidth: "100%",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    wordWrap: "break-word",
  },
  clientMessageBubble: {
    backgroundColor: "#e5e7eb",
    color: "#1f2937",
    borderTopLeftRadius: "4px",
  },
  firmMessageBubble: {
    backgroundColor: "#3b82f6",
    color: "#ffffff",
    borderTopRightRadius: "4px",
  },
  expandButton: {
    display: "block",
    margin: "10px auto",
    padding: "8px 16px",
    backgroundColor: "#3b82f6",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "backgroundColor 0.3s",
  },
  expandButtonHover: {
    backgroundColor: "#2563eb",
  },
  messageInputContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    justifyContent: "space-between",
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#f0f2f5",
    padding: "10px",
    boxShadow: "0 -1px 2px rgba(0, 0, 0, 0.1)",
  },
  messageInput: {
    width: "100%",
    padding: "12px",
    paddingLeft: 10,
    border: "1px solid #d1d5db",
    borderRadius: "4px",
    resize: "vertical",
  },
};

const IssuesChat = ({
  item,
  invoiceId,
  projectId,
  activeChat,
  chatsAreActive,
  activeThread,
  incrementClientUnseenCount,
  dummyIssues,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [chatText, setChatText] = useState("");
  const chats = Object.values(item);
  const chatContainerRef = useRef(null);
  const location = useLocation();
  const data = location.state.selectedProject;

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const pad = (num) => num.toString().padStart(2, "0");
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${day}/${month}/${year} : ${hours}:${minutes}:${seconds}`;
  }

  // here we are going to fetch the fcmToken of the client

  const [fcmClient, setFCMClient] = useState("");
  const [device, setDevice] = useState("");

  const fetchFCM = async () => {
    console.log("fetchFCM is called...");
    console.log("data.clientNumber: ", data.clientNumber);
    var useRef = firebase
      .database()
      .ref(`clientUserDetails/${data.clientNumber}/tokens/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setDevice(data.deviceType);
        setFCMClient(data.fcmToken);
      }
    });
  };

  useEffect(() => {
    // write the code here
    fetchFCM();
  }, []);

  const sendMessage = async () => {
    console.log("here we are going to send the message...");
    if (chatText.length > 0) {
      const timestamp = new Date().getTime();
      firebase
        .database()
        .ref(
          `clientDetails/${projectId}/agreement/issues/${invoiceId}/chats/${timestamp}`
        )
        .set({
          timestamp: timestamp,
          text: chatText,
          sender: `firm`,
        });
      incrementClientUnseenCount();
      sendNotification(
        "New Message",
        device,
        fcmClient,
        `${data.Firm} : ${chatText}`,
        data.clientNumber,
        "chats"
      );
      setChatText("");
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chats, expanded]);

  const handleExpand = async () => {
    setExpanded(true);
    firebase
      .database()
      .ref(
        `clientDetails/${projectId}/agreement/issues/${invoiceId}/unseenComment/firm`
      )
      .set(0);
  };

  return (
    <>
      <div
        style={
          chatsAreActive
            ? { ...styles.chatContainer, ...styles.chatContainerFullScreen }
            : styles.chatContainer
        }
        ref={chatContainerRef}>
        {!expanded &&
          !chatsAreActive &&
          chats.slice(-2).map((com, comIndex) => (
            <div
              key={comIndex}
              style={{
                ...styles.messageWrapper,
                ...(com.sender === "firm"
                  ? styles.firmMessageWrapper
                  : styles.clientMessageWrapper),
              }}>
              <div style={styles.messageContainer}>
                {com.sender !== "firm" && (
                  <AccountCircleIcon
                    style={{ ...styles.avatar, ...styles.clientAvatar }}
                  />
                )}
                <p
                  style={{
                    ...styles.messageBubble,
                    ...(com.sender === "firm"
                      ? styles.firmMessageBubble
                      : styles.clientMessageBubble),
                  }}>
                  {com?.text
                    ?.replace(/<new-line>/g, "\n")
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"')}
                </p>
                {com.sender === "firm" && (
                  <AccountCircleIcon
                    style={{ ...styles.avatar, ...styles.firmAvatar }}
                  />
                )}
              </div>
              <p
                style={{
                  fontSize: 10,
                  color: "#9ca3af",
                  marginTop: 5,
                  fontWeight: 600,
                }}>
                {formatTimestamp(Number(com.timestamp))}
              </p>
            </div>
          ))}
        {(expanded || chatsAreActive) &&
          chats.map((com, comIndex) => (
            <div
              key={comIndex}
              style={{
                ...styles.messageWrapper,
                ...(com.sender === "firm"
                  ? styles.firmMessageWrapper
                  : styles.clientMessageWrapper),
              }}>
              <div style={styles.messageContainer}>
                {com.sender !== "firm" && (
                  <AccountCircleIcon
                    style={{ ...styles.avatar, ...styles.clientAvatar }}
                  />
                )}
                <p
                  style={{
                    ...styles.messageBubble,
                    ...(com.sender === "firm"
                      ? styles.firmMessageBubble
                      : styles.clientMessageBubble),
                  }}>
                  {com?.text
                    ?.replace(/<new-line>/g, "\n")
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"')}
                </p>
                {com.sender === "firm" && (
                  <AccountCircleIcon
                    style={{ ...styles.avatar, ...styles.firmAvatar }}
                  />
                )}
              </div>
              <p
                style={{
                  fontSize: 10,
                  color: "#9ca3af",
                  marginTop: 5,
                  fontWeight: 600,
                }}>
                {formatTimestamp(Number(com.timestamp))}
              </p>
            </div>
          ))}
        {!expanded && !chatsAreActive && !dummyIssues && (
          <button
            style={styles.expandButton}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                styles.expandButtonHover.backgroundColor)
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor =
                styles.expandButton.backgroundColor)
            }
            onClick={handleExpand}>
            Expand Messages
          </button>
        )}
      </div>
      {(expanded || chatsAreActive) && (
        <div className="chat-input-container">
          <div className="input-row">
            <TextArea
              className="message-input"
              value={chatText}
              placeholder="Type your message"
              hideRedLine={true}
              onChange={(value) => setChatText(value)}
            />

            <button className="attach-button" title="Attach file">
              <AttachFileIcon className="attach-icon" />
            </button>

            <button
              className="send-button"
              title="Send message"
              onClick={sendMessage}>
              <SendSharpIcon className="send-icon" />
            </button>
          </div>

          {!chatsAreActive && (
            <button
              className="collapse-button"
              onClick={() => setExpanded(false)}>
              Collapse Messages
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default IssuesChat;
