import { secondDatabase } from "../../../config/firebase";

const saveOutwardInventoryData = async (firmId, projectId, item, formData, entryId, userName) => {

  const category = item.category;
  const descriptionId = item.descriptionId;
  const basePath = `${firmId}/${projectId}/allInventory/`;

  const historyId = item.historyId;
  const historyData = {
    [historyId]: {
      invoiceNumber: formData.invoiceNumber || "Not provided",
      name: userName || "Not provided",
      poNumber: formData.poNumber || "Not provided",
      quantity: String(-parseFloat(item.quantityAccepted || 0)), // Outward quantity is negative
      status: "Outward",
      timeStamp: historyId,
      entryId: entryId,
      itemEntryId: item.id,
      purpose: item.purpose,
      vehicleType: item.tag || null
    },
  };

  // Check if category exists
  const categoryRef = secondDatabase.ref(`${basePath}${category}`);
  const categorySnapshot = await categoryRef.once("value");
  console.log('categorySnapshot:', categorySnapshot);
  if (!categorySnapshot.exists()) {
    await categoryRef.set({});
  } else {
    console.log('Category exists, proceeding...');
  }

  // Check if description exists
  const descriptionRef = secondDatabase.ref(`${basePath}${category}/${descriptionId}`);
  const descriptionSnapshot = await descriptionRef.once("value");

  if (!descriptionSnapshot.exists()) {
    // Create new description node with negative quantity
    await descriptionRef.set({
      availableQuantity: -parseFloat(item.quantityAccepted || 0),
      description: item.description || "No description",
      uom: item.unit || "Units",
      history: historyData,
    });
  } else {
    // Update existing description node
    const existingData = descriptionSnapshot.val();
    const newQuantity = parseFloat(existingData.availableQuantity || 0) - parseFloat(item.quantityAccepted || 0);
    await descriptionRef.update({
      availableQuantity: newQuantity,
      history: { ...existingData.history, ...historyData },
    });
  }

  console.log("Outward inventory data saved successfully.");
};

export default saveOutwardInventoryData;
