import React from 'react';
import './MarkAsPaidButton.css';

const MarkAsPaidButton = ({status, onClick }) => {
  return (
    <button className="mark-as-paid-btn" onClick={onClick}>
      Mark as {status === 'Paid' ? 'Not Paid' : 'Paid'}
    </button>
  );
};

export default MarkAsPaidButton;
