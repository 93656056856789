import React, { useEffect, useState } from "react";
import { FaPlus, FaCheck, FaTimes, FaMinus } from "react-icons/fa";
import "./HorizontalForm.css";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import ArticleIcon from "@mui/icons-material/Article";
import ConfirmationModal from "./ConfirmationModal";
import InvoiceButton from "./generatePdf";
import RequestAmountModal from "./RequestAmountModal";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Tooltip } from "antd";

const HorizontalForm = ({
  index,
  displayHead,
  milestone,
  item,
  data,
  setData,
  projectValue,
}) => {

  const [milestoneName, setMilestoneName] = useState("");
  const [milestoneDescription, setMilestoneDescription] = useState("");
  const [milestoneAmount, setMilestoneAmount] = useState("");
  const [milestoneDueDate, setMilestoneDueDate] = useState("");
  const [milestonePercentage, setMilestonePercentage] = useState("");
  const [milestoneValue, setMilestoneValue] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!milestone && item && item.dueDate) {
      setMilestoneDueDate(item.dueDate);
    }
  }, [milestone, item]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (milestoneName.length === 0) {
      toast.error("Milestone name is required");
      return;
    }

    if (milestoneDescription.length === 0) {
      toast.error("Milestone description is required");
      return;
    }

    if (milestoneDueDate === null) {
      toast.error("Due date is required");
      return;
    }

    let tempData = [...data];
    tempData.push({
      id: new Date().getTime(),
      milestoneName,
      milestoneDescription,
      dueDate: milestoneDueDate,
      amount: parseFloat(milestoneAmount) || 0,
      percentage: parseFloat(milestonePercentage) || 0,
      paid: false,
      items: [
        {
          itemName: "",
          completed: 0,
          pmStatus: false,
        },
      ],
    });
    setData(tempData);

    setMilestoneName("");
    setMilestoneDescription("");
    setMilestoneAmount("");
    setMilestoneDueDate("");
    setMilestonePercentage("");

    toast.success("Milestone added successfully");
  };

  const handleItemSubmit = async (idx) => {
    if (!data) return;

    let tempData = [...data];

    let value = {
      itemName: "",
      completed: 0,
      pmStatus: false,
    };

    if (tempData[index] && tempData[index]["items"]) {
      tempData[index]["items"].splice(idx + 1, 0, value);
    } else {
      console.error("Invalid index or items array does not exist");
      return;
    }

    setData(tempData);
  };

  const deleteMilestone = async () => {
    let tempData = [...data];
    tempData.splice(index, 1);
    setData(tempData);
    toast.success("Milestone deleted successfully");
    setConfirmationModal(false);
  };

  const deleteItem = async (idx) => {
    let tempData = [...data];
    delete tempData[index]["items"][idx];
    setData(tempData);
  };

  const [value, setValue] = useState(0);
  const [sliderIndex, setSliderIndex] = useState(null);

  const handleChange = async (e, idx) => {
    let tempData = [...data];
    tempData[index]["items"][idx]["completed"] = Number(e.target.value);
    setData(tempData);
    setValue(0);
    setSliderIndex(null);
  };

  const handleSliderChange = async (e, idx) => {
    if (idx !== sliderIndex) {
      setSliderIndex(idx);
    }
    setValue(e.target.value);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  const addAbove = () => {
    let tempData = [...data];
    tempData.splice(index, 0, {
      id: new Date().getTime(),
      milestoneName,
      milestoneDescription,
      dueDate: milestoneDueDate,
      amount: parseFloat(milestoneAmount) || 0,
      percentage: parseFloat(milestonePercentage) || 0,
      paid: false,
      items: [
        {
          itemName: "",
          completed: 0,
          pmStatus: false,
        },
      ],
    });
    setData(tempData);
  };

  return (
    <>
      {displayHead && (
        <div className="horizontal-form header">
          <p className="item-number">No.</p>
          <p className="form-input date-input-name">Milestone Name</p>
          <p className="form-input">Description</p>
          <p className="form-input date-input">Amount</p>
          <p className="form-input date-input">Due Date</p>
          <p className="form-input percentage-icon">Percentage</p>
          {/* <p className="form-input invoice-button">Invoice</p> */}
          {/* <p className="form-input">Invoice</p> */}
        </div>
      )}

      <form
        className="horizontal-form"
        onSubmit={handleSubmit}
        style={{
          backgroundColor: "#c2f2dc",
          position: "relative", // Add this
        }}>
        <span className="item-number">
          {index + 1}
          {"."}
        </span>

        {!milestone && (
          <>
            <div
              style={{
                position: "absolute", // Change to absolute
                top: "-12px", // Adjust as needed
                right: "-12px", // Position on the right
                cursor: "pointer",
                backgroundColor: "#f0f0f0", // Light grey background
                border: "none",
                padding: "4px", // Smaller padding
                borderRadius: "50%", // Make it circular
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                display: "flex", // Center the icon
                justifyContent: "center", // Center the icon
                alignItems: "center", // Center the icon
                transition: "background-color 0.3s ease", // Smooth background transition
              }}
              onClick={() => setConfirmationModal(true)}
            >
              <Tooltip title="Delete Milestone">
                <DeleteOutlinedIcon
                  style={{ fontSize: "24px", color: "#f44336" }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                position: "absolute", // Change to absolute
                top: "-12px", // Adjust as needed
                left: "-12px", // Position on the right
                cursor: "pointer",
                backgroundColor: "#f0f0f0", // Light grey background
                border: "none",
                padding: "4px", // Smaller padding
                borderRadius: "50%", // Make it circular
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                display: "flex", // Center the icon
                justifyContent: "center", // Center the icon
                alignItems: "center", // Center the icon
                transition: "background-color 0.3s ease", // Smooth background transition
              }}
              onClick={addAbove}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#e0e0e0")
              } // Hover effect
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#f0f0f0")
              } // Hover effect
            >
              <Tooltip title="Add milestone above">
                <AddIcon style={{ fontSize: "24px", color: "#000000" }} />
              </Tooltip>
            </div>
          </>
        )}

        <input
          type="text"
          value={!milestone ? item.milestoneName : milestoneName}
          onChange={(e) => {
            if (index !== data.length) {
              let tempData = [...data];
              tempData[index].milestoneName = e.target.value;
              setData(tempData);
            }
            setMilestoneName(e.target.value);
          }}
          placeholder="Milestone Name"
          className="form-input date-input-name"
        />

        <input
          type="text"
          value={!milestone ? item.milestoneDescription : milestoneDescription}
          onChange={(e) => {
            if (index !== data.length) {
              let tempData = [...data];
              tempData[index].milestoneDescription = e.target.value;
              setData(tempData);
            }
            setMilestoneDescription(e.target.value);
          }}
          placeholder="Description"
          className="form-input"
        />

        <input
          type="number"
          value={!milestone ? item.amount : milestoneAmount}
          onChange={(e) => {
            if (index !== data.length) {
              let tempData = [...data];
              tempData[index].amount = e.target.value;
              tempData[index].percentage = (
                (e.target.value / projectValue) *
                100
              ).toFixed(0);
              setData(tempData);
            } else {
              setMilestonePercentage(() =>
                ((e.target.value / projectValue) * 100).toFixed(0)
              );
            }
            setMilestoneAmount(e.target.value);
          }}
          placeholder="Amount"
          className="form-input date-input"
        />

        <input
          type="date"
          value={item?.dueDate ? item.dueDate : milestoneDueDate}
          onChange={(e) => {
            if (index !== data.length) {
              let tempData = [...data];
              tempData[index].dueDate = e.target.value;
              setData(tempData);
            }
            setMilestoneDueDate(e.target.value);
          }}
          className="form-input date-input"
        />

        <input
          type="number"
          value={!milestone ? item.percentage : milestonePercentage}
          onChange={(e) => {
            if (index !== data.length) {
              let tempData = [...data];
              tempData[index].percentage = e.target.value;
              tempData[index].amount = (
                (e.target.value * projectValue) /
                100
              ).toFixed(0);
              setData(tempData);
            } else {
              setMilestoneAmount(() =>
                ((e.target.value * projectValue) / 100).toFixed(0)
              );
            }
            setMilestonePercentage(e.target.value);
          }}
          placeholder="Percentage"
          className="form-input percentage-icon"
        />

        {/* {!milestone && (
            <InvoiceButton
              request={false}
              handleOpen={handleOpen}
              amount={item.amount}
              setMilestoneValue={setMilestoneValue}
            />
        )} */}

        {milestone && (
          <button type="submit" className="add-button">
            <FaPlus />
          </button>
        )}
      </form>

      {!milestone &&
        "items" in item &&
        item.items.map((it, idx) => (
          <form
            key={idx}
            className="horizontal-form sub-item"
            onSubmit={(e) => e.preventDefault()}>
            <span className="item-number" style={{ fontWeight: 500 }}>
              {idx + 1}
              {"."}
            </span>
            <input
              type="text"
              value={it.itemName}
              onChange={(e) => {
                let tempData = [...data];
                tempData[index].items[idx].itemName = e.target.value;
                setData(tempData);
              }}
              placeholder="Item Name"
              className="form-input"
              style={{
                marginRight: 20,
              }}
            />

            <Box sx={{ width: 300 }}>
              <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1 }}
                alignItems="center">
                <Slider
                  aria-label="Progress"
                  value={sliderIndex === idx ? value : it.completed}
                  onChange={(e) => handleSliderChange(e, idx)}
                  onBlur={(e) => handleChange(e, idx)}
                  sx={{ color: "#7fc684" }}
                />
              </Stack>
            </Box>

            <p style={{ fontWeight: 700, color: "#6a6c6a", marginLeft: 20 }}>
              {sliderIndex === idx ? value : it.completed}
              {" %"}
            </p>

            <Tooltip title="Add new Item" placement="top">
              <div
                type="submit"
                className="add-button"
                onClick={() => handleItemSubmit(idx)}
              >
                <FaPlus />
              </div>
            </Tooltip>
            
            <Tooltip title="Remove current Item" placement="top">
              <div
                type="submit"
                className="add-button"
                style={{ backgroundColor: "#f49c96" }}
                onClick={() => deleteItem(idx)}
              >
                <FaMinus />
              </div>
            </Tooltip>
            <div
              style={{
                minWidth: 150,
              }}></div>
          </form>
        ))}

      {!milestone && (
        <InvoiceButton
          request={false}
          handleOpen={handleOpen}
          amount={item.amount}
          name={item.name}
          setMilestoneValue={setMilestoneValue}
        />
      )}



      {!milestone && item?.items.length === 0 && (
        <form
          key={1}
          className="horizontal-form sub-item"
          onSubmit={(e) => handleItemSubmit(e, 0)}>
          <span className="item-number">{1}</span>
          <input
            type="text"
            value={null}
            onChange={(e) => {
              let tempData = [...data];
              // tempData[index].items[0].itemName = e.target.value;
              setData(tempData);
            }}
            placeholder="Item Name"
            className="form-input"
          />
          <div className="status-indicator">
            <FaTimes className="status-icon not-completed" />
          </div>
          <button type="submit" className="add-button">
            <FaPlus />
          </button>
        </form>
      )}

      <ConfirmationModal
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={deleteMilestone}
        message={`Are you sure you want to delete milestone?`}
      />
      
      <RequestAmountModal
        index={index}
        open={open}
        name={item?.milestoneName}
        handleClose={handleClose}
        milestoneAmount={milestoneValue}
      />
    </>
  );
};

export default HorizontalForm;
