import React, { useState, useEffect } from "react";
import "./styles.scss";
import {
  FiChevronLeft,
  FiBriefcase,
  FiUser,
  FiUserCheck,
  FiChevronRight,
} from "react-icons/fi";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Pie,
  PieChart,
  BarChart,
  Bar,
  Cell,
  ComposedChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import getVendorPrice from "../../helpers/getVendorPrice";
import OpportunityService from "../../api/opportunityService";
import firebase from "../../config/firebase";
import { useLocation } from "react-router-dom";
import ProjectService from "../../api/projectService";
import getProjectStatus from "../../helpers/getProjectStatus";
import { useHistory } from "react-router";
import ReleasedOrders from "../ProjectPage/releasedOrders";
import opportunityApi from "../../api/opportunityService";
import PoService from "../../api/poService";

const pieData1 = [
  {
    name: "Upcoming Payments",
    value: 900,
  },
  {
    name: "Payment Delays",
    value: 300,
  },
  {
    name: "Payment Mismatch",
    value: 300,
  },
];
// const barData = [
//     {
//         "name": "Page A",
//         "uv": 4000,
//         "pv": 2400
//     },
//     {
//         "name": "Page B",
//         "uv": 3000,
//         "pv": 1398
//     },
//     {
//         "name": "Page C",
//         "uv": 2000,
//         "pv": 9800
//     },
//     {
//         "name": "Page D",
//         "uv": 2780,
//         "pv": 3908
//     },
//     {
//         "name": "Page E",
//         "uv": 1890,
//         "pv": 4800
//     },
//     {
//         "name": "Page F",
//         "uv": 2390,
//         "pv": 3800
//     },
//     {
//         "name": "Page G",
//         "uv": 3490,
//         "pv": 4300
//     }
// ]
const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];
export default (project, data) => {
  const history = useHistory();
  const [showContent, toggleContent] = useState(false);

  const [upcomingAmount, setupcomingAmount] = useState(0);
  const [ongoingAmount, setongoingAmount] = useState(0);
  const [completedAmount, setcompletedAmount] = useState(0);
  const [pettyCash, setPettyCash] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const opportunityService = new OpportunityService();
  const { pathname } = useLocation();
  const projectService = new ProjectService();
  const [selectedProject, setSelectedProject] = useState();
  const location = useLocation();
  const [serviceOrders, setServiceOrders] = useState([]);
  const [serviceOrdersBackup, setServiceOrdersBackup] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [activeFilter, setActiveFilter] = useState("Overall");
  const [ongoingAmount1, setOngoingAmount1] = useState(0);
  const [todayOngoingAmount, setTodayOngoingAmount] = useState(0);
  const [oneDayBackOngoingAmount, setOneDayBackOngoingAmount] = useState(0);
  const [twodaysBackOngoingAmount, setTwodaysBackOngoingAmount] = useState(0);
  const [threeDaysBackOngoingAmount, setThreeDaysBackOngoingAmount] =
    useState(0);
  const [fourDaysBackOngoingAmount, setFourDaysBackOngoingAmount] = useState(0);
  const [todayPayedAmount, setTodayPayedAmount] = useState(0);
  const [oneDayBackPayedAmount, setOneDayBackPayedAmount] = useState(0);
  const [twoDaysBackPayedAmount, setTwoDaysBackPayedAmount] = useState(0);
  const [threeDaysBackPayedAmount, setThreeDaysBackPayedAmount] = useState(0);
  const [fourDaysBackPayedAmount, setFourDaysBackPayedAmount] = useState(0);
  const [pieData, setPieData] = useState([]);
  const [total, setTotal] = useState(0);

  const oneday = 24 * 60 * 60 * 1000;
  const today = new Date().getTime();
  // let date = new Date()
  const [linechartData, setLinechartData] = useState([
    {
      name: new Date(today).getDate(),
      uv: todayPayedAmount,
      Proposed: todayOngoingAmount,
      amt: "",
    },
    {
      name: new Date(today - oneday).getDate(),
      uv: oneDayBackPayedAmount,
      pv: oneDayBackOngoingAmount,
      amt: "",
    },
    {
      name: new Date(today - oneday * 2).getDate(),
      uv: twoDaysBackPayedAmount,
      pv: twodaysBackOngoingAmount,
      amt: "",
    },
    {
      name: new Date(today - oneday * 3).getDate(),
      uv: threeDaysBackPayedAmount,
      pv: threeDaysBackOngoingAmount,
      amt: "",
    },
    {
      name: new Date(today - oneday * 4).getDate(),
      uv: fourDaysBackPayedAmount,
      pv: fourDaysBackOngoingAmount,
      amt: "",
    },
  ]);

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ/" + pathname.split("/")[2].toString())
      .set({ ...payload, rooms: JSON.stringify(payload.rooms) });

  const DateCreated = () => {
    let dateObj = new Date(project.ProjectId);
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    let newdate = year + "/" + month + "/" + day;
    return newdate;
  };

  useEffect(() => {
    new opportunityApi()
      .getOpportunitiesByProjectId(location.state.selectedProject.ProjectId)
      .then((res) => {
        if (res.status === 200) {
          setServiceOrders(res.servicesPayload);
          setServiceOrdersBackup(res.servicesPayload);
          setActiveCategory(res.servicesPayload[0].Category);
          res.servicesPayload.forEach((item) => {
            let parse = JSON.parse(item.Data);
            parse.data.forEach((order) => {
              let total =
                order.rate * order.quantity +
                ((order.rate * order.quantity) / 100) *
                  parseInt(order.gst || 0);
              let milestones = order.milestones.filter(
                (milestone) =>
                  milestone.paid !== "Cash" &&
                  milestone.paid !== "Online" &&
                  !!milestone.status
              );
              milestones.forEach((milestone) => {
                let milestoneTotal =
                  (total / 100) * parseInt(milestone.percentage);
                console.log("mile12345678", milestone.date);

                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date().toLocaleDateString()
                ) {
                  setTodayOngoingAmount((Previ) => Previ + milestoneTotal);
                  console.log("date1234", milestone.date);
                }
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date(
                    new Date().getTime() - 1000 * 60 * 60 * 24 * 1
                  ).toLocaleDateString()
                ) {
                  setOneDayBackOngoingAmount((Previ) => Previ + milestoneTotal);
                  console.log("date12345", milestone.date);
                }
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date(
                    new Date().getTime() - 1000 * 60 * 60 * 24 * 2
                  ).toLocaleDateString()
                ) {
                  setTwodaysBackOngoingAmount(
                    (Previ) => Previ + milestoneTotal
                  );
                  console.log("date123456", milestone.date);
                }
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date(
                    new Date().getTime() - 1000 * 60 * 60 * 24 * 3
                  ).toLocaleDateString()
                ) {
                  setThreeDaysBackOngoingAmount(
                    (Previ) => Previ + milestoneTotal
                  );
                  console.log("date123457", milestone.date);
                }
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date(
                    new Date().getTime() - 1000 * 60 * 60 * 24 * 4
                  ).toLocaleDateString()
                ) {
                  setFourDaysBackOngoingAmount(
                    (Previ) => Previ + milestoneTotal
                  );
                  console.log("date123458", milestone.date);
                }

                // setOngoingAmount1(Previ => Previ + milestoneTotal)
              });
            });
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setLinechartData((prevData) => {
      let newData = [...prevData];
      newData[0].pv = todayOngoingAmount;
      newData[1].pv = oneDayBackOngoingAmount;
      newData[2].pv = twodaysBackOngoingAmount;
      newData[3].pv = threeDaysBackOngoingAmount;
      newData[4].pv = fourDaysBackOngoingAmount;

      return newData;
    });
  }, [
    todayOngoingAmount,
    oneDayBackOngoingAmount,
    twodaysBackOngoingAmount,
    threeDaysBackOngoingAmount,
    fourDaysBackOngoingAmount,
  ]);

  useEffect(() => {
    new opportunityApi()
      .getOpportunitiesByProjectId(location.state.selectedProject.ProjectId)
      .then((res) => {
        if (res.status === 200) {
          setServiceOrders(res.servicesPayload);
          setServiceOrdersBackup(res.servicesPayload);
          setActiveCategory(res.servicesPayload[0].Category);
          res.servicesPayload.forEach((item) => {
            let parse = JSON.parse(item.Data);
            parse.data.forEach((order) => {
              console.log("mile123456", order.rate * order.quantity);
              console.log("mile123456", order.gst);
              let total =
                order.rate * order.quantity +
                ((order.rate * order.quantity) / 100) *
                  parseInt(order.gst || 0);
              let milestones = order.milestones.filter(
                (milestone) => milestone.paid && milestone.paid !== "Unpaid"
              );
              milestones.forEach((milestone) => {
                let milestoneTotal =
                  (total / 100) * parseInt(milestone.percentage);
                // setOngoingAmount1(Previ => Previ + milestoneTotal)
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date().toLocaleDateString()
                ) {
                  setTodayPayedAmount((Previ) => Previ + milestoneTotal);
                  console.log("date1234", milestone.date);
                }
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date(
                    new Date().getTime() - 1000 * 60 * 60 * 24 * 1
                  ).toLocaleDateString()
                ) {
                  setOneDayBackPayedAmount((Previ) => Previ + milestoneTotal);
                  console.log("date12345", milestone.date);
                }
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date(
                    new Date().getTime() - 1000 * 60 * 60 * 24 * 2
                  ).toLocaleDateString()
                ) {
                  setTwoDaysBackPayedAmount((Previ) => Previ + milestoneTotal);
                  console.log("date123456", milestone.date);
                }
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date(
                    new Date().getTime() - 1000 * 60 * 60 * 24 * 3
                  ).toLocaleDateString()
                ) {
                  setThreeDaysBackPayedAmount(
                    (Previ) => Previ + milestoneTotal
                  );
                  console.log("date123457", milestone.date);
                }
                if (
                  new Date(milestone.date).toLocaleDateString() ==
                  new Date(
                    new Date().getTime() - 1000 * 60 * 60 * 24 * 4
                  ).toLocaleDateString()
                ) {
                  setFourDaysBackPayedAmount((Previ) => Previ + milestoneTotal);
                  console.log("date123458", milestone.date);
                }
              });
            });
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setLinechartData((prevData) => {
      let newData = [...prevData];
      newData[0].uv = todayPayedAmount;
      newData[1].uv = oneDayBackPayedAmount;
      newData[2].uv = twoDaysBackPayedAmount;
      newData[3].uv = threeDaysBackPayedAmount;
      newData[4].uv = fourDaysBackPayedAmount;

      return newData;
    });
  }, [
    todayPayedAmount,
    oneDayBackPayedAmount,
    twoDaysBackPayedAmount,
    threeDaysBackPayedAmount,
    fourDaysBackPayedAmount,
  ]);

  useEffect(() => {
    const poService = new PoService();
    poService
      .getPosByProjectId(`${location.state.selectedProject.ProjectId}`)
      .then((res) => {
        if (res.status == 200) {
          let categories = new Set();
          res.payload.forEach((item) => {
            // console.log("item12345678", item.discription)
            let parse = JSON.parse(item.discription);
            parse.forEach((item1) => {
              console.log("item2513", item1);
              let category = item1.vendorCategory || item1.type;
              let Value =
                item1.rate * item1.quantity +
                ((item1.rate * item1.quantity) / 100) *
                  parseInt(item1.gst || 0);
              categories.add({ name: category, value: Value });
            });
          });
          setPieData([...categories]);
        }
      })
      .catch((error) => {
        alert("somthing went wrong");
      });
  }, []);

  useEffect(() => {
    const poService = new PoService();
    poService
      .getPosByProjectId(`${location.state.selectedProject.ProjectId}`)
      .then((res) => {
        if (res.status == 200) {
          res.payload.forEach((item) => {
            // console.log("item12345678", item.discription)
            let parse = JSON.parse(item.discription);
            parse.forEach((item1) => {
              let Value =
                item1.rate * item1.quantity +
                ((item1.rate * item1.quantity) / 100) *
                  parseInt(item1.gst || 0);
              setTotal((Previ) => Previ + Value || 0);
              console.log("sai1231", Value);
            });
          });
        }
      })
      .catch((error) => {
        alert("somthing went wrong");
      });
  }, []);
  const lastOpen = new Date().getTime();
  const openProject = () => {
    const projectService = new ProjectService();
    projectService
      .setProject({ ProjectId: project.ProjectId, lastOpen })
      .then((res) => {
        if (res.status == 200) {
          history.push("/ProjectPage/" + project.ProjectId);
        } else {
          alert(res.statusMsg);
        }
      })
      .catch((error) => {
        alert("somthing went wrong");
      });
  };

  useEffect(() => {
    console.log("INIT", location);
    if (project) {
      console.log("project", project);
      setupcomingAmount(0);
      setongoingAmount(0);
      setcompletedAmount(0);
      opportunityService
        .getOpportunitiesByProjectId(project.ProjectId)
        .then((res) => {
          console.log("data1234", selectedProject);
          setSpinner(true);
          console.log("resgetOpportunitiesByProjectId", res);
          if (res.status === 200) {
            setSpinner(false);
            res.servicesPayload.forEach((payload) => {
              const data = JSON.parse(payload.Data).data;
              data.forEach((item) => {
                if (item.rate.length > 0 && item.quantity.length > 0) {
                  const totalAmount =
                    parseInt(item.rate) * parseInt(item.quantity);
                  let tempcompletedAmount = 0;
                  let tempupcomingAmount = 0;
                  let tempongoingAmount = 0;
                  item.milestones.forEach((milestone) => {
                    if (milestone.paid && milestone.paid !== "Unpaid") {
                      tempcompletedAmount =
                        tempcompletedAmount +
                        (totalAmount / 100) * parseInt(milestone.percentage);
                    } else if (
                      milestone.paid !== "Cash" &&
                      milestone.paid !== "Online" &&
                      !!milestone.status
                    ) {
                      tempongoingAmount =
                        tempongoingAmount +
                        (totalAmount / 100) * parseInt(milestone.percentage);
                    } else if (!milestone.status && !milestone.paid) {
                      tempupcomingAmount =
                        tempupcomingAmount +
                        (totalAmount / 100) * parseInt(milestone.percentage);
                    }
                  });
                  setupcomingAmount((amt) =>
                    Math.round(amt + tempupcomingAmount)
                  );
                  setongoingAmount((amt) =>
                    Math.round(amt + tempongoingAmount)
                  );
                  setcompletedAmount((amt) =>
                    Math.round(amt + tempcompletedAmount)
                  );
                }
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
          setSpinner(false);
        });
    } else {
      setupcomingAmount(0);
      setongoingAmount(0);
      setcompletedAmount(0);
    }
    console.log("Project1234", computeItemCategories());
  }, [project]);

  const computeItemCategories = (sortOrder) => {
    let project = location.state.selectedProject;
    let rooms = project.Rooms;
    let itemsCount = [];
    rooms.forEach((room) => {
      room.Units.forEach((unit) => {
        unit.Components.forEach((component) => {
          component.Material.forEach((material) => {
            let categoryIndex = itemsCount.findIndex(
              (i) => i.category === material.category
            );
            if (!itemsCount.length || categoryIndex === -1) {
              itemsCount.push({ category: material.category, total: 1 });
            } else if (categoryIndex !== -1) {
              itemsCount[categoryIndex].total += 1;
            }
          });

          component.Work.forEach((work) => {
            let categoryIndex = itemsCount.findIndex(
              (i) => i.category === work.vendorCategory
            );
            if (!itemsCount.length || categoryIndex === -1) {
              itemsCount.push({ category: work.vendorCategory, total: 1 });
            } else if (categoryIndex !== -1) {
              itemsCount[categoryIndex].total += 1;
            }
          });
        });
      });
    });
    sortOrder === "ascending"
      ? itemsCount.sort((a, b) => (a.total < b.total ? -1 : 1))
      : itemsCount.sort((a, b) => (a.total > b.total ? -1 : 1));
    console.log("item123", itemsCount);
    return itemsCount;
  };
  console.log("amount123", location);

  return (
    <div id="project-analytics">
      <div id="project-analytics-header">
        <FiChevronLeft
          onClick={() => {
            history.push(
              "/ProjectPage/" + location.state.selectedProject.ProjectId
            );
          }}
        />
        <div>Analytics</div>
      </div>
      <div>
        <div>Details</div>
        <FiBriefcase />
        <div>{location.state.selectedProject.ClientName}</div>
        <FiUser />
        <div>{location.state.selectedProject.Firm}</div>
        <FiUserCheck />
        <div>SE: {location.state.selectedProject.SiteEngineerId}</div>
        <FiChevronRight color="green" />
        <div>Project start: 20th april, 2020</div>
        <FiChevronRight color="red" />
        <div>Project end: 20th april, 2020</div>
      </div>
      <div>
        <div>
          <div>BOQ Amount</div>
          <div>
            ₹ {Math.round(getComponentsPrice(location.state.selectedProject))}{" "}
            /-
          </div>
          <div></div>
        </div>
        <div>
          <div>Actual Amount</div>
          <div>
            ₹ {Math.round(getVendorPrice(location.state.selectedProject))} / -
          </div>
          <div></div>
        </div>
      </div>
      <div>
        <div>Proposed VS Actual</div>
        <LineChart
          width={400}
          height={250}
          data={linechartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="pv" stroke="#8884d8" />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>
      </div>
      <div>
        <div>Expenditure</div>
        <PieChart width={300} height={300}>
          <Tooltip cursor={{ stroke: "red", strokeWidth: 2 }} />
          <Pie
            data={pieData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={50}
            fill="#8884d8"
            label
          />
        </PieChart>
        <div>{total}</div>
      </div>
      <div>
        {/* <div>Items</div>
            <div>Plywood</div>
            <div>
                <div style={{ width: "75%" }}></div>
            </div>
            <div>Tiles</div>
            <div>
                <div style={{ width: "70%" }}></div>
            </div>
            <div>Cement</div>
            <div>
                <div style={{ width: "65%" }}></div>
            </div>
            <div>Cement</div>
            <div>
                <div style={{ width: "60%" }}></div>
            </div>
            <div>Cement</div>
            <div>
                <div style={{ width: "55%" }}></div>
            </div>
            <div>
                View Report
            </div> */}
        <ResponsiveContainer>
          <ComposedChart
            layout="vertical"
            data={computeItemCategories()}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis type="number" />
            <YAxis dataKey="category" type="category" scale="band" />
            <Tooltip />
            <Legend />
            {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
            <Bar dataKey="total" barSize={20} fill="#413ea0" />
            {/* <Bar dataKey="total" barSize={20} fill="red" /> */}
            {/* <Line dataKey="uv" stroke="#ff7300" /> */}
          </ComposedChart>
        </ResponsiveContainer>
        {/* <BarChart width={500} height={400} data={computeItemCategories()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="category" fill="#8884d8" />
                <Bar dataKey="total" fill="#82ca9d" />
            </BarChart> */}
      </div>
      <div>
        <div>Payment</div>
        {/* <div>
                <div style={{ backgroundColor: "#FDD34D", width: "40%", borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>10%</div>
                <div style={{ backgroundColor: "#FEB161", width: "10%", borderTopRightRadius: 5, borderBottomRightRadius: 5 }}></div>
                <div></div>
            </div> */}
        {/* <div style={{ backgroundColor: "#FDD34D" }}></div>
            <div>Payment Done</div>
            <div style={{ backgroundColor: "#FEB161" }}></div>
            <div>Petty Cash</div>
            <div style={{ backgroundColor: "white", border: "0.5px solid rgba(0,0,0,0.2)" }}></div>
            <div>Payment Scheduled</div> */}
        <ResponsiveContainer>
          <ComposedChart
            layout="vertical"
            data={linechartData}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis type="number" />
            <YAxis dataKey="category" type="category" scale="band" />
            <Tooltip />
            <Legend />
            {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
            <Bar dataKey="total" barSize={20} fill="#413ea0" />
            {/* <Bar dataKey="total" barSize={20} fill="red" /> */}
            {/* <Line dataKey="uv" stroke="#ff7300" /> */}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div>
        <div>Logs</div>
        <PieChart width={400} height={250}>
          <Pie
            data={pieData1}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={35}
            outerRadius={50}
            fill="#82ca9d"
            label={({ x, y, name }) => name}>
            {pieData1.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div>
        <div>
          <div>Margin</div>
          <div>
            <p>45</p>
            <small>L</small>
          </div>
          <p>Coming Soon</p>
        </div>
        <div>
          <div>Margin %</div>
          <div>
            <p>22</p>
            <small>%</small>
          </div>
          <p>Coming Soon</p>
        </div>
      </div>
      <div>
        <div>Billing</div>
        <div>
          <div
            style={{
              backgroundColor: "rgb(194, 194, 194)",
              width: "60%",
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}></div>
          <div
            style={{
              width: "10%",
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}></div>
        </div>
        <div style={{ backgroundColor: "rgb(194, 194, 194)" }}></div>
        <div>Actual Amount</div>
        <div style={{ border: "0.5px solid rgba(0,0,0,0.2)" }}></div>
        <div>Billed Amount</div>
        <div></div>
      </div>
    </div>
  );
};
