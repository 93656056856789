/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import {
  addRoom,
  onChangeRoom,
  delRoom,
  addUnit,
  onChangeUnit,
  deleteUnit,
} from "./helper";
import { PopOverView, TextArea, Tooltip } from "../../components";
import { FiChevronDown, FiChevronUp, FiMoreVertical } from "react-icons/fi";
import { AiOutlineDoubleRight } from "react-icons/ai";

export default ({
  room,
  roomIndex,
  project,
  setProject,
  updateFBRDB,
  itemOpenIndex,
  setItemOpenIndex,
  activeUnitIndex,
  setActiveUnitIndex,
  setActiveComponentIndex,
  setShowComponents,
  setShowDrawings,
  activeRevision,
  setShowWork,
  setShowMaterial,
  isScrollingUp,
  setDisplayScroll
}) => {
  const [showUnits, setShowUnits] = useState(true);


  useEffect(() => {
    document.getElementById(`roomIndex ${itemOpenIndex}`)?.focus()
  }, [itemOpenIndex])
  // const userContext = useContext(UserContext)


  return (
    <div>
      <div
      tabIndex= "1"
      id = {`roomIndex ${roomIndex}`}
        style={
          roomIndex === itemOpenIndex
            ? { backgroundColor: "rgb(255, 211, 77)", minHeight: "70px", transition: "background-color 1.0s ease" }
            : { minHeight: "70px" }
        }
        onMouseDown={() => {
          if (showUnits) {
            setActiveUnitIndex();
            setItemOpenIndex();
            setActiveComponentIndex();
          }
        }}
      >
        <Tooltip content="Click to change name">
          <TextArea
            value={room["Room Name"]}
            placeholder="Heading"
            onChange={(value) =>
              onChangeRoom(roomIndex, project, setProject, updateFBRDB, value)
            }
            onMouseDown={(e) => e.stopPropagation()}
            disabled={
              activeRevision !== null ||
              room["Room Name"] === "Non tender items"
              // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
            }
          />
        </Tooltip>

        {!showUnits ? (
          <div
            style={{
              width: 30,
              height: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FiChevronDown
              onClick={() => {
                setShowUnits(true);
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width: 30,
              height: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FiChevronUp
              onClick={() => {
                setShowUnits(false);
              }}
            />
          </div>
        )}

        {activeRevision === null ? (
          <PopOverView
            position="left"
            component={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  fontSize: 12,
                  color: "grey",
                  alignItems: "flex-start",
                  width: 140,
                }}
              >
                <div
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setDisplayScroll(false)
                    addRoom(roomIndex, project, setProject, updateFBRDB);
                    setItemOpenIndex(() => {
                      if (project.rooms[project.rooms.length - 1]["Room Name"] == "Non tender items") {
                        return project.rooms.length - 2
                      }
                      else {
                        return project.rooms.length - 2
                      }
                    })
                  }}
                  className="menuItem"
                >
                  Add Heading Below
                </div>
                <div
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    delRoom(roomIndex, project, setProject, updateFBRDB);
                  }}
                  className="menuItem"
                >
                  Delete
                </div>
              </div>
            }
          >
            {room["Room Name"] !== "Non tender items" && (
              <Tooltip content="Add/Delete heading">
                <div
                  style={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FiMoreVertical />
                </div>
              </Tooltip>
            )}
          </PopOverView>
        ) : (
          <div style={{ width: 20 }}>
            <FiMoreVertical />
          </div>
        )}
      </div>

   

      {showUnits && room?.Units && (
        <div>
          {room.Units.map((unit, unitIndex) => {
            return (
              <div
                key={unitIndex}
                tabIndex="1"
                id={`roomIndex ${roomIndex} unitIndex ${unitIndex}`}
                style={
                  unitIndex === activeUnitIndex && itemOpenIndex === roomIndex
                    ? {backgroundColor: "rgb(255, 211, 77)", minHeight: "70px", transition: "background-color 1.0s ease"}
                    : unitIndex === activeUnitIndex - 1 && itemOpenIndex === roomIndex && !isScrollingUp
                    ? {backgroundColor: "white", transition: "background-color 1.0s ease"}
                    : unitIndex === activeUnitIndex + 1 && itemOpenIndex === roomIndex && isScrollingUp
                    ? {backgroundColor: "white", transition: "background-color 1.0s ease"}
                    : {}
                }
              
                onClick={() => {
                  setItemOpenIndex(roomIndex);
                  setActiveUnitIndex(unitIndex);
                  setActiveComponentIndex();
                  setShowComponents(true);
                  setShowDrawings(true);
                  console.log('project : ', project)
                }}
              >
                <div>
                  <Tooltip content="Click to edit subheading">
                    <TextArea
                      value={unit["Unit Name"]}
                      placeholder="Sub Heading"
                      onClick={(event) => event.stopPropagation()}
                      onChange={(value) => {
                        onChangeUnit(
                          roomIndex,
                          unitIndex,
                          project,
                          setProject,
                          updateFBRDB,
                          "Unit Name",
                          value
                        );
                      }}
                      disabled={
                        activeRevision !== null ||
                        (unit["Unit Name"] === "Extra items" || unit["Unit Name"] === "Freight Charges")
                      }
                    />
                  </Tooltip>
                  {activeRevision === null && (
                    <PopOverView
                      position="left"
                      component={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            fontSize: 12,
                            color: "grey",
                            alignItems: "flex-start",
                            width: 160,
                          }}
                        >
                          <div
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              addUnit(
                                roomIndex,
                                unitIndex,
                                project,
                                setProject,
                                updateFBRDB
                              );
                              setActiveUnitIndex(room.Units.length-1);
                            }}
                            className="menuItem"
                          >
                            Add Sub Heading Below
                          </div>
                          <div
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              deleteUnit(
                                roomIndex,
                                unitIndex,
                                project,
                                setProject,
                                updateFBRDB
                              );
                            }}
                            className="menuItem"
                          >
                            Delete
                          </div>
                        </div>
                      }
                    >
                    {(unit["Unit Name"] !== "Extra items" || unit["Unit Name"] !== "Freight Charges") && (
                        <FiMoreVertical />
                      )}
                    </PopOverView>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    INR -{" "}
                    {(
                      unit?.Components?.reduce(
                        (total, item) => {
                          console.log(unit.Components , " unit . components")
                          return (
                            Number(total) +
                            Number(item?.rate) * (item?.quantity ? Number(item?.quantity) : 0)
                          );
                        },
                        0
                      ) || 0
                    ).toFixed(2)}
                  </div>

                  <div style={{ cursor: "pointer" }}>
                    <AiOutlineDoubleRight />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}



    </div>
  );
};
