import React from "react";
import "./Modal.css";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const MaintenanceModal = ({ maintenanceSchedule = [], onClose }) => {
  if (!Array.isArray(maintenanceSchedule)) {
    maintenanceSchedule = [];
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content maintenance-modal"
        onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Maintenance Details</h2>
          <IconButton onClick={onClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </div>

        <div className="modal-body">
          {maintenanceSchedule.map((maintenance, index) => {
            // Calculate total cost for this maintenance schedule
            const totalCost = Object.values(
              maintenance.serviceHistory || {}
            ).reduce((sum, service) => sum + (service.amount || 0), 0);

            // Sort service history by date
            const sortedServiceHistory = Object.values(
              maintenance.serviceHistory || {}
            ).sort((a, b) => b.date - a.date);

            const latestService = sortedServiceHistory[0];

            return (
              <div key={index} className="maintenance-section">
                <div className="maintenance-summary">
                  <div className="summary-header">
                    <h3>Maintenance Schedule {index + 1}</h3>
                    <span className="total-cost">
                      Total Cost: ₹{totalCost.toLocaleString()}
                    </span>
                  </div>
                  <div className="summary-details">
                    <div className="summary-item">
                      <label>Part</label>
                      <span>{maintenance.part}</span>
                    </div>
                    <div className="summary-item">
                      <label>Last Service</label>
                      <span>
                        {latestService
                          ? new Date(latestService.date).toLocaleDateString()
                          : "--"}
                      </span>
                    </div>
                    <div className="summary-item">
                      <label>Cycle</label>
                      <span>
                        {maintenance.cycle} {maintenance.cycleType}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="service-history">
                  <h4>Service History</h4>
                  <table className="maintenance-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Invoice Number</th>
                        <th>Cost</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedServiceHistory.map((service, serviceIndex) => (
                        <tr
                          key={serviceIndex}
                          className={serviceIndex % 2 === 0 ? "even" : "odd"}>
                          <td>{new Date(service.date).toLocaleDateString()}</td>
                          <td>{service.description}</td>
                          <td>{service.invoiceNumber}</td>
                          <td>₹{service.amount?.toLocaleString()}</td>
                          <td>{service.remarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MaintenanceModal;
