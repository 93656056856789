import React from 'react';

const Legend = ({ saved, unsaved }) => {
  const legendStyles = {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    padding: '10px',
    fontSize: '14px',
    color: '#333333', // Updated text color for the legend
  };

  const itemStyle = (color) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333333', // Updated text color for each item
  });

  const colorBoxStyle = (color) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: color,
    color: '#ffffff', // Updated text color for numbers inside boxes
    borderRadius: '4px',
    fontWeight: 'bold',
  });

  return (
    <div style={legendStyles}>
      <div style={itemStyle('#FFF0F0')}>
        <div style={colorBoxStyle('#FF8A8A')}>{unsaved}</div>
        <span>Unsaved</span>
      </div>
      <div style={itemStyle('#E8F5E9')}>
        <div style={colorBoxStyle('#4CAF50')}>{saved}</div>
        <span>Saved</span>
      </div>
    </div>
  );
};

export default Legend;