import React, { useState, useEffect } from "react";
import "./DisplaySiteImages.css";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";

const DisplaySiteImages = ({ visibility, displayImages, selectedImages, handleSelectedImages }) => {

  displayImages = [
    "https://c8.alamy.com/comp/DHF9JT/interior-of-commercial-building-during-refurbishment-DHF9JT.jpg",
    "https://thumbs.dreamstime.com/z/room-as-construction-site-interior-design-new-house-building-material-252881625.jpg",
    "https://www.korteco.com/wp-content/uploads/2018/03/Teaser-3.jpg",
  ];

  return (
    <Box
      className={`slide-out-list ${visibility ? "visible" : ""}`}
      sx={{
        opacity: visibility ? 1 : 0,
        zIndex: 20000,
        width: visibility ? "10px" : "500px",
        minWidth: 600,
        maxWidth: 600,
        backgroundColor: "white",
        boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #e0e0e0", alignSelf: 'center' }}>
        Site Images
      </Typography>

      {!displayImages || displayImages.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            color: "#757575",
          }}
        >
          <ImageIcon sx={{ fontSize: 60, mb: 2 }} />
          <Typography variant="subtitle1">No Images to display</Typography>
        </Box>
      ) : (
        <List sx={{ flexGrow: 1, overflow: "auto" }}>
          {displayImages.map((image, index) => (
            <ListItem key={index} dense button onClick={() => handleSelectedImages(image)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedImages.includes(image)}
                  tabIndex={-1}
                  disableRipple
                  sx={{
                    borderRadius: "50%",
                    "&.Mui-checked": {
                      color: "#fdd34d",
                    },
                  }}
                />
              </ListItemIcon>
              <img
                src={image}
                style={{
                  width: "400px", // Set desired width
                  height: "300px", // Set desired height
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow
                  borderRadius: "8px", // Rounded corners
                  border: "1px solid #ddd", // Border
                  // objectFit: "cover", // Ensure image fills the container without stretching
                }}
                alt="Description of the image"
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default DisplaySiteImages;
