import { useState } from "react";
import Button from "@material-ui/core/Button";
import ModalDialog from "./ModalDialog";

const AddVendorCategory = ({ setWorkMaterialCategories }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="AddVendorCategory">
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        style={{
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
          height: "50px",
        }}
      >
        Add Vendor Category
      </Button>
      <ModalDialog
        open={open}
        handleClose={handleClose}
        setWorkMaterialCategories={setWorkMaterialCategories}
      />
    </div>
  );
};

export default AddVendorCategory;
