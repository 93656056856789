import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import EditOutwardEntry from "../../WriteFunction/EditOutwardEntry";
import DeleteOutwardEntry from "../../WriteFunction/DeleteOutwardEntry";
import { AiOutlineFileSearch } from "react-icons/ai"; // Icon from React Icons
import InwardEntryModal from "../../InwardEntryModal";
import ImageModal from "../../Components/ImageModal";

const OutwardCard = ({ index, item, project, userName }) => {
  const { outwardItems } = item; // Accessing outward items from the props
  const [openEntryModal, setOpenEntryModal] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Track the selected item for the modal
  const [imageItems, setImageItems] = useState([]);
  const [displayImage, setDisplayImage] = useState(false);

  const handleShowModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleViewBills = () => {
    const dummyImages = Object.values(item.images); // Replace with actual data or API response
    setImageItems(dummyImages);
    setDisplayImage(true);
  };

  function convertTimestampToDate(timestamp) {
    // Convert the timestamp from milliseconds to a Date object
    const date = new Date(timestamp);
    
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    
    // Return the formatted date
    return `${day}/${month}/${year}`;
}

  return (
    <div style={styles.cardContainer}>
      <div style={styles.cardHeader}>
        <h3 style={styles.cardTitle}>{`${index + 1}. Outward Items`}</h3>
        {Array.isArray(project) && (
          <span style={styles.projectName}>{item.projectName}</span>
        )}

        <div style={styles.iconWrapper}>
          <AiOutlineEdit
            size={20}
            style={styles.icon}
            onClick={() => setOpenEntryModal(true)}
          />
          <AiOutlineDelete
            size={20}
            style={styles.icon}
            onClick={() => DeleteOutwardEntry(item, project)}
          />
          {item.images && (
            <AiOutlineFileSearch
              style={{
                // position: "absolute",
                // top: "10px",
                // right: "10px",
                marginLeft: "8px",
                fontSize: "20px",
                color: "#333",
                cursor: "pointer",
              }}
              title="View Bills"
              onClick={handleViewBills} // Open modal on click
            />
          )}
        </div>
      </div>

      {outwardItems &&
        Object.values(outwardItems).map((outwardItem, index) => {
          const { category, description, quantityIssued, uom, issueHistory } =
            outwardItem;

          // Sorting and selecting recent history items
          const recentHistory = Object.values(issueHistory || {})
            .sort((a, b) => b.timestamp - a.timestamp)
            .slice(0, 3);

          return (
            <div key={index}>
              {/* Item Summary Section */}
              <div style={styles.summaryContainer}>
                <div style={styles.summaryItem}>
                  <strong>Item No. :</strong> {index + 1}
                </div>
                <div style={styles.summaryItem}>
                  <strong>Category:</strong> {category}
                </div>
                <div style={styles.summaryItem}>
                  <strong>Item:</strong> {description}
                </div>
                <div style={styles.summaryItem}>
                  <strong>Total Issued Quantity:</strong>{" "}
                  {issueHistory &&
                    Object.values(issueHistory).reduce((acc, issue) => {
                      return acc + Number(issue.quantity);
                    }, 0)}{" "}
                  {uom}
                </div>
                <div style={styles.summaryItem}>
                  <strong>From:</strong> {convertTimestampToDate(Number(Object.keys(issueHistory)[0]))}
                </div>
                <div style={styles.summaryItem}>
                  <strong>To:</strong> {convertTimestampToDate(Number(Object.keys(issueHistory)[Object.keys(issueHistory).length - 1]))}
                </div>
              </div>

              <div style={styles.historyContainer}>
                <table style={styles.historyTable}>
                  <thead>
                    <tr>
                      <th style={styles.tableCell}>Date</th>
                      {category === "Fuel" && (
                        <th style={styles.tableCell}>Vehicle</th>
                      )}
                      <th style={styles.tableCell}>Quantity</th>
                      <th style={styles.tableCell}>Purpose</th>
                      <th style={styles.tableCell}>Images</th>
                      <th style={styles.tableCell}>Recorded by</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentHistory.map((history, index) => (
                      <tr key={index} style={styles.tableRow}>
                        <td style={styles.tableCell}>
                          {history.timestamp
                            ? new Date(history.timestamp).toLocaleDateString()
                            : "N/A"}
                        </td>
                        {category === "Fuel" && (
                          <td style={styles.tableCell}>
                            {history.type || "N/A"}
                          </td>
                        )}
                        <td style={styles.tableCell}>
                          {history.quantity || "N/A"} {uom}
                        </td>
                        <td style={styles.tableCell}>
                          {history.purpose || "N/A"}
                        </td>
                        <td
                          style={{
                            ...styles.tableCell,
                            cursor: "pointer",
                            color: history.images ? "#007bff" : "#aaa",
                          }}
                          onClick={() => {
                            if (history.images) {
                              setDisplayImage(true);
                              setImageItems(() => (Object.values(history.images).map(item => ({ uri: item }))));
                            }
                          }}
                        >
                          {history.images ? "View Images" : "No Images"}
                        </td>
                        <td style={styles.tableCell}>{history.by || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {Object.values(issueHistory).length > 2 && (
                  <button
                    style={styles.viewMoreButton}
                    onClick={() => handleShowModal(outwardItem)}
                  >
                    View More
                  </button>
                )}
              </div>
            </div>
          );
        })}

      {openEntryModal && (
        <InwardEntryModal
          userName={userName}
          categoryItems={[]}
          project={project}
          entryType={"subtract"}
          close={() => {
            // setEntryType(null);
            setOpenEntryModal(false);
          }}
          editing={true}
          itemData={item}
        />
      )}

      {displayImage && (
        <ImageModal
          images={imageItems}
          selectedImageIndex={0}
          onClose={() => {
            setDisplayImage(false);
            setImageItems([]);
          }}
        />
      )}

      {/* More History Modal */}
      {showModal && selectedItem && (
        <MoreHistoryModal
          issueHistory={selectedItem.issueHistory}
          category={selectedItem.category} // Pass category
          itemName={selectedItem.description} // Pass item name
          uom={selectedItem.uom}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

// More History Modal Component
const MoreHistoryModal = ({
  issueHistory,
  uom,
  category,
  itemName,
  onClose,
}) => {
  const sortedHistory = Object.values(issueHistory || {}).sort(
    (a, b) => b.timestamp - a.timestamp
  );

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <button style={styles.closeButton} onClick={onClose}>
          ✖
        </button>
        <h3 style={styles.modalTitle}>
          Full Issue History for {itemName} ({category})
        </h3>
        <div style={styles.tableContainer}>
          <table style={styles.modalTable}>
            <thead style={styles.fixedHeader}>
              <tr>
                <th style={styles.tableHeader}>Date</th>
                {category === "Fuel" && (
                  <th style={styles.tableHeader}>Vehicle</th>
                )}
                <th style={styles.tableHeader}>Quantity</th>
                <th style={styles.tableHeader}>Purpose</th>
                <th style={styles.tableHeader}>Recorded by</th>
              </tr>
            </thead>
            <tbody style={styles.tableBody}>
              {sortedHistory.map((history, index) => (
                <tr key={index} style={styles.tableRow}>
                  <td style={styles.tableCell}>
                    {history.timestamp
                      ? new Date(history.timestamp).toLocaleDateString()
                      : "N/A"}
                  </td>
                  {category === "Fuel" && (
                    <td
                      style={{
                        ...styles.tableCell,
                        ...styles.vehicleCell,
                      }}
                    >
                      {history.type || "N/A"}
                    </td>
                  )}
                  <td style={styles.tableCell}>
                    {history.quantity || "N/A"} {uom}
                  </td>
                  <td style={{ ...styles.tableCell, ...styles.purposeCell }}>
                    {history.purpose || "N/A"}
                  </td>
                  <td style={{ ...styles.tableCell, ...styles.purposeCell }}>
                    {history.by || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Inline Styles
const styles = {
  cardContainer: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    margin: "0", // Remove side margins
    width: "100%", // Full width of the screen
    marginBottom: 25,
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between", // Ensures left and right alignment
    alignItems: "center", // Vertically centers content
    padding: "10px 20px", // Adds some spacing
    backgroundColor: "#fce496",
    color: "#353535",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    textAlign: "center",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  icon: {
    marginLeft: "8px",
    cursor: "pointer",
    color: "#555",
  },
  projectName: {
    fontSize: "20px",
    fontWeight: "800",
    color: "#8f8f8f", // Subtle color for differentiation
  },
  cardTitle: {
    fontSize: "20px",
    margin: 0,
    color: "#353535",
  },
  summaryContainer: {
    display: "grid",
    gridTemplateColumns: "8% repeat(5, 1fr)", // 10% for S.No. and 1fr (flexible) for other columns
    gap: "15px",
    padding: "10px",
    backgroundColor: "#f8f3e5",
    borderRadius: "6px",
    marginBottom: "20px",
  },
  summaryItem: {
    padding: "10px",
    backgroundColor: "#faeec7",
    borderRadius: "4px",
    textAlign: "center",
  },
  historyContainer: {
    marginTop: "20px",
  },
  historyTitle: {
    color: "#0073e6",
    marginBottom: "10px",
  },
  tableContainer: {
    maxHeight: "90vh", // Limit table height
    overflowY: "auto", // Enable vertical scrolling for the rows
    border: "1px solid #ddd",
  },
  fixedHeader: {
    position: "sticky", // Make the header sticky
    top: 0, // Stick to the top of the scrollable area
    backgroundColor: "#fdf8e8", // Match the header background
    zIndex: 2, // Ensure the header stays above the rows
  },
  // modalTable: {
  //   width: "100%",
  //   borderCollapse: "collapse",
  //   tableLayout: "fixed", // Ensures column widths are fixed
  //   zIndex: 1, // Ensures modal content is on top of the overlay
  // },
  // tableHeader: {
  //   padding: "10px",
  //   backgroundColor: "#fdf8e8",
  //   color: "#353535",
  //   fontWeight: "bold",
  //   textAlign: "center",
  //   verticalAlign: "middle", // Ensures headers are vertically aligned
  //   borderBottom: "2px solid #ddd", // Adds bottom border for separation
  //   zIndex: 3, // Keeps the headers above the table rows
  // },
  modalTable: {
    width: "100%",
    borderCollapse: "collapse", // Merge borders
    tableLayout: "fixed", // Fixed column widths
  },
  tableHeader: {
    padding: "10px",
    backgroundColor: "#fdf8e8",
    color: "#353535",
    fontWeight: "bold",
    textAlign: "center",
    verticalAlign: "middle",
    borderBottom: "2px solid #ddd",
    position: "sticky", // Keep headers in place
    top: 0, // Stick to the top of the scrollable container
    zIndex: 2, // Ensure headers appear above the rows
  },
  historyTable: {
    width: "100%",
    borderCollapse: "collapse", // Ensures borders are merged for cells
    marginBottom: "15px",
    tableLayout: "fixed", // Ensures column widths are fixed
  },
  tableRow: {
    backgroundColor: "#f2f2f2",
  },
  tableCell: {
    // padding: "10px",
    // border: "1px solid #ddd",
    // textAlign: "center", // Centers text for data cells
    // verticalAlign: "middle", // Ensures cells are vertically aligned
    // wordWrap: "break-word", // Ensures long text wraps within the cell
    // wordBreak: "break-word", // Breaks text at word boundaries
    padding: "10px",
    border: "1px solid #ddd",
    textAlign: "center", // Centers text for data cells
    verticalAlign: "middle", // Ensures cells are vertically aligned
    wordWrap: "break-word", // Ensures long text wraps within the cell
    wordBreak: "break-word", // Breaks text at word boundaries
    whiteSpace: "normal", // Allows text to wrap to the next line
    overflow: "hidden", // Prevents content from overflowing the cell
    textOverflow: "ellipsis", // Adds ellipsis if the text overflows
  },
  // Specific column widths for "Vehicle" and "Purpose"
  vehicleCell: {
    width: "25%", // 25% width for the Vehicle column
  },
  purposeCell: {
    width: "40%", // 40% width for the Purpose column, larger than Vehicle
    wordBreak: "break-word", // Ensure long text wraps to the next line
    whiteSpace: "normal", // Allow text to wrap
    textAlign: "left", // Align text to the left for better readability
  },
  viewMoreButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    padding: "8px 16px",
    backgroundColor: "#f5f5f5",
    color: "#353535",
    fontWeight: "500",
    fontSize: "14px",
    borderRadius: "8px",
    cursor: "pointer",
    border: "1px solid #fdd34d", // Subtle border for a formal look
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)", // Soft shadow for a subtle depth
    transition: "background-color 0.2s ease, transform 0.2s ease",
  },
  viewMoreButtonHover: {
    backgroundColor: "#e6e6e6", // Slightly darker on hover
    transform: "scale(1.02)", // Slightly larger on hover
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000, // Ensure overlay appears on top of all content
  },
  // modalContent: {
  //   position: "relative",
  //   width: "90%",
  //   maxWidth: "1000px",
  //   padding: "20px",
  //   backgroundColor: "white",
  //   borderRadius: "8px",
  //   zIndex: 1001, // Ensure modal content is on top of the overlay
  // },
  modalContent: {
    position: "relative",
    width: "90%",
    maxWidth: "1000px",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    zIndex: 1001, // Ensure modal content is on top of the overlay
    maxHeight: "90vh", // Set maximum height to 90% of the viewport height
    overflowY: "auto", // Enable vertical scrolling
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "20px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    zIndex: 1002, // Ensure close button is on top
  },
  modalTitle: {
    fontSize: "24px",
    marginBottom: "15px",
  },
};

export default OutwardCard;
