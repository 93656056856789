import { secondDatabase } from "../../../config/firebase";
import modifyOutwardNetInventory from "./modifyOutwardNetInventory";
import { uploadImagesToFirebase } from "./uploadImagesToFirebase";


async function EditOutwardEntry (firmId, projectId, items, ogOlderItems, itemsImages, previewImages, userName)  {

    // updating the net inventory data

    const isLocalURL = (url) =>
        url.startsWith("blob:") || url.startsWith("data:");
    
      // Helper function to get the correct URL or ID field
      const getImageUrlOrId = (image) => image.url || image.id; // Priority to 'url' if exists
    
      // // Updated createImageObject function
      const createImageObject = async (itemObject) => {
        let imageObject = {};
        let imageUploadQueue = []; // Queue for images to be uploaded
        let imageTimestamp = Date.now(); // Ensure unique timestamps
    
        for (let index = 0; index < itemObject.length; index++) {
          const image = itemObject[index];
          const imageUrlOrId = getImageUrlOrId(image); // Get the correct value (either url or id)
    
          if (isLocalURL(imageUrlOrId)) {
            imageUploadQueue.push({ id: image.id, file: image.file });
          } else {
            imageObject[imageTimestamp] = { url: imageUrlOrId }; // Already a Firebase URL
            imageTimestamp++;
          }
        }
    
        // Upload local images and map to their Firebase URLs
        if (imageUploadQueue.length > 0) {
          const uploadedURLs = await uploadImagesToFirebase(
            imageUploadQueue,
            "items"
          );
          uploadedURLs.forEach((url) => {
            imageObject[imageTimestamp] = { url };
            imageTimestamp++;
          });
        }
        console.log('createImageObject imageObject : ', imageObject)
        return imageObject;
      };
    
      // // Updated createBillImageObject function
      const createBillImageObject = async () => {
        let imageObject = {};
        let imageUploadQueue = []; // Queue for images to be uploaded
        let imageTimestamp = Date.now(); // Ensure unique timestamps
    
        for (let index = 0; index < previewImages.length; index++) {
    
          const image = previewImages[index];
          const imageUrlOrId = getImageUrlOrId(image); // Get the correct value (either url or id)
    
          if (isLocalURL(imageUrlOrId)) {
            imageUploadQueue.push({ id: image.id, file: image.file });
          } else {
            imageObject[imageTimestamp] = { url: imageUrlOrId }; // Already a Firebase URL
            imageTimestamp++;
          }
        }
    
        // Upload local images and map to their Firebase URLs
        if (imageUploadQueue.length > 0) {
          const uploadedURLs = await uploadImagesToFirebase(
            imageUploadQueue,
            "bills"
          );
          uploadedURLs.forEach((url) => {
            imageObject[imageTimestamp] = { url };
            imageTimestamp++;
          });
        }
    
        return imageObject;
      };

    
    const outwardRef = secondDatabase.ref(`${firmId}/projects/${projectId}/records/allOutward/${ogOlderItems.id}`);
    console.log("ogOlderItems['outwardItems'] : ", ogOlderItems['outwardItems'])
    const olderItems = Object.values(ogOlderItems['outwardItems']);
    await modifyOutwardNetInventory(firmId, projectId, olderItems, items, ogOlderItems.id, userName);
    console.log("olderItems : ", olderItems);
    let outwardItems = {};
    let ind = 0;
    for(let item of items){
        let issueHistory = Object.values(item['issueHistory'])[0];
        item['images'] = await createImageObject(itemsImages[ind]) || {};
        item['issueHistory'] = {
            [issueHistory.timestamp || new Date().getTime()] : {
                by: userName || "Not provided",
                purpose: issueHistory.purpose || "Not provided",
                quantity: item.quantityAccepted || "0",
                timestamp: issueHistory.timestamp || new Date().getTime(),
                type: issueHistory.vehicle || "Not provided",
                images: item['images']
            }
        }
        outwardItems[item.id] = item;
        ind = ind + 1;
    }
    ogOlderItems['outwardItems'] = outwardItems;
    ogOlderItems['images'] = await createBillImageObject();
    outwardRef.update(ogOlderItems);
}


export default EditOutwardEntry;