import React from "react";
import { FaExclamationTriangle } from "react-icons/fa"; // Icon from react-icons

const NoData = () => {
  return (
    <div style={styles.container}>
      <FaExclamationTriangle size={50} style={styles.icon} />
      <p style={styles.text}>No Data Present at this Moment</p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
    border: "1px dashed #fdd34d", // Border to represent caution/warning
    borderRadius: "10px",
    backgroundColor: "#f7f7f7", // Light gray background
    padding: "20px",
  },
  icon: {
    color: "#fdd34d", // Use the specified color for the icon
    marginBottom: "10px",
  },
  text: {
    color: "#666", // Dark gray text for better readability
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "center",
  },
};

export default NoData;
