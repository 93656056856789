import React from "react";

const TabComponent = ({ selectedTab, setSelectedTab, setSearchQuery, setStartDate, setEndDate}) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
    },
    headingWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      position: "relative",
    },
    heading: {
      cursor: "pointer",
      padding: "10px 20px",
      fontSize: "18px",
      position: "relative",
      zIndex: 1, // Ensures text stays above the border
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1, // Makes sure the headings span equally
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
    },
    borderBottom: {
      position: "absolute",
      bottom: 0,
      height: "6px",
      backgroundColor: "#fdd34d",
      transition: "width 0.3s, left 0.3s",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.headingWrapper}>
        <h1
          style={{
            ...styles.heading,
            borderBottom:
              selectedTab === "All Materials" ? "4px solid #fdd34d" : "none",
          }}
          onClick={() => {
            setSelectedTab("All Materials");
            setSearchQuery('');
            setStartDate("");
            setEndDate("");
          }}
        >
          All Materials
        </h1>
        <h1
          style={{
            ...styles.heading,
            borderBottom:
              selectedTab === "Inward" ? "4px solid #fdd34d" : "none",
          }}
          onClick={() => {
            setSelectedTab("Inward");
            setSearchQuery('');
            setStartDate("");
            setEndDate("");
          }}
        >
          Inward
        </h1>
        <h1
          style={{
            ...styles.heading,
            borderBottom:
              selectedTab === "Outward" ? "4px solid #fdd34d" : "none",
          }}
          onClick={() => {
            setSelectedTab("Outward");
            setSearchQuery('');
            setStartDate("");
            setEndDate("");
          }}
        >
          Outward
        </h1>
      </div>

      {/* Border directly below each tab, with no visible border for non-selected tabs */}
      <div
        style={{
          ...styles.borderBottom,
          width: "33.33%",
          left: selectedTab === "All Materials" ? "0%" : selectedTab === "Inward" ? "33.33%" : "66.66%",
        }}
      />
    </div>
  );
};

export default TabComponent;