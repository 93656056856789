import { useState, useEffect } from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import getWorkMaterialByCategory from "../../helpers/getWorkMaterialByCategory";
import getCategories from "../../helpers/getCategories";
import DraftService from "../../api/draftService";
import { AiOutlineInfoCircle } from "react-icons/ai";

const UnreleasedItemsForm = ({
  handleClose,
  sendItems,
  data,
  orderType,
  sendCheckedWorks,
  selectedItems,
}) => {
  const [revision, setRevision] = useState(data.rooms);
  const [categories, setCategories] = useState(
    orderType == "Purchase Order" || orderType == "Material Quotation"
      ? getCategories(data).materialCategories
      : getCategories(data).workCategories
  );
  const [category, setCategory] = useState(categories[0]);
  const [checkedWorks, setCheckedWorks] = useState([]);
  const [works, setWorks] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [selectedItemsAmount, setSelectedItemsAmount] = useState(0);
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedItemsOrderId, setSelectedItemsOrderId] = useState([]);
  const [showMore, setShowMore] = useState([]);

  useEffect(() => {
    if (data) {
      setRevision(data.rooms);
      setCategories(
        orderType == "Purchase Order" || orderType == "Material Quotation"
          ? getCategories(data).materialCategories
          : getCategories(data).workCategories
      );
    }
  }, [data]);

  useEffect(() => {
    if (category) {
      setCheckedWorks([]);
      const { Work, Material } = getWorkMaterialByCategory(
        { rooms: revision },
        category
      );
      const works = Work;
      const materials = Material;
      setWorks(works.filter((item) => item.status != "ordered" && item.status != "inApproval"));
      setMaterials(materials.filter((item) => item.status != "ordered" && item.status != "inApproval"));
    }
  }, [category]);

  useEffect(() => {
    let temp = [];
    selectedItems.map((item, index) => temp.push(item.OrderId));
    setSelectedItemsOrderId(temp);
  }, [selectedItems]);

  // useEffect(() => {
  //     console.log(`selectedMaterials`, selectedMaterials)
  // }, [selectedMaterials])

  useEffect(() => {
    if (data) {
      fetchDrafts();
    }
  }, [data]);

  const [savedDrafts, setSavedDrafts] = useState([]);
  const [draftsOrderId, setDraftsOrderId] = useState([]);

  const fetchDrafts = async () => {
    const draftService = new DraftService();
    const response = await draftService.getDraft(data.ProjectId);
    console.log(`Response from fetchDrafts API: `, response);
    setSavedDrafts(response.payload);
  };

  useEffect(() => {
    let allOrderIdsDrafts = [];
    savedDrafts.map((draft) =>
      JSON.parse(draft.selectedItems).map((item) =>
        allOrderIdsDrafts.push(item.OrderId)
      )
    );
    setDraftsOrderId(allOrderIdsDrafts);
  }, [savedDrafts]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ width: "100%", padding: 20 }}>
        <FormControl
          variant="filled"
          required
          style={{
            borderRadius: "8px",
            fontSize: "20px",
            width: "100%",
            paddingBottom: "10px",
          }}
        >
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            onChange={(event) => {
              setCategory(event.target.value);
              setSelectedWorks([]);
              setSelectedMaterials([]);
            }}
          >
            {categories.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 20,
            fontWeight: "bold",
          }}
        >
          <p>Unreleased Orders</p>
          <p>INR {selectedItemsAmount.toFixed(2)}</p>
        </div>
        <div>
          {works.map(
            (work, index) =>
              !selectedItemsOrderId.includes(work.OrderId) &&
              work.rate != "quotation" && (
                <div
                  key={index}
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 80,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {!draftsOrderId.includes(work.OrderId) && (
                      <input
                        type="checkbox"
                        checked={checkedWorks.includes(index)}
                        style={{ minHeight: "24px", minWidth: "24px" }}
                        onChange={(e) => {
                          setCheckedWorks((st) =>
                            st.includes(index)
                              ? st.filter((x) => x !== index)
                              : [...st, index]
                          );
                          setSelectedItemsAmount(
                            checkedWorks.includes(index)
                              ? selectedItemsAmount -
                                  (work["quantity"] * work["rate"] +
                                    work["quantity"] *
                                      work["rate"] *
                                      (work["gst"] / 100))
                              : selectedItemsAmount +
                                  (work["quantity"] * work["rate"] +
                                    work["quantity"] *
                                      work["rate"] *
                                      (work["gst"] / 100))
                          );
                          setSelectedWorks((st) =>
                            st.some((x) => x.indexces == work.indexces)
                              ? st.filter((x) => x.indexces != work.indexces)
                              : [...st, work]
                          );
                        }}
                      />
                    )}
                    <div
                      style={{
                        width: "2px",
                        height: "65px",
                        margin: "0px 20px",
                        backgroundColor: "#ffaf99",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      {work.description.length > 100 ? (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {showMore.includes(work.description) ? (
                              <span>
                                {work["Unit Name"]} |{" "}
                                {work.description
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')}
                              </span>
                            ) : (
                              <span>
                                {work["Unit Name"]} |{" "}
                                {work.description
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')
                                  .substring(0, 100)}
                                ....
                              </span>
                            )}
                          </span>
                          <span>
                            <AiOutlineInfoCircle
                              title={
                                showMore.length > 0 ? "showless" : "showmore"
                              }
                              onClick={() => {
                                if (showMore.includes(work.description)) {
                                  setShowMore(
                                    showMore.filter(
                                      (item) => item !== work.description
                                    )
                                  );
                                } else {
                                  setShowMore([...showMore, work.description]);
                                }
                              }}
                              style={{
                                color: "gray",
                                cursor: "pointer",
                                marginRight: "25px",
                                marginTop: "2px",
                              }}
                            />
                          </span>
                        </span>
                      ) : (
                        <span style={{ width: "80%" }}>
                          {work["Unit Name"]} |{" "}
                          {work.description
                            .replace(/<new-line>/g, "\n")
                            .replace(/<single-quote>/g, "'")
                            .replace(/<double-quote>/g, '"')}
                        </span>
                      )}
                      <p>
                        {"\u20B9 "}
                        {work["rate"]}/{work["unit"]}
                      </p>
                      <small>{work["vendorCategory"]}</small>
                      <small>Work Type: {work["workType"]}</small>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      minWidth: 150,
                    }}
                  >
                    <p>
                      {isNaN(work.quantity) || work.quantity.length == 0
                        ? 0
                        : work.quantity}{" "}
                      {work["unit"]}
                    </p>
                    <p>
                      {"\u20B9 "}
                      {(
                        work["quantity"] * work["rate"] +
                          work["quantity"] *
                            work["rate"] *
                            (work["gst"] / 100) || 0
                      ).toFixed(2)}
                    </p>
                    {draftsOrderId.includes(work.OrderId) && (
                      <p style={{ color: "#4685D4" }}>Already in draft</p>
                    )}
                  </div>
                </div>
              )
          )}

          {(orderType == "Work Order" || orderType == "Work Quotation") &&
            works.filter((item) => !selectedItemsOrderId.includes(item.OrderId))
              .length <= 0 && (
              <p>No unreleased items remaining for this category!</p>
            )}

          {materials.map(
            (material, index) =>
              !selectedItemsOrderId.includes(material.OrderId) &&
              material.rate != "quotation" && (
                <div
                  key={works.length + index}
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 80,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {!draftsOrderId.includes(material.OrderId) && (
                      <input
                        type="checkbox"
                        checked={checkedWorks.includes(works.length + index)}
                        style={{ minHeight: "24px", minWidth: "24px" }}
                        onChange={() => {
                          setCheckedWorks((st) =>
                            st.includes(works.length + index)
                              ? st.filter((x) => x !== works.length + index)
                              : [...st, works.length + index]
                          );
                          setSelectedItemsAmount(
                            checkedWorks.includes(index)
                              ? selectedItemsAmount -
                                  (material["quantity"] * material["rate"] +
                                    material["quantity"] *
                                      material["rate"] *
                                      (material["gst"] / 100))
                              : selectedItemsAmount +
                                  (material["quantity"] * material["rate"] +
                                    material["quantity"] *
                                      material["rate"] *
                                      (material["gst"] / 100))
                          );
                          setSelectedMaterials((st) =>
                            st.some((x) => x.indexces == material.indexces)
                              ? st.filter(
                                  (x) => x.indexces != material.indexces
                                )
                              : [...st, material]
                          );
                        }}
                      />
                    )}
                    <div
                      style={{
                        width: "2px",
                        height: "65px",
                        margin: "0px 20px",
                        backgroundColor: "#ffaf99",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      {material.specification.length > 100 ? (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{ width: "90%" }}>
                            {showMore.includes(material.specification) ? (
                              <span>
                                {material["Unit Name"]} |{" "}
                                {material.specification
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')}
                              </span>
                            ) : (
                              <span>
                                {material["Unit Name"]} |{" "}
                                {material.specification
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')
                                  .substring(0, 50)}
                                ....
                              </span>
                            )}
                          </span>
                          <span>
                            <AiOutlineInfoCircle
                              title={
                                showMore.length > 0 ? "showless" : "showmore"
                              }
                              onClick={() => {
                                if (showMore.includes(material.specification)) {
                                  setShowMore(
                                    showMore.filter(
                                      (item) => item !== material.specification
                                    )
                                  );
                                } else {
                                  setShowMore([
                                    ...showMore,
                                    material.specification,
                                  ]);
                                }
                              }}
                              style={{ color: "gray", cursor: "pointer" }}
                            />
                          </span>
                        </span>
                      ) : (
                        <span style={{ width: "80%" }}>
                          {material["Unit Name"]} |{" "}
                          {material.specification
                            .replace(/<new-line>/g, "\n")
                            .replace(/<single-quote>/g, "'")
                            .replace(/<double-quote>/g, '"')}
                        </span>
                      )}
                      <p>{material["type"]}</p>
                      <p>
                        {"\u20B9 "}
                        {material["rate"]}/{material["unit"]}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      minWidth: 150,
                    }}
                  >
                    <p>
                      {isNaN(material.quantity) || material.quantity.length == 0
                        ? 0
                        : material.quantity}{" "}
                      {material.unit}
                    </p>
                    <p>
                      {"\u20B9 "}
                      {(
                        material["quantity"] * material["rate"] +
                          material["quantity"] *
                            material["rate"] *
                            (material["gst"] / 100) || 0
                      ).toFixed(2)}
                    </p>
                    {draftsOrderId.includes(material.OrderId) && (
                      <p style={{ color: "#4685D4" }}>Already in draft</p>
                    )}
                  </div>
                </div>
              )
          )}
        </div>

        {(orderType == "Purchase Order" || orderType == "Material Quotation") &&
          materials.filter(
            (item) => !selectedItemsOrderId.includes(item.OrderId)
          ).length <= 0 && (
            <p>No unreleased items remaining for this category!</p>
          )}
      </div>
      <div
        style={{
          width: "90%",
          bottom: "0",
          position: "sticky",
          paddingBottom: "10px",
          backgroundColor: "#fff",
        }}
      >
        {(works.length > 0 || materials.length > 0) && checkedWorks.length > 0 && (
          <div
            style={{
              width: "100%",
              height: "50px",
              backgroundColor: "rgb(255, 211, 77)",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 0px 3px grey",
              color: "00aaff",
              fontWeight: "500",
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleClose();
              sendItems(
                selectedWorks.length > 0 ? selectedWorks : selectedMaterials
              );
              sendCheckedWorks(checkedWorks);
            }}
          >
            Add Items
          </div>
        )}
      </div>
    </div>
  );
};

export default UnreleasedItemsForm;
