import React from "react";
import { LiaBrainSolid } from "react-icons/lia";
import { TbPencilPlus } from "react-icons/tb";
import { BiSolidCircleQuarter } from "react-icons/bi";

const AnalyticsBrowser = ({ width }) => {
    
  return (
    <div
      style={{
        width: width,
        maxWidth: width,
        minWidth: width,
        backgroundColor: "#F9F9F9",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 50,
        }}
      >
        <p
          style={{
            marginLeft: 10,
            fontStyle: "Montserrat",
            fontSize: 30,
            fontWeight: 700,
          }}
        >
          Analytics
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: 10,
            alignItems: "center",
          }}
        >
          <LiaBrainSolid size={25} />
          <p>AI Bot</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginRight: 10,
          marginTop: 100,
        }}
      >
        <p style={{ fontStyle: "Montserrat", fontSize: 24, fontWeight: 600 }}>
          New Graph
        </p>
        <TbPencilPlus size={25} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginRight: 10,
          marginTop: 100,
          cursor: "pointer",
        }}
      >
        <p style={{ fontStyle: "Montserrat", fontSize: 20, fontWeight: 600 }}>
          Amount vs Date Graph
        </p>
        <BiSolidCircleQuarter
          style={{ transform: "rotate(-135deg)" }}
          size={25}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginRight: 10,
          marginTop: 15,
          cursor: "pointer",
        }}
      >
        <p style={{ fontStyle: "Montserrat", fontSize: 20 }}>
          No. of PO vs Date Graph
        </p>
        {/* <BiSolidCircleQuarter style={{ transform: 'rotate(-135deg)' }} size={25} /> */}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginRight: 10,
          marginTop: 15,
          cursor: "pointer",
        }}
      >
        <p style={{ fontStyle: "Montserrat", fontSize: 20 }}>
          Paid/ Unpaid vs Date Graph
        </p>
        {/* <BiSolidCircleQuarter style={{ transform: 'rotate(-135deg)' }} size={25} /> */}
      </div>
    </div>
  );
};

export default AnalyticsBrowser;
