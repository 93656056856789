import React from "react";
import "./dropDown.scss";

export default ({ onChange, value, data, disabled }) => {
  return (
    <div className="dropDown">
      <select
        onChange={(e) => onChange && onChange(e.currentTarget.value)}
        value={value}
        disabled={disabled}
      >
        {data.map((option, index) => {
          return <option key={index}>{option}</option>;
        })}
      </select>
    </div>
  );
};
