import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Form from "./Form";

const AddVendor = ({ getIsVendorModalClosed }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="AddVendor">
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
        style={{
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
          height: "50px",
          // border:"2px solid red"
        }}
      >
        Add Vendor
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <Form
          handleClose={() => {
            getIsVendorModalClosed(true);
            setOpen(false);
          }}
          setOpen={setOpen}
        />
      </Dialog>
    </div>
  );
};

export default AddVendor;
