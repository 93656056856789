import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./AssignProjects.scss";

const AssignProjects = ({
  handleClose,
  selectedProjectAssingnedDetials,
  options,
  onAdminChange,
}) => {
  // console.log('selectedProjectAssingnedDetials',JSON.parse(selectedProjectAssingnedDetials.AssignedProjects))
  // email, firm, status, accountType, AssignedProjects

  const UpdateHandler = async (projectstoUpdate, contactNumber) => {
    // console.log('upload handler and ',JSON.stringify(projectstoUpdate),'other details ',selectedProjectAssingnedDetials)
    await onAdminChange(
      selectedProjectAssingnedDetials.email,
      selectedProjectAssingnedDetials.Firm,
      selectedProjectAssingnedDetials.active,
      selectedProjectAssingnedDetials.accountType,
      projectstoUpdate,
      contactNumber
    );
    // console.log('updates')
    handleClose();
  };

  const [assignedProjects, setAssignedProjects] = useState(
    JSON?.parse(selectedProjectAssingnedDetials?.AssignedProjects)
  );
  const [contactNumber, setContactNumber] = useState(
    selectedProjectAssingnedDetials?.ContactNumber
      ? selectedProjectAssingnedDetials?.ContactNumber
      : ""
  );
  return (
    <div className="assign-projects-modal">
      <h3>Assign Projects</h3>
      <div className="user-details">
        <p>
          <span className="user-details-heading">User: </span>
          <span>{selectedProjectAssingnedDetials.email}</span>
        </p>
        <p>
          <span className="user-details-heading">Account Type: </span>
          <span>{selectedProjectAssingnedDetials.accountType}</span>
        </p>
        <div>
          <span className="user-details-heading">ContactNumber: </span>
          <input
            required
            maxLength="10"
            placeholder="Contact Number"
            className="user-details-contact"
            value={contactNumber !== null ? contactNumber : 0}
            onChange={(e) => {
              let val = e.currentTarget.value.replace(/(?!-)[^0-9.]/g, "");
              setContactNumber(val);
            }}
          />
        </div>
      </div>
      {selectedProjectAssingnedDetials.accountType === "Admin" ||
      selectedProjectAssingnedDetials.accountType === "Owner" ? (
        <p>Admins can access all projects</p>
      ) : (
        <div className="user-assigned-projects">
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={options}
            getOptionDisabled={(option) => {
              let matched = false;
              assignedProjects?.map((e) => {
                if (e.ProjectId === option.ProjectId) {
                  matched = true;
                  return true;
                }
              });
              return matched;
              //     console.log('optino in disable ',option)
              // return assignedProjects.forEach(element =>
              //     {   console.log('assdddd',assignedProjects)
              //          return option.ProjectId === element.ProjectId})
            }}
            value={assignedProjects || undefined}
            getOptionLabel={(option) => option.ClientName}
            //   defaultValue={assignedProjects}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assigned Projects"
                placeholder="Assign Projects"
              />
            )}
            sx={{ width: "300px" }}
            onChange={(e, val) => {
              // console.log("values assigned",val)
              setAssignedProjects(val);
            }}
          />
        </div>
      )}
      <div>
        <button
          className="assign-modal-update-btn"
          onClick={() => {
            UpdateHandler(assignedProjects, contactNumber);
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};
export default AssignProjects;
