import React, { useEffect, useState } from "react";
import { LuSaveAll } from "react-icons/lu"; // Importing the save icon from react-icons

const SavingButton = ({ isLoading, onClick, label }) => {
  const [currentMessage, setCurrentMessage] = useState(0);

  const waitingMessages = [
    "Waiting for server response...",
    "Uploading image...",
    "Writing images URLs...",
    "Hang tight, we're working on it!",
    "Just a moment...",
    "We hope you are having good day... :)"
  ];

  // Change the waiting message every 1500ms while loading
  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setCurrentMessage((prevMessage) =>
          prevMessage === waitingMessages.length - 1 ? 0 : prevMessage + 1
        );
      }, 2000);
    }

    return () => {
      clearInterval(interval); // Clean up the interval on unmount or when loading is false
    };
  }, [isLoading]);

  const styles = {
    saveButton: {
      backgroundColor: "#4caf50",
      border: "none",
      borderRadius: "8px",
      cursor: isLoading ? "default" : "pointer",
      fontSize: "18px",
      color: "#fff",
      transition: "background-color 0.2s",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative", // To align spinner correctly
      overflow: "hidden",
      width: "450px", // Fixed width for the button
      flexDirection: "row", // Set flex direction to row for icon and text alignment
      textAlign: "center",
      padding: "10px 20px", // Add padding for better alignment
    },
    spinner: {
      border: "4px solid rgba(255, 255, 255, 0.3)", // Light border
      borderTop: "4px solid #fff", // Rotating segment
      borderRadius: "50%",
      width: "20px",
      height: "20px",
      animation: "spin 1s linear infinite", // Continuous rotation
    },
    label: {
      marginLeft: "10px", // Space between the icon and label
      opacity: isLoading ? 0 : 1, // Hide label when spinner is active
      transition: "opacity 0.2s ease",
      fontWeight: '700',
      color: '#353535'
    },
    waitingText: {
      fontSize: "14px",
      color: "#fff",
      marginTop: "10px",
      opacity: isLoading ? 1 : 0,
      transition: "opacity 0.5s ease",
      height: "20px",
    },
    icon: {
      marginRight: "10px", // Space between icon and label
      color: '#353535', // White icon color
      fontSize: "30px", // Make the icon significantly larger
    },
    // Spinner animation (CSS keyframes)
    "@keyframes spin": {
      from: { transform: "rotate(0deg)" },
      to: { transform: "rotate(360deg)" },
    },
  };

  // Inject keyframes for spinner animation dynamically
  const injectKeyframes = () => {
    const sheet = document.styleSheets[0];
    const keyframes = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    if (sheet && !Array.from(sheet.cssRules).some((rule) => rule.name === "spin")) {
      sheet.insertRule(keyframes, sheet.cssRules.length);
    }
  };

  injectKeyframes(); // Ensure the animation is defined

  return (
    <div style={styles.saveButton}>
      <button
        style={styles.saveButton}
        onClick={!isLoading ? onClick : null}
        disabled={isLoading}
      >
        {/* Display the save icon when not loading, with increased size */}
        {!isLoading && <LuSaveAll style={styles.icon} />}
        
        {isLoading && <div style={styles.spinner}></div>}
        
        {!isLoading && <span style={styles.label}>{label}</span>}
      </button>
      
      {/* Display waiting text when loading */}
      {isLoading && (
        <div style={styles.waitingText}>
          {waitingMessages[currentMessage]}
        </div>
      )}
    </div>
  );
};

export default SavingButton;
