const CheckboxComponent = ({isChecked, handleCheckboxChange}) => {

  return (
    <label style={styles.checkboxContainer}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        style={styles.checkbox}
      />
      <span
        style={{
          ...styles.customCheckbox,
          backgroundColor: isChecked ? "#4CAF50" : "#fff",
          borderColor: isChecked ? "#45a049" : "#4CAF50",
        }}
      >
        {isChecked && <span style={styles.checkmark}>✓</span>}
      </span>
      <span style={styles.labelText}>Additional Information</span>
    </label>
  );
};

const styles = {
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "16px",
    color: "#333",
  },
  checkbox: {
    display: "none", // Hide default checkbox
  },
  customCheckbox: {
    width: "18px",
    height: "18px",
    borderRadius: "4px",
    border: "2px solid #fdd34d",
    backgroundColor: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s, border-color 0.3s",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
  },
  checkmark: {
    color: "white",
    fontSize: "12px",
  },
  labelText: {
    fontWeight: "500",
    color: "#333",
  },
};

export default CheckboxComponent;
