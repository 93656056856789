import { updateIf } from "typescript";
import { secondDatabase } from "../../../config/firebase";
import saveInventoryData from "./updateNetInventory";
import { uploadImagesToFirebase } from "./uploadImagesToFirebase";

async function saveInwardEntriesToFirebase (firmId, projectId, items, additionalInfo, itemsImages, previewImages, userName)  {
  // Firebase reference path

  console.log('saveInwardEntriesToFirebase itemsImages : ', itemsImages);

  let imageTimestamp = new Date().getTime();

  for (let index = 0; index < items.length; index++) {
    if(itemsImages[index].length > 0){
      console.log('itemsImages[index] : ', itemsImages[index])
      let imageArray = await uploadImagesToFirebase(itemsImages[index]);
      let imageObject = {};
      for(let url of imageArray){
        imageObject[imageTimestamp] = {
          url : url
        }
        imageTimestamp = imageTimestamp + 1;
      }
      items[index]['images'] = imageObject;
    }
  }

  const getBillImages = async () => {
    let imageObject = {};
    if(previewImages.length > 0){
      console.log('previewImages : ', previewImages)
      let imageArray = await uploadImagesToFirebase(previewImages);
      for(let url of imageArray){
        imageObject[imageTimestamp] = {
          url : url
        }
        imageTimestamp = imageTimestamp + 1;
      }
    }
    console.log('getBillImages imageObject : ', imageObject)
    return imageObject;
  }

  const basePath = `${firmId}/projects/${projectId}/records/allInward/`;

  const timestamp = new Date().getTime();

  // Construct the inward entry object
  const inwardEntry = {
    fields: {
      "01": {
        name: "Referral PO No.",
        value: additionalInfo.referralPONo || "Not provided",
      },
      "02": {
        name: "Material Requisition Note (MRN)",
        value: additionalInfo.mrn || "Not provided",
      },
      "03": {
        name: "Vehicle No.",
        value: additionalInfo.vehicleNo || "Not provided",
      },
      "04": {
        "01": {
          name: "Invoice No.",
          value: additionalInfo.invoiceNumber || "Not provided",
        },
        "02": {
          name: "Invoice Date",
          value: additionalInfo.invoiceDate || "Not provided",
        },
      },
      "05": {
        "01": {
          name: "Gate Pass No.",
          value: additionalInfo.gatePassNo || "Not provided",
        },
        "02": {
          name: "Gate Pass Date",
          value: additionalInfo.gatePassDate || "Not provided",
        },
      },
      "06": {
        "01": {
          name: "DC No.",
          value: additionalInfo.dcNo || "Not provided",
        },
        "02": {
          name: "DC Date",
          value: additionalInfo.dcDate || "Not provided",
        },
      },
      "07": {
        name: "Supplier/Vendor",
        value: additionalInfo.supplierVendor || "Not provided",
      },
    },
    invoices: {
      [timestamp]: {
        invoiceItems: items.reduce((acc, item) => {
          acc[item.id] = {
            id: item.id,
            amount: "0",
            category: item.category || "Not provided",
            dateRequired: item.date || "Not provided",
            description: item.description || "Not provided",
            descriptionId: item.descriptionId,
            gst: "0",
            historyId: timestamp,
            images: item.images || {},
            itemID: item.descriptionId,
            quantityAccepted: item.quantityAccepted || "0",
            quantityOrdered: "0",
            quantityReceived: "0",
            quantityRejected: item.quantityRejected || "0",
            quantityRequired: "0",
            rate: "0",
            remark: item.purpose || "None",
            uom: item.unit || "Not provided",
          };
          return acc;
        }, {}),
        invoiceNumber: additionalInfo.invoiceNumber || "Not provided",
        timeStamp: additionalInfo.invoiceDate || "Not provided",
        id: timestamp,
      },
    },
    poNumber: additionalInfo.poNumber || "Not provided",
    referalPONumber: additionalInfo.referralPONo || "-",
    requestHistory: {
      [timestamp]: {
        associatedPersons: "",
        doneBy: userName,
        forwardedTo: "",
        noOfItem: items.length,
        remark: "None",
        status: "Released",
        timestamp: timestamp,
      },
    },
    status: "Released",
    id: timestamp,
    vendorName: additionalInfo.supplierVendor || "Not provided",
    inTime: additionalInfo.inTime,
    outTime: additionalInfo.outTime,
    images: await getBillImages(),
    timeStamp: timestamp
  };

  for (let index = 0; index < items.length; index++) {
    await saveInventoryData(firmId, projectId, items[index], additionalInfo, timestamp, userName);
  }
  
  const refPath = secondDatabase.ref(`${basePath}${timestamp}`);
  return refPath.update(inwardEntry)
    .then(() => console.log("Inward entry saved successfully."))
    .catch((error) => console.error("Error saving inward entry:", error));
  
}

export default saveInwardEntriesToFirebase;
