import React from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "12px",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: "none",
  width: "80vw",
  height: "90vh",
  display: "flex",
  flexDirection: "column",
}));

const CloseButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: theme.palette.grey[500],
}));

const PDFViewerModal = ({ open, handleClose, pdfUrl }) => {
  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="pdf-viewer-modal"
      aria-describedby="modal-to-view-pdf">
      <ModalContent>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <h2 id="pdf-viewer-modal" style={{ marginBottom: "20px" }}>
          View PDF
        </h2>
        <iframe
          src={pdfUrl}
          width="100%"
          height="100%"
          style={{ border: "none" }}
          title="Invoice PDF"
        />
      </ModalContent>
    </StyledModal>
  );
};

export default PDFViewerModal;
