/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import { element } from "prop-types";
import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Button } from "../../components";
import "./table2.scss";

const Preview = ({
  handleClose,
  isApproval,
  orderType,
  selectedItems,
  contactPersonName,
  contactPersonNumber,
  siteEngineerName,
  siteEngineerNumber,
  discount,
  totalAmount,
  startDate,
  endDate,
  vendorMobile,
  vendorFirm,
  vendorGSTIN,
  vendorAddress,
  vendorPinCode,
  vendorCity,
  vendorState,
  termsAndConditions,
  paymentTerms,
  specialInstructions,
  priceBase,
  freight,
  insurance,
  shippingAddress,
  billingAddress,
  firmName,
  firmAddress,
  firmPhoneNumber,
  firmGSTIN,
  firmContactPersonName,
  firmContactPersonNumber,
  firmContactPersonEmail,
  firmSignature,
  releaseOpportunityQuotation,
  attachments,
  milestoneTobeAddedIndex,
  imageULS,
  // clientName
  setIsUpdatingProgress,
  totalPaidAmountReleasedOrder,
  defaultMilestones,
  ImagesObjectFetchedFromFirebase,
}) => {
  
  var counter = 0;
  var orderNo = 0;
  var commonMilestonesIndex = 0;
  var commonMilestoneAmount = 0;

  const [newItems, setNewItems] = useState(selectedItems);

  useEffect(() => {

    let highlightTimer = setTimeout(() => {
      let textarea = (elem, regex) => {
        for (let i = 0; i < elem.length; i++) {
          elem[i].innerHTML = elem[i].innerHTML.replace(
            regex,
            "<mark>$&</mark>"
          );
        }
      };
      textarea(
        document.querySelectorAll(".desc-highlight-brand"),
        /@[a-zA-Z_-]{1,}/g
      );
    }, 0);

    return () => clearTimeout(highlightTimer);

  });

  useEffect(() => {
    const newSelectedItems = [];

    if (milestoneTobeAddedIndex.length == 0) {
      let tempItems = [...newItems];
      const arrayOfDescriptionandSpecifications = [];

      let tempObjFreqDesc = {};

      for (let index = 0; index <= tempItems.length - 1; index++) {
        if (typeof tempItems[index].workType != "undefined") {
          if (
            arrayOfDescriptionandSpecifications.includes(
              tempItems[index].description
            )
          ) {
            tempObjFreqDesc[tempItems[index].description].rate.push(
              +tempItems[index].rate
            );
            tempObjFreqDesc[tempItems[index].description].quantity.push(
              +tempItems[index].quantity
            );
            tempObjFreqDesc[tempItems[index].description].workType.push(
              tempItems[index].workType
            );
            tempObjFreqDesc[tempItems[index].description].indexes.push(index);
            tempObjFreqDesc[tempItems[index].description].unit.push(
              tempItems[index].unit
            );
          } else {
            arrayOfDescriptionandSpecifications.push(
              tempItems[index].description
            );

            tempObjFreqDesc[tempItems[index].description] = {
              rate: [+tempItems[index].rate],
              quantity: [+tempItems[index].quantity],
              workType: [tempItems[index].workType],
              indexes: [index],
              unit: [tempItems[index].unit],
            };
          }
        } else {
          if (
            arrayOfDescriptionandSpecifications.includes(
              tempItems[index].specification
            )
          ) {
            tempObjFreqDesc[tempItems[index].specification].rate.push(
              +tempItems[index].rate
            );
            tempObjFreqDesc[tempItems[index].specification].quantity.push(
              +tempItems[index].quantity
            );
            tempObjFreqDesc[tempItems[index].specification].indexes.push(index);
            tempObjFreqDesc[tempItems[index].specification].unit.push(
              tempItems[index].unit
            );
          } else {
            // write the code here
            arrayOfDescriptionandSpecifications.push(
              tempItems[index].specification
            );
            tempObjFreqDesc[tempItems[index].specification] = {
              rate: [+tempItems[index].rate],
              quantity: [+tempItems[index].quantity],
              // workType: [tempItems[index].workType],
              indexes: [index],
              unit: [tempItems[index].unit],
            };
          }
        }
      }

      let tempRatesCheck = {};
      let tempFinalFiltered = [];

      for (
        let objectIndex = 0;
        objectIndex < Object.keys(tempObjFreqDesc).length;
        objectIndex++
      ) {
        let tempRate = [];
        let rateCount = {};
        for (
          let rIndex = 0;
          rIndex <
          tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]]["indexes"]
            .length;
          rIndex++
        ) {
          // write the code here
          if (
            tempRate.includes(
              +tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                "rate"
              ][rIndex]
            )
          ) {
            if (
              rateCount[
                tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                  "rate"
                ][rIndex]
              ].hasOwnProperty(
                tempItems[
                  tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                    "indexes"
                  ][rIndex]
                ].unit
              )
            ) {
              rateCount[
                tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                  "rate"
                ][rIndex]
              ][
                [
                  tempItems[
                    tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                      "indexes"
                    ][rIndex]
                  ].unit,
                ]
              ].push(
                tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                  "indexes"
                ][rIndex]
              );
            } else {
              rateCount[
                tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                  "rate"
                ][rIndex]
              ][
                tempItems[
                  tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                    "indexes"
                  ][rIndex]
                ].unit
              ] = [
                tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                  "indexes"
                ][rIndex],
              ];
            }
          } else {
            // write the code here
            tempRate.push(
              tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                "rate"
              ][rIndex]
            );
            rateCount[
              tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                "rate"
              ][rIndex]
            ] = {
              [tempItems[
                tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                  "indexes"
                ][rIndex]
              ].unit]: [
                tempObjFreqDesc[Object.keys(tempObjFreqDesc)[objectIndex]][
                  "indexes"
                ][rIndex],
              ],
            };
          }
        }

        for (
          let rCIndex = 0;
          rCIndex < Object.keys(rateCount).length;
          rCIndex++
        ) {
          for (
            let unitIndex = 0;
            unitIndex <
            Object.keys(rateCount[Object.keys(rateCount)[rCIndex]]).length;
            unitIndex++
          ) {
            if (
              rateCount[Object.keys(rateCount)[rCIndex]][
                Object.keys(rateCount[Object.keys(rateCount)[rCIndex]])[
                  unitIndex
                ]
              ].length > 1
            ) {
              let newQuantity = 0;
              rateCount[Object.keys(rateCount)[rCIndex]][
                Object.keys(rateCount[Object.keys(rateCount)[rCIndex]])[
                  unitIndex
                ]
              ].forEach((element) => {
                newQuantity = newQuantity + +tempItems[element].quantity;
              });

              tempFinalFiltered.push({
                ...tempItems[
                  rateCount[Object.keys(rateCount)[rCIndex]][
                    Object.keys(rateCount[Object.keys(rateCount)[rCIndex]])[
                      unitIndex
                    ]
                  ][0]
                ],
                quantity: newQuantity,
              });
            } else {
              tempFinalFiltered.push(
                tempItems[
                  rateCount[Object.keys(rateCount)[rCIndex]][
                    Object.keys(rateCount[Object.keys(rateCount)[rCIndex]])[
                      unitIndex
                    ]
                  ][0]
                ]
              );
            }
          }
        }
      }

      setNewItems(tempFinalFiltered);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
        gap: 40,
        color: "#353535",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "5%",
          marginTop: "20px",
        }}
      >
        <FiChevronLeft
          size={25}
          style={{ marginLeft: 10, cursor: "pointer" }}
          onClick={() => handleClose()}
        />
        <p
          style={{
            fontWeight: "bold",
            textDecorationLine: "underline",
            fontSize: "28px",
          }}
        >
          {orderType}
        </p>
        <div />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            alignItems: "flex-start",
            gap: 5,
          }}
        >
          {/* <p style={{ fontWeight: 'bold' }} >Client Name: {clientName}</p> */}
          <p style={{ fontWeight: "bold" }}>{firmName}</p>
          <p>{firmAddress}</p>
          <p>Phone Number: {firmPhoneNumber}</p>
          <p>GSTIN: {firmGSTIN}</p>
          <p>Contact Person: {firmContactPersonName}</p>
          <p>Mobile Number: {firmContactPersonNumber}</p>
          <p>Email: {firmContactPersonEmail}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            alignItems: "flex-end",
            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={sessionStorage.getItem("firmLogoURL")}
              style={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                borderStyle: "solid",
                borderWidth: 0.25,
                borderColor: "grey",
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
            <div>
              <p>Order ID</p>
              <p>PO ID</p>
              <p>PO Date</p>
              <p>Start Date</p>
              <p>End Date</p>
            </div>
            <div>
              <p>-</p>
              <p>-</p>
              <p>{`${new Date().getDate()} / ${
                new Date().getMonth() + 1
              } / ${new Date().getFullYear()}`}</p>
              <p>{startDate.toString().substring(4, 16)}</p>
              <p>{endDate.toString().substring(4, 16)}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: 10,
          backgroundColor: "#FCFCFC",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            maxWidth: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-start",
              fontWeight: "bold",
            }}
          >
            To,
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <p style={{ fontWeight: "bold" }}>Vendor Name</p>
              <p>
                {vendorMobile == null ||
                vendorMobile == "null" ||
                vendorMobile == "Open Vendor"
                  ? "-"
                  : vendorMobile.split(":")[0].trim()}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <p style={{ fontWeight: "bold" }}>Vendor Firm</p>
              <p>
                {vendorMobile == null ||
                vendorMobile == "null" ||
                vendorMobile == "Open Vendor"
                  ? "-"
                  : vendorFirm}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <p style={{ fontWeight: "bold" }}>Mobile Number</p>
              <p>
                {vendorMobile == null ||
                vendorMobile == "null" ||
                vendorMobile == "Open Vendor"
                  ? "-"
                  : vendorMobile && vendorMobile.split(":")[1].trim()}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <p style={{ fontWeight: "bold" }}>Address</p>
              <p>
                {vendorMobile == null ||
                vendorMobile == "null" ||
                vendorMobile == "Open Vendor"
                  ? "-"
                  : vendorAddress}{" "}
                {vendorPinCode} {vendorCity} {vendorState}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <p style={{ fontWeight: "bold" }}>GSTIN</p>
              <p>
                {vendorMobile == null ||
                vendorMobile == "null" ||
                vendorMobile == "Open Vendor"
                  ? "-"
                  : vendorGSTIN}
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "flex-start",
            gap: 20,
            maxWidth: "50%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <p style={{ fontWeight: "bold" }}>Site Engineer</p>
              <p>{siteEngineerName}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 5,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontWeight: "bold" }}>Mobile Number</p>
                <p style={{ fontSize: 15 }}>(Escalation 1)</p>
              </div>
              <p>{siteEngineerNumber}</p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <p style={{ fontWeight: "bold" }}>Escalation 2</p>
              <p>{contactPersonName}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontWeight: "bold" }}>Mobile Number</p>
                {/* <p style={{ fontSize: 15 }}>(Escalation 2)</p> */}
              </div>
              <p>{contactPersonNumber}</p>
            </div>
          </div>
        </div>
      </div>

      <table id="table2" style={{ fontSize: 12 }}>
        <tbody>
          <tr>
            <th style={{ backgroundColor: "#fff6db" }}>Category</th>
            <th style={{ backgroundColor: "#fff6db" }}>Description</th>
            <th style={{ backgroundColor: "#fff6db" }}>Image</th>
            <th style={{ backgroundColor: "#fff6db" }}>Quantity</th>
            <th style={{ backgroundColor: "#fff6db" }}>Unit</th>
            {orderType == "Work Quotation" ||
            orderType == "Material Quotation" ? null : (
              <th style={{ backgroundColor: "#fff6db" }}>Rate</th>
            )}
            <th style={{ backgroundColor: "#fff6db" }}>GST</th>
            {orderType == "Work Quotation" ||
            orderType == "Material Quotation" ? null : (
              <th style={{ backgroundColor: "#fff6db" }}>Amount</th>
            )}
          </tr>

          {newItems &&
            newItems.map((item, index) => {
              if (index == milestoneTobeAddedIndex[orderNo]) {
                orderNo = orderNo + 1;
              } else {
                commonMilestonesIndex = index;

                return (
                  <tr
                    style={{ verticalAlign: "top", textAlign: "center" }}
                    key={index}
                  >
                    {"type" in item ||
                    ("workType" in item && item.workType == "Only Material") ? (
                      <td>{item.type || item.category}</td>
                    ) : (
                      <td>{item.category || item.vendorCategory}</td>
                    )}

                    {"type" in item ? (
                      <td style={{
                        wordWrap: "break-word", // Ensures text wraps to the next line
                        overflowWrap: "break-word", // Handles text wrapping for modern browsers
                        maxWidth: "500px", // Limits the width of the content
                        whiteSpace: "pre-wrap", // Preserves newlines and wraps text
                        overflow: "hidden", // Prevents content from overflowing
                        display: "inline-block", // Keeps layout consistent
                      }} className="desc-highlight-brand">
                        {item?.specification
                          ?.replace(/<new-line>/g, "\n")
                          .replace(/<single-quote>/g, "'")
                          .replace(/<double-quote>/g, '"')}

                        {item?.milestones?.map((mItem, mIndex) => (
                          <div style={{ display: "none" }} key={mIndex}>
                            {
                              (commonMilestoneAmount =
                                commonMilestoneAmount +
                                Number(
                                  (item.quantity * item.rate +
                                    item.quantity *
                                      item.rate *
                                      (item.gst / 100)) *
                                    (mItem.percentage / 100)
                                ))
                            }
                          </div>
                        ))}
                      </td>
                    ) : (
                      <td style={{
                        wordWrap: "break-word", // Ensures text wraps to the next line
                        overflowWrap: "break-word", // Handles text wrapping for modern browsers
                        maxWidth: "500px", // Limits the width of the content
                        whiteSpace: "pre-wrap", // Preserves newlines and wraps text
                        overflow: "hidden", // Prevents content from overflowing
                        display: "inline-block", // Keeps layout consistent
                      }} className="desc-highlight-brand">
                        {item?.milestones?.map((mItem, mIndex) => (
                          <div
                            key={mIndex}
                            style={{ display: "none" }}
                            className="desc-highlight-brand"
                          >
                            {
                              (commonMilestoneAmount =
                                commonMilestoneAmount +
                                Number(
                                  (item.quantity * item.rate +
                                    item.quantity *
                                      item.rate *
                                      (item.gst / 100)) *
                                    (mItem.percentage / 100)
                                ))
                            }
                          </div>
                        ))}
                        {item.description
                          ?.replace(/<new-line>/g, "\n")
                          .replace(/<single-quote>/g, "'")
                          .replace(/<double-quote>/g, '"')}
                      </td>
                    )}

                    <td>
                      {imageULS[item.OrderId] == null ||
                      imageULS[item.OrderId]?.length === 0 ||
                      Object.keys(imageULS[item.OrderId]).length === 0 ? (
                        ImagesObjectFetchedFromFirebase &&
                        ImagesObjectFetchedFromFirebase[item?.OrderId] !==
                          undefined ? (
                          <img
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                            src={
                              ImagesObjectFetchedFromFirebase[item.OrderId].url
                            }
                            alt="Image Not uploaded"
                          />
                        ) : (
                          <p>-</p>
                        )
                      ) : (
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "contain",
                          }}
                          src={
                            imageULS[item.OrderId].url ||
                            URL.createObjectURL(imageULS[item.OrderId][0]) ||
                            ImagesObjectFetchedFromFirebase[item.OrderId].url
                          }
                          alt="Image Not uploaded"
                        />
                      )}
                    </td>

                    <td>{item.quantity}</td>
                    <td>{item.unit}</td>
                    {orderType == "Work Quotation" ||
                    orderType == "Material Quotation" ? null : (
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {"\u20B9 "}
                          {item.rate}
                        </div>
                      </td>
                    )}
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {item.gst} %
                      </div>
                    </td>
                    {orderType == "Work Quotation" ||
                    orderType == "Material Quotation" ? null : (
                      <td>
                        {"\u20B9 "}{" "}
                        {(
                          item["quantity"] * item["rate"] +
                          item["quantity"] * item["rate"] * (item["gst"] / 100)
                        ).toFixed(2)}
                      </td>
                    )}
                  </tr>
                );
              }
            })}
        </tbody>
      </table>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            backgroundColor: "#fff6db",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "5%",
              maxWidth: "5%",
              minWidth: "5%",
            }}
          ></div>

          <div
            style={{
              display: "flex",
              width: "40%",
              maxWidth: "40%",
              minWidth: "40%",
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            Common Milestones Payment
          </div>

          <div
            style={{
              display: "flex",
              width: "10%",
              maxWidth: "10%",
              minWidth: "10%",
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            %
          </div>

          <div
            style={{
              display: "flex",
              width: "10%",
              maxWidth: "10%",
              minWidth: "10%",
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            Amount
          </div>
        </div>
        {defaultMilestones.map((mItem, mIndex) => (
          <div
            key={mIndex}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "5%",
                maxWidth: "5%",
                minWidth: "5%",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "40%",
                maxWidth: "40%",
                minWidth: "40%",
                fontSize: 12,
              }}
            >
              {mItem.name}
            </div>
            <div
              style={{
                display: "flex",
                width: "10%",
                maxWidth: "10%",
                minWidth: "10%",
                fontSize: 12,
              }}
            >
              {mItem.percentage} %
            </div>
            <div
              style={{
                display: "flex",
                width: "10%",
                maxWidthL: "10%",
                minWidth: "10%",
                fontSize: 12,
              }}
            >
              {"\u20B9 "}
              {mItem.totalAmount}
            </div>
          </div>
        ))}
      </div>

      <table id="table" style={{ fontSize: 12 }}>
        <tbody>
          {milestoneTobeAddedIndex.length == 0 ? null : (
            <tr>
              <th style={{ backgroundColor: "#fff6db" }}>Category</th>
              <th style={{ backgroundColor: "#fff6db" }}>Description</th>
              <th style={{ backgroundColor: "#fff6db" }}>Image</th>
              <th style={{ backgroundColor: "#fff6db" }}>Quantity</th>
              <th style={{ backgroundColor: "#fff6db" }}>Unit</th>
              {orderType == "Work Quotation" ||
              orderType == "Material Quotation" ? null : (
                <th style={{ backgroundColor: "#fff6db" }}>Rate</th>
              )}
              <th style={{ backgroundColor: "#fff6db" }}>GST</th>
              {orderType == "Work Quotation" ||
              orderType == "Material Quotation" ? null : (
                <th style={{ backgroundColor: "#fff6db" }}>Amount</th>
              )}
            </tr>
          )}
          {<div style={{ display: "none" }}>{(orderNo = 0)}</div>}

          {newItems &&
            newItems.map((item, index) => {
              if (index == milestoneTobeAddedIndex[orderNo]) {
                orderNo = orderNo + 1;
                return (
                  <tr
                    style={{ verticalAlign: "top", textAlign: "center" }}
                    key={index}
                  >
                    {"type" in item ||
                    ("workType" in item && item.workType == "Only Material") ? (
                      <td>{item.type || item.category}</td>
                    ) : (
                      <td>{item.category || item.vendorCategory}</td>
                    )}

                    {"type" in item ? (
                      <td style={{
                        wordWrap: "break-word", // Ensures text wraps to the next line
                        overflowWrap: "break-word", // Handles text wrapping for modern browsers
                        maxWidth: "500px", // Limits the width of the content
                        whiteSpace: "pre-wrap", // Preserves newlines and wraps text
                        overflow: "hidden", // Prevents content from overflowing
                        display: "inline-block", // Keeps layout consistent
                      }} className="desc-highlight-brand">
                        {item.specification
                          ?.replace(/<new-line>/g, "\n")
                          .replace(/<single-quote>/g, "'")
                          .replace(/<double-quote>/g, '"')}
                        <br />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 30,
                            gap: 10,
                            padding: 5,
                            wordWrap: "break-word",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "bold",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                textAlign: "left",
                                // , backgroundColor: '#fff6db', padding: 5
                              }}
                            >
                              Milestones
                            </div>
                            <div
                              style={{
                                width: "15%",
                                // , backgroundColor: '#fff6db', padding: 5
                              }}
                            >
                              %
                            </div>
                            <div
                              style={{
                                width: "25%",
                                // , backgroundColor: '#fff6db', padding: 5
                              }}
                            >
                              {"\u20B9"}
                            </div>
                          </div>
                          {item.milestones.map((mItem, mIndex) => (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                              key={mIndex}
                            >
                              <div style={{ width: "60%", textAlign: "left" }}>
                                {mItem.name}
                              </div>
                              <div style={{ width: "15%" }}>
                                {mItem.percentage} %
                              </div>
                              <div style={{ width: "25%" }}>
                                {"\u20B9 "}
                                {(
                                  (item.quantity * item.rate +
                                    item.quantity *
                                      item.rate *
                                      (item.gst / 100)) *
                                    (mItem.percentage / 100) -
                                  counter
                                ).toFixed(2)}
                                <div style={{ display: "none" }}>
                                  {
                                    (counter =
                                      mIndex == item.milestones.length - 1
                                        ? 0
                                        : (item.quantity * item.rate +
                                            item.quantity *
                                              item.rate *
                                              (item.gst / 100)) *
                                          (mItem.percentage / 100))
                                  }
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                    ) : (
                      <td style={{
                        wordWrap: "break-word", // Ensures text wraps to the next line
                        overflowWrap: "break-word", // Handles text wrapping for modern browsers
                        maxWidth: "500px", // Limits the width of the content
                        whiteSpace: "pre-wrap", // Preserves newlines and wraps text
                        overflow: "hidden", // Prevents content from overflowing
                        display: "inline-block", // Keeps layout consistent
                      }} className="desc-highlight-brand">
                        {item.description
                          ?.replace(/<new-line>/g, "\n")
                          .replace(/<single-quote>/g, "'")
                          .replace(/<double-quote>/g, '"')}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 30,
                            gap: 10,
                            padding: 5,
                            wordWrap: "break-word",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "bold",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                textAlign: "left",
                                // , backgroundColor: '#fff6db', padding: 5
                              }}
                            >
                              Milestones
                            </div>
                            <div
                              style={{
                                width: "15%",
                                // , backgroundColor: '#fff6db', padding: 5
                              }}
                            >
                              %
                            </div>
                            <div
                              style={{
                                width: "25%",
                                // , backgroundColor: '#fff6db', padding: 5
                              }}
                            >
                              {"\u20B9"}
                            </div>
                          </div>
                          {item.milestones.map((mItem, mIndex) => (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                              key={mIndex}
                            >
                              <div style={{ width: "60%", textAlign: "left" }}>
                                {mItem.name}
                              </div>
                              <div style={{ width: "15%" }}>
                                {mItem.percentage} %
                              </div>
                              <div style={{ width: "25%" }}>
                                {"\u20B9 "}
                                {(
                                  (item.quantity * item.rate +
                                    item.quantity *
                                      item.rate *
                                      (item.gst / 100)) *
                                  (mItem.percentage / 100)
                                ).toFixed(2)}
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                    )}

                    <td>
                      {imageULS[index] == null ? (
                        <p>-</p>
                      ) : (
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "contain",
                          }}
                          src={URL.createObjectURL(imageULS[index][0])}
                          alt="Image Not uploaded"
                        />
                      )}
                    </td>

                    <td>{item.quantity}</td>
                    <td>{item.unit}</td>
                    {orderType == "Work Quotation" ||
                    orderType == "Material Quotation" ? null : (
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {"\u20B9 "}
                          {item.rate}
                        </div>
                      </td>
                    )}
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {item.gst} %
                      </div>
                    </td>
                    {orderType == "Work Quotation" ||
                    orderType == "Material Quotation" ? null : (
                      <td>
                        {"\u20B9 "}{" "}
                        {(
                          item["quantity"] * item["rate"] +
                          item["quantity"] * item["rate"] * (item["gst"] / 100)
                        ).toFixed(2)}
                      </td>
                    )}
                  </tr>
                );
              } else {
              }
            })}
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: 10,
          gap: 40,
          backgroundColor: "#FCFCFC",
        }}
      >
        {discount != 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p>Basic Value</p>
              <p>
                {"\u20B9 "}
                {newItems &&
                  newItems.reduce(
                    (total, item) =>
                      Number(total) +
                      Number(
                        (
                          Number(item["quantity"] * item["rate"]) +
                          Number(
                            item["quantity"] *
                              item["rate"] *
                              (item["gst"] / 100)
                          )
                        ).toFixed(2)
                      ),
                    0
                  )}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p>Discount</p>
              <p>{discount} %</p>
            </div>
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p>Total Value including Taxes and Duties</p>
          <p>
            {"\u20B9 "}
            {totalAmount}
          </p>
        </div>
      </div>

      {termsAndConditions?.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: 10,
            gap: 40,
            backgroundColor: "#FCFCFC",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Terms & Conditions
          </p>
          <p>
            {termsAndConditions
              ?.replace(/<single-quote>/g, "'")
              .replace(/<double-quote>/g, '"')
              .split("<new-line>")
              .map((text, index) => (
                <div key={index}>
                  {text}
                  <br />
                </div>
              ))}
          </p>
        </div>
      ) : (
        ""
      )}

      <table id="table" style={{ textAlign: "center" }}>
        <tbody>
          <tr>
            {/* <th>PRICE BASIS</th> */}
            <th colSpan="2">FREIGHT</th>
            <th colSpan="2">INSURANCE</th>
          </tr>
          <tr>
            {/* <td>{priceBase}</td> */}
            {/* {freight.value && freight.description && <td>{freight?.value?.replace(/<single-quote>/g, "'")
              .replace(/<double-quote>/g, '"')
              .split("<new-line>") + ' ' +  freight?.description?.replace(/<single-quote>/g, "'")
              .replace(/<double-quote>/g, '"')
              .split("<new-line>")}</td>} */}
            {freight.value && !freight.description && (
              <td colSpan="2">
                {freight.value
                  .replace(/<single-quote>/g, "'")
                  .replace(/<double-quote>/g, '"')
                  .split("<new-line>")}
              </td>
            )}

            {!freight.value && freight.description && (
              <td colSpan="2">
                {freight.description
                  .replace(/<single-quote>/g, "'")
                  .replace(/<double-quote>/g, '"')
                  .split("<new-line>")}
              </td>
            )}

            {freight.value && freight.description && (
              <td colSpan="2">
                {freight.value
                  .replace(/<single-quote>/g, "'")
                  .replace(/<double-quote>/g, '"')
                  .split("<new-line>")}
                {" " +
                  freight.description
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"')
                    .split("<new-line>")}
              </td>
            )}

            {!freight.value && !freight.description && (
              <td colSpan="2">
                {'No freight mentioned'}
              </td>
            )}
            {
              console.log('insurance from preview : ', insurance, freight)
            }
            <td colSpan="2">
              {insurance
                .replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .split("<new-line>")}
            </td>
          </tr>
          <tr>
            <th>SHIP TO ADDRESS</th>
            <th>BILL TO ADDRESS</th>
            <th>PAYMENT TERMS</th>
          </tr>
          <tr>
            <td>
              {shippingAddress
                ?.replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .split("<new-line>")
                .map((text, index) => (
                  <div key={index}>
                    {text}
                    <br />
                  </div>
                ))}
            </td>
            <td>
              {billingAddress
                ?.replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .split("<new-line>")
                .map((text, index) => (
                  <div key={index}>
                    {text}
                    <br />
                  </div>
                ))}
            </td>
            <td>
              {paymentTerms
                ?.replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .split("<new-line>")
                .map((text, index) => (
                  <div key={index}>
                    {text}
                    <br />
                  </div>
                ))}
            </td>
          </tr>
        </tbody>
      </table>

      {specialInstructions.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: 10,
            gap: 40,
            backgroundColor: "#FCFCFC",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Special Instructions
          </p>
          <p>
            {specialInstructions
              ?.replace(/<single-quote>/g, "'")
              .replace(/<double-quote>/g, '"')
              .split("<new-line>")
              .map((text, index) => (
                <div key={index}>
                  {text}
                  <br />
                </div>
              ))}
          </p>
        </div>
      ) : (
        ""
      )}

      {attachments?.length > 0 ? (
        <div>
          <p style={{ fontWeight: "bold" }}>Attachments</p>
          {attachments?.map((item, index) => (
            <div style={{ marginTop: 10 }} key={index}>
              <a href={item.url} target="_blank" key={index}>
                {item.name}
              </a>
              <br />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div>Vendor Signatory</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {firmSignature == "" ? (
            <p>
              No signature found! Save your firm signature in suggestive items.
            </p>
          ) : (
            <>
              <img
                src={firmSignature.url}
                style={{ width: 100, height: 100 }}
              />{" "}
              Authorized Signatory
            </>
          )}
        </div>
      </div>

      <Button
        content={
          isApproval ? `Send ${orderType} for Approval` : `Release ${orderType}`
        }
        onClick={() => {
          setIsUpdatingProgress(true);
          handleClose();
          releaseOpportunityQuotation();
        }}
      />
    </div>
  );
};

export default Preview;
