import React from "react";
import Legend from "./Legend";

const Header = ({ items }) => {
  const OldunsavedCount = items.filter((item) => item.unsaved).length;
  const OldsavedCount = items.length - OldunsavedCount;

  const unsavedCount = items.filter((item) => item.unsaved && !item.hasOwnProperty("inTime")).length;
  // const savedCount = items.filter((item) => item.hasOwnProperty("inTime") || (!item.unsaved && !item.hasOwnProperty("inTime"))).length;
  const savedCount = items.filter((item) => !item.hasOwnProperty("inTime")).filter((item) => !item.unsaved ).length;

  const styles = {
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "2px solid #d0d0d0",
      marginBottom: "15px",
      paddingBottom: "10px",
      alignItems: "center",
    },
    heading: {
      fontSize: "24px",
      fontWeight: "600",
      color: "#333333", // Updated text color
    },
    itemCount: {
      fontSize: "20px",
      color: "#4CAF50", // Updated text color for item count
    },
  };

  return (
    <div style={styles.headerContainer}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <h2 style={styles.heading}>
            Overview:{" "}
            <span style={styles.itemCount}>
              {items.length === 1 ? "One Item" : `${items.filter(item => !item.hasOwnProperty("inTime")).length} Items`}
            </span>
          </h2>
          <span style={{ fontSize: "14px", color: "#3366CC" }}>
            Add Items and Save Items to add entries
          </span>
        </div>
      </div>
      <Legend saved={savedCount} unsaved={unsavedCount} />
    </div>
  );
};

export default Header;
