import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AuthService from "../../../api/authService";
import config from "../../../config/config";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { UserContext } from "../../../Context/UserContext";
import SmsService from "../../../api/smsService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
}));

const Form = ({ handleClose, onAuthChanged, setMaterialCategories }) => {
  const classes = useStyles();
  const [name, setName] = useState();

  const AddMaterialCategories = (name) => {
    const authService = new AuthService();
    authService
      .addMaterialCategories(name)
      .then((res) => {
        if (res.status == 200) {
          alert("Category Added Successfully");
          setMaterialCategories((prevSt) => {
            return [
              ...prevSt,
              {
                id: prevSt.length + 1,
                Brands: null,
                name,
              },
            ];
          });
        } else {
          alert("category Already Exist");
        }
      })
      .catch((error) => {
        alert("somthing went wrong");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        label="category"
        variant="filled"
        required
        onChange={(e) => setName(e.currentTarget.value)}
        value={name}
      />
      <div>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            if (!name) {
              alert("Please Provide category ");
              return;
            }
            AddMaterialCategories(name);
          }}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default Form;
