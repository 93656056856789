import { secondDatabase } from "../../../config/firebase";
import extractInwardEntryData from "./Helpers/extractInwardObject";
import modifyInwardNetInventory from "./modifyInwardNetInventory";
import saveInventoryData from "./updateNetInventory";

const editInwardEntry = async (firmId, projectId, entryId, updatedData, userName) => {
  console.log('editInwardEntry entryId : ', entryId)
  // Firebase reference path for the inward entry
  const basePath = `${firmId}/projects/${projectId}/records/allInward/`;
  const entryRef = secondDatabase.ref(`${basePath}${entryId}`);
  
  // Fetch the existing inward entry to get current data
  const entrySnapshot = await entryRef.once("value");
  const existingEntry = entrySnapshot.val();

  let existingItems = await extractInwardEntryData(existingEntry);
  console.log('existingItems : ', existingItems.itemsArray);
  let updatedItems = await extractInwardEntryData(updatedData);
  console.log('updatedItems : ', updatedItems.itemsArray);

  console.log('editInwardEntry =>> userName : ', userName);

  modifyInwardNetInventory(firmId, projectId, existingItems.itemsArray, updatedItems.itemsArray, entryId, userName);

  await entryRef.update(updatedData)
    .then(() => console.log("Inward entry updated successfully."))
    .catch((error) => console.error("Error updating inward entry:", error));
};

export default editInwardEntry;
