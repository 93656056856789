import firebase from "../../config/firebase";
import React, { useState, lazy, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import drawerTemplate1 from "../../hoc/drawerTemplate1";
import Ongoing from "./Ongoing";
import Completed from "./Completed";
import Client from "./Client";
import { useAnalyticsContext } from "../../Context/Analytics";
import getComponentsPrice from "../../helpers/getComponentsPrice";
// const Ongoing = React.lazy(() => import("./Ongoing"));

const Finance = () => {
  const location = useLocation();

  const { record } = useAnalyticsContext();

  const data = location.state?.selectedProject;
  const [activeTab, setActiveTab] = useState(0);
  const [projectDetails, setProjectDetails] = useState();

  useEffect(() => {
    let projectId = location.pathname.split("/")[2];
    firebase
      .database()
      .ref("liveBOQ/" + projectId.toString())
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          const response = {
            ...snapshot.val(),
            rooms: JSON.parse(snapshot.val().rooms),
            Rooms: JSON.parse(snapshot.val().rooms),
          }; // has to replace with rooms or Rooms
          console.log("response :", snapshot.val());
          setProjectDetails(response);
        }
      });
  }, []);

  const [invoices, setInvoices] = useState([]);

  // also used in the client .js to fetch the invoices
  const getInvoicesDetails = async () => {
    var useRef = firebase
      .database()
      .ref(`clientDetails/${data.ProjectId}/invoiceDetails/`);
    // var useRef = firebase.database().ref(`clientDetails/${data.ProjectId}/invoiceDetails/`);

    useRef.on("value", function (snapshot) {
      var data = snapshot.val();
      if (data !== null) {
        setInvoices(Object.values(data));
      }
    });
  };

  const [target, setTarget] = useState(null);
  const [collected, setCollected] = useState(null);

  useEffect(() => {
    setCollected(() =>
      invoices.reduce(
        (sum, invoice) =>
          sum + (invoice.status == "Paid" ? invoice.amount : 0 || 0),
        0
      )
    );
    setTarget(() => Number(getComponentsPrice(data).toFixed(0)));
  }, [invoices]);

  useEffect(() => {
    getInvoicesDetails();
  }, []);

  const [role, setRole] = useState(null);

  useEffect(() => {
    // Fetch data from localStorage
    const storedData = localStorage.getItem("User");

    if (storedData) {
      // Parse JSON if the data is stored as a JSON string
      setRole(() => JSON.parse(storedData).accountType);
    }
  }, []);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {/* <div style={{ margin: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px', }}> */}
      <div
        style={{
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "20px",
          position: "sticky",
          top: 0,
          background: "white",
        }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            gap: 20,
            cursor: "pointer",
          }}>
          <div style={{ width: "15%" }}>
            {projectDetails && (
              <p
                style={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  cursor: "default",
                }}>
                {projectDetails.clientName
                  ? projectDetails.clientName
                  : projectDetails.ClientName}
              </p>
            )}{" "}
          </div>
          <div
            style={{
              width: "85%",
              display: "flex",
              flexDirection: "row",
              gap: 20,
            }}>
            <div
              onClick={() => {
                setActiveTab(0);
                record("@ongoing_tab_click_success", {
                  description: "ongoing tab click success",
                });
              }}
              className={activeTab === 0 ? "activeText" : ""}>
              <p>Ongoing</p>
              <div className={activeTab === 0 ? "activeTab" : ""}></div>
            </div>

            <div
              onClick={() => {
                setActiveTab(1);
                record("@completed_tab_click_success", {
                  description: "completed tab click success",
                });
              }}
              className={activeTab === 1 ? "activeText" : ""}>
              <p>Completed</p>
              <div className={activeTab === 1 ? "activeTab" : ""}></div>
            </div>

            {role === "Admin" || role === "Owner" || role === "Finance" ? (
              <div
                onClick={() => {
                  setActiveTab(2);
                  record("@completed_tab_click_success", {
                    description: "completed tab click success",
                  });
                }}
                className={activeTab === 2 ? "activeText" : ""}>
                <p>Client</p>
                <div className={activeTab === 2 ? "activeTab" : ""}></div>
              </div>
            ) : null}

            {activeTab !== 0 && activeTab !== 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "10px",
                  marginLeft: 900,
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                  }}>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "28px",
                      fontWeight: "bold",
                      color: "#4CAF50",
                    }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      maximumFractionDigits: 0,
                    }).format(collected)}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "28px",
                      fontWeight: "bold",
                      color: "#FF6B6B", // FF6B6B
                    }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      maximumFractionDigits: 0,
                    }).format(target)}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%", // This makes it equal to the width of the numbers above
                    height: "10px",
                    backgroundColor: "#f8bdbd",
                    borderRadius: "5px",
                    overflow: "hidden",
                  }}>
                  <div
                    style={{
                      width: `${Math.min((collected / target) * 100, 100)}%`,
                      height: "100%",
                      backgroundColor: "#4CAF50",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "12px 20px",
                    backgroundColor: "#F5F5F5", // Light gray background
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Arial, sans-serif", // Formal font
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#333", // Dark gray text for readability
                  }}>
                  Pending Collection:
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#FF5722", // Orange color to highlight pending amount
                      marginLeft: "4px",
                      fontSize: 18,
                    }}>
                    {" "}
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      maximumFractionDigits: 0,
                    }).format(target - collected)}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {(projectDetails || data) &&
        (activeTab == 0 ? (
          // <Suspense fallback={<div>Loading...</div>}>
          <Ongoing selectedProject={projectDetails} />
        ) : activeTab == 1 ? (
          // </Suspense>
          <Completed />
        ) : (
          <Client />
        ))}
    </div>
  );
};

export default drawerTemplate1(Finance);
