import { secondDatabase } from "../../../config/firebase";

const saveInventoryData = async (firmId, projectId, item, formData, entryId, userName) => {

    console.log('saveInventoryData item : ', item)

  const category = item.category;
  const descriptionId = item.descriptionId;
  const basePath = `${firmId}/${projectId}/allInventory/`;

  const historyId = item.historyId
  const historyData = {
    [entryId]: {
      invoiceNumber: formData.invoiceNumber || "Not provided",
      name: userName || "Not provided",
      poNumber: formData.poNumber || "Not provided",
      quantity:  '+' + String(parseFloat(item.quantityAccepted || 0)),
      status: "Inward",
      timeStamp: new Date().getTime(),
      entryId: entryId,
      itemEntryId: item.id,
      historyId: entryId,
      itemID: item.descriptionId,
      purpose: item.purpose
    },
  };

  // Check if category exists
  const categoryRef = secondDatabase.ref(`${basePath}${category}`);
  const categorySnapshot = await categoryRef.once("value");
  console.log('categorySnapshot : ', categorySnapshot);
  if (!categorySnapshot.exists()) {
    await categoryRef.set({});
  }else{
    console.log('catgory doesen"t exists we need to do somthing...');
  }

  // Check if description exists
  const descriptionRef = secondDatabase.ref(`${basePath}${category}/${descriptionId}`);
  const descriptionSnapshot = await descriptionRef.once("value");

  if (!descriptionSnapshot.exists()) {
    // Create new description node
    await descriptionRef.set({
      availableQuantity: parseFloat(item.quantityAccepted || 0),
      description: item.description || "No description",
      uom: item.unit || "Units",
      history: historyData,
    });
  } else {
    // Update existing description node
    const existingData = descriptionSnapshot.val();
    await descriptionRef.update({
      availableQuantity: parseFloat(existingData.availableQuantity || 0) + parseFloat(item.quantityAccepted || 0),
      history: { ...existingData.history, ...historyData },
    });
  }

  console.log("Inventory data saved successfully.");
};

export default saveInventoryData;
