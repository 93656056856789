import { secondDatabase } from "../../../config/firebase";

const fetchData = async (firmId, projectId, projectName) => {
  // Return a Promise to handle async behavior correctly
  return new Promise((resolve, reject) => {
    const useRef = secondDatabase.ref(
      `${firmId}/projects/${projectId}/records/allOutward/`
    );

    // Once the data is fetched from Firebase, handle the response
    useRef.once(
      "value",
      async (snapshot) => {
        let data = snapshot.val();

        console.log("data : ", data);

        if (data !== null) {
          try {
            // Wait for the inventory conversion to complete
            let returnData = Object.values(data);
            console.log("returnData : ", returnData);
            const updatedData = returnData.map((item) => ({
              ...item, // Spread the existing properties
              projectName: projectName, // Add the new key-value pair
            }));
            // return returnData;
            resolve(updatedData); // Resolve the Promise with the processed data
          } catch (error) {
            reject(error); // Reject the Promise if an error occurs during conversion
          }
        } else {
          resolve([]); // Resolve with an empty array if no data is found
        }
      },
      (error) => {
        reject(error); // Reject the Promise if Firebase request fails
      }
    );
  });
};

const generateOutwardData = async (projects) => {
  // write the code here...
  if (!Array.isArray(projects) && projects !== null) {
    //   if (typeof projects === "object" && projects !== null) {
    
        try {
          // Wait for the fetchData promise to resolve and return the result
          const result = await fetchData(
            projects.firmDetailsID,
            projects.projectID,
            projects.projectName
          );
          // Return the fetched data
          return result.reverse();
        } catch (error) {
          return []; // Return an empty array if an error occurs
        }
      } else {
        // Return a default value or handle the case where 'projects' is not an object
        let allProjectData = [];
        for (let index = 0; index < projects.length; index++) {
            const project = projects[index];
            try {
                // Wait for the fetchData promise to resolve and return the result
                const result = await fetchData(
                  project.firmDetailsID,
                  project.projectID,
                  project.projectName
                );
                // Return the fetched data
                allProjectData = [...allProjectData, ...result];
              } catch (error) {
              }
        }
        return allProjectData.reverse();
      }
}





export default generateOutwardData;




// const generateRandomHistory = () => {
//   const historyCount = Math.floor(Math.random() * 9) + 1; // Generate a random number of history entries (1-9)
//   const history = {};

//   for (let i = 1; i <= historyCount; i++) {
//     history[i] = {
//       timestamp: Date.now() - Math.floor(Math.random() * 1000000000), // Random timestamp
//       type: `Vehicle ${String.fromCharCode(65 + Math.floor(Math.random() * 26))}${Math.floor(Math.random() * 100)}`, // Random vehicle name (e.g., Vehicle A1, Vehicle B10)
//       quantity: Math.floor(Math.random() * 100) + 1, // Random quantity (between 1 and 100)
//       purpose: `Purpose ${Math.floor(Math.random() * 5) + 1}`, // Random purpose (Purpose 1, Purpose 2)
//     };
//   }
//   return history;
// };

// const generateOutwardData = (numEntries = 5, maxItemsPerEntry = 5) => {
//   const outwardData = [];
  
//   for (let i = 0; i < numEntries; i++) {
//     const numItems = Math.floor(Math.random() * maxItemsPerEntry) + 1; // Random number of outward items (1 to maxItemsPerEntry)
//     const outwardItems = [];

//     for (let j = 0; j < numItems; j++) {

//       const category = ["Fuel", "Tools", "Electronics", "Hardware"][Math.floor(Math.random() * 4)];
//       const description = `Item ${i + 1}-${j + 1}`;
//       const quantityIssued = Math.floor(Math.random() * 100) + 1;
//       const uom = category === "Fuel" ? "liters" : "pcs"; // For simplicity, using liters for Fuel items, pcs for others

//       outwardItems.push({
//         category,
//         description,
//         quantityIssued,
//         uom,
//         issueHistory: generateRandomHistory(), // Generate random issue history for this item
//       });
//     }

//     outwardData.push({
//       outwardItems, // Add multiple outward items to each entry
//     });
//   }

//   return outwardData;
// };
