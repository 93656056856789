import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AuthService from "../../../api/authService";
import { useHistory } from "react-router-dom";
import { useAnalyticsContext, TAGS } from "../../../Context/Analytics";
import Select from "@material-ui/core/Select";
import { UserContext } from "../../../Context/UserContext";
import { Password } from "antd/lib/input/Password";
import Link from "@material-ui/core/Link";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import config from "../../../config/config";
import MenuItem from "@material-ui/core/MenuItem";
import SmsService from "../../../api/smsService";
import Dialog from "@material-ui/core/Dialog";
import done1 from "../../../assets/img/done1.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
}));

const Form = ({
  handleClose,
  setSiteEngineers,
  onAuthChanged,
  siteEngineers,
}) => {
  const { record } = useAnalyticsContext();
  const history = useHistory();

  const classes = useStyles();
  // create state variables for each input
  const userContext = useContext(UserContext);

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firm, setFirm] = useState(userContext.user.Firm);
  const [id, setId] = useState("");
  const [tqAlert, setTqAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(firstName, lastName, email, password);
    handleClose();
  };

  const emailValidate = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  useEffect(() => {
    emailValidate();
  }, []);

  const onSubmit = async (message) => {
    const authService = new AuthService();
    if (
      email.trim().length > 0 &&
      password.trim().length > 0 &&
      name.trim().length > 0 &&
      mobile.trim().length > 0 &&
      id.trim().length > 0
    ) {
      try {
        const response = await authService.siteEngineerRegister(
          id,
          name,
          password,
          mobile,
          email,
          firm
        );
        if (response.status == 200) {
          // alert("SiteEngineer Registerd Successfully")
          siteEngineers(true);
          await new SmsService().sendSms(message, `+91${mobile}`);
        } else {
          alert("duplicate Id");
        }
        // setSiteEngineers(prev => [...prev, {
        //     Id: id,
        //     Name: name,
        //     Password: password,
        //     Mobile: `+91${mobile}`,
        //     Email: email,
        //     Firm: userContext.user.firm,

        // }])
      } catch (error) {
        alert(error);
      }
    } else {
      alert("Please Fill All Details");
    }
  };
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <form className={classes.root} onSubmit={handleSubmit}>
        <TextField
          label="ID"
          variant="filled"
          type="id"
          required
          value={id}
          onChange={(e) => setId(e.currentTarget.value)}
        />
        <TextField
          label="Name"
          variant="filled"
          required
          onChange={(e) => setName(e.currentTarget.value)}
          value={name}
        />

        <TextField
          label="Password"
          variant="filled"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "88%",
          }}
        >
          <p>+91</p>
          <TextField
            label="Mobile"
            inputProps={{ maxLength: 10 }}
            variant="filled"
            required
            value={mobile.replace(/(?!-)[^0-9]/g, "")}
            onChange={(e) => setMobile(e.currentTarget.value)}
          />
        </div>

        <TextField
          label="Email"
          variant="filled"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />

        <TextField
          label="Firm"
          variant="filled"
          required
          value={firm}
          disabled
        />
        <div>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button type="submit" variant="contained" color="primary" onClick={() => onSubmit("Thanks For Registering")}>
                    Submit
                </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setTqAlert(true);
            }}
          >
            Submit
          </Button>
        </div>
      </form>
      <Dialog open={tqAlert} onClose={() => setTqAlert(false)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 30px 10px 30px",
          }}
        >
          <div style={{ padding: "10px" }}>
            <img src={done1} alt="done image" style={{ width: "280px" }} />
          </div>
          <div style={{ padding: "5px 0px" }}>
            <p style={{ color: "black", fontSize: "24px", fontWeight: "600" }}>
              Thank You!
            </p>
          </div>

          <div style={{ padding: "5px 0px" }}>
            <p
              style={{ color: "#484848", fontSize: "14px", fontWeight: "500" }}
            >
              Your Site Engineer has been registered Successfully.
            </p>
          </div>

          <div style={{ padding: "15px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onSubmit("Thanks For Registering");
                setTqAlert(false);
                handleClose();
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Form;
