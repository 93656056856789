import React, { useState } from 'react';

const AdditionalInformation = ({formData, setFormData}) => {


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={styles.card}>
      <h2 style={styles.header}>Additional Information</h2>
      <div style={styles.formContainer}>

        {/* Supplier/Vendor and Vehicle No */}
        <div style={styles.row}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Supplier/Vendor</label>
            <input
              type="text"
              name="supplierVendor"
              value={formData.supplierVendor}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Vehicle No.</label>
            <input
              type="text"
              name="vehicleNo"
              value={formData.vehicleNo}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </div>

        {/* PO Number, Referral PO No., and MRN */}
        <div style={styles.row}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>PO Number</label>
            <input
              type="text"
              name="poNumber"
              value={formData.poNumber}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Referral PO No.</label>
            <input
              type="text"
              name="referralPONo"
              value={formData.referralPONo}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Material Request Number (MRN)</label>
            <input
              type="text"
              name="mrn"
              value={formData.mrn}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </div>

        {/* Invoice Number and Date */}
        <div style={styles.row}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Invoice Number</label>
            <input
              type="text"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Invoice Date</label>
            <input
              type="date"
              name="invoiceDate"
              value={formData.invoiceDate}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </div>

        {/* Gate Pass No and Date */}
        <div style={styles.row}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Gate Pass No.</label>
            <input
              type="text"
              name="gatePassNo"
              value={formData.gatePassNo}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Gate Pass Date</label>
            <input
              type="date"
              name="gatePassDate"
              value={formData.gatePassDate}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </div>

        {/* DC No and Date */}
        <div style={styles.row}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>DC No.</label>
            <input
              type="text"
              name="dcNo"
              value={formData.dcNo}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>DC Date</label>
            <input
              type="date"
              name="dcDate"
              value={formData.dcDate}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </div>

        {/* In Time and Out Time */}
        <div style={styles.row}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>In Time</label>
            <input
              type="time"
              name="inTime"
              value={formData.inTime}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Out Time</label>
            <input
              type="time"
              name="outTime"
              value={formData.outTime}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

const styles = {
  card: {
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    width: "100%",
    marginBottom: 20
  },
  header: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "15px",
    color: "#2f4858",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  row: {
    display: "flex",
    gap: "20px",
    width: "100%",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  label: {
    fontSize: "14px",
    marginBottom: "5px",
    color: "#555",
  },
  input: {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    fontSize: "14px",
    color: "#333",
    outline: "none",
    width: "100%",
    height: 50
  },
};

export default AdditionalInformation;
