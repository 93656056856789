// import "antd/dist/antd.css";
// import "antd/es/date-picker/style/css";
// import "antd/es/date-picker/style/index.css";
// import "antd/es/input/style/index.css";
// import "antd/lib/popover/style/index.css";
// import "antd/es/popover/style/index.css";
// import "antd/es/p";

import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const rangePresets = [
  {
    label: "Last 24 Hrs",
    value: [dayjs().add(-1, "d"), dayjs()],
  },
  {
    label: "Last 48 Hrs",
    value: [dayjs().add(-2, "d"), dayjs()],
  },
  {
    label: "Last 72 Hrs",
    value: [dayjs().add(-3, "d"), dayjs()],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  // {
  //   label: "Last 90 Days",
  //   value: [dayjs().add(-90, "d"), dayjs()],
  // },
];
const onChange = (date) => {
  if (date) {
    console.log("Date: ", date);
  } else {
    console.log("Clear");
  }
};

const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf("day");
};

// const disabledDate = (current) => {
//     // Can not select days before today and today
//     return current && current < dayjs().endOf('day');
//   };
const CustomDateRangePicker = ({ DateRange, setDateRange }) => {
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      //   console.log("From: ", dates[0], ", to: ", dates[1]);
      //   console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setDateRange([...dateStrings]);
    } else {
      setDateRange([null, null]);
      //   console.log("Clear");
    }
  };
  //   const onChange = (dates, dateStrings) => {
  //     console.log("dates", dates, dateStrings);
  //     setDateRange([...dateStrings]);
  //     // if (dates) {
  //     //   console.log('From: ', dates[0], ', to: ', dates[1]);
  //     //   console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
  //     // } else {
  //     //   console.log('Clear');
  //     // }
  //   };
  return (
    // <RangePicker
    //   disabledDate={disabledDate}
    //   onChange={onChange}
    //   getPopupContainer={(trigger) => {
    //     return trigger;
    //   }}
    //   //   presets={rangePresets}
    //   //    onChange={onRangeChange}
    // />
    <RangePicker
      size="large"
      style={{ padding: "7px 4px 7px 4px" }}
      //   disabledDate={disabledDate}
      presets={rangePresets}
      onChange={onRangeChange}
    />
  );
};

export default CustomDateRangePicker;
