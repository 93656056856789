import firebase from "../../../config/firebase";

const writeGlobalNotification = async (
  clientId,
  type,
  heading,
  text,
  activeChat
) => {
  // write the code here
  let timeStamp = new Date().getTime();
  firebase
    .database()
    .ref(`clientUserDetails/${clientId}/notifications/${timeStamp}`)
    .set({
      timeStamp: timeStamp,
      title: heading,
      message: text,
      type: type,
      seen: false,
      props: {
        activeChat: activeChat ? activeChat : "",
      },
    });
  console.log("written");
};

export const sendNotification = async (
  heading,
  platform,
  fcmToken,
  text,
  clientId,
  type,
  activeChat
) => {
  console.log("sendNotification is called...");
  console.log("heading: ", heading);
  console.log("platform: ", platform);
  console.log("fcmToken: ", fcmToken);
  console.log("text: ", text);
  console.log("clientId: ", clientId);
  console.log("type: ", type);
  console.log("activeChat: ", activeChat);

  const FCM_SERVER_KEY =
    platform == "ios"
      ? ""
      : "AAAAf1fxMb4:APA91bH_sefUL3U10IZLrjZOTf-nE-ytWorHcDm7zOJUB_OkHjjH3ipp7uuwOpiporM8WtslDaSBd6vOfqI47BFgks6UFUhr5Vh0gUYRMmbb9JqRLHLXxfyw0QngsSjXAGTvkkEDiq9r";

  const message = {
    to: fcmToken,
    notification: {
      title: platform === "android" ? heading : "Staart Client",
      subtitle: heading,
      body: text,
      image:
        "https://firebasestorage.googleapis.com/v0/b/staart-works-staging.appspot.com/o/_5889fdf9-2d78-4fc7-8828-3a5f40c8c02e.jpg?alt=media&token=519671c7-905c-4f40-b7aa-0b6e3a37a734",
    },
    data: {
      screenName: "YourScreenName",
      type: "open",
      url: "https://play.google.com/store/apps/details?id=com.inventory0&pcampaignid=web_share",
      type: type,
      // type : notify ? 'Promotional' : 'CRM',
    },
  };

  await fetch("https://fcm.googleapis.com/fcm/send", {
    method: "POST",
    headers: {
      Authorization: `key=${FCM_SERVER_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  })
    .then((response) => {
      console.log("Notification sent successfully:", response);
      writeGlobalNotification(clientId, type, heading, text, activeChat);
      // writeGlobalNotification(clientId, type, heading, text);
    })
    .catch((error) => {
      console.error("Error sending notification:", error);
    });
};
