import React from 'react';

const RequestHistoryModal = ({ requestHistory, onClose, onImageClick }) => (
  <div style={styles.modal}>
    <button style={styles.closeButton} onClick={onClose}>Close</button>
    <h3 style={styles.title}>Request History</h3>
    {Object.values(requestHistory).map((history, index) => (
      <div key={index} style={styles.historyItem}>
        <p><strong>Done By:</strong> {history.doneBy}</p>
        <p><strong>Status:</strong> {history.status}</p>
        <p><strong>Timestamp:</strong> {new Date(Number(history.timestamp)).toLocaleString()}</p>
        {history.images && Object.values(history.images).map((image, i) => (
          <button style={styles.imageButton} key={i} onClick={() => onImageClick(image.uri)}>View Image</button>
        ))}
      </div>
    ))}
  </div>
);

const styles = {
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '600px',
    margin: '20px auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  closeButton: {
    color: '#fff',
    backgroundColor: '#e57373',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    float: 'right',
  },
  title: {
    fontSize: '18px',
    color: '#333',
    marginBottom: '10px',
  },
  historyItem: {
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
  },
  imageButton: {
    color: '#007bff',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
};

export default RequestHistoryModal;
