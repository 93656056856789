import { secondDatabase } from "../../../config/firebase";

const convertInventoryObjectToArray = (inventory, projectName) => {
  const result = [];

  // Iterate over the categories in the inventory object
  for (const category in inventory) {
    const categoryData = inventory[category];

    // Iterate over the materials within each category
    for (const materialId in categoryData) {
      const material = categoryData[materialId];
      const materialHistory = material.history;

      // Initialize a variable to track the current quantity (net total)
      let currentQuantity = 0;

      // Create an object to store the entire history of the material (no changes to history structure)
      const historyDetails = materialHistory;

      const firstImage =
        material.materialImages && material.materialImages
          ? Object.values(material.materialImages)[0]?.uri
          : null;

      for (const historyId in materialHistory) {
        const history = materialHistory[historyId];
        // Parse the quantity as a number (considering positive as inward and negative as outward)
        const quantity = parseFloat(history.quantity) || 0;
        // Add inward quantities (+) and subtract outward quantities (-)
        currentQuantity += quantity;
      }

      // Create an object for each material, with calculated current quantity and full history
      const inventoryItem = {
        id: materialId,
        category: category,
        name: material.description || "Not provided",
        quantity: currentQuantity, // Use the calculated current quantity here
        unit: material.uom || "Not provided",
        status: currentQuantity > 0 ? "In Stock" : "Out of Stock", // Status from the latest entry
        projectName: projectName,
        historyDetails: historyDetails, // Attach the original history structure here
        image: firstImage,
      };

      result.push(inventoryItem);
    }
  }

  return result;
};

const fetchData = async (firmId, projectId, projectName) => {
  // Return a Promise to handle async behavior correctly
  return new Promise((resolve, reject) => {
    const useRef = secondDatabase.ref(`${firmId}/${projectId}/allInventory/`);

    // Once the data is fetched from Firebase, handle the response
    useRef.once(
      "value",
      async (snapshot) => {
        let data = snapshot.val();

        if (data !== null) {
          try {
            // Wait for the inventory conversion to complete
            let returnData = await convertInventoryObjectToArray(
              data,
              projectName
            );
            resolve(returnData); // Resolve the Promise with the processed data
          } catch (error) {
            reject(error); // Reject the Promise if an error occurs during conversion
          }
        } else {
          resolve([]); // Resolve with an empty array if no data is found
        }
      },
      (error) => {
        reject(error); // Reject the Promise if Firebase request fails
      }
    );
  });
};

const netInventoryData = async (projects) => {
  if (!Array.isArray(projects) && projects !== null) {
    //   if (typeof projects === "object" && projects !== null) {

    try {
      // Wait for the fetchData promise to resolve and return the result
      const result = await fetchData(
        projects.firmDetailsID,
        projects.projectID,
        projects.projectName
      );
      // Return the fetched data
      return result;
    } catch (error) {
      return []; // Return an empty array if an error occurs
    }
  } else {
    // Return a default value or handle the case where 'projects' is not an object
    let allProjectData = [];

    for (let index = 0; index < projects.length; index++) {
      const project = projects[index];
      try {
        // Wait for the fetchData promise to resolve and return the result
        const result = await fetchData(
          project.firmDetailsID,
          project.projectID,
          project.projectName
        );

        // Add projectName to each item in the result
        const updatedResult = result.map((item) => ({
          ...item,
          projectName: project.projectName,
        }));

        // Append updated items to allProjectData
        allProjectData = [...allProjectData, ...updatedResult];
      } catch (error) {
        console.error(
          `Error fetching data for project: ${project.projectName}`,
          error
        );
      }
    }

    return allProjectData;
  }
};

export default netInventoryData;

// let data = [
//     {
//       id: 1,
//       category: "Electronics",
//       name: "Laptop",
//       quantity: 5,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//   ];

//   return data;

// const netInventoryData = [
//     {
//       id: 1,
//       category: "Electronics",
//       name: "Laptop",
//       quantity: 5,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 2,
//       category: "Furniture",
//       name: "Chair",
//       quantity: 20,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 3,
//       category: "Electronics",
//       name: "Monitor",
//       quantity: 0,
//       unit: "pcs",
//       status: "Out of Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 4,
//       category: "Stationery",
//       name: "Notebook",
//       quantity: 15,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 5,
//       category: "Kitchen",
//       name: "Mug",
//       quantity: 8,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 6,
//       category: "Electronics",
//       name: "Headphones",
//       quantity: 0,
//       unit: "pcs",
//       status: "Out of Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 7,
//       category: "Furniture",
//       name: "Table",
//       quantity: 2,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 8,
//       category: "Stationery",
//       name: "Pen",
//       quantity: 50,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 9,
//       category: "Electronics",
//       name: "Smartphone",
//       quantity: 12,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 10,
//       category: "Tools",
//       name: "Hammer",
//       quantity: 4,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 11,
//       category: "Kitchen",
//       name: "Plate",
//       quantity: 0,
//       unit: "pcs",
//       status: "Out of Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 12,
//       category: "Furniture",
//       name: "Sofa",
//       quantity: 1,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 13,
//       category: "Stationery",
//       name: "Marker",
//       quantity: 18,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 14,
//       category: "Electronics",
//       name: "Camera",
//       quantity: 3,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//     {
//       id: 15,
//       category: "Kitchen",
//       name: "Glass",
//       quantity: 9,
//       unit: "pcs",
//       status: "In Stock",
//       projectName: "Single Project",
//     },
//   ];
