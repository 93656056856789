import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  pdf,
  Image,
} from "@react-pdf/renderer";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import ArticleIcon from "@mui/icons-material/Article";
import firebase from "../../../config/firebase";
import SendIcon from "@mui/icons-material/Send";
import toast from "react-hot-toast";
import { Tooltip } from "antd";
import ForwardOutlinedIcon from "@mui/icons-material/ForwardOutlined";

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    padding: 30,
  },
  header: {
    flexDirection: "row",
    marginBottom: 20,
  },
  logoPlaceholder: {
    width: 60,
    height: 60,
    border: "1 solid black",
    justifyContent: "center",
    alignItems: "center",
  },
  companyInfo: {
    marginLeft: 10,
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    marginVertical: 10,
    borderBottom: "1 solid black",
    paddingBottom: 5,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: "center",
    fontSize: 8,
    borderTop: "1 solid black",
    paddingTop: 5,
  },
  rightAlign: {
    textAlign: "right",
  },
  watermark: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-100%, -50%) rotate(-45deg)",
    fontSize: 60,
    opacity: 0.2,
    color: "red",
    zIndex: -1,
  },
});

function numberToWords(numberString) {
  // Define arrays for units, tens, and scales
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const scales = ["", "Thousand", "Million", "Billion"];

  // Function to handle conversion of chunks of three digits
  function convertChunk(num) {
    const numStr = num.toString();
    const numArr = numStr.padStart(3, "0").split("").map(Number); // Pad to handle numbers less than 1000

    let words = "";
    if (numArr[0] !== 0) {
      words += units[numArr[0]] + " hundred ";
    }
    if (numArr[1] === 1) {
      words += teens[numArr[2]] || "";
    } else {
      words += tens[numArr[1]] + " " + units[numArr[2]];
    }
    return words.trim();
  }

  // Split the number string by commas
  const parts = numberString.split(",");

  // Convert each part to words
  let result = "";
  for (let i = 0; i < parts.length; i++) {
    const num = parseInt(parts[i], 10);
    if (num !== 0) {
      const chunk = convertChunk(num);
      result += chunk + " " + scales[parts.length - 1 - i] + " ";
    }
  }

  return result.trim();
}

let financialYear = '';

function calculateFianancialYear () {
  let currentYear = new Date().getFullYear()%100;
  let currentMonth = new Date().getMonth() + 1;
  if(currentMonth < 4){
    financialYear = String(currentYear - 1) + '-' + String(currentYear);
  }else{
    financialYear = String(currentYear) + '-' + String(currentYear + 1);
  }
}

calculateFianancialYear();

const Invoice = ({
  name,
  clientGST,
  woRefNumber,
  lutSerialNumber,
  arnNumber,
  stateName,
  stateCode,
  milestoneAmount,
  CGST,
  SGST,
  IGST,
  totalTax,
  totalAmountAfterTax,
  placeOfSupply,
  poNos,
  remark,
  receiverData,
  consigneeData,
  edit,
  invoiceCount
}) => (
  
  <Document>
    <Page size="A4" style={styles.page}>

      {edit && <Text style={styles.watermark}>UNRELEASED</Text>}

      <View style={styles.header}>
        {/* <View style={styles.logoPlaceholder}> */}
        <Image
          src="https://media.licdn.com/dms/image/C4E0BAQG8W6uMG-jvbg/company-logo_200_200/0/1630593834328/uniworks_designs_pvt_lts_logo?e=2147483647&v=beta&t=lCfr1BmWhomCxZzyW5AIQMWC7_3kKQq3vWRoq636uBw"
          // style={{ width: '100%', height: 'auto' }}
          style={styles.logoPlaceholder}
        />
        {/* </View> */}
        <View style={styles.companyInfo}>
          <Text>Uniworks Designs Pvt Ltd</Text>
          <Text>
            CO-01, 1st Floor, Indu Fortune Fields The Annex, KPHB Phase 13,
            Kukatpally, Hyderabad, Telangana 500085
          </Text>
        </View>
      </View>

      <Text style={styles.title}>TAX INVOICE</Text>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Invoice No. UNI/{financialYear}/{invoiceCount + 1}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>WO Ref No. {woRefNumber}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>GST No. 36AABCU9953A2Z9</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Reverse Charge NIL</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              LUT Serial No {lutSerialNumber}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>ARN {arnNumber}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              Invoice Date{" "}
              {String(new Date().getDate()) +
                "/" +
                String(new Date().getMonth() + 1) +
                "/" +
                String(new Date().getFullYear())}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>PO Nos : {poNos}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              Place of Supply {placeOfSupply}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>State : {stateName}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>State Code : {stateCode}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}></Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Details of Receiver | Billed to:
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Details of Consignee | Shipped to:
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Name : {receiverData.name}</Text>
            <Text style={styles.tableCell}>
              Address : {receiverData.addressLine1}
            </Text>
            <Text style={styles.tableCell}> {receiverData.addressLine2}</Text>
            <Text style={styles.tableCell}>
              {" "}
              {receiverData.state}, {receiverData.city}-{receiverData.pin}
            </Text>
            <Text style={styles.tableCell}>GST No. : {receiverData.gstNo}</Text>
            <Text style={styles.tableCell}>PAN No. : {receiverData.panNo}</Text>
            <Text style={styles.tableCell}>
              State {stateName} State Code {stateCode}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Name : {consigneeData.name}</Text>
            <Text style={styles.tableCell}>
              Address : {consigneeData.addressLine1}
            </Text>
            <Text style={styles.tableCell}> {consigneeData.addressLine2}</Text>
            <Text style={styles.tableCell}>
              {consigneeData.state}, {consigneeData.city}-{consigneeData.pin}
            </Text>
            <Text style={styles.tableCell}>
              GST No. : {consigneeData.gstNo}
            </Text>
            <Text style={styles.tableCell}>
              PAN No. : {consigneeData.panNo}
            </Text>
            <Text style={styles.tableCell}>
              State {stateName} State Code {stateCode}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "5%" }]}>
            <Text style={styles.tableCell}>Sr. No</Text>
          </View>
          <View style={[styles.tableCol, { width: "30%" }]}>
            <Text style={styles.tableCell}>Name of Product / Service</Text>
          </View>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>HSN/SAC</Text>
          </View>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>UOM</Text>
          </View>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>Qty</Text>
          </View>
          <View style={[styles.tableCol, { width: "15%" }]}>
            <Text style={styles.tableCell}>Rate</Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={styles.tableCell}>Amount</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "5%" }]}>
            <Text style={styles.tableCell}>1</Text>
          </View>
          <View style={[styles.tableCol, { width: "30%" }]}>
            <Text style={styles.tableCell}>Work Contract ({`${name}`})</Text>
          </View>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>{"-"}</Text>
          </View>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>LUM</Text>
          </View>
          <View style={[styles.tableCol, { width: "10%" }]}>
            <Text style={styles.tableCell}>1</Text>
          </View>
          <View style={[styles.tableCol, { width: "15%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>
              {milestoneAmount}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>
              {milestoneAmount}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "80%" }]}>
            <Text style={styles.tableCell}>TOTAL:</Text>
          </View>
          <View style={[styles.tableCol, { width: "20%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>
              {milestoneAmount}
            </Text>
          </View>
        </View>
      </View>

      <View style={[styles.table, { marginTop: 10 }]}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>Total Amount Before Tax</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>
              {milestoneAmount}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>Add : CGST {"(9%)"}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>{CGST}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>Add : SGST {"(9%)"}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>{SGST}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>Add : IGST {"(18%)"}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>{IGST}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>Total GST Amount</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>
              {totalTax}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>Total Amount After Tax</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>
              {totalAmountAfterTax}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>Total to pay</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.rightAlign]}>
              {totalAmountAfterTax}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Certified that the particulars given above are true and correct
            </Text>
          </View>
        </View>
      </View>

      <Text style={{ fontSize: 8, marginTop: 5 }}>
        Rupees {numberToWords(totalAmountAfterTax)} only
      </Text>

      {/* Bank Details */}
      <View style={[styles.table, { marginTop: 10 }]}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Bank Details:</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              Bank Account Number : 059125010000005
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              Bank Branch IFSC : UBIN0905917 (UNION BANK)
            </Text>
          </View>
        </View>
      </View>

      {/* Signature */}
      <View style={{ marginTop: 20, alignItems: "flex-end" }}>
        <Text style={{ fontSize: 8 }}>For, Uniworks Designs Pvt Ltd</Text>
        <Text style={{ fontSize: 8 }}>Authorized Signatory</Text>
        <Text style={{ fontSize: 8 }}>Digitally signed by Juhi Jaiswal</Text>
        <Text style={{ fontSize: 8 }}>Chaubey</Text>
        <Text style={{ fontSize: 8 }}>Date: {String(new Date())}</Text>
      </View>

      <Text style={styles.footer}>Note/Declaration: {remark}</Text>
    </Page>
  </Document>
);

// {edit &&
//   data.map((item, index) => (
//     <Page size="A4" style={styles.page}>
//       <Text style={styles.watermark}>UNRELEASED</Text>

//       <View style={styles.header}>
//         {/* <View style={styles.logoPlaceholder}> */}
//         <Image
//           src="https://media.licdn.com/dms/image/C4E0BAQG8W6uMG-jvbg/company-logo_200_200/0/1630593834328/uniworks_designs_pvt_lts_logo?e=2147483647&v=beta&t=lCfr1BmWhomCxZzyW5AIQMWC7_3kKQq3vWRoq636uBw"
//           // style={{ width: '100%', height: 'auto' }}
//           style={styles.logoPlaceholder}
//         />
//         {/* </View> */}
//         <View style={styles.companyInfo}>
//           <Text>Uniworks Designs Pvt Ltd</Text>
//           <Text>
//             CO-01, 1st Floor, Indu Fortune Fields The Annex, KPHB Phase 13,
//             Kukatpally, Hyderabad, Telangana 500085
//           </Text>
//         </View>
//       </View>

//       <Text style={styles.title}>TAX INVOICE</Text>

//       <View style={styles.table}>
//         <View style={styles.tableRow}>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>Invoice No. UNI/23-24/041</Text>
//           </View>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>WO Ref No. {woRefNumber}</Text>
//           </View>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>GST No. 36AABCU9953A2Z9</Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>Reverse Charge NIL</Text>
//           </View>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>
//               LUT Serial No {lutSerialNumber}
//             </Text>
//           </View>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>ARN {arnNumber}</Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>
//               Invoice Date{" "}
//               {String(new Date().getDate()) +
//                 "/" +
//                 String(new Date().getMonth() + 1) +
//                 "/" +
//                 String(new Date().getFullYear())}
//             </Text>
//           </View>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>PO Nos : {poNos}</Text>
//           </View>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>
//               Place of Supply {placeOfSupply}
//             </Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>State : {stateName}</Text>
//           </View>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>State Code : {stateCode}</Text>
//           </View>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}></Text>
//           </View>
//         </View>
//       </View>

//       <View style={styles.table}>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "50%" }]}>
//             <Text style={styles.tableCell}>
//               Details of Receiver | Billed to:
//             </Text>
//           </View>
//           <View style={[styles.tableCol, { width: "50%" }]}>
//             <Text style={styles.tableCell}>
//               Details of Consignee | Shipped to:
//             </Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "50%" }]}>
//             <Text style={styles.tableCell}>Name : {receiverData.name}</Text>
//             <Text style={styles.tableCell}>
//               Address : {receiverData.addressLine1}
//             </Text>
//             <Text style={styles.tableCell}>
//               {" "}
//               {receiverData.addressLine2}
//             </Text>
//             <Text style={styles.tableCell}>
//               {" "}
//               {receiverData.state}, {receiverData.city}-{receiverData.pin}
//             </Text>
//             <Text style={styles.tableCell}>
//               GST No. : {receiverData.gstNo}
//             </Text>
//             <Text style={styles.tableCell}>
//               PAN No. : {receiverData.panNo}
//             </Text>
//             <Text style={styles.tableCell}>
//               State {stateName} State Code {stateCode}
//             </Text>
//           </View>
//           <View style={[styles.tableCol, { width: "50%" }]}>
//             <Text style={styles.tableCell}>
//               Name : {consigneeData.name}
//             </Text>
//             <Text style={styles.tableCell}>
//               Address : {consigneeData.addressLine1}
//             </Text>
//             <Text style={styles.tableCell}>
//               {" "}
//               {consigneeData.addressLine2}
//             </Text>
//             <Text style={styles.tableCell}>
//               {consigneeData.state}, {consigneeData.city}-
//               {consigneeData.pin}
//             </Text>
//             <Text style={styles.tableCell}>
//               GST No. : {consigneeData.gstNo}
//             </Text>
//             <Text style={styles.tableCell}>
//               PAN No. : {consigneeData.panNo}
//             </Text>
//             <Text style={styles.tableCell}>
//               State {stateName} State Code {stateCode}
//             </Text>
//           </View>
//         </View>
//       </View>

//       <View style={styles.table}>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "5%" }]}>
//             <Text style={styles.tableCell}>Sr. No</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "30%" }]}>
//             <Text style={styles.tableCell}>Name of Product / Service</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "10%" }]}>
//             <Text style={styles.tableCell}>HSN/SAC</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "10%" }]}>
//             <Text style={styles.tableCell}>UOM</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "10%" }]}>
//             <Text style={styles.tableCell}>Qty</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "15%" }]}>
//             <Text style={styles.tableCell}>Rate</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "20%" }]}>
//             <Text style={styles.tableCell}>Amount</Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "5%" }]}>
//             <Text style={styles.tableCell}>1</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "30%" }]}>
//             <Text style={styles.tableCell}>
//               Work Contract ({`${name}`})
//             </Text>
//           </View>
//           <View style={[styles.tableCol, { width: "10%" }]}>
//             <Text style={styles.tableCell}>{"-"}</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "10%" }]}>
//             <Text style={styles.tableCell}>LUM</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "10%" }]}>
//             <Text style={styles.tableCell}>1</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "15%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {milestoneAmount}
//             </Text>
//           </View>
//           <View style={[styles.tableCol, { width: "20%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {milestoneAmount}
//             </Text>
//           </View>
//         </View>
//       </View>

//       <View style={styles.table}>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "80%" }]}>
//             <Text style={styles.tableCell}>TOTAL:</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "20%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {milestoneAmount}
//             </Text>
//           </View>
//         </View>
//       </View>

//       <View style={[styles.table, { marginTop: 10 }]}>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={styles.tableCell}>Total Amount Before Tax</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {milestoneAmount}
//             </Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={styles.tableCell}>Add : CGST {"(9%)"}</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {CGST}
//             </Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={styles.tableCell}>Add : SGST {"(9%)"}</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {SGST}
//             </Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={styles.tableCell}>Add : IGST {"(18%)"}</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {IGST}
//             </Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={styles.tableCell}>Total GST Amount</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {totalTax}
//             </Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={styles.tableCell}>Total Amount After Tax</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {totalAmountAfterTax}
//             </Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={styles.tableCell}>Total to pay</Text>
//           </View>
//           <View style={[styles.tableCol, { width: "25%" }]}>
//             <Text style={[styles.tableCell, styles.rightAlign]}>
//               {totalAmountAfterTax}
//             </Text>
//           </View>
//           <View style={[styles.tableCol, { width: "50%" }]}>
//             <Text style={styles.tableCell}>
//               Certified that the particulars given above are true and
//               correct
//             </Text>
//           </View>
//         </View>
//       </View>

//       <Text style={{ fontSize: 8, marginTop: 5 }}>
//         Rupees {numberToWords(totalAmountAfterTax)} only
//       </Text>

//       {/* Bank Details */}
//       <View style={[styles.table, { marginTop: 10 }]}>
//         <View style={styles.tableRow}>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>Bank Details:</Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>
//               Bank Account Number : 059125010000005
//             </Text>
//           </View>
//         </View>
//         <View style={styles.tableRow}>
//           <View style={styles.tableCol}>
//             <Text style={styles.tableCell}>
//               Bank Branch IFSC : UBIN0905917 (UNION BANK)
//             </Text>
//           </View>
//         </View>
//       </View>

//       {/* Signature */}
//       <View style={{ marginTop: 20, alignItems: "flex-end" }}>
//         <Text style={{ fontSize: 8 }}>For, Uniworks Designs Pvt Ltd</Text>
//         <Text style={{ fontSize: 8 }}>Authorized Signatory</Text>
//         <Text style={{ fontSize: 8 }}>
//           Digitally signed by Juhi Jaiswal
//         </Text>
//         <Text style={{ fontSize: 8 }}>Chaubey</Text>
//         <Text style={{ fontSize: 8 }}>Date: {String(new Date())}</Text>
//       </View>

//       <Text style={styles.footer}>Note/Declaration: {remark}</Text>
//     </Page>
//   ))}

// Main Component

const InvoiceButton = ({
  request,
  writeIntoTheDatabase,
  name,
  handleOpen,
  amount,
  setMilestoneValue,
  clientGST,
  woRefNumber,
  lutSerialNumber,
  arnNumber,
  stateName,
  stateCode,
  checked,
  milestoneAmount,
  CGST,
  SGST,
  IGST,
  totalTax,
  placeOfSupply,
  poNos,
  remark,
  receiverData,
  consigneeData,
  invoiceCount
}) => {
  
  const [previewOpen, setPreviewOpen] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null); // State to store PDF blob

  const handlePreviewOpen = async () => {
    console.log("handlePreviewOpen is called...");
    let statuSEZ = true;
    if (checked) {
      if (
        lutSerialNumber === null ||
        arnNumber === null ||
        lutSerialNumber === "" ||
        arnNumber === ""
      ) {
        toast.error(
          "You have marked SEZ Unit, you need to provide LUT No and ARN No both."
        );
        statuSEZ = false;
      }
    }
    let status = await writeIntoTheDatabase(true);
    console.log("status : ", status);
    if (status && statuSEZ) {
      setPreviewOpen(true);
    }
  };

  const handlePreviewClose = () => setPreviewOpen(false);

  const openRequestModal = async () => {
    setMilestoneValue(amount);
    handleOpen();
  };

  const handleGeneratePDF = async () => {
    try {
      const doc = (
        <Invoice
          amount={amount}
          name={name}
          clientGST={clientGST}
          woRefNumber={woRefNumber}
          lutSerialNumber={lutSerialNumber}
          arnNumber={arnNumber}
          stateName={stateName}
          stateCode={stateCode}
          milestoneAmount={Number(amount).toLocaleString("en-IN")}
          CGST={CGST}
          SGST={SGST}
          IGST={IGST}
          totalTax={
            totalTax === "Nil"
              ? "Nil"
              : Number(totalTax).toLocaleString("en-IN")
          }
          totalAmountAfterTax={
            totalTax === "Nil"
              ? Number(amount).toLocaleString("en-IN")
              : String(
                  Number(
                    (Number(amount) + Number(totalTax)).toFixed(0)
                  ).toLocaleString("en-IN")
                )
          }
          placeOfSupply={placeOfSupply}
          poNos={poNos}
          remark={remark}
          receiverData={receiverData}
          consigneeData={consigneeData}
          invoiceCount
        />
      );
      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();

      setPdfBlob(blob);
      handleUpload(blob); // Call upload function
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleUpload = async (blob) => {
    console.log("Uploading PDF...");
    try {
      const storageRef = firebase.storage().ref();
      const pdfRef = storageRef.child(`invoices/invoice_${Date.now()}.pdf`);
      await pdfRef.put(blob);
      const downloadURL = await pdfRef.getDownloadURL();
      console.log("PDF uploaded successfully. Download URL:", downloadURL);
      // alert("PDF uploaded successfully. Download URL: " + downloadURL);
      writeIntoTheDatabase(downloadURL);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  useEffect(() => {
    if (previewOpen && pdfBlob) {
      URL.revokeObjectURL(pdfBlob); // Clean up previous URL
    }
  }, [previewOpen, pdfBlob]);

  return (
    <div>
      {!request ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "20px",
            marginBottom: "20px",
            alignItems: "center",
            // marginRight: "20px",
          }}
        >
          <Tooltip title="Raise invoice" placement="top">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#E3F2FD", // Light blue background
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                padding: "12px 20px",
                cursor: "pointer",
                transition: "all 0.3s ease",
              }}
              onClick={openRequestModal}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#BBDEFB";
                e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.15)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#E3F2FD";
                e.currentTarget.style.boxShadow = "0 2px 4px rgba(0,0,0,0.1)";
              }}
            >
              <p
                style={{
                  margin: 0,
                  marginRight: "15px",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#1565C0",
                  letterSpacing: "0.5px",
                }}
              >
                Generate Invoice
              </p>
              <ForwardOutlinedIcon style={{ fontSize: 28, color: "#1565C0" }} />
            </div>
          </Tooltip>
        </div>
      ) : (
        <Button
          variant="contained"
          fullWidth
          endIcon={<SendIcon />}
          sx={{
            borderRadius: 16,
            height: "56px",
            fontSize: "18px",
            fontWeight: 600,
            textTransform: "none",
            backgroundColor: "#fdd34d",
            "&:hover": {
              backgroundColor: "#f9d766",
              boxShadow: "0 8px 16px 0 rgba(59,130,246,0.3)",
            },
          }}
          onClick={handlePreviewOpen}
        >
          Send Request
        </Button>
      )}
      <Modal open={previewOpen} onClose={handlePreviewClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 20,
            boxShadow: 24,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "90vh",
            width: "40vw",
          }}
        >
          <p style={{ color: "#6a6a6a", fontSize: 20 }}>Invoice</p>
          <PDFViewer
            style={{
              width: "40vw",
              height: "80vh",
              border: "none",
              padding: 20,
            }}
          >
            <Invoice
              amount={amount}
              name={name}
              clientGST={clientGST}
              woRefNumber={woRefNumber}
              lutSerialNumber={lutSerialNumber}
              arnNumber={arnNumber}
              stateName={stateName}
              stateCode={stateCode}
              milestoneAmount={Number(amount).toLocaleString("en-IN")}
              CGST={CGST}
              SGST={SGST}
              IGST={IGST}
              totalTax={
                totalTax === "Nil"
                  ? "Nil"
                  : Number(totalTax).toLocaleString("en-IN")
              }
              totalAmountAfterTax={
                totalTax === "Nil"
                  ? Number(amount).toLocaleString("en-IN")
                  : String(
                      Number(
                        (Number(amount) + Number(totalTax)).toFixed(0)
                      ).toLocaleString("en-IN")
                    )
              }
              placeOfSupply={placeOfSupply}
              poNos={poNos}
              remark={remark}
              receiverData={receiverData}
              consigneeData={consigneeData}
              invoiceCount={invoiceCount}
            />
          </PDFViewer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              style={{
                border: "1px solid #6a6a6a",
                backgroundColor: "#6a6a6a",
                color: "white",
                borderRadius: 5,
                padding: 5,
                width: 250,
              }}
              onClick={handlePreviewClose}
            >
              Close
            </Button>
            {/* Updated PDFDownloadLink with onClick handler */}

            <Button
              variant="contained"
              style={{
                marginTop: 10,
                padding: 5,
                borderRadius: 5,
                backgroundColor: "#27AE60",
                color: "white",
                width: 250,
              }}
              onClick={handleGeneratePDF} // Trigger generate PDF function
            >
              Generate Invoice
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InvoiceButton;
