import React, { useEffect, useState, useRef, useCallback } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DrawerTemplate from "../../hoc/drawerTemplate";
import "./Vehicles.css";
import { vehicleData } from "./data";
import firebase from "../../config/firebase";
import { secondDatabase } from "../../config/firebase";
import BreakdownModal from "./BreakdownModal";
import MaintenanceModal from "./MaintenanceModal";
import VehicleForm from "./VehicleForm";
import TransferVehicle from "./TransferVehicle";
import {
  Button,
  Dialog,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as XLSX from "xlsx-js-style";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

import { saveAs } from "file-saver";
import ConsumptionModal from "./ConsumptionModal";
const Vehicles = () => {
  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("User"));
    const tempFirmId = localStorageData.firmId;
    setFirmId(tempFirmId);
  }, []);

  const exportToExcel = (data, tableName) => {
    const styles = {
      title: {
        font: {
          name: "Calibri",
          sz: 20,
          bold: true,
          color: { rgb: "2C3E50" },
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
        },
        border: {
          top: { style: "thin", color: { rgb: "FFFFFF" } },
          bottom: { style: "thin", color: { rgb: "E0E0E0" } },
          left: { style: "thin", color: { rgb: "FFFFFF" } },
          right: { style: "thin", color: { rgb: "FFFFFF" } },
        },
      },
      subtitle: {
        font: {
          name: "Calibri",
          sz: 10,
          color: { rgb: "7F8C8D" },
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
        },
      },
      sectionHeader: {
        font: {
          name: "Calibri",
          sz: 12,
          bold: true,
          color: { rgb: "000000" },
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FDD34D" },
        },
        border: {
          top: { style: "thin", color: { rgb: "E0E0E0" } },
          bottom: { style: "thin", color: { rgb: "E0E0E0" } },
          left: { style: "thin", color: { rgb: "E0E0E0" } },
          right: { style: "thin", color: { rgb: "E0E0E0" } },
        },
      },
      columnHeader: {
        font: {
          name: "Calibri",
          sz: 11,
          bold: true,
          color: { rgb: "000000" },
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
          wrapText: true,
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FDD34D" },
        },
        border: {
          top: { style: "thin", color: { rgb: "E0E0E0" } },
          bottom: { style: "thin", color: { rgb: "E0E0E0" } },
          left: { style: "thin", color: { rgb: "E0E0E0" } },
          right: { style: "thin", color: { rgb: "E0E0E0" } },
        },
      },
      cell: {
        font: {
          name: "Calibri",
          sz: 10,
          color: { rgb: "2C3E50" },
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
          wrapText: true,
        },
        border: {
          top: { style: "thin", color: { rgb: "F0F0F0" } },
          bottom: { style: "thin", color: { rgb: "F0F0F0" } },
          left: { style: "thin", color: { rgb: "F0F0F0" } },
          right: { style: "thin", color: { rgb: "F0F0F0" } },
        },
      },
      numericCell: {
        font: {
          name: "Calibri",
          sz: 10,
          color: { rgb: "2C3E50" },
        },
        alignment: {
          horizontal: "right",
          vertical: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "F0F0F0" } },
          bottom: { style: "thin", color: { rgb: "F0F0F0" } },
          left: { style: "thin", color: { rgb: "F0F0F0" } },
          right: { style: "thin", color: { rgb: "F0F0F0" } },
        },
      },
      currencyCell: {
        font: {
          name: "Calibri",
          sz: 10,
          color: { rgb: "2C3E50" },
        },
        alignment: {
          horizontal: "right",
          vertical: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "F0F0F0" } },
          bottom: { style: "thin", color: { rgb: "F0F0F0" } },
          left: { style: "thin", color: { rgb: "F0F0F0" } },
          right: { style: "thin", color: { rgb: "F0F0F0" } },
        },
        numFmt: "₹#,##0.00",
      },
      totalRow: {
        font: {
          name: "Calibri",
          sz: 11,
          bold: true,
          color: { rgb: "FFFFFF" },
        },
        alignment: {
          horizontal: "right",
          vertical: "center",
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "2C3E50" },
        },
        border: {
          top: { style: "thin", color: { rgb: "E0E0E0" } },
          bottom: { style: "thin", color: { rgb: "E0E0E0" } },
          left: { style: "thin", color: { rgb: "E0E0E0" } },
          right: { style: "thin", color: { rgb: "E0E0E0" } },
        },
      },
      totalRowLabel: {
        font: {
          name: "Calibri",
          sz: 11,
          bold: true,
          color: { rgb: "FFFFFF" },
        },
        alignment: {
          horizontal: "left",
          vertical: "center",
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "2C3E50" },
        },
        border: {
          top: { style: "thin", color: { rgb: "E0E0E0" } },
          bottom: { style: "thin", color: { rgb: "E0E0E0" } },
          left: { style: "thin", color: { rgb: "E0E0E0" } },
          right: { style: "thin", color: { rgb: "E0E0E0" } },
        },
      },
    };

    let wsData = [
      [{ v: tableName.toUpperCase(), s: styles.title }],
      [
        {
          v: `Generated on ${new Date().toLocaleDateString()}`,
          s: styles.subtitle,
        },
      ],
      [],
      [],
    ];

    if (tableName === "Daily Vehicle Report") {
      const groupHeaders = [
        [
          { v: "VEHICLE DETAILS", s: styles.sectionHeader },
          "",
          "",
          "",
          "",
          { v: "ENGINE 1", s: styles.sectionHeader },
          "",
          "",
          { v: "ENGINE 2", s: styles.sectionHeader },
          "",
          "",
          { v: "KILOMETERS", s: styles.sectionHeader },
          "",
          "",
          { v: "FUEL & COST", s: styles.sectionHeader },
          "",
          { v: "PURPOSE", s: styles.sectionHeader },
        ],
      ];

      const columnHeaders = [
        [
          { v: "Project Name", s: styles.columnHeader },
          { v: "Date", s: styles.columnHeader },
          { v: "Type", s: styles.columnHeader },
          { v: "Number", s: styles.columnHeader },
          { v: "Chassis Number", s: styles.columnHeader },
          { v: "Start Reading", s: styles.columnHeader },
          { v: "End Reading", s: styles.columnHeader },
          { v: "Hours", s: styles.columnHeader },
          { v: "Start Reading", s: styles.columnHeader },
          { v: "End Reading", s: styles.columnHeader },
          { v: "Hours", s: styles.columnHeader },
          { v: "Start Reading", s: styles.columnHeader },
          { v: "End Reading", s: styles.columnHeader },
          { v: "Distance", s: styles.columnHeader },
          { v: "Fuel (L)", s: styles.columnHeader },
          { v: "Rate (₹/L)", s: styles.columnHeader },
          { v: "Total Amount (₹)", s: styles.columnHeader },
          { v: "Purpose", s: styles.columnHeader },
        ],
      ];

      const dataRows = data.map((item) => [
        { v: item.projectName || "--", s: styles.cell },
        {
          v: item.date ? item.date.toLocaleDateString() : "--",
          s: { ...styles.cell, numFmt: "m/dd/yy" },
        },
        { v: item.type || "--", s: styles.cell },
        { v: item.number || "--", s: styles.cell },
        { v: item.chassisNumber || "--", s: styles.cell },
        {
          v:
            item.startReading && !isNaN(item.startReading)
              ? parseFloat(item.startReading).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.endReading && !isNaN(item.endReading)
              ? parseFloat(item.endReading).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.hours && !isNaN(item.hours)
              ? parseFloat(item.hours).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.engine2StartReading && !isNaN(item.engine2StartReading)
              ? parseFloat(item.engine2StartReading).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.engine2EndReading && !isNaN(item.engine2EndReading)
              ? parseFloat(item.engine2EndReading).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.engine2Hours && !isNaN(item.engine2Hours)
              ? parseFloat(item.engine2Hours).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.kmsStartReading && !isNaN(item.kmsStartReading)
              ? parseFloat(item.kmsStartReading).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.kmsEndReading && !isNaN(item.kmsEndReading)
              ? parseFloat(item.kmsEndReading).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.kmsTravelled && !isNaN(item.kmsTravelled)
              ? parseFloat(item.kmsTravelled).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.fuelConsumed && !isNaN(item.fuelConsumed)
              ? parseFloat(item.fuelConsumed).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.amount && !isNaN(item.amount)
              ? parseFloat(item.amount).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.totalAmount && !isNaN(item.totalAmount)
              ? parseFloat(item.totalAmount).toFixed(2)
              : "--",
          s: styles.currencyCell,
        },
        { v: item.purpose || "--", s: styles.cell },
      ]);

      const totals = data.reduce(
        (acc, item) => ({
          hours:
            acc.hours +
            (item.hours && !isNaN(item.hours) ? parseFloat(item.hours) : 0),
          engine2Hours:
            acc.engine2Hours +
            (item.engine2Hours && !isNaN(item.engine2Hours)
              ? parseFloat(item.engine2Hours)
              : 0),
          kmsTravelled:
            acc.kmsTravelled +
            (item.kmsTravelled && !isNaN(item.kmsTravelled)
              ? parseFloat(item.kmsTravelled)
              : 0),
          fuelConsumed:
            acc.fuelConsumed +
            (item.fuelConsumed && !isNaN(item.fuelConsumed)
              ? parseFloat(item.fuelConsumed)
              : 0),
          totalAmount:
            acc.totalAmount +
            (item.totalAmount && !isNaN(item.totalAmount)
              ? parseFloat(item.totalAmount)
              : 0),
        }),
        {
          hours: 0,
          engine2Hours: 0,
          kmsTravelled: 0,
          fuelConsumed: 0,
          totalAmount: 0,
        }
      );

      const totalRow = [
        { v: "TOTAL", s: styles.totalRowLabel },
        { v: "", s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: totals.hours, s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: totals.engine2Hours, s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: totals.kmsTravelled, s: styles.totalRow },
        { v: totals.fuelConsumed, s: styles.totalRow },
        { v: "", s: styles.totalRow },
        { v: totals.totalAmount, s: styles.totalRow },
        { v: "", s: styles.totalRow },
      ];

      wsData = [
        ...wsData,
        ...groupHeaders,
        ...columnHeaders,
        ...dataRows,
        totalRow,
      ];
    } else if (tableName === "Vehicle Information") {
      const groupHeaders = [
        [
          { v: "VEHICLE DETAILS", s: styles.sectionHeader },
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          { v: "TOTAL", s: styles.sectionHeader },
          "",
          "",
          { v: "ENGINE 1", s: styles.sectionHeader },
          { v: "ENGINE 2", s: styles.sectionHeader },
          { v: "PURCHASE", s: styles.sectionHeader },
          "",
          { v: "INSURANCE", s: styles.sectionHeader },
          "",
          "",
          { v: "PERMIT & FITNESS", s: styles.sectionHeader },
          "",
          "",
          "",
          "",
          "",
        ],
      ];

      const columnHeaders = [
        [
          { v: "Project Name", s: styles.columnHeader },
          { v: "Type", s: styles.columnHeader },
          { v: "Number", s: styles.columnHeader },
          { v: "Chassis Number", s: styles.columnHeader },
          { v: "Mileage (L/hr)", s: styles.columnHeader },
          { v: "Expected Mileage", s: styles.columnHeader },
          { v: "Efficiency", s: styles.columnHeader },
          { v: "Make", s: styles.columnHeader },
          { v: "Model", s: styles.columnHeader },
          { v: "Total Kms", s: styles.columnHeader },
          { v: "Fuel (L)", s: styles.columnHeader },
          { v: "Amount (₹)", s: styles.columnHeader },
          { v: "Hours", s: styles.columnHeader },
          { v: "Hours", s: styles.columnHeader },
          { v: "Month", s: styles.columnHeader },
          { v: "Year", s: styles.columnHeader },
          { v: "Start Date", s: styles.columnHeader },
          { v: "End Date", s: styles.columnHeader },
          { v: "Provider", s: styles.columnHeader },
          { v: "Permit Number", s: styles.columnHeader },
          { v: "Permit Range", s: styles.columnHeader },
          { v: "Valid Until", s: styles.columnHeader },
          { v: "FC Number", s: styles.columnHeader },
          { v: "FC Valid Until", s: styles.columnHeader },
          { v: "Road Tax Valid Until", s: styles.columnHeader },
        ],
      ];

      const dataRows = data.map((item) => [
        { v: item.projectName || "--", s: styles.cell },
        { v: item.vehicleType || "--", s: styles.cell },
        { v: item.vehicleNumber || "--", s: styles.cell },
        { v: item.chassisNumber || "--", s: styles.cell },
        {
          v: parseFloat(calculateEfficiency(item)) || "",
          s: styles.numericCell,
        },
        { v: getExpectedMileage(item.model) || "--", s: styles.numericCell },
        {
          v:
            parseFloat(
              calculateEfficiencyRatio(
                calculateEfficiency(item),
                getExpectedMileage(item.model)
              )
            ) || "",
          s: styles.numericCell,
        },
        { v: item.make || "--", s: styles.cell },
        { v: item.model || "--", s: styles.cell },
        {
          v:
            item.kmsTravelled && !isNaN(item.kmsTravelled)
              ? parseFloat(item.kmsTravelled).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.totalDieselConsumed && !isNaN(item.totalDieselConsumed)
              ? parseFloat(item.totalDieselConsumed).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.amount && !isNaN(item.amount)
              ? parseFloat(item.amount).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.totalHoursWorked && !isNaN(item.totalHoursWorked)
              ? parseFloat(item.totalHoursWorked).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item?.engine2?.totalHoursWorked &&
            !isNaN(item?.engine2?.totalHoursWorked)
              ? parseFloat(item?.engine2?.totalHoursWorked).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        { v: item.monthOfPurchase || "--", s: styles.cell },
        { v: item.yearOfPurchase || "--", s: styles.cell },
        {
          v: item.insuranceStartDate
            ? new Date(item.insuranceStartDate).toLocaleDateString()
            : "--",
          s: { ...styles.cell, numFmt: "m/dd/yy" },
        },
        {
          v: item.insuranceEndDate
            ? new Date(item.insuranceEndDate).toLocaleDateString()
            : "--",
          s: { ...styles.cell, numFmt: "m/dd/yy" },
        },
        { v: item.insuranceProvider || "--", s: styles.cell },
        { v: item.permitNo || "--", s: styles.cell },
        { v: item.permitRange || "--", s: styles.cell },
        {
          v: item.permitValidUpto
            ? new Date(item.permitValidUpto).toLocaleDateString()
            : "--",
          s: { ...styles.cell, numFmt: "m/dd/yy" },
        },
        { v: item.fitnessCertificateNo || "--", s: styles.cell },
        {
          v: item.fcValidUpto
            ? new Date(item.fcValidUpto).toLocaleDateString()
            : "--",
          s: { ...styles.cell, numFmt: "m/dd/yy" },
        },
        {
          v: item.roadTaxValidUpto
            ? new Date(item.roadTaxValidUpto).toLocaleDateString()
            : "--",
          s: { ...styles.cell, numFmt: "m/dd/yy" },
        },
      ]);

      wsData = [...wsData, ...groupHeaders, ...columnHeaders, ...dataRows];
    } else if (tableName === "Service Schedule") {
      const groupHeaders = [
        [
          { v: "VEHICLE DETAILS", s: styles.sectionHeader },
          "",
          "",
          "",
          "",
          "",
          { v: "SERVICE DETAILS", s: styles.sectionHeader },
          "",
          "",
          "",
          { v: "BREAKDOWN DATA", s: styles.sectionHeader },
          "",
          "",
          { v: "COSTS", s: styles.sectionHeader },
        ],
      ];

      const columnHeaders = [
        [
          { v: "Project Name", s: styles.columnHeader },
          { v: "Chassis Number", s: styles.columnHeader },
          { v: "Type", s: styles.columnHeader },
          { v: "Number", s: styles.columnHeader },
          { v: "Hours", s: styles.columnHeader },
          { v: "Fuel Consumed", s: styles.columnHeader },
          { v: "Next Service In", s: styles.columnHeader },
          { v: "Last Service Date", s: styles.columnHeader },
          { v: "Description", s: styles.columnHeader },
          { v: "Cycle Type", s: styles.columnHeader },
          { v: "Breakdowns", s: styles.columnHeader },
          { v: "Service Cost", s: styles.columnHeader },
          { v: "Breakdown Cost", s: styles.columnHeader },
          { v: "Total Cost", s: styles.columnHeader },
        ],
      ];

      const dataRows = data.map((item) => [
        { v: item.projectName || "--", s: styles.cell },
        { v: item.chassisNumber || "--", s: styles.cell },
        { v: item.type || "--", s: styles.cell },
        { v: item.number || "--", s: styles.cell },
        {
          v:
            item.hours && !isNaN(item.hours)
              ? parseFloat(item.hours).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        {
          v:
            item.fuelConsumed && !isNaN(item.fuelConsumed)
              ? parseFloat(item.fuelConsumed).toFixed(2)
              : "--",
          s: styles.numericCell,
        },
        { v: item.nextServiceIn || "--", s: styles.cell },
        {
          v: new Date(item.lastServiceDate).toLocaleDateString() || "--",
          s: styles.cell,
        },
        { v: item.serviceDescription || "--", s: styles.cell },
        { v: item.cycleType || "--", s: styles.cell },
        {
          v: `${item.numberOfBreakdowns?.total || 0}`,
          s: styles.numericCell,
        },
        {
          v:
            item.totalServiceCost && !isNaN(item.totalServiceCost)
              ? parseFloat(item.totalServiceCost).toFixed(2)
              : "--",
          s: styles.currencyCell,
        },
        {
          v:
            item.totalBreakdownCost && !isNaN(item.totalBreakdownCost)
              ? parseFloat(item.totalBreakdownCost).toFixed(2)
              : "--",
          s: styles.currencyCell,
        },
        {
          v:
            item.totalServiceCost &&
            !isNaN(item.totalServiceCost) &&
            item.totalBreakdownCost &&
            !isNaN(item.totalBreakdownCost)
              ? parseFloat(
                  item.totalServiceCost + item.totalBreakdownCost
                ).toFixed(2)
              : "--",

          s: styles.currencyCell,
        },
      ]);

      wsData = [...wsData, ...groupHeaders, ...columnHeaders, ...dataRows];
    }

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    const columnWidths = {
      "Daily Vehicle Report": [
        { wch: 30 }, // Project Name
        { wch: 15 }, // Date
        { wch: 25 }, // Type
        { wch: 25 }, // Number
        { wch: 30 }, // Chassis Number
        { wch: 18 }, // Start Reading
        { wch: 18 }, // End Reading
        { wch: 15 }, // Hours
        { wch: 18 }, // Engine 2 Start
        { wch: 18 }, // Engine 2 End
        { wch: 15 }, // Engine 2 Hours
        { wch: 18 }, // KMs Start
        { wch: 18 }, // KMs End
        { wch: 15 }, // Distance
        { wch: 15 }, // Fuel
        { wch: 15 }, // Rate
        { wch: 18 }, // Total Amount
        { wch: 40 }, // Purpose
      ],
      "Vehicle Information": [
        { wch: 30 }, // Project Name
        { wch: 20 }, // Vehicle Type
        { wch: 20 }, // Vehicle Number
        { wch: 25 }, // Chassis Number
        { wch: 15 }, // Mileage
        { wch: 15 }, // Expected Mileage
        { wch: 15 }, // Efficiency
        { wch: 15 }, // Make
        { wch: 15 }, // Model
        { wch: 15 }, // Total Kms
        { wch: 15 }, // Fuel
        { wch: 15 }, // Engine 1 Hours
        { wch: 15 }, // Engine 2 Hours
        { wch: 15 }, // Purchase Month
        { wch: 15 }, // Purchase Year
        { wch: 15 }, // Insurance Start
        { wch: 15 }, // Insurance End
        { wch: 25 }, // Insurance Provider
        { wch: 20 }, // Permit Number
        { wch: 20 }, // Permit Range
        { wch: 15 }, // Permit Valid Until
        { wch: 20 }, // FC Number
        { wch: 15 }, // FC Valid Until
        { wch: 15 }, // Road Tax Valid Until
      ],
      "Service Schedule": [
        { wch: 30 }, // Project Name
        { wch: 25 }, // Chassis Number
        { wch: 20 }, // Vehicle Type
        { wch: 20 }, // Vehicle Number
        { wch: 15 }, // Hours Worked
        { wch: 15 }, // Fuel Consumed
        { wch: 15 }, // Next Service In
        { wch: 15 }, // Last Service Date
        { wch: 35 }, // Service Description
        { wch: 15 }, // Service Type
        { wch: 15 }, // Number of Breakdowns
        { wch: 15 }, // Service Cost
        { wch: 15 }, // Breakdown Cost
        { wch: 15 }, // Total Cost
      ],
    };

    ws["!cols"] = columnWidths[tableName];

    const mergeCells = {
      "Daily Vehicle Report": [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 17 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 17 } },
        { s: { r: 4, c: 0 }, e: { r: 4, c: 4 } },
        { s: { r: 4, c: 5 }, e: { r: 4, c: 7 } },
        { s: { r: 4, c: 8 }, e: { r: 4, c: 10 } },
        { s: { r: 4, c: 11 }, e: { r: 4, c: 13 } },
        { s: { r: 4, c: 14 }, e: { r: 4, c: 16 } },
        { s: { r: 4, c: 17 }, e: { r: 4, c: 17 } },
      ],
      "Vehicle Information": [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 24 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 24 } },
        { s: { r: 4, c: 0 }, e: { r: 4, c: 8 } }, // VEHICLE DETAILS
        { s: { r: 4, c: 9 }, e: { r: 4, c: 11 } }, // TOTAL
        { s: { r: 4, c: 12 }, e: { r: 4, c: 12 } }, // ENGINE 1
        { s: { r: 4, c: 13 }, e: { r: 4, c: 13 } }, // ENGINE 2
        { s: { r: 4, c: 14 }, e: { r: 4, c: 15 } }, // PURCHASE
        { s: { r: 4, c: 16 }, e: { r: 4, c: 18 } }, // INSURANCE
        { s: { r: 4, c: 19 }, e: { r: 4, c: 24 } }, // PERMIT & FITNESS
      ],
      "Service Schedule": [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 13 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 13 } },
        { s: { r: 4, c: 0 }, e: { r: 4, c: 5 } },
        { s: { r: 4, c: 6 }, e: { r: 4, c: 9 } },
        { s: { r: 4, c: 10 }, e: { r: 4, c: 11 } },
        { s: { r: 4, c: 12 }, e: { r: 4, c: 13 } },
      ],
    };

    ws["!merges"] = mergeCells[tableName];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, tableName);

    const formattedDate = new Date()
      .toLocaleDateString("en-GB")
      .replace(/\//g, "-");
    const filename = `${tableName}_${formattedDate}.xlsx`;

    XLSX.writeFile(wb, filename);
  };

  const renderExportButton = () => (
    <button
      onClick={() => {
        let data;
        let tableName;

        switch (activeTab) {
          case "daily":
            // Create daily report data directly here instead of using a separate function
            const dailyData = [];

            if (firmData && firmData.projects) {
              Object.keys(firmData.projects).forEach((projectId) => {
                const projectData = projects.find(
                  (p) => p.projectID === projectId
                );
                const projectName = projectData
                  ? projectData.projectName
                  : "--";

                const outwardsData =
                  firmData.projects[projectId]?.records?.allOutward || {};

                Object.values(outwardsData).forEach((outward) => {
                  if (outward?.outwardItems) {
                    Object.values(outward.outwardItems).forEach((item) => {
                      if (item.type === "vehicle" && item.consumption) {
                        Object.entries(item.consumption).forEach(
                          ([timestamp, value]) => {
                            const date = new Date(parseInt(timestamp));

                            if (date >= startDate && date <= endDate) {
                              const hours = parseFloat(value.hours) || 0;
                              const meetsTimeFilter =
                                timeFilter === "all"
                                  ? true
                                  : timeFilter === "< 8 hours"
                                  ? hours < 8
                                  : false;

                              if (meetsTimeFilter) {
                                const dieselConsumed =
                                  parseFloat(value.quantity) || 0;
                                const matchingVehicle = vehiclesData.find(
                                  (v) => v.tag === value.tag
                                );
                                dailyData.push({
                                  projectName: projectName,
                                  type: matchingVehicle?.vehicleType || "--",
                                  number:
                                    matchingVehicle?.vehicleNumber || "--",
                                  chassisNumber:
                                    matchingVehicle?.chassisNumber || "--",
                                  readingType:
                                    matchingVehicle?.readingType || "hrs",
                                  hours: value.hours || "--",
                                  engine2Hours: value?.engine2Hours || "--",
                                  fuelConsumed: dieselConsumed.toFixed(2),
                                  efficiency:
                                    hours > 0
                                      ? (dieselConsumed / hours).toFixed(2)
                                      : "--",
                                  purpose: value.purpose || "--",
                                  date: date,
                                  startReading: value.startReading || "--",
                                  endReading: value.endReading || "--",
                                  engine2StartReading:
                                    value.engine2StartReading || "--",
                                  engine2EndReading:
                                    value.engine2EndReading || "--",
                                  kmsStartReading:
                                    value.kmsStartReading || "--",
                                  kmsEndReading: value.kmsEndReading || "--",
                                  kmsTravelled: value.kmsTravelled || "--",
                                  amount: value.amount || "--",
                                  totalAmount: value.amount
                                    ? (dieselConsumed * value.amount).toFixed(2)
                                    : "--",
                                });
                              }
                            }
                          }
                        );
                      }
                    });
                  }
                });
              });
            }

            // Sort by date descending
            dailyData.sort((a, b) => b.date - a.date);
            data = filterData(dailyData);
            tableName = "Daily Vehicle Report";
            break;

          case "vehicle":
            data = filterData(vehiclesData);
            tableName = "Vehicle Information";
            break;

          case "service":
            data = filterData(maintenanceData);
            tableName = "Service Schedule";
            break;
        }

        exportToExcel(data, tableName);
      }}
      className="export-button"
      style={{
        backgroundColor: "#fdd34d",
        color: "black",
        border: "none",
        borderRadius: "8px",
        padding: "10px 18px",
        fontSize: "14px",
        fontWeight: "500",
        cursor: "pointer",
        transition: "all 0.3s ease",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        textTransform: "uppercase",
        letterSpacing: "0.5px",
        outline: "none",
        transform: "scale(1)",
      }}
      onMouseEnter={(e) => {
        e.target.style.transform = "scale(1.05)";
        e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.15)";
      }}
      onMouseLeave={(e) => {
        e.target.style.transform = "scale(1)";
        e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
      }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2">
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
        <polyline points="7 10 12 15 17 10" />
        <line x1="12" y1="15" x2="12" y2="3" />
      </svg>
      Export to Excel
    </button>
  );
  // State management
  const [selectedChassisNumbers, setSelectedChassisNumbers] = useState([]);
  const filterModalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("daily");
  const [searchQuery, setSearchQuery] = useState("");
  const [timeFilter, setTimeFilter] = useState("all"); // Changed from "< 8 hours" to "all"
  // Replace the initial state setup for dates
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    return sevenDaysAgo;
  });
  const [breakdownModalOpen, setBreakdownModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);
  const [endDate, setEndDate] = useState(() => new Date());
  const [vehiclesData, setVehiclesData] = useState([]);
  const [firmData, setFirmData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [firmDetailsId, setFirmDetailsId] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [selectedBreakdowns, setSelectedBreakdowns] = useState(null);
  const [firmId, setFirmId] = useState("");

  // New states for vehicle management
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [actionLoading, setActionLoading] = useState(false);
  const [consumptionHistoryData, setConsumptionHistoryData] = useState(null);
  // Add these utility functions
  const getUniqueChassisNumbers = () => {
    return [...new Set(vehiclesData.map((item) => item.chassisNumber))].filter(
      Boolean
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterModalRef.current &&
        !filterModalRef.current.contains(event.target)
      ) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterModalRef, setIsFilterOpen]);
  // Vehicles.js
  const handleSubmit = async (vehicleData) => {
    setActionLoading(true);
    console.log("Submitting vehicle data:", vehicleData);
    try {
      // Get the firmDetailsId from the local storage
      const localStorageData = JSON.parse(localStorage.getItem("User"));
      const firmDetailsID = firmDetailsId;

      // Generate timestamp for key
      const timestamp = new Date().getTime().toString(); // Convert to string to ensure consistency

      // Format the data
      const formattedData = {
        ...vehicleData,
        timestamp: timestamp,
        id: timestamp,
        tag: vehicleData.tag,
        transferredFromAnotherSite: false,
        transferred: false,
        sos: false,
      };

      // Clean up unnecessary fields

      // Create an update object
      const updates = {};
      updates[
        `${firmDetailsID}/${vehicleData.projectId}/vehicles/${timestamp}`
      ] = formattedData;

      // Update database
      await secondDatabase.ref().update(updates);

      setSnackbar({
        open: true,
        message: "Vehicle added successfully",
        severity: "success",
      });

      setIsAddModalOpen(false);
      fetchVehicles();
    } catch (error) {
      console.error("Error adding vehicle:", error);
      setSnackbar({
        open: true,
        message: `Error adding vehicle: ${error.message}`,
        severity: "error",
      });
    }
    setActionLoading(false);
  };
  // In Vehicles.js
  const [isConsumptionModalOpen, setIsConsumptionModalOpen] = useState(false);

  const handleAddConsumption = async (consumptionData) => {
    setActionLoading(true);
    try {
      if (!consumptionData.itemKey) {
        throw new Error("No valid outward item found for this vehicle");
      }

      const path = `${firmDetailsId}/projects/${consumptionData.projectId}/records/allOutward/${consumptionData.entryId}/outwardItems/${consumptionData.itemKey}/consumption/${consumptionData.timestamp}`;

      const entryData = {
        itemKey: consumptionData.itemKey,
        purpose: consumptionData.purpose,
        quantity: consumptionData.quantity,
        tag: consumptionData.tag,
        timestamp: consumptionData.timestamp,
        entryId: consumptionData.entryId,
        amount: consumptionData.amount,
        vehicleId: consumptionData.vehicleId,
        startReading: consumptionData.startReading || "",
        endReading: consumptionData.endReading || "",
        hours: consumptionData.hours || "0",
        engine2StartReading: consumptionData.engine2StartReading || "",
        engine2EndReading: consumptionData.engine2EndReading || "",
        engine2Hours: consumptionData.engine2Hours || "0",
        kmsStartReading: consumptionData.kmsStartReading || "",
        kmsEndReading: consumptionData.kmsEndReading || "",
        kmsTravelled: consumptionData.kmsTravelled || "0",
      };

      await secondDatabase.ref(path).set(entryData);
      await updateVehicleInformation(consumptionData, false);

      setSnackbar({
        open: true,
        message: "Vehicle usage added successfully",
        severity: "success",
      });

      fetchVehicles();
    } catch (error) {
      console.error("Error adding consumption:", error);
      setSnackbar({
        open: true,
        message: `Error adding usage: ${error.message}`,
        severity: "error",
      });
    }
    setActionLoading(false);
  };
  const updateVehicleInformation = async (
    consumptionData,
    isEditing = false
  ) => {
    try {
      const vehicleByTag = vehiclesData.find(
        (v) => v.tag === consumptionData.tag
      );
      if (!vehicleByTag) {
        console.error("Vehicle not found with tag:", consumptionData.tag);
        return false;
      }

      const updates = {};
      const vehiclePath = `${firmDetailsId}/${vehicleByTag.projectId}/vehicles/${vehicleByTag.id}`;

      // Calculate values for update
      const hoursWorked = parseFloat(consumptionData.hours) || 0;
      const kmsTravelled = parseFloat(consumptionData.kmsTravelled) || 0;
      const dieselConsumed = parseFloat(consumptionData.quantity) || 0;
      const engine2Hours = parseFloat(consumptionData.engine2Hours) || 0;

      // For editing, subtract old amount and add new amount
      // For new entries, just add the new amount
      let amountChange;
      if (isEditing) {
        // oldAmount and newAmount are passed from handleEditConsumption
        amountChange = consumptionData.newAmount - consumptionData.oldAmount;
      } else {
        amountChange = dieselConsumed * parseFloat(consumptionData.amount) || 0;
      }

      // Create updated vehicle object
      const updatedVehicle = {
        ...vehicleByTag,
        totalHoursWorked:
          (parseFloat(vehicleByTag.totalHoursWorked) || 0) + hoursWorked,
        totalKilometers:
          (parseFloat(vehicleByTag.totalKilometers) || 0) + kmsTravelled,
        totalDieselConsumed:
          (parseFloat(vehicleByTag.totalDieselConsumed) || 0) + dieselConsumed,
        amount: (parseFloat(vehicleByTag.amount) || 0) + amountChange,
      };

      // Handle engine2 if it exists
      if (vehicleByTag.engine2 && engine2Hours > 0) {
        updatedVehicle.engine2 = {
          ...vehicleByTag.engine2,
          totalHoursWorked:
            (parseFloat(vehicleByTag.engine2?.totalHoursWorked) || 0) +
            engine2Hours,
          totalDieselConsumed:
            (parseFloat(vehicleByTag.engine2?.totalDieselConsumed) || 0) +
            dieselConsumed,
        };
      }

      updates[vehiclePath] = updatedVehicle;
      await secondDatabase.ref().update(updates);
      return true;
    } catch (error) {
      console.error("Error updating vehicle information:", error);
      return false;
    }
  };

  const handleEditConsumption = async (newEntry) => {
    setActionLoading(true);
    try {
      const path = `${firmDetailsId}/projects/${newEntry.projectId}/records/allOutward/${newEntry.entryId}/outwardItems/${newEntry.itemKey}/consumption/${newEntry.timestamp}`;

      // Fetch old entry to calculate differences
      const oldEntrySnapshot = await secondDatabase.ref(path).once("value");
      const oldEntry = oldEntrySnapshot.val();

      // Calculate old and new amounts
      const oldAmount =
        parseFloat(oldEntry.quantity || 0) * parseFloat(oldEntry.amount || 0);
      const newAmount =
        parseFloat(newEntry.quantity || 0) * parseFloat(newEntry.amount || 0);

      const diffData = {
        ...newEntry,
        hours:
          parseFloat(newEntry.hours || 0) - parseFloat(oldEntry.hours || 0),
        kmsTravelled:
          parseFloat(newEntry.kmsTravelled || 0) -
          parseFloat(oldEntry.kmsTravelled || 0),
        quantity:
          parseFloat(newEntry.quantity || 0) -
          parseFloat(oldEntry.quantity || 0),
        engine2Hours:
          parseFloat(newEntry.engine2Hours || 0) -
          parseFloat(oldEntry.engine2Hours || 0),
        oldAmount: oldAmount,
        newAmount: newAmount,
      };

      // Update consumption entry
      await secondDatabase.ref(path).set(newEntry);

      // Update vehicle information with differences
      await updateVehicleInformation(diffData, true);

      setSnackbar({
        open: true,
        message: "Entry updated successfully",
        severity: "success",
      });

      fetchVehicles();
    } catch (error) {
      console.error("Error updating entry:", error);
      setSnackbar({
        open: true,
        message: "Error updating entry",
        severity: "error",
      });
    }
    setActionLoading(false);
  };
  const handleDeleteConsumption = async (entry) => {
    try {
      const path = `${firmDetailsId}/projects/${entry.projectId}/records/allOutward/${entry.entryId}/outwardItems/${entry.itemKey}/consumption/${entry.timestamp}`;

      // Create negative values for updating vehicle information
      const deleteData = {
        ...entry,
        hours: -parseFloat(entry.hours || 0),
        kmsTravelled: -parseFloat(entry.kmsTravelled || 0),
        quantity: -parseFloat(entry.quantity || 0),
        engine2Hours: -parseFloat(entry.engine2Hours || 0),
      };

      // Update vehicle information first
      await updateVehicleInformation(deleteData, true);

      // Then delete the consumption entry
      await secondDatabase.ref(path).remove();

      setSnackbar({
        open: true,
        message: "Entry deleted successfully",
        severity: "success",
      });

      fetchVehicles();
    } catch (error) {
      console.error("Error deleting entry:", error);
      setSnackbar({
        open: true,
        message: "Error deleting entry",
        severity: "error",
      });
    }
  };

  const handleEdit = async (vehicleData) => {
    setActionLoading(true);
    console.log("Editing vehicle data:", vehicleData);
    try {
      const localStorageData = JSON.parse(localStorage.getItem("User"));
      const firmDetailsID = firmDetailsId;

      // Get the original timestamp that serves as the vehicle's key
      const timestamp = selectedVehicle.id;

      // Format the data while preserving the original timestamp and status
      const formattedData = {
        ...vehicleData,
        id: timestamp, // Keep the original ID
        timestamp: timestamp, // Keep the original timestamp
        transferredFromAnotherSite:
          selectedVehicle.transferredFromAnotherSite || false,
        transferred: selectedVehicle.transferred || false,
        sos: selectedVehicle.sos || false,
        projectId: selectedVehicle.projectId, // Keep the original project ID for editing
      };

      // Reference the exact vehicle node using the timestamp
      const vehicleRef = secondDatabase.ref(
        `${firmDetailsID}/${selectedVehicle.projectId}/vehicles/${timestamp}`
      );

      // Update the existing vehicle data
      await vehicleRef.update(formattedData);

      setSnackbar({
        open: true,
        message: "Vehicle updated successfully",
        severity: "success",
      });

      setIsEditModalOpen(false);
      setSelectedVehicle(null);
      fetchVehicles(); // Refresh the list
    } catch (error) {
      console.error("Error updating vehicle:", error);
      setSnackbar({
        open: true,
        message: `Error updating vehicle: ${error.message}`,
        severity: "error",
      });
    }
    setActionLoading(false);
  };

  const handleTransfer = async (transferData) => {
    setActionLoading(true);
    try {
      const localStorageData = JSON.parse(localStorage.getItem("User"));
      const firmDetailsID = firmDetailsId;

      // Get current timestamp as string
      const timestamp = selectedVehicle.id;

      // Create the vehicle data for the new project
      const vehicleData = {
        ...selectedVehicle,
        timestamp: timestamp,
        id: timestamp,
        transferredFromAnotherSite: true,
        transferred: false,
        transferredFrom: selectedVehicle.projectId,
        transferTimestamp: new Date().getTime().toString(),
        transferNotes: transferData.notes,
        projectId: transferData.destinationProjectId,
      };

      const tempVehicleData = {
        ...selectedVehicle,
        transferred: true,
        id: timestamp,
        transferredTo: transferData.destinationProjectId,
        transferTimestamp: new Date().getTime().toString(),
        transferNotes: transferData.notes,
      };

      // Create updates object for atomic operation
      const updates = {};

      // Add transferred to current project
      updates[
        `${firmDetailsID}/${selectedVehicle.projectId}/vehicles/${timestamp}`
      ] = tempVehicleData;

      // Add to new project with same timestamp key
      updates[
        `${firmDetailsID}/${transferData.destinationProjectId}/vehicles/${timestamp}`
      ] = vehicleData;

      // Execute both operations atomically
      await secondDatabase.ref().update(updates);

      setSnackbar({
        open: true,
        message: "Vehicle transferred successfully",
        severity: "success",
      });

      setIsTransferModalOpen(false);
      setSelectedVehicle(null);
      fetchVehicles();
    } catch (error) {
      console.error("Error transferring vehicle:", error);
      setSnackbar({
        open: true,
        message: `Error transferring vehicle: ${error.message}`,
        severity: "error",
      });
    }
    setActionLoading(false);
  };

  // Add management buttons to your existing render method

  const handleOpenBreakdownModal = (breakdownHistory) => {
    setSelectedBreakdowns(breakdownHistory);
    setBreakdownModalOpen(true);
  };
  const getUniqueProjects = () => {
    return [...new Set(vehiclesData.map((item) => item.projectName))].filter(
      Boolean
    );
  };
  const renderServiceDescription = (description, additionalCount, item) => {
    if (description === "--") return highlightText("--", searchQuery);

    return (
      <div
        className="description-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div>
          {highlightText(description, searchQuery)}
          {additionalCount > 0 && (
            <span
              style={{
                color: "#333",
                fontSize: "12px",
                fontWeight: 500,
                marginLeft: "5px",
                backgroundColor: "#fdd34d",
                padding: "2px 5px",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              +{additionalCount}
            </span>
          )}
        </div>
        <>
          <IconButton
            size="small"
            onClick={() => {
              console.log("Opening modal for item:", item);
              setSelectedItem(item.maintenanceSchedule);
              setMaintenanceModalOpen(true);
            }}
            sx={{ padding: "4px" }}>
            <ExpandMoreIcon fontSize="small" />
          </IconButton>
        </>
      </div>
    );
  };

  // Add this before the return statement
  const renderMaintenanceModal = () => {
    if (maintenanceModalOpen && selectedItem) {
      return (
        <MaintenanceModal
          maintenanceSchedule={selectedItem}
          onClose={() => {
            setMaintenanceModalOpen(false);
            setSelectedItem(null);
          }}
        />
      );
    }
    return null;
  };

  const getUniqueVehicleTypes = () => {
    return [
      ...new Set(vehiclesData.map((item) => item.vehicleType || item.type)),
    ].filter(Boolean);
  };
  // Fetch data from firebase from secondDatabase
  const LoadingUI = () => (
    <div
      className="loading-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "60vh",
        gap: "20px",
      }}>
      <div
        className="loading-spinner"
        style={{
          width: "40px",
          height: "40px",
          border: "3px solid #f3f3f3",
          borderTop: "3px solid #fdd34d",
          borderRadius: "50%",
          animation: "spin 1s linear infinite",
        }}></div>
      <div
        className="loading-text"
        style={{
          fontSize: "16px",
          color: "#666",
          fontWeight: 500,
        }}>
        Loading vehicle data...
      </div>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );

  const fetchVehicles = useCallback(async () => {
    setIsLoading(true);
    try {
      // Use Promise.all for concurrent fetches
      const useRef = secondDatabase.ref(`userDetails/${firmId}`);
      const [userSnapshot] = await Promise.all([useRef.once("value")]);

      const userData = userSnapshot.val();
      if (!userData) {
        setProjects([]);
        setVehiclesData([]);
        return;
      }

      const { firmDetailsID } = userData.personalDetails.firmDetails;
      const projects = Object.values(userData.projects);
      setProjects(projects);

      // Fetch firm data only once
      const firmRef = secondDatabase.ref(`${firmDetailsID}`);
      const [firmSnapshot] = await Promise.all([firmRef.once("value")]);
      const firmData = firmSnapshot.val();

      if (!firmData) {
        setVehiclesData([]);
        return;
      }

      // Use reduce for more efficient vehicle gathering
      const allVehicles = projects.reduce((acc, project) => {
        const projectData = firmData[project.projectID];
        if (projectData?.vehicles) {
          const projectVehicles = Object.entries(projectData.vehicles).map(
            ([id, vehicle]) => ({
              ...vehicle,
              id,
              projectId: project.projectID,
              projectName: project.projectName,
            })
          );
          return [...acc, ...projectVehicles];
        }
        return acc;
      }, []);

      setVehiclesData(allVehicles);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      setSnackbar({
        open: true,
        message: "Error fetching vehicles data",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, [firmId]);

  // Add this useEffect to fetch vehicles when firmId changes
  useEffect(() => {
    if (firmId) {
      fetchVehicles();
    }
  }, [firmId]);

  // In your Vehicles.js component, modify the code to:
  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching data");
      setIsLoading(true);
      try {
        const useRef = secondDatabase.ref(`userDetails/${firmId}`);

        useRef.on("value", function (snapshot) {
          var data = snapshot.val();
          console.log("data", data);
          if (data !== null) {
            setFirmDetailsId(data.personalDetails.firmDetails.firmDetailsID);
            setProjects(Object.values(data.projects));

            const firmUseRef = secondDatabase.ref(
              `${data.personalDetails.firmDetails.firmDetailsID}`
            );

            firmUseRef.on("value", function (snapshot) {
              var firmData = snapshot.val();
              if (firmData !== null) {
                setFirmData(firmData);

                let latestDate = new Date(0); // Initialize with oldest possible date
                Object.values(firmData.projects || {}).forEach((project) => {
                  const outwardsData = project?.records?.allOutward || {};
                  Object.values(outwardsData).forEach((outward) => {
                    if (outward?.outwardItems) {
                      Object.values(outward.outwardItems).forEach((item) => {
                        if (item.type === "vehicle" && item.consumption) {
                          Object.keys(item.consumption).forEach((timestamp) => {
                            const date = new Date(parseInt(timestamp));
                            if (date > latestDate) {
                              latestDate = date;
                            }
                          });
                        }
                      });
                    }
                  });
                });

                // If we found data, set the dates
                if (latestDate.getTime() !== 0) {
                  const sevenDaysBefore = new Date(latestDate);
                  sevenDaysBefore.setDate(latestDate.getDate() - 7);
                  setEndDate(latestDate);
                  setStartDate(sevenDaysBefore);
                }

                // Get vehicles data from all projects
                let allVehicles = [];
                Object.values(data.projects).forEach((project) => {
                  const projectData = firmData[project.projectID];
                  if (projectData && projectData.vehicles) {
                    allVehicles = [
                      ...allVehicles,
                      ...Object.values(projectData.vehicles),
                    ];
                  }
                });

                if (allVehicles.length > 0) {
                  setVehiclesData(allVehicles);
                  const processedMaintenanceData = allVehicles.map(
                    (vehicle) => {
                      const maintenanceSchedule =
                        vehicle.maintenanceSchedule || [];
                      const breakdownHistory = vehicle.breakdownHistory || {};

                      // Calculate breakdown stats
                      const breakdownStats = Object.values(
                        breakdownHistory
                      ).reduce(
                        (stats, breakdown) => ({
                          totalCost:
                            stats.totalCost + (breakdown.repairCost || 0),
                          count: stats.count + 1,
                          repairedCount:
                            stats.repairedCount +
                            (breakdown.alreadyRepaired ? 1 : 0),
                        }),
                        { totalCost: 0, count: 0, repairedCount: 0 }
                      );

                      // Process service history
                      let totalServiceCost = 0;
                      let allServiceHistories = [];

                      maintenanceSchedule.forEach((schedule) => {
                        const serviceHistory = schedule.serviceHistory || {};
                        Object.values(serviceHistory).forEach((service) => {
                          allServiceHistories.push({
                            date: service.date,
                            description: service.description,
                            amount: service.amount || 0,
                          });
                          totalServiceCost += service.amount || 0;
                        });
                      });

                      // Sort service histories by date (most recent first)
                      allServiceHistories.sort((a, b) => b.date - a.date);

                      const serviceDescription =
                        allServiceHistories.length > 0
                          ? allServiceHistories[0].description
                          : "--";
                      const additionalServicesCount =
                        allServiceHistories.length > 0
                          ? allServiceHistories.length - 1
                          : 0;

                      // Calculate next service details based on cycle type
                      const calculateNextService = () => {
                        const currentSchedule = maintenanceSchedule[0];
                        if (!currentSchedule)
                          return { nextService: "--", showAlert: false };

                        const cycle = parseFloat(currentSchedule.cycle) || 0;
                        const cycleType = currentSchedule.cycleType;
                        const lastServiceDate = allServiceHistories[0]?.date
                          ? new Date(allServiceHistories[0].date)
                          : new Date(maintenanceSchedule[0].lastService);

                        switch (cycleType) {
                          case "hours":
                            const totalHours =
                              parseFloat(vehicle.totalHoursWorked) || 0;
                            // Calculate remaining hours until next service based on cycle
                            const remainingHours = cycle - (totalHours % cycle);
                            // Alert if within 50 hours of next service
                            const showHoursAlert = remainingHours <= 50;

                            return {
                              nextService: `${Math.max(
                                0,
                                remainingHours
                              ).toFixed(2)} hrs`,
                              showAlert: showHoursAlert,
                              cycleType: "hrs",
                            };

                          case "km":
                            const totalKm =
                              parseFloat(vehicle.totalKilometers) || 0;
                            // Calculate remaining km until next service based on cycle
                            const remainingKm = cycle - (totalKm % cycle);
                            // Alert if within 500 km of next service
                            const showKmAlert = remainingKm <= 500;

                            return {
                              nextService: `${Math.max(0, remainingKm).toFixed(
                                2
                              )} km`,
                              showAlert: showKmAlert,
                              cycleType: "km",
                            };

                          case "days":
                            if (!lastServiceDate)
                              return {
                                nextService: "--",
                                showAlert: false,
                                cycleType: "days",
                              };

                            const today = new Date();
                            const daysSinceService = Math.floor(
                              (today - lastServiceDate) / (1000 * 60 * 60 * 24)
                            );
                            const remainingDays =
                              cycle - (daysSinceService % cycle);

                            // Alert if within 7 days of next service
                            const showDaysAlert = remainingDays <= 7;

                            return {
                              nextService: `${Math.max(0, remainingDays)} days`,
                              showAlert: showDaysAlert,
                              cycleType: "days",
                            };

                          default:
                            return {
                              nextService: "--",
                              showAlert: false,
                              cycleType: null,
                            };
                        }
                      };

                      const nextServiceDetails = calculateNextService();

                      return {
                        projectName: vehicle.projectName,
                        chassisNumber: vehicle.chassisNumber,
                        type: vehicle.vehicleType,
                        number: vehicle.vehicleNumber,
                        hours: vehicle.totalHoursWorked,
                        fuelConsumed: vehicle.totalDieselConsumed,
                        efficiency: calculateEfficiency(vehicle),
                        maintenanceSchedule: maintenanceSchedule,
                        nextServiceIn: nextServiceDetails.nextService,
                        showServiceAlert: nextServiceDetails.showAlert,
                        serviceCycleType: nextServiceDetails.cycleType,
                        lastServiceDate: allServiceHistories[0]?.date
                          ? new Date(
                              allServiceHistories[0].date
                            ).toLocaleDateString()
                          : maintenanceSchedule[0]?.lastService
                          ? new Date(
                              maintenanceSchedule[0]?.lastService
                            ).toLocaleDateString()
                          : "--",
                        serviceDescription: serviceDescription,
                        additionalServicesCount: additionalServicesCount,
                        cycleType: maintenanceSchedule[0]?.cycleType || "--",
                        totalServiceCost: totalServiceCost,
                        engine2: vehicle.engine2 || null,
                        numberOfBreakdowns: {
                          total: breakdownStats.count,
                          repaired: breakdownStats.repairedCount,
                          pending:
                            breakdownStats.count - breakdownStats.repairedCount,
                        },
                        totalBreakdownCost: breakdownStats.totalCost,
                      };
                    }
                  );

                  setMaintenanceData(processedMaintenanceData);
                }
                setIsLoading(false);
              } else {
                setFirmData([]);
                setIsLoading(false);
              }
            });
          } else {
            setProjects([]);
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    if (firmId) {
      fetchData();
    }
  }, [firmId]);

  // Utility function to highlight searched text
  const highlightText = (text, searchQuery) => {
    if (!searchQuery) return text;
    const parts = String(text).split(new RegExp(`(${searchQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "#fdd34d" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Filter function for search
  const filterData = (data) => {
    let filteredData = data;

    // Apply project filter
    if (selectedProjects.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedProjects.includes(item.projectName)
      );
    }

    // Apply vehicle type filter
    if (selectedVehicleTypes.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedVehicleTypes.includes(item.vehicleType || item.type)
      );
    }

    // Apply chassis number filter
    if (selectedChassisNumbers.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedChassisNumbers.includes(item.chassisNumber)
      );
    }

    // Apply search query
    if (searchQuery) {
      filteredData = filteredData.filter((item) => {
        const searchInObject = (obj) => {
          return Object.entries(obj).some(([key, value]) => {
            if (value === null || value === undefined) return false;
            if (typeof value === "object") return searchInObject(value);
            return String(value)
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          });
        };
        return searchInObject(item);
      });
    }

    return filteredData;
  };

  // setConsumptionHistoryData(dailyData);
  useEffect(() => {
    if (firmData && firmData.projects) {
      const dailyData = [];

      Object.keys(firmData.projects).forEach((projectId) => {
        const projectData = projects.find((p) => p.projectID === projectId);
        const projectName = projectData ? projectData.projectName : "--";

        const outwardsData =
          firmData.projects[projectId]?.records?.allOutward || {};

        Object.values(outwardsData).forEach((outward) => {
          if (outward?.outwardItems) {
            Object.values(outward.outwardItems).forEach((item) => {
              if (item.type === "vehicle" && item.consumption) {
                Object.entries(item.consumption).forEach(
                  ([timestamp, value]) => {
                    const date = new Date(parseInt(timestamp));

                    if (date >= startDate && date <= endDate) {
                      const hours = parseFloat(value.hours) || 0;
                      const meetsTimeFilter =
                        timeFilter === "all"
                          ? true
                          : timeFilter === "< 8 hours"
                          ? hours < 8
                          : false;

                      if (meetsTimeFilter) {
                        const dieselConsumed = parseFloat(value.quantity) || 0;
                        const matchingVehicle = vehiclesData.find(
                          (v) => v.tag === value.tag
                        );
                        dailyData.push({
                          tag: value.tag,
                          projectName: projectName,
                          type: matchingVehicle?.vehicleType || "--",
                          number: matchingVehicle?.vehicleNumber || "--",
                          chassisNumber: matchingVehicle?.chassisNumber || "--",
                          readingType: matchingVehicle?.readingType || "hrs",
                          hours: value.hours || "--",
                          engine2Hours: value.engine2Hours || "--",
                          fuelConsumed: dieselConsumed.toFixed(2),
                          efficiency:
                            hours > 0
                              ? (dieselConsumed / hours).toFixed(2)
                              : "--",
                          purpose: value.purpose || "--",
                          date: date,
                          startReading: value.startReading || "--",
                          endReading: value.endReading || "--",
                          engine2StartReading:
                            value.engine2StartReading || "--",
                          engine2EndReading: value.engine2EndReading || "--",
                          kmsStartReading: value.kmsStartReading || "--",
                          kmsEndReading: value.kmsEndReading || "--",
                          kmsTravelled: value.kmsTravelled || "--",
                          amount: value.amount || "--",
                          totalAmount: value.amount
                            ? (dieselConsumed * value.amount).toFixed(2)
                            : "--",
                        });
                      }
                    }
                  }
                );
              }
            });
          }
        });
      });

      dailyData.sort((a, b) => b.date - a.date);
      setConsumptionHistoryData(dailyData);
    }
  }, [firmData, projects, vehiclesData, startDate, endDate, timeFilter]);

  const renderDailyReportTable = () => {
    const dailyData = [];

    if (firmData && firmData.projects) {
      Object.keys(firmData.projects).forEach((projectId) => {
        const projectData = projects.find((p) => p.projectID === projectId);
        const projectName = projectData ? projectData.projectName : "--";

        const outwardsData =
          firmData.projects[projectId]?.records?.allOutward || {};

        Object.values(outwardsData).forEach((outward) => {
          if (outward?.outwardItems) {
            Object.values(outward.outwardItems).forEach((item) => {
              if (item.type === "vehicle" && item.consumption) {
                Object.entries(item.consumption).forEach(
                  ([timestamp, value]) => {
                    const date = new Date(parseInt(timestamp));

                    if (date >= startDate && date <= endDate) {
                      const hours = parseFloat(value.hours) || 0;
                      const meetsTimeFilter =
                        timeFilter === "all"
                          ? true
                          : timeFilter === "< 8 hours"
                          ? hours < 8
                          : false;

                      if (meetsTimeFilter) {
                        const dieselConsumed = parseFloat(value.quantity) || 0;
                        const matchingVehicle = vehiclesData.find(
                          (v) => v.tag === value.tag
                        );
                        dailyData.push({
                          tag: value.tag,
                          projectName: projectName,
                          type: matchingVehicle?.vehicleType || "--",
                          number: matchingVehicle?.vehicleNumber || "--",
                          chassisNumber: matchingVehicle?.chassisNumber || "--",
                          readingType: matchingVehicle?.readingType || "hrs",
                          hours: value.hours || "--",
                          engine2Hours: value.engine2Hours || "--",
                          fuelConsumed: dieselConsumed.toFixed(2),
                          efficiency:
                            hours > 0
                              ? (dieselConsumed / hours).toFixed(2)
                              : "--",
                          purpose: value.purpose || "--",
                          date: date,
                          startReading: value.startReading || "--",
                          endReading: value.endReading || "--",
                          engine2StartReading:
                            value.engine2StartReading || "--",
                          engine2EndReading: value.engine2EndReading || "--",
                          kmsStartReading: value.kmsStartReading || "--",
                          kmsEndReading: value.kmsEndReading || "--",
                          kmsTravelled: value.kmsTravelled || "--",
                          amount: value.amount || "--",
                          totalAmount: value.amount
                            ? (dieselConsumed * value.amount).toFixed(2)
                            : "--",
                        });
                      }
                    }
                  }
                );
              }
            });
          }
        });
      });
    }

    dailyData.sort((a, b) => b.date - a.date);

    return (
      <div className="table-wrapper">
        <div className="table-scroll-container">
          <table>
            <thead>
              <tr>
                <th>Project name</th>
                <th>Date</th>
                <th>Type</th>
                <th>Number</th>
                <th>Chassis number</th>
                <th>Start Reading</th>
                <th>End Reading</th>
                <th>Engine 1 Hours</th>
                <th>Engine 2 Start Reading</th>
                <th>Engine 2 End Reading</th>
                <th>Engine 2 Hours</th>
                <th>Opening Kms</th>
                <th>Closing Kms</th>
                <th>Kms Travelled</th>
                <th>Fuel consumed</th>
                <th>Amount(Per L)</th>
                <th>Total Amount</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              {filterData(dailyData).map((item, index) => (
                <tr key={index}>
                  <td>{highlightText(item.projectName, searchQuery)}</td>
                  <td>
                    {highlightText(item.date.toLocaleDateString(), searchQuery)}
                  </td>
                  <td>{highlightText(item.type, searchQuery)}</td>
                  <td>{highlightText(item.number, searchQuery)}</td>
                  <td>{highlightText(item.chassisNumber, searchQuery)}</td>
                  <td>{highlightText(item.startReading, searchQuery)}</td>
                  <td>{highlightText(item.endReading, searchQuery)}</td>
                  <td>{highlightText(item.hours, searchQuery)}</td>
                  <td>
                    {highlightText(item.engine2StartReading, searchQuery)}
                  </td>
                  <td>{highlightText(item.engine2EndReading, searchQuery)}</td>
                  <td>{highlightText(item.engine2Hours, searchQuery)}</td>
                  <td>{highlightText(item.kmsStartReading, searchQuery)}</td>
                  <td>{highlightText(item.kmsEndReading, searchQuery)}</td>
                  <td>{highlightText(item.kmsTravelled, searchQuery)}</td>
                  <td>{highlightText(item.fuelConsumed, searchQuery)}</td>
                  <td className="tdClass">
                    {highlightText(item.amount, searchQuery)}
                  </td>
                  <td className="tdClass">
                    {highlightText(item.totalAmount, searchQuery)}
                  </td>
                  <td className="tdClass">
                    {highlightText(item.purpose, searchQuery)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  const calculateEfficiency = (vehicle) => {
    // Handle case where vehicle is undefined or null
    if (!vehicle) return "--";

    let engine1Efficiency = 0;
    let totalEfficiency = 0;

    const totalDieselConsumed = parseFloat(vehicle.totalDieselConsumed) || 0;
    const totalHoursWorked = parseFloat(vehicle.totalHoursWorked) || 0;

    if (totalDieselConsumed === 0 || totalHoursWorked === 0) {
      engine1Efficiency = 0;
    } else {
      engine1Efficiency = totalDieselConsumed / totalHoursWorked;
    }

    // Check if engine2 exists and has data
    if (
      vehicle.engine2 &&
      (vehicle.engine2.totalDieselConsumed || vehicle.engine2.totalHoursWorked)
    ) {
      let engine2Efficiency = 0;
      const engine2DieselConsumed =
        parseFloat(vehicle.engine2.totalDieselConsumed) || 0;
      const engine2HoursWorked =
        parseFloat(vehicle.engine2.totalHoursWorked) || 0;

      if (engine2DieselConsumed === 0 || engine2HoursWorked === 0) {
        engine2Efficiency = 0;
      } else {
        engine2Efficiency = engine2DieselConsumed / engine2HoursWorked;
      }

      // Calculate average efficiency
      totalEfficiency = (engine1Efficiency + engine2Efficiency) / 2;

      // If both efficiencies are 0, return '--'
      if (totalEfficiency === 0) {
        return "--";
      }

      return `${totalEfficiency.toFixed(2)}`;
    } else {
      // If only engine1 exists but efficiency is 0, return '--'
      if (engine1Efficiency === 0) {
        return "--";
      }
      return `${engine1Efficiency.toFixed(2)}`;
    }
  };

  const getExpectedMileage = (model) => {
    // Map vehicle models to their expected mileage from PDF
    const mileageMap = {
      "JCB 130": "6-7", // EXCAVATOR
      "JCB 3DX": "4-5", // BUCKET LOADER
      "JCB VM117": "7-8", // SOIL COMPACTOR
      "JCB VMT330": "4", // MINI SOIL COMPACTOR
      SLM4600: "7-8", // CONCRETE MIXER SCHWING STETTER
      "DBX 5000": "--", // CONCRETE MIXER FIORI (no data in PDF)
      "ARGO 4800": "8-10", // CONCRETE MIXER AJAX
      "MA1920/36 T": "3", // TIPPER ASHOK LEYLAND
      "5042D": "4-5", // TRACTOR JOHN DEERE
      "5042D V3": "4-5", // TRACTOR JOHN DEERE variant
      "5310 V1 GEARPRO": "5-6", // TRACTOR JOHN DEERE
      "5310E": "5-6", // TRACTOR JOHN DEERE variant
      "5310 V1": "5-6", // TRACTOR JOHN DEERE variant
      "5310 V16": "5-6", // TRACTOR JOHN DEERE variant
      "14XW": "3.25", // HYDRA CRANE ACE
    };

    // For handling model variants
    const baseModel = Object.keys(mileageMap).find((key) =>
      model?.toUpperCase().includes(key)
    );

    return baseModel ? mileageMap[baseModel] : "--";
  };

  const calculateEfficiencyRatio = (actual, expected) => {
    if (actual === "--" || expected === "--") return "--";

    // For ranges like '6-7', use the average
    const getNumericValue = (value) => {
      if (value.includes("-")) {
        const [min, max] = value.split("-").map(Number);
        return (min + max) / 2;
      }
      return Number(value);
    };

    const actualValue = getNumericValue(actual);
    const expectedValue = getNumericValue(expected);

    if (isNaN(actualValue) || isNaN(expectedValue) || expectedValue === 0)
      return "--";

    const ratio = actualValue / expectedValue;
    return ratio.toFixed(2);
  };

  const renderVehicleInformationTable = () => (
    <div className="table-wrapper">
      <div className="table-scroll-container">
        <table>
          <thead>
            <tr>
              <th colSpan="1" className="group-header">
                Actions
              </th>
              <th colSpan="12" className="group-header">
                Vehicle
              </th>
              <th colSpan="1" className="group-header">
                Engine-1
              </th>
              <th colSpan="1" className="group-header">
                Engine-2
              </th>
              <th colSpan="2" className="group-header">
                Purchase Information
              </th>
              <th colSpan="3" className="group-header">
                Insurance
              </th>
              <th colSpan="6" className="group-header">
                Permit and Fitness
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Project name</th>
              <th>Type</th>
              <th>Number</th>
              <th>Chassis number</th>
              <th>Mileage (L/hr)</th>
              <th>Expected Mileage (L/hr)</th>
              <th>Efficiency</th>
              <th>Make</th>
              <th>Model</th>
              <th>Kms</th>
              <th>Fuel consumed</th>
              <th>Amount</th>
              <th>Hours</th>
              <th>Hours</th>
              <th>Month</th>
              <th>Year</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Provider</th>
              <th>Permit number</th>
              <th>Permit range</th>
              <th>Valid upto</th>
              <th>Fitness certificate no</th>
              <th>FC valid upto</th>
              <th>Road tax valid upto</th>
            </tr>
          </thead>
          <tbody>
            {filterData(vehiclesData).map((item, index) => (
              <tr
                key={index}
                //If transfeered bg color should be light red or else even number rows should be fafafa
                style={{
                  backgroundColor: item.transferred
                    ? "#FFCCEA"
                    : index % 2 === 0
                    ? "#fafafa"
                    : "white",
                }}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      justifyContent: "center",
                    }}>
                    <Tooltip
                      title={
                        item.transferred
                          ? "Transferred vehicles cannot be edited"
                          : "Edit vehicle"
                      }>
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedVehicle(item);
                            setIsEditModalOpen(true);
                          }}
                          disabled={item.transferred}
                          sx={{
                            color: item.transferred
                              ? "grey.400"
                              : "primary.main",
                            "&:hover": {
                              backgroundColor: item.transferred
                                ? "transparent"
                                : "rgba(0, 123, 255, 0.04)",
                            },
                          }}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip
                      title={
                        item.transferred
                          ? "Vehicle already transferred"
                          : item.sos
                          ? "Vehicle under maintenance cannot be transferred"
                          : "Transfer vehicle"
                      }>
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedVehicle(item);
                            setIsTransferModalOpen(true);
                          }}
                          disabled={item.transferred || item.sos}
                          sx={{
                            color:
                              item.transferred || item.sos
                                ? "grey.400"
                                : "primary.main",
                            "&:hover": {
                              backgroundColor:
                                item.transferred || item.sos
                                  ? "transparent"
                                  : "rgba(0, 123, 255, 0.04)",
                            },
                          }}>
                          <CompareArrowsIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>

                    {item.sos && (
                      <Tooltip title="Vehicle under maintenance">
                        <WarningIcon
                          fontSize="small"
                          sx={{ color: "warning.main" }}
                        />
                      </Tooltip>
                    )}

                    {item.transferred && (
                      <Tooltip title="Vehicle transferred">
                        <InfoIcon
                          fontSize="small"
                          sx={{ color: "info.main" }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </td>
                <td>{highlightText(item?.projectName || "--", searchQuery)}</td>
                <td>{highlightText(item?.vehicleType || "--", searchQuery)}</td>
                <td>
                  {highlightText(item?.vehicleNumber || "--", searchQuery)}
                </td>
                <td>
                  {highlightText(item?.chassisNumber || "--", searchQuery)}
                </td>

                <td>{highlightText(calculateEfficiency(item), searchQuery)}</td>
                <td>
                  {highlightText(getExpectedMileage(item.model), searchQuery)}
                </td>
                <td>
                  {highlightText(
                    calculateEfficiencyRatio(
                      calculateEfficiency(item),
                      getExpectedMileage(item.model)
                    ),
                    searchQuery
                  )}
                </td>
                <td>{highlightText(item?.make || "--", searchQuery)}</td>
                <td>{highlightText(item?.model || "--", searchQuery)}</td>
                <td>
                  {item?.kmsTravelled
                    ? highlightText(
                        Number(item?.kmsTravelled).toFixed(2) || "--",
                        searchQuery
                      )
                    : "--"}
                </td>

                <td>
                  {item?.totalDieselConsumed
                    ? highlightText(
                        Number(item?.totalDieselConsumed).toFixed(2) || "--",
                        searchQuery
                      )
                    : "--"}
                </td>
                <td>
                  ₹{" "}
                  {item?.amount
                    ? highlightText(
                        Number(item?.amount).toFixed(2) || "0",
                        searchQuery
                      )
                    : "0"}
                </td>
                <td>
                  {item?.totalHoursWorked
                    ? highlightText(
                        Number(item?.totalHoursWorked).toFixed(2) || "--",
                        searchQuery
                      )
                    : "--"}
                </td>

                <td>
                  {item?.engine2?.totalHoursWorked
                    ? highlightText(
                        Number(item?.engine2?.totalHoursWorked).toFixed(2) ||
                          "--",
                        searchQuery
                      )
                    : "--"}
                </td>
                <td>
                  {highlightText(item?.monthOfPurchase || "--", searchQuery)}
                </td>
                <td>
                  {highlightText(item?.yearOfPurchase || "--", searchQuery)}
                </td>
                <td>
                  {highlightText(
                    item?.insuranceStartDate
                      ? new Date(item.insuranceStartDate).toLocaleDateString()
                      : "--",
                    searchQuery
                  )}
                </td>
                <td>
                  {highlightText(
                    item?.insuranceEndDate
                      ? new Date(item.insuranceEndDate).toLocaleDateString()
                      : "--",
                    searchQuery
                  )}
                </td>
                <td>
                  {highlightText(item?.insuranceProvider || "--", searchQuery)}
                </td>
                <td>{highlightText(item?.permitNo || "--", searchQuery)}</td>
                <td>{highlightText(item?.permitRange || "--", searchQuery)}</td>
                <td>
                  {highlightText(
                    item?.permitValidUpto
                      ? new Date(item.permitValidUpto).toLocaleDateString()
                      : "--",
                    searchQuery
                  )}
                </td>
                <td>
                  {highlightText(
                    item?.fitnessCertificateNo || "--",
                    searchQuery
                  )}
                </td>
                <td>
                  {highlightText(
                    item?.fcValidUpto
                      ? new Date(item.fcValidUpto).toLocaleDateString()
                      : "--",
                    searchQuery
                  )}
                </td>
                <td>
                  {highlightText(
                    item?.roadTaxValidUpto
                      ? new Date(item.roadTaxValidUpto).toLocaleDateString()
                      : "--",
                    searchQuery
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderServiceScheduleTable = () => (
    <div className="table-wrapper">
      <div className="table-scroll-container">
        <table>
          <thead>
            <tr>
              <th colSpan="6" className="group-header">
                Vehicle
              </th>
              <th colSpan="2" className="group-header">
                Service Details
              </th>
              <th colSpan="3" className="group-header">
                Service History
              </th>
              <th colSpan="2" className="group-header">
                Breakdown
              </th>
              <th colSpan="1" className="group-header">
                Total Cost
              </th>
            </tr>
            <tr>
              <th>Project name</th>
              <th>Chassis number</th>
              <th>Type</th>
              <th>Number</th>
              <th>Hours</th>
              <th>Fuel consumed</th>
              <th>Next service in</th>
              <th>Last service Date</th>
              <th>Description</th>
              <th>Maintenance cost</th>
              <th>Cycle Type</th>
              <th>No of breakdowns</th>
              <th>Cost</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {filterData(maintenanceData).map((item, index) => (
              <tr key={index}>
                <td>{highlightText(item.projectName || "--", searchQuery)}</td>
                <td>
                  {highlightText(item.chassisNumber || "--", searchQuery)}
                </td>
                <td>{highlightText(item.type || "--", searchQuery)}</td>
                <td>{highlightText(item.number || "--", searchQuery)}</td>
                <td>
                  {highlightText(
                    item.hours ? Number(item.hours).toFixed(2) : "--",
                    searchQuery
                  )}
                </td>
                <td>
                  {highlightText(
                    item.fuelConsumed
                      ? Number(item.fuelConsumed).toFixed(2)
                      : "--",
                    searchQuery
                  )}
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between ",
                    }}>
                    {highlightText(item.nextServiceIn || "--", searchQuery)}
                    {item.showServiceAlert && (
                      <WarningIcon
                        sx={{
                          color: "red",
                          fontSize: "20px",
                          marginLeft: "4px",
                        }}
                        titleAccess={item.nextServiceIn} // This will show "X hrs" or "X km" or "X days"
                      />
                    )}
                  </div>
                </td>
                <td>
                  {highlightText(item.lastServiceDate || "--", searchQuery)}
                </td>
                <td>
                  {renderServiceDescription(
                    item.serviceDescription,
                    item.additionalServicesCount,
                    item
                  )}
                </td>
                <td>
                  {highlightText(
                    item.totalServiceCost ? `₹${item.totalServiceCost}` : "--",
                    searchQuery
                  )}
                </td>
                <td>{highlightText(item.cycleType || "--", searchQuery)}</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}>
                    {highlightText(
                      `${item.numberOfBreakdowns.total}`,
                      searchQuery
                    )}
                    {item.numberOfBreakdowns.total > 0 && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          const vehicle = vehiclesData.find(
                            (v) => v.chassisNumber === item.chassisNumber
                          );
                          handleOpenBreakdownModal(vehicle.breakdownHistory);
                        }}
                        sx={{ padding: "4px" }}>
                        <ExpandMoreIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                </td>
                <td>
                  {highlightText(
                    item.totalBreakdownCost
                      ? `₹${item.totalBreakdownCost}`
                      : "--",
                    searchQuery
                  )}
                </td>
                <td>
                  {highlightText(
                    Number(item.totalServiceCost) +
                      Number(item.totalBreakdownCost) >
                      0
                      ? `₹${
                          Number(item.totalServiceCost) +
                          Number(item.totalBreakdownCost)
                        }`
                      : "--",
                    searchQuery
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const tabStyle = (tabName) => ({
    padding: "10px 20px",
    borderRadius: "20px",
    color: activeTab === tabName ? "black" : "#666",
    backgroundColor: activeTab === tabName ? "#fdd34d" : "#f5f5f5",
    transition: "all 0.3s",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: "14px",
  });

  return (
    <div className="vehicles-container">
      {isLoading ? (
        <LoadingUI />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "center",
              marginBottom: "10px",
            }}>
            <div className="header-tabs">
              <div
                onClick={() => setActiveTab("daily")}
                style={tabStyle("daily")}>
                Daily Vehicle Report
              </div>
              <div
                onClick={() => setActiveTab("vehicle")}
                style={tabStyle("vehicle")}>
                Vehicle Information
              </div>
              <div
                onClick={() => setActiveTab("service")}
                style={tabStyle("service")}>
                Service schedule
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                marginBottom: "10px",
              }}>
              {renderExportButton()}
              <button
                style={{
                  backgroundColor: "#fdd34d",
                  color: "black",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 18px",
                  fontSize: "14px",
                  fontWeight: "500",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  textTransform: "uppercase",
                  letterSpacing: "0.5px",
                  outline: "none",
                  transform: "scale(1)",
                }}
                onMouseEnter={(e) => {
                  e.target.style.transform = "scale(1.05)";
                  e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = "scale(1)";
                  e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
                }}
                onClick={() => setIsAddModalOpen(true)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  style={{
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                  }}>
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
                Add Asset
              </button>
              <button
                style={{
                  backgroundColor: "#fdd34d",
                  color: "black",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 18px",
                  fontSize: "14px",
                  fontWeight: "500",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  textTransform: "uppercase",
                  letterSpacing: "0.5px",
                  outline: "none",
                  transform: "scale(1)",
                }}
                onMouseEnter={(e) => {
                  e.target.style.transform = "scale(1.05)";
                  e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = "scale(1)";
                  e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
                }}
                onClick={() => setIsConsumptionModalOpen(true)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2">
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
                Add Usage
              </button>
            </div>
          </div>

          <div className="controls-section">
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}>
              {activeTab === "daily" && (
                <div className="filters">
                  <select
                    value={timeFilter}
                    onChange={(e) => setTimeFilter(e.target.value)}
                    className="time-filter">
                    <option value="all">Show All</option>
                    <option value="< 8 hours">Show {"<"} 8 hours</option>
                  </select>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className="date-pickers">
                      <DatePicker
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        className="modern-date-picker"
                        slotProps={{ textField: { size: "small" } }}
                      />
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#666",
                        }}>
                        to
                      </span>
                      <DatePicker
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        className="modern-date-picker"
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </div>
                  </LocalizationProvider>
                </div>
              )}
              <div className="filters-container">
                <button
                  className={`filter-trigger`}
                  onClick={() => setIsFilterOpen(!isFilterOpen)}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2">
                    <path d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z" />
                  </svg>
                  Filters{" "}
                  {(selectedProjects.length > 0 ||
                    selectedVehicleTypes.length > 0 ||
                    selectedChassisNumbers.length > 0) &&
                    `(${
                      selectedProjects.length +
                      selectedVehicleTypes.length +
                      selectedChassisNumbers.length
                    })`}
                </button>

                {isFilterOpen && (
                  <div className="filter-modal" ref={filterModalRef}>
                    <div className="filter-header">
                      <h3>Filters</h3>
                      <button
                        onClick={() => {
                          setSelectedProjects([]);
                          setSelectedVehicleTypes([]);
                          setSelectedChassisNumbers([]); // Add this
                        }}>
                        Clear all
                      </button>
                    </div>

                    <div className="filter-content">
                      <div className="filter-section">
                        <div className="filter-section-header">
                          <span className="filter-section-title">
                            Project Name
                          </span>
                          <button
                            className="select-all-btn"
                            onClick={() => {
                              const allProjects = getUniqueProjects();
                              setSelectedProjects(
                                selectedProjects.length === allProjects.length
                                  ? []
                                  : allProjects
                              );
                            }}>
                            {selectedProjects.length ===
                            getUniqueProjects().length
                              ? "Unselect All"
                              : "Select All"}
                          </button>
                        </div>

                        <div className="filter-options">
                          {getUniqueProjects().map((project) => (
                            <label className="filter-option" key={project}>
                              <input
                                type="checkbox"
                                className="filter-checkbox"
                                checked={selectedProjects.includes(project)}
                                onChange={() => {
                                  setSelectedProjects((prev) =>
                                    prev.includes(project)
                                      ? prev.filter((p) => p !== project)
                                      : [...prev, project]
                                  );
                                }}
                              />
                              <span className="filter-label">{project}</span>
                            </label>
                          ))}
                        </div>
                      </div>

                      <div className="filter-section">
                        <div className="filter-section-header">
                          <span className="filter-section-title">
                            Vehicle Type
                          </span>
                          <button
                            className="select-all-btn"
                            onClick={() => {
                              const allTypes = getUniqueVehicleTypes();
                              setSelectedVehicleTypes(
                                selectedVehicleTypes.length === allTypes.length
                                  ? []
                                  : allTypes
                              );
                            }}>
                            {selectedVehicleTypes.length ===
                            getUniqueVehicleTypes().length
                              ? "Unselect All"
                              : "Select All"}
                          </button>
                        </div>

                        <div className="filter-options">
                          {getUniqueVehicleTypes().map((type) => (
                            <label className="filter-option" key={type}>
                              <input
                                type="checkbox"
                                className="filter-checkbox"
                                checked={selectedVehicleTypes.includes(type)}
                                onChange={() => {
                                  setSelectedVehicleTypes((prev) =>
                                    prev.includes(type)
                                      ? prev.filter((t) => t !== type)
                                      : [...prev, type]
                                  );
                                }}
                              />
                              <span className="filter-label">{type}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className="filter-section">
                        <div className="filter-section-header">
                          <span className="filter-section-title">
                            Chassis Number
                          </span>
                          <button
                            className="select-all-btn"
                            onClick={() => {
                              const allChassisNumbers =
                                getUniqueChassisNumbers();
                              setSelectedChassisNumbers(
                                selectedChassisNumbers.length ===
                                  allChassisNumbers.length
                                  ? []
                                  : allChassisNumbers
                              );
                            }}>
                            {selectedChassisNumbers.length ===
                            getUniqueChassisNumbers().length
                              ? "Unselect All"
                              : "Select All"}
                          </button>
                        </div>

                        <div className="filter-options">
                          {getUniqueChassisNumbers().map((chassisNumber) => (
                            <label
                              className="filter-option"
                              key={chassisNumber}>
                              <input
                                type="checkbox"
                                className="filter-checkbox"
                                checked={selectedChassisNumbers.includes(
                                  chassisNumber
                                )}
                                onChange={() => {
                                  setSelectedChassisNumbers((prev) =>
                                    prev.includes(chassisNumber)
                                      ? prev.filter((c) => c !== chassisNumber)
                                      : [...prev, chassisNumber]
                                  );
                                }}
                              />
                              <span className="filter-label">
                                {chassisNumber}
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {(selectedProjects.length > 0 ||
            selectedVehicleTypes.length > 0 ||
            selectedChassisNumbers.length > 0) && (
            <div className="active-filters">
              {selectedProjects.map((project) => (
                <div key={project} className="filter-tag">
                  {project}
                  <button
                    onClick={() =>
                      setSelectedProjects((prev) =>
                        prev.filter((p) => p !== project)
                      )
                    }>
                    ×
                  </button>
                </div>
              ))}
              {selectedVehicleTypes.map((type) => (
                <div key={type} className="filter-tag">
                  {type}
                  <button
                    onClick={() =>
                      setSelectedVehicleTypes((prev) =>
                        prev.filter((t) => t !== type)
                      )
                    }>
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}

          {activeTab === "daily" && renderDailyReportTable()}
          {activeTab === "vehicle" && renderVehicleInformationTable()}
          {activeTab === "service" && renderServiceScheduleTable()}
          {renderMaintenanceModal()}
          {breakdownModalOpen && (
            <BreakdownModal
              breakdownData={selectedBreakdowns}
              onClose={() => {
                setBreakdownModalOpen(false);
                setSelectedBreakdowns(null);
              }}
            />
          )}
        </>
      )}
      <Dialog
        open={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setSelectedVehicle(null);
        }}
        maxWidth="lg"
        fullWidth>
        <VehicleForm
          vehicle={selectedVehicle}
          onSubmit={handleSubmit} // Pass the submit handler here
          projects={projects}
          mode={isAddModalOpen ? "add" : "edit"}
          loading={actionLoading}
          onClose={() => {
            setIsAddModalOpen(false);
            setIsEditModalOpen(false);
            setSelectedVehicle(null);
          }}
          firmId={firmId}
        />
      </Dialog>

      {/* Transfer Vehicle Modal */}
      <Dialog
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setSelectedVehicle(null);
        }}
        maxWidth="lg"
        fullWidth>
        <VehicleForm
          vehicle={selectedVehicle}
          onSubmit={handleEdit} // This will be called when form is submitted
          projects={projects}
          mode="edit" // Make sure this is set to "edit"
          loading={actionLoading}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedVehicle(null);
          }}
          firmId={firmId}
        />
      </Dialog>

      {/* Transfer Modal */}
      <TransferVehicle
        open={isTransferModalOpen}
        onClose={() => {
          setIsTransferModalOpen(false);
          setSelectedVehicle(null);
        }}
        vehicle={selectedVehicle}
        projects={projects.filter(
          (p) => p.projectID !== selectedVehicle?.projectId
        )}
        currentProject={
          projects.find((p) => p.projectID === selectedVehicle?.projectId)
            ?.projectName || ""
        }
        onTransfer={handleTransfer}
        loading={actionLoading}
      />
      {/* Add this near your other modals */}
      <ConsumptionModal
        open={isConsumptionModalOpen}
        onClose={() => setIsConsumptionModalOpen(false)}
        onSubmit={handleAddConsumption}
        onDelete={handleDeleteConsumption}
        onEdit={handleEditConsumption}
        projects={projects}
        vehicles={vehiclesData}
        firmData={firmData}
        loading={actionLoading}
        consumptionHistoryData={consumptionHistoryData}
      />
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DrawerTemplate(Vehicles);
