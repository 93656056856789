// eslint-disable-next-line import/no-anonymous-default-export
export default (data) => {
  let total = 0;
  const { rooms } = data;
  rooms &&
    Array.isArray(rooms) &&
    rooms.forEach((room, roomIndex) => {
      const { Units } = room;
      Units?.forEach((Unit, unitIndex) => {
        const { Components } = Unit;
        if (Components) {
          Components.forEach((component, componentIndex) => {
            const { quantity, rate } = component;
            if (typeof rate === "string" && !isNaN(rate)) {
              if (
                quantity &&
                rate &&
                quantity.trim().length > 0 &&
                rate.length > 0 &&
                !isNaN(quantity) &&
                !isNaN(rate)
              ) {
                total += parseFloat(quantity) * parseFloat(rate);
              }
            } else if (
              quantity &&
              rate &&
              quantity.trim().length > 0 &&
              !isNaN(quantity) &&
              !isNaN(rate)
            ) {
              total += parseFloat(quantity) * parseFloat(rate);
            }
          });
        }
      });
    });

  return total;
};
