const InventoryItem = ({ material }) => {
    return (
      <div style={styles.imageContainer}>
        {material.image ? (
          <img 
            src={material.image} 
            alt={material.name || "Material"} 
            style={styles.image} 
          />
        ) : (
          <div style={styles.imagePlaceholder}></div>
        )}
      </div>
    );
  };

  const styles = {
    imageContainer: {
      width: "80px",
      height: "100px",
      borderRadius: "8px",
      overflow: "hidden", // Ensures the image doesn't overflow the box
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover", // Ensures the image covers the container while maintaining aspect ratio
    },
    imagePlaceholder: {
      width: "80px",
      height: "100px",
      backgroundColor: "#f0f0f0",
      borderRadius: "8px",
      backgroundImage: 'url("https://via.placeholder.com/80x100")',
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  };

export default InventoryItem;
  
  