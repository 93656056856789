import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const handleExportExcelInward = async (filteredData, project) => {
  const includeProjectName =
    typeof project === "object" && project !== null && "projectName" in project;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Exported Data");

  // Define headers
  const columns = [
    { header: "Referral PO No.", key: "Referral PO No.", width: 20 },
    { header: "Material Requisition Note (MRN)", key: "Material Requisition Note (MRN)", width: 30 },
    { header: "Vehicle No.", key: "Vehicle No.", width: 20 },
    { header: "Invoice No.", key: "Invoice No.", width: 20 },
    { header: "Invoice Date", key: "Invoice Date", width: 15 },
    { header: "Gate Pass No.", key: "Gate Pass No.", width: 20 },
    { header: "Gate Pass Date", key: "Gate Pass Date", width: 15 },
    { header: "DC No.", key: "DC No.", width: 20 },
    { header: "DC Date", key: "DC Date", width: 15 },
    { header: "Supplier/Vendor", key: "Supplier/Vendor", width: 20 },
    { header: "Total Items", key: "Total Items", width: 15 },
    { header: "Done By", key: "Done By", width: 20 },
    { header: "Item Category", key: "Item Category", width: 20 },
    { header: "Item Description", key: "Item Description", width: 30 },
    { header: "Quantity Accepted", key: "Quantity Accepted", width: 20 },
    { header: "UOM", key: "UOM", width: 10 },
    { header: "Item Remark", key: "Item Remark", width: 30 },
    { header: "Item Image", key: "Item Image", width: 30 },
    { header: "Invoice Image", key: "Invoice Image", width: 30 },
  ];

  if (includeProjectName) {
    columns.unshift({
      header: "Project Name",
      key: "Project Name",
      width: 25,
    });
  }

  worksheet.columns = columns;

  // Style headers
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };
  headerRow.alignment = { vertical: "middle", horizontal: "center" };

  const assignBackgroundColorToRow = (row, color) => {
    row.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
    });
  };

  for (const entry of filteredData) {
    if (worksheet.rowCount > 1) {
      worksheet.addRow([]);
    }

    const baseRowData = {
      "Referral PO No.": entry.fields["01"]?.value || "Not provided",
      "Material Requisition Note (MRN)": entry.fields["02"]?.value || "Not provided",
      "Vehicle No.": entry.fields["03"]?.value || "Not provided",
      "Invoice No.": entry.fields["04"]?.["01"]?.value || "Not provided",
      "Invoice Date": entry.fields["04"]?.["02"]?.value || "Not provided",
      "Gate Pass No.": entry.fields["05"]?.["01"]?.value || "Not provided",
      "Gate Pass Date": entry.fields["05"]?.["02"]?.value || "Not provided",
      "DC No.": entry.fields["06"]?.["01"]?.value || "Not provided",
      "DC Date": entry.fields["06"]?.["02"]?.value || "Not provided",
      "Supplier/Vendor": entry.fields["07"]?.value || "Not provided",
      "Total Items": Object.values(entry.invoices).reduce(
        (sum, invoice) => sum + Object.keys(invoice.invoiceItems).length,
        0
      ),
      "Done By": entry.requestHistory
        ? entry.requestHistory[Object.keys(entry.requestHistory || {}).pop()]?.doneBy || "Unknown"
        : "Unknown",
    };

    if (includeProjectName) {
      baseRowData["Project Name"] = project.projectName;
    }

    const invoiceImagesatRoot = entry.images ? Object.values(entry.images).filter((img) => img.url) : [];

    Object.values(entry.invoices).forEach((invoice) => {
      let invoiceImages = invoice.images ? Object.values(invoice.images).filter((img) => img.uri || img.url) : [];
      invoiceImages = [...invoiceImagesatRoot, ...invoiceImages];

      Object.values(invoice.invoiceItems).forEach((item, itemIndex) => {
        
        const rowData = itemIndex === 0 ? { ...baseRowData } : Object.fromEntries(Object.keys(baseRowData).map((key) => [key, "-"]));

        rowData["Item Category"] = item.category || "-";
        rowData["Item Description"] = item.description || "-";
        rowData["Quantity Accepted"] = item.quantityAccepted || "-";
        rowData["UOM"] = item.uom || "-";
        rowData["Item Remark"] = item.remark || "No Remark";

        // Handling multiple item images
        const itemImages = item.images ? Object.values(item.images).filter((img) => img.uri || img.url) : [];
        if (itemImages.length > 0) {
          const itemLinks = itemImages.map((img, index) => ({
            text: `Image${index + 1}`,
            hyperlink: img.uri || img.url
          }));
          
          rowData["Item Image"] = itemLinks;
        } else {
          rowData["Item Image"] = "No Image";
        }

        // Handling multiple invoice images
        if (itemIndex === 0 && invoiceImages.length > 0) {
          const invoiceLinks = invoiceImages.map((img, index) => ({
            text: `Image${index + 1}`,
            hyperlink: img.uri || img.url
          }));
          
          rowData["Invoice Image"] = invoiceLinks;
        }

        const mainRow = worksheet.addRow(rowData);

        if (itemIndex === 0) {
          assignBackgroundColorToRow(mainRow, "FFFFE699");
        }

        mainRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "left", wrapText: true };
          
          // Check if cell contains image links array
          if (Array.isArray(cell.value)) {
            // Format the links
            const formattedLinks = cell.value.map(link => ({
              text: link.text,
              hyperlink: link.hyperlink
            }));
            
            // Apply formatting to each link
            cell.value = formattedLinks[0];  // Set first link
            cell.font = { color: { argb: "FF0000FF" }, underline: true };
            
            // Add remaining links in adjacent cells below
            if (formattedLinks.length > 1) {
              const colNumber = cell.col;
              formattedLinks.slice(1).forEach((link, index) => {
                const newCell = worksheet.getCell(cell.row + index + 1, colNumber);
                newCell.value = link;
                newCell.font = { color: { argb: "FF0000FF" }, underline: true };
                newCell.alignment = { vertical: "middle", horizontal: "left", wrapText: true };
              });
            }
          }
        });
      });
    });
  }

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${project.projectName ? project.projectName : 'Site'} Inward.xlsx`);
};


export default handleExportExcelInward;

