import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AuthService from "../../../api/authService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
}));

const Form = ({ handleClose, onAuthChanged, setWorkMaterialCategories }) => {
  const classes = useStyles();
  const [category, setCategory] = useState();

  const VendorCategories = (category) => {
    const authService = new AuthService();
    authService
      .addVendorCategories(category)
      .then((res) => {
        if (res.status == 200) {
          // alert("Category Added Successfully")
          setWorkMaterialCategories((prev) => {
            return [...prev, { Category: category, Brands: null }];
          });
        } else {
          alert("category Already Exist");
          return;
        }
      })
      .catch((error) => {
        alert("somthing went wrong");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        label="category"
        variant="filled"
        required
        onChange={(e) => setCategory(e.currentTarget.value)}
        value={category}
      />
      <div>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            if (!category) {
              alert("Please Provide category ");
              return;
            }
            VendorCategories(category);
          }}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default Form;
