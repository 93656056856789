import React from 'react';

const LoadingIcon = () => {
  return (
    <div style={styles.container}>
      <div style={styles.spinner}></div>
      <div style={styles.text}>Data is being fetched...</div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full viewport height
  },
  spinner: {
    border: '8px solid #fdd34d', // Yellow color for the spinner border
    borderTop: '8px solid #fff', // White color for the top part of the spinner
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite', // Spinning animation
    marginBottom: '20px', // Space between the spinner and text
  },
  text: {
    fontSize: '16px',
    color: '#6c1710', // Text color matches the spinner
    fontWeight: 'bold',
    letterSpacing: '1px',
  }
};

// Keyframes for the spinning animation
const stylesKeyframes = {
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
};

// Add keyframes dynamically to style (for spinning animation)
document.styleSheets[0].insertRule(`@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`, document.styleSheets[0].cssRules.length);

export default LoadingIcon;
