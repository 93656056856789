import config from "../config/config";


class AnalyticsService{

  async getFirmBasedAnalytics(firm) {
    try {
      let response = await fetch(
        `${config.authService}getFirmBasedAnalytics/${firm}`
      );
      response = await response.json();
      if (response && response.firm) {
        response.firm += `,${firm}`;
      } else {
        response.firm = firm;
      }
      return { ...response };
    } catch (e) {
      return { status: 400, statusMsg: "Error fetching tickets!", payload: e };
    }
  }
}

export default AnalyticsService;
