import React, { useState } from 'react';
import TopInfoBar from './TopInfoBar';
import ItemsTable from './ItemsTable';
import RequestHistoryModal from './RequestHistoryModal';
import ImagePreviewModal from './ImagePreviewModal';

const InwardCard = ({ itemData, project, userName }) => {
  
  const [showRequestHistory, setShowRequestHistory] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleRequestHistoryToggle = () => setShowRequestHistory(!showRequestHistory);

  const handleImagePreviewToggle = (image) => {
    setSelectedImage(image);
    setShowImagePreview(!showImagePreview);
  };

  return (
    <div style={styles.cardContainer}>
      <TopInfoBar fields={itemData.fields} inTime={itemData.inTime} outTime={itemData.outTime} itemData={itemData} />
      <ItemsTable
        userName={userName}
        itemData={itemData}
        project={project}
        projectName={itemData.projectName}
        invoices={itemData.invoices} 
        onViewMore={handleRequestHistoryToggle}
        onImageClick={handleImagePreviewToggle}
      />

      {showRequestHistory && (
        <RequestHistoryModal 
          requestHistory={itemData.requestHistory} 
          onClose={handleRequestHistoryToggle} 
          onImageClick={handleImagePreviewToggle} 
        />
      )}

      {showImagePreview && (
        <ImagePreviewModal 
          image={selectedImage} 
          onClose={() => setShowImagePreview(false)} 
        />
      )}
    </div>
  );
};

const styles = {
  cardContainer: {
    border: '1px solid #aaa',
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
};

export default InwardCard;
